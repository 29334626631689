<div>
  <p class="mb-4">
    Are there any pending
    <span class="aux-link cursor-pointer" [routerLink]="changeOrdersRoute">Change Orders?</span>
  </p>
  <p class="mb-2">
    Are there any new or changing
    <span class="aux-link cursor-pointer" [routerLink]="sitesRoute">Site</span> Contracts?
  </p>
</div>

<div class="flex items-center">
  <div>
    <p>
      Are there any new or changing
      <span class="aux-link cursor-pointer" [routerLink]="purchaseOrdersRoute">Purchase Orders</span
      >?
    </p>
  </div>

  <div class="gather-estimates-container ml-2">
    <!-- Upload section -->
    <div *ngIf="!data.disabledLock" class="gather-estimates-upload-section">
      <button type="button" class="btn--secondary pl-3" (click)="data.addPoReportUploadClick()">
        <aux-icon name="CirclePlus" />
        <span class="ml-2">Add PO Report</span>
      </button>
    </div>

    <!-- Upload-disabled section -->
    <div *ngIf="data.disabledLock" class="gather-estimates-upload-section">
      <button
        type="button"
        class="btn--secondary gather-estimates-upload-section-disabled pl-3"
        disabled
      >
        <aux-icon name="CirclePlus" />
        <span class="ml-2">Add PO Report</span>
      </button>
    </div>
  </div>
</div>
