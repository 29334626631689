import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { Subject } from 'rxjs';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams, SortDirection } from '@ag-grid-community/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IconComponent } from '@components/icon/icon.component';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { AgExpandAllRows } from '@shared/utils';

export interface AgExpandHeaderParams extends IHeaderParams {
  sortChanged$?: Subject<null>;
}

@UntilDestroy()
@Component({
  standalone: true,
  template: `
    <aux-icon
      name="ChevronsDown"
      [size]="20"
      [auxTooltip]="'Expand All Rows'"
      (click)="toggleRowExpand(true)"
      class="cursor-pointer"
    />

    <aux-icon
      name="ChevronsUp"
      class="ml-[5px] cursor-pointer"
      [size]="20"
      [auxTooltip]="'Collapse All Rows'"
      (click)="toggleRowExpand(false)"
    />

    <div class="flex items-center" (click)="toggleSort()">
      <div
        class="text-aux-black text-base ml-[10px]"
        [innerHTML]="params.template || params.displayName"
      ></div>

      <ng-container *ngIf="params.enableSorting">
        <span class="ag-sort-indicator-container">
          <span
            class="ag-sort-indicator-icon ag-sort-ascending-icon"
            aria-hidden="true"
            *ngIf="currentSort === 'asc'"
          >
            <span class="ag-icon ag-icon-asc" role="presentation"></span>
          </span>
          <span
            class="ag-sort-indicator-icon ag-sort-descending-icon"
            aria-hidden="true"
            *ngIf="currentSort === 'desc'"
          >
            <span class="ag-icon ag-icon-desc" role="presentation"></span>
          </span>
        </span>
      </ng-container>
    </div>
  `,
  imports: [IconComponent, TooltipDirective, NgIf],
  styles: [
    `
      :host {
        @apply flex items-center justify-center w-full h-full;
      }
    `,
  ],
})
export class AgExpandHeaderComponent implements IHeaderAngularComp {
  params!: AgExpandHeaderParams;

  visible = true;

  currentSort: SortDirection = null;

  agInit(params: AgExpandHeaderParams): void {
    this.params = params;
    this.currentSort = params.column.getSort() || null;
    if (params.sortChanged$) {
      params.sortChanged$.pipe(untilDestroyed(this)).subscribe(() => {
        this.currentSort = params.column.getSort() || null;
      });
    }
  }

  toggleRowExpand(expanded: boolean) {
    AgExpandAllRows({ gridApi: this.params.api, expanded });
  }

  toggleSort() {
    if (!this.params.enableSorting) {
      return;
    }
    this.currentSort = this.currentSort === 'asc' ? 'desc' : 'asc';
    this.params.setSort(this.currentSort);
  }

  refresh(): boolean {
    return false;
  }
}
