import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { ChangeOrderStatus, EntityType, EventType, GqlService } from '@services/gql.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OverlayService } from '@services/overlay.service';
import { ApiService, FileMetadata } from '@services/api.service';
import { MainStore } from 'src/app/layouts/main-layout/state/main.store';

@Component({
  selector: 'aux-change-order-budget-upload',
  template: `
    <div class="text-lg font-bold mb-4">Upload Change Order Budget</div>
    <div>
      <aux-file-manager
        #manager
        class="h-48"
        [fetchFilesOnInit]="false"
        [pathFn]="pathFn"
        [eager]="false"
        [metadata]="metadata"
        [showSuccessOnUpload]="true"
        [accept]="'.csv'"
      />
      <div class="max-h-60 overflow-auto mt-4">
        <aux-file-viewer
          [fileManager]="manager"
          [disableFirstFileMargin]="true"
          [onlyShowUploaded]="false"
        />
      </div>
    </div>

    <div *ngIf="errorMessage" class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md">
      {{ errorMessage }}
    </div>

    <div>
      <aux-checkbox
        class="text-sm font-medium"
        [id]="'bypass-validation'"
        [(ngModel)]="bypassValidation"
      >
        Bypass blank Activity ID check
      </aux-checkbox>
    </div>

    <div class="mt-8 flex space-x-4">
      <aux-button
        variant="custom"
        classList="w-48 text-sm btn btn--blue"
        label="Upload Budget"
        [spinnerSize]="6"
        [loading]="loading$ | async"
        [onClick]="onUpload"
      />

      <button
        class="text-sm font-normal aux-link focus:outline-none"
        type="button"
        (click)="ref.close()"
      >
        Cancel
      </button>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderBudgetUploadComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  errorMessage = '';

  loading$ = new BehaviorSubject(false);

  change_order_id = this.ref.data?.change_order_id || '';

  change_order_status = this.ref.data?.change_order_status || '';

  organization_id = this.ref.data?.organization_id || '';

  bypassValidation = false;

  metadata: FileMetadata = {};

  constructor(
    public ref: CustomOverlayRef<
      unknown,
      {
        change_order_id: string;
        change_order_status: ChangeOrderStatus;
        organization_id: string;
      }
    >,
    private mainQuery: MainQuery,
    private mainStore: MainStore,
    private overlayService: OverlayService,
    private apiService: ApiService,
    private gqlService: GqlService
  ) {}

  pathFn: () => string = () => '';

  getFilePath(org_id: string, co_id: string) {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/vendors/${org_id}/changeorders/${co_id}/`;
  }

  onUpload = async () => {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = 'You need to upload a file!';
        return;
      }

      if (files.length > 1) {
        this.errorMessage = 'Maximum one file allowed!';
        return;
      }

      const match = files[0].bucket_key.match(/\.([^.]+)$/);
      if (match?.[1] !== 'csv') {
        this.errorMessage = 'File type must be a .csv!';
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const bucket_key = `${this.getFilePath(this.organization_id, this.change_order_id)}${
        file.bucket_key
      }`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        bucket_key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        this.mainStore.setProcessingLoadingState(
          EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED,
          true
        );
        const { success, errors } = await firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: this.organization_id,
            bucket_key: `public/${bucket_key}`,
            payload: JSON.stringify({
              change_order_id: this.change_order_id,
              change_order_status: this.change_order_status,
              skip_activityno_check: this.bypassValidation,
            }),
          })
        );
        if (success) {
          this.overlayService.success(`Budget is processing. Please wait...`);
        } else {
          this.apiService.removeFile(bucket_key);
          this.overlayService.error(errors);
          this.mainStore.setProcessingLoadingState(
            EventType.CHANGE_ORDER_BUDGET_TEMPLATE_UPLOADED,
            false
          );
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  };
}
