import { animation, animate, style } from '@angular/animations';

export const transAnimation = animation([
  style({
    height: '{{ height }}',
    opacity: '{{ opacity }}',
    backgroundColor: '{{ backgroundColor }}',
  }),
  animate('{{ time }}'),
]);

export const enter = animation([
  style({ opacity: 0, transform: 'scale(0.95)' }),
  animate('300ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
]);

export const leave = animation(
  animate('200ms ease-in', style({ opacity: 0, transform: 'scale(0.95)' }))
);
