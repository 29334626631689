<div class="flex flex-col" [style.background-color]="options.color ? options.color : ''">
  <!-- Divider -->
  <div *ngIf="options.divider" class="trial-insights-divider full"></div>

  <div class="flex" [class.justify-center]="options.centered">
    <!-- Legend -->
    <div *ngFor="let legend of options.data" class="trial-insights-legend">
      <div class="trial-insights-legend-data-container" [class.compact]="options.compact">
        <div class="flex" [ngClass]="options.compact ? 'items-center' : 'items-start'">
          <!-- Color -->
          <div
            *ngIf="legend.displayIcon"
            class="trial-insights-legend-color"
            [class.compact]="options.compact"
            [style.background-color]="legend.iconColor ? legend.iconColor : ''"
          ></div>

          <!-- Name and Number -->
          <div class="trial-insights-legend-data">
            <span class="trial-insights-legend-name" [class.compact]="options.compact">{{
              legend.title
            }}</span>
            <span
              class="trial-insights-legend-number"
              [style.color]="legend.valueColor ? legend.valueColor : ''"
              >{{ legend.value }}</span
            >
          </div>
        </div>
      </div>

      <!--  Legend Divider -->
      <div *ngIf="legend.displayDivider" class="trial-insights-legend-divider"></div>
    </div>
  </div>
</div>
