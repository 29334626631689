<ng-template #loader>
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-template>

<div *ngIf="!isLoading; else loader" class="my-4">
  <ng-container *ngIf="isGridForOldInvoiceVisible(); else newGridTemplate">
    <aux-invoice-items [invoice]="this.invoice" [items]="this.invoice?.ocr_line_items || []" />
  </ng-container>

  <ng-template #newGridTemplate>
    <div class="my-4 flex flex-row-reverse">
      <aux-button
        variant="primary"
        label="Export"
        icon="FileExport"
        [disabled]="gridData.length === 0 || editMode"
        (click)="getDynamicExcelParams()"
      />

      <div class="flex">
        <aux-button
          *ngIf="!editMode"
          variant="secondary"
          label="Edit"
          icon="Pencil"
          class="mr-2"
          [disabled]="isEditBtnDisabled()"
          [auxTooltip]="getEditBtnTooltip()"
          (click)="onEditMode()"
        />

        <aux-button
          *ngIf="editMode"
          variant="secondary"
          label="Cancel"
          icon="X"
          class="mr-2"
          (click)="onCancel()"
        />

        <aux-button
          *ngIf="editMode"
          variant="success"
          label="Save"
          icon="Check"
          class="mr-2"
          [disabled]="!hasChanges()"
          (click)="onSave()"
        />
      </div>
    </div>

    <ag-grid-angular
      *ngIf="gridData.length > 0; else noRows"
      class="ag-theme-aux tabular-nums"
      [rowData]="gridData"
      [gridOptions]="gridOptions"
      [enableFillHandle]="editMode"
      [domLayout]="'autoHeight'"
      (gridReady)="onGridReady($event)"
      (firstDataRendered)="autosize()"
      (cellValueChanged)="onCellValueChanged($event)"
      (viewportChanged)="onWindowScroll()"
    />

    <ng-template #noRows>
      <aux-line-items-no-rows [invoice]="invoice" />
    </ng-template>
  </ng-template>
</div>
