import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  InvestigatorForecastForm,
  PatientVisitsValues,
} from '../../../investigator-forecast.types';
import { FormGroup } from '@angular/forms';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { decimalDivide } from '@shared/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { InvestigatorPatientVisits } from '../../../services/investigator-patient-visits.service';
import { Options } from 'ngx-slider-v2';

@UntilDestroy()
@Component({
  selector: 'aux-investigator-forecast-overview-patient-visits',
  templateUrl: './investigator-forecast-overview-patient-visits.component.html',
  styles: [
    `
      ::ng-deep .aux-slider .ngx-slider {
        flex-grow: 1;
        width: 30%;
      }
      ::ng-deep .forecast-overview-form input {
        color: black;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorForecastOverviewPatientVisitsComponent implements OnInit {
  @Input() form!: FormGroup<InvestigatorForecastForm>;

  private sliderRange: Options = {
    ceil: 100,
    floor: 0,
  };

  invoiceablesDistributionOptions: Options = {
    ...this.sliderRange,
    hidePointerLabels: true,
  };

  remainingPatientsToDiscontinuePercOptions: Options = this.sliderRange;

  editMode$ = this.investigatorPatientVisits.editMode$;

  investigatorSummaryRoute = `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_SUMMARY}`;

  budgetRoute = `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`;

  constructor(private investigatorPatientVisits: InvestigatorPatientVisits) {}

  ngOnInit(): void {
    this.form.get(['patientVisits', 'remainingPatientsToDiscontinueCost'])?.disable();

    this.recalculatePatientForm();
  }

  recalculatePatientForm() {
    const formGroup = this.form.controls.patientVisits;

    formGroup?.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(isEqual),
        tap(() => {
          const values: PatientVisitsValues = formGroup.getRawValue();

          const formattedTotalDiscontinuedPerc = decimalDivide(values.totalDiscontinuedPerc, 100);

          const forecastedRemainingInvoiceables =
            this.investigatorPatientVisits.getForecastedRemainingInvoiceables(
              decimalDivide(values.invoiceablesPerc, 100),
              values.forecastedRemainingVisitCosts
            );

          const forecastedRemainingInvestigatorCosts =
            this.investigatorPatientVisits.getForecastedRemainingInvestigatorCosts(
              values.forecastedRemainingVisitCosts,
              forecastedRemainingInvoiceables
            );

          // discontinue section
          const patientRemainingToDiscontinueAmount =
            this.investigatorPatientVisits.getPatientRemainingToDiscontinueAmount(
              formattedTotalDiscontinuedPerc,
              values.currentDiscontinuedPerc,
              values.currentEnrolledAmount
            );

          const patientRemainingToDiscontinueAmountLeft =
            this.investigatorPatientVisits.getPatientRemainingToDiscontinueAmountLeft(
              values.currentEnrolledAmount,
              formattedTotalDiscontinuedPerc
            );

          const remainingPatientsToDiscontinueCost =
            this.investigatorPatientVisits.getRemainingPatientsToDiscontinueCost(
              decimalDivide(values.remainingPatientsToDiscontinuePerc, 100),
              values.averageCompletedPatientCost
            );

          const differentPatientCost = this.investigatorPatientVisits.getDiscontinuedDifference(
            values.averageCompletedPatientCost,
            remainingPatientsToDiscontinueCost
          );

          const totalDiscontinued = this.investigatorPatientVisits.getTotalDiscontinued(
            differentPatientCost,
            patientRemainingToDiscontinueAmount
          );

          // Spend to date and remaining section
          const totalForecastedRemainingInvestigatorCosts =
            this.investigatorPatientVisits.getTotalForecastedRemainingInvestigatorCosts(
              forecastedRemainingInvestigatorCosts,
              totalDiscontinued
            );

          const totalForecastedInvestigatorAmountThroughEndOfTrial =
            this.investigatorPatientVisits.getTotalForecastedInvestigatorAmountThroughEndOfTrial(
              totalForecastedRemainingInvestigatorCosts,
              values.totalSpendToDate
            );

          formGroup.patchValue(
            {
              forecastedRemainingInvoiceables,
              forecastedRemainingInvestigatorCosts,
              differentPatientCost,
              patientRemainingToDiscontinueAmount,
              patientRemainingToDiscontinueAmountLeft,
              remainingPatientsToDiscontinueCost,
              totalDiscontinued,
              totalForecastedInvestigatorAmountThroughEndOfTrial,
              totalForecastedRemainingInvestigatorCosts,
            },
            { emitEvent: false }
          );
        })
      )
      .subscribe();
  }

  formatDistributionLabel(percent: number) {
    return decimalDivide(percent, 100);
  }
}
