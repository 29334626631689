<div
  *ngIf="currentWorkflow$ | async as currentWorkflow"
  class="border border-aux-gray-dark flex items-center justify-between p-2.5 pl-4 pr-4"
  [ngClass]="{
    'bg-aux-gray-light': !currentWorkflow.properties.locked,
    'bg-aux-blue-dark': currentWorkflow.properties.locked
  }"
>
  <div class="flex items-center">
    <aux-icon name="Lock" *ngIf="currentWorkflow.properties.locked" class="text-white" />
    <aux-icon name="LockOpen" *ngIf="!currentWorkflow.properties.locked" />
    <div class="ml-3">
      <p
        *ngIf="isWorkflowNameVisible"
        class="text-aux-black font-bold"
        [ngClass]="{
          'text-aux-black': !currentWorkflow.properties.locked,
          'text-white': currentWorkflow.properties.locked
        }"
      >
        {{ parseWorkflowTitle(headerTextCallback(currentWorkflow)) }}
      </p>
      <p
        class="text-sm"
        [ngClass]="{
          'text-aux-gray-darkest': !currentWorkflow.properties.locked,
          'text-white': currentWorkflow.properties.locked
        }"
      >
        {{ auditTextCallback(currentWorkflow) }}
      </p>
    </div>
  </div>

  <aux-button
    [disabled]="!hasUserPermissions || !lockAvailable"
    [auxTooltip]="getBtnTooltipText()"
    [label]="getBtnLabel(currentWorkflow)"
    [icon]="getBtnIcon(currentWorkflow)"
    [variant]="getBtnVariant(currentWorkflow)"
    (click)="onToggleLockWorkflow(currentWorkflow)"
    [attr.data-pendo-id]="getBtnDataPendoId(currentWorkflow)"
  />
</div>
