import { Injectable } from '@angular/core';
import { EntityUIQuery, QueryEntity } from '@datorama/akita';
import {
  ForecastSettingsStore,
  ForecastSettingsState,
  ForecastSettingsUIState,
} from './forecast-settings.store';

@Injectable({ providedIn: 'root' })
export class ForecastSettingsQuery extends QueryEntity<ForecastSettingsState> {
  ui!: EntityUIQuery<ForecastSettingsUIState>;

  constructor(protected store: ForecastSettingsStore) {
    super(store);

    this.createUIQuery();
  }
}
