import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'aux-ag-cis-log-approval',
  template: `
    <ng-container *ngIf="params?.value === 0">
      <div class="w-5 h-5 bg-aux-green rounded-full text-white flex items-center justify-center">
        <aux-icon name="Check" [size]="16" />
      </div>
    </ng-container>
    <ng-container *ngIf="params?.value !== 0">
      Needs {{ params?.value }} approval{{ params?.value === 1 ? '' : 's' }}
    </ng-container>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCisLogApprovalComponent implements ICellRendererAngularComp {
  params: ICellRendererParams | undefined;

  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
}
