import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgControlComponent } from './ag-control.component';

@Component({
  template: `
    <div *ngIf="formGroup$ | async" [formGroup]="$any(formGroup$ | async)">
      <aux-input
        *ngIf="isReady$ | async"
        class="w-full"
        [formControl]="formControl"
        [placeholder]="'Description'"
        (change)="changeHandler()"
      />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgInputComponent extends AgControlComponent {}
