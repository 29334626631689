import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { AgExpandAllRows } from '@shared/utils';

export interface AgCompareGroupHeaderComponentParams extends IHeaderParams {
  expandLevel: () => number;
}

@Component({
  selector: 'aux-ag-compare-group-header',
  templateUrl: './ag-compare-group-header.component.html',
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCompareGroupHeaderComponent implements IHeaderAngularComp {
  params!: AgCompareGroupHeaderComponentParams;

  visible = true;

  agInit(params: AgCompareGroupHeaderComponentParams): void {
    this.params = params;
  }

  toggleRowExpand(expanded: boolean) {
    AgExpandAllRows({ gridApi: this.params.api, expanded });
  }

  refresh(): boolean {
    return false;
  }
}
