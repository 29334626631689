import { MainQuery } from './../../layouts/main-layout/state/main.query';
import { AsyncPipe } from '@angular/common';
import { LaunchDarklyService } from './../../services/launch-darkly.service';
import { Component } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';

@Component({
  selector: 'aux-stage-banner',
  template: `<div
    class="bg-aux-brown-beige flex justify-between items-center py-4 px-8 text-sm font-semibold"
  >
    <div class="flex items-center">
      <aux-icon name="InfoCircle" />
      <span class="ml-2">Currently in {{ envName$ | async }} Environment</span>
    </div>
    <span class="aux-link cursor-pointer font-semibold" (click)="hideBanner()">Dismiss</span>
  </div>`,
  standalone: true,
  imports: [IconComponent, AsyncPipe],
})
export class StageBannerComponent {
  envName$ = this.launchDarklyService.envName$;

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private mainQuery: MainQuery
  ) {}

  hideBanner() {
    this.mainQuery.setEnvBannerWarningVisibility(false);
  }
}
