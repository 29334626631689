<div class="flex flex-col w-full relative">
  <!-- Spinner -->
  <div
    *ngIf="trialLoading || componentsLoading"
    class="flex items-center w-full h-full z-10 absolute"
    style="background-color: #f3f6f7"
  >
    <div
      *ngIf="componentsLoading && !trialLoading"
      class="border-8 w-32 h-32 m-auto mt-40 spinner"
    ></div>
  </div>

  <!-- Timeline Component -->
  <aux-trial-insights-clinical-timeline />

  <br />

  <div class="grid grid-cols-fluid gap-[25px]">
    <!-- Patient Cost Component -->
    <aux-trial-insights-clinical-patient-cost />

    <div>
      <!-- Investigator Cost Component -->
      <aux-trial-insights-clinical-investigator-cost />

      <br />

      <!-- Screen Fail Component -->
      <aux-trial-insights-clinical-screen-fail />
    </div>

    <!-- Site Cost Component -->
    <aux-trial-insights-clinical-site-cost />
  </div>
</div>
