import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import {
  AgMultiLinkComponentParams,
  AgMultiLinkItem,
} from '@shared/components/ag-multi-link/ag-multi-link.model';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

@Component({
  templateUrl: 'ag-multi-link.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgForOf, NgIf, TooltipDirective],
})
export class AgMultiLinkComponent implements ICellRendererAngularComp {
  params: AgMultiLinkComponentParams = {} as AgMultiLinkComponentParams;
  links: AgMultiLinkItem[] = [];

  refresh(): boolean {
    return false;
  }

  agInit(params: AgMultiLinkComponentParams): void {
    this.links = params.value || [];
    this.params = params;
  }

  onClick(link: AgMultiLinkItem): void {
    if (this.params.downloadFileFn) {
      this.params.downloadFileFn(link.link, link.name);
    }
  }
}
