<div class="flex flex-col w-full bg-aux-gray-light rounded-lg">
  <div class="flex flex-row justify-between items-center px-4 pt-4 pb-6">
    <div class="flex items-center space-x-2">
      <aux-icon name="ChartAreaLine" />
      <span class="text-aux-gray-dark-100"> Comparison</span>
    </div>
    <span class="text-aux-gray-darkest text-sm"> To Date</span>
  </div>
  <div class="flex flex-row space-x-2 text-aux-gray-darkest px-4 pb-6 items-center">
    <aux-icon name="Sparkles" class="flex-shrink-0" />
    <span class="text-aux-gray-darkest text-sm">
      Compare actual enrollment against forecasted metrics to date, to gain insights for a better
      analysis
    </span>
  </div>

  <div
    *ngIf="comparisonCards$ | async as comparisonCards"
    class="flex flex-col items-center px-4 pb-4 w-full space-y-6"
  >
    <div *ngFor="let card of comparisonCards" class="w-full">
      <aux-ag-patient-driver-comparison-card [card]="card" />
    </div>
  </div>
</div>
