import {
  ContractedInvestigatorAmountValues,
  InvestigatorForecastValues,
  PatientVisitsValues,
  SiteContractAveragesValues,
} from './investigator-forecast.types';

export const AVERAGES_SOURCE_INITIAL_VALUES: SiteContractAveragesValues = {
  contractedSites: '',
  totalForecastedPatients: 0,
  averagePatientCost: 0,
  forecastedVisitCosts: 0,
  invoiceables: null,
  otherInvoiceablesPerc: 50,
  overheadInvoiceablesPerc: 50,
  forecastedInvoiceables: 0,
  totalInvestigatorCosts: 0,
  spendToDateAmount: 0,
  remainingAmount: 0,
};

export const CONTRACTED_SOURCE_INITIAL_VALUE: ContractedInvestigatorAmountValues = {
  totalVisitCosts: 0,
  totalInvoiceables: 0,
  invoiceablesPerc: 0,
  totalInvestigatorCosts: 0,
  spendToDateAmount: 0,
  remainingAmount: 0,
  otherInvoiceables: 0,
  overheadInvoiceables: 0,
  visitCostsToDate: 0,
  invoiceablesToDate: 0,
};

export const PATIENT_VISITS_SOURCE_INITIAL_VALUE: PatientVisitsValues = {
  forecastedRemainingVisitCosts: 0,
  invoiceablesPerc: 0,
  forecastedRemainingInvoiceables: 0,
  invoiceablesDistribution: 0,
  forecastedRemainingInvestigatorCosts: 0,
  currentInvoiceablesPercent: 0,

  // discontinued
  totalDiscontinuedPerc: 0,
  currentDiscontinuedAmount: 0,
  currentEnrolledAmount: 0,
  currentDiscontinuedPerc: 0,

  patientRemainingToDiscontinueAmount: 0,
  patientRemainingToDiscontinueAmountLeft: 0,

  averageCompletedPatientCost: 0,
  remainingPatientsToDiscontinuePerc: 0,
  remainingPatientsToDiscontinueCost: 0,
  differentPatientCost: 0,
  totalDiscontinued: 0,

  // Spend to date and remaining section
  visitCostsToDate: 0,
  invoiceablesToDate: 0,
  totalSpendToDate: 0,
  totalForecastedInvestigatorAmountThroughEndOfTrial: 0,
  totalForecastedRemainingInvestigatorCosts: 0,

  avgPatientCost: 0,
  totalInvestigatorCosts: 0,
  spendToDateAmount: 0,
  remainingAmount: 0,
};

export const INITIAL_FORM_VALUES: InvestigatorForecastValues = {
  source: null,
  vendorId: null,
  averages: AVERAGES_SOURCE_INITIAL_VALUES,
  contracted: CONTRACTED_SOURCE_INITIAL_VALUE,
  patientVisits: PATIENT_VISITS_SOURCE_INITIAL_VALUE,
};
