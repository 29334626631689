import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthQuery } from '@models/auth/auth.query';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { Observable } from 'rxjs/internal/Observable';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-nav',
  templateUrl: './trial-insights-nav.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsNavComponent implements OnInit {
  userName = '';

  userOrganization = '';

  path = ROUTING_PATH.TRIAL_INSIGHTS;

  clinicalRouterLink = `/${this.path.INDEX}/${this.path.CLINICAL}`;

  financeRouterLink = `/${this.path.INDEX}/${this.path.FINANCE}`;

  showFinanceButton$: Observable<boolean>;

  showClinOpsButton$: Observable<boolean>;

  constructor(
    private authQuery: AuthQuery,
    private mainQuery: MainQuery,
    private launchDarklyService: LaunchDarklyService,
    private router: Router
  ) {
    this.showFinanceButton$ = this.launchDarklyService.select$((flags) => flags.finance_dashboard);
    this.showClinOpsButton$ = this.launchDarklyService.select$((flags) => flags.clin_ops_dashboard);
    combineLatest([this.showClinOpsButton$, this.showFinanceButton$])
      .pipe(untilDestroyed(this))
      .subscribe(([clin_ops, finance]) => {
        if (!clin_ops && finance) {
          this.router.navigateByUrl(this.financeRouterLink);
        }
        if (clin_ops && !finance) {
          this.router.navigateByUrl(this.clinicalRouterLink);
        }
      });
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  getUserInfo(): void {
    // User header info
    this.authQuery
      .select(['given_name', 'trial_id'])
      .pipe(untilDestroyed(this))
      .subscribe(({ given_name }) => {
        const trial = this.mainQuery.getSelectedTrial();

        this.userName = given_name;
        this.userOrganization = trial?.sponsor_organization.name || '';
      });
  }
}
