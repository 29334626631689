<div class="w-screen max-w-2xl"></div>
<div class="text-xl font-bold mb-4">{{ mode === 'add' ? 'Add' : 'Edit' }} Timeline Milestone</div>

<form [formGroup]="addTimelineMilestoneForm" (ngSubmit)="onSubmit()">
  <div class="grid grid-cols-2 gap-5">
    <div>
      <div class="text-xs">Category</div>
      <ng-select
        class="select select__big"
        formControlName="milestone_category_id"
        auxFormError
        label="Category"
        [clearable]="false"
        [searchable]="false"
      >
        <ng-option
          *ngFor="let milestoneCategory of milestoneCategoryQuery.selectAll() | async"
          [value]="milestoneCategory.id"
        >
          {{ milestoneCategory.name }}
        </ng-option>
      </ng-select>
    </div>

    <aux-input #name label="Milestone Name" formControlName="name" validators="required" />

    <aux-input
      label="Start Date"
      formControlName="contract_start_date"
      placeholder="YYYY-MM-DD"
      validators="required"
      [type]="'date'"
    />

    <aux-input
      label="End Date"
      formControlName="contract_end_date"
      placeholder="YYYY-MM-DD"
      validators="required"
      [type]="'date'"
    />

    <div>
      <div class="text-xs">Track From</div>
      <ng-select
        class="select select__big"
        formControlName="track_from_milestone_id"
        auxFormError
        label="Track From"
        [clearable]="true"
        [searchable]="true"
        [appendTo]="'body'"
      >
        <ng-option *ngFor="let milestone of trackFromMilestones$ | async" [value]="milestone.id">
          {{ milestone.name }}
        </ng-option>
      </ng-select>
    </div>

    <aux-input label="Description" formControlName="description" />
  </div>

  <div class="flex space-x-4 mt-4">
    <button class="text-sm font-normal btn btn--blue w-32" type="submit">
      {{ mode === 'add' ? 'Add' : 'Update' }}
    </button>
    <button
      class="text-sm font-normal aux-link focus:outline-none"
      type="button"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</form>
