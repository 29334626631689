<section
  class="w-full flex border-l-4 border-aux-blue"
  [class]="className"
  [ngClass]="{ 'bg-aux-gray-light': !locked, 'bg-aux-blue-dark': locked }"
>
  <ng-container *ngIf="(isLoading$ | async) === false">
    <div class="flex flex-row items-center justify-center border border-aux-gray-dark">
      <aux-icon
        name="CircleArrowLeft"
        class="m-1.5"
        [ngClass]="{ 'text-aux-blue': !locked, 'text-white': locked }"
      />
      <a
        style="margin-left: -1rem"
        class="aux-link text-sm text-center"
        [ngClass]="{ 'text-aux-blue': !locked, 'text-white': locked }"
        [routerLink]="[quarterCloseChecklistLink]"
      >
        Close Checklist
      </a>
    </div>

    <div class="grid grid-cols-1 w-full">
      <aux-workflow-panel-general-item
        [workflowName]="workflowName"
        [isAdminUser]="isAdminUser"
        [headerTextCallback]="getHeaderFn"
        [auditTextCallback]="getTitleFn"
        [lockAvailable]="!processing"
        [hasUserPermissions]="hasUserPermissions"
      />
    </div>
  </ng-container>

  <div *ngIf="isLoading$ | async" class="border-8 m-auto spinner h-16 w-16"></div>
</section>
