<div id="forecast-table-grid-container" class="relative" [style.height]="gridHeight">
  <div
    *ngIf="LoadingService.loadingCategories(this.rowData) | async"
    id="forecast-table-grid-loading-container"
    class="flex absolute"
    [style.height]="gridHeight"
  >
    <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
  </div>
  <ag-grid-angular
    id="forecast-table"
    class="ag-theme-aux mb-8 tabular-nums w-full"
    style="width: 100%; height: 100%"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [context]="context"
    [getRowId]="getRowId"
    (gridReady)="onGridReady($event)"
    [gridOptions]="gridOptions"
    (viewportChanged)="onWindowScroll()"
    (gridSizeChanged)="gridSizeChanged()"
  />
</div>
