import { ChangeDetectorRef, Component } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import {
  CellClassParams,
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GetRowIdFunc,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ITooltipParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '@components/components.module';
import { TableConstants } from '@constants/table.constants';
import { Utils } from '@services/utils';
import { uomHide$ } from '../column-defs';
import {
  BeInlineCategoryDropdownComponent,
  BeInlineCategoryDropdownParams,
} from './be-inline-category-dropdown.component';
import { ActivitySubType, ActivityType, Currency } from '@services/gql.service';
import { isEqual, set } from 'lodash-es';
import { NgForOf, NgIf } from '@angular/common';
import { IconComponent } from '@components/icon/icon.component';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { firstValueFrom } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import { AgRequiredHeaderComponent } from './ag-required-header.component';
import { AgDeleteCellComponent, AgDeleteCellComponentParams } from './ag-delete-cell.component';
import { AgTooltipComponent } from './ag-tooltip.component';
import { agFormatToNumberValueSetter } from '@shared/utils/ag-value-setter.utils';
import { WarningModalComponent, WarningModalParams } from './warning-modal.component';
import { decimalDivide } from '@shared/utils';
import { AgGridAngular } from '@ag-grid-community/angular';

export const BeActivityTypesObj = { ...ActivityType, ...ActivitySubType };
export type BeActivityTypes = keyof typeof BeActivityTypesObj;

export type BeActivitiesModalRowData = {
  activity_name: string;
  activity_type: BeActivityTypes | '';
  display_label: string;
  uom: string;
  unit_num: number | string;
  unit_cost: number | string;
  category: string;
  id: string;
  isGenerated: boolean;
  attributes: Record<string, string>;
  changed: boolean;
  currencyName: string;
  deleted: boolean;
  deletable: boolean;
  isPercentage: boolean;
};

export interface BeActivitiesModalParams {
  rows: BeActivitiesModalRowData[];
  vendor: {
    id: string;
    label: string;
  };
  categories: {
    id: string;
    name: string;
    type: BeActivityTypes;
    indent: number;
  }[];
  editMode: boolean;
  currencyName: string;
  usedCategories: Record<BeActivityTypes, boolean>;
}

const EMPTY_TABLE_TOOLTIP =
  'To continue, include an Activity with Units, Unit Cost, and location in budget';
const ERROR_TABLE_TOOLTIP = 'Please resolve errors to continue';

@Component({
  standalone: true,
  template: `
    <div class="hidden absolute sm:block top-0 right-0 pr-4 pt-4">
      <button
        id="closeButton"
        type="button"
        class="bg-white flex rounded-md text-gray-400 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        (click)="close('close', false)"
      >
        <span class="sr-only">Close</span>
        <aux-icon name="X" />
      </button>
    </div>

    <div class="text-2xl font-bold absolute pt-4 top-0">Manage Activities</div>
    <div class="w-[1200px] be-inline-container my-[32px] relative">
      <div class="flex items-center justify-between mb-[32px]">
        <div>
          <ng-select
            [(ngModel)]="selectedVendor"
            bindLabel="label"
            bindValue="id"
            [disabled]="true"
            [items]="vendors"
          />
        </div>
        <div>
          <aux-button
            variant="secondary"
            (clickEmit)="addNewRow()"
            icon="Plus"
            label="Add New Activity"
            classList="!border-none h-[35px]"
          />
        </div>
      </div>
      <ag-grid-angular
        style="height: 500px"
        class="ag-theme-aux tabular-nums be-inline-table"
        [rowData]="filteredRowData"
        (gridReady)="onGridReady($event)"
        [gridOptions]="gridOptions"
        [getRowId]="getRowId"
        (cellEditingStopped)="onCellEdit($event)"
        (cellValueChanged)="onCellChanged($event)"
      />
    </div>
    <div class="flex items-center justify-between">
      <div>
        <aux-button
          label="Cancel"
          variant="hyperlink"
          classList="no-underline text-aux-gray-darkest"
          (clickEmit)="close('close', false)"
        />
      </div>
      <div>
        <aux-button
          class="mr-[16px]"
          label="Save & Exit"
          variant="secondary"
          (clickEmit)="close('save')"
          [disabled]="saveButtonDisabled"
          [auxTooltip]="saveButtonDisabled ? saveButtonTooltip : ''"
        />
        <aux-button
          label="Edit Attributes"
          variant="primary"
          (clickEmit)="close('edit')"
          [disabled]="editButtonDisabled"
          [auxTooltip]="editButtonDisabled ? editButtonTooltip : ''"
        />
      </div>
    </div>
  `,
  imports: [
    NgSelectModule,
    FormsModule,
    ComponentsModule,
    NgForOf,
    IconComponent,
    NgIf,
    AgGridAngular,
  ],
  styles: [
    `
      ::ng-deep .be-inline-container .ng-select {
        @apply w-[254px];
      }
      ::ng-deep .be-inline-container .ng-select.ng-select-disabled > .ng-select-container {
        @apply bg-aux-gray-light-2 text-aux-gray-dark-1;
      }
      ::ng-deep .be-inline-container .ng-select .ng-arrow {
        @apply border-t-aux-gray-darkest;
      }
      ::ng-deep .be-inline-container .two-row-header {
        @apply absolute -top-[32px] h-[64px];
      }
      ::ng-deep .be-inline-container .two-row-header:first-child {
        @apply rounded-tl-[8px] border-l-[1px];
      }
      ::ng-deep .be-inline-container .two-row-header:last-child {
        @apply rounded-tr-[8px] border-r-[1px];
      }
      ::ng-deep .be-inline-container .ag-cell.error-outline {
        @apply !border-aux-error !border-[1.5px];
      }
      ::ng-deep .be-inline-container .ag-cell:first-child {
        @apply !border-l-[1px];
      }
      ::ng-deep .be-inline-container .ag-cell:last-child {
        @apply !border-r-[1px];
      }
      ::ng-deep .be-inline-container .ag-row-last .ag-cell {
        @apply !border-b-[1px];
      }
      ::ng-deep .be-inline-container .ag-header {
        height: 64px !important;
        min-height: 64px !important;
      }
      ::ng-deep .be-inline-container .ag-header-row,
      ::ng-deep .be-inline-container .ag-header-cell {
        overflow: unset;
      }
      ::ng-deep .be-inline-container .ag-header-group-cell-no-group {
        @apply hidden;
      }
    `,
  ],
})
export class BeActivitiesModalComponent {
  rowData: BeActivitiesModalRowData[];
  filteredRowData: BeActivitiesModalRowData[];

  gridAPI!: GridApi;

  data: BeActivitiesModalParams;

  selectedVendor: string;
  vendors: { id: string; label: string }[];

  columns: (ColDef | ColGroupDef)[];

  gridOptions: GridOptions;

  categories: { id: string; name: string; type: BeActivityTypes; indent: number }[];

  validRows: Record<string, BeActivitiesModalRowData> = {};
  invalidRows: Record<string, BeActivitiesModalRowData> = {};
  deletedRows: Record<string, BeActivitiesModalRowData> = {};

  saveButtonDisabled = true;
  saveButtonTooltip = EMPTY_TABLE_TOOLTIP;

  editButtonDisabled = true;
  editButtonTooltip = EMPTY_TABLE_TOOLTIP;

  disableCanDeactivate = false;

  currencyName: string;

  selectedCategories = {} as Record<BeActivityTypes, boolean>;

  constructor(
    public ref: CustomOverlayRef<
      {
        type: 'close' | 'save' | 'edit';
        rows: BeActivitiesModalRowData[];
      },
      BeActivitiesModalParams
    >,
    private overlayService: OverlayService,
    private cdr: ChangeDetectorRef
  ) {
    if (!this.ref.data) {
      throw new Error("You didn't pass data to BeActivitiesModal");
    }

    this.data = this.ref.data;
    this.rowData = this.data.rows;
    this.categories = this.data.categories;
    this.ref.canDeactivate = this.canDeactivate;
    this.currencyName = this.data.currencyName;
    this.editButtonDisabled = !this.data.editMode;

    // if the rows are empty or less than 5, add empty rows
    if (this.rowData.length < 6 && !this.data.editMode) {
      const rowsToAdd = 5 - this.rowData.length;
      for (let i = 0; i < rowsToAdd; i++) {
        this.rowData.push(this.emptyRow());
      }
    }

    this.rowData.forEach((row) => {
      if (row.changed) {
        this.updateState(row);
      }
    });

    this.filteredRowData = this.rowData.filter((row) => {
      if (row.deleted) {
        this.deletedRows[row.id] = row;
        return false;
      }
      return true;
    });

    this.vendors = [this.data.vendor];
    this.selectedVendor = this.data.vendor.id;

    this.columns = [
      {
        headerClass: 'two-row-header ag-header-align-center',
        headerName: '',
        field: 'action',
        cellRenderer: AgDeleteCellComponent,
        cellRendererParams: <AgDeleteCellComponentParams>{
          onDelete: async (params: AgDeleteCellComponentParams) => {
            const data = params.node.data;
            if (!data) {
              return;
            }
            if (!data.isGenerated) {
              const ref = this.overlayService.open<'cancel' | 'confirm', WarningModalParams>({
                baseComponent: WarningModalComponent,
                data: {
                  header: 'Delete Activity?',
                  message:
                    'Please note that once you delete this activity, it cannot be recovered, and all associated data will be lost. Would you like to proceed?',
                  maxWidth: 455,
                  cancelLabel: 'Go Back',
                  confirmLabel: 'Delete',
                },
              });

              const { data: refData } = await firstValueFrom(ref.afterClosed$);

              if (refData !== 'confirm') {
                return;
              }
              this.deletedRows[data.id] = data;
            }

            delete this.validRows[data.id];
            delete this.invalidRows[data.id];
            switch (data.activity_type) {
              case ActivityType.ACTIVITY_DISCOUNT:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
                delete this.selectedCategories[data.activity_type];
                break;
              default:
                break;
            }
            this.gridAPI.applyTransaction({
              remove: [{ id: data.id }],
            });
            this.updateState();
            this.cdr.markForCheck();
          },
          isDisabled: (params: AgDeleteCellComponentParams) => {
            return params.data?.deletable ? '' : 'Activities with actuals booked cannot be deleted';
          },
        },
        width: 32,
        minWidth: 32,
        maxWidth: 32,
        cellClass: '!p-0 h-full',
        editable: false,
      },
      {
        headerName: 'Category',
        field: 'category',
        headerClass: 'two-row-header ag-header-align-center',
        headerComponent: AgRequiredHeaderComponent,
        cellRenderer: BeInlineCategoryDropdownComponent,
        editable: false,
        cellClass: '!p-0',
        cellRendererParams: <BeInlineCategoryDropdownParams>{
          categories: this.categories,
          usedCategories: () => {
            const ct = {
              ...this.selectedCategories,
              ...Object.entries(this.data.usedCategories).reduce(
                (acc, [key, bool]) => {
                  if (bool) {
                    acc[key as BeActivityTypes] = true;
                  }
                  return acc;
                },
                {} as Record<BeActivityTypes, boolean>
              ),
            };

            return {
              ...ct,
              [ActivityType.ACTIVITY_INVESTIGATOR]:
                ct.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES &&
                ct.ACTIVITY_INVESTIGATOR_PATIENT_VISITS &&
                ct.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES,
            };
          },
          onCategoryChange: (params, id, type) => {
            const data = params.data;
            if (data) {
              this.updateState({ ...data, category: id, activity_type: type });
              switch (type) {
                case ActivityType.ACTIVITY_DISCOUNT:
                  params.node.updateData(<BeActivitiesModalRowData>{
                    ...params.node.data,
                    activity_name: 'Services Discount',
                    unit_num: 1,
                  });
                  this.selectedCategories[ActivityType.ACTIVITY_DISCOUNT] = true;
                  break;
                case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
                  params.node.updateData(<BeActivitiesModalRowData>{
                    ...params.node.data,
                    activity_name: 'Patient Invoiceables',
                  });
                  this.selectedCategories[
                    ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES
                  ] = true;
                  break;
                case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
                  params.node.updateData(<BeActivitiesModalRowData>{
                    ...params.node.data,
                    activity_name: 'Site Invoiceables',
                  });
                  this.selectedCategories[ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES] =
                    true;
                  break;
                case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
                  params.node.updateData(<BeActivitiesModalRowData>{
                    ...params.node.data,
                    activity_name: 'Patient Visits',
                  });
                  this.selectedCategories[ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS] =
                    true;
                  break;
                default:
                  break;
              }
              this.gridAPI.refreshCells({ rowNodes: [params.node] });
            }
          },
        },
        minWidth: 250,
        cellClassRules: {
          'error-outline': (params: CellClassParams<BeActivitiesModalRowData>) =>
            (params.data?.isGenerated ? !!this.invalidRows[params.data.id] : true) && !params.value,
        },
      },
      {
        headerName: 'Activities',
        field: 'activity_name',
        headerClass: 'two-row-header ag-header-align-center',
        headerComponent: AgRequiredHeaderComponent,
        tooltipValueGetter: (params: ITooltipParams<BeActivitiesModalRowData>) => {
          if (params.data) {
            switch (params.data.activity_type) {
              case ActivityType.ACTIVITY_DISCOUNT:
              case ActivityType.ACTIVITY_INVESTIGATOR:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
                return 'Auxilius Product restricts Category and Activity Name for Investigator and Discount.';
              default:
                break;
            }

            return params.data.activity_name;
          }
          return '';
        },
        editable: (params: EditableCallbackParams<BeActivitiesModalRowData>) => {
          switch (params.data?.activity_type) {
            case ActivityType.ACTIVITY_DISCOUNT:
            case ActivityType.ACTIVITY_INVESTIGATOR:
            case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
            case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
            case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
              return false;
            default:
              return true;
          }
        },
        cellClass: '!block truncate text-left',
        cellClassRules: {
          'error-outline': (params: CellClassParams<BeActivitiesModalRowData>) =>
            (params.data?.isGenerated ? !!this.invalidRows[params.data.id] : true) && !params.value,
        },
      },
      {
        headerName: 'Overall Budget',
        headerClass:
          'ag-header-align-center bg-aux-gray-dark gray-dark-header-group aux-black border-0',
        children: [
          {
            headerName: 'Display Label',
            field: 'display_label',
            headerClass: 'ag-header-align-center text-sm',
            width: 120,
            maxWidth: 120,
          },
          {
            headerName: 'Unit of Measure',
            headerClass: 'ag-header-align-center text-sm',
            field: 'uom',
            colId: 'uom',
            tooltipValueGetter: (params: ITooltipParams<BeActivitiesModalRowData>) => {
              if (params.data?.isPercentage) {
                return 'Discount Percentage cannot be updated. Please contact Auxilius if needed.';
              }
              return params.data?.uom || '';
            },
            hide: uomHide$.getValue(),
            cellClass: ['!block', 'truncate', 'text-left'],
            valueFormatter: (params) => params.value || '',
            width: 120,
            maxWidth: 120,
            editable: (params: EditableCallbackParams<BeActivitiesModalRowData>) => {
              return !params.data?.isPercentage;
            },
          },
          {
            headerName: 'Units',
            headerClass: 'ag-header-align-center text-sm',
            headerComponent: AgRequiredHeaderComponent,
            field: 'unit_num',
            tooltipValueGetter: (params: ITooltipParams<BeActivitiesModalRowData>) => {
              if (params.data?.isPercentage) {
                return 'Discount Percentage cannot be updated. Please contact Auxilius if needed.';
              }
              return '';
            },
            valueFormatter: (params) => {
              const resp = Utils.decimalFormatter(params.data?.unit_num);
              return resp === Utils.zeroHyphen && this.isValidNumber(params.value)
                ? `${params.value}`
                : resp;
            },
            editable: (params: EditableCallbackParams<BeActivitiesModalRowData>) => {
              switch (params.data?.activity_type) {
                case ActivityType.ACTIVITY_DISCOUNT:
                  return false;
                default:
                  return true;
              }
            },
            cellClass: ['budget-units', 'text-right'],
            width: 120,
            maxWidth: 120,
            valueParser: (p) => {
              if (p.newValue == '') {
                return '';
              }
              const parsed = parseFloat(p.newValue);
              return Number.isFinite(parsed) ? parsed : p.newValue;
            },
            valueSetter: (params) => {
              params.data.unit_num = params.newValue;
              return true;
            },
            cellClassRules: {
              'error-outline': (params: CellClassParams<BeActivitiesModalRowData>) =>
                (params.data?.isGenerated ? !!this.invalidRows[params.data.id] : true) &&
                !this.isValidNumber(params.value),
            },
          },
          {
            headerName: 'Unit Cost',
            headerClass: 'ag-header-align-center text-sm',
            headerComponent: AgRequiredHeaderComponent,
            field: 'unit_cost',
            cellDataType: 'text',
            tooltipValueGetter: (params: ITooltipParams<BeActivitiesModalRowData>) => {
              if (params.data?.isPercentage) {
                return 'Discount Percentage cannot be updated. Please contact Auxilius if needed.';
              }
              return '';
            },
            valueFormatter: (params: ValueFormatterParams<BeActivitiesModalRowData>) => {
              if (params.data) {
                if (params.data.isPercentage) {
                  return Utils.percentageFormatter(decimalDivide(params.value, 100), {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }

                const resp = Utils.agCurrencyFormatter(
                  params,
                  params.data.currencyName as Currency
                );

                return resp === Utils.zeroHyphen && this.isValidNumber(params.value)
                  ? `${params.value}`
                  : resp;
              }
              return '';
            },
            cellClass: (p) => [`budgetCost${p.data.currencyName}`, 'text-right'],
            width: 120,
            maxWidth: 120,
            valueSetter: (params) =>
              params.newValue === '' || params.newValue == null
                ? set(params.data, 'unit_cost', '')
                : agFormatToNumberValueSetter(params, ['$', ','], 'unit_cost'),
            cellClassRules: {
              'error-outline': (params: CellClassParams<BeActivitiesModalRowData>) => {
                const data = params.data;
                if (data) {
                  if (data.isPercentage) {
                    return false;
                  }

                  const isDiscountInvalid =
                    data.activity_type === ActivityType.ACTIVITY_DISCOUNT
                      ? +params.value >= 0
                      : false;

                  return (
                    (data.isGenerated && !this.isRowEmpty(data)
                      ? !this.isValidNumber(params.value)
                      : false) ||
                    (this.isRowEmpty(data) ? false : !this.isValidNumber(params.value)) ||
                    isDiscountInvalid
                  );
                }
                return false;
              },
            },
            editable: (params: EditableCallbackParams<BeActivitiesModalRowData>) => {
              return !params.data?.isPercentage;
            },
          },
          {
            headerName: 'Total Cost',
            headerClass: 'ag-header-align-center text-sm',
            headerComponent: AgRequiredHeaderComponent,
            cellStyle: {
              'text-overflow': 'unset',
            },
            valueGetter: (params: ValueGetterParams<BeActivitiesModalRowData>) => {
              let val;
              if (params.data) {
                if (
                  this.isValidNumber(params.data.unit_cost) &&
                  this.isValidNumber(params.data.unit_num)
                ) {
                  val = params.data.unit_num * params.data.unit_cost;
                }
              }
              return val ?? '';
            },
            valueFormatter: (params: ValueFormatterParams<BeActivitiesModalRowData>) => {
              if (params.data) {
                if (params.data.isPercentage) {
                  return Utils.percentageFormatter(decimalDivide(params.value, 100), {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  });
                }

                const resp = Utils.agCurrencyFormatter(
                  params,
                  params.data.currencyName as Currency
                );

                return resp === Utils.zeroHyphen && this.isValidNumber(params.value)
                  ? `${params.value}`
                  : resp;
              }
              return '';
            },
            cellClass: (p) => [
              `budgetCost${p.data.currencyName}`,
              'text-right',
              TableConstants.STYLE_CLASSES.NOT_EDITABLE_CELL,
            ],
            width: 120,
            maxWidth: 120,
            cellClassRules: {
              'error-outline': (params: CellClassParams<BeActivitiesModalRowData>) => {
                const data = params.data;
                if (data) {
                  if (data.isPercentage) {
                    return false;
                  }

                  const isDiscountInvalid =
                    data.activity_type === ActivityType.ACTIVITY_DISCOUNT
                      ? +params.value >= 0
                      : false;

                  return (
                    (data.isGenerated && !this.isRowEmpty(data)
                      ? !this.isValidNumber(params.value)
                      : false) ||
                    (this.isRowEmpty(data) ? false : !this.isValidNumber(params.value)) ||
                    isDiscountInvalid
                  );
                }
                return false;
              },
            },
            editable: false,
          },
        ],
      },
    ];

    this.gridOptions = <GridOptions>{
      ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
      tooltipShowDelay: 0,
      headerHeight: 32,
      stopEditingWhenCellsLoseFocus: true,
      fillOperation: (params) => {
        let index = params.rowNode.rowIndex;
        if (params.column.getColId() === 'category' && index != null) {
          switch (params.direction) {
            case 'up':
              index += 1;
              break;
            case 'down':
              index -= 1;
              break;
            case 'left':
            case 'right':
              return false;
          }
          const node = params.api.getDisplayedRowAtIndex(index);
          if (node && node.data.category) {
            switch (node.data.activity_type) {
              case ActivityType.ACTIVITY_DISCOUNT:
              case ActivityType.ACTIVITY_INVESTIGATOR:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
              case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
                return false;
              default:
                break;
            }
            const data = {
              ...params.rowNode.data,
              category: node.data.category,
              activity_type: node.data.activity_type,
            };
            params.rowNode.updateData(data);
            this.updateState(data);
            this.gridAPI.refreshCells({ rowNodes: [params.rowNode] });
          }
        }
        return false;
      },
      defaultColDef: {
        editable: true,
        tooltipComponent: AgTooltipComponent,
        suppressMenu: true,
      },
      enableFillHandle: true,
      enableRangeSelection: true,
      columnDefs: this.columns,
    };
  }

  canDeactivate = async () => {
    return this.disableCanDeactivate ? true : await this.openUnsavedChanges();
  };

  isRowValid(data: BeActivitiesModalRowData) {
    return (
      data.category &&
      data.activity_name &&
      this.isValidNumber(data.unit_cost) &&
      this.isValidNumber(data.unit_num)
    );
  }

  isValidNumber(n: unknown): n is number {
    return Number.isFinite(parseFloat(<string>n));
  }

  onGridReady(event: GridReadyEvent) {
    this.gridAPI = event.api;
    this.gridAPI.sizeColumnsToFit();
  }

  addNewRow() {
    this.gridAPI.applyTransaction({
      add: [this.emptyRow()],
    });
    this.gridAPI.sizeColumnsToFit();
  }

  emptyRow = () =>
    <BeActivitiesModalRowData>{
      activity_no: '',
      activity_name: '',
      display_label: '',
      uom: '',
      unit_num: '',
      unit_cost: '',
      category: '',
      id: Utils.uuid(),
      isGenerated: true,
      activity_type: '',
      attributes: {},
      changed: false,
      currencyName: this.currencyName,
      deleted: false,
      deletable: true,
      isPercentage: false,
    };

  getRowId: GetRowIdFunc<BeActivitiesModalRowData> = (d) => {
    return d.data.id;
  };

  onCellEdit(
    params: Pick<
      CellEditingStoppedEvent<BeActivitiesModalRowData>,
      'newValue' | 'oldValue' | 'data' | 'node'
    >
  ) {
    if (params.newValue !== params.oldValue) {
      // this.changedRows.add(params.data.id);
      this.updateState(params.data);
      this.gridAPI.refreshCells({ rowNodes: [params.node] });
    }
  }

  updateState(data?: BeActivitiesModalRowData) {
    if (data) {
      const isEmpty = this.isRowEmpty(data);
      let valid = this.isRowValid(data);
      const discountValid =
        data.activity_type === ActivityType.ACTIVITY_DISCOUNT && !data.isPercentage
          ? this.isValidNumber(data.unit_cost) && +data.unit_cost < 0
          : true;
      if (!discountValid) {
        valid = false;
      }

      if (valid) {
        this.validRows = {
          ...this.validRows,
          [data.id]: data,
        };
        delete this.invalidRows[data.id];
      } else {
        if (!isEmpty) {
          this.invalidRows = {
            ...this.invalidRows,
            [data.id]: data,
          };
        }
        delete this.validRows[data.id];
      }
    }

    const isThereAnyValidRow = !!Object.keys(this.validRows).length;
    const isThereAnyInvalidRow = !!Object.keys(this.invalidRows).length;
    const isThereAnyDeletedRow = !!Object.keys(this.deletedRows).length;
    const isThereAnyRow = this.gridAPI ? this.gridAPI.getDisplayedRowCount() > 0 : true;

    if (isThereAnyInvalidRow) {
      this.saveButtonDisabled = true;
      this.editButtonDisabled = true;
      this.saveButtonTooltip = ERROR_TABLE_TOOLTIP;
      this.editButtonTooltip = ERROR_TABLE_TOOLTIP;
      return;
    }

    this.saveButtonDisabled = false;
    this.editButtonDisabled = false;
    this.saveButtonTooltip = EMPTY_TABLE_TOOLTIP;
    this.editButtonTooltip = EMPTY_TABLE_TOOLTIP;

    if (!isThereAnyValidRow) {
      this.saveButtonDisabled = !isThereAnyDeletedRow;
      this.editButtonDisabled = !this.data.editMode;
    }

    if (!isThereAnyRow) {
      this.editButtonDisabled = true;
    }
  }

  isRowEmpty(data: BeActivitiesModalRowData) {
    return isEqual({ ...data, id: '', isGenerated: true }, { ...this.emptyRow(), id: '' });
  }

  getRowData() {
    const ids = new Set(Object.keys(this.validRows));
    const deletedIds = new Set(Object.keys(this.deletedRows));

    const rows: BeActivitiesModalRowData[] = [];
    this.filteredRowData.forEach((row) => {
      if (!row.isGenerated || (row.isGenerated && row.changed)) {
        const changed = !!this.validRows[row.id];
        const deleted = !!this.deletedRows[row.id];
        ids.delete(row.id);
        deletedIds.delete(row.id);
        rows.push({
          ...row,
          ...this.validRows[row.id],
          changed,
          deleted,
        });
      }
    });
    ids.forEach((id) => {
      rows.push({
        ...this.validRows[id],
        changed: true,
      });
    });
    deletedIds.forEach((id) => {
      rows.push({
        ...this.deletedRows[id],
        deleted: true,
      });
    });

    return rows;
  }

  close(type: 'close' | 'edit' | 'save', force = true) {
    this.disableCanDeactivate = force;
    this.ref.close({
      rows: this.getRowData(),
      type,
    });
  }

  async openUnsavedChanges() {
    if (
      Object.keys(this.invalidRows).length ||
      Object.keys(this.validRows).length ||
      Object.keys(this.deletedRows).length
    ) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event?.data;
    }
    return true;
  }

  onCellChanged(event: CellValueChangedEvent<BeActivitiesModalRowData>) {
    this.onCellEdit(event);
  }
}
