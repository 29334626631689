import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlComponent } from '../base-form-control';

@Component({
  selector: 'aux-toggle',
  templateUrl: './toggle.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent extends BaseFormControlComponent<boolean | null> {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter();

  @Input() ngModelOptions?: object;

  @Input() disabled = false;

  @Input() set value(value: boolean | null) {
    if (this.fc.value !== value) {
      this.change.emit(value);
    }

    this.fc.setValue(value);
  }

  get value() {
    return !!this.fc.value;
  }

  toggle() {
    this.fc.setValue(!this.fc.value);
    this.onChange(this.fc.value);

    this.change.emit(this.fc.value);
  }
}
