import { Injectable } from '@angular/core';
import { MainQuery } from '../layouts/main-layout/state/main.query';
import { ApiService } from './api.service';
import { GqlService, Document, UserAuditLog, PatientVisitSchedule } from './gql.service';
import {
  DataStreamAnalytics,
  ServerSideDatasource,
  ServerSideFilterInfo,
  applyFilterTransformationsToFormGroup,
} from '@shared/utils';
import { FormGroup } from '@angular/forms';
import { GridApi } from '@ag-grid-community/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatasourceService {
  constructor(
    private mainQuery: MainQuery,
    private apiService: ApiService,
    private gqlService: GqlService
  ) {}

  private async retrieveAnalytics<TData = Record<string, unknown>>(
    endpoint: string,
    input?: Record<string, unknown>
  ): Promise<DataStreamAnalytics<TData>> {
    try {
      if (!input || typeof input !== 'object') {
        input = {};
      }
      const sniffDataStreamResponse = await firstValueFrom(
        this.gqlService.sniffDataStream$(endpoint, JSON.stringify(input))
      );
      if (
        sniffDataStreamResponse.data?.aggregation_json &&
        Number.isInteger(sniffDataStreamResponse.data.total_rows) &&
        sniffDataStreamResponse.data.total_rows >= 0
      ) {
        const aggregation = JSON.parse(sniffDataStreamResponse.data.aggregation_json);
        if (aggregation && typeof aggregation === 'object') {
          return {
            total_rows: sniffDataStreamResponse.data.total_rows,
            aggregation,
          };
        } else {
          console.error('Invalid aggregation!');
        }
      } else {
        console.error(
          'Failed to retrieve analytics',
          endpoint,
          input,
          JSON.stringify(sniffDataStreamResponse, undefined, 2)
        );
      }
    } catch (err) {
      console.error('An error occurred while retrieving analytics', endpoint, input, err);
    }
    return {
      total_rows: 0,
      aggregation: {} as TData,
    };
  }

  /* DOCUMENT */
  private readonly _documentEndpoint = 'FetchDocuments';

  private readonly _documentDatasource = new ServerSideDatasource<Document>({
    endpoint: this._documentEndpoint,
    fetchFunction: (input) => firstValueFrom(this.gqlService.fetchDocumentsDataStream$(input)),
    mainQuery: this.mainQuery,
    apiService: this.apiService,
    gqlService: this.gqlService,
  });

  get documentDatasource() {
    return this._documentDatasource;
  }

  /* USER AUDIT LOG */
  private readonly _userAuditLogEndpoint = 'FetchUserAuditLogs';

  private readonly _userAuditLogDatasource = new ServerSideDatasource<UserAuditLog>({
    endpoint: this._userAuditLogEndpoint,
    fetchFunction: (input) => firstValueFrom(this.gqlService.fetchUserAuditLogsDataStream$(input)),
    mainQuery: this.mainQuery,
    apiService: this.apiService,
    gqlService: this.gqlService,
  });

  get userAuditLogDatasource() {
    return this._userAuditLogDatasource;
  }

  /* PATIENT VISIT SCHEDULE */
  private readonly _patientVisitScheduleEndpoint = 'FetchPatientVisitSchedules';

  private readonly _patientVisitScheduleDatasource = new ServerSideDatasource<PatientVisitSchedule>(
    {
      endpoint: this._patientVisitScheduleEndpoint,
      fetchFunction: (input) =>
        firstValueFrom(this.gqlService.fetchPatientVisitSchedulesDataStream$(input)),
      mainQuery: this.mainQuery,
      apiService: this.apiService,
      gqlService: this.gqlService,
    }
  );

  get patientVisitScheduleDatasource() {
    return this._patientVisitScheduleDatasource;
  }

  retrievePatientVisitScheduleAnalytics<TInput = Record<string, unknown>>(input?: TInput) {
    return this.retrieveAnalytics<PatientVisitSchedule>(
      this._patientVisitScheduleEndpoint,
      input || {}
    );
  }

  /* helpers */

  /**
   * Prepares and returns an observable derived from the "formGroup.valueChanges" observable. It also returns the
   * current value of the form group. Both the current form value and the values that will be signalled by the
   * returned observable shall reflect the effect of the transformations applied for the corresponding input controls.
   */
  applyTransformations<T>(formGroup: FormGroup | unknown, filters: Array<ServerSideFilterInfo<T>>) {
    return applyFilterTransformationsToFormGroup(formGroup, filters);
  }

  /**
   * Retrieves the current sort model for the grid to which the gridApi is attached.
   */
  getCurrentSortModel<T>(
    gridApi: GridApi | null | undefined
  ): { column: keyof T; descending: boolean }[] {
    const result: Array<{ column: keyof T; descending: boolean }> = [];

    if (gridApi instanceof GridApi) {
      const sortedColumnStates = gridApi
        .getColumnState()
        .filter((cs) => cs.sort && Number.isInteger(cs.sortIndex));

      sortedColumnStates.sort((a, b) => (a.sortIndex || 0) - (b.sortIndex || 0));
      for (const columnState of sortedColumnStates) {
        const col = gridApi.getColumn(columnState.colId);
        if (col) {
          const columnState = col.getColDef();
          const columnName = columnState.colId || columnState.field;
          const column = columnState.colId ? gridApi.getColumn(columnState.colId) : '';

          if (!column) {
            console.warn(`Column ${columnName} is missing colId to apply sorting`);
          } else {
            result.push({
              column: (columnName || '') as keyof T,
              descending: column.isSortDescending(),
            });
          }
        }
      }
    }

    return result;
  }
}
