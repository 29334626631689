<div class="flex" [auxTooltip]="!!snapshotDisabled ? tooltip : ''">
  <aux-compare-dropdown-trial-insights
    class="mr-1.5"
    [snapshotFormControl]="snapshotFormControl"
    [snapshotList]="snapshotList"
    [snapshotValue]="snapshotValue"
    [snapshotDisabled]="!!snapshotDisabled"
    [snapshotShowEdit]="snapshotShowEdit"
    [snapshotShowDelete]="snapshotShowDelete"
    [snapshotLoading]="snapshotLoading"
    [snapshotChangeFn]="snapshotChangeFn"
    [snapshotRefreshFn]="snapshotRefreshFn"
  />
</div>
