import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'aux-risk-alert-dialog',
  templateUrl: './risk-alert-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskAlertDialogComponent {
  averageOptions = ['1 Week', '2 Weeks', '3 Weeks'];

  siteVisitOptions = ['1 Month', '2 Months', '3 Months'];

  lessOptions = ['75%', '80%', '85%'];

  moreOptions = ['75%', '80%', '85%'];
}
