import { CustomOverlayRef } from './../overlay/custom-overlay-ref';
import { trigger, transition, useAnimation } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { enter, leave } from '@animations/enterLeave';

@Component({
  selector: 'aux-field-notification',
  templateUrl: './field-notification.component.html',
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      ::ng-deep .bubble-box {
        position: relative;
        transform: translateX(-50%);
      }
      ::ng-deep .bubble-extension:after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #236262;
        border-top: 0;
        margin-left: -10px;
        margin-top: -10px;
      }
    `,
  ],
  animations: [
    trigger('enterLeave', [
      transition(':enter', [useAnimation(enter)]),
      transition(':leave', [useAnimation(leave)]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldNotificationComponent {
  constructor(public ref: CustomOverlayRef<null, { message: string }>) {}
}
