import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OrganizationQuery } from '@models/organization/organization.query';
import { TrialsQuery } from '@models/trials/trials.query';
import { ApiService } from '@services/api.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';

import { ChangeOrderStore, ChangeOrderState } from './change-order.store';

@Injectable({ providedIn: 'root' })
export class ChangeOrderQuery extends QueryEntity<ChangeOrderState> {
  constructor(
    protected store: ChangeOrderStore,
    private organizationQuery: OrganizationQuery,
    private mainQuery: MainQuery,
    private trialQuery: TrialsQuery,
    private apiService: ApiService
  ) {
    super(store);
  }

  async downloadCO(
    id: string
  ): Promise<{ success: boolean; data: string | null; errors: string[] }> {
    const emptyReturn = {
      success: false,
      data: null,
      errors: ['Unexpected Error!'],
    };
    const co = this.getEntity(id);
    if (!co) {
      return emptyReturn;
    }

    const org = this.organizationQuery.getEntity(co.organization_id);
    if (!org) {
      return emptyReturn;
    }

    const currentTrial = this.mainQuery.getSelectedTrial();

    if (!currentTrial) {
      return emptyReturn;
    }
    const { success, data, errors } = await this.apiService.getS3ZipFile(
      this.getFilePath(co.organization_id, co.id)
    );
    if (success && data) {
      // [insert trial name]_[insert vendor name]_Change Order [insert Change Order #]_[insert date created YYYY.MM.DD]
      const fileName =
        `${currentTrial.short_name}_${org.name}_Change_Order_` +
        `${co.change_order_no}_${(co.create_date || '').slice(0, 10)}`;
      await this.apiService.downloadZipOrFile(data, fileName);
    }

    return { success, data, errors };
  }

  getFilePath(org_id: string, co_id: string) {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/vendors/${org_id}/changeorders/${co_id}/`;
  }
}
