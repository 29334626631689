import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { ApiService, FileMetadata } from '@services/api.service';
import { AuthQuery } from '@models/auth/auth.query';
import { OverlayService } from '@services/overlay.service';
import { DocumentLibraryService } from 'src/app/pages/documents/document-library.service';
import { FileStore } from './file.store';
import { File } from './file.model';
import { FileQuery } from './file.query';
import { batchPromises } from '@shared/utils';
import { CreateDocumentInput, DocumentType } from '@services/gql.service';

@Injectable()
export class FileService {
  constructor(
    private fileStore: FileStore,
    private fileQuery: FileQuery,
    private apiService: ApiService,
    private authQuery: AuthQuery,
    private documentLibraryService: DocumentLibraryService,
    private overlayService: OverlayService
  ) {}

  async fetchFiles(
    path?: string,
    document_entity_id?: string,
    document_entity_type_id?: string,
    document_type_id?: string,
    site_id?: string
  ) {
    this.fileStore.setLoading(true);
    const resp = await this.apiService.getFilesByFilters(
      path,
      document_entity_id,
      document_entity_type_id,
      document_type_id,
      undefined,
      site_id
    );
    const fileArr = resp.map((x) => ({
      ...x,
      fileName: x.name,
      uploaded: true,
      // todo remove this now that key comes as bucket key
      key: x.bucket_key,
    }));

    this.fileStore.set(fileArr as unknown as File[]);
    this.fileStore.setLoading(false);
  }

  add(files: FileList, path: string, entityIdS3Uploading?: boolean) {
    for (let i = 0; i < files.length; i++) {
      const rawFile = files.item(i);

      if (rawFile) {
        const id = uuid();
        const file: File = {
          uploaded: false,
          eTag: id,
          id,
          ...this.generateFileDynamicFields(!!entityIdS3Uploading, path, rawFile.name),
          fileName: rawFile.name,
          lastModified: rawFile.lastModified,
          size: rawFile.size,
          rawFile,
          type: rawFile.type,
        };

        this.fileStore.add(file);
      }
    }
  }

  private generateFileDynamicFields(entityIdS3Uploading: boolean, path: string, name: string) {
    if (entityIdS3Uploading) {
      const entity_id = uuid();
      return {
        entity_id,
        bucket_key: `${path}/${entity_id}/${name}`,
      };
    }

    return {
      bucket_key: `${path}${new Date().toISOString()}_${name}`,
    };
  }

  async removeFromStore(file: File) {
    this.fileStore.remove(file.id);
  }

  async remove(file: File) {
    if (file.uploaded) {
      const resp = await this.documentLibraryService.removeDocument(file.id);
      if (resp) {
        this.fileStore.remove(file.id);
      }
      return resp;
    }

    this.fileStore.remove(file.id);
    return true;
  }

  removeFiles = async (files: File[]) => {
    const promises = files.map((file) => this.remove(file));

    return batchPromises(promises, (p) => p);
  };

  async removeFromS3(file: File) {
    if (file.uploaded) {
      const resp = await this.apiService.removeFile(file.id);
      if (resp) {
        this.fileStore.remove(file.id);
      }
      return resp;
    }

    this.fileStore.remove(file.id);
    return true;
  }

  uploadFile(
    file: File,
    metadata: FileMetadata = {},
    insertDocument = false,
    overrideFilePath?: string,
    is_metadata_editable = false
  ): Promise<boolean> {
    // this should be default metadata
    const uploadedBy = this.authQuery.getValue().sub;

    const bucket_key = overrideFilePath
      ? `${overrideFilePath}${new Date().toISOString()}_${file.fileName}`
      : file.bucket_key;

    return this.apiService
      .uploadFile(bucket_key, file.rawFile as globalThis.File, {
        ...{ uploadedBy },
        ...metadata,
      })
      .then((x) => {
        if (x) {
          this.fileStore.update(file.id, { ...file, uploaded: true });

          if (insertDocument) {
            this.documentLibraryService.uploadDocument(
              file,
              metadata,
              bucket_key,
              is_metadata_editable
            );
          }
        }
        return x;
      });
  }

  async uploadFiles(
    metadata: FileMetadata = {},
    showSuccess = false,
    insertDocument = false,
    overrideFilePath?: string,
    filterLocalFilesByUploadedFlag = true,
    removeFilesOnPartialFailure = false,
    is_metadata_editable = false
  ) {
    const promises: Promise<boolean>[] = [];

    const files = this.fileQuery.getAll({
      filterBy: (entity) =>
        (filterLocalFilesByUploadedFlag ? !entity.uploaded : true) && !!entity.rawFile,
    });

    files.forEach((file) => {
      let metadataWithUpdatedDocumentType = metadata;
      if (file.document_type_id) {
        metadataWithUpdatedDocumentType = {
          ...metadata,
          documentType: file.document_type_id,
        };
      }
      promises.push(
        this.uploadFile(
          file,
          metadataWithUpdatedDocumentType,
          insertDocument,
          overrideFilePath,
          is_metadata_editable
        )
      );
    });

    this.fileStore.setLoading(true);
    try {
      const ss = await batchPromises(promises, (p) => p);
      this.fileStore.setLoading(false);
      const result = ss.every((x) => x);
      if (showSuccess && result && ss.length > 0) {
        this.overlayService.success(
          `${ss.length} file${ss.length > 1 ? 's' : ''} uploaded successfully!`
        );
        // api.service consumes error, returns false
      } else if (!result) {
        this.overlayService.error('Error uploading file(s)');
        if (removeFilesOnPartialFailure)
          for (let i = 0; i < ss.length; i++) {
            if (ss[i]) {
              this.removeFromS3(files[i]);
            }
          }
      }
      return result;
      //error condition outside of api failure response on upload
    } catch (error) {
      this.overlayService.error('An error has occurred');
      this.fileStore.setLoading(false);
    }

    return false;
  }

  async batchUploadFiles(
    metadata: FileMetadata[] = [],
    showSuccess = false,
    overrideFilePath?: string,
    filterLocalFilesByUploadedFlag = true,
    removeFilesOnPartialFailure = false
  ) {
    const uploadedBy = this.authQuery.getValue().sub;
    const files = this.fileQuery.getAll({
      filterBy: (entity) =>
        (filterLocalFilesByUploadedFlag ? !entity.uploaded : true) && !!entity.rawFile,
    });

    let createDocumentsInputs: CreateDocumentInput[] = [];
    files.forEach((file) => {
      const inputs: CreateDocumentInput[] = [];
      metadata.forEach((x) => {
        inputs.push({
          bucket_key: overrideFilePath
            ? `${overrideFilePath}${new Date().toISOString()}_${file.fileName}`
            : this.documentLibraryService.buildS3Path(file.bucket_key),
          name: file.fileName,
          document_type_id: DocumentType[x.documentType as keyof typeof DocumentType] || null,
          is_metadata_editable: false,
          site_id: x.site || null,
          vendor_id: x.vendor || null,
          entity_id: x.entity_id || null,
          entity_type_id: x.entity_type_id || null,
          target_date: x.target_date || null,
        });
      });
      createDocumentsInputs = createDocumentsInputs.concat(inputs);
    });

    const s3Promises: Promise<boolean>[] = files.map((file) =>
      this.apiService.uploadFile(
        createDocumentsInputs.find((input) => input.name === file.fileName)?.bucket_key || '',
        file.rawFile as globalThis.File,
        {
          ...{ uploadedBy },
          documentType: metadata[0].documentType,
          entity_type_id: metadata[0].entity_type_id,
          target_date: metadata[0].target_date,
          vendor: metadata[0].vendor,
        }
      )
    );

    this.fileStore.setLoading(true);
    try {
      const ss = await batchPromises(s3Promises, (p) => p).then((x) => {
        if (x) {
          this.documentLibraryService.batchUploadDocuments(createDocumentsInputs);
        }
        return x;
      });
      this.fileStore.setLoading(false);
      const result = ss.every((x) => x);
      if (showSuccess && result && ss.length > 0) {
        this.overlayService.success(
          `${ss.length} file${ss.length > 1 ? 's' : ''} uploaded successfully!`
        );
        // api.service consumes error, returns false
      } else if (!result) {
        this.overlayService.error('Error uploading file(s)');
        if (removeFilesOnPartialFailure)
          for (let i = 0; i < ss.length; i++) {
            if (ss[i]) {
              this.removeFromS3(files[i]);
            }
          }
      }
      return result;
      //error condition outside of api failure response on upload
    } catch (error) {
      this.overlayService.error('An error has occurred');
      this.fileStore.setLoading(false);
    }

    return false;
  }
}
