import { Subject } from 'rxjs';

import { OverlayRef } from '@angular/cdk/overlay';

import { TemplateRef, Type } from '@angular/core';

export interface OverlayCloseEvent<R> {
  type: 'backdropClick' | 'close';
  data: R | null;
}

// R = Response Data Type, T = Data passed to Modal Type
export class CustomOverlayRef<R = unknown, T = unknown> {
  afterClosed$: Subject<OverlayCloseEvent<R>> = new Subject<OverlayCloseEvent<R>>();

  constructor(
    public overlay: OverlayRef,
    public closeButton: boolean,
    public content: string | TemplateRef<unknown> | Type<unknown> | undefined,
    public data: T | null // pass data to modal i.e. FormData
  ) {
    overlay.backdropClick().subscribe(() => this._close('backdropClick'));
  }

  close(data?: R) {
    this._close('close', data);
  }

  canDeactivate: () => boolean | Promise<boolean> = () => true;

  private async _close(type: 'backdropClick' | 'close', data: R | null = null) {
    if (await this.canDeactivate()) {
      this.overlay.dispose();
      this.afterClosed$.next({
        type,
        data,
      });

      this.afterClosed$.complete();
    }
  }
}
