import { Injectable } from '@angular/core';
import {
  CreatePatientProtocolInput,
  CreatePatientProtocolVersionInput,
  GqlService,
  PatientProtocolType,
  UpdatePatientProtocolVersionInput,
  removePatientProtocolMutation,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';

import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { map, switchMap, tap } from 'rxjs/operators';
import { PatientProtocolModel, PatientProtocolStore } from './patient-protocol.store';
import { batchPromises } from '@shared/utils';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PatientProtocolService {
  constructor(
    private patientProtocolStore: PatientProtocolStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  get(
    patient_protocol_types: Array<PatientProtocolType> = [],
    group_id: string | null = null,
    should_get_all = false,
    protocolVersion = '',
    include_invoiceables = false
  ) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.patientProtocolStore.setLoading(true);
        this.patientProtocolStore.remove(() => true);

        return this.gqlService.listPatientProtocols$(patient_protocol_types, protocolVersion).pipe(
          tap(({ success, data }) => {
            if (success && data) {
              if (should_get_all) {
                this.patientProtocolStore.set(data);
              } else {
                const patientProtocols = group_id
                  ? data.filter(
                      (x) =>
                        x.patient_group_id === group_id ||
                        (include_invoiceables &&
                          x.patient_protocol_type !==
                            PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT)
                    )
                  : data.filter((x) => !x.patient_group_id);

                this.patientProtocolStore.set(patientProtocols);
              }
            }
            this.patientProtocolStore.setLoading(false);
          })
        );
      })
    );
  }

  getForPatientBudgetTable(
    patient_protocol_version_id: string,
    patient_protocol_types: Array<PatientProtocolType> = []
  ) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.patientProtocolStore.setLoading(true);
        this.patientProtocolStore.remove(() => true);

        return this.gqlService
          .listPatientProtocols$(patient_protocol_types, patient_protocol_version_id)
          .pipe(
            tap(({ success, data }) => {
              if (success && data) {
                this.patientProtocolStore.set(data);
              }
              this.patientProtocolStore.setLoading(false);
            })
          );
      })
    );
  }

  async add(patientProtocol: CreatePatientProtocolInput) {
    const { errors, success, data } = await firstValueFrom(
      this.gqlService.createPatientProtocol$(patientProtocol)
    );
    if (success && data) {
      this.patientProtocolStore.add(data);
    } else {
      this.overlayService.error(errors);
    }

    return {
      success,
      errors,
      data,
    };
  }

  update(id: string, patientProtocol: Partial<PatientProtocolModel>) {
    this.patientProtocolStore.update(id, patientProtocol);
  }

  async upsert(
    upsertData: (CreatePatientProtocolInput & {
      patient_protocol_id: string | null;
    })[]
  ) {
    const allErrors: string[][] = [];

    for (const mil of upsertData) {
      const {
        patient_protocol_id,
        patient_protocol_sub_type_id,
        patient_protocol_frequency,
        patient_group_id,
        name,
        target_date_days_out,
        patient_protocol_version_id,
        target_tolerance_days_out,
        order_by,
      } = mil;
      if (patient_protocol_id) {
        // eslint-disable-next-line no-await-in-loop
        await firstValueFrom(
          this.gqlService
            .updatePatientProtocol$({
              id: patient_protocol_id,
              patient_protocol_sub_type_id,
              patient_protocol_frequency,
              patient_protocol_version_id,
              name,
              target_date_days_out: target_date_days_out,
              target_tolerance_days_out: target_tolerance_days_out || 0,
              order_by,
            })
            .pipe(
              tap(({ success, data, errors }) => {
                if (success && data) {
                  this.patientProtocolStore.update(data.id, data);
                } else {
                  allErrors.push(errors);
                }
              })
            )
        );
      } else {
        // eslint-disable-next-line no-await-in-loop
        await firstValueFrom(
          this.gqlService
            .createPatientProtocol$({
              patient_protocol_sub_type_id,
              patient_protocol_frequency,
              name,
              patient_group_id,
              patient_protocol_version_id,
              target_date_days_out: target_date_days_out,
              target_tolerance_days_out: target_tolerance_days_out || 0,
              order_by,
            })
            .pipe(
              tap(({ success, data, errors }) => {
                if (success && data) {
                  this.patientProtocolStore.add(data);
                } else {
                  allErrors.push(errors);
                }
              })
            )
        );
      }
    }
    if (allErrors.length) {
      this.overlayService.error(allErrors.flat());
    }

    return !!allErrors.length;
  }

  async checkPatientProtocolIsUnique(
    patient_protocol_id: string | null,
    patient_protocol_version_id: string,
    name: string,
    patient_protocol_type: PatientProtocolType,
    patient_group_id: string | null
  ): Promise<boolean> {
    return firstValueFrom(
      this.gqlService
        .checkPatientProtocolIsUnique$({
          patient_protocol_id,
          patient_protocol_version_id,
          patient_protocol_name: name,
          patient_protocol_type,
          patient_group_id,
        })
        .pipe(map(({ success, data }) => (success && data ? data.is_unique : false)))
    );
  }

  async remove(ids: string[]) {
    const proms: Promise<GraphqlResponse<removePatientProtocolMutation>>[] = [];
    const allErrors: string[][] = [];
    ids.forEach((id) => {
      proms.push(
        firstValueFrom(
          this.gqlService.removePatientProtocol$(id).pipe(
            tap(({ success, errors, data }) => {
              if (success && data) {
                this.patientProtocolStore.remove(id);
              } else {
                allErrors.push(errors);
              }
            })
          )
        )
      );
    });

    await batchPromises(proms, (p) => p);

    if (allErrors.length) {
      this.overlayService.error(...allErrors);
    }

    return !!allErrors.length;
  }

  getPatientProtocolVersions() {
    return this.gqlService.listPatientProtocolVersions$().pipe(
      map(({ data }) => {
        return data || [];
      })
    );
  }

  getPatientProtocolSubTypes() {
    // Requesting all types at once by default
    return this.gqlService.listPatientProtocolSubTypes$([]).pipe(
      map(({ data }) => {
        return data || [];
      })
    );
  }

  async createPatientProtocolVersion(version: CreatePatientProtocolVersionInput) {
    return firstValueFrom(this.gqlService.createPatientProtocolVersion$(version));
  }

  async updatePatientProtocolVersion(params: UpdatePatientProtocolVersionInput) {
    const { success } = await firstValueFrom(this.gqlService.updatePatientProtocolVersion$(params));
    if (success) {
      this.overlayService.success(`Protocol Version ${params.name} updated!`);
    }

    return success;
  }

  async removePatientProtocolVersion(id: string, versionName: string) {
    const { success, errors } = await firstValueFrom(
      this.gqlService.removePatientProtocolVersion$(id)
    );

    if (success) {
      this.overlayService.success(`Protocol Version ${versionName} removed!`);
    }

    if (errors.length) {
      this.overlayService.error(errors);
    }

    return success;
  }
}
