import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'aux-currency-checkboxes',
  template: `
    <div
      class="flex items-center"
      *ngVar="{
        isPrimaryCurrencyDisabled: isUSDCurrencyDisabled$ | async,
        isContractCurrencyDisabled: isContractCurrencyDisabled$ | async,
        selectedCurrencies: selectedCurrencies$ | async
      } as obj"
    >
      <div class="flex items-center space-x-4">
        <aux-checkbox
          [checked]="obj.selectedCurrencies.isPrimaryCurrency"
          (customChange)="changeCurrency('isPrimaryCurrency', $event)"
          [disabled]="obj.isPrimaryCurrencyDisabled"
          [auxTooltip]="obj.isPrimaryCurrencyDisabled && currencyValidationTooltip"
          >USD
        </aux-checkbox>
        <aux-checkbox
          [checked]="obj.selectedCurrencies.isContractCurrency"
          (customChange)="changeCurrency('isContractCurrency', $event)"
          [disabled]="obj.isContractCurrencyDisabled"
          [auxTooltip]="obj.isContractCurrencyDisabled && currencyValidationTooltip"
          >Contracted Currency
        </aux-checkbox>
      </div>
      <aux-exchange-link class="ml-4" />
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyCheckboxesComponent implements OnInit {
  @Input() selectedCurrencies$!: BehaviorSubject<{
    isPrimaryCurrency: true;
    isContractCurrency: true;
  }>;

  currencyValidationTooltip = 'At least one checkbox must be checked';

  isUSDCurrencyDisabled$ = of(false);

  isContractCurrencyDisabled$ = of(false);

  ngOnInit(): void {
    this.isUSDCurrencyDisabled$ = this.getCheckboxDisableState(true);
    this.isContractCurrencyDisabled$ = this.getCheckboxDisableState(false);
  }

  changeCurrency(currencyKey: string, checked: boolean) {
    this.selectedCurrencies$.next({
      ...this.selectedCurrencies$.getValue(),
      [currencyKey]: checked,
    });
  }

  private getCheckboxDisableState(isUsdCurrency: boolean) {
    return this.selectedCurrencies$.pipe(
      untilDestroyed(this),
      map(({ isContractCurrency, isPrimaryCurrency }) => {
        if (isUsdCurrency) {
          return isPrimaryCurrency && !isContractCurrency;
        }

        return isContractCurrency && !isPrimaryCurrency;
      })
    );
  }
}
