import { UntilDestroy } from '@ngneat/until-destroy';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ComponentsModule } from '@components/components.module';

@UntilDestroy()
@Component({
  selector: 'aux-notes',
  templateUrl: 'notes.component.html',
  styleUrls: ['notes.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InputComponent, ReactiveFormsModule, NgIf, ComponentsModule, AsyncPipe, NgClass],
})
export class NotesComponent implements OnInit {
  @Input() title = '';

  @Input() notesControl = new FormControl();

  @Input() hasEditPermission = false;

  @Input() disabledTooltipText = '';

  @Output() saveNotes = new EventEmitter();

  @Output() cancelNotes = new EventEmitter();

  @ViewChild('notesTextArea') notesTextArea!: InputComponent;

  editMode = false;

  ngOnInit(): void {
    this.notesControl.disable();
  }

  onSave(): void {
    this.saveNotes.emit();
    this.editMode = false;
  }

  cancel(): void {
    this.cancelNotes.emit();
    this.editMode = false;
  }

  edit(): void {
    this.editMode = true;
    setTimeout(() => {
      const end = this.notesTextArea.input?.nativeElement.value.length || 0;
      this.notesTextArea.input?.nativeElement.focus();
      this.notesTextArea.input?.nativeElement.setSelectionRange(end, end);
    }, 0);
  }
}
