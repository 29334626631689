import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  EntityType,
  EventType,
  GqlService,
  listInvoicesForReconciliationQuery,
  PermissionType,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { ExportType, Utils } from '@services/utils';
import { isEqual, sortBy } from 'lodash-es';
import { distinctUntilChanged, first, map, startWith } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import { WorkflowQuery, WorkflowService } from '../quarter-close/close-quarter-check-list/store';
import { Workflow } from '../quarter-close/close-quarter-check-list/store/workflow.store';
import {
  ChecklistComponentChangeLockFn,
  QuarterCloseChecklistRowComponents,
  QuarterCloseChecklistRowDisabled,
  QuarterCloseChecklistVendorEstimateSummary,
  QuarterCloseChecklistSection,
  ChecklistComponentLockAllFn,
  ChecklistMonthlyDropdownDate,
  QuarterCloseChecklistPermissionList,
  QuarterCloseChecklistRowSections,
} from './models/quarter-close-checklist.model';
import { QuarterCloseChecklistToggleService } from './services/quarter-close-checklist-toggle.service';
import { QuarterCloseChecklistWorkflowService } from './services/quarter-close-checklist-workflow.service';
import { QuarterCloseChecklistService } from './services/quarter-close-checklist.service';
import { QuarterCloseChecklistPeriodCloseService } from './services/quarter-close-checklist-period-close.service';
import { AddVendorEstimateUploadComponent } from '../quarter-close/add-vendor-estimate-upload/add-vendor-estimate-upload.component';
import { Router } from '@angular/router';
import { AddPoReportUploadComponent } from '../quarter-close/add-po-report-upload/add-po-report-upload.component';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { AuthService } from '@models/auth/auth.service';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { QuarterCloseAdjustmentsService } from '../quarter-close-adjustments/quarter-close-adjustments.service';

@UntilDestroy()
@Component({
  selector: 'aux-quarter-close-checklist',
  templateUrl: './quarter-close-checklist.component.html',
  styleUrls: ['./quarter-close-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCloseChecklistComponent implements OnInit, OnDestroy {
  protected readonly PermissionType = PermissionType;

  sections = this.checklistService.Sections;

  sectionTitles = this.checklistService.SectionTitles;

  rows = this.checklistService.Rows;

  rowTitles = this.checklistService.RowTitles;

  rowComponents: QuarterCloseChecklistRowComponents = this.checklistService.RowComponents;

  rowDisabled: QuarterCloseChecklistRowDisabled = this.checklistService.RowDisabled;

  isAdminUser = false;

  isCurrentQuarterSelected = false;

  isLoadingList = false;

  isLockingAll = false;

  isAdjustmentAvailable = false;

  isClosedMonthsProcessing: boolean | null = null;

  isQuarterCloseEnabled = false;

  gatherDocumentsSectionComplete = false;

  confirmForecastSectionComplete = false;

  closeExpensesRowComplete = false;

  workflowList: Workflow[] = [];

  vendorEstimateSummaries: QuarterCloseChecklistVendorEstimateSummary[] = [];

  selectedQuarterMonthFormControl = new FormControl('');

  selectedQuarterMonth = '';

  isPastQuarterMonth = false;

  currentOpenMonth = '';

  quarterMonths: ChecklistMonthlyDropdownDate[] = [];

  ungroupedInvoices: listInvoicesForReconciliationQuery[] = [];

  numberOfInvoices = Utils.zeroHyphen;

  amountInvoiced = Utils.zeroHyphen;

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  permissionList: QuarterCloseChecklistPermissionList = {
    userHasChecklistContractsPermission: false,
    userHasChecklistDiscountsPermission: false,
    userHasChecklistForecastMethodologyPermission: false,
    userHasChecklistInvoicesPermission: false,
    userHasChecklistPatientDataPermission: false,
    userHasChecklistPatientSiteCurvePermission: false,
    userHasChecklistTrialTimelinePermission: false,
    userHasChecklistVendorEstimatesPermission: false,
    userHasChecklistVendorExpensesPermission: false,
    userHasChecklistAdminPermission: false,
  };

  quarterCloseChecklistTitle$(workflowList: Workflow[]): Observable<string> {
    return this.periodCloseService.isCurrentQuarterSelected.pipe(
      first(),
      map(() => {
        const workflowLength = this.workflowList.filter(
          (workflow) => workflow.section !== QuarterCloseChecklistRowSections['Close Month']
        ).length;
        const date = dayjs(this.periodCloseService.getSelectedQuarterMonth(this.router.url));
        const isDateValid = date.isValid();
        const dateStr = isDateValid ? date.format('MMMM YYYY') : '';

        return `${dateStr} - Closing Checklist (${this.workflowService.completedTotals(
          workflowList
        )}/${workflowLength} Complete)`;
      })
    );
  }

  constructor(
    private checklistService: QuarterCloseChecklistService,
    public toggleService: QuarterCloseChecklistToggleService,
    public workflowService: QuarterCloseChecklistWorkflowService,
    public originalWorkflowService: WorkflowService,
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private overlayService: OverlayService,
    private changeDetectorRef: ChangeDetectorRef,
    private workflowQuery: WorkflowQuery,
    private router: Router,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private authService: AuthService,
    private quarterCloseAdjustmentsService: QuarterCloseAdjustmentsService
  ) {
    this.setUserPermissions();
  }

  ngOnInit(): void {
    this.checklistService
      .isAdminUser()
      .pipe(untilDestroyed(this))
      .subscribe((isAdminUser) => {
        this.isAdminUser = isAdminUser;
        this.changeDetectorRef.detectChanges();
      });

    this.checklistService
      .isCurrentQuarterSelected()
      .pipe(untilDestroyed(this))
      .subscribe((isCurrentQuarterSelected) => {
        this.resetWorkflow();
        this.configureDates(isCurrentQuarterSelected);
        this.changeDetectorRef.detectChanges();
      });

    this.checklistService
      .isClosedMonthsProcessing()
      .pipe(untilDestroyed(this))
      .subscribe((isClosedMonthsProcessing) => {
        this.isClosedMonthsProcessing = isClosedMonthsProcessing;
        this.changeDetectorRef.detectChanges();
      });

    this.checklistService
      .isQuarterCloseEnabled()
      .pipe(untilDestroyed(this))
      .subscribe((isQuarterCloseEnabled) => {
        this.isQuarterCloseEnabled = isQuarterCloseEnabled;
        this.changeDetectorRef.detectChanges();
      });

    this.checklistService
      .workflowAdjustmentAvailable()
      .pipe(untilDestroyed(this))
      .subscribe((isAdjustmentAvailable) => {
        this.isAdjustmentAvailable = isAdjustmentAvailable;
        this.changeDetectorRef.detectChanges();

        if (!this.isAdjustmentAvailable) {
          this.workflowQuery.getAll();
        }
      });

    this.checklistService
      .workflowLoading()
      .pipe(untilDestroyed(this))
      .subscribe((isLoadingList) => {
        this.isLoadingList = isLoadingList;
        this.changeDetectorRef.detectChanges();
      });

    this.checklistService
      .workflowList()
      .pipe(untilDestroyed(this), distinctUntilChanged(isEqual))
      .subscribe((workflowList) => {
        this.workflowList = workflowList;

        this.disabledWorkflowLocks();
        this.configureInvoiceData();

        this.changeDetectorRef.detectChanges();
      });

    this.selectedQuarterMonthFormControl.valueChanges
      .pipe(startWith(this.selectedQuarterMonthFormControl.value), untilDestroyed(this))
      .subscribe(() => {
        this.getVendorEstimateSummaries();
      });

    this.selectedQuarterMonthFormControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((selectedQuarterMonth) => {
        this.configureSelectedQuarterMonthChanges(selectedQuarterMonth, false);
      });
  }

  ngOnDestroy(): void {
    this.resetWorkflow();
  }

  resetWorkflow(): void {
    const latestMonth = this.periodCloseService.getLatestMonth();

    this.configureSelectedQuarterMonthChanges(latestMonth, true);
  }

  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  onExportChecklist = async () => {
    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const date = dayjs(this.periodCloseService.getSelectedQuarterMonth(this.router.url));
    const isDateValid = date.isValid();
    const dateStr = isDateValid ? date.format('MMM-YYYY') : '';

    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.CLOSING_PERIOD_CHECKLIST,
          filename: `${dateStr}_closing-period-checklist`,
          export_entity_id: 'WF_MONTH_CLOSE_LOCK',
          json_properties: { month: dateStr },
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  };

  configureDates(isCurrentQuarterSelected: boolean): void {
    this.isLoadingList = true;
    this.isCurrentQuarterSelected = isCurrentQuarterSelected;
    this.ungroupedInvoices = this.periodCloseService.ungroupedInvoices;
    if (!this.currentOpenMonth) {
      this.currentOpenMonth = this.periodCloseService.currentOpenMonth;
    }
    this.quarterMonths = this.workflowService.configureQuarterMonths(
      this.periodCloseService.quarterMonths,
      this.currentOpenMonth
    );

    const selectedMonth = this.periodCloseService.selectedQuarterMonth;
    const selectedQuarterMonth = this.workflowService.parseToStartOfMonth(selectedMonth);
    const persistedQuarterMonth = this.periodCloseService.getSelectedQuarterMonth(this.router.url);
    const sameMonths = selectedQuarterMonth === persistedQuarterMonth;

    if (!selectedQuarterMonth) {
      return;
    }

    this.selectedQuarterMonth = selectedQuarterMonth;

    if (sameMonths) {
      this.selectedQuarterMonthFormControl.setValue(selectedQuarterMonth, { emitEvent: false });
      this.configureSelectedQuarterMonthChanges(selectedQuarterMonth, false);
    } else {
      this.selectedQuarterMonthFormControl.setValue(persistedQuarterMonth, { emitEvent: false });
      this.configureSelectedQuarterMonthChanges(persistedQuarterMonth, false);
    }
  }

  configureInvoiceData(): void {
    const invoiceQuickView = this.workflowService.calculateInvoiceMonthlyQuickView(
      this.selectedQuarterMonth,
      this.ungroupedInvoices
    );

    this.numberOfInvoices = invoiceQuickView.numberOfInvoices;
    this.amountInvoiced = invoiceQuickView.amountInvoiced;
  }

  configureSelectedQuarterMonthChanges(
    selectedQuarterMonth: string | null,
    onDestroy: boolean
  ): void {
    if (!selectedQuarterMonth) {
      this.checklistService.resetWorkflowState();
      return;
    }

    if (selectedQuarterMonth === this.selectedQuarterMonth) {
      return;
    }

    this.selectedQuarterMonth = selectedQuarterMonth;
    this.periodCloseService.selectedQuarterMonth = selectedQuarterMonth;
    this.currentOpenMonth = this.periodCloseService.currentOpenMonth;

    this.isPastQuarterMonth = this.workflowService.isPastQuarterMonth(
      selectedQuarterMonth,
      this.currentOpenMonth
    );

    // Persist selected month
    if (!onDestroy) {
      this.periodCloseService.persistedQuarterMonth = selectedQuarterMonth;
    }

    this.periodCloseService.selectedQuarterMonthChanged$.next(null);

    // Pull selected month
    this.checklistService.onQuarterMonthChange(selectedQuarterMonth, this.isAdminUser);
  }

  disabledWorkflowLocks(): void {
    if (!this.workflowList.length) {
      return;
    }

    this.gatherDocumentsSectionComplete = this.workflowService.gatherDocumentsSectionCompleted(
      this.workflowList
    );
    this.confirmForecastSectionComplete = this.workflowService.confirmForecastSectionCompleted(
      this.workflowList
    );
    this.closeExpensesRowComplete = this.workflowService.closeExpensesRowCompleted(
      this.workflowList
    );

    this.changeDetectorRef.detectChanges();
  }

  isChecklistBannerDisabled(): boolean {
    return !(
      this.gatherDocumentsSectionComplete &&
      this.confirmForecastSectionComplete &&
      this.closeExpensesRowComplete &&
      this.workflowService.workflow(this.rowTitles.CloseDiscounts, this.workflowList)?.properties
        .locked
    );
  }

  isSelectedMonthOpen(): boolean {
    return dayjs(this.currentOpenMonth).isSame(dayjs(this.selectedQuarterMonth));
  }

  onAddVendorEstimateUploadClick: () => void = () => {
    const overlay = this.overlayService.open<{ vendorId?: string }>({
      content: AddVendorEstimateUploadComponent,
    });

    overlay.afterClosed$.subscribe((res) => {
      this.getVendorEstimateSummaries();
      if (res.data?.vendorId) {
        this.quarterCloseAdjustmentsService.updateFormControlValues('', res.data.vendorId);

        this.router.navigate(
          [`/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`],
          {
            queryParams: {
              vendorId: res.data.vendorId,
              editMode: true,
              currentOpenMonth: dayjs(this.currentOpenMonth).add(2, 'day').format('YYYY-MM-DD'),
            },
          }
        );
      }
    });
  };

  onAddPoReportUploadClick: () => void = () => {
    this.overlayService.open({ content: AddPoReportUploadComponent });
  };

  getVendorEstimateSummaries(): void {
    const period = this.selectedQuarterMonthFormControl.value;

    if (!period) {
      return;
    }

    const formattedPeriod = dayjs(period).format('MMM-YYYY');

    this.checklistService
      .vendorEstimateSummaries(formattedPeriod)
      .pipe(first())
      .subscribe((vendorEstimateSummaries) => {
        this.vendorEstimateSummaries = sortBy(vendorEstimateSummaries, ['name']);
        this.changeDetectorRef.detectChanges();
      });
  }

  lockAllWorkflows: ChecklistComponentLockAllFn = async (section: QuarterCloseChecklistSection) => {
    this.isLockingAll = true;

    const workflows = this.workflowService.getLockAllWorkflows(section, this.workflowList);

    for (const workflow of workflows) {
      // eslint-disable-next-line no-await-in-loop
      await this.changeLockStatus(true, workflow, false);
    }

    this.isLockingAll = false;
  };

  changeLockStatus: ChecklistComponentChangeLockFn = async (
    shouldLock: boolean,
    workflow: Workflow,
    isAssign: boolean
  ) => {
    return this.originalWorkflowService.changeLockStatus(
      shouldLock,
      workflow,
      this.isAdminUser,
      isAssign
    );
  };

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_CONTRACTS],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_DISCOUNTS],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_FORECAST_METHODOLOGY],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_INVOICES],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_DATA],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_SITE_CURVES],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_TRIAL_TIMELINE],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_VENDOR_ESTIMATES],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_VENDOR_EXPENSES],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_ADMIN],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
          userHasChecklistContractsPermission,
          userHasChecklistDiscountsPermission,
          userHasChecklistForecastMethodologyPermission,
          userHasChecklistInvoicesPermission,
          userHasChecklistPatientDataPermission,
          userHasChecklistPatientSiteCurvePermission,
          userHasChecklistTrialTimelinePermission,
          userHasChecklistVendorEstimatesPermission,
          userHasChecklistVendorExpensesPermission,
          userHasChecklistAdminPermission,
        ]) => {
          this.permissionList = {
            userHasChecklistContractsPermission,
            userHasChecklistInvoicesPermission,
            userHasChecklistVendorEstimatesPermission,
            userHasChecklistPatientDataPermission,
            userHasChecklistTrialTimelinePermission,
            userHasChecklistPatientSiteCurvePermission,
            userHasChecklistForecastMethodologyPermission,
            userHasChecklistVendorExpensesPermission,
            userHasChecklistDiscountsPermission,
            userHasChecklistAdminPermission,
          };

          this.changeDetectorRef.markForCheck();
        }
      );
  }
}
