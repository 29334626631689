import { Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  listInvoicesForReconciliationQuery,
  listVendorEstimateSummariesQuery,
} from '@services/gql.service';
import { OverlayCloseEvent } from '@components/overlay/custom-overlay-ref';
import { QuarterDate } from '../../../period-close.component';
import { ChecklistSectionGatherContractsComponent } from '../components/checklist-section-gather-contracts/checklist-section-gather-contracts.component';
import { ChecklistSectionGatherEstimatesComponent } from '../components/checklist-section-gather-estimates/checklist-section-gather-estimates.component';
import { WorkflowModel } from '../../quarter-close/close-quarter-check-list/store';
import { ChecklistRowInfoComponent } from '../components/checklist-row-info/checklist-row-info.component';
import { Workflow } from '../../quarter-close/close-quarter-check-list/store/workflow.store';
import { ChecklistSectionDiscountComponent } from '../components/checklist-section-discount/checklist-section-discount.component';

export class ChecklistComponent<T> {
  constructor(
    public component: Type<T>,
    public data: unknown,
    public id: string
  ) {}
}

export interface ChecklistComponentData {
  parent: ChecklistRowInfoComponent;
  data: unknown;
  id: string;
}

export type ChecklistComponentLockAllFn = (section: QuarterCloseChecklistSection) => Promise<void>;

export type ChecklistComponentChangeLockFn = (
  locked: boolean,
  workflow: Workflow,
  isAssign: boolean
) => Promise<void>;

export type ChecklistComponentChangeLockStatusFn = (
  locked: boolean,
  workflow: WorkflowModel,
  isAdminUse: boolean,
  isAssign: boolean
) => Promise<void>;

export type ChecklistComponentVendorEstimateUploadFn = () => void;

export interface ChecklistComponentDataGatherEstimates {
  vendorEstimateUploadClick: ChecklistComponentVendorEstimateUploadFn;
  disabledLock: boolean;
  vendorEstimateSummaries: QuarterCloseChecklistVendorEstimateSummary[];
}

export type ChecklistComponentAddPoReportUploadFn = () => void;

export interface ChecklistComponentDataAddPoReport {
  addPoReportUploadClick: ChecklistComponentAddPoReportUploadFn;
  disabledLock: boolean;
}

export enum QuarterCloseChecklistSection {
  GatherDocuments = 'GatherDocuments',
  ConfirmForecast = 'ConfirmForecast',
  ConfirmClose = 'ConfirmClose',
  CloseMonth = 'CloseMonth',
}

export enum QuarterCloseChecklistSectionTitles {
  GatherDocuments = 'GATHER DOCUMENTS',
  ConfirmForecast = 'CONFIRM FORECAST',
  ConfirmClose = 'CONFIRM & CLOSE',
}

export enum QuarterCloseChecklistRow {
  // GatherDocuments
  GatherContracts = 'GatherContracts',
  GatherInvoices = 'GatherInvoices',
  GatherPatients = 'GatherPatients',
  GatherEstimates = 'GatherEstimates',
  // ConfirmForecast
  ForecastTimeline = 'ForecastTimeline',
  ForecastCurves = 'ForecastCurves',
  ForecastMethodology = 'ForecastMethodology',
  // ConfirmClose
  CloseExpenses = 'CloseExpenses',
  CloseDiscounts = 'CloseDiscounts',
}

export enum QuarterCloseChecklistRowTitles {
  // GatherDocuments
  GatherContracts = 'Pending Site Contracts, Change Orders, and Purchase Orders',
  GatherInvoices = 'Review Invoices',
  GatherEstimates = 'Review Vendor Estimates',
  GatherPatients = 'Confirm Patient Data',
  // ConfirmForecast
  ForecastTimeline = 'Confirm Trial Timeline',
  ForecastCurves = 'Site & Patient Curves',
  ForecastMethodology = 'Forecast Methodology',
  // ConfirmClose
  CloseExpenses = 'Confirm Vendor Expenses',
  CloseDiscounts = 'Confirm Discounts',
  //
  CloseMonth = 'Close Month',
}

export enum QuarterCloseChecklistRowSections {
  // GatherDocuments
  'Pending Site Contracts, Change Orders, and Purchase Orders' = 'GatherDocuments',
  'Confirm Patient Data' = 'GatherDocuments',
  'Review Invoices' = 'GatherDocuments',
  'Review Vendor Estimates' = 'GatherDocuments',
  // ConfirmForecast
  'Confirm Trial Timeline' = 'ConfirmForecast',
  'Site & Patient Curves' = 'ConfirmForecast',
  'Forecast Methodology' = 'ConfirmForecast',
  // ConfirmClose
  'Confirm Vendor Expenses' = 'ConfirmClose',
  'Confirm Discounts' = 'ConfirmClose',
  // CloseMonth
  'Close Month' = 'CloseMonth',
}

export enum QuarterCloseChecklistRowPermissionFields {
  // GatherDocuments
  'Pending Site Contracts, Change Orders, and Purchase Orders' = 'userHasChecklistContractsPermission',
  'Confirm Patient Data' = 'userHasChecklistPatientDataPermission',
  'Review Invoices' = 'userHasChecklistInvoicesPermission',
  'Review Vendor Estimates' = 'userHasChecklistVendorEstimatesPermission',
  // ConfirmForecast
  'Confirm Trial Timeline' = 'userHasChecklistTrialTimelinePermission',
  'Site & Patient Curves' = 'userHasChecklistPatientSiteCurvePermission',
  'Forecast Methodology' = 'userHasChecklistForecastMethodologyPermission',
  // ConfirmClose
  'Confirm Vendor Expenses' = 'userHasChecklistVendorExpensesPermission',
  'Confirm Discounts' = 'userHasChecklistDiscountsPermission',
}

export type QuarterCloseChecklistRowToggle = {
  -readonly [key in keyof typeof QuarterCloseChecklistRow]: BehaviorSubject<boolean>;
};

export type QuarterCloseChecklistRowDisabled = {
  [key in keyof typeof QuarterCloseChecklistRow]: boolean;
};

export type QuarterCloseChecklistRowComponents = {
  GatherContracts: ChecklistComponent<ChecklistSectionGatherContractsComponent>;
  GatherEstimates: ChecklistComponent<ChecklistSectionGatherEstimatesComponent>;
  CloseDiscounts: ChecklistComponent<ChecklistSectionDiscountComponent>;
};

export type QuarterCloseChecklistRowComponent =
  | ChecklistSectionGatherContractsComponent
  | ChecklistSectionDiscountComponent
  | ChecklistSectionGatherEstimatesComponent;

export interface QuarterCloseChecklistVendorEstimateQuery {
  success: boolean;
  errors: string[];
  data: listVendorEstimateSummariesQuery[] | null;
}

export interface QuarterCloseChecklistVendorEstimateSummary {
  id: string;
  name: string;
  vendorEstimateExists: boolean;
  activitiesExceedingForecast: string[];
  activitiesExceedingAmountRemaining: string[];
}

export type ShowWarningModalOverlayCloseEvent = {
  result: boolean;
  textarea: string;
};

export type ShowWarningModalResponse = Promise<
  OverlayCloseEvent<ShowWarningModalOverlayCloseEvent>
>;

export type ChecklistInvoiceMonthlyQuickView = {
  numberOfInvoices: string;
  amountInvoiced: string;
};

export type ChecklistInvoiceExpenseAmounts = listInvoicesForReconciliationQuery['expense_amounts'];

export interface ChecklistMonthlyDropdownDate extends QuarterDate {
  value: string;
  label: string;
  disabled: boolean;
}

export interface QuarterCloseChecklistPermissionList {
  userHasChecklistContractsPermission: boolean;
  userHasChecklistDiscountsPermission: boolean;
  userHasChecklistForecastMethodologyPermission: boolean;
  userHasChecklistInvoicesPermission: boolean;
  userHasChecklistPatientDataPermission: boolean;
  userHasChecklistPatientSiteCurvePermission: boolean;
  userHasChecklistTrialTimelinePermission: boolean;
  userHasChecklistVendorEstimatesPermission: boolean;
  userHasChecklistVendorExpensesPermission: boolean;
  userHasChecklistAdminPermission: boolean;
}
