import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PeriodType } from '@services/utils';
import {
  BudgetCompareMenuPeriodFormControl,
  BudgetCompareMenuPeriodList,
  defaultPeriodList,
} from '../../budget-compare-menu.models';

@UntilDestroy()
@Component({
  selector: 'aux-budget-compare-menu-period',
  templateUrl: './budget-compare-menu-period.component.html',
  styleUrls: ['../../budget-compare-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetCompareMenuPeriodComponent {
  @Input() periodFormControl: BudgetCompareMenuPeriodFormControl;

  @Input() periodList: BudgetCompareMenuPeriodList;

  constructor() {
    this.periodFormControl = new FormControl<string>(PeriodType.PERIOD_MONTH, {
      nonNullable: true,
    });
    this.periodList = defaultPeriodList;
  }
}
