import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { File } from '@components/file-manager/state/file.model';
import { ApiService, FileMetadata } from '@services/api.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { EntityType, EventType, GqlService, processEventMutation } from '@services/gql.service';
import { formatDate } from '@angular/common';
import { OrganizationQuery } from '@models/organization/organization.query';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { BudgetQuery } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget.query';
import * as dayjs from 'dayjs';

@Component({
  selector: 'aux-quarter-close-add-po-report',
  templateUrl: './add-po-report-upload.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPoReportUploadComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = '';

  selectedVendor = new UntypedFormControl('');

  documentShortName = new FormControl('');

  constructor(
    public ref: CustomOverlayRef,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private apiService: ApiService,
    private budgetQuery: BudgetQuery,
    public vendorQuery: OrganizationQuery,
    private gqlService: GqlService
  ) {}

  pathFn: () => string = () => '';

  getFilePath(month: string, vendor_id: string) {
    const formatted_current_month = formatDate(month, 'MMMM-y', 'en-US');
    const trialId = this.mainQuery.getValue().trialKey;
    if (vendor_id) {
      return `trials/${trialId}/vendors/${vendor_id}/po-report/${formatted_current_month}/`;
    } else {
      return `trials/${trialId}/po-report/${formatted_current_month}/`;
    }
  }

  async onUpload() {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = 'You need to upload a file!';
        return;
      }

      this.loading$.next(true);

      const { budget_info } = this.budgetQuery.getValue();
      const date = budget_info[0].current_month;
      const filePath = this.getFilePath(date || dayjs().toString(), this.selectedVendor.value);

      const fileUploadPromises: Promise<{
        success: boolean;
        data: processEventMutation | null;
        errors: string[];
      }>[] = [];

      for (const file of files) {
        this.fileManager.fileStore.update(file.id, {
          ...file,
          bucket_key: `${filePath}${file.bucket_key}`,
        });

        const fileUploadPromise = firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.PO_REPORT_UPLOADED,
            entity_type: EntityType.TRIAL,
            entity_id: this.selectedVendor.value,
            bucket_key: `public/${filePath}${file.bucket_key}`,
            payload: JSON.stringify({
              document_short_name: this.documentShortName.value,
              month: formatDate(date || '', 'MMM-y', 'en-US').toUpperCase(),
            }),
          })
        );
        fileUploadPromises.push(fileUploadPromise);
      }

      const metadata: FileMetadata = { admin: '1' };
      const fileSuccess = await this.fileManager.fileService.uploadFiles(metadata);

      if (fileSuccess) {
        try {
          await Promise.all(fileUploadPromises);
          this.overlayService.success();
        } catch {
          const removeFiles = async () => {
            for (const [i] of fileUploadPromises.entries()) {
              await this.apiService.removeFile(`${filePath}${files[i].bucket_key}`);
            }
          };

          removeFiles().catch((error: Error) => {
            console.error('Error removing files:', error);
          });

          this.overlayService.error('Error uploading files.');
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }

  removeFile(file: File) {
    if (this.fileManager) {
      this.fileManager.removeFile(file);
    }
  }
}
