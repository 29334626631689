import { Injectable } from '@angular/core';
import { getScreenFailSummaryQuery, SortOrder } from '@services/gql.service';
import { GenericOperator } from '@services/operator.class';
import { Utils } from '@services/utils';
import { combineLatest, Observable, Subscriber } from 'rxjs';
import { TrialInsightsLegendDataOptions } from '../../models/trial-insights-legend.model';
import { TrialInsightsQuery } from '../../store/trial-insights.query';
import { TrialInsightsClinicalScreenFailChartService } from './screen-fail-chart.service';
import { TrialInsightsClinicalScreenFailTableService } from './screen-fail-table.service';
import { TrialInsightsState } from '../../models/trial-insights-store.model';
import {
  TrialInsightsScreenFailKey,
  TrialInsightsTableOptions,
} from '../../models/trial-insights-table.model';
import {
  GenericTrialInsightsQuery,
  SortOrderDefault,
} from '../../classes/trial-insights-query.class';

type state = Partial<TrialInsightsState['screenFail']>;
type rowData = TrialInsightsTableOptions['rowData']['data'];
type results = [state['data'], TrialInsightsScreenFailKey, SortOrder];

const sortDefaults: SortOrderDefault[] = [
  {
    buttonKey: TrialInsightsScreenFailKey.NUMBER,
    defaultOrder: SortOrder.DESC,
  },
  {
    buttonKey: TrialInsightsScreenFailKey.PERCENT,
    defaultOrder: SortOrder.DESC,
  },
];

@Injectable()
export class TrialInsightsClinicalScreenFailQueryService extends GenericTrialInsightsQuery {
  constructor(
    public trialInsightsQuery: TrialInsightsQuery,
    public tableService: TrialInsightsClinicalScreenFailTableService,
    public chartService: TrialInsightsClinicalScreenFailChartService
  ) {
    super({
      trialInsightsQuery,
      slice: 'screenFail',
      sortDefaults,
      chartService,
      tableService,
    });
  }

  processScreenFailSummary$(): Observable<results> {
    return combineLatest([
      this.data as Observable<getScreenFailSummaryQuery[]>,
      this.selectedKey as Observable<TrialInsightsScreenFailKey>,
      this.sortOrder,
    ]).pipe(this.processResponseData());
  }

  processResponseData() {
    const processFn = (sourceValue: results, subscriber: Subscriber<results>) => {
      const [data, selectedKey, sortOrder] = sourceValue;

      if (!data || !data.length) {
        this.resetState();
        return;
      }

      const parsedData = this.parseStoreData(data);
      const screenFailData = this.parseStoreDetail(parsedData, selectedKey, sortOrder);

      if (!screenFailData) {
        return;
      }

      const screenFailCost = screenFailData.screen_fail_total_cost;
      this.totalAmount.next(Utils.currencyFormatter(screenFailCost));

      const screenFails = screenFailData.screen_fails;
      const screenFailRate = screenFailData.screen_fail_rate * 100;
      const dropoutRate = screenFailData.dropout_rate * 100;

      const legendData = [
        { title: 'Screen Fails', value: `${screenFails}` },
        { title: 'Screen Fail Rate', value: `${screenFailRate.toFixed(2)}%` },
        { title: 'Dropout Rate', value: `${dropoutRate.toFixed(2)}%`, displayDivider: false },
      ];

      const legend: TrialInsightsLegendDataOptions[] = legendData.map((legendOption) => {
        return {
          displayIcon: false,
          displayDivider: true,
          iconColor: '#094673',
          valueColor: '#094673',
          ...legendOption,
        };
      });

      const legendOptions = this.chartService.createLegend(legend);
      this.legendOptions.next(legendOptions);

      const screenFail =
        selectedKey === TrialInsightsScreenFailKey.NUMBER
          ? screenFailData.site_screen_fail_totals
          : screenFailData.site_screen_fail_rates;

      const rowData: rowData = screenFail.map((row) => {
        let screenFailAmount = 0;
        let screenFailFormatted = '';

        if ('screen_fail_rate' in row) {
          screenFailAmount = row.screen_fail_rate;
          screenFailFormatted = `${(screenFailAmount * 100).toFixed(2)}%`;
        } else if ('screen_fails' in row) {
          screenFailAmount = row.screen_fails;
          screenFailFormatted = `${screenFailAmount}`;
        }

        return {
          buttonKey: selectedKey,
          leftHeader: row.site_name || '',
          leftSubheader:
            row.primary_investigator?.given_name && row.primary_investigator?.family_name
              ? `${row.primary_investigator?.given_name} ${row.primary_investigator?.family_name}`
              : '',
          rightHeader: screenFailFormatted,
          rightSubheader: '',
        };
      });

      const tableOptions = this.tableService.createTable(selectedKey, rowData);
      this.tableOptions.next(tableOptions);

      subscriber.next(sourceValue);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }
}
