import { Injectable } from '@angular/core';
import { GqlService } from '@services/gql.service';
import { GenericOperator } from '@services/operator.class';
import { Observable, Subscriber } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import {
  TrialInsightsEnrolledPatientResponseType,
  TrialInsightsResponseGeneric,
  TrialInsightsState,
} from '../../models/trial-insights-store.model';
import { TrialInsightsStore } from '../../store/trial-insights.store';

type state = Partial<TrialInsightsState['patientEnrolled']>;
type requestType = 'initial' | 'remaining';
type loadingState = requestType | 'completed';
type responseType = TrialInsightsEnrolledPatientResponseType;
type response = TrialInsightsResponseGeneric<responseType>;

@Injectable()
export class TrialInsightsFinancePatientsEnrolledStoreService {
  constructor(
    private store: TrialInsightsStore,
    private mainQuery: MainQuery,
    private gqlService: GqlService
  ) {}

  getRemainingInvestigatorInfo$(): Observable<unknown> {
    return this.mainQuery.select('trialKey').pipe(
      this.updateLoadingState('initial'),
      switchMap(() => this.gqlService.getRemainingInvestigatorInfo$()),
      this.processResponse(),
      this.updateLoadingState('completed')
    );
  }

  loadingState(type: loadingState): state {
    const loadingState: state = {};

    if (type === 'initial') {
      loadingState.isLoading = true;
      loadingState.data = null;
    } else {
      loadingState.isLoading = false;
    }

    return loadingState;
  }

  updateLoadingState(type: loadingState) {
    const updates = this.loadingState(type);

    const processFn = (sourceValue: unknown, subscriber: Subscriber<unknown>) => {
      this.store.update((state) => {
        return {
          ...state,
          patientEnrolled: {
            ...state.patientEnrolled,
            ...updates,
            data: [...(state.patientEnrolled.data || []), ...(updates.data || [])],
          },
        };
      });

      subscriber.next(updates);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }

  processResponse() {
    const processFn = (response: response, subscriber: Subscriber<unknown>) => {
      const { data } = response;

      this.store.update((state) => {
        return {
          ...state,
          patientEnrolled: {
            ...state.patientEnrolled,
            data: data ? [data] : [],
          },
        };
      });

      subscriber.next(data);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }
}
