import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { FileMetadata } from '@services/api.service';

export interface FileManagerDialogInput {
  pathFn?: () => string;
  path?: string;
  metadataFn?: () => FileMetadata;
  metadata?: FileMetadata;
  header?: string;
}

@Component({
  selector: 'aux-file-manager-dialog',
  template: `
    <aux-file-manager
      class="h-32"
      #manager
      [fetchFilesOnInit]="false"
      [pathFn]="pathFn"
      [eager]="true"
      [path]="path"
      [metadataFn]="metadataFn"
      [metadata]="metadata"
      [insertDocument]="true"
      [showSuccessOnUpload]="true"
    />
    <div class="max-h-60 overflow-auto mt-4">
      <aux-file-viewer [fileManager]="manager" [disableFirstFileMargin]="true" />
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileManagerDialogComponent {
  pathFn: (() => string) | undefined;

  path = '';

  metadataFn: (() => FileMetadata) | undefined;

  metadata: FileMetadata = {};

  constructor(public ref: CustomOverlayRef<unknown, FileManagerDialogInput>) {
    if (this.ref.data) {
      this.pathFn = this.ref.data.pathFn;
      this.path = this.ref.data.path || '';
      this.metadataFn = this.ref.data.metadataFn;
      this.metadata = this.ref.data.metadata || {};
    }
  }
}
