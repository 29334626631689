import { Injectable } from '@angular/core';
import { memo } from 'helpful-decorators';
import { some } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryQuery } from '../../category/category.query';
import { ForecastTableGridDataInterface } from '../models/forecast-table-grid.model';

@Injectable()
export class ForecastTableGridCategoryLoggingService {
  constructor(private categoryQuery: CategoryQuery) {}

  loadingCategories(categoryIds: ForecastTableGridDataInterface[]): Observable<boolean> {
    const categories = categoryIds.map((category) => {
      return this.isCategoryLoading(category.id);
    });

    return combineLatest(categories).pipe(
      map((loadingStatus) => {
        return some(loadingStatus, Boolean);
      })
    );
  }

  @memo()
  isCategoryLoading(id: string) {
    return this.categoryQuery.ui.selectEntity(id, 'isLoading') as Observable<boolean>;
  }
}
