import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { Document, DocumentType } from '@services/gql.service';
import { DocumentLibraryService } from '../document-library.service';
import { Option } from '@components/components.type';
import { DocumentsComponentForm } from '../documents.component.model';
import { FormGroupTyped } from '@services/utils';

export interface EditMultipleDocumentsModalData {
  selectedRows: Document[];
  formGroup: FormGroupTyped<DocumentsComponentForm>;
}

@Component({
  selector: 'aux-edit-multiple-documents-modal',
  templateUrl: './edit-multiple-documents-modal.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMultipleDocumentsModalComponent {
  selectedRows: Document[] = [];

  formGroup!: FormGroupTyped<DocumentsComponentForm>;

  selectedType: DocumentType | null = null;

  selectedVendor: string | null = null;

  selectedSite: string | null = null;

  documentTypeOptions: Option[] = this.documentLibrary.getDocumentOptions();

  constructor(
    public ref: CustomOverlayRef<{ updateGrid: boolean }, EditMultipleDocumentsModalData>,
    public documentLibrary: DocumentLibraryService
  ) {
    if (this.ref.data) {
      this.selectedRows = this.ref.data.selectedRows;
      this.formGroup = this.ref.data.formGroup;
    }
  }

  onApply = () => {
    this.selectedRows.forEach((document) => {
      // todo(upgrade) find out if this is still working
      const table = this.formGroup.controls.table.getRawValue();
      const rowIndex = table.findIndex((v) => v.id === document.id);
      if (rowIndex !== -1) {
        const row = table[rowIndex];
        table.splice(rowIndex, 1, {
          ...row,
          document_type_id: this.selectedType,
          vendor_id: this.selectedVendor,
          site_id: this.selectedSite,
        });
        this.formGroup.controls.table.setValue(table);
      }
    });

    this.ref.close({ updateGrid: true });
  };
}
