import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'aux-quarter-close-processing-confirmation-header',
  template: `
    <div class="text-aux-green-dark flex items-center font-inter">
      <aux-icon name="Check" [size]="42" />
      <span class="text-2xl ml-3 font-bold text-7.5xl">The Close Process has begun</span>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCloseProcessingConfirmationHeaderComponent {}
