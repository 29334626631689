import { Component } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

export interface ICheckboxCellParams extends ICellRendererParams {
  checkboxTooltip: () => string;
  isEditable: (node: IRowNode, field: string) => boolean;
  onChange: (node: IRowNode, field: string, value: boolean) => void;
}

@Component({
  template: `
    <div
      class="flex items-center"
      [ngClass]="{
        'cursor-not-allowed': !selectable
      }"
    >
      <div class="ag-selection-checkbox flex !mr-0" *ngIf="!pinned">
        <!--AG-CHECKBOX-->
        <div
          class="ag-labeled ag-label-align-right ag-checkbox ag-input-field"
          [ngClass]="{ 'ag-disabled': !selectable }"
          [auxTooltip]="checkboxTooltip"
        >
          <div
            class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
            [ngClass]="{
              'ag-disabled': !selectable,
              'ag-checked': isSelected,
              'ag-indeterminate': isSelected === undefined
            }"
            (click)="selectRow()"
          >
            <input
              class="ag-input-field-input ag-checkbox-input flex"
              type="checkbox"
              [disabled]="!selectable"
              [value]="isSelected"
              [indeterminate]="isSelected === undefined"
            />
          </div>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [NgIf, TooltipDirective, NgClass],
})
export class AgGroupCheckboxComponent implements ICellRendererAngularComp {
  params!: ICheckboxCellParams;
  paddingLeft!: number;
  isGroup!: boolean;
  pinned = false;
  checkboxTooltip = '';
  isSelected: boolean | undefined = false;
  selectable = true;

  agInit(params: ICheckboxCellParams): void {
    this.params = params;
    this.paddingLeft = params.node.level * 10;
    this.isGroup = !!params.node.group;
    this.pinned = params.node.isRowPinned();
    this.calculateIsSelected();
    this.selectable = params.isEditable(params.node, this.params.colDef?.field || '');
    this.checkboxTooltip = params.checkboxTooltip();
  }

  refresh() {
    return false;
  }

  calculateIsSelected() {
    if (this.params.node.group) {
      const all = this.params.node.allLeafChildren?.every((node) => {
        return node.data?.[this.params.colDef?.field || ''];
      });
      if (all) {
        this.isSelected = true;
      } else {
        const some = this.params.node.allLeafChildren?.some((node) => {
          return node.data?.[this.params.colDef?.field || ''];
        });
        if (some) {
          this.isSelected = undefined;
        } else {
          this.isSelected = false;
        }
      }
    } else {
      this.isSelected = this.params.node.data[this.params.colDef?.field || ''];
    }
  }

  selectRow() {
    if (this.params.node.selectable && this.params.colDef?.field) {
      const field = this.params.colDef?.field;
      this.isSelected = !this.isSelected;
      if (this.params.node.group) {
        this.params.node.allLeafChildren.forEach((node) => {
          if (this.params.isEditable(node, field)) {
            if (node.data[field] !== this.isSelected) {
              node.updateData({
                ...node.data,
                [field]: this.isSelected,
              });
              this.params.onChange(node, field, !!this.isSelected);
            }
          }
        });
        this.calculateIsSelected();
      } else {
        this.params.node.updateData({
          ...this.params.node.data,
          [field]: this.isSelected,
        });
        this.params.onChange(this.params.node, field, this.isSelected);
      }

      const nodes = this.getAllParents(this.params.node);
      const childrenNodes = this.getAllGroupChildren(this.params.node);
      this.params.api.refreshCells({
        rowNodes: [...nodes, ...childrenNodes],
        force: true,
        columns: [field],
      });
    }
  }

  getAllParents(node: IRowNode) {
    const nodes: IRowNode[] = [];

    const getParent = (n: IRowNode) => {
      if (n.parent) {
        nodes.push(n.parent);
        if (n.parent.level > -1) {
          getParent(n.parent);
        }
      }
    };
    getParent(node);
    return nodes;
  }

  getAllGroupChildren(node: IRowNode) {
    const nodes: IRowNode[] = [];

    const getChildren = (n: IRowNode) => {
      n.childrenAfterGroup?.forEach((nn) => {
        if (nn.group) {
          nodes.push(nn);
          getChildren(nn);
        }
      });
    };
    getChildren(node);
    return nodes;
  }
}
