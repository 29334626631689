<aux-table-skeleton *ngIf="dataSource.loading$ | async" />

<div [hidden]="dataSource.loading$ | async">
  <ag-grid-angular
    [id]="idTable"
    class="ag-theme-aux mb-4 tabular-nums"
    domLayout="autoHeight"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (firstDataRendered)="sizeColumnsToFit()"
    (gridSizeChanged)="sizeColumnsToFit(); gridSizeChanged()"
    (filterChanged)="updateGridLayout()"
    (sortChanged)="onSortChanged()"
  />
</div>

<aux-pagination-panel
  [gridApi]="gridAPI"
  [paginationPageSize]="gridOptions.paginationPageSize || 10"
  [totalItems]="(dataSource.totalRows$ | async)!"
  (paginationChange)="paginationChange.emit()"
/>

<!--
  Additional hidden grid for exports
-->
<div [hidden]="true" *ngIf="exportGridOptions">
  <ag-grid-angular class="ag-theme-aux tabular-nums" [gridOptions]="exportGridOptions" />
</div>
