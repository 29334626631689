import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  ChangeDetectorRef,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  AfterContentInit,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RadioButtonComponent } from './radio-button.component';

const RADIOGROUP_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioButtonGroupComponent),
  multi: true,
};

let nextId = 0;

@Component({
  selector: 'aux-radio-button-group',
  template: `<ng-content></ng-content>`,
  styles: [],
  providers: [RADIOGROUP_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonGroupComponent
  implements ControlValueAccessor, OnDestroy, AfterContentInit, OnChanges
{
  // eslint-disable-next-line no-plusplus
  readonly UNIQUE_ID = `ngx-radio-group-${++nextId}`;

  @Input() id: string = this.UNIQUE_ID;

  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  @Input()
  get value(): boolean {
    return this._value;
  }

  set value(value) {
    if (this._value !== value) {
      this._value = value;
      this._updateSelectedRadioFromValue();
      this._updateRadioDisabledState();
      this.onChangeCallback(this._value);
    }
  }

  @Input()
  get name() {
    return this._name;
  }

  set name(name: string) {
    if (this._name !== name) {
      this._name = name;
      this._updateRadioButtonNames();
    }
  }

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<boolean>();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<Event>();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus = new EventEmitter<FocusEvent>();

  @ContentChildren(forwardRef(() => RadioButtonComponent), { descendants: true })
  readonly _radios: QueryList<RadioButtonComponent> | undefined;

  get selected(): RadioButtonComponent | undefined {
    return this._selected;
  }

  private _name: string = this.UNIQUE_ID;

  private _value = false;

  private _selected: RadioButtonComponent | undefined;

  private _disabled = false;

  private _destroy$ = new Subject<void>();

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  ngAfterContentInit() {
    this.subscribeToRadios();

    if (this._radios) {
      this._radios.changes.subscribe(this.subscribeToRadios.bind(this));
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnChanges() {
    this._updateRadioDisabledState();
  }

  subscribeToRadios(): void {
    this._destroy$.next();

    if (this._radios) {
      this._radios.forEach((radio) => {
        radio.change.pipe(takeUntil(this._destroy$)).subscribe(() => {
          this.onRadioSelected.bind(this);
        });
      });
    }

    this._cdr.markForCheck();
  }

  onRadioSelected(value: boolean) {
    if (this.value !== value) {
      setTimeout(() => {
        this.value = value;
      });
    }
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  onChangeCallback!: (p: boolean) => void;

  onTouchedCallback() {}

  private _updateRadioButtonNames(): void {
    if (this._radios) {
      this._radios.forEach((radio) => {
        // eslint-disable-next-line no-param-reassign
        radio.name = this.name;
      });
    }
  }

  private _updateSelectedRadioFromValue(): void {
    if (this._radios) {
      this._radios.forEach((radio) => {
        // eslint-disable-next-line no-param-reassign
        radio.checked = this.value === radio.value;

        if (radio.checked) {
          this._selected = radio;
        }
      });
    }
  }

  private _updateRadioDisabledState(): void {
    if (this._radios) {
      this._radios.forEach((radio) => {
        // eslint-disable-next-line no-param-reassign
        radio.groupDisabled = this.disabled;
      });
    }
  }
}
