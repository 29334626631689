<form
  *ngIf="availableGroups as items"
  class="ml-1 w-72"
  [formGroup]="blendedCurveForm"
  (ngSubmit)="saveBlendedCurve()"
>
  <div class="text-lg font-bold mb-4">{{ labelTopic }} {{ title }}</div>

  <div class="text-xs font-bold mb-4">{{ subTitle }}</div>

  <div *ngFor="let item of items; index as index" class="text-sm mb-4">
    <aux-checkbox
      class="mb-3 text-sm"
      [checked]="item.check"
      (customChange)="setValue(item, $event)"
    >
      {{ item.name }}
    </aux-checkbox>
  </div>

  <div class="text-sm grid">
    <aux-input
      formControlName="blendedCurveName"
      validators="required"
      [label]="'Blended Curve Name'"
      [showRequiredAsterisk]="true"
    />
  </div>

  <div class="mt-8 flex space-x-4 justify-between">
    <button class="text-sm font-normal focus:outline-none" type="button" (click)="ref.close()">
      Cancel
    </button>
    <button class="text-sm btn btn--blue" type="submit">
      <span *ngIf="(loading$ | async) === true" class="spinner w-6 h-6 mr-3"></span>
      <span>{{ labelButton }} Curve</span>
    </button>
  </div>
</form>
