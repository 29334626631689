<form class="font-inter w-screen max-w-2xl" [formGroup]="fg" (submit)="onUpload()">
  <div class="mb-8 text-xl font-bold">{{ edit ? 'Edit' : 'Upload' }} Change Order</div>

  <div class="grid grid-cols-2 gap-5">
    <div class="flex flex-col justify-start">
      <div>
        <div class="mb-2 text-xs">Vendor</div>
        <ng-select
          placeholder="Select"
          id="vendors"
          formControlName="organization_id"
          class="select select__big"
          label="Vendor"
          auxFormError
          [appendTo]="'body'"
          [searchable]="true"
          [clearable]="false"
        >
          <ng-option *ngFor="let vendor of vendorQuery.allVendors$ | async" [value]="vendor.id">
            <span [title]="vendor.name">{{ vendor.name }}</span>
          </ng-option>
        </ng-select>
      </div>

      <div>
        <aux-input
          class="mt-2 text-xs"
          formControlName="change_order_no"
          label="Change Order #"
          validators="required"
        />
      </div>

      <div>
        <aux-input
          class="mt-2 text-xs"
          formControlName="date_received"
          label="Date Received"
          placeholder="YYYY-MM-DD"
          validators="required"
          [type]="'date'"
        />
      </div>

      <div>
        <aux-input
          class="mt-2 text-xs"
          formControlName="effective_date"
          label="Effective Date"
          placeholder="YYYY-MM-DD"
          validators="required"
          [type]="'date'"
        />
      </div>

      <div>
        <aux-input
          label="Change Order Notes"
          formControlName="notes"
          class="col-span-6 mt-2 text-xs"
          [textArea]="true"
          [rows]="3"
        />
      </div>
    </div>
    <div class="flex flex-col">
      <div class="mb-2 text-xs ml-2">Upload Change Order</div>
      <aux-file-manager #fileManager class="h-56 ml-2 mb-2" [eager]="false" [path]="''" />
      <div class="ml-2 text-xs">Uploaded Files</div>
      <ng-container *ngIf="fileManager.fileQuery.selectAll() | async as files">
        <ng-container *ngIf="files.length">
          <ng-container *ngFor="let file of files; let last = last" class="border-b">
            <div class="flex justify-between items-center py-2" [ngClass]="{ 'border-b': !last }">
              <div class="items-start text-xs break-all pl-2">
                {{ file.fileName }}
              </div>

              <button
                class="btn rounded-full border-0 shadow-none p-0 w-6 h-6"
                (click)="removeFile(file)"
              >
                <aux-icon name="Trash" [size]="16" class="text-aux-error" />
              </button>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div *ngIf="error_message$ | async as msg" class="bg-aux-error mt-4 p-4 rounded-md text-white">
    <div>{{ msg }}</div>
  </div>

  <div class="mt-4 flex justify-between border-t">
    <button
      class="text-sm font-normal aux-link focus:outline-none"
      type="button"
      (click)="cancelOverlay()"
    >
      Cancel
    </button>
    <aux-button
      variant="custom"
      classList="w-48 text-sm btn btn--blue mt-2"
      type="submit"
      [disabled]="loading$ | async"
      [loading]="loading$ | async"
      [spinnerSize]="6"
      label="{{ edit ? 'Save' : 'Upload' }}"
    />
  </div>
</form>
