import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { PatientCurveStore, PatientCurveState } from './patient-curve.store';

@QueryConfig({
  sortBy: 'rank_order',
  sortByOrder: Order.ASC,
})
@Injectable({ providedIn: 'root' })
export class PatientCurveQuery extends QueryEntity<PatientCurveState> {
  constructor(protected store: PatientCurveStore) {
    super(store);
  }
}
