import { Injectable } from '@angular/core';
import { MoneyPipe } from '@pipes/money.pipe';
import {
  CellClassParams,
  ColDef,
  GridOptions,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { TableConstants } from 'src/app/constants/table.constants';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { ForecastTableGridCategoryComponent } from '../components/forecast-table-grid-category/forecast-table-grid-category.component';
import { ForecastTableGridDriverComponent } from '../components/forecast-table-grid-driver/forecast-table-grid-driver.component';
import { ForecastTableGridMethodComponent } from '../components/forecast-table-grid-method/forecast-table-grid-method.component';
import { ForecastTableGridPeriodComponent } from '../components/forecast-table-grid-period/forecast-table-grid-period.component';
import { Currency } from '@services/gql.service';

@Injectable()
export class ForecastTableGridColumnsService {
  gridOptions: GridOptions = {
    headerHeight: TableConstants.HEADER_HEIGHT,
    rowHeight: 55,
    suppressCopyRowsToClipboard: true,
    suppressClipboardPaste: true,
    enableRangeSelection: false,
    suppressMultiRangeSelection: true,
    enableCellTextSelection: true,
    suppressContextMenu: true,
    groupDefaultExpanded: 1,
    autoGroupColumnDef: {
      field: 'categoryName',
      headerName: 'Category Name',
      headerClass: 'ag-header-align-center font-bold',
      minWidth: 325,
      flex: 2,
      menuTabs: [],
      suppressMovable: true,
      resizable: true,
      pinned: 'left',
      sort: 'asc',
      initialSort: 'asc',
      comparator: (a, b) => {
        const categories = ['Services', 'Discount', 'Pass-through', 'Investigator'];
        return categories.indexOf(a) - categories.indexOf(b);
      },
      cellRendererSelector: (params) => {
        if (params.node.group) {
          return {
            component: 'agGroupCellRenderer',
            params: {
              suppressCount: true,
            },
          };
        }
        return {
          component: ForecastTableGridCategoryComponent,
        };
      },
      equals: (): boolean => {
        return false;
      },
    },
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      cellRenderer: AgCellWrapperComponent,
      sortable: false,
      cellClassRules: {
        'background-white': () => {
          return true;
        },
        'background-light': (params: CellClassParams) => {
          return !!params.node.group;
        },
      },
    },
  };

  gridColumns = (orgCurrency: Currency): ColDef[] => [
    {
      field: 'costCategoryType',
      hide: true,
      rowGroup: true,
    },
    {
      field: 'isOpen',
      hide: true,
    },
    {
      field: 'categoryName',
      headerClass: 'ag-header-align-center font-bold',
      minWidth: 325,
      flex: 2,
      menuTabs: [],
      hide: true,
      suppressMovable: true,
      resizable: true,
      pinned: 'left',
      cellRenderer: ForecastTableGridCategoryComponent,
      equals: (): boolean => {
        return false;
      },
    },
    {
      field: 'total',
      headerClass: 'ag-header-align-center font-bold',
      type: 'rightAligned',
      minWidth: 125,
      flex: 1,
      menuTabs: [],
      suppressMovable: true,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams): string => {
        const moneyFormat = new MoneyPipe().transform(params.value, orgCurrency);
        return params.node?.group ? '' : String(moneyFormat);
      },
      cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
    },
    {
      field: 'remaining',
      headerClass: 'ag-header-align-center font-bold',
      type: 'rightAligned',
      minWidth: 125,
      flex: 1,
      menuTabs: [],
      suppressMovable: true,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams): string => {
        const moneyFormat = new MoneyPipe().transform(params.value, orgCurrency);
        return params.node?.group ? '' : String(moneyFormat);
      },
      cellClass: TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
    },
    {
      field: 'units',
      headerClass: 'ag-header-align-center font-bold',
      minWidth: 75,
      flex: 0.5,
      menuTabs: [],
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'uom',
      headerName: 'UoM',
      headerClass: 'ag-header-align-center font-bold',
      minWidth: 125,
      flex: 0.5,
      menuTabs: [],
      suppressMovable: true,
      resizable: true,
    },
    {
      field: 'driver',
      headerClass: 'ag-header-align-center font-bold',
      minWidth: 150,
      maxWidth: 150,
      menuTabs: [],
      suppressMovable: true,
      cellRenderer: ForecastTableGridDriverComponent,
      equals: (): boolean => {
        return false;
      },
    },
    {
      field: 'period',
      headerClass: 'ag-header-align-center font-bold',
      minWidth: 280,
      maxWidth: 280,
      menuTabs: [],
      suppressMovable: true,
      cellRenderer: ForecastTableGridPeriodComponent,
      equals: (): boolean => {
        return false;
      },
    },
    {
      field: 'method',
      headerClass: 'ag-header-align-center font-bold',
      minWidth: 230,
      maxWidth: 230,
      menuTabs: [],
      suppressMovable: true,
      cellRenderer: ForecastTableGridMethodComponent,
      equals: (): boolean => {
        return false;
      },
    },
  ];
}
