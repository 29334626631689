import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Option } from '@components/components.type';
import { AgControlComponent, AgInputComponentParams } from './ag-control.component';
import { DocumentLibraryConstants } from '../../document-library.constants';

interface AgDropdownComponentParams extends AgInputComponentParams {
  options: Option[];
}

@Component({
  template: `<div *ngIf="formGroup$ | async" [formGroup]="$any(formGroup$ | async)">
    <ng-select
      *ngIf="isReady$ | async"
      placeholder="Select"
      [multiple]="false"
      [appendTo]="'body'"
      [searchable]="false"
      [clearable]="true"
      [formControl]="formControl"
      [auxTooltip]="formControl.disabled ? documentLibraryConstants.DISABLED_INPUT_TOOLTIP : ''"
      (change)="changeHandler()"
    >
      <ng-option *ngFor="let option of params.options" [value]="option.value">
        <span [title]="option.label">{{ option.label }}</span>
      </ng-option>
    </ng-select>
  </div>`,
  styles: [
    `
      ::ng-deep .ng-dropdown-panel.ng-select-top {
        margin-bottom: 0.5rem;
        border-radius: 0.375rem;
      }

      ::ng-deep .ng-select-disabled .ng-select-container {
        cursor: not-allowed;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgDropdownComponent extends AgControlComponent {
  readonly documentLibraryConstants = DocumentLibraryConstants;

  params!: AgDropdownComponentParams;

  agInit(params: AgDropdownComponentParams): void {
    this.params = params;
    this.value = params.value;
    this.fieldName = params.colDef?.field;
    this.changeHandler = params.changeHandler;
    this.updateView(params);
  }
}
