<div class="flex flex-row justify-between items-center">
  <div class="text-sm mr-1">Vendor</div>

  <aux-vendor-dropdown
    [showAllOption]="true"
    [vendors]="$any(vendorList)"
    [formControl]="vendorFormControl"
    (onChange)="vendorChangeFn($event)"
    [loading]="vendorLoading"
  />
</div>
