import { NgModule } from '@angular/core';

import { StageBannerComponent } from './stage-banner/stage-banner.component';
import { StageIndicatorComponent } from './stage-indicator/stage-indicator.component';

const sharedComponents = [StageBannerComponent, StageIndicatorComponent] as const;

@NgModule({
  imports: [...sharedComponents],
  exports: [...sharedComponents],
})
export class FeaturesModule {}
