<ng-container *ngVar="changeLogQuery.selectActive() | async as log">
  <div *ngIf="showAnalyticsSection$ | async" class="grid grid-cols-5 gap-7.5 mt-8 mb-12">
    <div
      class="border rounded border-aux-gray-dark h-28 py-3 flex col-span-2 justify-around items-center"
    >
      <div class="flex flex-col justify-center rounded-l px-4 pr-1">
        <div class="font-bold text-aux-black">
          Change Log<br />
          Running Total ($)
        </div>
      </div>
      <div class="h-full bg-aux-gray-dark" style="width: 1px"></div>
      <div class="flex space-x-2 items-center justify-center rounded-r px-4 tabular-nums">
        <div class="flex space-x-2 items-center justify-center rounded-r px-4">
          <div *ngIf="log" class="text-lg font-bold text-aux-gray-darkest">
            {{ log.clis_total }}
          </div>
        </div>
      </div>
    </div>
    <div class="border rounded p-4 grid col-span-3 grid-cols-5">
      <div class="border-r font-bold flex items-center justify-center h-18">
        Change by <br />
        Cost Type
      </div>
      <div *ngIf="log" class="col-span-4 pl-5 flex flex-col justify-between tabular-nums">
        <div class="flex text-white font-bold text-xs w-full h-6">
          <div
            *ngIf="log.services_percent as percentage"
            class="px-2 py-1 text-center bg-aux-blue-dark rounded-l"
            [ngStyle]="{ flexGrow: percentage }"
            [ngClass]="{ 'rounded-r': percentage === 100 }"
          >
            {{ percentage }}%
          </div>
          <div
            *ngIf="log.investigator_fee_percent as percentage"
            class="px-2 py-1 text-center bg-aux-gray-dark-100"
            [ngStyle]="{ flexGrow: percentage }"
            [ngClass]="{ rounded: percentage === 100 }"
          >
            {{ percentage }}%
          </div>
          <div
            *ngIf="log.pass_thru_percent as percentage"
            class="px-2 py-1 text-center bg-aux-gray-dark rounded-r"
            [ngStyle]="{ flexGrow: percentage }"
            [ngClass]="{ 'rounded-l': percentage === 100 }"
          >
            {{ percentage }}%
          </div>
        </div>
        <div class="grid grid-cols-3">
          <div>
            <div class="flex items-center justify-center mb-2 mr-1">
              <div class="w-4 h-4 rounded-full bg-aux-blue-dark mr-2"></div>
              <div class="text-xs">Services</div>
            </div>
            <div class="flex items-center text-sm justify-center font-bold">
              {{ log.services_total }}
            </div>
          </div>
          <div>
            <div class="flex items-center justify-center mb-2 mr-1">
              <div class="w-4 h-4 rounded-full bg-aux-gray-dark-100 mr-2"></div>
              <div class="text-xs">Investigator</div>
            </div>
            <div class="flex items-center text-sm justify-center font-bold">
              {{ log.investigator_fees_total }}
            </div>
          </div>
          <div>
            <div class="flex items-center justify-center mb-2 mr-1">
              <div class="w-4 h-4 rounded-full bg-aux-gray-dark mr-2"></div>
              <div class="text-xs">Pass-through</div>
            </div>
            <div class="flex items-center text-sm justify-center font-bold">
              {{ log.pass_thru_total }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="{
      loading: changeLogQuery.selectLoading() | async
    } as obj"
  >
    <div *ngIf="obj.loading" class="flex items-center justify-center mt-16 text-3xl">
      <!--<div *ngIf="!obj.loading && obj.count === 0">No log found</div>-->
      <div *ngIf="obj.loading" class="my-8 spinner"></div>
    </div>

    <ng-container *ngIf="!obj.loading">
      <ng-container *ngIf="changeLogQuery.selectAll() | async">
        <div class="flex flex-wrap gap-4 items-center mb-4" style="width: 100%">
          <div class="flex space-x-4">
            <div class="flex space-x-4 ml-1">
              <aux-input
                class="w-60"
                placeholder="Search"
                icon="Search"
                [(ngModel)]="nameFilterValue"
              />
              <div class="flex items-center">
                <div class="text-sm mr-2">Vendor:</div>
                <aux-vendor-dropdown
                  [vendors]="$any(vendorsQuery.allVendors$ | async)"
                  [formControl]="selectedVendor"
                  (onChange)="onVendorSelected($event)"
                />
              </div>
            </div>
            <div class="flex items-center w-min max-w-xl">
              <div class="text-sm mr-2">Status:</div>
              <ng-select
                id="status"
                class="w-48 text-sm"
                appendTo="body"
                [formControl]="selectedStatus"
                [clearable]="false"
                [searchable]="false"
              >
                <ng-option [value]="''">All</ng-option>>
                <ng-option *ngFor="let status of this.statusConstant" [value]="status.value">
                  <div class="flex items-center whitespace-nowrap">
                    <div class="w-3 h-3 rounded-full mr-1" [ngClass]="status.circleClass"></div>
                    <div [ngClass]="status.textClass">{{ status.text }}</div>
                  </div>
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="grid grid-flow-col gap-x-1.5 my-1">
            <!-- todo??? className="col-span-1" -->
            <aux-export-excel-button
              [gridAPI]="gridAPI"
              [ignoreColsId]="['site_id', 'file_link']"
              [ignoreColsCallback]="getIgnoreCols"
              [excelOptions]="excelOptions"
            />
            <button
              *ngIf="
                log?.change_log_status === 'STATUS_OPEN' &&
                (changeLogQuery.selectActiveId() | async) !== null
              "
              class="btn--primary pl-3"
              type="button"
              (click)="onAddLine()"
            >
              <aux-icon name="CirclePlus" class="mr-2" />
              Add New
            </button>
            <div *ngIf="changeLogQuery.selectActiveId() | async" class="flex space-x-2">
              <button class="btn btn--blue text-sm" (click)="openUploadModal()">
                <aux-icon name="Plus" />
                <span>Upload New CIS Log</span>
              </button>
            </div>
          </div>
        </div>
      </ng-container>

      <ag-grid-angular
        domLayout="autoHeight"
        class="ag-theme-aux tabular-nums"
        [rowData]="log?.change_log_items || []"
        [gridOptions]="gridOptions"
        [quickFilterText]="nameFilterValue"
        (firstDataRendered)="onGridReady($event)"
        (columnResized)="autoSize()"
        (viewportChanged)="onWindowScroll()"
        (gridSizeChanged)="gridSizeChanged()"
      />
    </ng-container>
  </ng-container>
</ng-container>
