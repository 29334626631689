import { GridApi, IRowNode } from '@ag-grid-community/core';

export function AgEditFirstRow({
  gridApi,
  filterNodes = (nodes) => nodes,
}: {
  gridApi: GridApi;
  filterNodes?: (nodes: IRowNode[]) => IRowNode[];
}): boolean {
  const nodes = filterNodes(gridApi.getRenderedNodes());
  const openNodes = nodes.filter((z) => !z.group);
  if (openNodes.length) {
    for (const node of openNodes) {
      const cell = AgFindTheFirstEditableCell({
        node,
        gridApi,
      });
      if (cell) {
        gridApi.startEditingCell({
          colKey: cell.colKey,
          rowIndex: cell.rowIndex,
        });
        return true;
      }
    }
  } else if (nodes.filter((z) => !!z.group).length) {
    AgExpandAllRows({ gridApi });
    return AgEditFirstRow({
      gridApi,
      filterNodes,
    });
  }
  return false;
}

// after this method call gridApi.onGroupExpandedOrCollapsed();
export function AgExpandRowRecursively(node: IRowNode) {
  if (node.isExpandable()) {
    node.setExpanded(true);
    if (node.childrenAfterGroup?.length) {
      AgExpandRowRecursively(node.childrenAfterGroup[0]);
    }
  }
}

export function AgExpandAllRows({
  gridApi,
  expanded = true,
}: {
  gridApi: GridApi;
  expanded?: boolean;
}) {
  gridApi.forEachNode((node) => {
    node.expanded = expanded;
  });
  gridApi.onGroupExpandedOrCollapsed();
}

export function AgFindTheFirstEditableCell({
  gridApi,
  node,
}: {
  node: IRowNode;
  gridApi: GridApi;
}) {
  const columns = gridApi.getAllDisplayedColumns();
  if (node.rowIndex == null) {
    return null;
  }
  for (const column of columns) {
    if (column.isCellEditable(node)) {
      return {
        rowIndex: node.rowIndex,
        colKey: column,
      };
    }
  }
  return null;
}
