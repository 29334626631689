import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface PaymentQuarterData {
  payment: number;
  payment_abs: number;
  wp: number;
  wp_abs: number;
  delta: number;
}

export interface PaymentScheduleState {
  PAYMENT_SCHEDULE_NEXT_PAYMENT_MILESTONE: {
    amount: number;
    target_date: string;
  };
  PAYMENT_SCHEDULE_PREPAID_BALANCE_IN_ESCROW: { amount: number };
  PAYMENT_SCHEDULE_MILESTONES: {
    date: string;
    name: string;
    organization_name: string;
    amount: number;
  }[];
  PAYMENT_SCHEDULE_CUMULATIVE_PAYMENTS_VS_CUMULATIVE_WP: Record<string, PaymentQuarterData>;
  PAYMENT_SCHEDULE_CASH_REQUIREMENTS: {
    pm_total: number;
    expense_months_back: string;
  }[];
}

export function createInitialState(): PaymentScheduleState {
  return {
    PAYMENT_SCHEDULE_NEXT_PAYMENT_MILESTONE: {
      amount: 0,
      target_date: '',
    },
    PAYMENT_SCHEDULE_PREPAID_BALANCE_IN_ESCROW: { amount: 0 },
    PAYMENT_SCHEDULE_MILESTONES: [],
    PAYMENT_SCHEDULE_CUMULATIVE_PAYMENTS_VS_CUMULATIVE_WP: {},
    PAYMENT_SCHEDULE_CASH_REQUIREMENTS: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'payment-schedule' })
export class PaymentScheduleStore extends Store<PaymentScheduleState> {
  constructor() {
    super(createInitialState());
  }
}
