import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ChangeLogStore, ChangeLogState } from './change-log.store';

@Injectable({ providedIn: 'root' })
export class ChangeLogQuery extends QueryEntity<ChangeLogState> {
  constructor(protected store: ChangeLogStore) {
    super(store);
  }
}
