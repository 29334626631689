import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ValuesType } from '@services/utils';
import { DocumentType, GqlService, listDocumentsQuery } from '@services/gql.service';
import { map } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { batchPromises } from '@shared/utils';
import { File } from '@components/file-manager/state/file.model';
import { MainQuery } from '../../../layouts/main-layout/state/main.query';

@Injectable()
export class ExtractorService {
  constructor(
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService
  ) {}

  getDocuments(): Observable<ValuesType<listDocumentsQuery['items']>[]> {
    return this.gqlService
      .listDocuments$({
        // TODO: currently we don't have pagination, so that's why end_row hardcoded
        end_row: 10000,
        start_row: 0,
      })
      .pipe(
        map<GraphqlResponse<listDocumentsQuery>, ValuesType<listDocumentsQuery['items']>[]>(
          ({ data, errors, success }) => {
            if (data?.items && success) {
              return data?.items;
            }
            this.overlayService.error(errors);

            return [];
          }
        )
      );
  }

  async extractFiles(
    files: File[],
    documentTypeId: DocumentType,
    siteId: string | null
  ): Promise<boolean> {
    const trialId = this.mainQuery.getValue().trialKey;

    const extractResult = await batchPromises(
      files.map((file) =>
        firstValueFrom(
          this.gqlService.createStoredDocument$({
            source_documents: file?.rawFile
              ? JSON.stringify([{ bucket_key: `public/${file.bucket_key}` }])
              : JSON.stringify([
                  { bucket_key: this.getNewBucketKeyForDocLibFiles(file, documentTypeId) },
                ]),
            document_type_id: documentTypeId,
            site_id: siteId,
            entity_id: file?.rawFile ? file.entity_id : file.id,
            trial_id: trialId,
            doclib_key: file?.rawFile ? null : this.getDocLibKey(file),
          })
        )
      ),
      (p) => p
    );

    const hasUploadError = extractResult.some((err) => err instanceof Error || !!err.errors.length);

    if (hasUploadError) {
      this.overlayService.error('Files extract error!');
      return false;
    } else {
      this.overlayService.success(
        `${extractResult.length} file${extractResult.length > 1 ? 's' : ''} extraction in progress!`
      );
      return true;
    }
  }

  private getNewBucketKeyForDocLibFiles(file: File, documentTypeId: DocumentType): string {
    return `public/document-storage/upload/${documentTypeId}/${file.id}/${file.fileName}`;
  }

  private getDocLibKey(file: File): string {
    if (file.bucket_key.startsWith('public/')) {
      return file.bucket_key;
    }

    return `public/${file.bucket_key}`;
  }
}
