<div class="mb-2 w-full">
  <!-- Header bar -->
  <div class="trial-insights-header-bar" [style.background-color]="color"></div>

  <!-- Header -->
  <div class="flex items-center pt-3">
    <!-- Icon -->
    <div class="trial-insights-header-icon">
      <ng-content select="[icon]"></ng-content>
    </div>

    <!-- Title -->
    <div class="flex flex-col flex-grow">
      <span class="text-sm font-medium">{{ title }}</span>
      <span class="text-2xl font-bold">{{ header }}</span>
    </div>

    <!-- Additional Content -->
    <div class="mr-2">
      <ng-content select="[additionalContent]"></ng-content>
    </div>
  </div>
</div>
