<div class="grid grid-cols-3 gap-7.5">
  <div class="border rounded p-4 grid grid-cols-4 col-span-2 h-28">
    <div class="border-r font-bold flex items-center">Activities by Driver</div>

    <ng-template #activitiesByDriverLoader>
      <ng-container *ngTemplateOutlet="analyticsSectionLoader" />
    </ng-template>

    <ng-container
      *ngIf="(analyticsSectionLoading$ | async) === false; else activitiesByDriverLoader"
    >
      <div
        *ngIf="forecastQuery.select('activitiesByDriver') | async as activitiesByDriver"
        class="col-span-3 pl-5 flex flex-col gap-3 justify-between tabular-nums"
      >
        <div class="flex text-white font-bold text-xs w-full h-6">
          <div
            *ngIf="activitiesByDriver.Time.percentage as percentage"
            class="px-2 py-1 text-center bg-aux-blue rounded-l"
            [ngStyle]="{ flexGrow: percentage }"
            [ngClass]="{ 'rounded-r': percentage === 100 }"
          >
            {{ percentage }}%
          </div>
          <div
            *ngIf="activitiesByDriver.Patient.percentage as percentage"
            class="px-2 py-1 text-center bg-aux-green"
            [ngStyle]="{ flexGrow: percentage }"
            [ngClass]="{ rounded: percentage === 100 }"
          >
            {{ percentage }}%
          </div>
          <div
            *ngIf="activitiesByDriver.Site.percentage as percentage"
            class="px-2 py-1 text-center bg-aux-blue-light rounded-r"
            [ngStyle]="{ flexGrow: percentage }"
            [ngClass]="{ 'rounded-l': percentage === 100 }"
          >
            {{ percentage }}%
          </div>
        </div>

        <div class="grid grid-cols-3">
          <div>
            <div class="flex items-center justify-center mb-2 mr-1">
              <div class="w-4 h-4 rounded-full bg-aux-blue mr-2"></div>
              <div class="text-xs">Time</div>
            </div>
            <div class="text-sm font-bold flex items-center justify-center mb-2 mr-1">
              {{ activitiesByDriver.Time.costStr }}
            </div>
          </div>

          <div>
            <div class="flex items-center justify-center mb-2 mr-1">
              <div class="w-4 h-4 rounded-full bg-aux-green mr-2"></div>
              <div class="text-xs">Patient</div>
            </div>
            <div class="text-sm font-bold flex items-center justify-center mb-2 mr-1">
              {{ activitiesByDriver.Patient.costStr }}
            </div>
          </div>

          <div>
            <div class="flex items-center justify-center mb-2 mr-1">
              <div class="w-4 h-4 rounded-full bg-aux-blue-light mr-2"></div>
              <div class="text-xs">Site</div>
            </div>

            <div class="text-sm font-bold flex items-center justify-center mb-2 mr-1">
              {{ activitiesByDriver.Site.costStr }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="border flex items-stretch justify-between">
    <ng-template #unforecastedCostsLoader>
      <ng-container *ngTemplateOutlet="analyticsSectionLoader" />
    </ng-template>

    <ng-container
      *ngIf="(analyticsSectionLoading$ | async) === false; else unforecastedCostsLoader"
    >
      <div class="p-3 flex bg-aux-gray-light items-center text-aux-blue-dark">
        Unforecasted Costs
      </div>

      <div class="p-3 text-xl flex items-center text-aux-blue-dark">
        <aux-icon
          name="AlertTriangle"
          class="text-aux-red-dark mr-3 flex-shrink-0"
          *ngIf="forecastQuery.select('unforecastedCosts') | async"
        />
        {{ forecastQuery.select('unforecastedCosts') | async | money: orgCurrency }}
      </div>
    </ng-container>
  </div>
</div>

<ng-template #analyticsSectionLoader>
  <div class="w-full col-span-3 flex items-center justify-center">
    <div class="border-4 m-auto spinner"></div>
  </div>
</ng-template>
