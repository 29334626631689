<div class="w-screen sm:max-w-2xl md:max-w-3xl lg:max-w-4xl sm:max-h-100 md:max-h-130 lg:max-h-158">
  <div class="space-y-2.5 p-4">
    <ng-container *ngIf="(isQuarterCloseEnabled$ | async) === false">
      <div>Before you close, please confirm:</div>

      <div *ngFor="let step of steps; index as i" class="flex items-center">
        <div
          class="w-6 h-6 border bg-aux-gray-light mr-2.5 rounded-full flex items-center justify-center"
        >
          {{ i + 1 }}
        </div>
        <div>{{ step }}</div>
      </div>
    </ng-container>

    <div>
      Clicking “Close Month” will convert the {{ ref.data?.formatted_current_month }} Accruals to
      Actuals. Please <br />
      leave a note.
    </div>

    <aux-input
      label="Note"
      class="text-xs"
      [textArea]="true"
      [rows]="2"
      [validators]="'required'"
      [formControl]="textarea"
      [showRequiredAsterisk]="true"
    />

    <div
      *ngIf="(showNetsuiteMessage$ | async) === true"
      style="padding-top: 15px; padding-bottom: 15px"
    >
      <div
        style="
          border-top: 0.5px solid var(--aux-gray-dark-100);
          border-bottom: 0.5px solid var(--aux-gray-dark-100);
        "
      >
        <aux-checkbox class="mb-3 text-sm mt-3" [formControl]="sendToNetsuite">
          Create Journal Entry in NetSuite General Ledger
        </aux-checkbox>
      </div>
    </div>
  </div>
</div>

<div class="pt-5 bg-white">
  <hr />
</div>

<div class="bg-white pt-5 flex justify-between">
  <button class="focus:outline-none" type="button" (click)="ref.close()">Cancel</button>
  <button class="btn--success" data-pendo-id="close-month" (click)="close(true)">
    Close {{ ref.data?.formatted_current_month }}
  </button>
</div>
