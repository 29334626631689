<span
  #trigger="cdkOverlayOrigin"
  class="text-sm font-normal aux-link cursor-pointer whitespace-nowrap"
  cdkOverlayOrigin
  [ngClass]="{ 'text-aux-gray-dark-100 cursor-not-allowed': !optionalFilters.length }"
  (click)="!!optionalFilters.length && toggleOpenList(!isOpen)"
  (auxClickOutside)="toggleOpenList(false)"
  >+ Add Filter</span
>
<ng-template
  cdkConnectedOverlay
  class="border-solid border-aux-gray-dark-100"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
  <ul
    tabindex="-1"
    class="bg-white border border-aux-gray-dark text-base overflow-auto mt-2 rounded w-56"
  >
    <ng-container *ngFor="let filter of optionalFilters; index as index" class="grid">
      <li
        class="text-gray-900 cursor-pointer select-none relative flex flex-col p-2.5 hover:bg-aux-gray-light"
        (click)="selectFilter(filter)"
      >
        <p class="font-inter">
          {{ filter }}
        </p>
      </li>
    </ng-container>
  </ul>
</ng-template>
