import { MainQuery } from './../../layouts/main-layout/state/main.query';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { FileManagerComponent } from './file-manager.component';
import { File } from './state/file.model';
import { OverlayService } from '@services/overlay.service';
import { AuthQuery } from '@models/auth/auth.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { listUserNamesWithEmailQuery, User } from '@services/gql.service';
import { Utils } from '@services/utils';

@UntilDestroy()
@Component({
  selector: 'aux-file-viewer',
  templateUrl: './file-viewer.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileViewerComponent implements OnInit {
  @Input() fileManager: FileManagerComponent | undefined;

  @Input() onlyShowUploaded = true;

  @Input() disableFirstFileMargin = false;

  @Input() removeOnlyFromStore = false;

  @Output() removeFileChange = new EventEmitter<File>();

  @Input() showUserAndDate = false;

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  files$: Observable<File[]> | undefined;

  loading$: Observable<boolean> | undefined;

  constructor(
    private overlayService: OverlayService,
    private authQuery: AuthQuery,
    private mainQuery: MainQuery
  ) {
    this.mainQuery
      .select('userList')
      .pipe(untilDestroyed(this))
      .subscribe((_users) => {
        _users.forEach((user: listUserNamesWithEmailQuery) => {
          this.users.set(user.sub, user);
        });
      });
  }

  ngOnInit(): void {
    if (this.fileManager) {
      this.files$ = this.fileManager.fileQuery.selectAll({
        filterBy: (entity) => (this.onlyShowUploaded ? entity.uploaded : true),
      });
      this.loading$ = this.fileManager.fileQuery.selectLoading();
    }
  }

  async onDownload(file: File) {
    if (file) {
      if (!file.rawFile) {
        const fileSuccess = this.fileManager?.apiService.downloadFileFromPath(
          file.bucket_key,
          file.fileName
        );
        if (!fileSuccess) {
          this.overlayService.error('There was a problem downloading the file!');
        }
      } else {
        const newBlob = new Blob([file.rawFile]);
        this.fileManager?.apiService.downloadBlob(newBlob, file.fileName);
      }
    } else {
      this.overlayService.error('There was a problem downloading the file!');
    }
  }

  async onRemove(file: File) {
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Document',
      message: `Are you sure you want to remove this document?`,
      okBtnText: 'Remove',
    });
    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data?.result) {
      if (!this.removeOnlyFromStore) {
        const success = this.fileManager?.removeFile(file);
        if (success) {
          this.overlayService.success(`${file.fileName} removed!`);
        }
        return;
      }
      this.fileManager?.fileService.removeFromStore(file);
      this.fileManager?.resetUploadInput();
      this.removeFileChange.emit(file);
    }
  }

  userFormatter(sub: string | undefined) {
    const user = this.users.get(sub || '');
    if (user) {
      const isUserAuxAdmin = user.email.includes('@auxili.us');
      if (this.authQuery.isAuxAdmin() || !isUserAuxAdmin) {
        return `${user.given_name} ${user.family_name}`;
      }
      return 'Auxilius Expert';
    }
    return Utils.zeroHyphen;
  }
}
