import { GridOptions, RowClassParams } from '@ag-grid-community/core';
import { ActionParams, ActionsComponent } from './actions.component';

export const removeRow = async (params: ActionParams) => {
  params.api.applyTransaction({
    remove: [params.node.data],
  });
};

export const editRow = async () => {};

export const checkboxGridOptions: GridOptions = {
  rowHeight: 35,
  headerHeight: 45,
  rowClassRules: {
    'is-even': (params: RowClassParams) => {
      return !!((params.node.rowIndex || 0) % 2);
    },
    'is-odd': (params: RowClassParams) => {
      return !((params.node.rowIndex || 0) % 2);
    },
  },
  columnDefs: [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 80,
      suppressMenu: true,
    },
    {
      headerName: '',
      field: 'actions',
      suppressMenu: true,
      cellClass: 'cell-justify-center',
      cellRenderer: ActionsComponent,
      cellRendererParams: {
        editClickFN: editRow(),
        deleteClickFN: (params: ActionParams) => {
          removeRow(params);
        },
      },
      width: 65,
    },
    {
      headerName: 'Header',
      headerClass: 'ag-header-align-center font-bold',
      suppressMenu: true,
      cellClass: 'text-left',
      field: 'field1',
      width: 250,
    },
    {
      headerName: 'Header',
      headerClass: 'ag-header-align-center font-bold',
      suppressMenu: true,
      cellClass: 'text-left',
      field: 'field2',
      width: 250,
    },
    {
      headerName: 'Header',
      headerClass: 'ag-header-align-center font-bold',
      suppressMenu: true,
      cellClass: 'text-left',
      field: 'field3',
      width: 300,
    },
  ],
};
