import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface FormValuesState {
  patientBudget: {
    site_ids: string[];
    selectedCurrencies: {
      isContractCurrency: boolean;
      isPrimaryCurrency: boolean;
    };
  };
  patientTracker: {
    site_ids: string[];
    selectedCurrencies: {
      isContractCurrency: boolean;
      isPrimaryCurrency: boolean;
    };
  };
}

export function createInitialState(): FormValuesState {
  return {
    patientBudget: {
      site_ids: [],
      selectedCurrencies: {
        isContractCurrency: true,
        isPrimaryCurrency: true,
      },
    },
    patientTracker: {
      site_ids: [],
      selectedCurrencies: {
        isContractCurrency: true,
        isPrimaryCurrency: true,
      },
    },
    ...JSON.parse(localStorage.getItem('formValues') || '{}'),
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'form-values' })
export class FormValuesStore extends Store<FormValuesState> {
  constructor() {
    super(createInitialState());
  }
}
