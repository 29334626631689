import * as dayjs from 'dayjs';
import { ChartConfiguration, ChartOptions, LegendOptions, Scale, TooltipItem } from 'chart.js';
import { ChartDataset, TooltipOptions } from 'chart.js/dist/types';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { TrialInsightsLegendOptions } from '../../models/trial-insights-legend.model';
import { TrialTimelineMilestoneCategoryDates } from '../../models/trial-insights.model';

export function createLegend(labels?: string[]): TrialInsightsLegendOptions {
  const legendOptions = {
    compact: true,
    centered: true,
    divider: true,
    color: '#f3f6f7',
    data: [],
  } as TrialInsightsLegendOptions;

  if (!labels?.length) {
    return legendOptions;
  }

  const colors = ['#226262', '#437f7f', '#4e6d79'];

  labels.forEach((label, index) => {
    legendOptions.data.push({
      displayIcon: true,
      displayDivider: false,
      iconColor: colors[index],
      valueColor: colors[index],
      title: label,
      value: '',
    });
  });

  return legendOptions;
}

export function defaultChart(): ChartConfiguration<'bar'> {
  return {
    type: 'bar',
    data: {
      datasets: [],
    },
    options: {},
  };
}

export function chartOptions(
  totalDays: number,
  quarters: string[],
  timelineStart: string
): ChartConfiguration<'bar'>['options'] {
  return {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    elements: elementOptions(),
    scales: {
      ...xAxesOptions(totalDays, quarters, timelineStart),
      ...yAxesOptions(),
    },
    plugins: pluginOptions(),
  };
}

export function pluginOptions(): ChartOptions<'bar'>['plugins'] {
  return {
    tooltip: {
      ...tooltipOptions(),
    },
    legend: legendOptions(),
  };
}

export function tooltipOptions(): DeepPartial<TooltipOptions<'bar'>> {
  return {
    callbacks: {
      label(tooltipItem: TooltipItem<'bar'>) {
        const raw = tooltipItem.raw as [number, number];
        const totalDays = raw[1] - raw[0];
        const totalMonths = dayjs().add(totalDays, 'days').diff(dayjs(), 'months');

        return `Months: ${totalMonths}`;
      },
    },
  };
}

export function elementOptions(): ChartOptions<'bar'>['elements'] {
  return {
    bar: {
      borderColor: '#bacad0',
    },
  };
}

export function legendOptions(): DeepPartial<LegendOptions<'bar'>> {
  return {
    display: false,
  };
}

export function xAxesOptions(
  totalDays: number,
  quarters: string[],
  timelineStart: string
): ChartOptions<'bar'>['scales'] {
  return {
    x1: {
      stacked: true,
      beginAtZero: true,
      max: totalDays,
      title: {
        display: false,
      },
      grid: {
        color: '#bacad0',
        lineWidth: 0.75,
        z: 2,
      },
      ticks: {
        stepSize: 91,
        autoSkip: false,
        callback: (_, index) => {
          return quarters[index];
        },
      },
    },
    // this is only for the black line in the chart
    x2: {
      beginAtZero: true,
      max: totalDays,
      grid: {
        color: 'black',
        lineWidth: 1.5,
        tickLength: 10,
        z: 1,
        drawTicks: false,
      },
      ticks: {
        stepSize: 91,
        display: false,
        callback: () => '',
      },
      afterBuildTicks(axis: Scale) {
        const startDate = dayjs(timelineStart);
        const fromToday = dayjs().diff(startDate, 'days');

        axis.ticks = fromToday > totalDays ? [] : [{ value: fromToday }];
      },
    },
  };
}

export function yAxesOptions(): ChartOptions<'bar'>['scales'] {
  return {
    y: {
      display: false,
    },
  };
}

export function chartData(milestoneCategoryDates: TrialTimelineMilestoneCategoryDates[]) {
  const timelineStart = dayjs(milestoneCategoryDates[0].startDate);

  return milestoneCategoryDates.reduce(
    (accumulator, milestoneDates) => {
      // Buffer days (for stacked chart)
      const milestoneStart = dayjs(milestoneDates.startDate);
      const buffer = milestoneStart.diff(timelineStart, 'days');

      // Total days
      const total = milestoneDates.totalDays;

      accumulator.push([buffer, total + buffer]);

      return accumulator;
    },
    [] as [number, number][]
  );
}

export function createDatasets(
  milestoneCategoryDates: TrialTimelineMilestoneCategoryDates[]
): ChartDataset<'bar'>[] {
  return [
    {
      data: chartData(milestoneCategoryDates),
      backgroundColor: ['#226262', '#437f7f', '#4e6d79'],
      hoverBackgroundColor: ['#3da0a0', '#489898', '#548092'],
      barThickness: 35,
      xAxisID: 'x1',
    },
  ];
}
