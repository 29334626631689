import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { BehaviorSubject } from 'rxjs';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

interface IAgCheckboxParams extends ICellRendererParams {
  getDisabledState?: () => boolean;
  dontSelectRow?: boolean;
  isHidden?: boolean;
  tooltip?: string;
}

@Component({
  template: `
    <div *ngIf="!params.isHidden" class="flex items-center justify-center" style="height: 30px">
      <aux-checkbox
        [disabled]="(disabled$ | async) === true"
        [indeterminate]="indeterminate"
        [(ngModel)]="val"
        (ngModelChange)="onChange($event)"
        [auxTooltip]="(disabled$ | async) === true ? tooltip : ''"
      />
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCheckboxComponent implements ICellRendererAngularComp {
  val!: boolean;

  params!: IAgCheckboxParams;

  disabled$ = new BehaviorSubject(false);

  indeterminate = false;

  tooltip = '';

  refresh(): boolean {
    return false;
  }

  agInit(params: IAgCheckboxParams) {
    this.params = params;
    this.val = params.value;

    if (params.tooltip) {
      this.tooltip = params.tooltip;
    }

    if (params.value === 'indeterminate') {
      this.indeterminate = true;
      this.val = false;
    }

    if (params.getDisabledState) {
      this.disabled$.next(params.getDisabledState());
    }
  }

  onChange(e: boolean) {
    this.indeterminate = false;
    if (this.params.colDef?.field) {
      this.params.node.setDataValue(this.params.colDef.field, e);
    }

    if (!this.params.dontSelectRow) {
      this.params.node.setSelected(e);
    }
  }
}
