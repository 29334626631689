import { ColDef, ColGroupDef, ProcessCellForExportParams } from '@ag-grid-community/core';
import { AgActionsComponent } from '@components/ag-actions/ag-actions.component';

export class TableConstants {
  static readonly NO_ROWS_MESSAGE = 'No Rows To Show';

  static readonly NO_ROWS_CO_MESSAGE =
    'There are currently no Change Orders available for this trial or specified vendor.';

  static readonly NO_ROWS_ON_FILTERING_MESSAGE =
    'No results found. Please adjust your search criteria or filters.';

  static readonly HEADER_HEIGHT = 45;

  static readonly ACTIONS_WIDTH = 65;

  static readonly STYLE_CLASSES = {
    HEADER_ALIGN_CENTER: 'ag-header-align-center',
    CELL_ALIGN_RIGHT: 'ag-cell-align-right',
    CELL_ALIGN_LEFT: 'ag-cell-align-left',
    CELL_VERTICAL_HORIZONTAL_ALIGN_CENTER: 'ag-cell-align-center',
    CELL_ALIGN_CENTER: 'cell-align-center',
    IS_ODD: 'is-odd',
    IS_EVEN: 'is-even',
    CELL_JUSTIFY_CENTER: 'cell-justify-center',
    EXCEL_NUMBER: 'number',
    NOT_EDITABLE_CELL: 'ag-cell-not-edit-cell',
    EDITABLE_CELL: 'ag-cell-edit-mode',
    NOT_EDITABLE_HEADER: 'ag-header-cell',
    EDITABLE_HEADER: 'ag-header-edit-mode',
    CELL_AUTO_HEIGHT: 'cell-auto-height',
  };

  static readonly AG_SYSTEM = {
    GROUP_ROWS: 'groupRows',
    CUSTOM: 'custom',
    AG_GROUP_CELL_RENDERER: 'agGroupCellRenderer',
  };

  static readonly FIELDS = {
    ACTIONS: 'actions',
  };

  static readonly PROCESS_CELL_FOR_CLIPBOARD = {
    processCellForClipboard: (params: ProcessCellForExportParams) => {
      return params.value;
    },
  };

  static readonly DEFAULT_GRID_OPTIONS = {
    DEFAULT_COL_DEF: {
      resizable: false,
      suppressMenu: true,
      suppressMovable: true,
      headerClass: TableConstants.STYLE_CLASSES.HEADER_ALIGN_CENTER,
    },
    GRID_OPTIONS: {
      headerHeight: TableConstants.HEADER_HEIGHT,
      rowHeight: 35,
      columnDefs: [],
      suppressCellFocus: true,
      stopEditingWhenCellsLoseFocus: true,
      ...TableConstants.PROCESS_CELL_FOR_CLIPBOARD,
    },
    ACTIONS_COL_DEF: {
      headerName: '',
      field: TableConstants.FIELDS.ACTIONS,
      cellRenderer: AgActionsComponent,
      width: TableConstants.ACTIONS_WIDTH,
      suppressSizeToFit: true,
      suppressFillHandle: true,
      editable: false,
      cellClass: TableConstants.STYLE_CLASSES.CELL_JUSTIFY_CENTER,
    },
    EDIT_GRID_OPTIONS: {
      enableRangeSelection: true,
      undoRedoCellEditingLimit: 20,
      undoRedoCellEditing: true,
      suppressMenuHide: true,
      enterNavigatesVertically: true,
      enterNavigatesVerticallyAfterEdit: true,
      ...TableConstants.PROCESS_CELL_FOR_CLIPBOARD,
    },
  };

  static SPACER_COLUMN: ColDef | ColGroupDef = {
    headerClass: 'ag-invisible',
    cellClass: 'ag-invisible',
    colId: 'spacerColumn',
    children: [
      {
        headerClass: 'ag-invisible',
        cellClass: 'ag-invisible',
        width: 5,
        maxWidth: 5,
        colId: 'spacerColumn',
      },
    ],
  };

  static SPACER_COLUMN_NO_PARENT: ColDef | ColGroupDef = {
    headerClass: 'ag-invisible',
    cellClass: 'ag-invisible',
    colId: 'spacerColumn',
    width: 5,
    maxWidth: 5,
  };

  static readonly SCROLL = {
    MAX_TABLE_HEIGHT: '400px',
    MAX_TABLE_HEIGHT_LG: '700px',
    MAX_ROWS_WITH_TOTAL: 9,
    MAX_ROWS_WITHOUT_TOTAL: 10,
  };

  static dynamicColumnProps(headerName: string, headerTooltipText?: string): ColDef {
    return {
      headerName,
      minWidth: 100,
      resizable: true,
      headerClass: ['whitespace-nowrap', TableConstants.STYLE_CLASSES.HEADER_ALIGN_CENTER],
      headerTooltip: headerTooltipText || headerName,
    };
  }
}
