import { Injectable } from '@angular/core';
import { ExpenseType, listInvestigatorForecastContractedAmountsQuery } from '@services/gql.service';
import { ContractedInvestigatorAmountValues } from '../investigator-forecast.types';
import { decimalAdd, decimalAddAll, decimalDifference, decimalDivide } from '@shared/utils';

enum Invoiceables {
  PATIENT = 'Patient Invoiceables',
  SITE = 'Site Invoiceables',
}

@Injectable({ providedIn: 'root' })
export class InvestigatorContractedAmountService {
  private forecastTotalExpenseTypes: ExpenseType[] = [ExpenseType.EXPENSE_QUOTE];

  private getVisitCostsByExpenseType(
    expenses: listInvestigatorForecastContractedAmountsQuery[],
    expenseType: ExpenseType
  ) {
    return expenses
      .filter(
        ({ activity_name, expense_type }) =>
          activity_name === 'Patient Visits' && expense_type === expenseType
      )
      .reduce((accum, { amount }) => accum + amount, 0);
  }

  private getInvoiceablesByCategory(
    expenses: listInvestigatorForecastContractedAmountsQuery[],
    category: string[],
    expenseType: ExpenseType
  ) {
    return expenses
      .filter(
        ({ activity_name, expense_type }) =>
          category.includes(activity_name) && expense_type === expenseType
      )
      .reduce((accum, { amount }) => accum + amount, 0);
  }

  private getForecastTotal(expenses: listInvestigatorForecastContractedAmountsQuery[]): number {
    return expenses
      .filter(({ expense_type }) => this.forecastTotalExpenseTypes.includes(expense_type))
      .reduce((accum, { amount }) => accum + amount, 0);
  }

  getSummaryValues(
    expenses: listInvestigatorForecastContractedAmountsQuery[]
  ): ContractedInvestigatorAmountValues {
    const totalInvestigatorCosts = this.getForecastTotal(expenses);
    const visitCostsToDate = this.getVisitCostsByExpenseType(expenses, ExpenseType.EXPENSE_WP);
    const invoiceablesToDate = this.getInvoiceablesByCategory(
      expenses,
      [Invoiceables.PATIENT, Invoiceables.SITE],
      ExpenseType.EXPENSE_WP
    );
    const spendToDateAmount = decimalAddAll(2, visitCostsToDate, invoiceablesToDate);

    const remainingAmount = decimalDifference(totalInvestigatorCosts, spendToDateAmount, 2);

    const totalVisitCosts = this.getVisitCostsByExpenseType(expenses, ExpenseType.EXPENSE_QUOTE);
    const otherInvoiceables = this.getInvoiceablesByCategory(
      expenses,
      [Invoiceables.PATIENT],
      ExpenseType.EXPENSE_QUOTE
    );
    const overheadInvoiceables = this.getInvoiceablesByCategory(
      expenses,
      [Invoiceables.SITE],
      ExpenseType.EXPENSE_QUOTE
    );

    const totalInvoiceables = decimalAdd(otherInvoiceables, overheadInvoiceables);
    const invoiceablesPerc = decimalDivide(totalInvoiceables, totalInvestigatorCosts, 2);

    return {
      totalVisitCosts,
      totalInvoiceables,
      invoiceablesPerc,
      totalInvestigatorCosts,
      spendToDateAmount,
      remainingAmount,
      otherInvoiceables,
      overheadInvoiceables,
      visitCostsToDate,
      invoiceablesToDate,
    };
  }
}
