import { BudgetData, BudgetHeader, BudgetInfo, BudgetType } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export type ExtendedBudgetData = {
  remaining_cost: number;
  remaining_percentage: number;
  wp_percentage: number;
  wp_cost: number;
  wp_unit_num: number;
  remaining_unit_num: number;
  accrual: number;
  forecast: number;
  total_monthly_accrual: number;
  adjustment: number;
  wp_ltd: number;
  current_lre: number;
  forecast_obj: Record<string, number>;
  accrual_obj: Record<string, number>;
  exchange_rate: Record<string, number>;
  accrual_adjusted_obj: Record<string, number>;
  adjustment_obj: Record<string, number>;
  accrual_override: Record<string, number>;
} & BudgetData &
  Record<string, number | string>;

export interface BudgetState {
  header_data: RequireSome<BudgetHeader, 'date_headers'>[];
  budget_info: BudgetInfo[];
  budget_data: ExtendedBudgetData[];
  current_data: ExtendedBudgetData[];
  pendingChanges: { [key: string]: string };
  budget_type: BudgetType;
  invoices: { [key: string]: { [key: string]: string } };
  work_performed: { [key: string]: { [key: string]: string } };
}

export function createInitialState(budget_type?: BudgetType): BudgetState {
  return {
    header_data: [],
    budget_data: [],
    budget_info: [],
    current_data: [],
    pendingChanges: { vendor_id: '' },
    budget_type: budget_type || BudgetType.BUDGET_PRIMARY,
    invoices: { vendor_id: { invoice_total: '$0' } },
    work_performed: { vendor_id: { invoice_total: '$0' } },
  };
}
