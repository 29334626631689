<div class="font-bold text-xl text-aux-black mt-8">Converted Files</div>

<div class="flex justify-between my-4">
  <div>
    <aux-input class="w-72" placeholder="Search" icon="Search" [(ngModel)]="searchValue" />
  </div>

  <div>
    <aux-button
      variant="secondary"
      label="Refresh"
      icon="Replace"
      [onClick]="refreshGridData.bind(this)"
    />
  </div>
</div>

<ng-container *ngIf="loadingGridData">
  <aux-table-skeleton />
</ng-container>

<div
  [ngClass]="{
    'invisible h-0 overflow-hidden': loadingGridData
  }"
>
  <ag-grid-angular
    domLayout="autoHeight"
    class="ag-theme-aux tabular-nums w-full"
    [quickFilterText]="searchValue"
    [gridOptions]="gridOptions"
    [rowData]="gridData$ | async"
    (gridReady)="onGridReady($event)"
    (firstDataRendered)="autosize()"
  />
</div>
