import { Injectable } from '@angular/core';
import { GqlService, UserTaskType } from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { switchMap, tap } from 'rxjs/operators';
import { combineLatest, EMPTY, firstValueFrom, Observable } from 'rxjs';
import { AuthQuery } from '@models/auth/auth.query';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserTasksStore } from './user-tasks.store';
import { MainQuery } from '../../layouts/main-layout/state/main.query';
import { ROUTING_PATH } from '../../app-routing-path.const';

export const MAP_PATH_BY_TASK_TYPE = new Map<
  UserTaskType,
  (taskId: string, vendorId: string) => string
>([
  [
    UserTaskType.CHANGE_ORDER,
    (taskId: string) =>
      `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}/${taskId}`,
  ],
  [
    UserTaskType.FORECAST_VENDOR_COSTS,
    (_, vendorId) =>
      `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}?vendor_id=${vendorId}`,
  ],
  [
    UserTaskType.PERIOD_CLOSE,
    () => `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`,
  ],
  [
    UserTaskType.VENDOR_ESTIMATE,
    () => `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`,
  ],
]);

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class UserTasksService {
  showInvoiceDetailLink$: Observable<boolean>;

  userTasksIsEnabled$: Observable<boolean>;

  userTasksIsEnabled = false;

  constructor(
    private mainQuery: MainQuery,
    private userTasksStore: UserTasksStore,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private ld: LaunchDarklyService,
    private authQuery: AuthQuery
  ) {
    this.showInvoiceDetailLink$ = this.ld.select$((flags) => !!flags.invoice_detail);

    this.userTasksIsEnabled$ = this.ld.select$((flags) => !!flags.section_task_list);

    this.showInvoiceDetailLink$.pipe(untilDestroyed(this)).subscribe((enabled) => {
      MAP_PATH_BY_TASK_TYPE.set(
        UserTaskType.INVOICE,
        (taskId: string) =>
          `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}${
            enabled ? `/${taskId.replace('Categorize Invoice| ', '')}` : ''
          }`
      );
    });

    this.userTasksIsEnabled$.pipe(untilDestroyed(this)).subscribe((enabled) => {
      this.userTasksIsEnabled = enabled;
    });
  }

  getUserTaskList$() {
    return this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => {
          this.userTasksStore.setLoading(true);

          const userId = this.authQuery.getValue();
          if (userId.trial_id && this.userTasksIsEnabled) {
            return combineLatest([this.gqlService.listUserTasks$()]);
          }
          return EMPTY;
        })
      )
      .pipe(
        tap(([{ data, errors }]) => {
          this.userTasksStore.set(data || []);

          if (errors) {
            this.overlayService.error(errors);
          }

          this.userTasksStore.setLoading(false);
        })
      );
  }

  async triggerUserTaskList$() {
    if (this.userTasksIsEnabled) {
      this.userTasksStore.setLoading(true);
      const { success, data, errors } = await firstValueFrom(this.gqlService.listUserTasks$());
      if (success && data) {
        this.userTasksStore.set(data || []);
      }
      if (errors) {
        this.overlayService.error(errors);
      }
      this.userTasksStore.setLoading(false);
    }
  }
}
