<div class="font-inter min-w-[600px] max-w-2xl">
  <div class="mb-2 text-xl font-bold">Add Multiple Vendors</div>
  <div class="mb-4">Separate vendor names by using line breaks</div>

  <ng-container *ngIf="(isLoading$ | async) === true">
    <div class="border-8 h-32 m-auto my-16 spinner w-32"></div>
  </ng-container>

  <ng-container *ngIf="(isLoading$ | async) === false">
    <aux-input #vendors class="vendors-textarea" [textArea]="true" />
  </ng-container>

  <div class="flex justify-between mt-8">
    <aux-button variant="custom" classList="text-sm h-full" label="Cancel" (click)="ref.close()" />
    <aux-button variant="primary" label="Add Vendors" [onClick]="addVendors" [spinnerSize]="6" />
  </div>
</div>
