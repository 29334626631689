import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrialInsightsQuery } from '../../store/trial-insights.query';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { TimelineQuery } from 'src/app/pages/forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.query';
import { FormControl } from '@angular/forms';
import { Option } from '@components/components.type';
import { AnalyticData } from './trial-insights-finance-analytic-card.component';
import { Utils } from '@services/utils';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import * as dayjs from 'dayjs';
import { DEFAULT_ANALYTIC_CARDS } from './analytic-card.const';
import { TrialInsightsFinanceVariancesService } from './trial-insights-finance-variances.service';
import { isEqual } from 'lodash-es';
import { TrialInsightsStore } from '../../store/trial-insights.store';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-finance-comparing-budget',
  templateUrl: './trial-insights-finance-comparing-budget.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsFinanceComparingBudgetComponent implements OnInit, OnDestroy {
  fromDateControl = new FormControl('');

  toDateControl = new FormControl('');

  trialRangeDate$: Observable<Option[]> = this.timelineQuery
    .selectTimelineRange('MMM YYYY')
    .pipe(map((range) => range.map((date) => ({ label: date, value: date }))));

  fromRange: Option[] = [];

  fromRange$ = combineLatest([this.trialRangeDate$, this.toDateControl.valueChanges]).pipe(
    map(([trialRange, toDate]) => {
      return trialRange.filter(({ value }) => {
        return toDate === value ? true : dayjs(value).isBefore(dayjs(toDate));
      });
    })
  );

  toRange: Option[] = [];

  toRange$ = combineLatest([this.trialRangeDate$, this.fromDateControl.valueChanges]).pipe(
    map(([trialRange, fromDate]) => {
      return trialRange.filter(({ value }) => {
        return fromDate === value ? true : dayjs(value).isAfter(dayjs(fromDate));
      });
    })
  );

  currentOpenMonth$ = this.trialInsightsQuery.select('currentOpenMonth').pipe(
    map((currentOpenMonth) => {
      if (!currentOpenMonth) {
        return '';
      }

      return currentOpenMonth ? dayjs(currentOpenMonth).format('MMM YYYY') : '';
    })
  );

  snapshotName$ = this.trialInsightsQuery
    .select((state) => state.bvaChart.snapshotName)
    .pipe(
      map((snapshotName) => {
        return snapshotName || Utils.zeroHyphen;
      })
    );

  budgetLink = `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`;

  analyticCards$ = new BehaviorSubject<AnalyticData[]>(DEFAULT_ANALYTIC_CARDS);

  constructor(
    private trialInsightsQuery: TrialInsightsQuery,
    private timelineQuery: TimelineQuery,
    private trialInsightsFinanceVariancesService: TrialInsightsFinanceVariancesService,
    private trialInsightsStore: TrialInsightsStore,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fetchVariances();

    this.fromRange$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.fromRange = value;
      this.changeDetector.detectChanges();
    });
    this.toRange$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.toRange = value;
      this.changeDetector.detectChanges();
    });

    // SET DEFAULT DATES
    combineLatest([this.trialRangeDate$, this.currentOpenMonth$])
      .pipe(untilDestroyed(this), distinctUntilChanged(isEqual))
      .subscribe(([timeline, currentOpenMonth]) => {
        if (currentOpenMonth && timeline[0]) {
          this.fromDateControl.setValue(timeline[0].value);

          const value = dayjs(currentOpenMonth).subtract(1, 'month');

          // check if subtracted date in timeline range
          const validDate = value.isAfter(timeline[0].value)
            ? value.format('MMM YYYY')
            : timeline[0].value;

          this.toDateControl.setValue(validDate);
        }
      });
  }

  ngOnDestroy() {
    this.trialInsightsStore.reset();
  }

  private fetchVariances() {
    combineLatest([
      this.trialInsightsQuery.select((state) => state.bvaChart.vendorId),
      this.snapshotName$,
      this.fromDateControl.valueChanges,
      this.toDateControl.valueChanges,
    ])
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this),
        tap(([, snapshotName]) => {
          if (snapshotName === Utils.zeroHyphen || !snapshotName) {
            this.analyticCards$.next(DEFAULT_ANALYTIC_CARDS);
          }
        }),
        filter(([, snapshotName, fromDate, toDate]) => {
          return !!snapshotName && snapshotName !== Utils.zeroHyphen && !!toDate && !!fromDate;
        }),
        switchMap(([vendorId, snapshotName, fromDate, toDate]) => {
          return this.trialInsightsFinanceVariancesService.getListActivityVariances(
            vendorId || null,
            snapshotName,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            fromDate!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            toDate!
          );
        })
      )
      .subscribe((analyticCards) => {
        this.analyticCards$.next(analyticCards);
      });
  }
}
