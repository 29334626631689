import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { TableConstants } from '@constants/table.constants';
import { AuxExcelStyleKeys } from '@shared/utils';

export const PATIENT_DATE_COLUMNS: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Visit Day',
    field: 'target_date_days_out',
    minWidth: 150,
    pinned: 'left',
    valueFormatter: Utils.preserveZeroDashFormatter,
    cellClass: [AuxExcelStyleKeys.NUMBER],
  },
  {
    headerName: 'Visit Window [+/-]',
    field: 'target_tolerance_days_out',
    minWidth: 150,
    pinned: 'left',
    valueFormatter: Utils.dashFormatter,
    cellClass: [AuxExcelStyleKeys.NUMBER],
  },
];

export const getVisitInformationColumn = (): (ColDef | ColGroupDef)[] => [
  {
    headerName: 'Visit Information',
    headerClass: 'ag-header-align-center',
    children: [
      {
        headerName: 'Name',
        field: 'patient_protocol_name',
        tooltipField: 'patient_protocol_name',
        minWidth: 250,
        pinned: 'left',
        cellClass: 'text-left',
        resizable: true,
      },
    ],
  },
  TableConstants.SPACER_COLUMN,
  {
    headerName: 'patient_protocol_id',
    field: 'patient_protocol_id',
    colId: 'patient_protocol_id',
    hide: true,
  },
];

export const COST_COLUMN_PROPS: ColDef = {
  minWidth: 125,
  aggFunc: 'sum',
  valueFormatter: Utils.agCurrencyFormatter,
  cellClass: ['cost'],
};
