import { Route, Routes } from '@angular/router';
import { CanDeactivateGuard } from '@services/can-deactivate.guard';
import { buildRoutesByFeatureFlags, FeatureFlag } from '@models/feature-flag.model';
import { Flags } from '@services/launch-darkly.service';
import { ForecastAccrualsPageComponent } from './forecast-accruals-page.component';
import { ForecastComponent } from './tabs/forecast/forecast.component';
import { SpecificationsComponent } from './tabs/specifications/specifications.component';
import { TimelineGroupComponent } from './tabs/timeline-group/timeline-group.component';
import { ROUTING_PATH } from '../../app-routing-path.const';
import { TimelineComponent } from './tabs/timeline-group/timeline/timeline.component';
import { PaymentMilestonesComponent } from '../vendor-payments-page/tabs/payment-milestones/payment-milestones.component';
import { PatientCurvesComponent } from './tabs/forecast/drivers/patients/patient-curves.component';
import { ForecastSiteCurvesComponent } from './tabs/forecast/drivers/forecast-sites/forecast-site-curves.component';
import { PatientDriversComponent } from './tabs/forecast/drivers/patient-drivers.component';
import { PatientGroupsComponent } from './tabs/forecast/drivers/patients/patient-groups/patient-groups.component';
import { ForecastSiteGroupsComponent } from './tabs/forecast/drivers/forecast-sites/forecast-site-groups.component';
import { ForecastSiteDriversComponent } from './tabs/forecast/drivers/forecast-site-drivers.component';
import { InvestigatorForecastComponent } from './tabs/investigator-forecast/investigator-forecast.component';

export const FORECAST_ROUTES_FF: Routes = [
  {
    path: ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX,
    component: PatientDriversComponent,
    data: {
      feature: FeatureFlag.FORECAST_DRIVERS,
    },
    children: [
      {
        path: ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES,
        component: PatientCurvesComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.GROUPS,
        component: PatientGroupsComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: '**',
        redirectTo: ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES,
      },
    ],
  },
  {
    path: ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX,
    component: ForecastSiteDriversComponent,
    data: {
      feature: FeatureFlag.FORECAST_DRIVERS,
    },
    children: [
      {
        path: ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES,
        component: ForecastSiteCurvesComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.GROUPS,
        component: ForecastSiteGroupsComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: '**',
        redirectTo: ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES,
      },
    ],
  },
  {
    path: ROUTING_PATH.FORECAST_ROUTING.INVESTIGATOR_FORECAST,
    component: InvestigatorForecastComponent,
    canDeactivate: [CanDeactivateGuard],
    data: {
      feature: FeatureFlag.SITES_INVESTIGATOR_FORECAST,
    },
  },
  {
    path: ROUTING_PATH.FORECAST_ROUTING.STUDY_SPECIFICATIONS,
    component: SpecificationsComponent,
    data: {
      feature: FeatureFlag.FORECAST_STUDY_SPECS,
    },
  },
];

export const buildForecastRoutes = (featureFlags: Flags | null): Route => {
  const enabledRoutes: Routes = [
    {
      path: ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY,
      component: ForecastComponent,
      canDeactivate: [CanDeactivateGuard],
    },
    {
      path: ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX,
      component: TimelineGroupComponent,
      children: [
        {
          path: ROUTING_PATH.FORECAST_ROUTING.TIMELINE.TRIAL_TIMELINE,
          component: TimelineComponent,
          canDeactivate: [CanDeactivateGuard],
        },
        {
          path: ROUTING_PATH.FORECAST_ROUTING.TIMELINE.PAYMENT_MILESTONES,
          component: PaymentMilestonesComponent,
        },
        {
          path: '**',
          redirectTo: ROUTING_PATH.FORECAST_ROUTING.TIMELINE.TRIAL_TIMELINE,
        },
      ],
    },
    ...buildRoutesByFeatureFlags(featureFlags, FORECAST_ROUTES_FF),
  ];

  return {
    path: ROUTING_PATH.FORECAST_ROUTING.INDEX,
    component: ForecastAccrualsPageComponent,
    children: [
      ...enabledRoutes,
      { path: '**', redirectTo: ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY },
    ],
  };
};
