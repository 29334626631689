import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { TopCard } from '../patient-curves.constants';
import { CommonModule } from '@angular/common';
import { AgPatientDriverTopCardComponent } from '../ag-patient-driver-top-card/ag-patient-driver-top-card.component';

@Component({
  standalone: true,
  imports: [AgPatientDriverTopCardComponent, CommonModule],
  selector: 'aux-ag-patient-driver-upper-cards',
  templateUrl: './ag-patient-driver-upper-cards-component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgPatientDriverUpperCardsComponent {
  @Input() topCards: Observable<TopCard[]> | undefined;
}
