import { EventQuery } from '@models/event/event.query';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  CellClassParams,
  CellClickedEvent,
  CellValueChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  ModelUpdatedEvent,
  RowNode,
  ColDef,
  GetQuickFilterTextParams,
  ValueFormatterParams,
  RowSelectedEvent,
  ValueGetterParams,
  ValueSetterParams,
  IRowNode,
  SuppressKeyboardEventParams,
  FillOperationParams,
} from '@ag-grid-community/core';
import { OverlayService } from '@services/overlay.service';
import { StickyElementService } from '@services/sticky-element.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import {
  ApprovalType,
  DocumentType,
  EntityType,
  EventType,
  GqlService,
  InvoiceStatus,
  PermissionType,
  User,
  WorkflowStep,
} from '@services/gql.service';
import { ExportType, Utils } from '@services/utils';
import { Option } from '@components/components.type';
import { ApiService } from '@services/api.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable } from 'rxjs';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { EventService } from 'src/app/services/event.service';
import { AuthQuery } from '@models/auth/auth.query';

import * as dayjs from 'dayjs';
import { AgDatePickerComponent } from '@components/datepicker/ag-date-picker/ag-date-picker.component';
import { cloneDeep, differenceWith, isBoolean, isEqual, isString } from 'lodash-es';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { AuthService } from '@models/auth/auth.service';
import { TableConstants } from '@constants/table.constants';
import { MessagesConstants } from '@constants/messages.constants';
import { AgInvoiceActionsComponent } from './ag-invoice-actions/ag-invoice-actions.component';
import { NewInvoiceDialogComponent } from './new-invoice-dialog/new-invoice-dialog.component';
import { UploadDocumentsDialogComponent } from './upload-documents-dialog/upload-documents-dialog.component';
import { InvoiceService } from './state/invoice.service';
import { InvoiceQuery } from './state/invoice.query';
import { InvoicesStatusComponent } from './invoices-status.component';
import { PaymentStatusComponent } from './payment-status.component';
import { AgHeaderActionsComponent } from './ag-invoice-actions/ag-header-actions.component';
import { InvoicesGridFormatterService } from './invoices-grid-formatter.service';
import { InvoiceModel } from './state/invoice.model';
import { WorkflowQuery } from '../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import {
  EditMultipleInvoicesModalComponent,
  EditMultipleInvoicesModalData,
} from './edit-multiple-invoices-modal/edit-multiple-invoices-modal';
import { OrganizationQuery } from '@models/organization/organization.query';
import { DocumentLibraryFile } from 'src/app/pages/documents/document-library.service';
import { batchPromises, AgSetColumnsVisible } from '@shared/utils';
import { BudgetCurrencyType } from '../../../budget-page/tabs/budget-enhanced/budget-type';
import { AgCheckboxComponent } from '@components/ag-actions/ag-checkbox.component';

interface IInvoicesGridData extends InvoiceModel {
  file?: DocumentLibraryFile;
  hasError?: boolean;
  approved_by?: string | null;
  requireCostBreakdown: boolean;
}

@UntilDestroy()
@Component({
  selector: 'aux-invoices',
  templateUrl: './invoices.component.html',
  styles: [
    `
      :host ::ng-deep .ag-theme-aux .ag-rich-select-list {
        height: 100% !important;
      }
      ::ng-deep .ag-cell.aux-link {
        color: var(--aux-blue);
      }
      ::ng-deep .ag-cell.aux-link-zero-hyphen {
        color: var(--aux-blue);
      }
    `,
  ],
})
export class InvoicesComponent implements OnInit, OnDestroy {
  getCellClass = () => (params: CellClassParams) => {
    if (params.data.organization.currency) {
      return `budgetCost${params.data.organization.currency}`;
    }
    return 'budgetCostNoSymbol';
  };

  numberOfVendorCurrencies = 0;

  selectedBudgetCurrencyType$ = new BehaviorSubject<BudgetCurrencyType>(BudgetCurrencyType.VENDOR);

  isVendorCurrency = true;

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES;

  isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

  isClosingPanelEnabled$ = this.launchDarklyService.select$(
    (flags) => flags.closing_checklist_toolbar
  );

  iCloseMonthsProcessing$ = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  isAdminUser$ = this.authQuery.adminUser$.pipe(untilDestroyed(this));

  isInvoiceFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType(this.workflowName);

  invoiceLockTooltip$ = this.workflowQuery.invoiceLockTooltip$;

  isEditModeEnabled$ = new BehaviorSubject(false);

  isSavingChanges$ = new BehaviorSubject(false);

  overlayNoRowsTemplate = TableConstants.NO_ROWS_MESSAGE;

  userHasEditInvoicePermission = false;

  userHasDeleteInvoicePermission = false;

  userHasApproveInvoicePermission = false;

  gridOptions$ = new BehaviorSubject<GridOptions>(this.getGridOptions());

  gridData$ = new BehaviorSubject<IInvoicesGridData[]>([]);

  gridAPI!: GridApi;

  showInvoicesPaymentStatus$: Observable<boolean>;

  showInvoicesPaymentDate$: Observable<boolean>;

  billComIntegrationEnabled$: Observable<boolean>;

  coupaIntegrationEnabled$: Observable<boolean>;

  dynamics365IntegrationEnabled$: Observable<boolean>;

  dynamics365FoIntegrationEnabled$: Observable<boolean>;

  netsuiteIntegrationEnabled$: Observable<boolean>;

  oracleFusionIntegrationEnabled$: Observable<boolean>;

  showInvoiceDetailLink$: Observable<boolean>;

  quickbooksOnlineIntegrationEnabled$: Observable<boolean>;

  sageIntacctIntegrationEnabled$: Observable<boolean>;

  invoicesDepositsEnabled$: Observable<boolean>;

  invoicesDepositsEnabled = false;

  filesLoading$ = new BehaviorSubject(false);

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  selectedRows: InvoiceModel[] = [];

  rowsToDelete: { invoice: InvoiceModel; reason: string }[] = [];

  initialValues: IInvoicesGridData[] = [];

  hasChanges$ = new BehaviorSubject(false);

  showErrors$ = new BehaviorSubject(false);

  apiErrors$ = new BehaviorSubject<string[]>([]);

  shouldRefreshAfterNewInvoiceIsProcessed$ = new BehaviorSubject(false);

  shouldRefreshAfterNewLineItemsAreProcessed$ = new BehaviorSubject(false);

  vendorCurrencyEnabled$: Observable<boolean>;

  hasPaymentStatus = false;

  hasPaymentDate = false;

  btnLoading$ = new BehaviorSubject<'export' | false>(false);

  userHasLockInvoicesPermission = false;

  checkBoxTooltip = 'Please click Edit button in top right corner to edit invoice data.';

  paginationPageSize = 10;

  constructor(
    private apiService: ApiService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    public invoiceService: InvoiceService,
    private eventService: EventService,
    public invoiceQuery: InvoiceQuery,
    public authQuery: AuthQuery,
    private authService: AuthService,
    private organizationQuery: OrganizationQuery,
    private gqlService: GqlService,
    private invoicesGridFormatterService: InvoicesGridFormatterService,
    private workflowQuery: WorkflowQuery,
    private stickyElementService: StickyElementService,
    private launchDarklyService: LaunchDarklyService,
    private eventQuery: EventQuery
  ) {
    this.setUserPermissions();

    this.showInvoicesPaymentStatus$ = this.launchDarklyService.select$(
      (flags) => flags.invoices_payment_status
    );

    this.showInvoicesPaymentDate$ = this.launchDarklyService.select$(
      (flags) => flags.invoices_payment_date
    );

    this.billComIntegrationEnabled$ = this.launchDarklyService.select$(
      (flags) => !!flags.cronjob_bill_com_integration
    );

    this.coupaIntegrationEnabled$ = this.launchDarklyService.select$(
      (flags) => !!flags.cronjob_coupa_integration
    );

    this.dynamics365IntegrationEnabled$ = this.launchDarklyService.select$(
      (flags) => !!flags.cronjob_dynamics365_integration
    );

    this.dynamics365FoIntegrationEnabled$ = this.launchDarklyService.select$(
      (flags) => !!flags.cronjob_dynamics365_fo_integration
    );

    this.netsuiteIntegrationEnabled$ = this.launchDarklyService.select$(
      (flags) => !!flags.cronjob_netsuite_integration
    );

    this.oracleFusionIntegrationEnabled$ = this.launchDarklyService.select$(
      (flags) => !!flags.cronjob_oracle_fusion_integration
    );

    this.showInvoiceDetailLink$ = this.launchDarklyService.select$((flags) => flags.invoice_detail);

    this.quickbooksOnlineIntegrationEnabled$ = this.launchDarklyService.select$(
      (flags) => !!flags.cronjob_quickbooks_online_integration
    );

    this.sageIntacctIntegrationEnabled$ = this.launchDarklyService.select$(
      (flags) => !!flags.cronjob_sage_intacct_integration
    );

    this.vendorCurrencyEnabled$ = this.launchDarklyService.select$((flags) => {
      return flags.vendor_currency;
    });

    this.invoicesDepositsEnabled$ = this.launchDarklyService.select$((flags) => {
      return flags.invoices_deposits;
    });
  }

  ngOnInit() {
    this.selectedBudgetCurrencyType$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.isVendorCurrency = x === BudgetCurrencyType.VENDOR;

      if (!this.gridAPI) {
        return;
      }

      /*
        if the investigator total column is showing then the breakdown values are visible
        which means we want to keep the trial currency values hidden if the user toggles to trial currency
      */
      if (this.gridAPI.getColumn('expense_amounts.investigator_total.value')?.isVisible()) {
        AgSetColumnsVisible({
          gridApi: this.gridAPI,
          keys: [
            'organization.currency',
            'expense_amounts.invoice_total_trial_currency.exchange_rate',
            'expense_amounts.invoice_total_trial_currency.value',
            'expense_amounts.investigator_total_trial_currency.value',
            'expense_amounts.pass_thru_total_trial_currency.value',
            'expense_amounts.services_total_trial_currency.value',
            'expense_amounts.discount_total_trial_currency.value',
          ],
          visible: !this.isVendorCurrency,
        });
      } else {
        AgSetColumnsVisible({
          gridApi: this.gridAPI,
          keys: [
            'organization.currency',
            'expense_amounts.invoice_total_trial_currency.exchange_rate',
            'expense_amounts.invoice_total_trial_currency.value',
          ],
          visible: !this.isVendorCurrency,
        });
      }
      setTimeout(() => {
        this.invoiceService.generatePinnedRow();
      }, 0);
    });

    this.invoiceService.initialize().pipe(untilDestroyed(this)).subscribe();

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.invoiceService.setAccrualPeriodsAndVendorFilter();
        this.overlayNoRowsTemplate = TableConstants.NO_ROWS_MESSAGE;
      });

    this.eventService
      .select$(EventType.INVOICE_UPDATED)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.invoiceService.newInvoiceCreated$.getValue()) {
          this.shouldRefreshAfterNewInvoiceIsProcessed$.next(true);
          this.invoiceService.newInvoiceCreated$.next(false);
        }
        if (this.invoiceService.invoiceDesignationChanged$.getValue()) {
          this.shouldRefreshAfterNewLineItemsAreProcessed$.next(true);
          this.invoiceService.invoiceDesignationChanged$.next(false);
        }
      });

    merge(
      this.eventService.select$(EventType.REFRESH_BILL_COM),
      this.eventService.select$(EventType.REFRESH_COUPA),
      this.eventService.select$(EventType.REFRESH_DYNAMICS365),
      this.eventService.select$(EventType.REFRESH_DYNAMICS365_FO),
      this.eventService.select$(EventType.REFRESH_NETSUITE),
      this.eventService.select$(EventType.REFRESH_ORACLE_FUSION),
      this.eventService.select$(EventType.REFRESH_QUICKBOOKS_ONLINE),
      this.eventService.select$(EventType.REFRESH_SAGE_INTACCT),
      this.eventService.select$(EventType.BULK_INVOICE_TEMPLATE_UPLOADED),
      this.invoiceService.redirectedWithAnyRenderedNodes$,
      this.apiErrors$,
      this.shouldRefreshAfterNewInvoiceIsProcessed$
    )
      .pipe(
        startWith([]),
        distinctUntilChanged((prevState, nextState) => {
          if (isBoolean(nextState) && !nextState) {
            return true;
          }

          return isEqual(prevState, nextState);
        }),
        switchMap(() => {
          return this.invoiceService.get();
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.setGridData();
      });

    this.shouldRefreshAfterNewLineItemsAreProcessed$
      .pipe(
        switchMap(() => {
          return this.invoiceService.get();
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.setGridData();
      });

    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
      }),
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_DELETE_INVOICE],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasEditInvoicePermission, userHasDeleteInvoicePermission]) => {
        this.userHasEditInvoicePermission = userHasEditInvoicePermission;
        this.userHasDeleteInvoicePermission = userHasDeleteInvoicePermission;
      });

    combineLatest([
      this.showInvoicesPaymentStatus$,
      this.showInvoicesPaymentDate$,
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_APPROVE_INVOICE],
      }),
      this.showInvoiceDetailLink$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
          showInvoicesPaymentStatus,
          showInvoicesPaymentDate,
          userHasApproveInvoicePermission,
          showInvoiceDetailLink,
        ]) => {
          this.userHasApproveInvoicePermission = userHasApproveInvoicePermission;
          this.gridOptions$.next(this.getGridOptions());

          if (showInvoicesPaymentDate) {
            const momentaryGrid = this.gridOptions$.getValue();
            const paymentDateColumn: ColDef = {
              headerName: 'Payment Date',
              headerTooltip: 'Payment Date',
              field: 'payment_date',
              valueFormatter: Utils.agDateFormatter,
              minWidth: 170,
              width: 170,
              suppressSizeToFit: true,
              cellClass: ['text-right'],
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
              tooltipValueGetter: (params) => params.valueFormatted,
              cellEditor: AgDatePickerComponent,
            };
            const hasPaymentDateCol = momentaryGrid.columnDefs?.filter(
              (col) => col.headerName === paymentDateColumn.headerName
            ).length;

            if (!hasPaymentDateCol) {
              const paymentColIdx = 17;

              momentaryGrid.columnDefs?.splice(paymentColIdx, 0, paymentDateColumn);
              this.gridOptions$.next(momentaryGrid);
              this.hasPaymentDate = true;
            }
          }

          if (showInvoicesPaymentStatus) {
            const currentGrid = this.gridOptions$.getValue();
            const paymentColumn: ColDef = {
              headerName: 'Payment Status',
              headerTooltip: 'Payment Status',
              field: 'payment_status',
              colId: 'payment_status',
              cellRenderer: PaymentStatusComponent,
              minWidth: 175,
              maxWidth: 175,
              getQuickFilterText: (params: GetQuickFilterTextParams) =>
                this.invoicesGridFormatterService.getFormattedPaymentStatus(params.value),
              cellClass: 'text-left',
              tooltipValueGetter: (params) =>
                this.invoicesGridFormatterService.getFormattedPaymentStatus(
                  params.data.payment_status
                ),
              cellEditor: 'agRichSelectCellEditor',
              cellEditorParams: () => {
                return {
                  values: this.invoiceService.paymentStatusOptions,
                  cellRenderer: PaymentStatusComponent,
                };
              },
            };
            const hasPaymentCol = currentGrid.columnDefs?.filter(
              (col) => col.headerName === paymentColumn.headerName
            ).length;

            if (!hasPaymentCol) {
              const paymentColIdx = 18;
              currentGrid.columnDefs?.splice(paymentColIdx, 0, paymentColumn);
              this.gridOptions$.next(currentGrid);
              this.hasPaymentStatus = true;
            }
          }

          const currentGrid = this.gridOptions$.getValue();
          const invoiceNumberColumn: ColDef = {
            headerName: 'Invoice #',
            headerTooltip: 'Invoice #',
            field: 'invoice_no',
            tooltipField: 'invoice_no',
            valueFormatter: Utils.dashFormatter,
            onCellClicked: (event: CellClickedEvent) => {
              if (!this.isEditModeEnabled$.getValue() && showInvoiceDetailLink) {
                this.invoiceService.goToInvoiceDetail(event);
              }
            },
            cellClass: (value) => {
              const classes: string[] = ['text-left'];
              if (!value.node.rowPinned && showInvoiceDetailLink) {
                classes.push('cursor-pointer');
                if (value.data.invoice_no !== null) {
                  classes.push('aux-link');
                } else {
                  classes.push('aux-link-zero-hyphen');
                }
              }
              return classes;
            },
            cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
            minWidth: 175,
            width: 175,
          };

          const invoiceNumberColIdx = 3;

          currentGrid.columnDefs?.splice(invoiceNumberColIdx, 0, invoiceNumberColumn);
          this.gridOptions$.next(currentGrid);
        }
      );

    this.isEditModeEnabled$.pipe(untilDestroyed(this)).subscribe((isEditModeEnabled) => {
      if (isEditModeEnabled) {
        this.gridAPI.startEditingCell({
          rowIndex: 0,
          colKey: 'invoice_no',
        });
      }
      this.gridAPI?.setColumnVisible('checkbox', isEditModeEnabled);
      this.gridAPI?.refreshCells({ force: true });
    });

    this.invoicesDepositsEnabled$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.invoicesDepositsEnabled = value;
      this.gridAPI?.setColumnVisible('is_deposit', value);
    });
  }

  setGridData() {
    this.invoiceQuery
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe((listData) => {
        const currencies = new Set();
        const gridData: IInvoicesGridData[] = listData.map((invoice) => {
          currencies.add(invoice.organization.currency);
          const formattedInvoice = {
            ...invoice,
            file: null,
            accrual_period: invoice.accrual_period
              ? dayjs(invoice.accrual_period).format('YYYY-MM')
              : invoice.accrual_period,
            approved_by: invoice.approvals ? invoice.approvals[0]?.aux_user_id : null,
            hasError: false,
            requireCostBreakdown:
              (invoice.expense_amounts.investigator_total.value === 0 &&
                invoice.expense_amounts.pass_thru_total.value === 0 &&
                invoice.expense_amounts.services_total.value === 0 &&
                invoice.expense_amounts.discount_total.value === 0) ||
              !this.invoiceService.isInvoiceTotalEqual({ data: invoice } as ValueSetterParams),
          };
          return { ...formattedInvoice, file: undefined };
        });

        this.numberOfVendorCurrencies = currencies.size;
        this.isVendorCurrency = true;
        this.selectedBudgetCurrencyType$.next(BudgetCurrencyType.VENDOR);
        if (!this.isSavingChanges$.getValue()) {
          this.initialValues = cloneDeep(gridData);
          this.gridData$.next(cloneDeep(gridData));
          this.fetchDocumentsForPage();
        }
      });
  }

  fetchDocumentsForPage() {
    const entity_ids: string[] = [];
    this.gridAPI?.getRenderedNodes()?.forEach((node) => {
      if (node.data.id) {
        entity_ids.push(node.data.id);
      }
    });

    const trialKey = this.mainQuery.getValue().trialKey;

    Promise.all([
      this.apiService.getFilesByFilters(
        `trials/${trialKey}/vendors/`,
        undefined,
        EntityType.INVOICE,
        DocumentType.DOCUMENT_INVOICE,
        entity_ids
      ),
      this.apiService.getFilesByFilters(
        `trials/${trialKey}/vendors/`,
        undefined,
        EntityType.INVOICE,
        DocumentType.DOCUMENT_INVOICE_SUPPORT,
        entity_ids
      ),
    ])
      .then(([invoice_docs, supportings_docs]) => {
        const combinedDocuments = [...invoice_docs, ...supportings_docs];
        entity_ids.forEach((id) => {
          const rowNode = this.gridAPI.getRowNode(id);
          if (rowNode) {
            const file = combinedDocuments.find((f) => f.entity_id?.includes(id));
            rowNode.setData({ ...rowNode.data, file });
            this.gridAPI.redrawRows({ rowNodes: [rowNode] });
          }
        });
      })
      .catch((error) => {
        console.error('Error fetching documents:', error);
      });
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  getGridOptions() {
    return {
      pagination: true,
      paginationPageSize: 10,
      suppressPaginationPanel: true,
      getRowId: ({ data }) => data.id,
      defaultColDef: {
        ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
        resizable: true,
        editable: (params) =>
          this.isEditModeEnabled$.getValue() &&
          !params.node.rowPinned &&
          !this.isSavingChanges$.getValue(),
      },
      ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      enableRangeSelection: true,
      suppressCellFocus: false,
      suppressMenuHide: true,
      fillHandleDirection: 'y',
      onModelUpdated: (event: ModelUpdatedEvent) => {
        if (event.api.getDisplayedRowCount() === 0) {
          event.api.showNoRowsOverlay();
        } else {
          event.api.hideOverlay();
        }

        if (this.gridData$.getValue().length) {
          this.overlayNoRowsTemplate = TableConstants.NO_ROWS_ON_FILTERING_MESSAGE;
        }
      },
      fillOperation: (params: FillOperationParams) => {
        if (
          params.column.getColId() === 'accrual_period' ||
          params.column.getColId() === 'payment_date' ||
          params.column.getColId() === 'invoice_date' ||
          params.column.getColId() === 'due_date'
        ) {
          return params.values[0];
        }
        return false;
      },
      columnDefs: [
        {
          field: 'checkbox',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          maxWidth: 35,
          width: 35,
          minWidth: 35,
          hide: true,
          suppressFillHandle: true,
          editable: false,
        },
        {
          headerName: 'Files',
          field: 'file',
          cellRendererSelector: (params) => this.getActionsCellRenderer(params),
          width: 100,
          suppressSizeToFit: true,
          cellClass: TableConstants.STYLE_CLASSES.CELL_JUSTIFY_CENTER,
          suppressFillHandle: true,
        },
        {
          headerName: 'Deposit',
          field: 'is_deposit',
          width: 100,
          hide: !this.invoicesDepositsEnabled,
          suppressSizeToFit: true,
          cellClass: TableConstants.STYLE_CLASSES.CELL_JUSTIFY_CENTER,
          suppressFillHandle: true,
          cellRenderer: AgCheckboxComponent,
          cellRendererParams: (params: ICellRendererParams) => {
            return {
              getDisabledState: () => !this.isEditModeEnabled$.getValue(),
              dontSelectRow: true,
              isHidden: params.node.rowPinned === 'bottom',
              tooltip: this.checkBoxTooltip,
            };
          },
          editable: false,
        },
        {
          headerName: 'Vendor',
          headerTooltip: 'Vendor',
          field: 'organization',
          tooltipField: 'organization.name',
          minWidth: 175,
          width: 175,
          valueFormatter: Utils.dashFormatter,
          cellClass: 'text-left',
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          valueGetter: (params: ValueGetterParams) => {
            return params.data?.organization?.name || '';
          },
          valueSetter: this.invoiceService.vendorValueSetter,
          cellEditor: 'agRichSelectCellEditor',
          onCellValueChanged: (params) => {
            if (params.oldValue !== params.newValue) {
              params.data.organization.currency = this.organizationQuery.getVendor(
                params.data.organization.id
              )[0].currency;
              params.api.refreshCells({ columns: ['organization.currency'] });
            }
          },
          cellEditorParams: () => {
            return {
              values: this.invoiceService.vendorOptions,
              formatValue: (value?: Option | string) => {
                return isString(value) ? value : value?.label || '';
              },
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
            };
          },
          filterValueGetter: (params: ValueGetterParams) => params.data?.organization?.name,
        },
        {
          headerName: 'Accrual Period',
          headerTooltip: 'Accrual Period',
          field: 'accrual_period',
          tooltipValueGetter: (params) => params.valueFormatted,
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value && params.data.invoice_status !== InvoiceStatus.STATUS_DECLINED
              ? dayjs(params.value).format('MMMM YYYY')
              : Utils.zeroHyphen;
          },
          getQuickFilterText(params: GetQuickFilterTextParams) {
            return params.value ? dayjs(params.value).format('MMMM YYYY') : '';
          },
          cellClass: ['text-right'],
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          minWidth: 195,
          width: 195,
          suppressSizeToFit: true,
          cellEditor: AgDatePickerComponent,
          cellEditorParams: (params: ICellEditorParams) => ({
            type: 'month',
            min: this.invoiceService.trialStartDate,
            max: this.invoiceService.trialEndDate,
            value: params.value || this.invoiceService.trialMonthClose,
          }),
        },
        {
          headerName: 'Vendor Currency',
          headerTooltip: 'Vendor Currency',
          field: 'organization.currency',
          tooltipField: 'organization.currency',
          minWidth: 175,
          width: 175,
          valueFormatter: Utils.dashFormatter,
          cellClass: 'text-left',
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          valueGetter: (params: ValueGetterParams) => {
            return params.data?.organization?.currency || '';
          },
          hide: true,
          editable: false,
        },
        {
          headerName: 'Exchange Rate',
          headerTooltip: 'Exchange Rate',
          field: 'expense_amounts.invoice_total_trial_currency.exchange_rate',
          tooltipField: 'expense_amounts.invoice_total_trial_currency.exchange_rate',
          minWidth: 175,
          width: 175,
          valueFormatter: Utils.dashFormatter,
          cellClass: ['text-right'],
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          valueGetter: (params: ValueGetterParams) => {
            return params.data?.expense_amounts.invoice_total_trial_currency.exchange_rate || '';
          },
          hide: true,
          editable: false,
        },
        {
          headerName: 'Total Amount',
          headerTooltip: 'Total Amount',
          field: 'expense_amounts.invoice_total.value',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(
              params,
              params.data.expense_amounts.invoice_total.currency ||
                params.data.organization.currency
            );
          },
          headerComponent: AgHeaderActionsComponent,
          minWidth: 150,
          width: 150,
          cellClass: (params) => {
            return params.data.organization.currency
              ? `budgetCost${params.data.organization.currency}`
              : 'budgetCostNoSymbol';
          },
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueSetter: (params) =>
            this.invoiceService.setCostValue(params, 'expense_amounts.invoice_total.value'),
          cellClassRules: {
            'border-aux-error': (params) => this.checkCellErrorClass(params),
          },
          cellDataType: 'text',
        },
        {
          headerName: 'Total Amount (USD)',
          headerTooltip: 'Total Amount (USD)',
          field: 'expense_amounts.invoice_total_trial_currency.value',
          valueFormatter: Utils.agCurrencyFormatter,
          minWidth: 150,
          width: 150,
          cellClass: ['text-right', 'cost'],
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          hide: true,
          editable: false,
          valueSetter: (params) =>
            this.invoiceService.setCostValue(
              params,
              'expense_amounts.invoice_total_trial_currency.value'
            ),
          cellClassRules: {
            'border-aux-error': (params) => this.checkCellErrorClass(params),
          },
          cellDataType: 'text',
        },
        {
          headerName: 'Services Total',
          headerTooltip: 'Services Total',
          field: 'expense_amounts.services_total.value',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(
              params,
              params.data.expense_amounts.services_total.currency ||
                params.data.organization.currency
            );
          },
          hide: true,
          minWidth: 125,
          width: 125,
          cellClass: (params) => {
            return params.data.organization.currency
              ? `budgetCost${params.data.organization.currency}`
              : 'budgetCostNoSymbol';
          },
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueSetter: (params) =>
            this.invoiceService.setCostValue(params, 'expense_amounts.services_total.value'),
          cellClassRules: {
            'border-aux-error': (params) => this.checkCellErrorClass(params),
          },
          cellDataType: 'text',
        },
        {
          headerName: 'Discount Total',
          headerTooltip: 'Discount Total',
          field: 'expense_amounts.discount_total.value',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(
              params,
              params.data.expense_amounts.discount_total.currency ||
                params.data.organization.currency
            );
          },
          hide: true,
          minWidth: 125,
          width: 125,
          cellClass: (params) => {
            return params.data.organization.currency
              ? `budgetCost${params.data.organization.currency}`
              : 'budgetCostNoSymbol';
          },
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueSetter: (params) =>
            this.invoiceService.setCostValue(params, 'expense_amounts.discount_total.value'),
          cellClassRules: {
            'border-aux-error': (params) => this.checkCellErrorClass(params),
          },
          cellDataType: 'text',
        },
        {
          headerName: 'Investigator Total',
          headerTooltip: 'Investigator Total',
          field: 'expense_amounts.investigator_total.value',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(
              params,
              params.data.expense_amounts.investigator_total.currency ||
                params.data.organization.currency
            );
          },
          hide: true,
          minWidth: 150,
          width: 150,
          cellClass: (params) => {
            return params.data.organization.currency
              ? `budgetCost${params.data.organization.currency}`
              : 'budgetCostNoSymbol';
          },
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueSetter: (params) =>
            this.invoiceService.setCostValue(params, 'expense_amounts.investigator_total.value'),
          cellClassRules: {
            'border-aux-error': (params) => this.checkCellErrorClass(params),
          },
          cellDataType: 'text',
        },
        {
          headerName: 'Pass-Through Total',
          headerTooltip: 'Pass-Through Total',
          field: 'expense_amounts.pass_thru_total.value',
          valueFormatter: (params) => {
            return Utils.agCurrencyFormatter(
              params,
              params.data.expense_amounts.pass_thru_total.currency ||
                params.data.organization.currency
            );
          },
          hide: true,
          minWidth: 160,
          width: 160,
          cellClass: (params) => {
            return params.data.organization.currency
              ? `budgetCost${params.data.organization.currency}`
              : 'budgetCostNoSymbol';
          },
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueSetter: (params) =>
            this.invoiceService.setCostValue(params, 'expense_amounts.pass_thru_total.value'),
          cellClassRules: {
            'border-aux-error': (params) => this.checkCellErrorClass(params),
          },
          cellDataType: 'text',
        },
        {
          headerName: 'Services Total (USD)',
          headerTooltip: 'Services Total',
          field: 'expense_amounts.services_total_trial_currency.value',
          valueFormatter: Utils.agCurrencyFormatter,
          hide: true,
          editable: false,
          minWidth: 125,
          width: 125,
          cellClass: ['text-right', 'cost'],
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueSetter: (params) =>
            this.invoiceService.setCostValue(
              params,
              'expense_amounts.services_total_trial_currency.value'
            ),
          cellClassRules: {
            'border-aux-error': (params) => this.checkCellErrorClass(params),
          },
          cellDataType: 'text',
        },
        {
          headerName: 'Discount Total (USD)',
          headerTooltip: 'Discount Total',
          field: 'expense_amounts.discount_total_trial_currency.value',
          valueFormatter: Utils.agCurrencyFormatter,
          hide: true,
          editable: false,
          minWidth: 125,
          width: 125,
          cellClass: ['text-right', 'cost'],
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueSetter: (params) =>
            this.invoiceService.setCostValue(
              params,
              'expense_amounts.discount_total_trial_currency.value'
            ),
          cellClassRules: {
            'border-aux-error': (params) => this.checkCellErrorClass(params),
          },
          cellDataType: 'text',
        },
        {
          headerName: 'Investigator Total (USD)',
          headerTooltip: 'Investigator Total',
          field: 'expense_amounts.investigator_total_trial_currency.value',
          valueFormatter: Utils.agCurrencyFormatter,
          hide: true,
          editable: false,
          minWidth: 150,
          width: 150,
          cellClass: ['text-right', 'cost'],
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueSetter: (params) =>
            this.invoiceService.setCostValue(
              params,
              'expense_amounts.investigator_total_trial_currency.value'
            ),
          cellClassRules: {
            'border-aux-error': (params) => this.checkCellErrorClass(params),
          },
          cellDataType: 'text',
        },
        {
          headerName: 'Pass-Through Total (USD)',
          headerTooltip: 'Pass-Through Total',
          field: 'expense_amounts.pass_thru_total_trial_currency.value',
          valueFormatter: Utils.agCurrencyFormatter,
          hide: true,
          editable: false,
          minWidth: 160,
          width: 160,
          cellClass: ['text-right', 'cost'],
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          valueSetter: (params) =>
            this.invoiceService.setCostValue(
              params,
              'expense_amounts.pass_thru_total_trial_currency.value'
            ),
          cellClassRules: {
            'border-aux-error': (params) => this.checkCellErrorClass(params),
          },
          cellDataType: 'text',
        },
        {
          headerName: 'Invoice Date',
          headerTooltip: 'Invoice Date',
          field: 'invoice_date',
          valueFormatter: Utils.agDateFormatter,
          minWidth: 170,
          width: 170,
          suppressSizeToFit: true,
          cellClass: ['text-right'],
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          cellEditor: AgDatePickerComponent,
        },
        {
          headerName: 'Status',
          headerTooltip: 'Status',
          field: 'invoice_status',
          colId: 'invoice_status',
          minWidth: 150,
          width: 150,
          cellRenderer: InvoicesStatusComponent,
          tooltipValueGetter: (params) =>
            this.invoicesGridFormatterService.getFormattedInvoiceStatus(params.value),
          getQuickFilterText: (params: GetQuickFilterTextParams) =>
            this.invoicesGridFormatterService.getFormattedInvoiceStatus(params.value),
          cellClass: 'text-left',
          cellEditor: 'agRichSelectCellEditor',
          suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
            return ['Backspace', 'Delete'].includes(params.event.key);
          },
          editable: (params) =>
            this.isEditModeEnabled$.getValue() &&
            !params.node.rowPinned &&
            !this.isSavingChanges$.getValue() &&
            (this.userHasApproveInvoicePermission || this.authQuery.isAuxAdmin()),
          suppressFillHandle: !(
            this.userHasApproveInvoicePermission || this.authQuery.isAuxAdmin()
          ),
          cellEditorParams: () => {
            return {
              values: this.invoiceService.invoiceStatusOptions,
              cellRenderer: InvoicesStatusComponent,
            };
          },
        },
        {
          headerName: 'Due Date',
          headerTooltip: 'Due Date',
          field: 'due_date',
          valueFormatter: Utils.agDateFormatter,
          minWidth: 170,
          width: 170,
          suppressSizeToFit: true,
          cellClass: ['text-right'],
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          cellEditor: AgDatePickerComponent,
        },
        {
          headerName: 'PO#',
          headerTooltip: 'PO#',
          field: 'po_reference',
          colId: 'po_reference',
          cellClass: ['align-right', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
          minWidth: 100,
          width: 100,
          suppressFillHandle: true,
          suppressPaste: true,
          valueFormatter: (params: ValueFormatterParams) =>
            this.invoicesGridFormatterService.getFormattedPurchaseOrder(params.value),
          tooltipValueGetter: (params) => params.valueFormatted,
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: (params: ICellEditorParams) => {
            const options = this.invoiceService.purchaseOrdersQuery
              .getAll()
              .filter((order) => order.organization?.id === params.data.organization.id)
              .map(({ id }) => id);

            return {
              values: [null, ...options],
              cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
            };
          },
          getQuickFilterText: (params: GetQuickFilterTextParams) =>
            this.invoicesGridFormatterService.getFormattedPurchaseOrder(params.value),
        },
        {
          headerName: 'Date Imported',
          headerTooltip: 'Date Imported',
          field: 'create_date',
          colId: 'create_date',
          valueFormatter: Utils.agDateFormatter,
          getQuickFilterText: Utils.agDateFormatter,
          sort: 'desc',
          minWidth: 120,
          width: 120,
          cellClass: ['text-right'],
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          editable: false,
          suppressFillHandle: true,
        },
        {
          headerName: 'Created by',
          headerTooltip: 'Created by',
          field: 'created_by',
          colId: 'created_by',
          valueFormatter: (val: ValueFormatterParams) =>
            val.node?.rowPinned
              ? Utils.zeroHyphen
              : this.invoicesGridFormatterService.getFormatterCreateAuthor(
                  val,
                  this.invoiceService.userFormatter
                ),
          getQuickFilterText: (params: GetQuickFilterTextParams) =>
            this.invoiceService.userFormatter(params.value),
          minWidth: 150,
          width: 150,
          cellClass: 'text-left',
          cellRenderer: Utils.getCellWrapper('ag-cell-value', 'valueFormatted'),
          tooltipValueGetter: (params) => params.valueFormatted,
          editable: false,
          suppressFillHandle: true,
        },
        {
          headerName: 'Require Cost Breakdown',
          headerTooltip: 'Require Cost Breakdown',
          field: 'requireCostBreakdown',
          colId: 'requireCostBreakdown',
          hide: true,
          filter: true,
        },
      ],
    } as GridOptions;
  }

  getActionsCellRenderer = (params: ICellRendererParams) =>
    params.node.rowPinned
      ? { component: Utils.getCellWrapper('ag-cell-value', 'value') }
      : {
          component: AgInvoiceActionsComponent,
          params: {
            isInvoiceFinalized$: this.isInvoiceFinalized$,
            iCloseMonthsProcessing$: this.iCloseMonthsProcessing$,
            invoiceLockTooltip$: this.invoiceLockTooltip$,
            downloadClickFN: ({ rowNode }: { rowNode: RowNode }) => {
              this.invoiceService.downloadInvoiceItems(rowNode);
            },
            downloadLinesClickFN: ({ rowNode }: { rowNode: RowNode }) => {
              this.invoiceService.downloadInvoiceLines(rowNode);
            },
            uploadClickFN: async (uploadParams: {
              rowNode: IRowNode;
              instance: AgInvoiceActionsComponent;
            }) => {
              const { rowNode, instance } = uploadParams;
              const result = this.overlayService.open<unknown[]>({
                content: UploadDocumentsDialogComponent,
                data: { invoice: rowNode.data },
              });
              result.afterClosed$.subscribe(async (filesSaved) => {
                instance.refreshFileState(filesSaved.data);
                this.gridAPI.refreshCells();
              });
            },
          },
        };

  checkChanges() {
    const currentValues: IInvoicesGridData[] = [];

    this.gridAPI?.forEachNode(({ data }) => {
      currentValues.push(data);
    });

    this.hasChanges$.next(!isEqual(this.initialValues, currentValues));
  }

  cellValueChanged(event: CellValueChangedEvent) {
    if (event.column.getColId() === 'organization') {
      const data = { ...event.data, po_reference: null };
      event.node.setData(data);
      this.gridAPI.refreshCells();
    }
    this.checkChanges();
  }

  checkCellErrorClass = (params: CellClassParams) =>
    params.data.hasError && this.showErrors$.getValue();

  firstDataRendered({ api }: { api: GridApi }) {
    api.sizeColumnsToFit();
    this.gridAPI = api;
    this.invoiceService.setGridApi(api);
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: [
        'expense_amounts.investigator_total.value',
        'expense_amounts.pass_thru_total.value',
        'expense_amounts.services_total.value',
        'expense_amounts.discount_total.value',
      ],
      visible: true,
    });

    this.fetchDocumentsForPage();

    this.invoiceService.generatePinnedRow();
    this.gridAPI?.setColumnVisible('is_deposit', this.invoicesDepositsEnabled);
  }

  async onNewInvoice() {
    this.overlayService.open({ content: NewInvoiceDialogComponent });
  }

  onGridReady({ api }: GridReadyEvent) {
    api.sizeColumnsToFit();
    this.gridAPI = api;
    this.invoiceService.setGridApi(api);
  }

  removeInvoices = async () => {
    const invoiceNumbers = this.selectedRows
      .map(({ invoice_no }) => invoice_no)
      .filter((number) => number);
    const amountOfInvoicesWithoutNumber = this.selectedRows.length - invoiceNumbers.length;
    const invoiceNumbersMessage = amountOfInvoicesWithoutNumber
      ? `${invoiceNumbers.join(', ')} and ${amountOfInvoicesWithoutNumber} more`
      : invoiceNumbers.join(', ');
    const message =
      amountOfInvoicesWithoutNumber === this.selectedRows.length
        ? 'Are you sure you want to remove selected Invoices?'
        : `Are you sure you want to remove following Invoices: ${invoiceNumbersMessage}?`;

    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Invoices',
      message,
      okBtnText: 'Remove',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });

    const event = await firstValueFrom(resp.afterClosed$);

    if (event.data?.result) {
      this.rowsToDelete.push(
        ...this.selectedRows.map((invoice) => ({ invoice, reason: event.data?.textarea || '' }))
      );
      this.gridAPI?.applyTransaction({ remove: this.selectedRows });
      this.selectedRows = [];
    }

    this.checkChanges();
  };

  checkRequireCostBreakdown(value: boolean) {
    this.invoiceService.showRequireCostBreakdown$.next(value);
    this.gridAPI.onFilterChanged();

    if (value) {
      this.showErrors$.next(true);
      this.gridAPI.refreshCells();
    }
  }

  checkRequireAccrualPeriod(value: boolean) {
    this.invoiceService.showRequireAccrualPeriod$.next(value);
    this.gridAPI.onFilterChanged();

    if (value) {
      this.showErrors$.next(true);
      this.gridAPI.refreshCells();
    }
  }

  rowSelected(event: RowSelectedEvent) {
    this.selectedRows = event.api.getSelectedRows();
  }

  resetEditMode() {
    this.showErrors$.next(false);
    this.isEditModeEnabled$.next(false);
    this.gridAPI.deselectAll();
    this.rowsToDelete = [];
    this.hasChanges$.next(false);
    this.selectedRows = [];
  }

  checkIfInvoiceStatusChanged(invoice: InvoiceModel) {
    return (
      invoice.invoice_status !==
      this.initialValues.find((initialInvoice) => initialInvoice.id === invoice.id)?.invoice_status
    );
  }

  getInvoiceStatusChangeReason = (invoice: InvoiceModel, status: InvoiceStatus) =>
    this.checkIfInvoiceStatusChanged(invoice) && invoice.invoice_status === status
      ? 'In-line Status Change'
      : null;

  onSave = async () => {
    this.paginationPageSize = this.gridAPI?.paginationGetPageSize();
    this.isSavingChanges$.next(true);

    let hasErrors = false;
    const apiErrors: string[] = [];
    const currentValues: IInvoicesGridData[] = [];

    this.gridAPI?.forEachNode(({ data }) => {
      currentValues.push(data);

      if (data.hasError) {
        hasErrors = true;
      }
    });

    if (hasErrors) {
      this.showErrors$.next(true);
      this.gridAPI.refreshCells();
      this.overlayService.error(MessagesConstants.INVOICE.TOTAL_VALIDATION);
      this.isSavingChanges$.next(false);

      return;
    }

    const mapError = (result: boolean | { success: boolean; errors: string[] } | Error) => {
      if (result instanceof Error) {
        apiErrors.push(result.message);
      } else if (typeof result === 'object' && result?.success) {
        apiErrors.push(...result.errors);
      }
    };

    if (this.rowsToDelete.length) {
      const deleteResults = await batchPromises(
        this.rowsToDelete,
        ({ invoice, reason }) => {
          return this.invoiceService.remove(invoice, reason);
        },
        5
      );

      deleteResults.forEach(mapError);
    }

    const changedRows = differenceWith(currentValues, this.initialValues, isEqual);
    if (changedRows.length) {
      const invoicesWithApproveRules = changedRows.filter(
        (invoice) =>
          [InvoiceStatus.STATUS_APPROVED, InvoiceStatus.STATUS_DECLINED].includes(
            invoice.invoice_status
          ) && this.checkIfInvoiceStatusChanged(invoice)
      );

      const approveResults = await batchPromises(
        invoicesWithApproveRules,
        ({ invoice_status, id }) => {
          return firstValueFrom(
            this.gqlService.approveRule$({
              approved: invoice_status === InvoiceStatus.STATUS_APPROVED,
              comments: '',
              permission: 'PERMISSION_APPROVE_INVOICE',
              approval_type: ApprovalType.APPROVAL_INVOICE,
              entity_id: id,
              entity_type: EntityType.INVOICE,
              activity_details: '{}',
            })
          );
        },
        5
      );

      approveResults.forEach(mapError);

      await this.invoiceService.batchUpdate(
        changedRows.map((invoice) => {
          return {
            ...invoice,
            accrual_period: invoice.accrual_period
              ? dayjs(invoice.accrual_period).format('YYYY-MM-DD')
              : null,
            invoice_date: invoice.invoice_date || null,
            due_date: invoice.due_date || null,
            payment_date: invoice.payment_date || null,
            decline_reason: this.getInvoiceStatusChangeReason(
              invoice,
              InvoiceStatus.STATUS_DECLINED
            ),
            admin_review_reason: this.getInvoiceStatusChangeReason(
              invoice,
              InvoiceStatus.STATUS_PENDING_REVIEW
            ),
          };
        })
      );
    }

    if (apiErrors.length) {
      this.overlayService.error(MessagesConstants.SOME_CHANGES_ARE_NOT_SAVED);
      this.apiErrors$.next(apiErrors);
    } else {
      this.overlayService.success(MessagesConstants.SUCCESSFULLY_SAVED);
      if (this.numberOfVendorCurrencies > 1) {
        this.shouldRefreshAfterNewInvoiceIsProcessed$.next(true);
      } else {
        this.initialValues = cloneDeep(currentValues);
        this.gridData$.next(cloneDeep(currentValues));
      }
    }

    this.resetEditMode();
    this.isSavingChanges$.next(false);
  };

  onCancel = () => {
    this.resetEditMode();
    this.gridData$.next(cloneDeep(this.initialValues));
    this.fetchDocumentsForPage();
  };

  enableEditMode = () => {
    this.isEditModeEnabled$.next(true);
  };

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges$.getValue()) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  onBulkApplyButtonClick = () => {
    const modalRef = this.overlayService.open<
      {
        hasChanges?: boolean;
      },
      EditMultipleInvoicesModalData
    >({
      content: EditMultipleInvoicesModalComponent,
      data: {
        selectedRows: this.selectedRows,
        hasApprovePermission: this.userHasApproveInvoicePermission || this.authQuery.isAuxAdmin(),
        gridApi: this.gridAPI,
        hasPaymentStatus: this.hasPaymentStatus,
      },
    });

    modalRef.afterClosed$.pipe(untilDestroyed(this)).subscribe(({ data }) => {
      if (data?.hasChanges) {
        this.checkChanges();
      }
    });
  };

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }

  isBtnLoading(str: string) {
    return this.btnLoading$.pipe(map((x) => x === str));
  }

  async onExportInvoices() {
    const trialName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const dateStr = dayjs(new Date()).format('YYYY.MM.DD-HHmmss');

    if (this.btnLoading$.getValue()) {
      return;
    }
    this.btnLoading$.next('export');

    const filteredInvoiceIds: string[] = [];
    if (this.gridData$.value.length !== this.gridAPI.getDisplayedRowCount()) {
      for (let i = 0; i < this.gridAPI.getDisplayedRowCount(); i++) {
        filteredInvoiceIds.push(this.gridAPI?.getDisplayedRowAtIndex(i)?.data.id);
      }
    }
    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getSelectedTrial()?.id || '',
        payload: JSON.stringify({
          export_type: ExportType.INVOICES,
          trial_currency_selected: !this.isVendorCurrency,
          filename: `${trialName}_auxilius-invoices__${dateStr}`,
          filtered_invoice_ids: filteredInvoiceIds,
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next(false);
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_INVOICES],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasLockInvoicesPermission]) => {
        this.userHasLockInvoicesPermission = userHasLockInvoicesPermission;
      });
  }
}
