<div>
  <!-- Header / Nav -->
  <aux-trial-insights-nav />

  <!-- Route -->
  <div
    *ngIf="(this.showClinOpsButton$ | async) === true || (this.showFinanceButton$ | async) === true"
  >
    <router-outlet />
  </div>
</div>
