import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-header',
  templateUrl: './trial-insights-header.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsHeaderComponent {
  @Input() title = '';

  @Input() header = '';

  @Input() color = '';
}
