<div class="flex items-center justify-center space-x-[10px]">
  <div class="flex items-center justify-center space-x-[5px]">
    <button (click)="toggleRowExpand(true)">
      <aux-icon name="ChevronsDown" [size]="20" [auxTooltip]="'Expand All Rows'" />
    </button>

    <button (click)="toggleRowExpand(false)">
      <aux-icon name="ChevronsUp" [size]="20" [auxTooltip]="'Collapse All Rows'" />
    </button>
  </div>
  <div class="text-aux-black text-base" [innerHTML]="params.template"></div>
</div>
