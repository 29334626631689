<section class="p-4 bg-aux-gray-light border border-aux-gray-dark">
  <p class="font-bold mb-4">
    <ng-container [ngSwitch]="formValues.source">
      <span *ngSwitchCase="sourceTypes.Contracted">Contracted Investigator Amount</span>
      <span *ngSwitchCase="sourceTypes.Averages">Site Contract Settings</span>
      <p *ngSwitchCase="sourceTypes.PatientVisits">
        Forecast Through End of Trial
        <span class="italic font-normal">(Best used when trial is fully enrolled)</span>
      </p>
    </ng-container>
  </p>

  <aux-investigator-forecast-overview-amount
    [ngClass]="{
      hidden:
        formValues.source === sourceTypes.Averages ||
        formValues.source === sourceTypes.PatientVisits
    }"
    [values]="formValues.contracted"
  />

  <aux-investigator-forecast-overview-averages
    [ngClass]="{
      hidden:
        formValues.source === sourceTypes.Contracted ||
        formValues.source === sourceTypes.PatientVisits
    }"
    [form]="form"
  />

  <aux-investigator-forecast-overview-patient-visits
    [ngClass]="{
      hidden:
        formValues.source === sourceTypes.Contracted || formValues.source === sourceTypes.Averages
    }"
    [form]="form"
  />

  <div
    *ngIf="getBottomTotalValuesBySelectedSource() as bottomValues"
    class="my-4 flex space-x-4 justify-end"
  >
    <div>
      <p>Forecasted Total Investigator Cost</p>
      <p class="font-bold text-right">{{ bottomValues?.totalInvestigatorCosts | money }}</p>
    </div>
    <div class="border-l h-12 border-aux-gray-dark"></div>
    <div>
      <p>Forecasted Remaining Investigator Cost</p>
      <p class="font-bold text-right">{{ bottomValues?.remainingAmount | money }}</p>
    </div>
  </div>
</section>
