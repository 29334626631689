<ng-container
  *ngIf="{
    invoices: invoices$ | async,
    isPendingApproval: isPendingApproval$ | async,
    loading: loading$ | async,
    isInQueue: isInQueue$ | async,
    isPendingReview: isPendingReview$ | async,
    isApproved: isApproved$ | async,
    isDecline: isDecline$ | async,
    organization: organization$ | async,
    approvalBy: approvalBy$ | async,
    isInvoiceFinalized: (isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async),
    invoiceLockTooltip: (invoiceLockTooltip$ | async)!,
    trialMonthClose: (trialMonthClose$ | async)!,
    trialEndDate: (trialEndDate$ | async)!
  } as obj"
>
  <ng-container *ngIf="obj.loading">
    <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
  </ng-container>

  <ng-container *ngIf="!obj.loading">
    <ng-container *ngIf="!obj.invoices">NOT FOUND</ng-container>

    <ng-container *ngIf="obj.invoices">
      <div
        class="flex items-center text-aux-blue-light-200 mb-6 cursor-pointer max-w-max"
        [routerLink]="[invoicesLink]"
      >
        <aux-icon name="ArrowLeft" class="mr-2" />
        <div>Back to Invoices</div>
      </div>

      <div class="flex items-center justify-between">
        <div class="font-bold text-xl mb-4">
          {{ obj.organization?.name }} - Invoice {{ obj.invoices.invoice_no }}
        </div>
        <div></div>
      </div>

      <div
        class="border px-4 mb-4"
        [ngClass]="{
          'bg-aux-blue-light-50 text-aux-black':
            obj.isPendingApproval || obj.isInQueue || obj.isPendingReview,
          'bg-aux-green-dark': obj.isApproved,
          'bg-aux-red-dark pb-4': obj.isDecline,
          'text-white': obj.isApproved || obj.isDecline
        }"
      >
        <div class="flex items-center justify-between h-16">
          <div>
            <span>Status:</span>
            <span class="font-bold ml-2">{{ status$ | async }}</span>
            <ng-container
              *ngIf="
                (obj.isApproved || obj.isDecline) && obj.approvalBy && obj.approvalBy.approved_by
              "
              class="justify-start"
            >
              <span>
                by {{ obj.approvalBy.approved_by }} on
                {{ obj.approvalBy.approved_time | date }}</span
              >
            </ng-container>
          </div>

          <div class="space-x-2 flex items-center">
            <aux-button
              [auxTooltip]="
                (isEditEnabled$ | async) === false ? dontHavePermission : obj.invoiceLockTooltip
              "
              [disabled]="
                (isEditEnabled$ | async) === false || isPoInvalid() || obj.isInvoiceFinalized
              "
              (clickEmit)="onSave()"
              [loading]="(btnLoading$ | async)?.save === true"
              icon="Pencil"
              variant="secondary"
              classList="h-9 bg-white"
              label="Save"
              [iconSize]="16"
            />

            <aux-button
              *ngIf="!obj.isPendingReview && !obj.isInQueue"
              (clickEmit)="onDownloadInv()"
              [loading]="(btnLoading$ | async)?.download === true"
              icon="Download"
              variant="secondary"
              classList="h-9 bg-white"
              label="Download Invoice"
              [iconSize]="20"
            />

            <ng-container *ngIf="obj.isPendingReview || obj.isInQueue">
              <aux-button
                [auxTooltip]="
                  (isDeleteEnabled$ | async) === false ? dontHavePermission : obj.invoiceLockTooltip
                "
                [disabled]="(isDeleteEnabled$ | async) === false || obj.isInvoiceFinalized"
                (clickEmit)="onDelete()"
                [loading]="(btnLoading$ | async)?.delete === true"
                icon="Trash"
                variant="negative"
                classList="h-9"
                label="Delete"
                [iconSize]="16"
              />
              <aux-button
                (clickEmit)="onDownloadInv()"
                [loading]="(btnLoading$ | async)?.download === true"
                icon="Download"
                variant="secondary"
                classList="h-9 bg-white"
                label="Download Invoice"
                [iconSize]="20"
              />
            </ng-container>
            <ng-container *ngIf="obj.isPendingApproval">
              <aux-button
                [auxTooltip]="
                  (isApproveEnabled$ | async) === false
                    ? dontHavePermission
                    : obj.invoiceLockTooltip
                "
                [disabled]="(isApproveEnabled$ | async) === false || obj.isInvoiceFinalized"
                (clickEmit)="onDecline()"
                [loading]="(btnLoading$ | async)?.decline === true"
                icon="X"
                variant="negative"
                classList="h-9"
                label="Decline"
                [iconSize]="20"
              />
              <aux-button
                [auxTooltip]="
                  (isApproveEnabled$ | async) === false
                    ? dontHavePermission
                    : obj.invoiceLockTooltip
                "
                [disabled]="(isApproveEnabled$ | async) === false || obj.isInvoiceFinalized"
                (clickEmit)="onApprove()"
                [loading]="(btnLoading$ | async)?.forceApprove === true"
                icon="Check"
                variant="success"
                classList="h-9"
                label="Approve Invoice"
                [iconSize]="20"
              />
              <aux-button
                [auxTooltip]="
                  (isDeleteEnabled$ | async) === false ? dontHavePermission : obj.invoiceLockTooltip
                "
                [disabled]="(isDeleteEnabled$ | async) === false || obj.isInvoiceFinalized"
                (clickEmit)="onDelete()"
                [loading]="(btnLoading$ | async)?.delete === true"
                icon="Trash"
                variant="negative"
                classList="h-9"
                label="Delete"
                [iconSize]="16"
              />
            </ng-container>
          </div>
        </div>

        <div *ngIf="obj.isDecline" class="bg-aux-gray-light mt-4 p-2 text-aux-black">
          <div class="font-bold">Reason for Decline</div>
          <div *ngIf="obj.invoices.decline_reason">
            {{ obj.invoices.decline_reason }}
          </div>
        </div>
      </div>

      <ng-container
        *ngIf="
          (authQuery.adminUser$ | async) === true ||
          obj.isPendingApproval ||
          obj.isApproved ||
          obj.isDecline ||
          obj.isPendingReview
        "
      >
        <aux-invoice
          #invoiceProc
          [disabled]="(isEditEnabled$ | async) === false"
          [trialMonthClose]="obj.trialMonthClose"
          [trialEndDate]="obj.trialEndDate"
          [invoiceData]="obj.invoices"
          [isDeclined]="!!obj.isDecline"
        />
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
