import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { ApiService, FileMetadata } from '@services/api.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { EntityType, EventType, GqlService, TemplateType } from '@services/gql.service';
import { MessagesConstants } from '@constants/messages.constants';
import { UntypedFormControl } from '@angular/forms';
import { Option } from '@components/components.type';
import { ButtonToggleItem } from '@components/button-toggle-group/button-toggle-item.model';

@Component({
  selector: 'aux-import-site-cost-data-model',
  templateUrl: './import-site-cost-data-model.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportSiteCostDataModelComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = '';

  selectedProtocol = new UntypedFormControl('');

  selectedPatientGroup = new UntypedFormControl('');

  patientGroupOptions: ButtonToggleItem[] = [];

  protocolVersionOptions: Option[] = [];

  visitCostsFF$ = new BehaviorSubject(false);

  constructor(
    public ref: CustomOverlayRef<
      unknown,
      {
        protocolVersionOptions: Option[];
        patientGroupOptions: ButtonToggleItem[];
        visitCostsFF$: BehaviorSubject<boolean>;
      }
    >,
    private apiService: ApiService,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private gqlService: GqlService
  ) {
    if (this.ref.data) {
      this.visitCostsFF$.next(this.ref.data.visitCostsFF$.getValue());
      this.protocolVersionOptions = this.ref.data.protocolVersionOptions;
      this.patientGroupOptions = this.ref.data.patientGroupOptions.filter((patientGroup) => {
        if (this.visitCostsFF$.getValue()) {
          return patientGroup.value !== 'invoiceables';
        } else {
          return patientGroup.value !== 'visits_costs' && patientGroup.value !== 'invoiceables';
        }
      });
      if (this.visitCostsFF$.getValue()) {
        this.selectedPatientGroup.setValue('visits_costs');
      }
    }
  }

  pathFn: () => string = () => '';

  getFilePath() {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/patient-budget/`;
  }

  async downloadPatientBudgetTemplate() {
    this.errorMessage = '';
    if (!this.selectedProtocol.value) {
      this.errorMessage = 'Please select a protocol version!';
      return;
    }
    if (!this.selectedPatientGroup.value) {
      this.errorMessage = 'Please select a patient group!';
      return;
    }

    this.loading$.next(true);

    const payloadString = JSON.stringify({
      patient_protocol_version_id: this.selectedProtocol.value,
      patient_group_id: this.selectedPatientGroup.value,
    });

    const { success, data } = await this.apiService.getTemplatePath(
      null,
      TemplateType.PATIENT_BUDGET_TEMPLATE,
      payloadString
    );
    if (!(success && data)) {
      this.overlayService.error(MessagesConstants.FILE.PROBLEM_DOWNLOADING_TEMPLATE);
    } else {
      await this.apiService.downloadFileFromPath(data.id);
    }
    this.loading$.next(false);
  }

  async onUpload() {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = MessagesConstants.FILE.NEED_UPLOAD_FILE;
        return;
      }

      if (files.length > 1) {
        this.errorMessage = 'Maximum one file allowed!';
        return;
      }

      if (!this.selectedProtocol.value) {
        this.errorMessage = 'Please select a protocol version!';
        return;
      }

      if (!this.selectedPatientGroup.value) {
        this.errorMessage = 'Please select a patient group!';
        return;
      }

      this.loading$.next(true);

      const file = files[0];

      const trialId = this.mainQuery.getValue().trialKey;

      const filePath = this.getFilePath();

      const key = `${filePath}${file.bucket_key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        bucket_key: key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const { success, errors } = await firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.PATIENT_BUDGET_TEMPLATE_UPLOADED,
            entity_type: EntityType.TRIAL,
            entity_id: trialId,
            bucket_key: `public/${filePath}${file.bucket_key}`,
            payload: JSON.stringify({
              patient_protocol_version_id: this.selectedProtocol.value,
              patient_group_id: this.selectedPatientGroup.value,
            }),
          })
        );
        if (success) {
          this.overlayService.success('Patient budget updated');
        } else {
          this.apiService.removeFile(`${this.getFilePath()}${file.bucket_key}`);
          this.overlayService.error(errors, undefined, true);
        }
        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }
}
