import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  APP_VERSION: string | undefined;

  SAML_PROVIDERS: string[] = [];
}
