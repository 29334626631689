import { Injectable } from '@angular/core';
import { UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthQuery } from '@models/auth/auth.query';
import { ROUTING_PATH } from '../../app-routing-path.const';
import { LocalStorageKey } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authQuery: AuthQuery
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated(route, state);
  }

  async isAuthenticated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLoggedIn = await firstValueFrom(this.authQuery.isLoggedIn$.pipe(take(1)));

    if (isLoggedIn) {
      const lastURL = localStorage.getItem(LocalStorageKey.LOGIN_LAST_URL);
      if (lastURL) {
        const [url, ...rest] = lastURL.split('?');
        const urlSearchParams = new URLSearchParams(`?${rest.join('?')}`);
        const params = Object.fromEntries(urlSearchParams.entries());
        localStorage.removeItem(LocalStorageKey.LOGIN_LAST_URL);
        return this.router.createUrlTree([`${url}`], { queryParams: params });
      }
      return true;
    }

    localStorage.setItem(LocalStorageKey.LOGIN_LAST_URL, state.url);
    return this.router.createUrlTree([`/${ROUTING_PATH.LOGIN}`]);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
}
