import { FormControl } from '@angular/forms';
import { PeriodType } from '@services/utils';
import { Observable } from 'rxjs';
import { Option } from '@components/components.type';

export const defaultVendorChangeFn = () => {};
export const defaultSnapshotChangeFn = () => {};
export const defaultSnapshotRefreshFn = () => {};

export const defaultPeriodList = [
  { label: 'Month', value: PeriodType.PERIOD_MONTH },
  { label: 'Quarter', value: PeriodType.PERIOD_QUARTER },
  { label: 'Year', value: PeriodType.PERIOD_YEAR },
];

export type BudgetCompareMenuFlatten$<T> = T extends Observable<infer R> ? R : null;

export type BudgetCompareMenuVendorFormControl = FormControl<string>;

export type BudgetCompareMenuVendorLoading = boolean;

export type BudgetCompareMenuVendorChangeFn = (vendorId: string) => void;

export type BudgetCompareMenuSnapshotFormControl = FormControl<string | null>;

export type BudgetCompareMenuSnapshotList = Option<string>[];

export type BudgetCompareMenuSnapshotValue = string | undefined;

export type BudgetCompareMenuSnapshotDisabled = boolean;

export type BudgetCompareMenuSnapshotShowEdit = boolean;

export type BudgetCompareMenuSnapshotShowDelete = boolean;

export type BudgetCompareMenuSnapshotLoading = boolean;

export type BudgetCompareMenuSnapshotChangeFn = (value: string) => void;

export type BudgetCompareMenuSnapshotRefreshFn = () => void;

export type BudgetCompareMenuPeriodFormControl = FormControl<string>;

export type BudgetCompareMenuPeriodListItem = {
  label: string;
  value: PeriodType;
};

export type BudgetCompareMenuPeriodList = BudgetCompareMenuPeriodListItem[];
