<div *ngIf="workflow" class="flex h-full ml-4 items-center whitespace-nowrap">
  <ng-select
    *ngIf="isAdmin || userHasChecklistAdminPermission; else noAssignToPermissions"
    placeholder="Assign To"
    [clearable]="true"
    [formControl]="assignToFormControl"
    [className]="'w-36'"
    (change)="changeAssign()"
  >
    <ng-container *ngVar="externalUserWithPermissionsList$ | async as usersList">
      <ng-option *ngFor="let user of usersList" [value]="user.sub">
        <aux-first-name-show
          [text]="
            (user.given_name + ' ' + user.family_name).length > 30
              ? (user.given_name + ' ' + user.family_name).substring(0, 30)
              : user.given_name + ' ' + user.family_name
          "
          [showHyphenIfEmpty]="true"
        />
      </ng-option>
    </ng-container>
  </ng-select>

  <ng-template #noAssignToPermissions>
    <div class="flex font-bold gap-2">
      Assigned To:
      <aux-first-name-show
        [className]="'font-bold max-w-[126px]'"
        [text]="user ? user : zeroHyphen"
        [showHyphenIfEmpty]="true"
      />
    </div>
  </ng-template>
</div>
