<div class="flex items-center justify-center">
  <!-- Header Title -->
  <span>Evidence Based</span>

  <!-- Header Button enabled -->
  <div
    *ngIf="(editMode$ | async) === false"
    class="flex rounded-full bg-white p-[4px] ml-1 cursor-pointer"
    (click)="navigateToInvestigatorTransactions()"
  >
    <aux-icon name="Eye" [size]="20" class="text-aux-blue-dark" />
  </div>

  <!-- Chevron for expanding and collapsing columns -->
  <aux-icon
    [size]="20"
    [name]="visible ? 'ChevronLeft' : 'ChevronRight'"
    class="ml-1 cursor-pointer"
    (click)="toggleExpand()"
  />

  <!-- Header Button disabled -->
  <div *ngIf="(editMode$ | async) === true" class="flex rounded-full p-[4px] bg-[#cbc4c4]">
    <aux-icon name="Eye" [size]="20" class="text-[#a3a3a3]" />
  </div>
</div>
