import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, getEntityType } from '@datorama/akita';
import { SpecificationCategory } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export type SpecificationCategoryState = EntityState<
  Omit<RequireSome<Partial<SpecificationCategory>, 'id'>, 'specifications'> & {
    specifications: string[];
  }
>;

export type SpecificationCategoryModel = getEntityType<SpecificationCategoryState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'specification-category' })
export class SpecificationCategoryStore extends EntityStore<SpecificationCategoryState> {
  constructor() {
    super({});
  }
}
