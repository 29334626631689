<ng-container *ngVar="form.get('patientVisits') as formGroup">
  <div
    class="bg-white p-4 border border-aux-gray-dark text-sm mt-5"
    [formGroup]="formGroup"
    [ngClass]="{ 'forecast-overview-form': (this.editMode$ | async) === true }"
  >
    <div
      *ngIf="formGroup.getRawValue() as patientVisitsValues"
      class="grid grid-cols-[2fr_1fr] gap-y-3 items-center"
    >
      <p class="col-span-2 font-bold">Remaining Costs</p>

      <p>Forecasted Remaining Visit Costs:</p>
      <aux-investigator-forecast-link
        class="text-right"
        [label]="patientVisitsValues.forecastedRemainingVisitCosts | money"
        [link]="investigatorSummaryRoute"
      />

      <div>
        <p>Invoiceables %:</p>
        <p class="text-aux-gray-darkest italic">
          Current: {{ patientVisitsValues.currentInvoiceablesPercent | percent }}
        </p>
      </div>
      <aux-input-mask formControlName="invoiceablesPerc" maskType="percentage" />

      <p>Forecasted Remaining Invoiceables:</p>
      <p class="text-right">{{ patientVisitsValues.forecastedRemainingInvoiceables | money }}</p>

      <p>Invoiceables Distribution:</p>
      <aux-slider
        formControlName="invoiceablesDistribution"
        type="percent"
        [minLabel]="
          (formatDistributionLabel(patientVisitsValues.invoiceablesDistribution) | percent) +
          ' Patient Invoiceables'
        "
        [maxLabel]="
          (formatDistributionLabel(100 - patientVisitsValues.invoiceablesDistribution) | percent) +
          ' Site Invoiceables'
        "
        [options]="invoiceablesDistributionOptions"
      />

      <p class="font-bold">Forecasted Remaining Investigator Costs</p>
      <p class="font-bold text-right">
        {{ patientVisitsValues.forecastedRemainingInvestigatorCosts | money }}
      </p>

      <div class="border-b h-1 border-aux-gray-dark col-span-2"></div>

      <p class="col-span-2 font-bold">Discontinued</p>

      <div>
        <p>Total Discontinued %:</p>
        <p class="text-aux-gray-darkest italic">
          Current: {{ patientVisitsValues.currentDiscontinuedAmount }}/{{
            patientVisitsValues.currentEnrolledAmount
          }}
          ({{ patientVisitsValues.currentDiscontinuedPerc | percent }})
        </p>
      </div>
      <aux-input-mask formControlName="totalDiscontinuedPerc" maskType="percentage" />

      <p>Patients Remaining to Discontinue:</p>
      <p class="text-right">
        {{ patientVisitsValues.patientRemainingToDiscontinueAmount | number: '1.0-2' }} Remaining of
        {{ patientVisitsValues.patientRemainingToDiscontinueAmountLeft | number: '1.0-2' }} Total
      </p>

      <p>Avg. Completed Patient Cost:</p>
      <p class="text-right">{{ patientVisitsValues.averageCompletedPatientCost | money }}</p>

      <p>Avg. % Trial Complete for Patients Remaining to Discontinue:</p>
      <aux-slider
        formControlName="remainingPatientsToDiscontinuePerc"
        type="percent"
        [minLabel]="'0%'"
        [maxLabel]="'100%'"
        [options]="remainingPatientsToDiscontinuePercOptions"
      />

      <div class="mt-4">
        <p>Avg. Cost for Remaining Discontinued Patients:</p>
        <p class="text-aux-gray-darkest italic">
          Avg % Trial Complete * Avg Completed Patient Cost
        </p>
      </div>
      <aux-input-mask
        formControlName="remainingPatientsToDiscontinueCost"
        maskType="money"
        class="mt-4"
      />

      <div>
        <p>Difference:</p>
        <p class="text-aux-gray-darkest italic">
          Avg Completed Patient Cost - Avg. Cost for Remaining Discontinued Patients
        </p>
      </div>
      <p class="text-right">({{ patientVisitsValues.differentPatientCost | money }})</p>

      <div>
        <p class="font-bold">Total Discontinued $:</p>
        <p class="text-aux-gray-darkest italic">
          Discontinued Difference * Patients Remaining to Discontinue:
        </p>
      </div>
      <!-- Temporary fix view until fil https://auxilius.atlassian.net/browse/AUXI-3351  -->
      <p *ngIf="patientVisitsValues.totalDiscontinued >= 0" class="text-right font-bold">
        ({{ patientVisitsValues.totalDiscontinued | money }})
      </p>
      <p *ngIf="patientVisitsValues.totalDiscontinued < 0" class="text-right font-bold">
        {{ patientVisitsValues.totalDiscontinued | money }}
      </p>

      <div class="border-b h-1 border-aux-gray-dark col-span-2"></div>

      <p class="col-span-2 font-bold">Spend to Date</p>

      <p>Visit Costs to Date</p>
      <p class="text-right">{{ patientVisitsValues.visitCostsToDate | money }}</p>

      <p>Invoiceables to Date</p>
      <p class="text-right">{{ patientVisitsValues.invoiceablesToDate | money }}</p>

      <p class="font-bold">Total Spend to Date</p>
      <aux-investigator-forecast-link
        class="text-right"
        [label]="patientVisitsValues.totalSpendToDate | money"
        [link]="budgetRoute"
      />
    </div>
  </div>

  <div class="bg-white p-4 border border-aux-gray-dark text-sm mt-5" [formGroup]="formGroup">
    <div
      *ngIf="formGroup.getRawValue() as patientVisitsValues"
      class="grid grid-cols-[2fr_1fr] gap-y-3 items-center"
    >
      <p>Forecasted Remaining Investigator Costs</p>
      <p class="text-right">
        {{ patientVisitsValues.forecastedRemainingInvestigatorCosts | money }}
      </p>

      <p>Total Discontinued $</p>
      <!-- Temporary fix view until fil https://auxilius.atlassian.net/browse/AUXI-3351  -->
      <p *ngIf="patientVisitsValues.totalDiscontinued >= 0" class="text-right">
        ({{ patientVisitsValues.totalDiscontinued | money }})
      </p>
      <p *ngIf="patientVisitsValues.totalDiscontinued < 0" class="text-right">
        {{ patientVisitsValues.totalDiscontinued | money }}
      </p>

      <div class="border-b h-1 border-aux-gray-dark col-span-2"></div>

      <p>Total Forecasted Remaining Investigator Costs</p>
      <p class="text-right">
        {{ patientVisitsValues.totalForecastedRemainingInvestigatorCosts | money }}
      </p>

      <p>Total Spend to Date</p>
      <aux-investigator-forecast-link
        class="text-right"
        [label]="patientVisitsValues.totalSpendToDate | money"
        [link]="budgetRoute"
      />

      <div class="border-b h-1 border-aux-gray-dark col-span-2"></div>

      <p class="font-bold">Total Forecasted Investigator Amount Through End of Trial</p>
      <p class="text-right">
        {{ patientVisitsValues.totalForecastedInvestigatorAmountThroughEndOfTrial | money }}
      </p>
    </div>
  </div>
</ng-container>
