<form #invoiceFormRef="ngForm" [formGroup]="invoiceForm">
  <div
    class="grid grid-cols-5 gap-5 p-5 rounded-t-md border mb-8"
    *ngVar="{
      isInvoiceFinalized: (isInvoiceFinalized$ | async)! || (iCloseMonthsProcessing$ | async)!,
      invoiceLockTooltip: (invoiceLockTooltip$ | async)!
    } as obj"
  >
    <div>
      <div class="text-sm mb-1">Vendor</div>
      <ng-select
        placeholder="Select"
        id="vendor"
        formControlName="vendor_id"
        auxFormError
        required
        [searchable]="false"
        [multiple]="false"
        [clearable]="true"
        [auxTooltip]="obj.invoiceLockTooltip"
        (change)="onVendorChange()"
      >
        <ng-option *ngFor="let vendor of filteredOrganizations$ | async" [value]="vendor.id">
          {{ vendor.name }}
        </ng-option>
      </ng-select>
    </div>

    <div>
      <div class="text-sm mb-1">Invoice Number</div>
      <aux-input
        formControlName="invoice_no"
        class="mb-4"
        validators="required"
        [auxTooltip]="obj.invoiceLockTooltip"
      />
    </div>

    <div>
      <div class="text-sm mb-1">PO Number</div>
      <ng-select
        placeholder="Select"
        id="po_reference"
        [searchable]="false"
        [multiple]="false"
        [formControl]="selectedPOReference"
        [clearable]="true"
        [ngClass]="{ 'is-invalid': selectedPOReference.status === 'INVALID' }"
        [auxTooltip]="obj.invoiceLockTooltip"
        [(ngModel)]="clonedInvoice.po_reference"
      >
        <ng-option *ngFor="let po of filteredPONumbers$ | async" [value]="po.id">
          {{ po.po_number }}
        </ng-option>
      </ng-select>
    </div>

    <div></div>
    <div></div>

    <div>
      <div class="text-sm mb-1">Invoice Total</div>
      <aux-input formControlName="invoice_total" [auxTooltip]="obj.invoiceLockTooltip" />
    </div>

    <div>
      <div class="text-sm mb-1">Services Total</div>
      <aux-input
        formControlName="services_total"
        [disabled]="
          (!authQuery.isAuxAdmin() && !userHasEditInvoicePermission) || obj.isInvoiceFinalized
        "
        [auxTooltip]="obj.invoiceLockTooltip"
      />
    </div>

    <div>
      <div class="text-sm mb-1">Discount Total</div>
      <aux-input
        formControlName="discount_total"
        [disabled]="
          (!authQuery.isAuxAdmin() && !userHasEditInvoicePermission) || obj.isInvoiceFinalized
        "
        [auxTooltip]="obj.invoiceLockTooltip"
      />
    </div>

    <div>
      <div class="text-sm mb-1">Pass-Through Total</div>
      <aux-input
        formControlName="pass_thru_total"
        [auxTooltip]="obj.invoiceLockTooltip"
        [disabled]="
          (!authQuery.isAuxAdmin() && !userHasEditInvoicePermission) || obj.isInvoiceFinalized
        "
      />
    </div>

    <div>
      <div class="text-sm mb-1">Investigator Total</div>
      <aux-input
        formControlName="investigator_total"
        [disabled]="
          (!authQuery.isAuxAdmin() && !userHasEditInvoicePermission) || obj.isInvoiceFinalized
        "
        [auxTooltip]="obj.invoiceLockTooltip"
      />
    </div>

    <div *ngIf="!isDeclined">
      <div class="text-sm mb-1">Accrual Period</div>
      <aux-input
        formControlName="accrual_period"
        [disabled]="accrualDisabled"
        [type]="'month'"
        [max]="accrualMaxDate"
        [min]="accrualMinDate"
        [auxTooltip]="obj.invoiceLockTooltip"
      />
    </div>

    <div>
      <div class="text-sm mb-1">Invoice Date</div>
      <aux-input
        formControlName="invoice_date"
        validators="required"
        [type]="'date'"
        [auxTooltip]="obj.invoiceLockTooltip"
      />
    </div>

    <div>
      <div class="text-sm mb-1">Due Date</div>
      <aux-input
        [type]="'date'"
        [disabled]="disabled || obj.isInvoiceFinalized"
        [ngModelOptions]="{ standalone: true }"
        [auxTooltip]="obj.invoiceLockTooltip"
        [(ngModel)]="clonedInvoice.due_date"
      />
    </div>
  </div>

  <aux-notes
    [title]="'Notes'"
    [notesControl]="notesControl"
    [hasEditPermission]="!disabled"
    [disabledTooltipText]="messagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION"
    (saveNotes)="saveNotes()"
    (cancelNotes)="cancelNotes()"
  />

  <ng-container
    *ngIf="(isOcrItemsEnabled$ | async) === true || (isIntegrationItemsEnabled$ | async) === true"
  >
    <div class="mt-8 border-b border-gray-200">
      <nav class="flex -mb-px space-x-8" aria-label="Tabs">
        <ng-container *ngFor="let tab of tabs; index as i">
          <button
            *ngIf="tab.show | async"
            type="button"
            class="px-1 py-4 text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
            [ngClass]="{
              'border-aux-blue-light text-aux-blue-light': i === activeTabIndex,
              'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                i !== activeTabIndex
            }"
            (click)="onTabChange(i)"
          >
            {{ tab.label }}
          </button>
        </ng-container>
      </nav>
    </div>

    <ng-container *ngIf="activeTabIndex === 0 && (isOcrItemsEnabled$ | async) === true">
      <aux-items-via-pdf-tab #itemsViaPdfTab [invoice]="this.clonedInvoice" />
    </ng-container>

    <ng-container *ngIf="activeTabIndex === 1 && (isIntegrationItemsEnabled$ | async) === true">
      <aux-invoice-items
        [invoice]="this.clonedInvoice"
        [items]="this.clonedInvoice.line_items || []"
      />
    </ng-container>
  </ng-container>
</form>
