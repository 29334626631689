<p>
  All icons from the Tabler Icon Set -
  <a class="aux-link" href="https://github.com/tabler/tabler-icons">
    https://github.com/tabler/tabler-icons
  </a>
</p>
<div *ngFor="let icon of icons" class="mt-4">
  <aux-icon [name]="icon" class="mr-2 align-middle" />
  <div class="inline-block align-middle">{{ icon }}</div>
  <div class="flex items-center mt-1">
    <div class="text-black bg-aux-gray-dark">
      <div class="m-2">1</div>
    </div>

    <div class="pr-2 text-white bg-aux-gray-darkest">
      <div class="m-2">&lt;aux-icon name="{{ icon }}" /&gt;</div>
    </div>
  </div>
</div>
