<div class="w-full">
  <div>
    <div>
      <div *ngTemplateOutlet="recursiveMenu; context: { $implicit: dataSource$ | async }"></div>
      <ng-template #recursiveMenu let-menus>
        <ul class="text-sm">
          <li
            *ngFor="let menu of menus"
            [ngClass]="{
              'flex flex-col px-4 cursor-pointer': hasChild(menu) || singleRoute(menu),
              'pl-[35px] pr-4 h-[35px] flex items-center': isCollapsable(menu),
              'font-bold': isActiveLink(menu.url) || expanded[menu.name],
              'bg-aux-gray-light border-t border-b border-aux-gray-dark text-aux-blue':
                isActiveLink(menu.url) && hasChild(menu),
              'pl-[23px] h-[35px] flex justify-center': singleRoute(menu)
            }"
          >
            <!-- level 1 -->
            <div *ngIf="hasChild(menu)" (click)="onItemSelected(menu)">
              <div *ngVar="isActiveLink(menu.url)" class="flex items-center h-10">
                <button
                  class="focus:outline-none text-aux-black transition duration-300 transform ease-in-out w-8 h-8 rounded-full flex items-center justify-center"
                  [attr.aria-label]="'Toggle ' + menu.name"
                  [ngClass]="{
                    '-rotate-180': expanded[menu.name]
                  }"
                >
                  <aux-icon name="ChevronDown" />
                </button>

                <span class="nav-header-primary p-2 text-aux-black">{{ menu.name }}</span>
              </div>
            </div>

            <!-- sub level or single menu item -->
            <div
              *ngIf="isCollapsable(menu) || singleRoute(menu)"
              class="flex items-center font-normal w-auto h-[35px]"
              [routerLink]="menu.url"
            >
              <ng-container *ngVar="isActiveLink(menu.url) as isActive">
                <div
                  *ngIf="{
                    svg: (isActive && menu.activeSvg) || menu.svg
                  } as obj"
                  class="flex h-7.5 items-center justify-center text-aux-blue"
                  [ngClass]="{
                    'text-aux-dark': isActive,
                    'w-[18px] mr-2': obj.svg
                  }"
                >
                  <aux-icon
                    [name]="obj.svg"
                    [size]="18"
                    *ngIf="obj.svg"
                    [ngClass]="menu.className"
                  />
                  <div *ngIf="!obj.svg && isActive" class="relative">
                    <div class="h-6 w-1 bg-aux-blue-dark rounded-full absolute -bottom-3"></div>
                  </div>
                </div>
                <a
                  class="grow-0 text-sm whitespace-nowrap overflow-hidden text-ellipsis"
                  [ngClass]="{
                    'text-aux-blue-dark font-bold': isActive,
                    'font-medium': !isActive,
                    'ml-2': isActive && !(menu.activeSvg || menu.svg),
                    'hover:underline decoration-aux-blue-dark underline-offset-[3px] decoration-4':
                      !isActive
                  }"
                >
                  {{ menu.name }}
                </a>
                <span
                  *ngIf="menu?.badge?.amount | async as badgeAmount"
                  class="flex items-center justify-center h-6 w-6 bg-aux-error rounded-full ml-2"
                  [auxTooltip]="menu?.badge?.tooltip || ''"
                >
                  <span class="text-aux-gray-light text-sm font-bold"> {{ badgeAmount }} </span>
                </span>
              </ng-container>
            </div>

            <!-- Divider -->
            <hr *ngIf="isDivider(menu)" class="my-4" />

            <!-- sub level -->
            <ul *ngIf="menu.subRoutes && expanded[menu.name]" id="nav{{ menu.name }}">
              <ng-container
                *ngTemplateOutlet="recursiveMenu; context: { $implicit: menu.subRoutes }"
              />
            </ul>
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
</div>
