import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components/components.module';
import { DirectivesModule } from '@directives/directives.module';
import { CompareDropdownComponent } from './compare-dropdown.component';
import { IconComponent } from '@components/icon/icon.component';

@NgModule({
  imports: [CommonModule, ComponentsModule, DirectivesModule, IconComponent],
  providers: [],
  declarations: [CompareDropdownComponent],
  exports: [CompareDropdownComponent],
})
export class CompareDropdownModule {}
