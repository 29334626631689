import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  BudgetCompareMenuSnapshotChangeFn,
  BudgetCompareMenuSnapshotDisabled,
  BudgetCompareMenuSnapshotValue,
  BudgetCompareMenuSnapshotRefreshFn,
  BudgetCompareMenuSnapshotShowDelete,
  BudgetCompareMenuSnapshotShowEdit,
  defaultSnapshotChangeFn,
  defaultSnapshotRefreshFn,
  BudgetCompareMenuSnapshotList,
  BudgetCompareMenuSnapshotFormControl,
  BudgetCompareMenuSnapshotLoading,
} from '../../budget-compare-menu.models';

@UntilDestroy()
@Component({
  selector: 'aux-budget-compare-menu-snapshot',
  templateUrl: './budget-compare-menu-snapshot.component.html',
  styleUrls: ['../../budget-compare-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetCompareMenuSnapshotComponent {
  @Input() snapshotFormControl: BudgetCompareMenuSnapshotFormControl;

  @Input() snapshotList: BudgetCompareMenuSnapshotList;

  @Input() snapshotValue: BudgetCompareMenuSnapshotValue;

  @Input() snapshotDisabled: BudgetCompareMenuSnapshotDisabled;

  @Input() snapshotShowEdit: BudgetCompareMenuSnapshotShowEdit;

  @Input() snapshotShowDelete: BudgetCompareMenuSnapshotShowDelete;

  @Input() snapshotLoading: BudgetCompareMenuSnapshotLoading;

  @Input() snapshotChangeFn: BudgetCompareMenuSnapshotChangeFn;

  @Input() snapshotRefreshFn: BudgetCompareMenuSnapshotRefreshFn;

  tooltip = 'Comparing to a snapshot is only supported in Vendor Currency';

  constructor() {
    this.snapshotFormControl = new FormControl<string>('');
    this.snapshotList = [];
    this.snapshotValue = '';
    this.snapshotDisabled = false;
    this.snapshotShowEdit = true;
    this.snapshotShowDelete = true;
    this.snapshotLoading = false;
    this.snapshotChangeFn = defaultSnapshotChangeFn;
    this.snapshotRefreshFn = defaultSnapshotRefreshFn;
  }
}
