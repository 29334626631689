<h2 class="font-bold mt-6">Server-Side Pagination</h2>

<form [formGroup]="gridFiltersFormGroup" class="my-4 flex space-x-4">
  <aux-input [type]="'date'" placeholder="YYYY-MM-DD" formControlName="afterDate" class="w-36" />
  <aux-input placeholder="Action search" formControlName="actionSearch" class="w-36" />

  <aux-export-excel-button
    [gridAPI]="exportGridApi"
    [getDynamicExcelParamsCallback]="getDynamicExcelParams"
    [onExportSuccess]="onExportSuccess"
  />
  <aux-button
    classList="w-48 text-sm btn btn--blue"
    [loading]="exporting$ | async"
    [disabled]="exporting$ | async"
    [spinnerSize]="6"
    [onClick]="onExportCsvClick"
    label="Download Csv"
  ></aux-button>
</form>

<aux-pagination-grid
  [gridOptions]="$any(gridOptions$ | async)"
  (gridReady)="gridReady()"
  [dataSource]="datasource"
  [filterForm]="gridFiltersFormGroup"
  [serverSideFilters]="serverSideFilters"
  [filterValues$]="filterValues$"
  [sortModel$]="sortModel$"
  [exportGridOptions]="(exportGridOptions$ | async)!"
  [idTable]="'test'"
/>

<h2 class="font-bold mt-6">Compact Tables</h2>

<ag-grid-angular
  class="ag-theme-aux mb-8 w-full"
  [rowData]="compactGridData"
  [gridOptions]="compactGridOptions"
  [domLayout]="'autoHeight'"
/>

<div class="flex items-center mt-2">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(5)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;ag-grid-angular</div>
    <div class="m-2">class="ag-theme-aux mb-8 w-full"</div>
    <div class="m-2">[rowData]="compactGridData"</div>
    <div class="m-2">[gridOptions]="compactGridOptions"&gt;</div>
    <div class="m-2">&lt;/ag-grid-angular&gt;</div>
  </div>
</div>

<h3 class="mt-6">Checkbox Selections & Hover States</h3>

<ag-grid-angular
  class="ag-theme-aux mb-8 w-full"
  rowSelection="multiple"
  [rowData]="checkboxGridData"
  [gridOptions]="checkboxGridOptions"
  [domLayout]="'autoHeight'"
/>

<div class="flex items-center mt-2">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(6)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;ag-grid-angular</div>
    <div class="m-2">class="ag-theme-aux mb-8 w-full"</div>
    <div class="m-2">[rowData]="checkboxGridData"</div>
    <div class="m-2">[gridOptions]="checkboxGridOptions"</div>
    <div class="m-2">rowSelection="multiple"&gt;</div>
    <div class="m-2">&lt;/ag-grid-angular&gt;</div>
  </div>
</div>

<h3 class="mt-6">Sorting</h3>

<ag-grid-angular
  class="ag-theme-aux mb-8 w-full"
  [rowData]="gridData"
  [gridOptions]="gridOptions"
  [domLayout]="'autoHeight'"
/>

<div class="flex items-center mt-2">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(5)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;ag-grid-angular</div>
    <div class="m-2">class="ag-theme-aux mb-8 w-full"</div>
    <div class="m-2">[rowData]="gridData"</div>
    <div class="m-2">[gridOptions]="gridOptions"></div>
    <div class="m-2">&lt;/ag-grid-angular&gt;</div>
  </div>
</div>

<br />

<h2 class="font-bold mt-6">Trial Insights</h2>

<br />

<h2 class="font-bold mt-6">Trial Insights Header</h2>

<div style="width: 450px; margin-top: 15px">
  <aux-trial-insights-header [title]="'Header Title'" [header]="'1 Sub Header'" [color]="'#3572b0'">
    <!-- Icon -->
    <ng-container icon>
      <aux-icon name="Users" [size]="35" [ngStyle]="{ color: '#3572b0' }" />
    </ng-container>

    <!-- Additional Content (optional) -->
    <ng-container additionalContent>
      <a class="aux-link"> Link </a>
    </ng-container>
  </aux-trial-insights-header>
</div>

<br />

<h2 class="font-bold mt-6">Trial Insights Legend</h2>

<div style="width: 450px; margin-top: 15px">
  <aux-trial-insights-legend [options]="trialInsightsSiteChartService.createLegend(100, 250)" />
</div>

<br />

<h2 class="font-bold mt-6">Trial Insights Legend (compact)</h2>

<div style="width: 450px; margin-top: 15px">
  <aux-trial-insights-legend
    [options]="trialInsightsClinicalCreateLegend(['Legend 1', 'Legend 2'])"
  />
</div>

<br />

<h2 class="font-bold mt-6">Trial Insights Table</h2>

<div style="width: 450px; margin-top: 15px">
  <aux-trial-insights-table
    [options]="tableOptions"
    [isLoadingRemaining]="isLoadingRemaining"
    [selectedKey]="selectedKey"
    [sortOrder]="sortOrder"
    [changeSelectedKey]="changeSelectedKey"
    [toggleSortOrder]="toggleSortOrder"
  />
</div>
