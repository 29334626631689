import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AUX_ICONS, AuxIconName } from '@components/icon/icon';
import { NgForOf } from '@angular/common';
import { IconComponent } from '@components/icon/icon.component';

@Component({
  selector: 'aux-iconography',
  templateUrl: './iconography.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf, IconComponent],
})
export class IconographyComponent {
  icons = Object.keys(AUX_ICONS).map((z) => {
    return z.replace('Icon', '') as AuxIconName;
  });
}
