import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { BudgetType, EntityType, GqlService, NoteType } from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { Utils } from '@services/utils';
import { BudgetLibraryModel, BudgetLibraryStore } from './budget-library.store';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BudgetLibraryService {
  constructor(
    private budgetLibraryStore: BudgetLibraryStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.budgetLibraryStore.setLoading(true);
        this.budgetLibraryStore.remove(() => true);
        return this.gqlService
          .listBudgetLibrary$([BudgetType.BUDGET_PRIMARY, BudgetType.BUDGET_SECONDARY], '')
          .pipe(
            map(({ data, success, errors }) => {
              const bLibrary = (data || []).map((val) => {
                return { ...val, id: val.budget_version_id };
              });
              this.budgetLibraryStore.set(bLibrary);

              this.budgetLibraryStore.setLoading(false);
              return { success, errors, data };
            })
          );
      })
    );
  }

  async updateBudgetVersionAsBaseline(id: string, organization_id: string) {
    const ref = this.overlayService.loading();
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.setBudgetVersionAsBaseline$(id, organization_id)
    );
    if (errors.length) {
      this.overlayService.error(errors);
    } else if (success) {
      data?.forEach((x: BudgetLibraryModel) => {
        this.budgetLibraryStore.update(x.budget_version_id, x);
      });
      this.overlayService.success();
      ref.close();
    }
  }

  async updateLastBudgetVersion(item: BudgetLibraryModel) {
    this.budgetLibraryStore.update(item?.budget_version_id, item as BudgetLibraryModel);
  }

  async removeBudgetVersion(item: BudgetLibraryModel) {
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Budget Version',
      message: `Are you sure you want to remove Budget ${item.budget_name}?`,
      okBtnText: 'Remove',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });

    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data?.result) {
      const { success, errors } = await firstValueFrom(
        this.gqlService.removeBudgetVersion$(item.budget_version_id)
      );
      if (success) {
        if (event.data.textarea.length > 0) {
          const {
            errors: cErrors,
            success: cSuccess,
            data: cData,
          } = await firstValueFrom(
            this.gqlService.createNote$({
              entity_id: item.budget_version_id,
              entity_type: EntityType.BUDGET_VERSION,
              note_type: NoteType.NOTE_TYPE_DELETE_REASON,
              message: Utils.scrubUserInput(event.data.textarea),
            })
          );
          if (cSuccess && cData) {
            this.overlayService.success();
          } else {
            this.overlayService.error(cErrors);
          }
        }
        this.overlayService.success(`${item.budget_name} successfully removed!`);
        this.budgetLibraryStore.remove(item.budget_version_id);
      } else {
        this.overlayService.error(errors);
      }
    }
  }
}
