import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ColorsComponent } from './colors/colors.component';
import { IconographyComponent } from './iconography/iconography.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { FormsComponent } from './forms/forms.component';
import { TablesComponent } from './tables';
import { ModalComponent } from './modal';
import { TypographyComponent } from './typography';
import { ButtonToggleComponentDemoComponent } from './button-toggle-component/button-toggle-component-demo.component';

const routes: Routes = [
  {
    path: 'colors',
    component: ColorsComponent,
  },
  {
    path: 'iconography',
    component: IconographyComponent,
  },
  {
    path: 'buttons',
    component: ButtonsComponent,
  },
  {
    path: 'button-toggle-group-demo',
    component: ButtonToggleComponentDemoComponent,
  },
  {
    path: 'forms-and-controls',
    component: FormsComponent,
  },
  {
    path: 'tables',
    component: TablesComponent,
  },
  {
    path: 'modals',
    component: ModalComponent,
  },
  {
    path: 'typography',
    component: TypographyComponent,
  },
  { path: '**', redirectTo: 'colors' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DesignSystemRoutingModule {}
