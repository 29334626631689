import { Component } from '@angular/core';
import {
  ChecklistComponentData,
  ChecklistComponentDataAddPoReport,
} from '../../models/quarter-close-checklist.model';
import { ROUTING_PATH } from '../../../../../../app-routing-path.const';
import { ChecklistRowInfoComponent } from '../checklist-row-info/checklist-row-info.component';
import { Utils } from '@services/utils';

@Component({
  templateUrl: './checklist-section-gather-contracts.component.html',
  styleUrls: ['../../quarter-close-checklist.component.scss'],
})
export class ChecklistSectionGatherContractsComponent implements ChecklistComponentData {
  parent!: ChecklistRowInfoComponent;

  id = 'GatherContracts';

  data: ChecklistComponentDataAddPoReport = {
    disabledLock: false,
    addPoReportUploadClick: () => {},
  };

  changeOrdersRoute = `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`;

  sitesRoute = `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES}`;

  purchaseOrdersRoute = `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PURCHASE_ORDERS}`;

  available = true;

  zeroHyphen = Utils.zeroHyphen;
}
