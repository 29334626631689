<div class="font-inter max-w-2xl" [ngClass]="{ 'w-screen': hasPaymentStatus }">
  <div class="text-xl font-bold mb-4">
    Bulk Edit: <span class="font-bold">{{ selectedRows.length }} Selected Invoices</span>
  </div>
  <div class="flex pb-4">
    <div class="w-56">
      <span class="text-xs mb-1">Vendor:</span>
      <ng-select
        placeholder="Select"
        bindValue="value"
        [appendTo]="'body'"
        [(ngModel)]="vendor"
        (clear)="vendor = undefined"
        (change)="onVendorChange($event)"
      >
        <ng-option *ngFor="let option of invoiceService.vendorOptions" [value]="option.value">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
    <div class="w-60 pl-4">
      <span class="text-xs mb-1">Status:</span>
      <ng-select
        placeholder="Select"
        bindValue="value"
        [appendTo]="'body'"
        [items]="invoiceStatusOptions"
        [(ngModel)]="status"
        (clear)="status = undefined"
      >
        <ng-template let-item="item" ng-label-tmp ng-option-tmp>
          <div class="flex items-center whitespace-nowrap">
            <div class="w-3 h-3 rounded-full mr-1" [ngClass]="item.bgClass"></div>
            <div [ngClass]="item.textClass">{{ item.label }}</div>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div *ngIf="hasPaymentStatus" class="w-52 pl-4">
      <span class="text-xs mb-1">Payment Status:</span>
      <ng-select
        placeholder="Select"
        bindValue="value"
        [appendTo]="'body'"
        [items]="paymentStatusOptions"
        [(ngModel)]="paymentStatus"
        (clear)="paymentStatus = undefined"
      >
        <ng-template let-item="item" ng-label-tmp ng-option-tmp>
          <div class="flex items-center whitespace-nowrap">
            <div class="w-3 h-3 rounded-full mr-1" [ngClass]="item.bgClass"></div>
            <div [ngClass]="item.textClass">{{ item.label }}</div>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="flex border-b pb-4">
    <div class="w-56">
      <span class="text-xs mb-1">Purchase Order:</span>
      <ng-select
        placeholder="Select"
        bindValue="value"
        [appendTo]="'body'"
        [(ngModel)]="purchaseOrder"
        (clear)="purchaseOrder = undefined"
      >
        <ng-option *ngFor="let option of purchaseOrderOptions" [value]="option.value">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
    <div class="w-60 pl-4">
      <span class="text-xs mb-1">Accrual Period:</span>
      <ng-select
        placeholder="Select"
        bindValue="value"
        [appendTo]="'body'"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="accrualPeriod"
        (clear)="accrualPeriod = undefined"
      >
        <ng-option *ngFor="let option of accrualPeriodOptions" [value]="option.value">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
  </div>

  <div class="flex justify-between mt-4">
    <aux-button variant="custom" classList="text-sm h-full" label="Cancel" (click)="ref.close()" />
    <aux-button variant="primary" label="Apply" [spinnerSize]="6" [onClick]="onApply" />
  </div>
</div>
