import { Component, Input } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';
import { AuxIconName } from '@components/icon/icon';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

export interface AuxButtonGroup {
  name: string;
  iconName?: AuxIconName;
  onClick: () => void;
  disabled?: boolean;
  disabledTooltip?: string;
}

@Component({
  standalone: true,
  selector: 'aux-button-group',
  template: `
    <button
      class="flex items-center btn--secondary py-[7px] px-[8px]"
      (click)="dropdownOpened = !dropdownOpened"
      [disabled]="disabled"
      [auxTooltip]="disabled ? disabledTooltip : ''"
    >
      <aux-icon *ngIf="iconName" [name]="iconName!" class="mr-[5px]" />
      <div>{{ label }}</div>
      <aux-icon name="CaretDownFilled" class="ml-[5px]" [size]="16" />
    </button>
    <div #trigger="cdkOverlayOrigin" cdkOverlayOrigin></div>

    <ng-template
      cdkConnectedOverlay
      class="border-solid border-aux-gray-dark-100"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="dropdownOpened"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'random-class'"
      (detach)="dropdownOpened = false"
      (backdropClick)="dropdownOpened = false"
    >
      <div class="py-3 bg-white rounded shadow min-w-[200px]">
        <div
          *ngFor="let option of options"
          (click)="onOptionClick(option)"
          class="flex items-center p-[8px] "
          [ngClass]="{
            'hover:bg-aux-blue-light-50 cursor-pointer': option.disabled !== true,
            'opacity-50 cursor-not-allowed': option.disabled === true
          }"
          [auxTooltip]="option.disabled ? $any(option.disabledTooltip) : ''"
        >
          <aux-icon *ngIf="option.iconName" [name]="option.iconName" class="mr-[12px]" />
          {{ option.name }}
        </div>
      </div>
    </ng-template>
  `,
  imports: [
    IconComponent,
    NgIf,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    NgForOf,
    TooltipDirective,
    NgClass,
    AsyncPipe,
  ],
  styles: [],
})
export class ButtonGroupComponent {
  @Input({ required: true }) label = '';
  @Input() options: AuxButtonGroup[] = [];
  @Input() disabled = false;
  @Input() disabledTooltip = '';

  @Input() iconName?: AuxIconName;

  dropdownOpened = false;

  onOptionClick(option: AuxButtonGroup) {
    if (!option.disabled) {
      option.onClick();
      this.dropdownOpened = false;
    }
  }
}
