<ng-container *ngIf="template">
  <ng-container *ngIf="clearTemplate">
    <ng-container @tooltip *ngTemplateOutlet="template" />
  </ng-container>
  <ng-container *ngIf="!clearTemplate">
    <div @tooltip>
      <ng-container *ngTemplateOutlet="template" />
    </div>
  </ng-container>
</ng-container>
<div *ngIf="!template" @tooltip>{{ text }}</div>
