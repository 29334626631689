import { Injectable } from '@angular/core';
import { TrialInsightsChartModel } from '../../models/trial-insights-chart.model';
import {
  ChartConfiguration,
  ChartDataset,
  ElementOptionsByType,
  PluginOptionsByType,
  ScaleChartOptions,
  TooltipOptions,
} from 'chart.js';
import { DeepPartial } from 'chart.js/dist/types/utils';

// TrialInsightsClinicalInvestigatorCost
export type TICICChartType = 'bar';
// TrialInsightsClinicalInvestigatorCost
export type TICICChartLabel = [string, number][];

@Injectable()
export class TrialInsightsClinicalInvestigatorCostChartService
  implements TrialInsightsChartModel<TICICChartType, TICICChartLabel>
{
  createDatasets = (labels?: TICICChartLabel): ChartDataset<TICICChartType>[] => {
    const datasets: ChartDataset<TICICChartType>[] = [];

    if (!labels?.length) {
      return datasets;
    }

    const colors = ['#226263', '#437f7f', '#6e9797', '#4e6d79', '#8b9fa7'];
    const hoverColors = ['#207d7e', '#449494', '#75b7b7', '#588496', '#90aeba'];

    labels.forEach(([label, percent], index) => {
      datasets.push({
        label: label,
        data: [percent],
        backgroundColor: [colors[index]],
        hoverBackgroundColor: [hoverColors[index]],
        barThickness: 85,
        xAxisID: 'x1',
      });
    });

    return datasets;
  };

  createChart = (datasets?: ChartDataset<TICICChartType>[]): ChartConfiguration<TICICChartType> => {
    return {
      type: 'bar',
      options: this.chartOptions(),
      data: {
        datasets: datasets ? datasets : [],
        labels: this.labelOptions(),
      },
    };
  };

  chartOptions = (): ChartConfiguration<TICICChartType>['options'] => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: this.elementOptions(),
      scales: {
        ...this.xAxesOptions().scales,
        ...this.yAxesOptions().scales,
      },
      plugins: this.pluginOptions(),
      indexAxis: 'y',
    };
  };

  labelOptions = () => {
    return ['Investigator Costs'];
  };

  elementOptions = (): DeepPartial<ElementOptionsByType<TICICChartType>> => {
    return {
      bar: {
        borderColor: '#bacad0',
      },
    };
  };

  pluginOptions = (): DeepPartial<PluginOptionsByType<TICICChartType>> => {
    return {
      legend: {
        display: false,
      },
      tooltip: this.tooltipOptions(),
    };
  };

  tooltipOptions = (): DeepPartial<TooltipOptions<TICICChartType>> => {
    return {
      callbacks: {
        label: (tooltipItem) => {
          const label = tooltipItem.dataset.label;
          const value = tooltipItem.formattedValue;

          return `${label}: ${value}%`;
        },
      },
    };
  };

  xAxesOptions = (): DeepPartial<ScaleChartOptions<TICICChartType>> => {
    return {
      scales: {
        x1: {
          stacked: true,
          beginAtZero: true,
          max: 100,
          title: {
            display: false,
          },
          grid: {
            display: false,
            color: '#bacad0',
            lineWidth: 0.75,
            z: 1,
          },
          ticks: {
            display: false,
            stepSize: 10,
          },
        },
      },
    };
  };

  yAxesOptions = (): DeepPartial<ScaleChartOptions<TICICChartType>> => {
    return {
      scales: {
        y: {
          display: false,
          stacked: true,
        },
      },
    };
  };
}
