<div class="flex p-4 bg-aux-gray-light border border-aux-gray-dark">
  <div class="w-[250px]">
    <div class="text-sm mb-1">Customer name</div>

    <div class="mb-2">{{ authQuery.fullName$ | async }}</div>

    <hr class="mb-2" />

    <div class="text-sm mb-1">Trial</div>

    <div class="mb-2">{{ activeTrial }}</div>

    <hr class="mb-2" />

    <div class="text-sm mb-1">Current open month</div>

    <div class="mb-2">{{ currentOpenMonth }}</div>
  </div>

  <div class="self-stretch bg-aux-gray-dark w-px mx-4"></div>

  <div class="flex-grow">
    <div class="text-sm mb-2">Upload Input Files</div>

    <aux-file-manager
      #fileManager
      class="h-40 bg-aux-gray-light"
      [fetchFilesOnInit]="false"
      [pathFn]="getBucketKey"
      [eager]="false"
      [showSuccessOnUpload]="true"
      [accept]="accept"
      [extensions]="extensions"
      [showSpecificError]="true"
      [entityIdS3Uploading]="true"
    >
      <div class="flex items-center justify-center text-aux-gray-dark-100">
        <aux-icon name="Upload" [size]="42" />
        <div class="ml-3">
          <p class="text-lg">Drag & Drop Documents Here or <span class="aux-link">Browse</span></p>
        </div>
      </div>
    </aux-file-manager>
  </div>

  <div class="pl-8 w-[300px] flex flex-col">
    <div class="text-sm border-b border-aux-gray-dark">Selected Files</div>

    <aux-file-manager-uploaded-files
      [fileManager]="fileManager"
      class="flex-grow"
      [titleLess]="true"
      filesContainerClassNames="!mt-0"
    />

    <div class="flex justify-between mt-2">
      <aux-button variant="secondary" label="Clear All" classList="h-[40px]" (click)="clearAll()" />

      <aux-button
        variant="primary"
        label="Convert"
        icon="RobotFace"
        [spinnerSize]="7"
        [loading]="loading"
        [disabled]="uploadedFiles.length < 1 || loading"
        [onClick]="convert.bind(this)"
      />
    </div>
  </div>
</div>

<aux-edc-converted-files />
