<div class="flex items-center compare-dropdown">
  <span class="mr-1 text-sm">Compare to</span>
  <ng-select
    id="compare-snapshot-dropdown"
    class="w-60 text-sm compare-dropdown"
    placeholder="Select a budget snapshot"
    bindValue="value"
    appendTo="body"
    [clearable]="true"
    [searchable]="false"
    [loading]="snapshotLoading"
    [formControl]="snapshotFormControl"
    [items]="snapshotList"
    [groupBy]="groupByFn"
  >
    <ng-template let-item="item" let-index="index" ng-option-tmp>
      <div>
        <div class="flex justify-between">
          <p class="w-40 overflow-hidden text-ellipsis" [auxTooltip]="item.label">
            {{ item.label }}
          </p>
          <div *ngIf="item.value" class="flex items-center">
            <aux-icon
              name="Pencil"
              [size]="16"
              *ngIf="snapshotShowEdit && item.snapshot_type === BUDGET_SNAPSHOT_USER_CREATED"
              class="text-aux-blue mr-1"
              (click)="editSnapshot($event, item)"
            />

            <aux-icon
              name="Trash"
              [size]="16"
              *ngIf="snapshotShowDelete"
              class="text-aux-red-dark"
              (click)="deleteSnapshot($event, item)"
            />
          </div>
        </div>
        <p class="text-aux-gray-darkest">{{ item.create_date | date: 'YYYY-MM-dd' }}</p>
      </div>
    </ng-template>
  </ng-select>
</div>
