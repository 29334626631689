import { Component, ChangeDetectionStrategy } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import { enter, leave } from '@animations/enterLeave';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';

@Component({
  template: `
    <div class="max-w-sm w-full rounded-lg pointer-events-auto overflow-hidden" @enterLeave>
      <div class="border-8 h-32 m-auto spinner w-32"></div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enterLeave', [
      transition(':enter', [useAnimation(enter)]),
      transition(':leave', [useAnimation(leave)]),
    ]),
  ],
})
export class SpinnerDialogComponent {
  constructor(public ref: CustomOverlayRef<null, null>) {}
}
