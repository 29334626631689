<div class="aux-slider">
  <span class="range">{{ minLabel || options.floor }}</span>
  <ngx-slider
    [options]="options"
    [value]="fc.value"
    [ngClass]="{ disabled: fc.disabled }"
    (valueChange)="onValueChange($event)"
  />
  <span class="range">{{ maxLabel || options.ceil }}</span>
</div>
