<nav
  aria-label="Tabs"
  [ngClass]="{
    'flex -mb-px overflow-x-auto overflow-y-hidden border-b border-gray-200': isClassicVariant,
    'space-x-8': (isFullPage$ | async) === false && isClassicVariant,
    'divide-x': (isFullPage$ | async) === true && isClassicVariant
  }"
>
  <ng-container *ngIf="{ tabs: tabs$ | async, activeIndex: activeIndex$ | async } as obj">
    <ng-container *ngFor="let tab of obj.tabs; index as index">
      <button
        *ngIf="isClassicVariant && (tab.show ? (tab.show | async) : true)"
        type="button"
        class="text-sm font-medium whitespace-nowrap focus:outline-none flex items-center"
        [routerLink]="tab.route"
        [ngClass]="{
          'border-b-2 px-1 py-4': (isFullPage$ | async) === false,
          'px-4 my-4': (isFullPage$ | async) === true,
          'border-aux-blue-light text-aux-blue-light':
            (isFullPage$ | async) === false && index === obj.activeIndex,
          'border-transparent text-gray-500':
            (isFullPage$ | async) === false && index !== obj.activeIndex,
          'font-bold text-aux-black': (isFullPage$ | async) === true && index === obj.activeIndex,
          'underline text-aux-blue': (isFullPage$ | async) === true && index !== obj.activeIndex,
          'hover:text-gray-700 hover:border-gray-300': index !== obj.activeIndex
        }"
      >
        {{ tab.label }}
        <span
          *ngIf="tab?.badge?.amount$ | async as badgeAmount"
          class="flex items-center justify-center h-6 w-6 bg-aux-error rounded-full ml-2"
          [auxTooltip]="tab?.badge?.tooltip || ''"
        >
          <span class="text-aux-gray-light text-sm font-bold"> {{ badgeAmount }} </span>
        </span>
      </button>
      <button
        *ngIf="isButtonVariant && (tab.show ? (tab.show | async) : true)"
        type="button"
        class="px-4 py-2 rounded text-sm font-medium border-b-2 whitespace-nowrap focus:outline-none"
        [routerLink]="tab.route"
        [ngClass]="{
          'border-aux-blue-light bg-aux-blue-light text-white': index === obj.activeIndex,
          'border-transparent text-gray-500 hover:text-gray-700': index !== obj.activeIndex
        }"
      >
        {{ tab.label }}
      </button>
    </ng-container>
  </ng-container>
</nav>
