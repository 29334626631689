import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass } from '@angular/common';
import { AgStatusComponentParams } from '@shared/components/ag-status/ag-status.model';

@Component({
  templateUrl: 'ag-status.component.html',
  styleUrls: ['ag-status.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class AgStatusComponent implements ICellRendererAngularComp {
  statusText!: string;

  bgClass!: string;

  textClass!: string;

  refresh(): boolean {
    return false;
  }

  agInit(params: AgStatusComponentParams): void {
    const { bgClass, textClass, statusText } = params.status;

    this.bgClass = bgClass;
    this.statusText = statusText;
    this.textClass = textClass;
  }
}
