import { ConnectedPosition } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { BaseFormControlComponent } from '@components/form-inputs/base-form-control';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { TrialStatusMap } from '@constants/status.constants';
import { NgSelectModule } from '@ng-select/ng-select';
import { Trial, TrialImplementationStatus } from '@services/gql.service';
import { RequireSome } from '@services/utils';

@Component({
  selector: 'aux-trial-dropdown',
  standalone: true,
  imports: [NgSelectModule, ReactiveFormsModule, CommonModule, TooltipDirective],
  templateUrl: './trial-dropdown.component.html',
  styles: [
    `
      ::ng-deep .trial-select .ng-select-container {
        @apply bg-transparent border-none h-full;
      }

      ::ng-deep .trial-select .ng-dropdown-panel {
        z-index: 1000 !important;
      }

      ::ng-deep .trial-select.ng-select .ng-select-container .ng-value-container {
        @apply pl-5 pt-[22px];
      }

      ::ng-deep .trial-select .ng-input {
        top: 20px !important;
        left: 8px !important;
      }

      ::ng-deep
        .portfolio-trial-select
        .ng-dropdown-panel.ng-select-bottom
        .ng-dropdown-panel-items
        .ng-option:first-child,
      ::ng-deep
        .trial-select
        .ng-dropdown-panel.ng-select-bottom
        .ng-dropdown-panel-items
        .ng-option:first-child {
        background-color: #e5e5e5 !important;
      }

      ::ng-deep .trial-select > .ng-dropdown-panel.ng-select-bottom {
        margin-top: 14px;
      }

      ::ng-deep .portfolio-trial-select.portfolio:not(.filled) .ng-placeholder {
        display: block !important;
      }

      ::ng-deep .portfolio-trial-select .ng-select-container {
        @apply bg-transparent border-none h-full;
      }

      ::ng-deep .portfolio-trial-select.ng-select .ng-select-container .ng-value-container {
        @apply pl-5 font-bold pt-4;
      }

      ::ng-deep .portfolio-trial-select .ng-input {
        top: 22px !important;
        left: 8px !important;
      }
      ::ng-deep .cdk-overlay-pane {
        z-index: 1051 !important;
      }

      ::ng-deep .trial-select.ng-select .ng-arrow,
      ::ng-deep .portfolio-trial-select.ng-select .ng-arrow {
        display: none !important;
      }
      ::ng-deep .trial-select.ng-select .ng-arrow-wrapper,
      ::ng-deep .portfolio-trial-select.ng-select .ng-arrow-wrapper {
        height: 24px;
        width: 24px;
        background-image: url('/assets/svg/chevron-down.svg');
        background-repeat: no-repeat;
        transform: rotate(0deg);
        transform-origin: 12px 12px;
        transition: transform 0.3s ease;
        margin-right: 8px;
      }
      ::ng-deep .trial-select.ng-select .ng-arrow-wrapper {
        margin-top: -12px;
      }
      ::ng-deep .trial-select.ng-select-opened .ng-arrow-wrapper,
      ::ng-deep .portfolio-trial-select.ng-select-opened .ng-arrow-wrapper {
        transform: rotate(-180deg);
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrialDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TrialDropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialDropdownComponent extends BaseFormControlComponent<string | null> {
  @Input() trials: (
    | (Omit<RequireSome<Partial<Trial>, 'id'>, 'sponsor_organization'> & {
        sponsor_organization: { id: string; name: string };
      })
    | {
        id: string | undefined;
        short_name: string | undefined;
        onboarding_complete: boolean | undefined;
        implementation_status?: string;
      }
  )[] = [];

  @Input() searchable = false;

  @Input() trialFormControl = new UntypedFormControl('');

  @Input() mainPage = true;

  tooltipPosition = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: 8,
  } as ConnectedPosition;

  tooltipColor = '';

  tooltipValue = '';

  trialStatusMap = TrialStatusMap;

  typecastingForTrialStatus(item_status: unknown): TrialImplementationStatus {
    const implementation_status = item_status as TrialImplementationStatus;
    return implementation_status;
  }

  mouseEnter(implementation_status: TrialImplementationStatus) {
    this.tooltipColor = this.trialStatusMap[implementation_status].color;
    this.tooltipValue = this.trialStatusMap[implementation_status].tooltipValue;
  }
}
