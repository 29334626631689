export const checkboxGridData = [
  {
    data_id: '1',
    selected: false,
    field1: 'Tials Docs Preparation',
    field2: 'Tials Docs Preparation',
    field3: 'Tials Docs Preparation',
  },
  {
    data_id: '2',
    selected: true,
    field1: 'Tials Docs Preparation',
    field2: 'Tials Docs Preparation',
    field3: 'Tials Docs Preparation',
  },
  {
    data_id: '3',
    selected: false,
    field1: 'Tials Docs Preparation',
    field2: 'Tials Docs Preparation',
    field3: 'Tials Docs Preparation',
  },
  {
    data_id: '3',
    selected: false,
    field1: 'Tials Docs Preparation',
    field2: 'Tials Docs Preparation',
    field3: 'Tials Docs Preparation',
  },
];

export const gridData = [
  {
    data_id: '1',
    field1: '2',
    field2: 'D',
    field3: 'Test Data 1',
  },
  {
    data_id: '2',
    field1: '1',
    field2: 'C',
    field3: 'Test Data 2',
  },
  {
    data_id: '3',
    field1: '4',
    field2: 'A',
    field3: 'Test Data 3',
  },
  {
    data_id: '4',
    field1: '3',
    field2: 'B',
    field3: 'Test Data 4',
  },
];

export const compactGridData = [
  {
    type: 'Services',
    service: 'Trial Docs Preparation',
    name: 'Trial Docs Preparation',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: 32232.09,
    var_perc: 32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
  {
    type: 'Services',
    service: 'Regulatory',
    name: 'Regulatory',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: -32232.09,
    var_perc: -32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
  {
    type: 'Services',
    service: 'Site / Investigator Identify',
    name: 'Site / Investigator Identify',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: 32232.09,
    var_perc: 32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
  {
    type: 'Services',
    service: 'Identify Sites',
    name: 'Identify Sites - NA Sites',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: -32232.09,
    var_perc: -32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
  {
    type: 'Services',
    service: 'Dev. Feasibility Questionnaire',
    name: 'Dev. Feasibility Questionnaire',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: 32232.09,
    var_perc: 32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
  {
    type: 'Services',
    service: 'Site Monitoring & Management',
    name: 'Site Monitors',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: 32232.09,
    var_perc: 32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
  {
    type: 'Services',
    service: 'Site Monitoring & Management',
    name: 'Devices',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: 32232.09,
    var_perc: 32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
  {
    type: 'Services',
    service: 'Track Questionnaires w/sites',
    name: 'Track Questionnaires w/sites',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: -32232.09,
    var_perc: -32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
  {
    type: 'Services',
    service: 'Dev Study Reference Binder',
    name: 'Dev Study Materials',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: 32232.09,
    var_perc: 32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
  {
    type: 'Services',
    service: 'Dev Study Reference Binder',
    name: 'Print & Ship Study Materials',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: 32232.09,
    var_perc: 32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
  {
    type: '3rd Party: Site/Investigator Payments',
    service: 'Regulatory',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: -32232.09,
    var_perc: -32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
  {
    type: 'Pass Through Payments',
    service: 'Regulatory',
    primary: 32232.09,
    original: 32232.09,
    var_dollar: -32232.09,
    var_perc: -32.59,
    '2020_q1_actuals': 1232232.09,
    '2020_q2_actuals': 1232232.09,
    '2020_q3_actuals': 1232232.09,
    '2020_q4_actuals': 1232232.09,
    '2021_q1_actuals': 1232232.09,
    '2021_q2_actuals': 1232232.09,
    '2021_q3_actuals': 1232232.09,
  },
];
