import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputComponent } from '../input/input.component';

interface MaskProperties {
  prefix: string;
  suffix: string;
  mask: string;
  thousandSeparator: string;
  allowNegativeNumbers: boolean;
  dropSpecialCharacters: boolean;
  separatorLimit?: string;
}

type MaskType = 'money' | 'percentage' | 'limitedPercentage';

@Component({
  selector: 'aux-input-mask',
  templateUrl: './input-mask.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => InputMaskComponent),
      multi: true,
    },
  ],
})
export class InputMaskComponent extends InputComponent implements OnInit {
  @Input() maskType!: MaskType;

  @Input() mask? = '';

  mapMaskByType = new Map<MaskType, Partial<MaskProperties>>([
    [
      'money',
      {
        prefix: '$',
        mask: 'separator',
        thousandSeparator: ',',
        allowNegativeNumbers: false,
      },
    ],
    [
      'percentage',
      {
        suffix: '%',
        mask: 'separator.2',
        thousandSeparator: '',
        separatorLimit: '99999',
        allowNegativeNumbers: false,
        dropSpecialCharacters: false,
      },
    ],
    [
      'limitedPercentage',
      {
        suffix: '%',
        mask: 'percent',
        allowNegativeNumbers: false,
        dropSpecialCharacters: false,
      },
    ],
  ]);

  maskProperties: Partial<MaskProperties> = {};

  ngOnInit() {
    this.maskProperties = this.mapMaskByType.get(this.maskType) || { mask: this.mask };
  }

  onChangeValue(value: string | null) {
    let formattedValue: string | number | null = value;

    if (this.maskType === 'money') {
      formattedValue = this.formatMoneyToNumber(value);
    }

    if (this.maskType === 'percentage') {
      formattedValue = this.formatPercentToNumber(value);
    }

    this.onChange(formattedValue);
  }

  private formatMoneyToNumber(value?: string | null): number {
    if (!value) {
      return 0;
    }

    return parseFloat(`${value}`.replace(/[$,]/g, ''));
  }

  private formatPercentToNumber(value?: string | null): number {
    if (!value) {
      return 0;
    }

    return parseFloat(`${value}`);
  }
}
