import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SitesService } from '@models/sites/sites.service';
import { BehaviorSubject } from 'rxjs';
import { FileMetadata } from '@services/api.service';
import { OverlayService } from '@services/overlay.service';
import { Utils } from '@services/utils';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Currency, DocumentType, EntityType } from '@services/gql.service';
import { DocumentLibraryService } from 'src/app/pages/documents/document-library.service';
import * as dayjs from 'dayjs';
import { FormControlConstants } from 'src/app/constants/form-control.constants';

@Component({
  selector: 'aux-add-custom-site-dialog',
  templateUrl: './add-custom-site-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCustomSiteDialogComponent implements OnInit, AfterViewInit {
  currencyOptions = Object.entries(Utils.CURRENCY_OPTIONS).map(([key, label]) => ({
    label,
    key,
  }));

  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  fg = this.formBuilder.group({
    name: ['', Validators.required],
    site_no: ['', Validators.required],
    city: '',
    state: '',
    zip: '',
    country: ['', Validators.required],
    given_name: '',
    family_name: '',
    managed_by_id: ['', Validators.required],
    target_patients: 0,
    site_activation: null,
    closeout_date: null,
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    currency: ['', Validators.required],
  });

  loading$ = new BehaviorSubject(false);

  countries: { label: string; value: string }[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public ref: CustomOverlayRef,
    public organizationQuery: OrganizationQuery,
    private sitesService: SitesService,
    private mainQuery: MainQuery,
    private documentLibraryService: DocumentLibraryService,
    private overlayService: OverlayService
  ) {
    this.countries = Utils.getCountriesForSelectOptions();
  }

  ngOnInit(): void {
    if (this.fileManager) {
      console.log('this.fileManager', this.fileManager);
    }
  }

  getMetadata(): FileMetadata {
    return { onboarding: 'true' };
  }

  async onAddTrialSite() {
    if (this.fg.valid && !this.loading$.getValue()) {
      const {
        name,
        site_no,
        city,
        state,
        zip,
        country,
        given_name,
        family_name,
        managed_by_id,
        target_patients,
        site_activation,
        closeout_date,
        currency,
        address_line_1,
        address_line_2,
        address_line_3,
      } = this.fg.value;
      if (
        closeout_date &&
        site_activation &&
        dayjs(closeout_date).isBefore(dayjs(site_activation))
      ) {
        this.overlayService.error(FormControlConstants.VALIDATION_MESSAGE.SITE_CLOSEOUT);
        return;
      }
      this.loading$.next(true);
      const id = await this.sitesService.add({
        name,
        site_no,
        city,
        state,
        zip,
        managed_by_id,
        target_patients,
        site_activation,
        closeout_date,
        currency: Utils.CURRENCY_OPTIONS[+currency] as Currency,
        investigator: {
          given_name,
          family_name,
        },
        country,
        address_line_1,
        address_line_2,
        address_line_3,
      });
      if (id) {
        const trialId = this.mainQuery.getValue().trialKey;
        const path = `trials/${trialId}/sites/${id}/contracts/`;

        if (this.fileManager) {
          const files = this.fileManager.fileQuery.getAll();

          for (const file of files) {
            const bucket_key = `${path}${file.bucket_key}`;

            this.fileManager.fileStore.update(file.id, { ...file, bucket_key });
            this.documentLibraryService.uploadDocument(
              { ...file, bucket_key },
              {
                documentType: DocumentType.DOCUMENT_SITE_AGREEMENTS,
                site: id,
                entity_id: id,
                entity_type_id: EntityType.SITE,
              },
              bucket_key
            );
          }

          const success = await this.fileManager.fileService.uploadFiles(this.getMetadata());

          if (success) {
            this.overlayService.success('Trial site successfully saved!');
          }
        }
      }
      this.ref.close();
      this.loading$.next(false);
    }
  }

  ngAfterViewInit(): void {
    if (this.fileManager) {
      this.fileManager.fileQuery.selectAll().subscribe((value) => {
        console.log('value', value);
      });
    }
  }
}
