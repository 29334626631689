import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DividerOrientation } from './divider/divider.component';

@Component({
  selector: 'aux-typography',
  templateUrl: './typography.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypographyComponent {
  DividerOrientation = DividerOrientation;

  typeScaleList = [
    {
      title: '.text-2xl',
      description: '1.5 rem (2 rem line-height) - 700 weight',
      classNames: 'text-2xl font-bold',
    },
    {
      title: '.text-xl',
      description: '1.25 rem (1.75 rem line-height)',
      classNames: 'text-xl',
    },
    {
      title: '.text-lg',
      description: '1.125rem (1.75 line-height)',
      classNames: 'text-lg',
    },
    {
      title: '.text-base - Body (root size)',
      description: '1 rem (1.5 rem line-height) - 14px',
      classNames: 'text-base',
    },
    {
      title: '.text-sm',
      description: '.875 rem (1.25 rem line-height)',
      classNames: 'text-sm',
    },
    {
      title: '.text-xs',
      description: '.75 rem (1 rem line-height)',
      classNames: 'text-xs',
    },
    {
      title: '.text-xxs',
      description: '.688rem (.875 rem line height)',
      classNames: 'text-xxs',
    },
  ];

  typographyRules = [
    {
      title: 'Font',
      description:
        'Inter is Auxilius system font. We do not use any other fonts in the application.',
    },
    {
      title: 'Body Copy',
      description:
        'Unless otherwise specified, text should use the default size (1 rem) using the color @aux-black. We never use #000000 (pure black) for text',
    },
    {
      title: 'Links',
      description: '',
    },
    {
      title: 'Page Headers',
      description: 'Page headers use .text-2xl in @aux-black',
      classNames: 'text-2xl',
    },
  ];
}
