import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { EntityType, Organization } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface VendorsState
  extends EntityState<
      Omit<
        RequireSome<Partial<Organization>, 'id'>,
        'parent_organization' | 'contacts' | 'current_budget_versions' | 'baseline_budget_version'
      > & {
        parent_organization?: {
          id: string;
          name: string;
        } | null;
        baseline_budget_version?:
          | {
              budget_type?: string;
              budget_name?: string;
              budget_version_id?: string;
              bucket_key?: string | null;
              manual_forecast?: boolean;
              entity_id?: string;
              entity_type?: EntityType;
            }
          | null
          | undefined;
        budget_version: Record<
          string,
          {
            budget_version_id: string;
            manual_forecast: boolean;
            total_budget_amount: number;
            bucket_key: string;
            budget_name: string;
          }
        >;
      }
    >,
    ActiveState<string> {}

export type OrganizationModel = getEntityType<VendorsState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organization' })
export class OrganizationStore extends EntityStore<VendorsState> {
  constructor() {
    super({});
  }
}
