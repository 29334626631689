<div
  class="bg-aux-blue-arctic border border-aux-gray-dark py-4 px-3 flex items-center justify-between"
>
  <p>
    Current Investigator Forecast Source:
    <span class="font-bold">
      {{ source }}:
      {{ amount | money }}
    </span>
  </p>
</div>
