import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OverlayService } from '@services/overlay.service';
import { flatten, isNaN, set, isString, uniq } from 'lodash-es';
import * as dayjs from 'dayjs';
import {
  AmountType,
  Currency,
  DataSource,
  EntityType,
  EventType,
  GqlService,
  InvoiceStatus,
  listUserNamesWithEmailQuery,
  MonthlyExchangeRate,
  NoteType,
  DocumentType,
  PaymentStatus,
  updateExpenseAmountMutation,
  User,
  WorkflowDetail,
  UpdateWorkflowInput,
  ExpenseAmountInput,
  UpdateInvoiceInput,
} from '@services/gql.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { Utils } from '@services/utils';
import { CellClickedEvent, GridApi, ValueSetterParams, IRowNode } from '@ag-grid-community/core';
import { ApiService } from '@services/api.service';
import { BehaviorSubject, Subject, firstValueFrom, merge } from 'rxjs';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { Router } from '@angular/router';
import { AuthQuery } from '@models/auth/auth.query';
import { FormControl, FormGroup } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Option } from '@components/components.type';
import { OrganizationService } from '@models/organization/organization.service';
import { InvoiceCard, InvoiceModel } from './invoice.model';
import { InvoiceStore } from './invoice.store';
import { ROUTING_PATH } from '../../../../../app-routing-path.const';
import { MessagesConstants } from '@constants/messages.constants';
import { PurchaseOrdersService } from '../../purchase-orders/state/purchase-orders.service';
import { PurchaseOrdersQuery } from '../../purchase-orders/state/purchase-orders.query';
import { decimalAddAll, decimalEquality, batchAPIRequest } from '@shared/utils';
import { BudgetCurrencyQuery } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget-currency.query';
import { v4 as uuidv4 } from 'uuid';
import { BudgetCurrencyType } from '../../../../budget-page/tabs/budget-enhanced/budget-type';

interface InvoiceFormGroup {
  search: FormControl<string | null>;
  vendors: FormControl<string[] | null>;
  accrualPeriod: FormControl<string[] | null>;
  invoiceDate: FormControl<string | null>;
}

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class InvoiceService {
  trialMonthClose = '';

  trialStartDate = '';

  trialEndDate = '';

  gridAPI!: GridApi;

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  loadingFetchBillCom$ = new BehaviorSubject(false);

  loadingFetchCoupa$ = new BehaviorSubject(false);

  loadingFetchDynamics365$ = new BehaviorSubject(false);

  loadingFetchDynamics365Fo$ = new BehaviorSubject(false);

  loadingFetchNetsuite$ = new BehaviorSubject(false);

  loadingFetchOracleFusion$ = new BehaviorSubject(false);

  loadingFetchQuickbooksOnline$ = new BehaviorSubject(false);

  loadingFetchSageIntacct$ = new BehaviorSubject(false);

  showRequireCostBreakdown$ = new BehaviorSubject(false);

  showRequireAccrualPeriod$ = new BehaviorSubject(false);

  redirectedWithAnyRenderedNodes$ = new Subject<boolean>();

  invoiceDesignationChanged$ = new BehaviorSubject(false);

  filtersForm = new FormGroup<InvoiceFormGroup>({
    search: new FormControl(''),
    vendors: new FormControl([] as string[]),
    accrualPeriod: new FormControl([] as string[]),
    invoiceDate: new FormControl(''),
  });

  vendorOptions: Option[] = [];

  accrualPeriodOptions: Option[] = [];

  newInvoiceCreated$ = new BehaviorSubject(false);

  invoiceStatusOptions = [
    InvoiceStatus.STATUS_PENDING_REVIEW,
    InvoiceStatus.STATUS_PENDING_APPROVAL,
    InvoiceStatus.STATUS_APPROVED,
    InvoiceStatus.STATUS_DECLINED,
  ];

  paymentStatusOptions = [
    null,
    PaymentStatus.PAYMENT_STATUS_PAID_IN_FULL,
    PaymentStatus.PAYMENT_STATUS_UNPAID,
  ];

  constructor(
    private apiService: ApiService,
    private invoiceStore: InvoiceStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private trialUserService: TrialUserService,
    private router: Router,
    public authQuery: AuthQuery,
    public organizationQuery: OrganizationQuery,
    public organizationService: OrganizationService,
    public purchaseOrdersService: PurchaseOrdersService,
    public purchaseOrdersQuery: PurchaseOrdersQuery,
    private budgetCurrencyQuery: BudgetCurrencyQuery
  ) {
    this.filtersForm.setValue({ search: '', vendors: [], accrualPeriod: [], invoiceDate: '' });
  }

  initialize() {
    return merge(
      this.organizationService.listIdOrganizations$().pipe(
        tap(() => {
          this.vendorOptions = this.organizationQuery
            .getAllVendors()
            .map(({ id, name }) => ({ value: id, label: name || '' }));
        })
      ),
      this.purchaseOrdersService.get(),
      this.gqlService.getTrialInformation$().pipe(
        tap(({ data: trials }) => {
          if (trials?.length) {
            this.trialMonthClose = dayjs(trials[0].trial_month_close).format('YYYY-MM');
            this.trialStartDate = dayjs(trials[0].trial_start_date).format('YYYY-MM');
            this.trialEndDate = dayjs(trials[0].trial_end_date).format('YYYY-MM');
            this.accrualPeriodOptions = this.getAccrualPeriodOptions(
              trials[0].trial_start_date,
              trials[0].trial_end_date
            );
          }
        })
      ),
      this.mainQuery.select('userList').pipe(
        tap((_users) => {
          _users.forEach((user: listUserNamesWithEmailQuery) => {
            this.users.set(user.sub, user);
          });
        })
      )
    );
  }

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.invoiceStore.setLoading(true);

        return this.gqlService.listInvoices$().pipe(
          map(({ data, success, errors }) => {
            let invoices = [] as InvoiceModel[];
            if (data) {
              invoices = data.map((invoice) => {
                return {
                  ...invoice,
                  ...{
                    cards: [],
                    po_reference: invoice.purchase_order_id,
                    expense_amounts: this.mapExpenseAmountToObject(invoice.expense_amounts),
                    line_items: JSON.parse(invoice?.line_items || '[]'),
                    ocr_line_items: JSON.parse(invoice?.ocr_line_items || '[]'),
                  },
                } as unknown as InvoiceModel;
              });
              this.invoiceStore.set(invoices);
            }

            this.invoiceStore.setLoading(false);
            return { success, errors, data: invoices };
          })
        );
      })
    );
  }

  getOne(id: string) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.invoiceStore.setLoading(true);

        return this.gqlService.getInvoice$(id);
      }),
      tap(({ success, data, errors }) => {
        if (success && data) {
          const { workflow_details } = data;

          const invReq = {
            ...data,
            ...{
              cards: this.workflowToCards(workflow_details as WorkflowDetail[]),
              po_reference: data.purchase_order_id,
              expense_amounts: this.mapExpenseAmountToObject(data.expense_amounts),
              line_items: JSON.parse(data?.line_items || '[]'),
              ocr_line_items: JSON.parse(data?.ocr_line_items || '[]'),
            },
          } as unknown as InvoiceModel;
          if (data.reasons && data.reasons.length > 0) {
            invReq.decline_reason = Utils.unscrubUserInput(
              data.reasons.find((reason) => reason.note_type === NoteType.NOTE_TYPE_DECLINE_REASON)
                ?.message || ''
            );
            invReq.delete_reason = Utils.unscrubUserInput(
              data.reasons.find((reason) => reason.note_type === NoteType.NOTE_TYPE_DELETE_REASON)
                ?.message || ''
            );
          }
          this.invoiceStore.upsert(invReq.id, invReq);
        } else {
          this.overlayService.error(errors);
        }
        this.invoiceStore.setLoading(false);
      })
    );
  }

  setGridApi(api: GridApi) {
    this.gridAPI = api;
  }

  workflowToCards(invoice_workflow: Array<WorkflowDetail>): InvoiceCard[] {
    return invoice_workflow.map((workflow) => {
      const obj = JSON.parse(workflow.properties || '') as { properties: InvoiceCard };

      return { ...obj.properties, id: workflow.id || '' };
    });
  }

  setAccrualPeriodsAndVendorFilter(accrualPeriods?: string[], vendor_id?: string) {
    this.filtersForm.setValue({
      search: '',
      vendors: vendor_id ? [vendor_id] : [],
      accrualPeriod: accrualPeriods ? accrualPeriods : [],
      invoiceDate: '',
    });
  }

  mapExpenseAmountToObject(
    expense_amounts: {
      amount?: number | null | undefined;
      contract_curr?: string | null | undefined;
      contract_amount?: number | null | undefined;
      exchange_rate?: MonthlyExchangeRate | null | undefined;
      amount_type: string;
    }[]
  ) {
    const getAmount = (amountType: AmountType | string, is_vendor_currency_amount: boolean) => {
      const filteredAmounts = expense_amounts.filter((x) => x.amount_type === amountType)[0];
      const value = is_vendor_currency_amount
        ? filteredAmounts?.contract_amount
        : filteredAmounts?.amount;
      if (is_vendor_currency_amount) {
        return {
          value: value || 0,
          type: amountType,
          is_vendor_currency_amount,
          contract_curr: filteredAmounts?.contract_curr
            ? (filteredAmounts.contract_curr?.replace('CURRENCY_', '') as Currency)
            : Currency.USD,
        };
      } else {
        return {
          value: value || 0,
          type: amountType,
          is_vendor_currency_amount,
          exchange_rate: filteredAmounts?.exchange_rate?.rate || 1,
        };
      }
    };

    return {
      invoice_total: getAmount('AMOUNT_TOTAL', true),
      pass_thru_total: getAmount(AmountType.AMOUNT_PASSTHROUGH, true),
      services_total: getAmount(AmountType.AMOUNT_SERVICE, true),
      discount_total: getAmount(AmountType.AMOUNT_DISCOUNT, true),
      investigator_total: getAmount(AmountType.AMOUNT_INVESTIGATOR, true),
      invoice_total_trial_currency: getAmount('AMOUNT_TOTAL', false),
      pass_thru_total_trial_currency: getAmount(AmountType.AMOUNT_PASSTHROUGH, false),
      services_total_trial_currency: getAmount(AmountType.AMOUNT_SERVICE, false),
      discount_total_trial_currency: getAmount(AmountType.AMOUNT_DISCOUNT, false),
      investigator_total_trial_currency: getAmount(AmountType.AMOUNT_INVESTIGATOR, false),
    };
  }

  async add({
    id,
    organization_id,
    po_reference,
    bucket_keys,
  }: {
    id: string;
    organization_id: string;
    po_reference: string | null;
    bucket_keys: string[];
  }) {
    const { errors, success, data } = await firstValueFrom(
      this.gqlService.createInvoice$({ id, organization_id, po_reference, bucket_keys })
    );
    let invoice: InvoiceModel | null = null;
    if (success && data) {
      const { workflow_details } = data;

      invoice = {
        ...data,
        ...{
          cards: this.workflowToCards(workflow_details as WorkflowDetail[]),
          po_reference: data.purchase_order_id,
          expense_amounts: this.mapExpenseAmountToObject(data.expense_amounts),
        },
      } as unknown as InvoiceModel;

      this.invoiceStore.add(invoice);
      this.newInvoiceCreated$.next(true);
    }

    return { errors, success, data: invoice };
  }

  async batchUpdate(invoices: InvoiceModel[], showSuccessMessages = true, setLoading = true) {
    const expenseAmountMaxBatchSize = 50;
    const invoiceMaxBatchSize = 50;
    const workflowMaxBatchSize = 200;

    if (setLoading) {
      this.invoiceStore.setLoading(true);
    }

    const workflowInputs: UpdateWorkflowInput[] = [];
    invoices.forEach((invoice) => {
      invoice.cards.forEach((card) => {
        const { id, lines, status, header, note } = card;
        workflowInputs.push({
          id,
          properties: JSON.stringify({ properties: { lines, status, header, note } }),
        });
      });
    });

    const workflowResponses = await batchAPIRequest(
      workflowInputs,
      this.gqlService.batchUpdateWorkflowDetails$,
      workflowMaxBatchSize
    );

    // check if everything is ok
    if (workflowResponses.success) {
      const expenseAmountInputs: ExpenseAmountInput[] = [];
      const invoiceInputs: UpdateInvoiceInput[] = [];

      invoices.forEach((invoice) => {
        for (const { value, type, is_vendor_currency_amount } of Object.values(
          invoice.expense_amounts
        )) {
          if (is_vendor_currency_amount) {
            expenseAmountInputs.push({
              entity_id: invoice.id,
              amount_type: type,
              amount_value: value,
              amount_curr: `CURRENCY_${invoice.organization.currency}`,
              entity_type_id: EntityType.INVOICE,
            });
          }
        }
        invoiceInputs.push({
          accrual_period: invoice.accrual_period,
          id: invoice.id,
          invoice_no: invoice.invoice_no,
          invoice_date: invoice.invoice_date,
          po_reference: invoice.po_reference,
          invoice_status: invoice.invoice_status,
          due_date: invoice.due_date,
          organization_id: invoice.organization.id,
          payment_status: invoice.payment_status,
          payment_date: invoice.payment_date,
          decline_reason: invoice.decline_reason,
          admin_review_reason: invoice.admin_review_reason,
          is_deposit: invoice.is_deposit,
        });
      });

      const expenseAmountResponses = await batchAPIRequest(
        expenseAmountInputs,
        this.gqlService.batchUpdateExpenseAmounts$,
        expenseAmountMaxBatchSize
      );

      const invoiceResponses = await batchAPIRequest(
        invoiceInputs,
        this.gqlService.batchUpdateInvoices$,
        invoiceMaxBatchSize
      );

      if (
        expenseAmountResponses.success &&
        expenseAmountResponses.data &&
        expenseAmountResponses.data.length > 0
      ) {
        if (showSuccessMessages) {
          this.overlayService.success(MessagesConstants.INVOICE.SUCCESSFULLY_UPDATED);
        }

        invoices.forEach((invoice) => {
          const invoiceData = invoiceResponses.data?.find((datum) => datum.id === invoice.id);
          this.invoiceStore.update(
            invoice.id,
            () =>
              ({
                ...invoiceData,
                decline_reason: invoice.decline_reason,
                cards: invoice.cards,
                po_reference: invoiceData?.purchase_order_id || '',
                expense_amounts: invoice.expense_amounts,
              }) as unknown as InvoiceModel
          );
        });

        if (setLoading) {
          this.invoiceStore.setLoading(false);
        }

        return true;
      }

      this.overlayService.error([...expenseAmountResponses.errors, ...invoiceResponses.errors]);
    }
    this.overlayService.error(workflowResponses.errors);

    if (setLoading) {
      this.invoiceStore.setLoading(false);
    }

    return false;
  }

  async update(invoice: InvoiceModel, showSuccessMessages = true, setLoading = true) {
    const cardPromises = [];

    if (setLoading) {
      this.invoiceStore.setLoading(true);
    }

    for (const { id: cardId, lines, status, header, note } of invoice.cards) {
      // update each card
      cardPromises.push(
        firstValueFrom(
          this.gqlService.updateWorkflowDetail$({
            id: cardId,
            properties: JSON.stringify({ properties: { lines, status, header, note } }),
          })
        )
      );
    }

    // send all the request in parallel
    const responses = await Promise.all(cardPromises);
    // check if everything is ok
    if (responses.every((x) => x.success)) {
      const expenseAmountsPromises: Promise<GraphqlResponse<updateExpenseAmountMutation>>[] = [];
      for (const { value, type, is_vendor_currency_amount } of Object.values(
        invoice.expense_amounts
      )) {
        if (is_vendor_currency_amount) {
          const prom = firstValueFrom(
            this.gqlService.updateExpenseAmount$({
              entity_id: invoice.id,
              amount_type: type,
              amount_value: value,
              amount_curr: `CURRENCY_${invoice.organization.currency}`,
              entity_type_id: EntityType.INVOICE,
            })
          );
          expenseAmountsPromises.push(prom);
        }
      }
      const expenseAmountsResponses = await Promise.all(expenseAmountsPromises);

      const { success, data, errors } = await firstValueFrom(
        this.gqlService.updateInvoice$({
          accrual_period: invoice.accrual_period,
          id: invoice.id,
          invoice_no: invoice.invoice_no,
          invoice_date: invoice.invoice_date,
          po_reference: invoice.po_reference,
          invoice_status: invoice.invoice_status,
          due_date: invoice.due_date,
          organization_id: invoice.organization.id,
          payment_status: invoice.payment_status,
          payment_date: invoice.payment_date,
        })
      );
      if (success && data) {
        if (invoice.decline_reason && invoice.decline_reason.length > 0) {
          const {
            errors: cErrors,
            success: cSuccess,
            data: cData,
          } = await firstValueFrom(
            this.gqlService.createNote$({
              entity_id: invoice.id,
              entity_type: EntityType.INVOICE,
              note_type: NoteType.NOTE_TYPE_DECLINE_REASON,
              message: Utils.scrubUserInput(invoice.decline_reason),
            })
          );
          if (cSuccess && cData && showSuccessMessages) {
            this.overlayService.success();
          }

          if (!cSuccess) {
            this.overlayService.error(cErrors);
          }
        }

        if (invoice.admin_review_reason && invoice.admin_review_reason.length > 0) {
          const {
            errors: cErrors,
            success: cSuccess,
            data: cData,
          } = await firstValueFrom(
            this.gqlService.createNote$({
              entity_id: invoice.id,
              entity_type: EntityType.INVOICE,
              note_type: NoteType.NOTE_TYPE_ADMIN_REVIEW_REASON,
              message: Utils.scrubUserInput(invoice.admin_review_reason),
            })
          );
          if (cSuccess && cData && showSuccessMessages) {
            this.overlayService.success();
          }

          if (!cSuccess) {
            this.overlayService.error(cErrors);
          }
        }
      }

      if (expenseAmountsResponses.every(({ success: eaSuccess }) => eaSuccess) && success && data) {
        if (showSuccessMessages) {
          this.overlayService.success(MessagesConstants.INVOICE.SUCCESSFULLY_UPDATED);
        }

        this.invoiceStore.update(
          invoice.id,
          () =>
            ({
              ...data,
              decline_reason: invoice.decline_reason,
              cards: invoice.cards,
              po_reference: data.purchase_order_id,
              expense_amounts: invoice.expense_amounts,
            }) as unknown as InvoiceModel
        );

        if (setLoading) {
          this.invoiceStore.setLoading(false);
        }

        return true;
      }

      const messages = flatten(
        expenseAmountsResponses.filter((x) => !x.success).map((x) => x.errors)
      );
      this.overlayService.error([...messages, ...errors]);
    }
    const messages = flatten(responses.filter((x) => !x.success).map((x) => x.errors));
    this.overlayService.error(messages);

    if (setLoading) {
      this.invoiceStore.setLoading(false);
    }

    return false;
  }

  async remove(invoice: InvoiceModel, delete_reason = '') {
    const { success, errors } = await firstValueFrom(
      this.gqlService.removeInvoice$({ id: invoice.id })
    );
    if (success) {
      if (delete_reason.length > 0) {
        const { errors: cErrors, success: cSuccess } = await firstValueFrom(
          this.gqlService.createNote$({
            entity_id: invoice.id,
            entity_type: EntityType.INVOICE,
            note_type: NoteType.NOTE_TYPE_DELETE_REASON,
            message: Utils.scrubUserInput(delete_reason),
          })
        );
        if (!cSuccess) {
          this.overlayService.error(cErrors);
        }
      }
      this.invoiceStore.remove(invoice.id);
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors };
  }

  async downloadInvoiceItems(rowNode: IRowNode) {
    let { bucket_key } = rowNode.data?.file || {};
    if (!bucket_key) {
      const trialId = this.mainQuery.getValue().trialKey;
      await this.apiService
        .getFilesByFilters(
          `trials/${trialId}/vendors/`,
          undefined,
          EntityType.INVOICE,
          DocumentType.DOCUMENT_INVOICE,
          rowNode.data?.id
        )
        .then((documents) => {
          bucket_key = documents[0].bucket_key;
        });
      if (!bucket_key) {
        this.overlayService.error(MessagesConstants.INVOICE.DOES_NOT_HAVE_FILE);
        return;
      }
    }
    const pathParts = bucket_key.split('/');
    const formattedKey = `${pathParts.slice(0, pathParts.indexOf('invoices') + 2).join('/')}/`;
    const ref = this.overlayService.loading();
    const { success, data } = await this.apiService.getS3ZipFile(formattedKey);
    if (success && data) {
      const fileName =
        `${rowNode.data.invoice_no}_${rowNode.data.organization.name}_Invoice_` +
        `${rowNode.data.id}_${(rowNode.data.create_date || '').slice(0, 10)}`;
      await this.apiService.downloadZipOrFile(data, fileName);
    }
    ref.close();
  }

  async createInvoiceNote(entityId: string, message: string) {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.createNote$({
        entity_id: entityId,
        entity_type: EntityType.INVOICE,
        note_type: NoteType.NOTE_TYPE_GENERAL,
        message: message || '',
      })
    );

    if (success && data) {
      this.overlayService.success('Notes saved');
      return data;
    } else {
      this.overlayService.error(errors);
      return;
    }
  }

  async updateInvoiceNote(id: string, message: string) {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.updateNote$({
        id: id,
        message: message || '',
      })
    );

    if (success && data) {
      this.overlayService.success('Notes saved');
      return data;
    } else {
      this.overlayService.error(errors);
      return;
    }
  }

  getFilePath(invoice_id: string, vendorId: string) {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/vendors/${vendorId}/invoices/${invoice_id}/invoice-lines/${uuidv4()}/`;
  }

  downloadInvoiceLines(rowNode: IRowNode) {
    if (rowNode.data.line_items.length > 0) {
      const blob = new Blob([
        this.invoiceLinesToCsv(rowNode.data.line_items, rowNode.data.data_source_id),
      ]);
      const fileName =
        `${rowNode.data.invoice_no}_${rowNode.data.organization.name}_Auxilius_Invoice_Line_Items` +
        `_${dayjs(new Date()).format('YYYY.MM.DD-HHmmss')}.csv`;

      this.apiService.downloadBlob(blob, fileName);
    }
    if (rowNode.data.ocr_line_items.length > 0) {
      const blob = new Blob([
        this.invoiceLinesToCsv(rowNode.data.ocr_line_items, DataSource.DATA_SOURCE_AUXILIUS),
      ]);
      const fileName =
        `${rowNode.data.invoice_no}_${rowNode.data.organization.name}_Auxilius_Invoice_OCR_Line_Items` +
        `_${dayjs(new Date()).format('YYYY.MM.DD-HHmmss')}.csv`;

      this.apiService.downloadBlob(blob, fileName);
    }
  }

  invoiceLinesToCsv(data: Record<string, unknown>[], dataSource: DataSource) {
    let amountKey = 'amount';
    let descriptionKey = 'description';
    if (dataSource === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE) {
      amountKey = 'Amount';
      descriptionKey = 'Description';
    } else if (dataSource === DataSource.DATA_SOURCE_DYNAMICS365) {
      amountKey = 'netAmountIncludingTax';
    }
    const csvRows = [];
    if (dataSource === DataSource.DATA_SOURCE_AUXILIUS) {
      csvRows.push(
        'Auxilius AI (Beta) extraction: Data outside of scope for Auxilius QAQC processes; manual review recommended.',
        ''
      );
    }
    const headers = ['Description', 'Amount'];
    csvRows.push(headers.join(','));
    let total = 0;
    for (const row of data) {
      const values = headers.map((header) => {
        let value = String(row[header === 'Description' ? descriptionKey : amountKey]);
        if (header === 'Amount') {
          total += +value;
        }
        if (value.includes(',')) {
          value = `"${value}"`;
        }
        return value;
      });
      csvRows.push(values.join(','));
    }
    csvRows.push(`Total, ${total}`);

    return csvRows.join('\n');
  }

  calculateTotals = () => {
    const rowsToCalculateAgainst = this.gridAPI?.getRenderedNodes();

    const uniqVendorCurrencies = uniq(
      rowsToCalculateAgainst?.map((x) => x.data.organization.currency)
    );

    const isVendorSelected =
      this.budgetCurrencyQuery.getValue().currency === BudgetCurrencyType.VENDOR;

    let currency = Currency.USD;

    // if there's only one currency, we can use it for formatting
    if (uniqVendorCurrencies.length === 1) {
      currency = uniqVendorCurrencies[0];
    }

    // if there are multiple currencies and we're toggled on vendor currency, we don't need to calculate a total row since we can't directly sum different currencies
    if (uniqVendorCurrencies.length > 1 && isVendorSelected) {
      return {
        expense_amounts: {
          invoice_total_trial_currency: { value: 0 },
          invoice_total: { value: 0 },
        },
      };
    } else {
      return rowsToCalculateAgainst?.reduce(
        (accumulator, row) => ({
          expense_amounts: {
            invoice_total_trial_currency: {
              value:
                accumulator.expense_amounts.invoice_total_trial_currency.value +
                row.data.expense_amounts.invoice_total_trial_currency.value,
            },
            investigator_total_trial_currency: {
              value:
                accumulator.expense_amounts.investigator_total_trial_currency.value +
                row.data.expense_amounts.investigator_total_trial_currency.value,
            },
            pass_thru_total_trial_currency: {
              value:
                accumulator.expense_amounts.pass_thru_total_trial_currency.value +
                row.data.expense_amounts.pass_thru_total_trial_currency.value,
            },
            services_total_trial_currency: {
              value:
                accumulator.expense_amounts.services_total_trial_currency.value +
                row.data.expense_amounts.services_total_trial_currency.value,
            },
            discount_total_trial_currency: {
              value:
                accumulator.expense_amounts.discount_total_trial_currency.value +
                row.data.expense_amounts.discount_total_trial_currency.value,
            },
            invoice_total: {
              value:
                accumulator.expense_amounts.invoice_total.value +
                (uniqVendorCurrencies.length > 1
                  ? 0
                  : row.data.expense_amounts.invoice_total.value),
              currency,
            },
            investigator_total: {
              value:
                accumulator.expense_amounts.investigator_total.value +
                (uniqVendorCurrencies.length > 1
                  ? 0
                  : row.data.expense_amounts.investigator_total.value),
              currency,
            },
            pass_thru_total: {
              value:
                accumulator.expense_amounts.pass_thru_total.value +
                (uniqVendorCurrencies.length > 1
                  ? 0
                  : row.data.expense_amounts.pass_thru_total.value),
              currency,
            },
            services_total: {
              value:
                accumulator.expense_amounts.services_total.value +
                (uniqVendorCurrencies.length > 1
                  ? 0
                  : row.data.expense_amounts.services_total.value),
              currency,
            },
            discount_total: {
              value:
                accumulator.expense_amounts.discount_total.value +
                (uniqVendorCurrencies.length > 1
                  ? 0
                  : row.data.expense_amounts.discount_total.value),
              currency,
            },
          },
        }),
        {
          expense_amounts: {
            invoice_total_trial_currency: { value: 0 },
            investigator_total_trial_currency: { value: 0 },
            pass_thru_total_trial_currency: { value: 0 },
            services_total_trial_currency: { value: 0 },
            discount_total_trial_currency: { value: 0 },
            invoice_total: { value: 0, currency: Currency.USD },
            investigator_total: { value: 0, currency: Currency.USD },
            pass_thru_total: { value: 0, currency: Currency.USD },
            services_total: { value: 0, currency: Currency.USD },
            discount_total: { value: 0, currency: Currency.USD },
          },
        }
      );
    }
  };

  generatePinnedRow() {
    const totals = this.calculateTotals();
    // if the invoice total is 0, then no need for a total row
    if (
      totals?.expense_amounts?.invoice_total.value === 0 &&
      totals?.expense_amounts?.invoice_total_trial_currency.value === 0
    ) {
      this.gridAPI?.setGridOption('pinnedBottomRowData', []);
    } else {
      this.gridAPI?.setGridOption('pinnedBottomRowData', [
        {
          ...totals,
          file: '',
          invoice_no: 'Total',
          organization: { name: Utils.zeroHyphen },
          created_by: '',
        },
      ]);
    }
  }

  isInvoiceTotalEqual(params: ValueSetterParams) {
    const invoice_total = this.getCostAsNumber(params.data.expense_amounts.invoice_total.value);

    const item_totals = decimalAddAll(
      15,
      this.getCostAsNumber(params.data.expense_amounts.services_total.value),
      this.getCostAsNumber(params.data.expense_amounts.discount_total.value),
      this.getCostAsNumber(params.data.expense_amounts.investigator_total.value),
      this.getCostAsNumber(params.data.expense_amounts.pass_thru_total.value)
    );

    return decimalEquality(invoice_total, item_totals, 2);
  }

  getCostAsNumber = (cost: string) => Number(Number(cost).toFixed(2));

  setCostValue(params: ValueSetterParams, field: string) {
    const newValue = Number(params.newValue);
    const isNewValueANumber = !isNaN(newValue);

    set(params.data, field, isNewValueANumber ? newValue : params.oldValue);

    if (isNewValueANumber) {
      set(params.data, 'hasError', !this.isInvoiceTotalEqual(params));
    } else {
      this.overlayService.error(MessagesConstants.ONLY_NUMERIC_VALUES_ARE_SUPPORTED);
    }

    return isNewValueANumber;
  }

  goToInvoiceDetail(event: CellClickedEvent) {
    const id = event.data?.id;
    if (id) {
      this.router.navigateByUrl(
        `${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}/${id}`
      );
    }
  }

  userFormatter = (sub?: string): string => {
    const user = this.users.get(sub || '');

    return Utils.agUserFormatter(user as listUserNamesWithEmailQuery, this.authQuery.isAuxAdmin());
  };

  async triggerFetchBillCom() {
    this.loadingFetchBillCom$.next(true);
    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.REFRESH_BILL_COM,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
    );
    this.overlayService.success(MessagesConstants.INVOICE.BILL_COM_RETRIEVAL);
    this.loadingFetchBillCom$.next(false);
  }

  async triggerFetchCoupa() {
    this.loadingFetchCoupa$.next(true);
    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.REFRESH_COUPA,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
    );

    this.overlayService.success(MessagesConstants.INVOICE.COUPA_RETRIEVAL);
    this.loadingFetchCoupa$.next(false);
  }

  async triggerFetchDynamics365() {
    this.loadingFetchDynamics365$.next(true);
    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.REFRESH_DYNAMICS365,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
    );
    this.overlayService.success(MessagesConstants.INVOICE.DYNAMICS365_RETRIEVAL);
    this.loadingFetchDynamics365$.next(false);
  }

  async triggerFetchDynamics365Fo() {
    this.loadingFetchDynamics365Fo$.next(true);
    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.REFRESH_DYNAMICS365_FO,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
    );
    this.overlayService.success(MessagesConstants.INVOICE.DYNAMICS365_FO_RETRIEVAL);
    this.loadingFetchDynamics365Fo$.next(false);
  }

  async triggerFetchNetsuite() {
    this.loadingFetchNetsuite$.next(true);
    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.REFRESH_NETSUITE,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
    );
    this.overlayService.success(MessagesConstants.INVOICE.NETSUITE_RETRIEVAL);
    this.loadingFetchNetsuite$.next(false);
  }

  async triggerFetchOracleFusion() {
    this.loadingFetchOracleFusion$.next(true);
    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.REFRESH_ORACLE_FUSION,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
    );
    this.overlayService.success(MessagesConstants.INVOICE.ORACLE_FUSION_RETRIEVAL);
    this.loadingFetchOracleFusion$.next(false);
  }

  async triggerFetchQuickbooksOnline() {
    this.loadingFetchQuickbooksOnline$.next(true);
    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.REFRESH_QUICKBOOKS_ONLINE,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
    );
    this.overlayService.success(MessagesConstants.INVOICE.QUICKBOOKS_RETRIEVAL);
    this.loadingFetchQuickbooksOnline$.next(false);
  }

  async triggerFetchSageIntacct() {
    this.loadingFetchSageIntacct$.next(true);
    await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.REFRESH_SAGE_INTACCT,
        entity_type: EntityType.TRIAL,
        entity_id: '',
        payload: JSON.stringify({
          triggered_by_user: true,
        }),
      })
    );
    this.overlayService.success(MessagesConstants.INVOICE.SAGE_INTACCT_RETRIEVAL);
    this.loadingFetchSageIntacct$.next(false);
  }

  isExternalFilterPresent = () => {
    const { vendors, accrualPeriod, invoiceDate } = this.filtersForm.value;

    return !!(
      this.showRequireCostBreakdown$.getValue() ||
      this.showRequireAccrualPeriod$.getValue() ||
      vendors?.length ||
      accrualPeriod?.length ||
      invoiceDate
    );
  };

  doesExternalFilterPass = ({
    data: {
      hasError,
      organization: { id },
      accrual_period,
      invoice_date,
      requireCostBreakdown,
    },
  }: IRowNode) => {
    const { vendors, accrualPeriod, invoiceDate } = this.filtersForm.value as unknown as {
      vendors: string[];
      accrualPeriod: string[];
      invoiceDate: string;
    };

    return (
      (!this.showRequireAccrualPeriod$.getValue() || !accrual_period) &&
      (!this.showRequireCostBreakdown$.getValue() || requireCostBreakdown || hasError) &&
      (!vendors?.length || vendors.includes(id)) &&
      (!accrualPeriod?.length || accrualPeriod.includes(dayjs(accrual_period).format('YYYY-MM'))) &&
      (!invoiceDate || invoiceDate === invoice_date)
    );
  };

  onFilterChange = () => {
    // If there is no rendered node after redirection and applied filters, the grid destroys itself so we need to refresh the grid
    if (!this.gridAPI.getColumnDefs()) {
      this.redirectedWithAnyRenderedNodes$.next(true);
    } else {
      this.gridAPI.onFilterChanged();
    }
  };

  getAccrualPeriodOptions(startDate: string, endDate: string) {
    const amountOfMonths = Math.round(Number(Utils.getDurationInMonths(startDate, endDate)));
    const accrualPeriodOptions: Option[] = [];

    for (let month = 0; month < amountOfMonths; month++) {
      const date = dayjs(startDate).add(month, 'month');

      accrualPeriodOptions.push({
        label: date.format('MMMM YYYY'),
        value: date.format('YYYY-MM'),
      });
    }

    return accrualPeriodOptions;
  }

  vendorValueSetter = (params: ValueSetterParams) => {
    const newValue = isString(params.newValue)
      ? this.vendorOptions.find(({ label }) => label === params.newValue)
      : params.newValue;

    const newVendor = this.organizationQuery.getVendor(newValue.value)[0];

    set(params.data, 'organization.currency', newVendor?.currency);
    set(params.data, 'organization.name', newValue.label);
    set(params.data, 'organization.id', newValue.value);

    return true;
  };
}
