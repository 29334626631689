import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';

@Component({
  selector: 'aux-quarter-close-processing-confirmation',
  template: `<div style="width: 420px" class="font-inter">
    <p>
      You have successfully began the closing of your month/quarter.
      <span class="font-bold"
        >This process can take up to 5 minutes. You can close this window and leave the page.
      </span>
      You will receive an email notification when complete, and an Audit Package will be available
      in the Document Library.
    </p>
    <div class="flex items-end flex-row-reverse border-t border-aux-gray-dark pt-3.5 mt-4">
      <button class="btn btn--blue" (click)="closeModal()">OK</button>
    </div>
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCloseProcessingConfirmationComponent {
  constructor(private ref: CustomOverlayRef) {}

  closeModal() {
    this.ref.close();
  }
}
