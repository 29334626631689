import { Injectable } from '@angular/core';
import { getPatientCostSummaryQuery, SortOrder } from '@services/gql.service';
import { GenericOperator } from '@services/operator.class';
import { Utils } from '@services/utils';
import { BehaviorSubject, combineLatest, Observable, Subscriber } from 'rxjs';
import { TrialInsightsQuery } from '../../store/trial-insights.query';
import {
  TICPCChartType,
  TrialInsightsClinicalPatientCostChartService,
} from './patient-cost-chart.service';
import { TrialInsightsClinicalPatientCostTableService } from './patient-cost-table.service';
import { TrialInsightsState } from '../../models/trial-insights-store.model';
import {
  TrialInsightsPatientCostKey,
  TrialInsightsTableOptions,
} from '../../models/trial-insights-table.model';
import {
  GenericTrialInsightsQuery,
  SortOrderDefault,
} from '../../classes/trial-insights-query.class';

type state = Partial<TrialInsightsState['patientCost']>;
type rowData = TrialInsightsTableOptions['rowData']['data'];
type results = [state['data'], TrialInsightsPatientCostKey, SortOrder];

const sortDefaults: SortOrderDefault[] = [
  {
    buttonKey: TrialInsightsPatientCostKey.SITE,
    defaultOrder: SortOrder.DESC,
  },
  {
    buttonKey: TrialInsightsPatientCostKey.GROUP,
    defaultOrder: SortOrder.DESC,
  },
];

@Injectable()
export class TrialInsightsClinicalPatientCostQueryService extends GenericTrialInsightsQuery<TICPCChartType> {
  constructor(
    public trialInsightsQuery: TrialInsightsQuery,
    public chartService: TrialInsightsClinicalPatientCostChartService,
    public tableService: TrialInsightsClinicalPatientCostTableService
  ) {
    super({
      trialInsightsQuery,
      slice: 'patientCost',
      sortDefaults,
      chartService,
      tableService,
    });
  }

  processPatientCostSummary$(): Observable<results> {
    return combineLatest([
      this.data as Observable<getPatientCostSummaryQuery[]>,
      this.selectedKey as BehaviorSubject<TrialInsightsPatientCostKey>,
      this.sortOrder,
    ]).pipe(this.processResponseData());
  }

  processResponseData() {
    const processFn = (sourceValue: results, subscriber: Subscriber<results>) => {
      const [data, selectedKey, sortOrder] = sourceValue;

      if (!data || !data.length) {
        this.resetState();
        return;
      }

      const parsedData = this.parseStoreData(data);
      const costData = this.parseStoreDetail(parsedData, selectedKey, sortOrder);

      if (!costData) {
        return;
      }

      // Costs
      const costs =
        selectedKey === TrialInsightsPatientCostKey.SITE
          ? costData.site_costs
          : costData.patient_group_costs;

      // Patients Enrolled
      const patientsEnrolled = costData.total_cost?.patients_enrolled || 0;

      // Expected Enrolled
      const expectedEnrolled = costData.expected_patients_enrolled;
      this.expectedEnrolled.next(`${expectedEnrolled}`);

      // Expected Enrolled Exceeded
      const expectedEnrolledExceeded = patientsEnrolled > expectedEnrolled;
      this.expectedEnrolledExceeded.next(expectedEnrolledExceeded);

      // Expected Enrolled for Chart Max
      const expectedEnrolledChartMax = expectedEnrolledExceeded
        ? patientsEnrolled
        : expectedEnrolled;

      // Total Amount
      const totalAmount = costData.total_cost?.average_patient_cost || 0;
      this.totalAmount.next(Utils.currencyFormatter(totalAmount));

      // Legend Options
      const legendOptions = this.chartService.createLegend(patientsEnrolled);
      this.legendOptions.next(legendOptions);

      // Chart Options
      const dataset = this.chartService.createDatasets(patientsEnrolled);
      const chartOptions = this.chartService.createChart(dataset, expectedEnrolledChartMax);
      this.chartOptions.next(chartOptions);

      const rowData: rowData = costs.map((cost) => {
        const siteData = JSON.parse(cost.source_entity_data);

        return {
          buttonKey: selectedKey,
          leftHeader: siteData.name || '',
          leftSubheader: siteData.primary_investigator || '',
          rightHeader: Utils.currencyFormatter(cost.average_patient_cost),
          rightSubheader: `${cost.patients_enrolled} Patients`,
        };
      });

      // Table Options
      const tableOptions = this.tableService.createTable(selectedKey, rowData);
      this.tableOptions.next(tableOptions);

      subscriber.next(sourceValue);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }
}
