import { firstValueFrom, Observable } from 'rxjs';

type BatchPromisesReturn<R> = (R | Error)[];

export const batchPromises = async <T, R>(
  requests: T[],
  cb: (param: T, index: number) => Promise<R>,
  batchSize = 10
): Promise<BatchPromisesReturn<R>> => {
  const results: BatchPromisesReturn<R> = [];
  for (let i = 0; i <= requests.length; i += batchSize) {
    const slicedRequests = requests.slice(i, i + batchSize).map(cb);
    const responses = await Promise.allSettled(slicedRequests);

    for (const resp of responses) {
      if (resp.status === 'fulfilled') {
        results.push(resp.value);
      } else {
        results.push(new Error(String(resp.reason)));
        console.error(resp.reason);
      }
    }
  }

  return results;
};

export const batchAPIRequest = async <T, R>(
  inputs: T[],
  p: (input: T[]) => Observable<GraphqlResponse<R[]>>,
  batchSize = 10
): Promise<{
  data: R[] | null;
  success: boolean;
  errors: string[];
}> => {
  const responses: {
    data: R[] | null;
    success: boolean;
    errors: string[];
  } = {
    data: [],
    success: true,
    errors: [],
  };

  for (let i = 0; i < inputs.length; i += batchSize) {
    const input = inputs.slice(i, i + batchSize);
    const result = await firstValueFrom(p(input));

    responses.success ||= result.success;
    if (result.success && result.data) {
      responses.data = responses.data?.concat(result.data) || result.data;
    } else {
      responses.errors = responses.errors.concat(result.errors);
    }
  }

  return responses;
};
