import { Injectable } from '@angular/core';
import { combineQueries, QueryEntity } from '@datorama/akita';
import { OrganizationQuery } from '@models/organization/organization.query';
import { map } from 'rxjs/operators';
import { PaymentMilestoneStore, PaymentMilestoneState } from './payment-milestone.store';

@Injectable({ providedIn: 'root' })
export class PaymentMilestoneQuery extends QueryEntity<PaymentMilestoneState> {
  constructor(
    protected store: PaymentMilestoneStore,
    private organizationQuery: OrganizationQuery
  ) {
    super(store);
  }

  selectAllMilestones() {
    return combineQueries([this.selectAll(), this.organizationQuery.allVendorsObj$]).pipe(
      map(([mils, organizations]) => {
        return mils.map((mil) => {
          return {
            ...mil,
            organization: mil.organization?.id ? organizations[mil.organization.id] : null,
          };
        });
      })
    );
  }
}
