import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import {
  Currency,
  EntityType,
  GqlService,
  PermissionType,
  UpdateInvoiceDetailInput,
} from '@services/gql.service';
import { AuthQuery } from '@models/auth/auth.query';
import { Utils } from '@services/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InvoiceModel } from '../state/invoice.model';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';
import { decimalAdd, AuxExcelStyles } from '@shared/utils';
import {
  CellClassParams,
  CellValueChangedEvent,
  ColumnApi,
  EditableCallbackParams,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { TableConstants } from '@constants/table.constants';
import { TrialsQuery } from '@models/trials/trials.query';
import { StickyElementService } from '@services/sticky-element.service';
import { cloneDeep, merge } from 'lodash-es';
import { OverlayService } from '@services/overlay.service';
import { InvoiceItemColumnInfo, InvoiceItemColumnType } from './items-via-pdf-tab.model';
import { AuthService } from '@models/auth/auth.service';
import { MessagesConstants } from '@constants/messages.constants';
import { WorkflowQuery } from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { TableService } from '@services/table.service';
import { removeSymbolsFromString } from '@shared/utils/formatter.utils';

@UntilDestroy()
@Component({
  selector: 'aux-items-via-pdf-tab',
  templateUrl: 'items-via-pdf-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsViaPdfTabComponent implements OnInit, OnDestroy {
  @Input() invoice: InvoiceModel | undefined;

  readonly currencyCodes = [
    'USD',
    'EUR',
    'GBP',
    'CAD',
    'INR',
    'JPY',
    'CHF',
    'AUD',
    'CNY',
    'BZR',
    'SEK',
    'HKD',
  ];

  isLoading = true;

  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    enableRangeSelection: true,
    suppressCellFocus: false,
    excelStyles: [...AuxExcelStyles, ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS)],
  } as GridOptions;

  gridData = [];

  editMode = false;

  private initialGridData = [];

  private gridChangeList: UpdateInvoiceDetailInput[] = [];

  private excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Invoice Line Items',
    allColumns: true,
    skipPinnedBottom: true,
  } as ExcelExportParams;

  private gridAPI!: GridApi;

  private gridColumnApi!: ColumnApi;

  private columnDefs = [];

  private userHasEditInvoicePermission = false;

  private isAdminUser = false;

  private invoiceLockTooltip = '';

  constructor(
    public vendorsQuery: OrganizationQuery,
    public authQuery: AuthQuery,
    public stickyElementService: StickyElementService,
    private mainQuery: MainQuery,
    private trialsQuery: TrialsQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private authService: AuthService,
    private workflowQuery: WorkflowQuery,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initPermission();
    this.initInvoiceDetailsGrid();
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  onGridReady(event: GridReadyEvent): void {
    this.gridColumnApi = event.columnApi;
    this.gridAPI = event.api;
    this.setPinnedBottomRowData();
  }

  autosize(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  isGridForOldInvoiceVisible(): boolean {
    return !!(
      this.gridData.length < 1 &&
      this.invoice &&
      this.invoice.ocr_line_items &&
      this.invoice.ocr_line_items.length > 0
    );
  }

  onEditMode(): void {
    this.editMode = true;
    this.initialGridData = cloneDeep(this.gridData);
    this.gridAPI.startEditingCell({
      rowIndex: 0,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      colKey: [this.columnDefs[0]?.field],
    });
  }

  onCancel(): void {
    this.editMode = false;
    if (this.hasChanges()) {
      this.gridData = cloneDeep(this.initialGridData);
      this.gridChangeList = [];
      this.setPinnedBottomRowData();
    }
  }

  onSave(): void {
    this.editMode = false;
    this.gqlService
      .updateInvoiceDetail$(this.gridChangeList)
      .pipe(untilDestroyed(this))
      .subscribe(({ success, data, errors }) => {
        if (success && data) {
          this.overlayService.success(MessagesConstants.SUCCESSFULLY_SAVED);
        } else {
          this.overlayService.error(errors);
          this.gridData = cloneDeep(this.initialGridData);
        }
        this.gridChangeList = [];
      });
  }

  onCellValueChanged(event: CellValueChangedEvent): void {
    const collId = event.colDef.field?.replace('.value', '') || '';
    const cellId = event.data[collId].id;
    const index = this.gridChangeList.findIndex((item) => item.id === cellId);
    if (index === -1) {
      this.gridChangeList.push({
        id: cellId,
        entity_field_value: event.newValue,
        entity_field_source: 'MANUAL',
      });
    } else {
      this.gridChangeList[index].entity_field_value = event.newValue;
    }

    if (
      event.colDef.type === InvoiceItemColumnType.UNIT_PRICE ||
      event.colDef.type === InvoiceItemColumnType.PRICE
    ) {
      this.setPinnedBottomRowData();
    }
  }

  getDynamicExcelParams(): void {
    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    if (!trial) {
      return;
    }
    const totals = this.gridAPI.getPinnedBottomRow(0)?.data;

    const appendContent: ExcelExportParams['appendContent'] = [
      {
        cells: [
          {
            data: {
              value: 'Total',
              type: 'String',
            },
            styleId: ['total_row_header'],
          },
        ],
      },
    ];

    this.gridColumnApi
      ?.getAllDisplayedColumns()
      .filter((col) => col.getColId() !== 'col_1.value')
      .forEach((col) => {
        if (
          col.getColDef().type === InvoiceItemColumnType.UNIT_PRICE ||
          col.getColDef().type === InvoiceItemColumnType.PRICE
        ) {
          appendContent[0].cells.push({
            data: {
              value: `${totals[col.getColId().replace('.value', '')].value}`,
              type: 'Number',
            },
            styleId: [`total_row_${this.invoice?.organization?.currency || Currency.USD}`],
          });
        } else {
          appendContent[0].cells.push({
            data: { value: '', type: 'String' },
            styleId: ['total_row'],
          });
        }
      });

    const exportOptions = {
      ...this.excelOptions,
      fileName: `auxilius-invoice-line-items-${trial.short_name}-${this.invoice?.invoice_no}.xlsx`,
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trial.short_name}`, type: 'String' },
              mergeAcross: appendContent[0].cells.length - 1,
              styleId: 'first_row',
            },
          ],
        },
      ],
      appendContent: appendContent,
    } as ExcelExportParams;
    this.gridAPI?.exportDataAsExcel(exportOptions);
  }

  hasChanges(): boolean {
    return this.gridChangeList.length > 0;
  }

  isEditBtnDisabled(): boolean {
    return (
      (!this.isAdminUser && !this.userHasEditInvoicePermission) ||
      !!this.invoiceLockTooltip ||
      this.gridData.length < 1
    );
  }

  getEditBtnTooltip(): string {
    if (!this.isAdminUser && !this.userHasEditInvoicePermission) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    return this.invoiceLockTooltip;
  }

  private initPermission(): void {
    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
      })
      .pipe(untilDestroyed(this))
      .subscribe((hasPermission) => {
        this.userHasEditInvoicePermission = hasPermission;
      });

    this.authQuery.adminUser$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.isAdminUser = event;
    });

    this.workflowQuery.invoiceLockTooltip$.pipe(untilDestroyed(this)).subscribe((tooltip) => {
      this.invoiceLockTooltip = tooltip;
    });
  }

  private initInvoiceDetailsGrid(): void {
    this.gqlService
      .getInvoiceDetailsGrid$(EntityType.INVOICE_LINE, this.invoice?.id || '')
      .pipe(untilDestroyed(this))
      .subscribe(({ success, data, errors }) => {
        if (success && data) {
          this.columnDefs = this.parseColumnDefs(data.column_defs || '[]');
          const columnsWithNumbers: string[] = [];

          this.columnDefs.forEach((column: { type: string; field: string }) => {
            if (
              column.type === InvoiceItemColumnType.UNIT_PRICE ||
              column.type === InvoiceItemColumnType.PRICE
            ) {
              columnsWithNumbers.push(column.field.replace('.value', ''));
            }
          });

          this.gridData = JSON.parse(data.row_data || '[]');

          columnsWithNumbers.forEach((column) => {
            this.gridData.forEach((row, index) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.gridData[index][column].value = this.refactorToNumbers(row[column].value);
            });
          });

          this.gridOptions = {
            ...this.gridOptions,
            columnDefs: this.columnDefs,
          };
        } else {
          this.overlayService.error(errors);
        }
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  private refactorToNumbers(value: string): string {
    const refactoredValue = removeSymbolsFromString(value, [...this.currencyCodes, '$', ' ', ',']);

    return Utils.isNumber(refactoredValue) ? refactoredValue : '';
  }

  private setPinnedBottomRowData(): void {
    this.gridAPI.setPinnedBottomRowData([
      merge(
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          [this.columnDefs[0]?.field.replace('.value', '')]: {
            value: 'Total',
          },
        },
        this.calculatePinnedBottomData()
      ),
    ]);
  }

  private parseColumnDefs(columnDefs: string) {
    return JSON.parse(columnDefs)
      .sort((a: { field: string }, b: { field: string }) => a.field.localeCompare(b.field))
      .map((column: InvoiceItemColumnInfo) => ({
        ...column,
        cellClass: (params: CellClassParams) => {
          if (
            params.colDef.type === InvoiceItemColumnType.UNIT_PRICE ||
            params.colDef.type === InvoiceItemColumnType.PRICE
          ) {
            return [
              `budgetCost${this.invoice?.organization?.currency}`,
              TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT,
            ];
          }

          if (params.colDef.type === InvoiceItemColumnType.QUANTITY) {
            return [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT];
          }

          return ['!block text-left max-w truncate'];
        },
        tooltipField: column.field,
        tooltipValueGetter: (params: { valueFormatted: string }) => {
          return params.valueFormatted !== Utils.zeroHyphen ? params.valueFormatted : '';
        },
        ...this.getValueFormatter(column.type),
        resizable: true,
        sortable: false,
        editable: (params: EditableCallbackParams) =>
          TableService.isEditableCell(this.editMode)(params),
      }));
  }

  private getValueFormatter(columnType: InvoiceItemColumnType) {
    if (
      columnType === InvoiceItemColumnType.UNIT_PRICE ||
      columnType === InvoiceItemColumnType.PRICE
    ) {
      return {
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(
            params,
            this.invoice?.organization?.currency || Currency.USD,
            true
          );
        },
      };
    }

    return {
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.node?.rowPinned === 'bottom' && !params.value) {
          return Utils.zeroHyphen;
        }
        return;
      },
    };
  }

  private calculatePinnedBottomData(): { [key: string]: { value: number } } {
    const columnsForTotal = this.columnDefs
      .filter(
        (column: { type: string }) =>
          column.type === InvoiceItemColumnType.UNIT_PRICE ||
          column.type === InvoiceItemColumnType.PRICE
      )
      .map((column: { field: string }) => column.field.replace('.value', ''));

    const init = this.getZeroTotalValue(columnsForTotal);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.gridData.reduce((acc, val) => {
      for (const key of columnsForTotal) {
        const currentVal = acc[key].value;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const additionalVal = val[key].value;

        if (Utils.isNumber(currentVal) && Utils.isNumber(additionalVal)) {
          acc[key].value = decimalAdd(currentVal, additionalVal);
        }
      }

      return acc;
    }, init);
  }

  private getZeroTotalValue(columnsForTotal: string[]): {
    [key: string]: { value: number | string };
  } {
    const list: { [key: string]: { value: number | string } } = {};

    columnsForTotal.forEach((column) => {
      list[`${column}`] = {
        value: '',
      };
    });

    return list;
  }
}
