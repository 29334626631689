import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DriverSiteStore, DriverSiteState } from './driver-site.store';

@Injectable({ providedIn: 'root' })
export class DriverSiteQuery extends QueryEntity<DriverSiteState> {
  constructor(protected store: DriverSiteStore) {
    super(store);
  }
}
