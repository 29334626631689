import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import {
  ForecastTableGridMethodChange,
  ForecastTableGridNgOnChangesInterface,
  ForecastTableGridSiteCurveInterface,
} from '../../models/forecast-table-grid.model';
import { CurveType } from '@services/gql.service';

@Component({
  selector: 'aux-forecast-table-grid-method-site',
  templateUrl: './forecast-table-grid-method-site.component.html',
  styleUrls: ['./forecast-table-grid-method-site.component.scss'],
})
export class ForecastTableGridMethodSiteComponent implements OnChanges {
  @ViewChild('gridMethodSite') public input!: NgSelectComponent;

  @Input() categoryId = '';

  @Input() primarySettingsId = '';

  @Input() isChild = false;

  @Input() placeholder = '';

  @Input() tooltip = '';

  @Input() disabled = false;

  @Input() isInvalid = false;

  @Input() onMethodChange!: ForecastTableGridMethodChange;

  @Input() siteCurves: ForecastTableGridSiteCurveInterface[] = [];

  @Input() driverSettingId = '';

  dropdownOpen = false;

  filteredSiteCurves: ForecastTableGridSiteCurveInterface[] = [];

  ngOnChanges(changes: ForecastTableGridNgOnChangesInterface): void {
    if (changes.categoryId && changes.categoryId.firstChange) {
      this.initSiteMethod();
    }

    // Filter siteCurves based on selected site period
    this.filterSiteCurves();
  }

  initSiteMethod(): void {
    if (!this.driverSettingId) {
      const netSites = this.siteCurves.filter((siteCurve) => {
        return siteCurve.curveType === CurveType.NET;
      });

      if (netSites.length >= 1) {
        this.onModelChange(netSites[0].value);
      }
    }
  }

  filterSiteCurves(): void {
    const currentCurve = this.siteCurves.find((siteCurve) => {
      return siteCurve.value === this.driverSettingId;
    });

    const currentCurveType = currentCurve?.curveType || CurveType.NET;

    this.filteredSiteCurves = this.siteCurves.filter((siteCurve) => {
      return siteCurve.curveType === currentCurveType;
    });
  }

  onModelChange(event: string): void {
    this.onMethodChange(event, this.primarySettingsId, {
      name: !this.isChild ? 'category' : 'activity',
      prop: 'driver_setting',
      id: this.categoryId,
    });
  }

  onDropdownOpen() {
    this.dropdownOpen = true;
  }

  onDropdownClose() {
    this.dropdownOpen = false;
  }
}
