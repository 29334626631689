<div class="flex flex-col w-full relative">
  <!-- Spinner -->
  <div
    *ngIf="trialLoading || componentsLoading"
    class="flex items-center w-full h-full z-10 absolute"
    style="background-color: #f3f6f7"
  >
    <div
      *ngIf="componentsLoading && !trialLoading"
      class="border-8 w-32 h-32 m-auto mt-40 spinner"
    ></div>
  </div>

  <!-- BVA Chart Menu -->
  <aux-trial-insights-finance-bva-chart-menu />

  <br />

  <aux-trial-insights-finance-comparing-budget />

  <div class="trial-insights-divider w-full !m-0 !my-7"></div>

  <p class="font-bold">Trial to Date Insights</p>

  <div class="grid grid-cols-3 mt-4 gap-7">
    <div>
      <aux-trial-insights-finance-remaining-spend-over-time />
    </div>
    <div>
      <aux-trial-insights-finance-payment-milestones />
    </div>
    <div *ngIf="showInvestigatorSpendCard$ | async">
      <aux-trial-insights-finance-enrolled-patients />
    </div>
  </div>
</div>
