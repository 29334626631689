import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  StoreConfig,
  getEntityType,
  EntityUIStore,
} from '@datorama/akita';
import { BudgetType, Category } from '@services/gql.service';
import { RequireSome } from '@services/utils';
import { BehaviorSubject } from 'rxjs';

export type InMonthBudgetTypes = {
  [key in Exclude<BudgetType, BudgetType.BUDGET_MODEL>]?: {
    total_cost: number;
    total_accrual: number;
    total_forecast: number;
    total_wp: number;
    total_override: number;
    total_override_adjusted: number;
    adjustment: number;
  };
};
export type CategoryState = EntityState<
  Omit<
    RequireSome<Partial<Category>, 'id' | 'category_type'>,
    | 'categories'
    | 'activities'
    | 'budget_forecast_settings'
    | 'total_cost'
    | 'total_wp'
    | 'total_forecast'
    | 'total_accrual'
  > & {
    sub_category_ids: string[];
    sub_categories: string[];
    activity_ids: string[];
    primary_settings_id: string;
    secondary_settings_id: string;
    activity_checked_count?: number;
  } & InMonthBudgetTypes
>;

export type CategoryModel = getEntityType<CategoryState>;

export type CategoryUI = {
  isOpen: boolean;
  isLoading: boolean;
  isDetailFetched: boolean;
};

export type CategoryUIState = EntityState<CategoryUI>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'category' })
export class CategoryStore extends EntityStore<CategoryState> {
  ui!: EntityUIStore<CategoryUIState>;

  hideForecastedActivities$ = new BehaviorSubject(false);

  hideActivitiesWithNoRemainingCost$ = new BehaviorSubject(false);

  constructor() {
    super({});

    const defaultUiState: CategoryUI = {
      isOpen: false,
      isLoading: false,
      isDetailFetched: false,
    };
    this.createUIStore().setInitialEntityState(defaultUiState);
  }
}
