import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { EntityType, GqlService, PeriodType } from '@services/gql.service';
import { GenericOperator } from '@services/operator.class';
import {
  TrialInsightsRemainingSpendResponseType,
  TrialInsightsResponseGeneric,
} from '../../models/trial-insights-store.model';
import { TrialInsightsStore } from '../../store/trial-insights.store';
import { TrialInsightsQuery } from '../../store/trial-insights.query';

type responseType = TrialInsightsRemainingSpendResponseType;
type response = TrialInsightsResponseGeneric<responseType[]>;

@Injectable()
export class TrialInsightsRemainingSpendStoreService {
  constructor(
    private store: TrialInsightsStore,
    private query: TrialInsightsQuery,
    private gqlService: GqlService
  ) {}

  getRemainingSpend$(): Observable<unknown> {
    return this.query.select('currentOpenMonth').pipe(
      filter((currentOpenMonth) => !!currentOpenMonth),
      switchMap((currentOpenMonth) => {
        return this.gqlService.getRemainingSpend$({
          entity_type: EntityType.ORGANIZATION,
          period_type: PeriodType.QUARTER,
          start_date: currentOpenMonth || '',
        });
      }),
      this.processResponse()
    );
  }

  // loadingState(type: loadingState): state {
  //   const loadingState: state = {};
  //
  //   if (type === 'initial') {
  //     loadingState.isLoading = true;
  //     loadingState.data = null;
  //   } else {
  //     loadingState.isLoading = false;
  //     loadingState.isLoadingRemaining = false;
  //   }
  //
  //   return loadingState;
  // }

  // updateLoadingState(type: loadingState) {
  //   const updates = this.loadingState(type);
  //
  //   const processFn = (sourceValue: any, subscriber: Subscriber<any>) => {
  //     this.store.update((state) => {
  //       return {
  //         ...state,
  //         remainingSpend: {
  //           ...state.remainingSpend,
  //           ...updates,
  //           data: [...(state.remainingSpend.data || []), ...(updates.data || [])],
  //         },
  //       };
  //     });
  //
  //     subscriber.next(updates);
  //   };
  //
  //   const operatorConfig = new GenericOperator(processFn);
  //   return operatorConfig.operator();
  // }

  processResponse() {
    const processFn = (response: response, subscriber: Subscriber<unknown>) => {
      const { data } = response;

      this.store.update((state) => {
        return {
          ...state,
          remainingSpend: {
            ...state.remainingSpend,
            data: data ? data : [],
            totalRemainingSpend: this.getTotalRemainingSpend(data),
            isLoading: false,
            isLoadingRemaining: false,
          },
        };
      });

      subscriber.next(data);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }

  getTotalRemainingSpend(spends: response['data']): number {
    return spends?.reduce((accum, spend) => accum + spend.remaining_spend.amount, 0) || 0;
  }
}
