import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@services/utils';

@Pipe({
  name: 'round',
})
export class RoundPipe implements PipeTransform {
  transform(value?: number | string | null | undefined, decimalPlaces?: number): string {
    const roundedNumber = Utils.roundToNumber(value || 0, decimalPlaces || 2);
    if (roundedNumber) {
      return roundedNumber.toString();
    } else if (value) {
      return '0.00';
    }
    return Utils.zeroHyphen;
  }
}
