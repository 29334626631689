import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { BudgetCurrencyState, createInitialState } from './budget-currency.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'budget-currency' })
export class BudgetCurrencyStore extends EntityStore<BudgetCurrencyState> {
  constructor() {
    super(createInitialState());
  }
}
