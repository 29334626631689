import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuxIconName } from '@components/icon/icon';

@Component({
  selector: 'aux-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnChanges {
  isHandlingClick$ = new BehaviorSubject(false);

  spinnerClassNames = 'spinner w-5 h-5';

  defaultClasses = {
    success: 'btn--success',
    primary: 'btn--primary',
    negative: 'btn--negative',
    secondary: 'btn--secondary',
    hyperlink: 'btn--hyperlink',
    custom: '',
  };

  classNames = '';

  @Input() type?: 'button' | 'submit' | 'reset' = 'button';

  @Input() disabled: boolean | null = false;

  @Input() classList? = '';

  @Input() icon?: AuxIconName;

  @Input() loading: boolean | null = false;

  @Input() label = '';

  @Input() svgAttributes = 'mr-2 flex-shrink-0';

  @Input() iconSize = 24;

  @Input() variant: 'success' | 'primary' | 'negative' | 'secondary' | 'hyperlink' | 'custom' =
    'primary';

  @Input() spinnerSize = 5;

  @Input() disableLoading = false;

  @Input() onClick: VoidFunction = () => {};

  @Output() clickEmit = new EventEmitter();

  ngOnChanges() {
    this.classNames = `relative ${this.defaultClasses[this.variant]} ${this.classList}`;
    this.spinnerClassNames = `spinner w-${this.spinnerSize} h-${this.spinnerSize}`;
  }

  async handleClick() {
    this.clickEmit.emit();
    if (this.disableLoading) {
      this.onClick();

      return;
    }

    this.isHandlingClick$.next(true);

    try {
      await this.onClick();
    } finally {
      this.isHandlingClick$.next(false);
    }
  }
}
