import {
  Component,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { finalize, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ScenarioDialogComponent } from './scenario-dialog/scenario-dialog.component';
import { ScenarioComponent } from '../scenario.component';

@UntilDestroy()
@Component({
  selector: 'aux-scenario-manager',
  templateUrl: './scenario-manager.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioManagerComponent implements AfterViewInit {
  @ViewChildren('scenarioTemp') scenarioTemp!: QueryList<TemplateRef<unknown>>;

  scenarios = [
    {
      header: 'Enrollment Rate Change',
      row1: 'Total Cost: $27,000,000 (+8%)',
      row2: 'Timeline: 38 weeks (-2 weeks)',
      row3: 'Owner: Adam W. (Sponsor)',
    },
  ];

  budgets = [
    {
      header: 'Current Budget',
      row1: 'Vendor: Parexel',
      row2: 'Total Cost: $24,500,00',
      row3: 'Timeline: 40 weeks',
    },
    {
      header: 'Change Order #761123',
      row1: 'Vendor: Parexel',
      row2: 'Total Cost: $26,000,00 (+6.2%)',
      row3: 'Timeline: 45 weeks (+5 weeks)',
    },
  ];

  constructor(
    private overlayService: OverlayService,
    private scenarioPage: ScenarioComponent
  ) {}

  createScenario() {
    this.overlayService.open({ content: ScenarioDialogComponent });
  }

  ngAfterViewInit(): void {
    this.scenarioTemp.changes
      .pipe(
        startWith(null),
        untilDestroyed(this),
        finalize(() => {
          setTimeout(() => {
            this.scenarioPage.rightSideContainer.next(null);
          }, 0);
        })
      )
      .subscribe(() => {
        setTimeout(() => {
          this.scenarioPage.rightSideContainer.next(this.scenarioTemp.first || null);
        }, 0);
      });
  }
}
