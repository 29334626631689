import { Injectable } from '@angular/core';
import { combineQueries, QueryEntity } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  SpecificationCategoryStore,
  SpecificationCategoryState,
  SpecificationCategoryModel,
} from './specification-category.store';
import { SpecificationQuery } from '../specification/specification.query';
import { SpecificationSettingQuery } from '../specification-setting/specification-setting.query';
import { SpecificationModel } from '../specification/specification.store';
import { SpecificationSettingModel } from '../specification-setting/specification-setting.store';

export interface FullSpecification extends Omit<SpecificationModel, 'settings'> {
  settings: SpecificationSettingModel[];
}

export interface FullSpecificationCategory
  extends Omit<SpecificationCategoryModel, 'specifications'> {
  specifications: FullSpecification[];
}

@Injectable({ providedIn: 'root' })
export class SpecificationCategoryQuery extends QueryEntity<SpecificationCategoryState> {
  categories$: Observable<FullSpecificationCategory[]> = combineQueries([
    this.selectAll(),
    this.specificationQuery.selectAll({ asObject: true }),
    this.specificationSettingQuery.selectAll({ asObject: true }),
  ]).pipe(
    map(([cats, specs, settings]) => {
      return cats.map((cat) => {
        return {
          ...cat,
          specifications: cat.specifications
            .map((specID) => specs[specID])
            .map((spec) => {
              return {
                ...spec,
                settings: spec.settings.map((settingID) => settings[settingID]),
              };
            }),
        };
      });
    })
  );

  constructor(
    protected store: SpecificationCategoryStore,
    public specificationQuery: SpecificationQuery,
    public specificationSettingQuery: SpecificationSettingQuery
  ) {
    super(store);
  }
}
