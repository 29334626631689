import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'aux-scenario-pages',
  templateUrl: './scenario-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioDialogComponent {
  types = ['Timeline', 'Enrollment Rate', 'Site Additions', 'Site Distribution'];

  selectedTab = 'Timeline';

  activeTabIndex = 0;

  milestoneOptions = ['Patient Enrollment'];

  tables = [
    {
      header: 'Current Timeline',
      gray: false,
      data: [
        { milestone: 'Protocol Preparation', date: '02 / 20 / 2019 ', length: '20 days' },
        { milestone: 'Site Identification', date: '03 / 08 / 2019', length: '20 days' },
        { milestone: 'Site Initiation', date: '03 / 28 / 2019', length: '20 days' },
        { milestone: 'Patient Enrollment', date: '04 / 18 / 2019', length: '20 days' },
        { milestone: 'Treatment', date: '05 / 07 / 2019', length: '20 days' },
      ],
    },
    {
      header: 'Revised Timeline',
      gray: true,
      data: [
        { milestone: 'Protocol Preparation', date: '02 / 20 / 2019 ', length: '20 days' },
        { milestone: 'Site Identification', date: '03 / 08 / 2019', length: '20 days' },
        { milestone: 'Site Initiation', date: '03 / 28 / 2019', length: '20 days' },
        { milestone: 'Patient Enrollment', date: '04 / 18 / 2019', length: '5 days' },
        { milestone: 'Treatment', date: '04 / 23 / 2019', length: '20 days' },
      ],
    },
  ];

  quadrants = ['High', 'Mid to High', 'Mid to Low', 'Low', 'All Sites'];
}
