import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ActivityType } from '@services/gql.service';
import { BehaviorSubject } from 'rxjs';

interface AgAdjustmentDiscountTooltipParams extends ICellRendererParams {
  isInEditMode: BehaviorSubject<boolean>;
}

@Component({
  selector: 'aux-ag-adjustment-discount-tooltip-column',
  template: `<div
      *ngIf="params.isInEditMode?.getValue() && activityTypeIsDiscount"
      class="absolute w-full h-full"
      style="background-color: transparent; box-sizing: border-box;"
      auxTooltip
      [template]="noteTemplate"
    ></div>
    <ng-template #noteTemplate>
      <div class="space-y-1">Discount can be adjusted in the Closing Checklist</div>
    </ng-template>

    <div>
      <span>{{ params.valueFormatted }}</span>
    </div>`,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgAdjustmentDiscountTooltipComponent implements ICellRendererAngularComp {
  params!: AgAdjustmentDiscountTooltipParams;

  activityTypeIsDiscount = false;

  refresh(): boolean {
    return false;
  }

  agInit(params: AgAdjustmentDiscountTooltipParams) {
    this.params = params;
    this.activityTypeIsDiscount =
      this.params?.data?.activity_type === ActivityType.ACTIVITY_DISCOUNT;
  }
}
