import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TopCard } from '../patient-curves.constants';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'aux-ag-patient-driver-top-card',
  templateUrl: './ag-patient-driver-top-card.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgPatientDriverTopCardComponent {
  @Input() card: TopCard | undefined;
}
