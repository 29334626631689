import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RiskCardAlert } from './risk-card/risk-card.component';

@Component({
  templateUrl: './risk-analytics.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskAnalyticsComponent {
  timelineAlerts: RiskCardAlert[] = [
    {
      color: 'red',
      message: 'Average site initiation time is greater than 4 months',
    },
    {
      color: 'red',
      message: 'Site visit pace lagging with budget impacts exceeding $100K',
    },
  ];

  timelineData = [
    {
      header: 'Sites Initiated',
      value: 100,
      progressColor: 'green',
      leftLabel: 'First Site - 11/5/19',
      rightLabel: 'Last Site - 11/5/19',
    },
    {
      header: 'Patient Enrollment',
      value: 50,
      progressColor: 'blue',
      leftLabel: 'First Patient - 12/5/19',
      rightLabel: 'Last Patient - In 45 days',
    },
    {
      header: 'Sites Initiated',
      value: 5,
      progressColor: 'blue',
      leftLabel: 'Database Lock - In 73 days',
      rightLabel: 'End of Trial - In 99 days',
    },
  ];

  patientAlerts: RiskCardAlert[] = [
    {
      color: 'red',
      message: 'Average cost per patient to date exceeds budget by 20%',
    },
    {
      color: 'green',
      message: 'Forecasted average cost through EOT exceeds current budget by 20%',
    },
    {
      color: 'green',
      message: 'Projected cost at full enrollment exceeds trial budget by 20% - 3 month Trend',
    },
  ];

  siteAlerts: RiskCardAlert[] = [
    {
      color: 'red',
      message: '4 Sites with > 2 protocol deviations',
    },
    {
      color: 'red',
      message: 'Data cleaning Efficiency ratio implies cost risk of > $25k',
    },
    {
      color: 'red',
      message: '15% of sites with target site efficiency ratio < 3',
    },
  ];

  paceOfSpendAlerts: RiskCardAlert[] = [
    {
      color: 'green',
      message: 'Cash position is above $1m',
    },
    {
      color: 'green',
      message: 'All categories remain less than 80% of budget',
    },
  ];

  fpAlerts: RiskCardAlert[] = [
    {
      color: 'red',
      message: 'Accrual gap is greater than 10% for >2 months',
    },
    {
      color: 'red',
      message: 'Missed trailing quarterly forecast by 10%',
    },
    {
      color: 'red',
      message: 'Deviation to original budget > 10%',
    },
  ];
}
