<button
  type="button"
  aria-pressed="false"
  class="relative inline-flex h-6 w-11 items-center cursor-pointer transition-colors focus:outline-none"
  [disabled]="disabled"
  (click)="toggle()"
>
  <span
    class="h-1.5 w-full rounded-full ease-in-out duration-200"
    [ngClass]="{
      'bg-aux-blue': fc.value,
      'bg-gray-200': !fc.value
    }"
  ></span>

  <span
    aria-hidden="true"
    class="absolute h-6 w-6 border rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
    [ngClass]="{
      'translate-x-5 border-aux-blue': fc.value,
      'translate-x-0 border-gray-200': !fc.value
    }"
  ></span>
</button>
