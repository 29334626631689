import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { BehaviorSubject, timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  template: `
    <div class="mb-4">
      You will be logged out in {{ secondsLeft | async }} seconds due to inactivity and all <br />
      unsaved changes will be lost. Please select “Stay Logged In” to <br />
      extend your session.
    </div>

    <div class="flex items-center justify-between">
      <button type="button" class="btn btn--secondary" (click)="ref.close('logout')">
        Log Out
      </button>

      <button type="button" class="btn btn--primary" (click)="ref.close()">Stay Logged In</button>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeoutDialogComponent {
  secondsLeft = new BehaviorSubject(90);

  constructor(public ref: CustomOverlayRef<'logout' | undefined>) {
    timer(0, 1000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const remaining = this.secondsLeft.getValue();

        if (remaining <= 0) {
          ref.close('logout');
          return;
        }
        this.secondsLeft.next(remaining - 1);
      });
  }
}
