import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

const INITIAL_POSITION = '-70px';
const ANIMATE_TIMING = '0.3s linear';

@Component({
  selector: 'aux-save-changes',
  templateUrl: './save-changes.component.html',
  styleUrls: ['save-changes.component.scss'],
  animations: [
    trigger('upDownAnimation', [
      transition(':enter', [
        style({ bottom: INITIAL_POSITION }),
        animate(ANIMATE_TIMING, style({ bottom: 0 })),
      ]),
      transition(':leave', [animate(ANIMATE_TIMING, style({ bottom: INITIAL_POSITION }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveChangesComponent implements OnInit, OnDestroy {
  @Input() onSaveChanges!: VoidFunction;

  @Input() showDiscardChangesBtn = false;

  @Output() cancel = new EventEmitter<Event>();

  moveJiraWidget(offset: string) {
    const widget = document.getElementById('jsd-widget');
    if (widget) {
      widget.style.bottom = offset;
    }
  }

  ngOnDestroy(): void {
    this.moveJiraWidget('0');
  }

  ngOnInit(): void {
    this.moveJiraWidget('90px');
  }

  onDiscardChanges(): void {
    this.cancel.emit();
  }
}
