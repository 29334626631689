import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TimelineStore, TimelineState } from './timeline.store';
import * as dayjs from 'dayjs';
import { map } from 'rxjs/operators';
import { first, last } from 'lodash-es';
import { Utils } from '@services/utils';

@Injectable({ providedIn: 'root' })
export class TimelineQuery extends Query<TimelineState> {
  constructor(protected store: TimelineStore) {
    super(store);
  }

  getTimelineRange(transformPattern = 'YYYY-MM-DD'): string[] {
    const timelineList = this.getValue().items;

    const timelineRange: string[] = [];

    timelineList.forEach(({ contract_start_date, contract_end_date }) => {
      let startDate = dayjs(contract_start_date);
      const endDate = dayjs(contract_end_date);

      while (startDate.isBefore(endDate)) {
        timelineRange.push(startDate.format(transformPattern));
        startDate = startDate.add(1, 'month');
      }
    });

    return timelineRange;
  }

  selectTimelineRange(transformPattern = 'YYYY-MM-DD') {
    return this.select('items').pipe(map(() => this.getTimelineRange(transformPattern)));
  }

  getStartEndDateTrial() {
    const items = this.getValue().items;

    const startDate = `${first(items)?.contract_start_date.slice(0, 8)}01`;
    let endDate = Utils.dateParse(`${last(items)?.contract_end_date.slice(0, 8)}01`);
    items.forEach((i) => {
      const parsedDate = Utils.dateParse(`${i.contract_end_date.slice(0, 8)}01`);
      if (parsedDate > endDate) {
        endDate = parsedDate;
      }
    });

    return {
      startDate,
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    };
  }

  selectStartEndDateTrial() {
    return this.select('items').pipe(map(() => this.getStartEndDateTrial()));
  }
}
