import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DistributionTimelineService } from '../services/distribution-timeline.service';
import { BehaviorSubject } from 'rxjs';
import { IconComponent } from '@components/icon/icon.component';
import { NgIf } from '@angular/common';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

export interface AgMonthCellComponentParams extends ICellRendererParams {
  timeline$: BehaviorSubject<{ startDate?: string; endDate?: string }>;
  noMonthInTimelineTooltipMessage: string;
}

@Component({
  template: `<div class="flex items-center">
    <aux-icon
      *ngIf="this.isMonthNoLongerInTimeline"
      name="AlertTriangle"
      class="text-aux-red-dark mr-2"
      [auxTooltip]="params.noMonthInTimelineTooltipMessage"
    />
    <span>{{ value }}</span>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TooltipDirective, IconComponent],
})
export class AgMonthCellComponent implements ICellRendererAngularComp {
  params!: AgMonthCellComponentParams;

  value!: string;

  cellClass!: string;

  isMonthNoLongerInTimeline = false;

  agInit(params: AgMonthCellComponentParams) {
    this.params = params;

    const cellValue = this.params.valueFormatted || this.params.value;
    this.value = cellValue;

    this.isMonthNoLongerInTimeline = DistributionTimelineService.isMonthNoLongerInTimeline(
      params,
      this.params.value,
      this.params.timeline$
    );
  }
  refresh() {
    return false;
  }
}
