<div class="w-screen h-screen flex p-4">
  <div class="max-w-2xl rounded shadow-md bg-white p-14 m-auto">
    <img class="w-64 mx-auto" src="assets/img/logo-black.png" alt="" />

    <img class="mx-auto my-7" src="assets/img/pablita-making-a-robot.png" alt="" />

    <h2 class="font-bold text-2xl text-aux-black text-center">
      {{ messagesConstants.SYSTEM_MAINTENANCE }}
    </h2>

    <hr class="my-5" />
    <div class="space-y-5">
      <div *ngFor="let obj of messages$ | async" class="relative">
        <div class="absolute top-[-1px]">
          <aux-icon name="Help" />
        </div>
        <div class="indent-8">{{ obj.message }}</div>
        <div class="text-aux-gray-lightest text-sm mt-0.5">{{ obj.timestamp }}</div>
      </div>
    </div>
  </div>
</div>
