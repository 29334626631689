import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Source } from '../investigator-forecast.types';

@Component({
  selector: 'aux-investigator-forecast-source-select',
  templateUrl: './investigator-forecast-source-select.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorForecastSourceSelectComponent {
  @Input() control!: FormControl;

  @Input() sources: Source[] = [];
}
