<div class="text-xl font-bold mb-4">Milestone Tracker Alerts</div>

<div class="text-sm">
  <div class="grid grid-cols-12 py-8 pr-4 border-b gap-5">
    <div class="col-span-11 flex items-center space-x-2">
      <span>Average treatment period is greater than</span>
      <aux-select class="w-32 inline-block" [options]="averageOptions" />
    </div>
    <div class="flex items-center justify-between">
      <aux-checkbox class="text-sm" />
    </div>
  </div>

  <div class="grid grid-cols-12 py-8 pr-4 border-b gap-5">
    <div class="col-span-11 flex items-center space-x-2">
      <span>Site visit pace exceeds forecasted pace for grater than</span>
      <aux-select class="w-32 inline-block" [options]="siteVisitOptions" />
    </div>
    <div class="flex items-center justify-between">
      <aux-checkbox class="text-sm" />
    </div>
  </div>

  <div class="grid grid-cols-12 py-8 pr-4 border-b gap-5">
    <div class="col-span-11">
      <div class="flex flex-wrap items-center space-x-2">
        <span>Patient enrollment is less than</span>
        <aux-select class="w-32 inline-block" [options]="lessOptions" />
        <span>of target at more</span>
      </div>
      <div class="flex flex-wrap items-center space-x-2">
        <span>than</span>
        <aux-select class="w-32 inline-block" [options]="moreOptions" />
        <span>of my sites</span>
      </div>
    </div>
    <div class="flex items-center justify-between">
      <aux-checkbox class="text-sm" />
    </div>
  </div>
</div>

<button class="mt-20 btn btn--blue w-32">Save</button>
