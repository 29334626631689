import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { PatientGroupsState, PatientGroupsStore } from './patient-groups.store';

@QueryConfig({
  sortBy: 'rank_order',
  sortByOrder: Order.ASC,
})
@Injectable({ providedIn: 'root' })
export class PatientGroupsQuery extends QueryEntity<PatientGroupsState> {
  constructor(protected store: PatientGroupsStore) {
    super(store);
  }
}
