import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { OverlayService } from '@services/overlay.service';

import { OverrideSettingsQuery } from '../override-settings/override-settings.query';
import { ActivityQuery } from '../activity/activity.query';
import { OverrideSettingsService } from '../override-settings/override-settings.service';

@Component({
  templateUrl: './forecast-manual-override-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastManualOverrideDialogComponent {
  fg = this.fb.group({
    units: [null, [Validators.required]],
    spread_type: 'Next Month',
  });

  override_id: string | null = null;

  loading$ = new BehaviorSubject(false);

  constructor(
    public ref: CustomOverlayRef<unknown, { sub_activity_id: string }>,
    private fb: UntypedFormBuilder,
    private overrideSettingsQuery: OverrideSettingsQuery,
    private overrideSettingsService: OverrideSettingsService,
    private activityQuery: ActivityQuery,
    private overlayService: OverlayService
  ) {
    const activity = this.activityQuery.getEntity(ref.data?.sub_activity_id as string);
    if (activity) {
      const overrideSetting = this.overrideSettingsQuery.getEntity(activity.forecast_override_id);
      if (overrideSetting) {
        this.override_id = overrideSetting.id;
        if (overrideSetting.expense_amounts && overrideSetting.expense_amounts.length) {
          const { units } = overrideSetting.expense_amounts[0];
          this.fg.get('units')?.setValue(units);
        }
      }
    }
  }

  async onSave() {
    if (this.fg.valid && !this.loading$.getValue()) {
      this.loading$.next(true);
      const { units, spread_type } = this.fg.value;
      const { success } = await this.overrideSettingsService.upsert({
        id: this.override_id,
        activity_id: this.ref.data?.sub_activity_id || '',
        expense_detail: JSON.stringify({ spread_type }),
        units,
      });
      if (success) {
        this.overlayService.success('Override successfully saved.');
        this.ref.close(true);
      }
      this.loading$.next(false);
    }
  }
}
