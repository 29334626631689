import { Component, HostBinding } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';
import { ComponentsModule } from '@components/components.module';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';

export interface WarningModalParams {
  header: string;
  message: string;
  cancelLabel: string;
  confirmLabel: string;
  maxWidth: number;
}

@Component({
  standalone: true,
  template: `
    <div class="pt-[24px] px-[16px] pb-[12px]">
      <div class="flex items-center justify-center">
        <aux-icon name="AlertTriangle" class="text-aux-red-dark" [size]="26" />
      </div>
      <div class="text-[24px] font-bold mt-[8px] text-center">
        {{ header }}
      </div>
      <div class="mt-[30px] text-center max-w-2xl">
        {{ message }}
      </div>
    </div>
    <div class="border-t flex items-center justify-between p-[16px]">
      <aux-button
        [label]="cancelLabel"
        (clickEmit)="onCancel()"
        variant="hyperlink"
        classList="text-aux-gray-darkest no-underline"
      />
      <aux-button [label]="confirmLabel" (clickEmit)="onConfirm()" variant="negative" />
    </div>
  `,
  imports: [IconComponent, ComponentsModule],
  styles: [
    `
      :host {
        @apply block bg-white;
      }
    `,
  ],
})
export class WarningModalComponent {
  header = '';
  message = '';

  cancelLabel = 'Cancel';
  confirmLabel = 'Confirm';

  @HostBinding('style.maxWidth.px')
  maxWidth: number | null = null;

  constructor(private ref: CustomOverlayRef<'cancel' | 'confirm', WarningModalParams>) {
    const data = this.ref.data;
    if (!data) {
      return;
    }
    this.header = data.header;
    this.message = data.message;
    this.cancelLabel = data.cancelLabel;
    this.confirmLabel = data.confirmLabel;
    this.maxWidth = data.maxWidth || null;
  }

  onCancel() {
    this.ref.close('cancel');
  }
  onConfirm() {
    this.ref.close('confirm');
  }
}
