import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';
import { TrialInsightsStore } from '../../store/trial-insights.store';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  templateUrl: './trial-insights-finance.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsFinanceComponent implements OnInit {
  trialLoading = false;

  componentsLoading = false;

  showInvestigatorSpendCard$: Observable<boolean>;

  constructor(
    private trialInsightsStore: TrialInsightsStore,
    private mainQuery: MainQuery,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.showInvestigatorSpendCard$ = this.launchDarklyService.select$(
      (flags) => flags.finance_dash_remaining_investigator_spend
    );
  }

  ngOnInit(): void {
    this.mainQuery
      .select('currentOpenMonth')
      .pipe(
        untilDestroyed(this),
        tap((currentOpenMonth) => {
          if (!currentOpenMonth) {
            return;
          }

          this.trialInsightsStore.update((state) => ({
            ...state,
            currentOpenMonth,
          }));
        })
      )
      .subscribe();
  }
}
