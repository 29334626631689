import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { SitePatientTrackerFlat } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface PatientTrackerSums extends Record<string, number> {
  sum_expense_amount: number;
  sum_visit_amount: number;
  sum_other_amount: number;
  sum_overhead_amount: number;
}

export interface PatientTrackerContractSums extends Record<string, number> {
  sum_expense_amount_contract: number;
  sum_visit_amount_contract: number;
  sum_other_amount_contract: number;
  sum_overhead_amount_contract: number;
}

export type PatientTrackerState = EntityState<
  Pick<
    RequireSome<Partial<SitePatientTrackerFlat>, 'id'>,
    'id' | 'create_date' | 'external_patient_id' | 'patient_id'
  > & {
    site_payment_schedule_ids: string[];
    sums_per_patient: Record<string, PatientTrackerSums & PatientTrackerContractSums>;
    source_refresh_date: string;

    sps_total_contract_expense_amount?: number;
    sps_total_expense_amount?: number;
  }
>;

export type PatientTrackerModel = getEntityType<PatientTrackerState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patient-tracker' })
export class PatientTrackerStore extends EntityStore<PatientTrackerState> {
  constructor() {
    super({
      patientGroupId: null,
    });
  }
}
