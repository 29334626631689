<div
  *ngVar="{
    isInvoiceFinalized: params.isInvoiceFinalized$ | async,
    invoiceLockTooltip: params.invoiceLockTooltip$ | async,
    iCloseMonthsProcessing: params.iCloseMonthsProcessing$ | async
  } as obj"
  class="flex items-center justify-center h-full"
>
  <div [auxTooltip]="obj?.invoiceLockTooltip || 'Upload'">
    <button
      class="p-0 w-7 h-7 flex justify-center items-center bg-aux-blue rounded-md ml-1"
      type="button"
      data-pendo-id="invoice-files-upload"
      [disabled]="obj.isInvoiceFinalized || obj.iCloseMonthsProcessing"
      (click)="onUploadClick()"
    >
      <aux-icon
        name="CloudUpload"
        [size]="18"
        class="text-white"
        [ngClass]="{ 'opacity-50': obj.isInvoiceFinalized || obj.iCloseMonthsProcessing }"
      />
    </button>
  </div>
  <button
    *ngIf="!params.hideDownloadButton"
    class="p-0 w-7 h-7 flex justify-center items-center rounded-md ml-1"
    type="button"
    data-pendo-id="invoice-files-download"
    [auxTooltip]="'Download'"
    [ngClass]="{
      'bg-aux-green': this.params.node.data.file,
      'bg-aux-gray-dark': !this.params.node.data.file
    }"
    [disabled]="params.disableDownloadButton || !this.params.node.data.file"
    (click)="onDownloadClick()"
  >
    <aux-icon
      name="Download"
      class="stroke-[1.4px]"
      [size]="18"
      [ngClass]="{
        'text-white': this.params.node.data.file,
        'text-aux-gray-darkest': !this.params.node.data.file,
        'opacity-50': !this.params.node.data.file
      }"
    />
  </button>

  <button
    class="p-0 w-7 h-7 flex justify-center items-center rounded-md mx-1"
    type="button"
    data-pendo-id="invoice-files-download-lines"
    [auxTooltip]="params.shouldEnableCSVDownload ? 'Download Line Item Details' : ''"
    [ngClass]="{
      'bg-aux-green': params.shouldEnableCSVDownload,
      'bg-aux-gray-dark': !params.shouldEnableCSVDownload
    }"
    [disabled]="!params.shouldEnableCSVDownload"
    (click)="onDownloadLinesClick()"
  >
    <aux-icon
      name="FileTypeCsv"
      [size]="18"
      [ngClass]="{
        'text-white': params.shouldEnableCSVDownload,
        'text-aux-gray-darkest': !params.shouldEnableCSVDownload,
        'opacity-50': !params.shouldEnableCSVDownload
      }"
    />
  </button>
</div>
