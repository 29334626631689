export interface TrialInsightsTableModel {
  route: string;
  subRoute: string;
  createTable?: () => TrialInsightsTableOptions;
}

export type TrialInsightsTableChangeKeyFn = {
  (buttonKey: string): void;
};

export type TrialInsightsTableToggleOrderFn = {
  (): void;
};

export type TrialInsightsTableOptions = {
  buttons: TrialInsightsTableButtonOptions;
  header: TrialInsightsTableHeaderOptions;
  rowData: TrialInsightsTableRowDataOptions;
  link: TrialInsightsTableLinkOptions;
};

export type TrialInsightsTableButtonOptions = {
  display: boolean;
  defaultButtonKey: string;
  data: TrialInsightsTableButtonData[];
};

export type TrialInsightsTableHeaderOptions = {
  display: boolean;
  data: TrialInsightsTableHeaderData[];
};

export type TrialInsightsTableRowDataOptions = {
  compact: boolean;
  data: TrialInsightsTableRowData[];
};

export type TrialInsightsTableLinkOptions = {
  display: boolean;
  url: string;
  value: string;
};

export type TrialInsightsTableButtonData = {
  key: string;
  value: string;
};

export type TrialInsightsTableHeaderData = {
  buttonKey: string;
  leftValue: string;
  rightValue: string;
};

export type TrialInsightsTableRowData = {
  buttonKey: string;
  leftHeader: string;
  leftSubheader: string;
  rightHeader: string;
  rightSubheader: string;
  color?: string;
};

export enum TrialInsightsPatientCostKey {
  SITE = 'site',
  GROUP = 'group',
}

export enum TrialInsightsPaymentMilestoneKey {
  MILESTONES = 'milestones',
}

export enum TrialInsightsRemainingSpendKey {
  REMAINING_SPEND = 'remainingSpend',
}

export enum TrialInsightInvestigatorCostKey {
  INVESTIGATOR = 'investigator',
}

export enum TrialInsightsScreenFailKey {
  NUMBER = 'number',
  PERCENT = 'percent',
}

export enum TrialInsightsSiteCostKey {
  SPEND = 'spend',
  LAG = 'lag',
}

export enum TrialInsightsBvaChartKey {
  CHART = 'chart',
}

export enum TrialInsightPatientEnrolledKey {
  PATIENT_ENROLLED = 'patientEnrolled',
}
