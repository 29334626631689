import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ApprovalState } from './approval.model';
import { ApprovalStore } from './approval.store';

@Injectable({ providedIn: 'root' })
export class ApprovalQuery extends Query<ApprovalState> {
  constructor(protected store: ApprovalStore) {
    super(store);
  }
}
