import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { Utils } from '@services/utils';
import { AuxIconName } from '@components/icon/icon';

@Component({
  template: `
    <div class="flex justify-end items-center whitespace-nowrap" [attr.auto-qa]="autoTestAttribute">
      <div>{{ value !== 0 ? value : '—' }}</div>
      <aux-icon
        [name]="icon"
        *ngIf="icon"
        class="ml-1.5 flex-shrink-0"
        [ngClass]="colorClass"
        [size]="16"
      />
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VariationStatusComponent
  extends AgCellWrapperComponent
  implements ICellRendererAngularComp
{
  icon!: AuxIconName;

  value!: string | number;

  colorClass!: string;

  textClass!: string;

  agInit(
    params: ICellRendererParams & {
      reverse?: boolean;
    }
  ): void {
    const variation = params.value || 0;

    let showUp = params.reverse ? variation < 0 : variation > 0;
    let showDown = params.reverse ? variation > 0 : variation < 0;

    const isDiscountPercent = !!(
      params.data &&
      params.data.cost_category === 'Discount' &&
      params.colDef?.field?.includes('::VAR_PERC::SNAPSHOT') &&
      params.valueFormatted !== Utils.zeroHyphen
    );
    if (isDiscountPercent) {
      showUp = !showUp;
      showDown = !showDown;
    }

    if (showUp) {
      this.colorClass = 'text-aux-red-dark';
      this.icon = 'CircleArrowUp';
    } else if (showDown) {
      this.colorClass = 'text-aux-green-dark';
      this.icon = 'CircleArrowDown';
    }

    this.value = params.formatValue ? params.formatValue(Math.abs(variation)) : Math.abs(variation);

    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }
}
