import { Component, OnInit, ChangeDetectionStrategy, HostListener, OnDestroy } from '@angular/core';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { ExportType, Utils } from '@services/utils';
import { TrialUserQuery } from '@models/trial-users/trial-user.query';
import { BehaviorSubject, combineLatest, firstValueFrom } from 'rxjs';
import {
  EntityType,
  EventType,
  GqlService,
  listPermissionsQuery,
  PermissionType,
} from '@services/gql.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@models/auth/auth.service';
import { TrialUserService } from '@models/trial-users/trial-user.service';
import { MainQuery } from '../../../layouts/main-layout/state/main.query';
import * as dayjs from 'dayjs';
import { OverlayService } from '@services/overlay.service';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import { ColDef, ColGroupDef, GridOptions, IRowNode } from '@ag-grid-community/core';
import { TableConstants } from '@constants/table.constants';
import { AgTooltipComponent } from '../../budget-page/tabs/budget-enhanced/inline-budget/ag-tooltip.component';
import {
  AgBudgetAttributeComponentParams,
  AgBudgetEnhancedGroupHeaderComponent,
} from '../../budget-page/tabs/budget-enhanced/ag-budget-enhanced-group-header.component';
import { LocalStorageKey } from '@shared/constants';
import { AgGroupCheckboxComponent, ICheckboxCellParams } from './ag-group-checkbox.component';
import { ComponentsModule } from '@components/components.module';
import { CommonModule } from '@angular/common';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { FormsModule } from '@angular/forms';
import { StickyElementService } from '@services/sticky-element.service';
import { AuthQuery } from '@models/auth/auth.query';
import { distinctUntilArrayItemChanged } from '@ngneat/elf';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';

dayjs.extend(utc);
dayjs.extend(timezone);

type UserPermissionsGridData = listPermissionsQuery & {
  [k: string]: boolean;
};

@UntilDestroy()
@Component({
  selector: 'aux-user-permissions',
  template: `
    <div class="flex items-center justify-between mb-[20px]">
      <div class="font-semibold text-[20px]">User Permissions</div>
      <div class="flex justify-end">
        <aux-button
          variant="secondary"
          icon="FileExport"
          [onClick]="this.onExport.bind(this)"
          label="Export"
          [spinnerSize]="7"
          classList="ml-1 btn"
        />
      </div>
    </div>

    <ng-container
      *ngIf="{
        loading: loading$ | async,
        gridData: gridData$ | async,
        gridOptions: gridOptions$ | async
      } as obj"
    >
      <ng-container *ngIf="obj.gridData?.length && obj.loading === false && obj.gridOptions">
        <ag-grid-angular
          domLayout="autoHeight"
          class="ag-theme-aux tabular-nums user-permissions-table mb-16"
          [gridOptions]="obj.gridOptions"
          [rowData]="obj.gridData"
          (viewportChanged)="onWindowScroll()"
        />
      </ng-container>
    </ng-container>

    <!-- Save changes -->
    <aux-save-changes
      *ngIf="changedValues.length"
      [onSaveChanges]="onSaveChanges"
      [showDiscardChangesBtn]="true"
      (cancel)="onDiscardChanges()"
    />
  `,
  styles: [
    `
      :host ::ng-deep .user-permissions-table .ag-row-odd:not(.custom-group-background) .ag-cell {
        @apply !bg-white;
      }

      :host ::ng-deep .user-permissions-table .custom-group-background .ag-cell {
        @apply !bg-[#F8F9FA] text-[16px] font-semibold;
      }

      :host ::ng-deep .user-permissions-table .custom-group-icon {
        --ag-icon-size: 24px;
      }

      :host ::ng-deep .user-permissions-table .permission-header ng-component {
        @apply justify-start;
      }

      :host ::ng-deep .user-permissions-table .ag-row-group-indent-2 {
        @apply ml-[36px];
      }

      :host ::ng-deep .user-permissions-table .ag-row-group-indent-1 .ag-group-expanded,
      :host ::ng-deep .user-permissions-table .ag-row-group-indent-1 .ag-group-contracted {
        @apply ml-[16px];
      }

      :host ::ng-deep .user-permissions-table .ag-row-level-1 .ag-row-group-leaf-indent {
        @apply ml-[19px];
      }

      :host ::ng-deep .ag-theme-aux .ag-header-group-cell-no-group:hover {
        background-color: var(--aux-blue-dark) !important;
      }

      :host ::ng-deep .ag-theme-aux .ag-header-row,
      :host ::ng-deep .ag-theme-aux .ag-header-cell {
        overflow: unset;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // todo change AgGridModule to AgGridAngular with the new AgGrid update
  imports: [ComponentsModule, CommonModule, AgGridModule, InputComponent, FormsModule],
  standalone: true,
})
export class UserPermissionsComponent implements OnInit, OnDestroy {
  permissions$ = new BehaviorSubject<listPermissionsQuery[]>([]);

  isSysAdmin$ = this.authService.isAuthorized$({
    sysAdminsOnly: true,
  });

  loggedInUserHasAdministration$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_UPDATE_USER_PERMISSIONS],
  });

  reset$ = new BehaviorSubject(null);

  matrixUsers$ = this.trialUserQuery
    .selectAll()
    .pipe(
      map((users) => {
        return users
          .filter((user) => {
            // todo: remove this when we get superAdmin flag
            return user.email ? !user.email.includes('@auxili.us') : true;
          })
          .sort((x, y) => {
            return Utils.alphaNumSort(
              `${x.given_name} ${x.family_name}` as string,
              `${y.given_name} ${y.family_name}` as string
            );
          });
      })
    )
    .pipe(distinctUntilArrayItemChanged());

  gridData$ = new BehaviorSubject<UserPermissionsGridData[]>([]);

  loading$ = new BehaviorSubject(true);

  autoGroupColumnDef: ColDef = {
    headerName: 'Permission',
    headerClass: 'permission-header',
    headerComponent: AgBudgetEnhancedGroupHeaderComponent,
    headerComponentParams: {
      columnsToCollapse: [] as string[],
      expandLevel: () => 2,
      template: `Permission`,
      localStorageKey: LocalStorageKey.USER_PERMISSION_STORAGE,
      alignHeaderOneRowHeight: true,
      templateClasses: ['text-[16px] font-semibold'],
      quickFilterText: true,
    } as AgBudgetAttributeComponentParams,
    cellRendererParams: {
      suppressCount: true,
    },
    minWidth: 400,
    width: 400,
    field: 'permission',
    cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT, 'custom-group-icon'],
    pinned: 'left',
    resizable: true,
  };

  gridOptions: GridOptions<UserPermissionsGridData> = {
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    groupDefaultExpanded: -1,
    defaultColDef: {
      sortable: false,
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      tooltipComponent: AgTooltipComponent,
    },
    suppressPropertyNamesCheck: true,
    autoGroupColumnDef: this.autoGroupColumnDef,
    groupAllowUnbalanced: true,
    tooltipShowDelay: 0,
    suppressColumnVirtualisation: true,
    suppressCellFocus: true,
    suppressMenuHide: true,
    suppressRowClickSelection: true,
    initialGroupOrderComparator: ({ nodeA, nodeB }) => {
      if (nodeA.key === 'Checklist') {
        return -1;
      }
      if (nodeB.key === 'Checklist') {
        return 1;
      }
      if (nodeA.key === 'Invoices') {
        return -1;
      }
      if (nodeB.key === 'Invoices') {
        return 1;
      }
      return 0;
    },
    rowClassRules: {
      'custom-group-background': (params) => !!params.node.group && params.node.level === 0,
    },
    getRowHeight: (params) => {
      if (params.node.group && params.node.level === 0) {
        return 50;
      }
      return 35;
    },
  };

  gridOptions$ = new BehaviorSubject<GridOptions<UserPermissionsGridData>>(this.gridOptions);

  defaultColumns: ColDef[] = [
    {
      field: 'permission',
      hide: true,
    },
    {
      field: 'permission_group',
      rowGroup: true,
      hide: true,
    },
    {
      field: 'permission_sub_group',
      rowGroup: true,
      hide: true,
    },
  ];

  changedValues: {
    sub: string;
    permission: string;
    value: boolean;
  }[] = [];

  constructor(
    private trialUserQuery: TrialUserQuery,
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private authQuery: AuthQuery,
    private authService: AuthService,
    private trialUserService: TrialUserService,
    private overlayService: OverlayService,
    private stickyElementService: StickyElementService
  ) {
    combineLatest([
      this.gridData$,
      this.matrixUsers$,
      this.isSysAdmin$,
      this.loggedInUserHasAdministration$,
    ])
      .pipe(debounceTime(0), untilDestroyed(this))
      .subscribe(([data, users, isSysAdmin, loggedInUserHasAdministration]) => {
        this.loading$.next(true);

        const userSub = this.authQuery.getValue().sub;
        const columnDefs: (
          | ColDef<UserPermissionsGridData>
          | ColGroupDef<UserPermissionsGridData>
        )[] = [...this.defaultColumns];
        this.changedValues = [];
        if (users.length && data.length) {
          columnDefs.push({
            headerName: 'User',
            headerClass: TableConstants.STYLE_CLASSES.HEADER_ALIGN_CENTER,
            children: users.map((user) => {
              return {
                headerName: `${user.given_name} ${user.family_name?.[0]?.toUpperCase()}.`,
                field: user.sub,
                headerClass: TableConstants.STYLE_CLASSES.HEADER_ALIGN_CENTER,
                cellClass: [
                  TableConstants.STYLE_CLASSES.CELL_VERTICAL_HORIZONTAL_ALIGN_CENTER,
                  '!flex',
                ],
                cellRenderer: AgGroupCheckboxComponent,
                cellRendererParams: <ICheckboxCellParams>{
                  isEditable: (node, field) => {
                    if (isSysAdmin) {
                      return true;
                    }

                    if (!loggedInUserHasAdministration) {
                      return false;
                    }

                    if (
                      node?.data?.permission_type ===
                      PermissionType.PERMISSION_UPDATE_USER_PERMISSIONS
                    ) {
                      return false;
                    }
                    return field !== userSub;
                  },
                  checkboxTooltip: () => {
                    return '';
                  },
                  onChange: (node: IRowNode<UserPermissionsGridData>, field, value) => {
                    if (node.data) {
                      const permission = node.data.id;
                      const index = this.changedValues.findIndex(
                        (v) => v.sub === field && v.permission === permission
                      );
                      const obj = {
                        permission,
                        value,
                        sub: field,
                      };
                      if (index !== -1) {
                        this.changedValues.splice(index, 1);
                      } else {
                        this.changedValues.push(obj);
                      }
                    }
                  },
                },
              };
            }),
          });
        }
        this.gridOptions$.next(<GridOptions<UserPermissionsGridData>>{
          ...this.gridOptions,
          columnDefs,
        });

        setTimeout(() => {
          this.loading$.next(false);
        }, 0);
      });
  }

  ngOnInit(): void {
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(() => this.gqlService.listPermissions$()),
        untilDestroyed(this)
      )
      .subscribe((x) => {
        const permissions: listPermissionsQuery[] = [];
        const administration_permissions: listPermissionsQuery[] = [];

        x.data?.forEach((x) => {
          if (x.permission_group === 'Administration') {
            administration_permissions.push(x);
          } else {
            permissions.push(x);
          }
        });

        this.permissions$.next([...permissions, ...administration_permissions]);
      });

    combineLatest([this.permissions$, this.matrixUsers$, this.reset$])
      .pipe(
        map(([permissions, users]) => {
          this.gridData$.next(
            users.length
              ? permissions.map((permission) => {
                  const userPerms = users.reduce(
                    (acc, user) => {
                      acc[user.sub] = user.permissions[permission.id] === 'E';
                      return acc;
                    },
                    {} as Record<string, boolean>
                  );

                  return <UserPermissionsGridData>{
                    ...permission,
                    permission_group: permission.permission_group || '',
                    permission_sub_group: permission.permission_sub_group || '',
                    ...userPerms,
                  };
                })
              : []
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  async canDeactivate(): Promise<boolean> {
    if (this.changedValues.length) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);

      return !!event.data;
    }

    return true;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  async onExport() {
    const trialShortName = this.mainQuery.getSelectedTrial()?.short_name || '';
    const trialId = this.mainQuery.getSelectedTrial()?.id || '';
    const fileTimestamp = dayjs().format('YYYY.MM.DD-HHmmss');
    const currentTz = dayjs.tz.guess();

    const { success, errors } = await firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.GENERATE_EXPORT,
        entity_type: EntityType.TRIAL,
        entity_id: trialId,
        payload: JSON.stringify({
          export_type: ExportType.PERMISSIONS,
          filename: `auxilius_${trialShortName}_User_Permissions_${fileTimestamp}`,
          export_entity_id: trialId || '',
          json_properties: {
            fileTimestamp: `${fileTimestamp} ${currentTz}`,
          },
        }),
      })
    );
    if (success) {
      this.overlayService.success(
        'Export is being generated and will download when complete. You may leave the page.'
      );
    } else {
      this.overlayService.error(errors);
    }
  }

  onDiscardChanges() {
    this.reset$.next(null);
  }

  onSaveChanges = async () => {
    const ref = this.overlayService.loading();
    await this.trialUserService.updatePermissions(this.changedValues);
    ref.close();
  };
}
