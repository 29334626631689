import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ScriptLoaderService } from '@services/script-loader.service';
import { MessagesConstants } from '@constants/messages.constants';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { IdleService } from '@services/idle.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './system-maintenance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemMaintenanceComponent {
  readonly messagesConstants = MessagesConstants;

  messages$: Observable<{ message: string; timestamp: string }[]> = this.launchDarklyService
    .select$((flags) => flags.nav_system_maintenance_with_messages)
    .pipe(
      map((v) => {
        if (v.messages) {
          return v.messages;
        } else {
          this.router.navigate(['/']);
        }

        return [];
      })
    );

  constructor(
    private scriptLoaderService: ScriptLoaderService,
    private launchDarklyService: LaunchDarklyService,
    private router: Router,
    // this is for injecting the window functions to app,
    // don't remove it
    private idleService: IdleService
  ) {
    this.scriptLoaderService.injectJiraWidget();
  }
}
