import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ICellRendererParams } from '@ag-grid-community/core';

export interface AgInputComponentParams extends ICellRendererParams {
  formGroup: UntypedFormGroup;
  changeHandler: () => void;
}

@Component({
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgControlComponent implements ICellRendererAngularComp {
  params!: AgInputComponentParams;

  formGroup$ = new BehaviorSubject<UntypedFormGroup | null>(null);

  formControl!: UntypedFormControl;

  isReady$ = new BehaviorSubject(false);

  protected value!: string;

  fieldName?: string;

  changeHandler: () => void = () => {};

  agInit(params: AgInputComponentParams) {
    this.value = params.value;
    this.fieldName = params.colDef?.field;
    this.changeHandler = params.changeHandler;

    this.updateView(params);
  }

  protected updateView(params: ICellRendererParams) {
    const documentId = params?.data?.id;

    if (this.fieldName && params.context?.formGroup?.controls?.table) {
      this.formGroup$.next(params.context.formGroup);

      const tableControls = params.context.formGroup.controls.table.controls;
      const rowControl = tableControls.find(
        (control: UntypedFormGroup) => control.value.id === documentId
      );

      if (tableControls && rowControl) {
        this.formControl = rowControl.controls[this.fieldName];
        this.isReady$.next(true);
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    this.updateView(params);

    return true;
  }
}
