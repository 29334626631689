import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CellClickedEvent, ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { NgClass } from '@angular/common';
import { OrganizationQuery } from '@models/organization/organization.query';

interface GroupCellToggleRendererParams extends ICellRendererParams {
  onLinkClickFn?: (params: ICellRendererParams | CellClickedEvent) => void;
  query?: OrganizationQuery;
}

@Component({
  templateUrl: 'group-cell-toggle-renderer.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class GroupCellToggleRendererComponent implements ICellRendererAngularComp {
  params!: GroupCellToggleRendererParams;

  refresh(): boolean {
    return false;
  }

  onLinkClick(): void {
    if (this.params && this.params.onLinkClickFn) {
      this.params.onLinkClickFn(this.params);
    }
  }

  agInit(params: GroupCellToggleRendererParams): void {
    this.params = params;
  }

  get text(): string {
    return this.params.query?.getEntity(this.params.value)?.name || '';
  }
}
