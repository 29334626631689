import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from '@ag-grid-community/core';
import { groupBy, isEqual } from 'lodash-es';
import { combineLatest, EMPTY, firstValueFrom, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OverlayService } from '@services/overlay.service';
import {
  ActivityType,
  AmountType,
  BudgetType,
  Currency,
  DiscountType,
  DriverType,
  EntityType,
  EventType,
  ForecastMethodType,
  GqlService,
} from '@services/gql.service';
import {
  CategoryQuery,
  FullActivity,
  FullCategory,
  FullSettings,
} from '../category/category.query';
import { ActivityQuery } from '../activity/activity.query';
import { CategoryService } from '../category/category.service';
import { ForecastSettingsQuery } from '../settings/forecast-settings.query';
import { ForecastSettingsService } from '../settings/forecast-settings.service';
import { ForecastSettingsStore } from '../settings/forecast-settings.store';
import { ForecastTableGridCategoryToggleService } from './services/forecast-table-grid-category-toggle.service';
import { ForecastTableGridCategoryCheckService } from './services/forecast-table-grid-category-check.service';
import { ForecastTableGridService } from './services/forecast-table-grid.service';
import { ForecastTableGridDriverService } from './services/forecast-table-grid-driver.service';
import { ForecastTableGridMethodService } from './services/forecast-table-grid-method.service';
import { ForecastTableGridPeriodService } from './services/forecast-table-grid-period.service';
import { ForecastTableGridPeriodTimeModalComponent } from './components/forecast-table-grid-period-time-modal/forecast-table-grid-period-time-modal.component';
import {
  ForecastTableGridDataInterface,
  ForecastTableGridDriverSelectMode,
  ForecastTableGridExpenses,
  ForecastTableGridMethodSelectMode,
  ForecastTableGridParentComponentContext,
  ForecastTableGridSaveChange,
  ForecastTableGridSiteCurveInterface,
  ForecastTableGridTimelineMilestoneInterface,
  ForecastTableGridTimelinePhaseInterface,
  ForecastTableTimelineOverlayData,
  ForecastTableTimelineOverlayResponse,
} from './models/forecast-table-grid.model';
import { ForecastTableGridColumnsService } from './services/forecast-table-grid-columns.service';
import { ForecastTableGridCategoryLoggingService } from './services/forecast-table-grid-category-loading.service';
import { Utils } from '@services/utils';
import { StickyElementService } from '@services/sticky-element.service';
import { batchPromises } from '@shared/utils';
import { EditableListDropdownItem } from '@components/editable-list-dropdown/editable-list-dropdown-item.model';
import { ForecastTableGridCategoryService } from './services/forecast-table-grid-category.service';

@UntilDestroy()
@Component({
  selector: 'aux-forecast-table-grid',
  templateUrl: './forecast-table-grid.component.html',
  styleUrls: ['./forecast-table-grid.component.scss'],
})
export class ForecastTableGridComponent implements OnInit, OnDestroy {
  @Input() isForecastFinalized = false;

  @Input() isClosedMonthsProcessing = false;

  @Input() selectedVendor!: FormControl;

  @Input() saveCheck = false;

  @Input() isPatientDriverAvailable = false;

  @Input() isSiteDriverAvailable = false;

  @Input() isPatientDriversLinkVisible = false;

  @Input() isSiteDriversLinkVisible = false;

  @Input() successForecast = false;

  @Input() changeDiscountTotalAmount!: FormControl;

  @Input() changeDiscountTotalPercent!: FormControl;

  @Output() emitSaveCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() emitIsPatientDriversLinkVisible: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() emitIsSiteDriversLinkVisible: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() emitHideDiscounts: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() emitSuccessForecast: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() emitChangeDiscountTotalAmount: EventEmitter<number> = new EventEmitter<number>();

  @Output() emitChangeDiscountTotalPercent: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  emitChangeDiscountTotalAmountEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  emitChangeDiscountTotalPercentEnable: EventEmitter<boolean> = new EventEmitter<boolean>();

  gridOptions: GridOptions;

  gridApi?: GridApi;

  columnDefs: ColDef[];

  gridHeight = '400px';

  context: ForecastTableGridParentComponentContext;

  initialSettings: Record<string, FullSettings | undefined> = {};

  defaultSubSettings = new Map<string, FullSettings>();

  edits = new Set<string>();

  DriverType = DriverType;

  userHasModifyPermissions = false;

  rowData: ForecastTableGridDataInterface[] = [];

  groupRowCount = 0;

  cloneRowData: ForecastTableGridDataInterface[] = [];

  siteCurves: ForecastTableGridSiteCurveInterface[] = [];

  patientCurves: EditableListDropdownItem[] = [];

  timelinePhases: ForecastTableGridTimelinePhaseInterface[] = [];

  timelineMilestones: ForecastTableGridTimelineMilestoneInterface[] = [];

  subscription!: Subscription;

  budgetId = '';

  discountableTotal = 0;

  expensesAmount: ForecastTableGridExpenses = { amount: 0, amountPercent: 0 };

  discountTypes = [
    { id: DiscountType.DISCOUNT_PERCENTAGE, label: '% of Relevant Services' },
    { id: DiscountType.DISCOUNT_TOTAL, label: '$ of Relevant Services' },
  ];

  selectedDiscountType = new FormControl({
    id: DiscountType.DISCOUNT_PERCENTAGE,
    label: '% of Relevant Services',
  });

  constructor(
    public GridService: ForecastTableGridService,
    private ColumnsService: ForecastTableGridColumnsService,
    public ToggleService: ForecastTableGridCategoryToggleService,
    public LoadingService: ForecastTableGridCategoryLoggingService,
    public CheckService: ForecastTableGridCategoryCheckService,
    public DriverService: ForecastTableGridDriverService,
    public MethodService: ForecastTableGridMethodService,
    public PeriodService: ForecastTableGridPeriodService,
    public organizationQuery: OrganizationQuery,
    private activityQuery: ActivityQuery,
    private categoryService: CategoryService,
    private categoryQuery: CategoryQuery,
    private forecastSettingsQuery: ForecastSettingsQuery,
    private forecastSettingsService: ForecastSettingsService,
    private forecastSettingsStore: ForecastSettingsStore,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private stickyElementService: StickyElementService,
    private forecastTableGridCategoryService: ForecastTableGridCategoryService
  ) {
    // Allow AG Grid components to access this component
    this.context = {
      componentParent: this,
    };

    // Grid options
    this.gridOptions = this.ColumnsService.gridOptions;

    // Grid definitions
    const currentOrgCurrency = this.organizationQuery.getActive()?.currency || Currency.USD;
    this.columnDefs = this.ColumnsService.gridColumns(currentOrgCurrency);
  }

  ngOnInit(): void {
    // Get row data
    combineLatest([
      this.GridService.getRowData(),
      this.forecastTableGridCategoryService.getActivities(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([[serviceCategories, costCategories], activities]) => {
        this.rowData = [...activities, ...serviceCategories, ...costCategories];
        const a = groupBy(this.rowData, 'costCategoryType');
        this.groupRowCount = Object.keys(a).length;
        if (
          this.cloneRowData.length === 0 ||
          this.cloneRowData?.[0]?.id !== this.rowData?.[0]?.id
        ) {
          this.cloneRowData = Utils.clone(this.rowData);
        }
        this.onRowResize();
      });

    // Get site curves
    this.GridService.getSiteCurves()
      .pipe(untilDestroyed(this))
      .subscribe((siteCurves) => {
        this.siteCurves = siteCurves;
      });

    // Get patient curves
    this.GridService.getPatientCurves()
      .pipe(untilDestroyed(this))
      .subscribe((patientCurves) => {
        this.patientCurves = patientCurves;
      });

    // Get timeline items
    this.GridService.getTimelineItems()
      .pipe(untilDestroyed(this))
      .subscribe(([timelinePhases, timelineMilestones]) => {
        this.timelinePhases = timelinePhases;
        this.timelineMilestones = timelineMilestones;
      });

    // Get user auth
    this.GridService.getAuthorization()
      .pipe(untilDestroyed(this))
      .subscribe((userHasModifyPermissions) => {
        this.userHasModifyPermissions = userHasModifyPermissions;
        this.gridApi?.refreshCells();
      });

    // Get budget
    this.getListBudgetVersionExpenses();

    // Listen for discount changes
    this.selectedDiscountType.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.processDiscountTypeChanges(value);
    });

    // Listen for change discount changes
    this.GridService.getChangeDiscountChanges(
      this.changeDiscountTotalAmount.valueChanges,
      this.changeDiscountTotalPercent.valueChanges
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.processChangeDiscountChanges();
      });
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridApi = api;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }

  onRowResize(): void {
    const defaultHeaderHeight = this.ColumnsService.gridOptions.headerHeight || 45;
    const defaultRowHeight = this.ColumnsService.gridOptions.rowHeight || 55;
    const defaultPadding = 10;
    const defaultTableHeight = 400;

    let newTableHeight =
      this.rowData.length * defaultRowHeight + defaultHeaderHeight + defaultPadding;

    newTableHeight += this.groupRowCount * defaultRowHeight;

    const newRowHeight = newTableHeight > defaultTableHeight ? newTableHeight : defaultTableHeight;

    this.gridHeight = `${newRowHeight}px`;
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  };

  setInitialSettings(id: string): void {
    if (!this.initialSettings[id]) {
      this.initialSettings[id] = this.forecastSettingsQuery.getEntity(id) as FullSettings;
    }
  }

  processDiscountTypeChanges(value: typeof this.selectedDiscountType.value): void {
    if (value?.id === DiscountType.DISCOUNT_TOTAL) {
      this.emitChangeDiscountTotalPercent.emit(this.expensesAmount.amountPercent);
      this.emitChangeDiscountTotalPercentEnable.emit(false);
      this.emitChangeDiscountTotalAmountEnable.emit(true);
    } else if (value?.id === DiscountType.DISCOUNT_PERCENTAGE) {
      this.emitChangeDiscountTotalAmount.emit(this.expensesAmount.amount);
      this.emitChangeDiscountTotalAmountEnable.emit(false);
      this.emitChangeDiscountTotalPercentEnable.emit(true);
    }
  }

  processChangeDiscountChanges(): void {
    if (
      this.expensesAmount.amount !== this.changeDiscountTotalAmount.value ||
      this.expensesAmount.amountPercent !== this.changeDiscountTotalPercent.value
    ) {
      this.emitSaveCheck.emit(true);
    }
  }

  checkChanges(primarySettingsId: string): void {
    const hasNoChanges = isEqual(
      this.initialSettings[primarySettingsId],
      this.forecastSettingsQuery.getEntity(primarySettingsId)
    );

    if (hasNoChanges) {
      this.edits.delete(primarySettingsId);
    }

    this.emitSaveCheck.emit(!!this.edits.size);
  }

  toggleCategoryApplyAll(
    checked: boolean,
    primarySettingsId: string,
    primarySettingsOverride: boolean,
    categoryId: string
  ): void {
    const ids = this.CheckService.getAllSubSettingIDs(categoryId);
    const isCategoryIndeterminate = this.CheckService.isCategoryIndeterminate(categoryId);

    this.setInitialSettings(primarySettingsId);

    ids.forEach((id) => {
      this.edits.add(id);
    });

    this.edits.add(primarySettingsId);

    if (checked || isCategoryIndeterminate) {
      ids.forEach((id) => {
        this.edits.delete(id);
      });
    }
    if (!this.defaultSubSettings.has(primarySettingsId)) {
      const settings = this.forecastSettingsQuery.getEntity(primarySettingsId);
      this.defaultSubSettings.set(primarySettingsId, settings as FullSettings);
    }

    this.emitSaveCheck.emit(true);

    const defaultValues = {
      forecast_method: null,
      driver_setting_id: null,
      period_end_date: null,
      period_start_date: null,
      period_start_milestone_id: null,
      period_end_milestone_id: null,
      driver: null,
      milestone_category: null,
    };

    this.forecastSettingsService.update(primarySettingsId, {
      override: !primarySettingsOverride,
      ...defaultValues,
    });

    this.forecastSettingsStore.update(ids, {
      ...defaultValues,
    });

    this.forecastSettingsStore.ui.update(primarySettingsId, {
      unforecasted: false,
    });

    this.afterCategoryStateChange(categoryId);
  }

  toggleActivityApplyAll(
    checked: boolean,
    activity: FullActivity,
    parentCategory: FullCategory
  ): void {
    const parentCategorySettings = parentCategory.primary_settings;
    const activitySettings = activity.primary_settings;

    const toggleSettings = (toggleChecked: boolean) => {
      return toggleChecked
        ? {
            id: activitySettings.id,
            override: !activitySettings.override,
            forecast_method: parentCategorySettings.forecast_method,
            driver: parentCategorySettings.driver,
            driver_setting_id: parentCategorySettings.driver_setting_id,
            period_start_milestone_id: parentCategorySettings.period_start_milestone_id,
            period_end_milestone_id: parentCategorySettings.period_end_milestone_id,
            milestone_category: parentCategorySettings.milestone_category?.id,
            period_start_date: parentCategorySettings.period_start_date,
            period_end_date: parentCategorySettings.period_end_date,
          }
        : {
            id: activitySettings.id,
            override: !activitySettings.override,
            forecast_method: null,
            driver: null,
            driver_setting_id: null,
            period_start_milestone_id: null,
            period_end_milestone_id: null,
            milestone_category: null,
            period_start_date: null,
            period_end_date: null,
          };
    };
    if (
      !this.defaultSubSettings.has(activitySettings.id) &&
      !this.cloneRowData.some((x) => x.id === activitySettings.id)
    ) {
      this.defaultSubSettings.set(
        activitySettings.id,
        activitySettings.driver_setting_id
          ? activitySettings
          : ({
              ...toggleSettings(!checked),
              override: activitySettings.override,
            } as FullSettings)
      );
    }
    this.edits.add(activitySettings.id);
    this.setInitialSettings(activitySettings.id);
    this.emitSaveCheck.emit(true);

    this.forecastSettingsService.update(activitySettings.id, toggleSettings(checked));

    this.forecastSettingsStore.ui.update(activitySettings.id, {
      unforecasted: false,
    });

    const overrideCount = !activitySettings.override ? 1 : -1;
    this.categoryService.updateCategoryOverride(activity.category_id, overrideCount);

    this.afterActivityStateChange(activity.id);
  }

  onDriverChange(
    driverType: DriverType | null,
    settings: FullSettings,
    mode: ForecastTableGridDriverSelectMode
  ): void {
    if (
      !this.defaultSubSettings.has(settings.id) &&
      !this.cloneRowData.some((x) => x.id === settings.id)
    ) {
      const subSettings = this.forecastSettingsQuery.getEntity(settings.id);
      this.defaultSubSettings.set(settings.id, subSettings as FullSettings);
    }
    this.edits.add(settings.id);
    this.setInitialSettings(settings.id);
    this.emitSaveCheck.emit(true);

    // Driver exists && (Driver site || Driver patient || Driver blended)
    if (
      driverType &&
      (driverType === DriverType.DRIVER_SITE ||
        driverType === DriverType.DRIVER_PATIENT ||
        driverType === DriverType.DRIVER_SERVICES_BLENDED)
    ) {
      // Driver site and available || Driver patient and available || Driver services blended
      if (
        (driverType === DriverType.DRIVER_SITE && !this.isSiteDriverAvailable) ||
        (driverType === DriverType.DRIVER_PATIENT && !this.isPatientDriverAvailable) ||
        driverType === DriverType.DRIVER_SERVICES_BLENDED
      ) {
        this.forecastSettingsStore.update(settings.id, {
          driver: driverType,
        });

        if (driverType === DriverType.DRIVER_SITE) {
          this.forecastSettingsStore.ui.update(settings.id, () => ({
            showError: true,
            errorMessage: 'Site Driver has not been added',
          }));
        } else if (driverType === DriverType.DRIVER_PATIENT) {
          this.forecastSettingsStore.ui.update(settings.id, () => ({
            showError: true,
            errorMessage: 'Patient Driver has not been added',
          }));
        }
      } else {
        // Anything else
        this.forecastSettingsStore.update(settings.id, {
          driver: driverType,
          driver_setting_id: null,
          forecast_method: null,
          period_start_milestone_id: null,
          period_end_milestone_id: null,
          period_start_date: null,
          period_end_date: null,
        });

        this.forecastSettingsStore.ui.update(settings.id, () => ({
          showError: false,
          errorMessage: '',
        }));
      }
    } else {
      // Anything else
      this.forecastSettingsStore.update(settings.id, {
        driver: driverType,
        forecast_method: settings.forecast_method || ForecastMethodType.FORECAST_STRAIGHTLINE,
      });

      this.forecastSettingsStore.ui.update(settings.id, () => ({
        showError: false,
        errorMessage: '',
      }));
    }

    const uiForecast = this.forecastSettingsStore.ui.getValue();

    const isThereAnyOtherError = uiForecast?.ids?.some((id) => {
      return !!uiForecast?.entities?.[id]?.showError;
    });

    if (!isThereAnyOtherError && !this.successForecast) {
      this.emitSuccessForecast.emit(true);
    }

    if (mode.name === 'activity') {
      this.afterActivityStateChange(mode.id);
    } else {
      this.afterCategoryStateChange(mode.id);
    }
  }

  // Function type expression: ForecastTableGridMethodChange
  onMethodChange = (
    methodType: ForecastMethodType | string | null,
    primarySettingsId: string,
    mode: ForecastTableGridMethodSelectMode
  ): void => {
    this.edits.add(primarySettingsId);
    this.setInitialSettings(primarySettingsId);
    this.emitSaveCheck.emit(true);

    if (mode.prop === 'driver_setting') {
      this.forecastSettingsStore.update(primarySettingsId, {
        driver_setting_id: methodType as string,
      });
    } else {
      this.forecastSettingsStore.update(primarySettingsId, {
        forecast_method: methodType as ForecastMethodType,
      });
    }

    if (mode.name === 'activity') {
      this.afterActivityStateChange(mode.id);
    } else {
      this.afterCategoryStateChange(mode.id);
    }
  };

  // Function type expression: ForecastTableGridPeriodChange
  onPeriodChange = (settings: FullSettings, mode: ForecastTableGridDriverSelectMode): void => {
    const ref = this.overlayService.open<
      ForecastTableTimelineOverlayResponse | null,
      ForecastTableTimelineOverlayData
    >({
      content: ForecastTableGridPeriodTimeModalComponent,
      data: {
        settings,
        timelinePhases: this.timelinePhases,
        timelineMilestones: this.timelineMilestones,
      },
    });

    ref.afterClosed$.subscribe((overlayResponse) => {
      if (overlayResponse.data) {
        const { phaseId, startMilestoneId, endMilestoneId, startDate, endDate } =
          overlayResponse.data;

        this.edits.add(settings.id);
        this.emitSaveCheck.emit(true);
        this.setInitialSettings(settings.id);

        this.forecastSettingsStore.update(settings.id, {
          milestone_category: phaseId,
          period_start_milestone_id: startMilestoneId,
          period_end_milestone_id: endMilestoneId,
          period_start_date: startDate,
          period_end_date: endDate,
        });

        if (mode.name === 'activity') {
          this.afterActivityStateChange(mode.id);
        } else {
          this.afterCategoryStateChange(mode.id);
        }
      }
    });
  };

  afterCategoryStateChange(categoryId: string): void {
    const entity = this.categoryQuery.getEntity(categoryId);
    const category = this.CheckService.getCategoryType(entity);

    if (!category || !category.primary_settings_id) {
      return;
    }

    const needFlag = this.CheckService.isCategoryNeedUnforecastedFlag(
      categoryId,
      this.isPatientDriverAvailable,
      this.isSiteDriverAvailable
    );

    const hasFlag = !!this.forecastSettingsQuery.ui.getEntity(category.primary_settings_id)
      ?.unforecasted;

    if (needFlag !== hasFlag) {
      this.forecastSettingsStore.ui.update(category.primary_settings_id, {
        unforecasted: needFlag,
      });

      if (category.parent_category_id) {
        this.afterCategoryStateChange(category.parent_category_id);
      }
    }

    this.checkChanges(category.primary_settings_id);
  }

  afterActivityStateChange(activityId: string): void {
    const needFlag = this.CheckService.checkIfActivityHasError(
      activityId,
      this.isPatientDriverAvailable,
      this.isSiteDriverAvailable
    );
    const entity = this.activityQuery.getEntity(activityId);
    const activity = this.CheckService.getActivityType(entity);

    if (!activity || !activity.primary_settings_id) {
      return;
    }

    const hasFlag = !!this.forecastSettingsQuery.ui.getEntity(activity.primary_settings_id)
      ?.unforecasted;

    if (hasFlag !== needFlag) {
      this.forecastSettingsStore.ui.update(activity.primary_settings_id, {
        unforecasted: needFlag,
      });

      this.afterCategoryStateChange(activity.category_id);
    }

    this.checkChanges(activity.primary_settings_id);
  }

  getDiscountableTotal() {
    return this.activityQuery.getAll().reduce((total_cost, activity) => {
      if (
        (activity as FullActivity).activity_type === ActivityType.ACTIVITY_SERVICE &&
        activity.discount_flag
      ) {
        return total_cost + (activity.total_cost || 0);
      }

      return total_cost;
    }, 0);
  }

  setDiscountAmounts() {
    if (this.selectedDiscountType.value?.id === DiscountType.DISCOUNT_PERCENTAGE) {
      this.expensesAmount.amount =
        Math.round(this.discountableTotal * Math.abs(this.expensesAmount.amountPercent || 0)) /
        -100;

      this.emitChangeDiscountTotalAmount.emit(this.expensesAmount.amount || 0);
    } else if (this.selectedDiscountType.value?.id === DiscountType.DISCOUNT_TOTAL) {
      const amountPercent =
        Math.round((Math.abs(this.expensesAmount.amount) / this.discountableTotal) * 10000) / 100 ||
        0;

      this.expensesAmount.amountPercent = Number.isFinite(amountPercent) ? amountPercent : 0;

      this.emitChangeDiscountTotalPercent.emit(this.expensesAmount.amountPercent || 0);

      this.emitChangeDiscountTotalAmount.emit(
        Math.abs(this.changeDiscountTotalAmount.value) * -1 || 0
      );

      this.expensesAmount.amount = Math.abs(this.changeDiscountTotalAmount.value) * -1;
    }
  }

  getListBudgetVersionExpenses(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.organizationQuery
      .selectActiveId()
      .pipe(
        switchMap((vendorId) => {
          if (vendorId) {
            return this.gqlService.listBudgetVersionsExpenses$(
              [BudgetType.BUDGET_PRIMARY],
              vendorId
            );
          }

          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe(({ data, success, errors }) => {
        if (!this.changeDiscountTotalAmount.value) {
          this.emitChangeDiscountTotalAmount.emit(0);
          this.expensesAmount.amount = 0;
        }

        if (!this.changeDiscountTotalPercent.value) {
          this.emitChangeDiscountTotalPercent.emit(0);
          this.expensesAmount.amountPercent = 0;
        }

        if (success && data?.length) {
          const currentBudget = data.filter((budget) => budget.is_current)[0];

          this.budgetId = currentBudget.budget_version_id;

          this.selectedDiscountType.setValue(
            this.discountTypes.filter(
              (discountType) =>
                discountType.id ===
                (currentBudget.discount_type || DiscountType.DISCOUNT_PERCENTAGE)
            )[0]
          );

          let showDiscount = false;

          data[0].expense_amounts.forEach((budget) => {
            if (budget.amount_type === AmountType.AMOUNT_DISCOUNT) {
              this.expensesAmount.amount = budget.amount || 0;
              this.expensesAmount.amountPercent = budget.amount_perct || 0;

              showDiscount = showDiscount || !!budget.amount || !!budget.amount_perct;

              this.emitChangeDiscountTotalAmount.emit(budget.amount || 0);
              this.emitChangeDiscountTotalPercent.emit(budget.amount_perct || 0);
              this.discountableTotal = this.getDiscountableTotal();

              if (this.discountableTotal !== 0) {
                this.setDiscountAmounts();
              }
            }
          });

          this.emitHideDiscounts.emit(!showDiscount);
        } else {
          this.overlayService.error(errors);
        }
      });
  }

  saveChanges: ForecastTableGridSaveChange = async () => {
    for (const editId of this.edits) {
      const editSetting = this.forecastSettingsQuery.getEntity(editId);

      this.emitSuccessForecast.emit(true);
      this.forecastSettingsStore.ui.update(editId, () => ({ showError: false }));

      let success = true;

      if (
        this.CheckService.isDriverTimeUnfilled(editSetting) ||
        this.CheckService.isDriverSettingIdUnfilled(editSetting, DriverType.DRIVER_PATIENT) ||
        this.CheckService.isDriverSettingIdUnfilled(editSetting, DriverType.DRIVER_SITE)
      ) {
        success = false;
        this.forecastSettingsStore.ui.update(editId, () => ({ showError: true }));
      }

      if (
        (editSetting?.driver === DriverType.DRIVER_PATIENT && !this.isPatientDriverAvailable) ||
        (editSetting?.driver === DriverType.DRIVER_SITE && !this.isSiteDriverAvailable)
      ) {
        this.emitIsPatientDriversLinkVisible.emit(
          editSetting?.driver === DriverType.DRIVER_PATIENT && !this.isPatientDriverAvailable
        );
        this.emitIsSiteDriversLinkVisible.emit(
          editSetting?.driver === DriverType.DRIVER_SITE && !this.isSiteDriverAvailable
        );
        this.emitSuccessForecast.emit(false);
        this.forecastSettingsStore.ui.update(editId, () => ({ showError: true }));
        return;
      }

      if (!success) {
        return;
      }
    }

    let forecastUpdated = false;

    const edits = [...this.edits];
    if (edits.length) {
      const ref = this.overlayService.loading();
      const responses = await batchPromises(edits, (p) =>
        this.forecastSettingsService.syncSetting(p)
      );

      if (responses.every((response) => !(response instanceof Error) && response?.success)) {
        const { success, errors } = await firstValueFrom(
          this.gqlService.processEvent$({
            type: EventType.BUDGET_FORECAST_SETTINGS_UPDATED,
            entity_type: EntityType.ORGANIZATION,
            entity_id: this.selectedVendor.value,
          })
        );

        if (success) {
          this.edits.forEach((x) => {
            const settings = this.forecastSettingsQuery.getEntity(x);
            this.defaultSubSettings.set(x, settings as FullSettings);
            this.cloneRowData = this.cloneRowData.map((y) => {
              if (x === y.primarySettingsId) {
                const cat = {
                  ...y.fullCategory,
                  primary_settings: settings,
                } as FullCategory;
                return {
                  ...y,
                  fullCategory: cat,
                };
              }
              return y;
            });
          });
          forecastUpdated = true;
          this.edits.clear();
        } else {
          forecastUpdated = false;
          this.overlayService.error(errors);
        }
      } else {
        forecastUpdated = false;
      }

      ref.close();
    }

    if (forecastUpdated) {
      this.overlayService.success('Changes successfully saved!');
      this.emitSaveCheck.emit(false);
      this.initialSettings = {};
    } else {
      this.overlayService.error('Something went wrong');
    }

    this.getListBudgetVersionExpenses();
  };

  onDiscardChanges() {
    this.rowData = this.cloneRowData;
    this.cloneRowData.forEach((x) => {
      const defaultValues = {
        id: x.fullCategory?.primary_settings.id,
        forecast_method: x.fullCategory?.primary_settings?.forecast_method || null,
        driver_setting_id: x.fullCategory?.primary_settings?.driver_setting_id || null,
        period_end_date: x.fullCategory?.primary_settings?.period_end_date || null,
        period_start_date: x.fullCategory?.primary_settings?.period_start_date || null,
        period_start_milestone_id:
          x.fullCategory?.primary_settings?.period_start_milestone_id || null,
        period_end_milestone_id: x.fullCategory?.primary_settings?.period_end_milestone_id || null,
        driver: x.fullCategory?.primary_settings?.driver || null,
        override: x.fullCategory?.primary_settings?.override,
      };
      this.changesToDefault(defaultValues as FullSettings);
    });
    this.edits.forEach((id) => {
      // sub rows are becoming defaults in this structure
      const editSetting = this.defaultSubSettings.get(id);
      if (editSetting && id) {
        this.changesToDefault(editSetting as unknown as FullSettings);
      }
    });
    // If override counts are changed, this func'll become to default values
    this.categoryService.defaultCategoryOverride();
    this.emitSaveCheck.emit(false);
    this.initialSettings = {};
    this.ToggleService.reset();
  }

  changesToDefault(data: FullSettings) {
    const ids = this.CheckService.getAllSubSettingIDs(data?.id || '');
    const milestone_category =
      this.timelinePhases.find((x) => x.firstMilestone?.id === data.period_start_milestone_id)
        ?.cat_id || null;

    const defaultValues = {
      forecast_method: data?.forecast_method || null,
      driver_setting_id: data?.driver_setting_id || null,
      period_end_date: data?.period_end_date || null,
      period_start_date: data?.period_start_date || null,
      period_start_milestone_id: data?.period_start_milestone_id || null,
      period_end_milestone_id: data?.period_end_milestone_id || null,
      driver: data?.driver || null,
      milestone_category,
    };

    this.forecastSettingsStore.update(data?.id, {
      ...defaultValues,
    });

    this.forecastSettingsService.update(data?.id, {
      ...defaultValues,
      override: data.override,
    });

    // If row has override or not this'll be update in here
    this.forecastSettingsStore.ui.update(data?.id || '', {
      unforecasted: !(data?.forecast_method || data.override || data.driver),
      showError: !(data?.forecast_method || data.override || data.driver),
    });

    this.checkChanges(data?.id || '');
    ids.forEach((id) => {
      this.edits.delete(id);
    });
    this.edits.delete(data?.id || '');
    this.defaultSubSettings.delete(data?.id || '');
  }
}
