import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { DateControlPeriodParams } from '@components/form-inputs/input/input.component';
import { OverlayService } from '@services/overlay.service';
import { FormControlConstants } from 'src/app/constants/form-control.constants';
import { MessagesConstants } from 'src/app/constants/messages.constants';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {
  ForecastTableGridTimelineCustomMilestoneDatepickerType,
  ForecastTableGridTimelineMilestoneInterface,
  ForecastTableGridTimelinePhaseInterface,
  ForecastTableTimelineOverlayData,
  ForecastTableTimelineOverlayResponse,
} from '../../models/forecast-table-grid.model';

dayjs.extend(isSameOrBefore);
@Component({
  selector: 'aux-forecast-table-grid-period-time-modal',
  templateUrl: './forecast-table-grid-period-time-modal.component.html',
})
export class ForecastTableGridPeriodTimeModalComponent implements OnInit {
  readonly formControlConstants = FormControlConstants;

  readonly customMilestoneDatepickerType = ForecastTableGridTimelineCustomMilestoneDatepickerType;

  customStartDateControl = new FormControl();

  customEndDateControl = new FormControl();

  phases: ForecastTableGridTimelinePhaseInterface[] = [];

  selectedPhase: ForecastTableGridTimelinePhaseInterface | null = null;

  allMilestones: ForecastTableGridTimelineMilestoneInterface[] = [];

  selectedCustomStartMilestone:
    | ForecastTableGridTimelineMilestoneInterface
    | ForecastTableGridTimelineCustomMilestoneDatepickerType.START_DATE
    | null = null;

  selectedCustomEndMilestone:
    | ForecastTableGridTimelineMilestoneInterface
    | ForecastTableGridTimelineCustomMilestoneDatepickerType.END_DATE
    | null = null;

  constructor(
    public ref: CustomOverlayRef<
      ForecastTableTimelineOverlayResponse | null,
      ForecastTableTimelineOverlayData
    >,
    private overlayService: OverlayService
  ) {}

  ngOnInit(): void {
    // Assign timeline phases
    this.phases = this.ref.data?.timelinePhases || [];
    // Assign timeline milestones
    this.allMilestones = this.ref.data?.timelineMilestones || [];
    // Assign selected phase
    this.assignSelectedPhase();
  }

  get trialTimelinePeriod(): DateControlPeriodParams {
    return {
      startDate: this.allMilestones[0].start_date,
      endDate: this.allMilestones[this.allMilestones.length - 1].end_date,
    };
  }

  isCustomStartDateControlDisabled(): boolean {
    return (
      this.selectedCustomStartMilestone !==
      ForecastTableGridTimelineCustomMilestoneDatepickerType.START_DATE
    );
  }

  isCustomEndDateControlDisabled(): boolean {
    return (
      this.selectedCustomEndMilestone !==
      ForecastTableGridTimelineCustomMilestoneDatepickerType.END_DATE
    );
  }

  customStartDate(): string {
    return this.selectedCustomStartMilestone ===
      ForecastTableGridTimelineCustomMilestoneDatepickerType.START_DATE
      ? this.customStartDateControl.value
      : this.selectedCustomStartMilestone?.start_date;
  }

  customEndDate(): string {
    return this.selectedCustomEndMilestone ===
      ForecastTableGridTimelineCustomMilestoneDatepickerType.END_DATE
      ? this.customEndDateControl.value
      : this.selectedCustomEndMilestone?.end_date;
  }

  isCustomDateUnValidAndNotDisabled(): boolean {
    return (
      (!this.isCustomStartDateControlDisabled() && !this.customStartDateControl.valid) ||
      (!this.isCustomEndDateControlDisabled() && !this.customEndDateControl.valid)
    );
  }

  assignSelectedPhase(): void {
    if (!this.ref.data?.settings) {
      return;
    }

    const {
      milestone_category,
      start_milestone,
      end_milestone,
      period_start_date,
      period_end_date,
    } = this.ref.data.settings;

    if (milestone_category) {
      const phase = this.phases.find(
        (timelinePhase) => timelinePhase.cat_id === milestone_category.id
      );

      if (phase) {
        this.selectedPhase = phase;
      }
    } else {
      this.selectedPhase = this.phases[this.phases.length - 1];

      const startMilestone = this.allMilestones.find(
        (milestone) => milestone.id === start_milestone?.id
      );
      const endMilestone = this.allMilestones.find(
        (milestone) => milestone.id === end_milestone?.id
      );

      if (period_start_date) {
        this.selectedCustomStartMilestone =
          ForecastTableGridTimelineCustomMilestoneDatepickerType.START_DATE;
        this.customStartDateControl.setValue(period_start_date);
      } else if (start_milestone && startMilestone) {
        this.selectedCustomStartMilestone = startMilestone;
      }

      if (period_end_date) {
        this.selectedCustomEndMilestone =
          ForecastTableGridTimelineCustomMilestoneDatepickerType.END_DATE;
        this.customEndDateControl.setValue(period_end_date);
      } else if (end_milestone && endMilestone) {
        this.selectedCustomEndMilestone = endMilestone;
      }
    }
  }

  onSave() {
    // If no selected phase
    if (!this.selectedPhase) {
      this.ref.close(null);
      return;
    }

    // If selected phase is Custom
    if (this.selectedPhase.name !== 'Custom') {
      const { cat_id, firstMilestone, lastMilestone } = this.selectedPhase;

      if (firstMilestone && lastMilestone) {
        this.ref.close({
          phaseId: cat_id,
          startMilestoneId: firstMilestone.id,
          endMilestoneId: lastMilestone.id,
          startDate: null,
          endDate: null,
        });

        return;
      }
    }

    // If selected phase is anything but Custom
    // and includes a start/end milestone
    if (
      this.selectedPhase.name === 'Custom' &&
      this.selectedCustomStartMilestone &&
      this.selectedCustomEndMilestone
    ) {
      if (this.isCustomDateUnValidAndNotDisabled()) {
        return;
      }

      if (this.customEndDate() < this.customStartDate()) {
        this.overlayService.error(MessagesConstants.START_DATE_BEFORE_END);
        return;
      }

      const closeData: ForecastTableTimelineOverlayResponse = {
        phaseId: null,
        startMilestoneId: '',
        endMilestoneId: '',
        startDate: null,
        endDate: null,
      };

      if (
        this.selectedCustomStartMilestone ===
        ForecastTableGridTimelineCustomMilestoneDatepickerType.START_DATE
      ) {
        if (!this.confirmStartDate()) {
          return;
        }

        closeData.startDate = this.customStartDateControl.value;
      } else {
        closeData.startMilestoneId = this.selectedCustomStartMilestone.id;
      }

      if (
        this.selectedCustomEndMilestone ===
        ForecastTableGridTimelineCustomMilestoneDatepickerType.END_DATE
      ) {
        if (!this.confirmEndDate()) {
          return;
        }

        closeData.endDate = this.customEndDateControl.value;
      } else {
        closeData.endMilestoneId = this.selectedCustomEndMilestone.id;
      }

      this.ref.close(closeData);
    }
  }

  selectDateInputRadio(selectingStart: boolean): void {
    if (selectingStart) {
      this.selectedCustomStartMilestone =
        ForecastTableGridTimelineCustomMilestoneDatepickerType.START_DATE;
    } else {
      this.selectedCustomEndMilestone =
        ForecastTableGridTimelineCustomMilestoneDatepickerType.END_DATE;
    }
  }

  confirmStartDate(): boolean {
    const startDate = dayjs(this.customStartDateControl.value);

    return startDate.isSameOrAfter(this.trialTimelinePeriod?.startDate);
  }

  confirmEndDate(): boolean {
    const endDate = dayjs(this.customEndDateControl.value);

    return endDate.isSameOrBefore(this.trialTimelinePeriod?.endDate);
  }
}
