import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@services/utils';

@Pipe({
  name: 'country',
})
export class CountryPipe implements PipeTransform {
  transform(value: string | undefined | null): string {
    if (value) {
      if (Object.prototype.hasOwnProperty.call(Utils.countries, value)) {
        return Utils.countries[value];
      }

      if (Object.prototype.hasOwnProperty.call(Utils.countries, value.slice(8))) {
        return Utils.countries[value.slice(8)];
      }
    }
    return '';
  }
}
