<ng-container *ngIf="(loadedOnce$ | async) === false">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="(loadedOnce$ | async) === true">
  <div class="px-8 py-8 bg-aux-gray-light border-b">
    <div class="flex justify-between items-center mb-3.5">
      <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 flex items-end">Audit History</h1>
      <div class="flex space-x-2">
        <div class="flex justify-between items-center space-x-4">
          <aux-processing-loader *ngIf="loading$ | async" [message]="processorMessage" />
          <aux-button
            *ngIf="(loading$ | async) === false"
            classList="h-8 bg-white"
            variant="secondary"
            label="Refresh Audit History"
            icon="CloudDownload"
            (click)="triggerFetchAuditHistory()"
            [iconSize]="20"
          />
          <button
            class="focus:outline-none aux-link underline mr-1 text-sm"
            (click)="resetFilter()"
          >
            Reset Filters
          </button>
          <aux-export-excel-button
            [className]="'ml-1 h-8 bg-white'"
            [gridAPI]="gridAPI"
            [excelOptions]="excelOptions"
            [getDynamicExcelParamsCallback]="getDynamicExcelParams.bind(this)"
            [svgSize]="20"
          />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-10 content-start mb-6 w-full">
      <div class="flex justify-start col-span-10 w-full">
        <div>
          <div class="h-4 mb-1"></div>
          <aux-input
            style="width: 679px"
            class="mr-2 mb-5"
            placeholder="Search"
            icon="Search"
            [(ngModel)]="nameFilterValue"
            (change)="filterChanges()"
            (keyup)="updateSearch($event.target)"
          />
        </div>
        <div class="w-full mr-2 mb-5" style="width: 480px">
          <div class="text-xs mb-1">Category</div>
          <ng-select
            placeholder="All"
            [clearable]="false"
            [multiple]="true"
            [formControl]="selectedCategory"
            (change)="filterChanges()"
          >
            <ng-container *ngVar="category$ | async as categories">
              <ng-option *ngIf="categories && categories.length > 1" [value]="''">All</ng-option>
              <ng-option *ngFor="let category of categories" [value]="category.id">
                <span [title]="category.name">{{ category.name }}</span>
              </ng-option>
            </ng-container>
          </ng-select>
        </div>
        <div class="w-full mb-5" style="width: 480px">
          <div class="text-xs mb-1">Action:</div>
          <ng-select
            placeholder="All"
            [clearable]="false"
            [multiple]="true"
            [formControl]="selectedAction"
            (change)="filterChanges()"
          >
            <ng-container *ngVar="actions$ | async as actions">
              <ng-option *ngIf="actions && actions.length > 1" [value]="''">All</ng-option>
              <ng-option *ngFor="let action of actions" [value]="action.id">
                <span [title]="action.name">{{ action.name }}</span>
              </ng-option>
            </ng-container>
          </ng-select>
        </div>
      </div>
      <div class="flex justify-start col-span-10 w-full">
        <div class="flex justify-start items-start">
          <aux-input
            class="mr-2 w-96"
            label="From"
            placeholder="YYYY-MM-DD"
            [type]="'date'"
            [formControl]="fromDate"
            (change)="filterChanges()"
          />
          <aux-input
            class="mr-2 w-96"
            label="To"
            placeholder="YYYY-MM-DD"
            [type]="'date'"
            [formControl]="toDate"
            (change)="filterChanges()"
          />
        </div>
        <div class="w-full mr-2" style="width: 480px">
          <div class="text-xs mb-1">Vendor</div>
          <ng-select
            class="big-dropdown"
            style="width: 350px"
            placeholder="All"
            [multiple]="true"
            [clearable]="false"
            [formControl]="selectedVendor"
            (change)="filterChanges()"
            [items]="vendorOptions$ | async"
            bindValue="id"
            bindLabel="name"
          >
            <ng-template let-item="item" ng-option-tmp>
              <div
                class="block truncate"
                #text
                [auxTooltip]="text.offsetWidth < text.scrollWidth ? $any(item).name : ''"
                [auxTooltipPositions]="optionTooltipPositions"
              >
                {{ item.name }}
              </div>
            </ng-template>
            <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp>
              <div *ngIf="items.length === 1" class="ng-value">
                <div *ngFor="let item of items | slice: 0 : 1" class="flex">
                  <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)">×</span>
                  <span
                    class="ng-value-label overflow-hidden text-ellipsis max-w-[200px]"
                    #text
                    [auxTooltip]="text.offsetWidth < text.scrollWidth ? $any(item).name : ''"
                    [auxTooltipPositions]="optionTooltipPositions"
                  >
                    {{ $any(item).name }}
                  </span>
                </div>
              </div>
              <div *ngIf="items.length > 1" class="ng-value">
                <span class="ng-value-label">{{ items.length }} Selected</span>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="w-full" style="width: 480px">
          <div class="text-xs mb-1">User</div>
          <ng-select
            placeholder="All"
            [multiple]="true"
            [clearable]="false"
            [formControl]="selectedUser"
            (change)="filterChanges()"
          >
            <ng-container *ngVar="users$ | async as users">
              <ng-option *ngIf="users && users.length > 1" [value]="''">All</ng-option>
              <ng-option *ngFor="let user of users" [value]="user.sub">
                <span [title]="user.given_name">{{
                  user.given_name + ' ' + user.family_name
                }}</span>
              </ng-option>
            </ng-container>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="(loadedOnce$ | async) === true">
  <div class="px-8 py-6">
    <ag-grid-angular
      domLayout="autoHeight"
      class="ag-theme-alpine audit-history-table tabular-nums w-full"
      [gridOptions]="$any(gridOptions$ | async)"
      [quickFilterText]="nameFilterValue"
      (firstDataRendered)="onDataRendered($event)"
      (gridReady)="onGridReady($event)"
      (columnResized)="autoSize()"
      (viewportChanged)="onWindowScroll()"
    />
    <aux-pagination-panel [gridApi]="gridAPI" [totalItems]="(totalItems$ | async)!" />
  </div>
</ng-container>
