export const enum AuxExcelStyleKeys {
  BORDER_RIGHT = 'border_right',
  BORDER_LEFT = 'border_left',
  BORDER_BOTTOM = 'border_bottom',
  BORDER_TOP = 'border_top',
  ALTERNATE = 'alternate',
  COST = 'cost',
  PERCENT = 'percent',
  FIRST_ROW = 'first_row',
  BUDGET_UNIT = 'budget-unit',
  BUDGET_UNITS = 'budget-units',
  HEADER = 'header',
  HEADER_GROUP = 'headerGroup',
  CELL = 'cell',
  CELL_RIGHT = 'cell-right',
  CELL_LEFT = 'cell-left',
  TOTAL_ROW_HEADER = 'total_row_header',
  TOTAL_ROW = 'total_row',
  TOTAL_ROW_NO_CURRENCY_SYMBOL = 'total_row_no_currency_symbol',
  TOTAL_ROW_AMOUNT = 'total_row_amount',
  TOTAL_ROW_PERCENT = 'total_row_percent',
  TOTAL_TEXT_FORMAT = 'total_text_format',
  NUMBER = 'number',
  TEXT_FORMAT = 'text_format',
}
