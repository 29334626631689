<p class="mb-8">
  The
  <span class="text-aux-blue-light"> aux-button-toggle-group </span>
  are toggles with the appearance of a button.
</p>

<ag-grid-angular
  class="ag-theme-alpine w-full"
  style="width: 100%"
  [rowData]="documentationData"
  [gridOptions]="documentationOptions"
  [domLayout]="'autoHeight'"
  (gridReady)="autoSize($event)"
  (firstDataRendered)="autoSize($event)"
/>

<h3 class="mt-2">Example</h3>

<div class="flex items-center gap-6 mt-4">
  <aux-button-toggle-group [value$]="activeTabId$" [items]="tabs" />
</div>

<div class="flex items-center mt-6">
  <div class="text-black bg-aux-gray-dark">
    <div class="m-2">1</div>
    <div class="m-2">2</div>
    <div class="m-2">3</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;aux-button-toggle-group</div>
    <div class="m-2">
      <div class="mx-4">[value$]="activeTabId$"</div>
    </div>
    <div class="m-2">
      <div class="mx-4">[items]="tabs">&lt;/aux-button-toggle-group&gt;</div>
    </div>
  </div>
</div>
