import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TrialInsightsState } from '../models/trial-insights-store.model';
import { TrialInsightsStore } from './trial-insights.store';

@Injectable({ providedIn: 'root' })
export class TrialInsightsQuery extends Query<TrialInsightsState> {
  constructor(protected store: TrialInsightsStore) {
    super(store);
  }
}
