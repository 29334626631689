import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { ModalDialogComponent } from './modal-dialog.component';

@Component({
  selector: 'aux-modal',
  templateUrl: './modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  constructor(private overlayService: OverlayService) {}

  openOneButtonModal() {
    this.overlayService.open<
      unknown,
      { header: string; useDesignSystemStyling: boolean; displayX: boolean; formValue?: null }
    >({
      content: ModalDialogComponent,
      data: { header: '1 Button', useDesignSystemStyling: true, displayX: true },
    });
  }

  openTwoButtonsModal() {
    this.overlayService.open<
      unknown,
      { header: string; useDesignSystemStyling: boolean; displayX: boolean; formValue?: null }
    >({
      content: ModalDialogComponent,
      data: { header: '2 Buttons', useDesignSystemStyling: true, displayX: false },
    });
  }

  getLineNumbers(upperBound: number) {
    return Array(upperBound)
      .fill(1)
      .map((_, i) => i + 1);
  }
}
