import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';

export interface RemoveDialogInput {
  header: string;
  cannotDeleteMessage: string;
  routeInputs: RouteInput[];
}

export interface RouteInput {
  componentName: string;
  name: string[];
  link: string;
}

@Component({
  template: `
    <div [innerHTML]="cannotDeleteMessage"></div>
    <div *ngFor="let route of routeInputs" class="mb-4">
      <br />{{ route.componentName }}<br />
      <ul *ngFor="let name of route.name" class="list-disc pl-4">
        <li *ngIf="name">
          <a class="aux-link cursor-pointer" [routerLink]="[route.link]" (click)="this.ref.close()"
            >{{ name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="bg-white pt-5 flex justify-between">
      <button class="focus:outline-none" type="button" (click)="this.ref.close()">Cancel</button>
      <button class="ml-auto mr-2 btn--primary" [disabled]="true">Remove</button>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveDialogComponent {
  header: string;

  cannotDeleteMessage: string;

  routeInputs: RouteInput[];

  constructor(public ref: CustomOverlayRef<unknown, RemoveDialogInput>) {
    this.header = ref.data?.header || '';
    this.cannotDeleteMessage = ref.data?.cannotDeleteMessage || '';
    this.routeInputs = ref.data?.routeInputs || [];
  }
}
