import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BudgetLibraryStore, BudgetLibraryState } from './budget-library.store';

@Injectable({ providedIn: 'root' })
export class BudgetLibraryQuery extends QueryEntity<BudgetLibraryState> {
  constructor(protected store: BudgetLibraryStore) {
    super(store);
  }
}
