import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { switchMap, tap } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { GqlService } from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';

import {
  SpecificationCategoryModel,
  SpecificationCategoryStore,
} from './specification-category.store';
import { SpecificationModel, SpecificationStore } from '../specification/specification.store';
import {
  SpecificationSettingModel,
  SpecificationSettingStore,
} from '../specification-setting/specification-setting.store';

@Injectable({ providedIn: 'root' })
export class SpecificationCategoryService {
  constructor(
    private specificationCategoryStore: SpecificationCategoryStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private specificationStore: SpecificationStore,
    private specificationSettingStore: SpecificationSettingStore
  ) {}

  getAllSpecifications$() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.specificationCategoryStore.setLoading(true);
        return this.gqlService.listSpecificationSettings$();
      }),
      tap(({ success, errors, data }) => {
        if (success && data) {
          const allSpecificationCategories: SpecificationCategoryModel[] = [];
          const allSpecifications: SpecificationModel[] = [];
          const allSpecificationSettings: SpecificationSettingModel[] = [];

          data.forEach((specificationCategory) => {
            const { specifications } = specificationCategory;
            allSpecificationCategories.push({
              ...specificationCategory,
              specifications: (specifications || []).map(
                (specification) => specification?.id
              ) as string[],
            });

            (specifications || []).forEach((specification) => {
              if (specification) {
                const { settings } = specification;

                allSpecifications.push({
                  ...specification,
                  settings: (settings || []).map((x) => x?.id) as string[],
                });

                (settings || []).forEach((setting) => {
                  if (setting) {
                    allSpecificationSettings.push({
                      ...setting,
                      specification_type: setting.specification_type,
                    });
                  }
                });
              }
            });
          });

          this.specificationCategoryStore.set(allSpecificationCategories);
          this.specificationStore.set(allSpecifications);
          this.specificationSettingStore.set(allSpecificationSettings);
        } else {
          this.overlayService.error(errors);
        }
        this.specificationCategoryStore.setLoading(false);
      })
    );
  }

  add(specificationCategory: SpecificationCategoryModel) {
    this.specificationCategoryStore.add(specificationCategory);
  }

  update(id: string, specificationCategory: Partial<SpecificationCategoryModel>) {
    this.specificationCategoryStore.update(id, specificationCategory);
  }

  remove(id: ID) {
    this.specificationCategoryStore.remove(id);
  }
}
