import {
  GridOptions,
  ColDef,
  ColGroupDef,
  ValueFormatterParams,
  ICellRendererParams,
  GridApi,
} from '@ag-grid-community/core';

import { Utils } from '@services/utils';
import { Currency } from '@services/gql.service';
import { CurrencyToggle } from '@components/toggle-currency/toggle-currency.type';
import { BehaviorSubject } from 'rxjs';
import { TableConstants } from '@constants/table.constants';
import { InvestigatorTransactionsDataRow } from './investigator-transactions.service';

interface OverrideGridOptions extends GridOptions {
  columnDefs: ((ColDef | ColGroupDef) & { field: keyof InvestigatorTransactionsDataRow })[];
}

export const getInvestigatorTransactionCols = (
  isContractCurrency: boolean,
  isEDCColumnBeShown: boolean,
  showSourceColumn: boolean,
  gridApi: GridApi
): OverrideGridOptions => ({
  ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
  defaultColDef: {
    ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
    resizable: true,
    minWidth: 100,
  },
  pagination: true,
  rowModelType: 'infinite',
  paginationPageSize: 10,
  paginationAutoPageSize: false,
  suppressPaginationPanel: true,
  columnDefs: [
    {
      headerName: 'Completion Date',
      field: 'completionDate',
      tooltipField: 'activityDate',
      cellClass: ['text-left', 'cell-left'],
      sort: 'desc',
      cellRenderer: (params: ICellRendererParams) => {
        if (!params?.value) {
          gridApi?.hideOverlay();
          return '<div class="h-8 w-8 m-auto spinner mt-1"></div>';
        }
        return params.value;
      },
    },
    {
      headerName: 'Source',
      field: 'source',
      tooltipField: 'Source',
      hide: !showSourceColumn,
      cellClass: ['text-left', 'cell-left'],
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'EDC Created Date',
      field: 'source_created_date',
      tooltipField: 'EDCCreatedDate',
      hide: !isEDCColumnBeShown,
      cellClass: ['text-left', 'cell-left'],
      valueFormatter: Utils.dashFormatter,
      minWidth: 120,
    },
    {
      headerName: 'Site #',
      field: 'site',
      tooltipField: 'site',
      cellClass: ['text-left', 'cell-left'],
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Site Name',
      field: 'siteName',
      tooltipField: 'siteName',
      cellClass: ['text-left', 'cell-left'],
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Site Address',
      field: 'siteAddress',
      tooltipField: 'siteAddress',
      sortable: false,
      cellClass: [
        'text-left',
        'cell-left',
        'whitespace-nowrap overflow-hidden text-ellipsis !block',
      ],
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Primary Investigator',
      field: 'principalInvestigator',
      tooltipField: 'principalInvestigator',
      cellClass: ['text-left', 'cell-left'],
      valueFormatter: (params) => {
        return Utils.dashFormatter({
          ...params,
          value: params.value?.trim(),
        });
      },
    },
    {
      headerName: 'Patient ID',
      field: 'patient',
      tooltipField: 'patient',
      cellClass: ['text-left', 'cell-left'],
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Category',
      field: 'category',
      tooltipField: 'category',
      cellClass: ['text-left', 'cell-left'],
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Patient Group',
      field: 'patientGroup',
      tooltipField: 'patientGroup',
      cellClass: ['text-left', 'cell-left'],
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Protocol Version',
      field: 'protocolVersion',
      tooltipField: 'protocolVersion',
      cellClass: ['text-left', 'cell-left'],
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Description',
      field: 'description',
      tooltipField: 'description',
      cellClass: ['text-left', 'cell-left'],
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Country',
      field: 'country',
      tooltipField: 'country',
      cellClass: ['text-left', 'cell-left'],
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Contract Currency',
      field: 'contractCurrency',
      tooltipField: 'contractCurrency',
      cellClass: ['text-left', 'cell-left'],
      hide: !isContractCurrency,
      valueFormatter: Utils.dashFormatter,
    },
    {
      headerName: 'Total Cost (Contract)',
      field: 'totalContract',
      tooltipValueGetter: (params) =>
        Utils.agMultipleCurrencyFormatter(
          new BehaviorSubject<CurrencyToggle>(CurrencyToggle.CONTRACTED)
        )({ value: params.value, data: params.data }),
      cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
      hide: !isContractCurrency,
      valueFormatter: (params) =>
        Utils.agMultipleCurrencyFormatter(
          new BehaviorSubject<CurrencyToggle>(CurrencyToggle.CONTRACTED)
        )(params),
    },
    {
      headerName: 'Exchange Rate',
      field: 'exchangeRate',
      tooltipField: 'exchangeRate',
      cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
      valueFormatter: Utils.dashFormatter,
      hide: !isContractCurrency,
    },
    {
      headerName: 'Total Cost (USD)',
      field: 'totalUsd',
      tooltipValueGetter: (params) =>
        Utils.agCurrencyFormatter({ value: params.value } as ValueFormatterParams, Currency.USD),
      cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
      valueFormatter: (params) => Utils.agCurrencyFormatter(params, Currency.USD),
    },
  ],
});
