import { SiteDialogComponent } from './site-dialog.component';

export class SiteDialogConstants {
  static readonly OVERLAY_SETTINGS = {
    content: SiteDialogComponent,
    overlayConfig: {
      hasBackdrop: false,
      panelClass: [
        'modal',
        'is-active',
        'cdk-global-overlay-wrapper',
        'cdk-overlay-dark-backdrop',
        'cdk-overlay-backdrop-showing',
        'justify-center',
        'items-center',
      ],
    },
  };
}
