import { Injectable } from '@angular/core';
import { TrialInsightsLegendOptions } from '../../models/trial-insights-legend.model';
import { TrialInsightsChartModel } from '../../models/trial-insights-chart.model';

@Injectable()
export class TrialInsightsClinicalSiteCostChartService implements TrialInsightsChartModel {
  createLegend = (sitesActivated?: number, sitesClosed?: number): TrialInsightsLegendOptions => {
    return {
      compact: false,
      centered: false,
      divider: false,
      color: '',
      data: [
        {
          displayIcon: true,
          displayDivider: false,
          iconColor: '#128ee7',
          valueColor: '#128ee7',
          title: 'Active (Forecast)',
          value: (sitesActivated || 0) + '',
        },
        {
          displayIcon: true,
          displayDivider: false,
          iconColor: '#4e6d79',
          valueColor: '#4e6d79',
          title: 'Closed (Forecast)',
          value: (sitesClosed || 0) + '',
        },
      ],
    };
  };
}
