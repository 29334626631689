import { AsyncPipe, CommonModule, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { AuthService } from '@models/auth/auth.service';
import { PermissionType } from '@services/gql.service';

@Component({
  selector: 'aux-warning-vendor-estimate',
  template: `<div
    class="bg-[#FFF0B5] p-4"
    *ngIf="{ userHasAdjustPermission: userHasAdjustPermission$ | async } as obj"
  >
    <p>
      Auxilius administrators will begin processing upon receipt, but for faster results you can now
      <span
        class="aux-link cursor-pointer text-aux-blue"
        [auxTooltip]="obj.userHasAdjustPermission ? '' : notPermittedMessage"
        (click)="obj.userHasAdjustPermission && linkClick.emit()"
        [ngClass]="{ 'opacity-80': !obj.userHasAdjustPermission }"
        >add it yourself</span
      >
      and see it instantly for the current month. Copy and paste directly from your Excel tracker!
    </p>
  </div>`,
  imports: [NgIf, NgClass, TooltipDirective, AsyncPipe, CommonModule],
  standalone: true,
})
export class WarningVendorEstimateComponent {
  @Output() linkClick = new EventEmitter();

  notPermittedMessage = 'You do not have permission to edit monthly work performed.';

  userHasAdjustPermission$ = this.authService.isAuthorized$({
    sysAdminsOnly: false,
    permissions: [PermissionType.PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS],
  });

  constructor(private authService: AuthService) {}
}
