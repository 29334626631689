<div
  [ngStyle]="{
    height: bannerHeight
  }"
  class="fixed left-64 bottom-0 right-0 flex justify-between items-center px-4 py-[9px] shadow-banner z-[1000]"
  [ngClass]="{
    'bg-aux-green-light-1': !disabled,
    'bg-aux-gray-light-1': disabled
  }"
>
  <div
    *ngIf="!disabled; else unCompleted"
    class="flex items-center text-aux-blue-dark-1 font-semibold"
  >
    <aux-icon name="Sparkles" class="mr-2" />

    {{ closeMonthText }}
  </div>

  <ng-template #unCompleted>
    <div class="text-aux-gray-darkest font-semibold">
      You can’t close the month until you review inputs, expenses, and complete your checklist.
    </div>
  </ng-template>

  <div class="flex items-center">
    <aux-assign-month-close />

    <span
      class="btn btn--secondary h-[40px] ml-4 text-sm font-normal whitespace-nowrap cursor-pointer"
      [routerLink]="routerLink"
    >
      Review Checklist
    </span>

    <span [auxTooltip]="closeMonthTooltip">
      <span
        type="button"
        class="btn min-w-[206px] h-[40px] ml-4 text-sm font-bold whitespace-nowrap"
        [ngClass]="{
          'btn--success bg-aux-green-1 cursor-pointer': !disabled && hasClosePermission,
          'bg-aux-gray-light-2 text-aux-gray-dark-1 pointer-events-none border-aux-gray-light-2':
            disabled || !hasClosePermission
        }"
        (click)="onCloseMonth()"
      >
        <aux-icon name="Check" />

        {{ closeMonthBtnText }}
      </span>
    </span>
  </div>
</div>
