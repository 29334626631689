import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  PaymentScheduleStore,
  PaymentScheduleState,
  PaymentQuarterData,
} from './payment-schedule.store';

@Injectable({ providedIn: 'root' })
export class PaymentScheduleQuery extends Query<PaymentScheduleState> {
  constructor(protected store: PaymentScheduleStore) {
    super(store);
  }

  getPaymentScheduleCumulative(): Observable<PaymentQuarterData[]> {
    return this.select('PAYMENT_SCHEDULE_CUMULATIVE_PAYMENTS_VS_CUMULATIVE_WP').pipe(
      map(Object.values)
    );
  }
}
