import { Injectable } from '@angular/core';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import {
  TrialInsightsPatientCostKey,
  TrialInsightsTableModel,
  TrialInsightsTableOptions,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsClinicalPatientCostTableService implements TrialInsightsTableModel {
  route = ROUTING_PATH.INVESTIGATOR.INDEX;
  subRoute = ROUTING_PATH.INVESTIGATOR.SITES;

  createTable = (
    selectedKey: TrialInsightsPatientCostKey = TrialInsightsPatientCostKey.SITE,
    rowData: TrialInsightsTableRowData[] = []
  ): TrialInsightsTableOptions => {
    return {
      buttons: {
        display: true,
        defaultButtonKey: selectedKey,
        data: [
          { key: 'site', value: 'Avg. Patient Cost/Site' },
          { key: 'group', value: 'Avg. Patient Cost/Group' },
        ],
      },
      header: {
        display: true,
        data: [
          { buttonKey: 'site', leftValue: 'Site', rightValue: 'Avg. Patient Cost' },
          { buttonKey: 'group', leftValue: 'Group', rightValue: 'Avg. Patient Cost' },
        ],
      },
      rowData: {
        compact: false,
        data: rowData,
      },
      link: {
        display: true,
        url: `/${this.route}/${this.subRoute}`,
        value: 'See All Sites',
      },
    };
  };
}
