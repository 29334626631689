import { Maybe } from './../../../../services/utils';
import { Injectable } from '@angular/core';
import {
  AnalyticsCardType,
  GqlService,
  PatientProtocolType,
  SitePatientTrackerFlat,
  fetchInvestigatorDetailQuery,
  listSitesQuery,
  InvestigatorDetail,
} from '@services/gql.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { expand, map, reduce, switchMap, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { first, flatten, groupBy } from 'lodash-es';
import { Utils } from '@services/utils';
import { EventService } from '@services/event.service';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom, Observable, of } from 'rxjs';
import {
  PaymentSchedulesModel,
  PaymentSchedulesState,
  PaymentSchedulesStore,
} from '@models/payment-schedules/payment-schedules.store';
import {
  PatientTrackerContractSums,
  PatientTrackerState,
  PatientTrackerStore,
  PatientTrackerSums,
} from './patient-tracker.store';
import { SitesService } from '@models/sites/sites.service';
import { convertFilterToMap, mapSiteToSiteOption } from '@shared/utils';
import { SiteOption } from '@models/site-option.model';
import { PatientTrackerQuery } from './patient-tracker.query';
import { Invoiceables } from '@shared/constants';

@Injectable({ providedIn: 'root' })
export class PatientTrackerService {
  patientTrackerCache = new Map<string, PatientTrackerState[]>();

  sitePaymentScheduleCache = new Map<string, PaymentSchedulesState[]>();

  siteOptions$ = new BehaviorSubject<SiteOption[]>([]);

  siteMap = new Map<string, listSitesQuery>();

  investigatorMap = new Map<string, string>();

  constructor(
    private patientTrackerStore: PatientTrackerStore,
    private patientTrackerQuery: PatientTrackerQuery,
    private gqlService: GqlService,
    private paymentSchedulesStore: PaymentSchedulesStore,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private sitesService: SitesService,
    private eventService: EventService
  ) {}

  fetchAll(
    patient_protocol_types1: PatientProtocolType[],
    patient_protocol_types2: PatientProtocolType[],
    patient_tracker_version_id: string | null,
    site_ids: string[],
    start_date: string | null,
    end_date: string | null,
    groupId?: Maybe<string>
  ) {
    const fetchParams = {
      patient_protocol_types: patient_protocol_types1.slice(),
      tpsa_patient_protocol_types: patient_protocol_types2.slice(),
      patient_tracker_version_id,
      patient_group_ids: groupId ? [groupId] : null,
      site_ids,
      start_date,
      end_date,
      page: {
        offset: 0,
        limit: 500,
      },
    };
    return this.gqlService
      .fetchSitePatientTrackersFlat$({
        ...fetchParams,
      })
      .pipe(
        expand(({ success, data }) => {
          if (success && data) {
            if (Number.isInteger(data.next_offset) && data.next_offset >= 0) {
              fetchParams.page.offset = data.next_offset;
              return this.gqlService.fetchSitePatientTrackersFlat$({
                ...fetchParams,
              });
            }
          }
          return EMPTY;
        }),
        reduce(
          (acc, curr) => {
            if (acc.data && curr.success && curr.data) {
              acc.data = acc.data.concat(curr.data.items);
              return acc;
            }
            return {
              success: false,
              data: null,
              errors: curr.errors,
            };
          },
          {
            success: true,
            data: [] as Array<SitePatientTrackerFlat> | null,
            errors: [] as string[],
          }
        )
      );
  }

  private fetchInvestigatorDetails(
    patient_protocol_types1: PatientProtocolType[],
    patient_protocol_types2: PatientProtocolType[],
    patient_tracker_version_id: string | null,
    site_ids: string[],
    start_date: string | null,
    end_date: string | null
  ) {
    const fetchParams = {
      end_date,
      page: {
        offset: 0,
        limit: 500,
      },
      patient_protocol_types: patient_protocol_types1.slice(),
      patient_tracker_version_id,
      site_ids,
      start_date: null,
      tpsa_patient_protocol_types: patient_protocol_types2.slice(),
    };

    return this.gqlService.fetchInvestigatorDetail$({
      ...fetchParams,
    });
  }

  getSiteOptions(): Observable<[GraphqlResponse<Array<listSitesQuery>>]> {
    return combineLatest([this.sitesService.get()]).pipe(
      tap(([siteList]) => {
        this.siteMap = convertFilterToMap<listSitesQuery>(siteList?.data || []);
        this.investigatorMap = new Map(
          (siteList?.data || []).map(({ id }) => [id, this.getPrincipalInvestigatorName(id)])
        );

        this.siteOptions$.next(
          (siteList.data || [])
            .sort(({ site_no }, { site_no: site_no2 }) =>
              Utils.localeAlphaNumSort(site_no, site_no2)
            )
            .map((site: listSitesQuery) =>
              mapSiteToSiteOption(site, this.getPrincipalInvestigatorName(site.id))
            )
        );
      })
    );
  }

  private getExpensesAmount(
    expensesAmount: number,
    patientProtocolType?: PatientProtocolType | null,
    patientProtocolTypes?: PatientProtocolType[]
  ) {
    if (!patientProtocolType || !patientProtocolTypes) {
      return 0;
    }
    return patientProtocolTypes.indexOf(patientProtocolType) >= 0 ? expensesAmount : 0;
  }

  private getSumAmounts = (
    sitePatients: (SitePatientTrackerFlat | InvestigatorDetail)[],
    expensesAmountKey: keyof Pick<
      SitePatientTrackerFlat | InvestigatorDetail,
      'sps_expense_amount' | 'sps_contract_expense_amount'
    >,
    prefix = ''
  ) => {
    const sum_expense_amount_key = `sum_expense_amount${prefix}`;
    const sum_other_amount_key = `sum_other_amount${prefix}`;
    const sum_visit_amount_key = `sum_visit_amount${prefix}`;
    const sum_overhead_amount_key = `sum_overhead_amount${prefix}`;

    return sitePatients
      .filter((sptf) => !!sptf[expensesAmountKey])
      .reduce(
        (accum, sptf) => {
          return {
            [sum_expense_amount_key]:
              accum[sum_expense_amount_key] + (sptf[expensesAmountKey] || 0),
            [sum_other_amount_key]:
              accum[sum_other_amount_key] +
              this.getExpensesAmount(
                sptf[expensesAmountKey] || 0,
                sptf.sps_pp_patient_protocol_type,
                [
                  PatientProtocolType.PATIENT_PROTOCOL_OTHER,
                  PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
                  PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
                ]
              ),
            [sum_visit_amount_key]:
              accum[sum_visit_amount_key] +
              this.getExpensesAmount(
                sptf[expensesAmountKey] || 0,
                sptf.sps_pp_patient_protocol_type,
                [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT]
              ),
            [sum_overhead_amount_key]:
              accum[sum_overhead_amount_key] +
              this.getExpensesAmount(
                sptf[expensesAmountKey] || 0,
                sptf.sps_pp_patient_protocol_type,
                [PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD]
              ),
          };
        },
        {
          [sum_expense_amount_key]: 0,
          [sum_other_amount_key]: 0,
          [sum_visit_amount_key]: 0,
          [sum_overhead_amount_key]: 0,
        }
      );
  };

  get(site_ids: string[], start_date: string | null, end_date: string | null) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.patientTrackerStore.setLoading(true);
        return this.fetchAll(
          [],
          [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT],
          null,
          site_ids,
          start_date,
          end_date
        );
      }),
      tap(this.applyPatientTrackerData)
    );
  }

  getInvestigatorDetails(
    patient_tracker_version_id: string | null,
    site_ids: string[],
    start_date: string | null,
    end_date: string | null
  ) {
    this.patientTrackerStore.setLoading(true);
    return this.fetchInvestigatorDetails(
      [],
      [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT],
      patient_tracker_version_id,
      site_ids,
      start_date,
      end_date
    ).subscribe(this.applyInvestigatorData);
  }

  // TODO: need remove, it's deprecated
  fetchAllPatientTrackerWithCache(
    site_ids: string[],
    start_date: string | null,
    end_date: string | null,
    groupId?: Maybe<string>
  ) {
    const remaining_site_ids = site_ids.filter((site_id) => {
      return !this.patientTrackerCache.has(this.getCacheHash(site_id));
    });

    if (remaining_site_ids.length === 0) {
      this.setPatientTracker(site_ids);
      this.setPaymentSchedules(site_ids);
      this.patientTrackerStore.setLoading(false);
      return of([]);
    }

    this.patientTrackerStore.setLoading(true);
    return this.fetchAll(
      [],
      [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT],
      null,
      remaining_site_ids,
      start_date,
      end_date,
      groupId
    ).subscribe(({ success, data, errors }) => {
      this.applyPatientTrackerData({ success, data, errors });
      this.setPatientTracker(site_ids);
      this.setPaymentSchedules(site_ids);
      this.patientTrackerStore.setLoading(false);
    });
  }

  applyInvestigatorData = ({
    success,
    data,
    errors,
  }: GraphqlResponse<fetchInvestigatorDetailQuery>) => {
    this.patientTrackerStore.remove(() => true);
    this.paymentSchedulesStore.remove(() => true);

    if (success && data) {
      const patient_tracker_version_id =
        data.items[0] && data.items[0].patient_tracker_version_id
          ? data.items[0].patient_tracker_version_id
          : undefined;
      const groupedData = groupBy(data.items, 'sps_site_id');
      const max_create_date = data.items.reduce(
        (prev, current) => {
          return new Date(prev.create_date || '01-01-1970') >
            new Date(current.create_date || '01-01-1970')
            ? prev
            : current;
        },
        data && data.items[0] ? data.items[0] : { create_date: '01-01-1970' }
      );
      const source_refresh_date = Utils.dateFormatter(max_create_date.create_date || '01-01-1970', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });

      for (const [site_id, patientTrackerPerSites] of Object.entries(groupedData)) {
        const ids: string[] = [];
        const paymentSchedules: PaymentSchedulesState[] = [];

        const patientTrackersPerPatients = groupBy(patientTrackerPerSites, 'patient_id');

        for (const [patient_id, patientTrackers] of Object.entries(patientTrackersPerPatients)) {
          patientTrackers.forEach((patientTracker) => {
            // todo(upgrade) make sure this is still working an if so change the types:
            // `sps_pp_id` and `sps_pp_patient_protocol_type` to be required.
            if (patientTracker.sps_pp_id && patientTracker.sps_pp_patient_protocol_type) {
              const id = (patient_id === 'null' ? site_id : patient_id) + patientTracker.sps_id;
              ids.push(id);
              const paymentSchedule: PaymentSchedulesModel = {
                id,
                patient_id,
                external_patient_id: patientTracker.external_patient_id,
                amount: patientTracker.sps_expense_amount,
                amount_contract: patientTracker.sps_contract_expense_amount,
                sps_expense_currency: patientTracker.sps_expense_currency,
                sps_contract_expense_currency: patientTracker.sps_contract_expense_currency,
                completion_date: patientTracker.completion_date,
                site_id,
                patient_protocol_id: patientTracker.sps_pp_id,
                patient_protocol_type: patientTracker.sps_pp_patient_protocol_type,
                total_payment_schedule_amount:
                  patientTracker.sps_site_total_payment_schedule_amount || 0,
              };
              paymentSchedules.push(paymentSchedule);
              this.paymentSchedulesStore.add(paymentSchedule);
            }
          });
        }

        this.populatePaymentScheduleCache(site_id, paymentSchedules, patient_tracker_version_id);

        const patientTrackerPerSitesPerPatients = groupBy(
          patientTrackerPerSites.filter((x) => x.patient_id),
          'patient_id'
        );

        const sums_per_patient = Object.entries(patientTrackerPerSitesPerPatients).reduce(
          (acc, [patient_id, values]) => {
            acc[patient_id] = {
              ...(this.getSumAmounts(values, 'sps_expense_amount') as PatientTrackerSums),
              ...(this.getSumAmounts(
                values,
                'sps_contract_expense_amount',
                '_contract'
              ) as PatientTrackerContractSums),
            };
            return acc;
          },
          {} as Record<string, PatientTrackerSums & PatientTrackerContractSums>
        );

        const newPatientTracker = {
          sps_total_contract_expense_amount:
            first(patientTrackerPerSites)?.sps_total_contract_expense_amount || 0,
          sps_total_expense_amount: first(patientTrackerPerSites)?.sps_total_expense_amount || 0,
          sums_per_patient,
          id: site_id,
          patient_id: null,
          site_payment_schedule_ids: ids,
          external_patient_id: null,
          source_refresh_date,
        };

        this.patientTrackerStore.add(newPatientTracker);
        this.populatePatientTrackerCache(site_id, [newPatientTracker], patient_tracker_version_id);
      }
    } else {
      this.overlayService.error(errors);
    }

    this.patientTrackerStore.setLoading(false);
  };

  applyPatientTrackerData = ({
    success,
    data,
    errors,
  }: GraphqlResponse<SitePatientTrackerFlat[]>) => {
    this.patientTrackerStore.remove(() => true);
    this.paymentSchedulesStore.remove(() => true);

    if (success && data) {
      const patient_tracker_version_id =
        data[0] && data[0].patient_tracker_version_id
          ? data[0].patient_tracker_version_id
          : undefined;
      const groupedData = groupBy(data, 'sps_site_id');
      const max_create_date = data.reduce(
        (prev, current) => {
          return new Date(prev.create_date || '01-01-1970') >
            new Date(current.create_date || '01-01-1970')
            ? prev
            : current;
        },
        data && data[0] ? data[0] : { create_date: '01-01-1970' }
      );
      const source_refresh_date = Utils.dateFormatter(max_create_date.create_date || '01-01-1970', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });

      for (const [site_id, patientTrackerPerSites] of Object.entries(groupedData)) {
        const ids: string[] = [];
        const paymentSchedules: PaymentSchedulesState[] = [];

        const patientTrackersPerPatients = groupBy(patientTrackerPerSites, 'patient_id');

        for (const [patient_id, patientTrackers] of Object.entries(patientTrackersPerPatients)) {
          patientTrackers.forEach((patientTracker) => {
            const id = (patient_id === 'null' ? site_id : patient_id) + patientTracker.sps_id;
            ids.push(id);
            const paymentSchedule = {
              id,
              patient_id,
              external_patient_id: patientTracker.external_patient_id,
              amount: patientTracker.sps_expense_amount,
              amount_contract: patientTracker.sps_contract_expense_amount,
              sps_expense_currency: patientTracker.sps_expense_currency,
              sps_contract_expense_currency: patientTracker.sps_contract_expense_currency,
              completion_date: patientTracker.completion_date,
              site_id,
              patient_protocol_id: patientTracker.sps_pp_id,
              patient_protocol_type: patientTracker.sps_pp_patient_protocol_type,
              total_payment_schedule_amount:
                patientTracker.sps_site_total_payment_schedule_amount || 0,
            };
            paymentSchedules.push(paymentSchedule);
            this.paymentSchedulesStore.add(paymentSchedule);
          });
        }

        this.populatePaymentScheduleCache(site_id, paymentSchedules, patient_tracker_version_id);

        const patientTrackerPerSitesPerPatients = groupBy(
          patientTrackerPerSites.filter((x) => x.patient_id),
          'patient_id'
        );

        const sums_per_patient = Object.entries(patientTrackerPerSitesPerPatients).reduce(
          (acc, [patient_id, values]) => {
            acc[patient_id] = {
              ...(this.getSumAmounts(values, 'sps_expense_amount') as PatientTrackerSums),
              ...(this.getSumAmounts(
                values,
                'sps_contract_expense_amount',
                '_contract'
              ) as PatientTrackerContractSums),
            };
            return acc;
          },
          {} as Record<string, PatientTrackerSums & PatientTrackerContractSums>
        );

        const newPatientTracker = {
          sps_total_contract_expense_amount:
            first(patientTrackerPerSites)?.sps_total_contract_expense_amount || 0,
          sps_total_expense_amount: first(patientTrackerPerSites)?.sps_total_expense_amount || 0,
          sums_per_patient,
          id: site_id,
          patient_id: null,
          site_payment_schedule_ids: ids,
          external_patient_id: null,
          source_refresh_date,
        };

        this.patientTrackerStore.add(newPatientTracker);
        this.populatePatientTrackerCache(site_id, [newPatientTracker], patient_tracker_version_id);
      }
    } else {
      this.overlayService.error(errors);
    }

    this.patientTrackerStore.setLoading(false);
  };

  populatePatientTrackerCache(
    site_id: string,
    patient_tracker: PatientTrackerState[],
    patient_tracker_version_id?: string
  ) {
    this.patientTrackerCache.set(
      this.getCacheHash(site_id, patient_tracker_version_id),
      patient_tracker
    );
  }

  populatePaymentScheduleCache(
    site_id: string,
    payment_schedules: PaymentSchedulesState[],
    patient_tracker_version_id?: string
  ) {
    this.sitePaymentScheduleCache.set(
      this.getCacheHash(site_id, patient_tracker_version_id),
      payment_schedules
    );
  }

  getCacheHash(site_id: string, patient_tracker_version_id?: string) {
    const patientGroupId = this.patientTrackerQuery.getValue().patientGroupId || null;

    return `${site_id}-${patient_tracker_version_id || 'patient-tracker_version'}-${
      patientGroupId || 'patientGroupId'
    }`;
  }

  setPaymentSchedules(site_ids: string[], patient_tracker_version_id?: string) {
    const paymentScheduleData = flatten(
      site_ids
        .map((site_id) => {
          return (
            this.sitePaymentScheduleCache.get(
              this.getCacheHash(site_id, patient_tracker_version_id)
            ) || []
          );
        })
        .filter((data) => data.length > 0)
    ) as PaymentSchedulesState[];

    this.paymentSchedulesStore.set(
      paymentScheduleData.map(
        ({
          id,
          patient_id,
          external_patient_id,
          amount,
          amount_contract,
          sps_expense_currency,
          sps_contract_expense_currency,
          completion_date,
          site_id,
          patient_protocol_id,
          patient_protocol_type,
          total_payment_schedule_amount,
        }) => ({
          id,
          patient_id,
          external_patient_id,
          amount,
          amount_contract,
          sps_expense_currency,
          sps_contract_expense_currency,
          completion_date,
          site_id,
          patient_protocol_id,
          patient_protocol_type,
          total_payment_schedule_amount,
        })
      )
    );
  }

  setPatientTracker(site_ids: string[], patient_tracker_version_id?: string) {
    const patientTrackerData = flatten(
      site_ids
        .map((site_id) => {
          return (
            this.patientTrackerCache.get(this.getCacheHash(site_id, patient_tracker_version_id)) ||
            []
          );
        })
        .filter((data) => data.length > 0)
    ) as PatientTrackerState[];

    this.patientTrackerStore.set(
      patientTrackerData.map(
        ({
          id,
          create_date,
          external_patient_id,
          patient_id,
          site_payment_schedule_ids,
          sums_per_patient,
          source_refresh_date,
        }) => ({
          id,
          create_date,
          external_patient_id,
          patient_id,
          site_payment_schedule_ids,
          sums_per_patient,
          source_refresh_date,
        })
      )
    );
  }

  async getAnalyticsCards() {
    const proms = [
      firstValueFrom(
        this.eventService.selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.PATIENT_TRACKER_AVG_ENROLLEES_TO_DATE,
        })
      ),
      firstValueFrom(
        this.eventService.selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.PATIENT_TRACKER_FORECASTED_AVG_COST_ENROLLEES,
        })
      ),
      firstValueFrom(
        this.eventService.selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.PATIENT_TRACKER_AVG_COST_THROUGH_EOT,
        })
      ),
    ];

    const [ptaetd, ptface, ptacte] = await Promise.all(proms);
    const resp: {
      header: string;
      data: string;
      firstProp: { status?: string; label: string };
      secondProp: { status?: string; label: string };
    }[] = [];

    // Card 1
    let analyticsCard: {
      header: string;
      data: string;
      firstProp: { status?: string; label: string };
      secondProp: { status?: string; label: string };
    } = {
      header: 'Average Cost, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 1 month',
      },
      secondProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
    };
    if (ptaetd.success && ptaetd.data) {
      analyticsCard = this.parseAvgEnrolleesResponse(ptaetd.data.data);
    }
    resp.push(analyticsCard);

    // Card 2
    analyticsCard = {
      header: 'Forecasted Average Cost through EOT, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
      secondProp: {
        status: 'middle',
        label: 'vs. Current Budget',
      },
    };
    if (ptface.success && ptface.data) {
      const current_budgeted_average =
        ptacte && ptacte.data && ptacte.data.data
          ? this.getCurrentBudgetedAverage(ptacte.data.data)
          : 0;
      analyticsCard = this.parseForecastedAvgCostEnrolleesResponse(
        ptface.data.data,
        current_budgeted_average
      );
    }
    resp.push(analyticsCard);

    // Card 3
    analyticsCard = {
      header: 'Budgeted Average Cost through EOT',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 1 months',
      },
      secondProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
    };
    if (ptacte.success && ptacte.data) {
      analyticsCard = this.parseAvgCostThroughEOTResponse(ptacte.data.data);
    }
    resp.push(analyticsCard);

    return resp;
  }

  getInvestigatorDetailsAnalyticsCards() {
    const defaultValues = [
      {
        header: 'In-Month Accrued to Date',
        sub: 'Current $<br />% of Forecast',
        percentval: Utils.zeroHyphen,
        data: Utils.zeroHyphen,
      },
      {
        header: 'Total Accrued To Date',
        data: Utils.zeroHyphen,
        sub: 'Current $<br />% of Forecast',
        config: {
          options: [''],
          data: [''],
          sub: 'Current $<br />% of Forecast',
          header: 'Total Accrued To Date',
        },
      },
      {
        header: 'Total Average Enrollee Cost by Site',
        data: Utils.zeroHyphen,
        sub: Utils.zeroHyphen,
        config: {
          options: [''],
          data: [''],
          header: 'Total Average Enrollee Cost by Site',
        },
      },
    ];

    return combineLatest([
      this.eventService
        .selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.INVESTIGATOR_DETAILS_IN_MON_ACC_TO_DATE,
        })
        .pipe(
          map(({ success, data }) => {
            if (success && data) {
              return this.parseINVESTIGATOR_DETAILS_IN_MON_ACC_TO_DATE(data.data);
            }
            return defaultValues[0];
          })
        ),
      this.eventService
        .selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.INVESTIGATOR_DETAILS_TOTAL_ACC_BY_DATE,
        })
        .pipe(
          map(({ success, data }) => {
            if (success && data) {
              return this.parseINVESTIGATOR_DETAILS_TOTAL_ACC_BY_DATE(data.data);
            }
            return defaultValues[1];
          })
        ),
      this.eventService
        .selectAnalyticsCard$({
          analytics_card_type: AnalyticsCardType.INVESTIGATOR_DETAILS_TOTAL_AVG_COST_BY_SITE,
        })
        .pipe(
          map(({ success, data }) => {
            if (success && data) {
              return this.parseINVESTIGATOR_DETAILS_TOTAL_AVG_COST_BY_SITE(data.data);
            }
            return defaultValues[2];
          })
        ),
    ]);
  }

  getCurrentBudgetedAverage(str: string) {
    const obj = JSON.parse(str) as { [key: string]: string }[];
    const date = new Date();
    const current_mo_yr = `${date
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${date.getFullYear()}`;
    const current_date_value = obj.filter((o) => o.month_year === current_mo_yr);
    let current_mo_total = 0;
    if (current_date_value && current_date_value.length > 0) {
      current_mo_total = parseFloat(current_date_value[0].average) || 0;
    }
    return current_mo_total;
  }

  parseAvgEnrolleesResponse(str: string) {
    // [{"monthly_average":null,"month_year":"JAN-2021","monthly_patients":0,"monthly_total":null},...]
    const obj = JSON.parse(str) as { [key: string]: string }[];

    const resp = {
      header: 'Average Cost, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 1 month',
      },
      secondProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
    };

    const date = new Date();
    const current_mo_yr = `${date
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${date.getFullYear()}`;
    const one_mo_back = new Date(date.setMonth(date.getMonth() - 1));
    const three_mo_back = new Date(date.setMonth(date.getMonth() - 2)); // subtract 2 since one is subtracted above
    const one_mo_back_str = `${one_mo_back
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${one_mo_back.getFullYear()}`;
    const three_mo_back_str = `${three_mo_back
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${three_mo_back.getFullYear()}`;

    const current_date_value = obj.filter((o) => o.month_year === current_mo_yr);
    let current_mo_total = 0;
    if (current_date_value && current_date_value.length > 0) {
      current_mo_total = parseFloat(current_date_value[0].monthly_average) || 0;
      resp.data = Utils.currencyFormatter(current_mo_total);
    }
    const one_mo_value = obj.filter((o) => o.month_year === one_mo_back_str);
    if (one_mo_value && one_mo_value.length > 0) {
      const this_val = parseFloat(one_mo_value[0].monthly_average) || 0;
      resp.firstProp.status = this_val > current_mo_total ? 'low' : 'high';
    }

    const three_mo_value = obj.filter((o) => o.month_year === three_mo_back_str);
    if (three_mo_value && three_mo_value.length > 0) {
      const this_val = parseFloat(three_mo_value[0].monthly_average) || 0;
      resp.secondProp.status = this_val > current_mo_total ? 'low' : 'high';
    }

    return resp;
  }

  parseForecastedAvgCostEnrolleesResponse(str: string, current_budgeted_average: number) {
    // "[{"month_year":"JUN-2021","forecasted_avg_cost":1043.0700000000002},...]"
    const obj = JSON.parse(str) as { [key: string]: string }[];

    const resp = {
      header: 'Forecasted Average Cost through EOT, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
      secondProp: {
        status: 'middle',
        label: 'vs. Current Budget',
      },
    };

    const date = new Date();
    const current_mo_yr = `${date
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${date.getFullYear()}`;
    const three_mo_back = new Date(date.setMonth(date.getMonth() - 3)); // subtract 2 since one is subtracted above
    const three_mo_back_str = `${three_mo_back
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${three_mo_back.getFullYear()}`;

    const current_date_value = obj.filter((o) => o.month_year === current_mo_yr);
    let current_mo_total = 0;
    if (current_date_value && current_date_value.length > 0) {
      current_mo_total = parseFloat(current_date_value[0].forecasted_avg_cost) || 0;
      resp.data = Utils.currencyFormatter(current_mo_total);
    }

    const three_mo_value = obj.filter((o) => o.month_year === three_mo_back_str);
    if (three_mo_value && three_mo_value.length > 0) {
      const this_val = parseFloat(three_mo_value[0].forecasted_avg_cost) || 0;
      resp.firstProp.status = this_val > current_mo_total ? 'low' : 'high';
    }

    resp.secondProp.status = current_budgeted_average > current_mo_total ? 'low' : 'high';
    return resp;
  }

  parseAvgCostThroughEOTResponse(str: string) {
    // "[{"average":"5215.35","enrollee_count":0,"month_year":"JUL-2021","payment_total":"5215.35"},...]"
    const obj = JSON.parse(str) as { [key: string]: string }[];
    const resp = {
      header: 'Budgeted Average Cost through EOT',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'middle',
        label: 'Prior 1 months',
      },
      secondProp: {
        status: 'middle',
        label: 'Prior 3 months',
      },
    };

    const date = new Date();
    const current_mo_yr = `${date
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${date.getFullYear()}`;
    const one_mo_back = new Date(date.setMonth(date.getMonth() - 1));
    const three_mo_back = new Date(date.setMonth(date.getMonth() - 2)); // subtract 2 since one is subtracted above
    const one_mo_back_str = `${one_mo_back
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${one_mo_back.getFullYear()}`;
    const three_mo_back_str = `${three_mo_back
      .toLocaleString('default', { month: 'short' })
      .toUpperCase()}-${three_mo_back.getFullYear()}`;

    const current_date_value = obj.filter((o) => o.month_year === current_mo_yr);
    let current_mo_total = 0;
    if (current_date_value && current_date_value.length > 0) {
      current_mo_total = parseFloat(current_date_value[0].average) || 0;
      resp.data = Utils.currencyFormatter(current_mo_total);
    }
    const one_mo_value = obj.filter((o) => o.month_year === one_mo_back_str);
    if (one_mo_value && one_mo_value.length > 0) {
      const this_val = parseFloat(one_mo_value[0].average) || 0;
      resp.firstProp.status = this_val > current_mo_total ? 'low' : 'high';
    } else {
      resp.firstProp.status = 'middle';
    }

    const three_mo_value = obj.filter((o) => o.month_year === three_mo_back_str);
    if (three_mo_value && three_mo_value.length > 0) {
      const this_val = parseFloat(three_mo_value[0].average) || 0;
      resp.secondProp.status = this_val > current_mo_total ? 'low' : 'high';
    } else {
      resp.secondProp.status = 'middle';
    }

    return resp;
  }

  parseINVESTIGATOR_DETAILS_IN_MON_ACC_TO_DATE(data: string) {
    const json = JSON.parse(data) as {
      current_cash: string;
      percentage_of_forecast: string;
    };

    return {
      header: 'In-Month Accrued to Date',
      sub: 'Current $<br />% of Forecast',
      percentval: `${
        json.percentage_of_forecast
          ? `${Utils.percentageFormatter(parseFloat(json.percentage_of_forecast))}`
          : Utils.zeroHyphen
      }`,
      data: `${
        json.current_cash
          ? `${Utils.currencyFormatter(parseFloat(json.current_cash))}`
          : Utils.zeroHyphen
      }`,
    };
  }

  parseINVESTIGATOR_DETAILS_TOTAL_ACC_BY_DATE(data: string) {
    try {
      const json = JSON.parse(data) as {
        current_cash: string;
        cost_type: string;
        percentage_of_forecast: string;
      }[];

      return {
        header: 'Total Accrued To Date',
        data: `${json[0].current_cash}%`,
        sub: 'Current $<br />% of Forecast',
        config: {
          options: json.map((f) => f.cost_type),
          data: json.map(
            (f) =>
              `${Utils.currencyFormatter(parseFloat(f.current_cash))}<br />${
                f.percentage_of_forecast
                  ? Utils.percentageFormatter(parseFloat(f.percentage_of_forecast))
                  : Utils.zeroHyphen
              }`
          ),
          sub: 'Current $<br />% of Forecast',
          selected: 'Patient Visit',
          header: 'Total Accrued To Date',
        },
      };
    } catch (e) {
      return {
        header: '',
        data: ``,
        sub: '',
        config: {
          options: ['Patient Visit', Invoiceables.SITE, 'Discontinued'],
          data: [Utils.zeroHyphen, Utils.zeroHyphen, Utils.zeroHyphen],
          sub: 'Current $<br />% of Forecast',
          header: 'Total Accrued To Date',
        },
      };
    }
  }

  parseINVESTIGATOR_DETAILS_TOTAL_AVG_COST_BY_SITE(data: string) {
    const json = JSON.parse(data) as {
      site: string;
      average_patient_cost: string;
    }[];

    return {
      header: 'Total Average Enrollee Cost by Site',
      data: Utils.zeroHyphen,
      sub: Utils.zeroHyphen,
      config: {
        options: json.map((f) => `Site: ${f.site}`),
        data: json.map((f) => Utils.currencyFormatter(parseFloat(f.average_patient_cost))),
        header: 'Total Average Enrollee Cost by Site',
      },
    };
  }

  private getPrincipalInvestigatorName(siteId: string): string {
    const principalInvestigator = first(this.siteMap.get(siteId)?.contacts);

    return principalInvestigator
      ? `${principalInvestigator.given_name} ${principalInvestigator.family_name}`.trim()
      : '';
  }
}
