import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { SiteModel, SitesState, SitesStore } from './sites.store';
import { map } from 'rxjs/operators';
import { listSitesQuery } from '@services/gql.service';
import { Utils } from '@services/utils';

@QueryConfig({
  sortBy: 'name',
  sortByOrder: Order.ASC,
})
@Injectable({ providedIn: 'root' })
export class SitesQuery extends QueryEntity<SitesState> {
  constructor(protected store: SitesStore) {
    super(store);
  }

  selectSortedListBy() {
    return this.select().pipe(
      map((sites) => {
        const entities = sites.entities;
        if (entities) {
          const siteArray = Object.values(entities);

          return siteArray.sort(({ site_no }, { site_no: site_no2 }) =>
            Utils.localeAlphaNumSort(site_no || '', site_no2 || '')
          ) as listSitesQuery[];
        }

        return [];
      })
    );
  }

  getSortedListBy(sortBy: keyof SiteModel) {
    return this.getAll({
      sortBy,
      sortByOrder: Order.ASC,
    });
  }
}
