<label *ngIf="label" class="block mb-1 text-xs" [for]="uid">
  <span *ngIf="showRequiredAsterisk" class="text-aux-error font-bold">*</span>
  {{ label }}
</label>
<ng-container *ngIf="!textArea">
  <label class="relative text-gray-400 focus-within:text-gray-600 block" [class]="labelClassName">
    <span
      *ngIf="icon"
      class="pointer-events-none w-[16px] h-[16px] absolute top-1/2 transform -translate-y-1/2 translate-x-1/3"
      [class]="iconClassName"
      [ngClass]="{
        'right-3': iconPosition === 'right'
      }"
    >
      <aux-icon [name]="icon" [size]="16" />
    </span>
    <input
      #inputElement
      class="block w-full h-10 text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
      auxFormError
      [ngClass]="{
        'tabular-nums': type === 'number',
        'pr-7': !!icon && iconPosition === 'right',
        'pl-7': !!icon && iconPosition === 'left'
      }"
      [class]="inputClassName"
      [id]="uid"
      [type]="type"
      [label]="label"
      [placeholder]="placeholder || label"
      [labelForErrorMessage]="label || placeholder"
      [formControl]="fc"
      [attr.disabled]="disabled || null"
      [autocomplete]="autocomplete"
      [min]="min"
      [max]="max"
      (ngModelChange)="onChange(fc.value)"
      (blur)="onBlur()"
      (keydown)="onKeyDown($event)"
    />
  </label>
</ng-container>
<ng-container *ngIf="textArea">
  <textarea
    #inputElement
    class="block w-full max-w-2xl text-sm placeholder-gray-400 focus-within:placeholder-gray-600 font-medium rounded-sm shadow-sm border-aux-gray-dark focus:ring-2 focus:ring-aux-blue-light-200 focus:border-aux-blue"
    auxFormError
    [class]="inputClassName"
    [id]="uid"
    [rows]="rows"
    [placeholder]="placeholder || label"
    [labelForErrorMessage]="label || placeholder"
    [formControl]="fc"
    [attr.disabled]="disabled || null"
    (ngModelChange)="onChange(fc.value)"
    (blur)="onBlur()"
    [auxTextareaAutoresize]="textareaAutoresize"
  ></textarea>
</ng-container>
