<div
  class="save-changes bg-aux-gray-light border border-aux-gray-dark flex justify-between items-center p-6 py-7 shadow fixed bottom-0 left-0 w-full"
  @upDownAnimation
>
  <span class="text-lg font-bold"> You have unsaved changes. </span>

  <div>
    <button
      *ngIf="showDiscardChangesBtn"
      class="btn btn--secondary text-sm font-normal whitespace-nowrap"
      type="button"
      (click)="onDiscardChanges()"
    >
      <aux-icon name="X" />
      Discard Changes
    </button>

    <button
      class="btn btn--success ml-4 text-sm font-normal whitespace-nowrap"
      type="button"
      (click)="onSaveChanges()"
    >
      <aux-icon name="Check" />
      Save Changes
    </button>
  </div>
</div>
