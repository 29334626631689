import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { list, uploadData, remove, downloadData } from 'aws-amplify/storage';
import type { ListAllOutput } from 'aws-amplify/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OverlayService } from '@services/overlay.service';
import { environment } from 'src/environments/environment';
import { DocumentType, GqlService, TemplateType } from '@services/gql.service';
import { last } from 'lodash-es';
import {
  DocumentLibraryFile,
  DocumentLibraryService,
} from '../pages/documents/document-library.service';

export interface AwsFile {
  id: string;
  eTag: string;
  key: string;
  lastModified: number;
  size: number;
  fileName: string;
  created_by?: string;
  created_date?: string;

  metadata?: { [k: string]: string };
}

export interface FileMetadata {
  [k: string]: string | DocumentType;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    public http: HttpClient,
    private overlayService: OverlayService,
    private documentLibraryService: DocumentLibraryService,
    private gqlService: GqlService
  ) {}

  getLeadSponsor(nct_id: string): Observable<{ success: boolean; data?: string; error?: string }> {
    const headers = new HttpHeaders({ 'x-api-key': environment.restApiKey });
    return this.http
      .get(`${environment.restApiEndpoint}/getNlmTrialDetails/${nct_id}`, {
        headers,
      })
      .pipe(
        map((x) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (x?.FullStudiesResponse?.FullStudies) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (x?.FullStudiesResponse?.NStudiesFound > 1) {
              return { success: false, error: 'Multiple Trial Found!' };
            }
            try {
              const data =
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                x.FullStudiesResponse.FullStudies[0].Study.ProtocolSection
                  .SponsorCollaboratorsModule.LeadSponsor.LeadSponsorName;
              return { success: true, data };
              // eslint-disable-next-line no-empty
            } catch (err) {}
          }

          return { success: false, error: 'Sponsor Not Found!' };
        })
      );
  }

  getAppProperties(): Observable<{
    success: boolean;
    version?: string;
    samlProviders?: string[];
  }> {
    const headers = new HttpHeaders({ 'x-api-key': environment.restApiKey });
    return this.http
      .get<{ version: string; samlProviders: string[] }>(
        `${environment.restApiEndpoint}/getAppProperties`,
        {
          headers,
        }
      )
      .pipe(
        map((x) => {
          return { success: true, version: x.version, samlProviders: x.samlProviders };
        }),
        catchError(() => {
          return of({ success: false });
        })
      );
  }

  async fileCounts(path: string) {
    let files: ListAllOutput | null = null;
    try {
      files = await list({
        prefix: path,
        options: {
          listAll: true,
        },
      });
    } catch (error) {
      console.error(error);
    }
    return files?.items?.length ?? 0;
  }

  async getFilesByFilters(
    path?: string,
    documentEntityId?: string,
    documentEntityTypeId?: string,
    documentTypeId?: string,
    documentEntityTypeIds?: string[],
    siteId?: string
  ): Promise<DocumentLibraryFile[]> {
    const filterModel = {
      entity_id: {},
      entity_type_id: {},
      document_type_id: {},
      bucket_key: {},
      site_id: {},
    };
    if (documentEntityId || documentEntityTypeIds) {
      const values = documentEntityTypeIds ? documentEntityTypeIds : [documentEntityId];

      filterModel.entity_id = {
        filterType: 'set',
        values,
      };
    }
    if (documentEntityTypeId) {
      filterModel.entity_type_id = {
        filterType: 'set',
        values: [documentEntityTypeId],
      };
    }
    if (documentTypeId) {
      filterModel.document_type_id = { filterType: 'set', values: [documentTypeId] };
    }
    if (siteId) {
      filterModel.site_id = {
        filterType: 'set',
        values: [siteId],
      };
    }
    if (path) {
      filterModel.bucket_key = { filterType: 'text', type: 'contains', filter: path };
    }
    return firstValueFrom(
      this.documentLibraryService.getDocumentLibraryList(JSON.stringify(filterModel))
    );
  }

  // TODO: deprecated method, use getFilesByFilters instead
  // async listFiles(
  //   path: string,
  //   documentEntityId?: string,
  //   documentEntityTypeId?: string,
  //   documentTypeId?: string,
  //   withMetadata = false
  // ): Promise<AwsFile[]> {
  //   const filterModel = { entity_id: {}, entity_type_id: {}, document_type_id: {}, bucket_key: {} };
  //   if (documentEntityId) {
  //     filterModel.entity_id = { filterType: 'set', values: [documentEntityId] };
  //   }
  //   if (documentEntityTypeId) {
  //     filterModel.entity_type_id = { filterType: 'set', values: [documentEntityTypeId] };
  //   }
  //   if (documentTypeId) {
  //     filterModel.document_type_id = { filterType: 'set', values: [documentTypeId] };
  //   }
  //   filterModel.bucket_key = { filterType: 'text', type: 'contains', filter: path };
  //
  //   const documentLibraryDocs = await firstValueFrom(
  //     this.documentLibraryService.getDocumentLibraryList(JSON.stringify(filterModel))
  //   );
  //
  //   let filteredFiles: S3ProviderListOutputItem[] = [];
  //   try {
  //     let files = await Storage.list(path);
  //
  //     documentLibraryDocs.forEach((doc) => {
  //       const file = files.results.find((f) => doc.bucket_key.includes(f.key!));
  //       if (file) {
  //         filteredFiles.push({
  //           ...file,
  //           // id: doc.id,
  //           // created_by: doc.created_by,
  //           // created_date: doc.create_date,
  //         });
  //       }
  //     });
  //     filteredFiles.map((x) => {
  //       const file = { ...x };
  //       // const arr = file.key!.split('/');
  //       // file.fileName = arr[arr.length - 1].slice(25);
  //       return file;
  //     });
  //
  //     const promises = [];
  //     if (withMetadata) {
  //       const tempFiles: S3ProviderListOutputItem[] = [];
  //       for (const file of filteredFiles) {
  //         promises.push(
  //           this.metadataOfFile(file.key!).then((metadata) => {
  //             tempFiles.push({ ...file, metadata });
  //             // file.metadata = value.Metadata;
  //           })
  //         );
  //       }
  //       await batchPromises(promises, (p) => p);
  //       filteredFiles = tempFiles;
  //     }
  //   } catch (e) {
  //     // eslint-disable-next-line no-alert
  //     alert(e);
  //   }
  //   return filteredFiles;
  // }

  // async metadataOfFile(key: string) {
  //   const ss = Storage.getPluggable('AWSS3');
  //   await ss._ensureCredentials();
  //   const s3Client = ss._createNewS3Client(ss._config);
  //   const headObjectCommand = new HeadObjectCommand({
  //     Bucket: ss._config.bucket,
  //     Key: `public/${key}`,
  //   });
  //   const dd = await (s3Client as S3Client).send(headObjectCommand);
  //   return dd.Metadata;
  // }

  // async updateMetadataOfFile(key: string, metadata: any) {
  //   const ss = Storage.getPluggable('AWSS3');
  //   await ss._ensureCredentials();
  //   const s3Client = ss._createNewS3Client(ss._config);
  //   const copyObjectCommand = new CopyObjectCommand({
  //     Bucket: ss._config.bucket,
  //     CopySource: `${ss._config.bucket}/public/${key}`,
  //     Key: `public/${key}`,
  //     Metadata: metadata,
  //     MetadataDirective: 'REPLACE',
  //   });
  //
  //   await (s3Client as S3Client).send(copyObjectCommand);
  // }

  // async getInstructionFile() {
  //   const ss = Storage.getPluggable('AWSS3');
  //   await ss._ensureCredentials();
  //   const s3Client = ss._createNewS3Client(ss._config);
  //   const file = await s3Client.send(
  //     new GetObjectCommand({
  //       Bucket: `auxilius-common-documents`,
  //       Key: `budget_upload_instructions.pdf`,
  //     })
  //   );
  //   if (file.Body) {
  //     this.downloadBlob(file.Body, 'Budget Template Instructions.pdf');
  //   }
  // }

  async uploadFile(fileName: string, file: File, metadata: FileMetadata = {}) {
    let success = true;
    try {
      await uploadData({
        key: fileName,
        data: file,
        options: {
          metadata,
        },
      }).result;
    } catch (e) {
      success = false;
      // eslint-disable-next-line no-alert
      alert(e);
    }
    return success;
  }

  // period must be MMM-YYYY format for file to be picked up in a zip process
  // async addFileToMonthlyReportsList(trialKey: string, period: string, fileKey: string) {
  //   let success = true;
  //   try {
  //     await Storage.put(
  //       `trials/${trialKey}/monthly_report_references/${period}/${fileKey}`,
  //       fileKey
  //     );
  //   } catch (e) {
  //     success = false;
  //   }
  //   return success;
  // }

  // period must be MMM-YYYY format for file to be picked up in zip process
  async removeFileFromMonthlyReportsList(trialKey: string, period: string, fileKey: string) {
    let success = true;
    try {
      await remove({
        key: `trials/${trialKey}/monthly_report_references/${period}/${fileKey}`,
      });
    } catch (e) {
      success = false;
    }
    return success;
  }

  async removeFile(fileName: string) {
    let success = true;
    try {
      await remove({ key: fileName });
    } catch (e) {
      success = false;
      // eslint-disable-next-line no-alert
      alert(e);
    }
    return success;
  }

  downloadBlob(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }

  async getTemplatePath(vendor_id: string | null, template_type: TemplateType, payload?: string) {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.getTemplatePath$({
        vendor_id,
        template_type,
        payload,
      })
    );

    if (!success) {
      this.overlayService.error(errors);
    }
    return { success, errors, data };
  }

  async downloadFile(value: AwsFile) {
    try {
      if (!value.key) {
        throw new Error('file not found');
      }

      const res = await downloadData({ key: value.key }).result;

      if (res.body) {
        this.downloadBlob(await res.body.blob(), value.fileName);
      }
    } catch (e: unknown) {
      this.overlayService.error((e as Error)?.message);
    }
  }

  async getS3ZipFile(path: string, vendor_id: string | null = null) {
    const key = path.includes('public/') ? path : `public/${path}`;
    const { success, data, errors } = await firstValueFrom(
      this.gqlService.getS3Archive$({ key, vendor_id })
    );
    return { success, data: (data?.id || '').replace('public/', ''), errors };
  }

  async getFileAsJson<T>(path: string) {
    try {
      return this.getFileAsJsonWithErrors<T>(path);
    } catch (e: unknown) {
      this.overlayService.error((e as Error)?.message);
    }
    return null;
  }

  async getFileAsJsonWithErrors<T>(path: string) {
    const res = await downloadData({ key: path.replace('public/', '') }).result;
    const text = await res.body.text();

    let json = {};
    try {
      json = JSON.parse(text);
    } catch (e) {
      console.error(e);
    }

    return json as T;
  }

  async downloadZipOrFile(path: string, fileName: string, numberOfCharactersToSlice = 25) {
    if (path.endsWith('.zip')) {
      return this.downloadFileFromPath(path, `${fileName}.zip`);
    }

    const fileWithPrefix = last(path.split('/'));
    return this.downloadFileFromPath(path, fileWithPrefix?.slice(numberOfCharactersToSlice));
  }

  async downloadFileFromPath(path: string, fileName = '') {
    try {
      let truePath = path;
      if (path.startsWith('/')) {
        truePath = path.substr(1);
      }
      const res = await downloadData({ key: truePath }).result;

      if (res.body) {
        if (fileName) {
          const arr = fileName.split('.');
          const fileExtension = arr.pop();
          /* eslint-disable no-param-reassign */
          fileName = arr.join('.');

          // Some filenames created with user inputted values,
          // in this regex we are replacing special characters with '_'
          fileName = fileName.replace(/[^a-zA-Z0-9-.]/g, '_');
          fileName = `${fileName}.${fileExtension}`;
        } else {
          const fileStr = truePath.split('/').pop();
          if (fileStr) {
            fileName = fileStr;
          } else {
            fileName = truePath;
          }
        }
        /* eslint-enable no-param-reassign */
        this.downloadBlob(await res.body.blob(), fileName);
      }
    } catch (e: unknown) {
      this.overlayService.error((e as Error)?.message);
    }
  }
}
