<aux-workflow-panel
  *ngIf="(isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)"
  [className]="'mb-4'"
  [workflowName]="workflowName"
  [isAdminUser]="(isAdminUser$ | async) === true"
  [processing]="(isCloseMonthsProcessing$ | async)!"
  [hasUserPermissions]="userHasLockPatientDataPermission"
/>

<aux-investigator-transactions-header
  [isContractSelected$]="isContractSelected$"
  [gridAPI]="gridAPI"
/>

<div class="flex">
  <aux-investigator-transactions-filters
    class="flex-1"
    [filterLoading$]="filterLoading$"
    [showSourceColumn$]="showSourceColumn$"
    [gridStabilized$]="gridStabilized$"
    (transactionFilterChange)="transactionFilterChange($event)"
  />

  <div class="flex flex-col-reverse">
    <aux-button
      *auxAuthorize="{ sysAdminsOnly: true }"
      variant="secondary"
      label="Import Patient Data"
      classList="mb-4"
      [disabled]="isPatientsFinalized$ | async"
      [onClick]="openImportPatientDataModal.bind(this)"
      [auxTooltip]="importPatientDataTooltip(isPatientsFinalized$ | async)"
    />
  </div>
</div>

<ng-container *ngIf="(gridRefresh$ | async) === false">
  <ag-grid-angular
    class="ag-theme-aux mb-8 tabular-nums w-full"
    domLayout="autoHeight"
    [gridOptions]="$any(gridOptions$ | async)"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="gridSizeChanged()"
    (viewportChanged)="onWindowScroll()"
    (firstDataRendered)="onFirstData()"
  />
  <aux-pagination-panel
    *ngIf="!!gridAPI?.getRenderedNodes()?.length"
    [gridApi]="gridAPI"
    [totalItems]="(totalItems$ | async)!"
  />
</ng-container>

<ng-container *ngIf="(gridRefresh$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>
