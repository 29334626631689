import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PeriodType } from '@services/utils';
import {
  BudgetCompareMenuPeriodFormControl,
  BudgetCompareMenuPeriodList,
  BudgetCompareMenuSnapshotChangeFn,
  BudgetCompareMenuSnapshotDisabled,
  BudgetCompareMenuSnapshotValue,
  BudgetCompareMenuSnapshotRefreshFn,
  BudgetCompareMenuSnapshotShowDelete,
  BudgetCompareMenuSnapshotShowEdit,
  BudgetCompareMenuVendorChangeFn,
  BudgetCompareMenuVendorFormControl,
  defaultPeriodList,
  defaultSnapshotChangeFn,
  defaultSnapshotRefreshFn,
  defaultVendorChangeFn,
  BudgetCompareMenuFlatten$,
  BudgetCompareMenuSnapshotList,
  BudgetCompareMenuSnapshotFormControl,
  BudgetCompareMenuSnapshotLoading,
  BudgetCompareMenuVendorLoading,
} from './budget-compare-menu.models';

@UntilDestroy()
@Component({
  selector: 'aux-budget-compare-menu',
  templateUrl: './budget-compare-menu.component.html',
  styleUrls: ['./budget-compare-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetCompareMenuComponent {
  @Input() vendorFormControl: BudgetCompareMenuVendorFormControl;

  @Input() vendorList: BudgetCompareMenuFlatten$<typeof this.organizationQuery.allVendors$>;

  @Input() vendorLoading: BudgetCompareMenuVendorLoading;

  @Input() vendorChangeFn: BudgetCompareMenuVendorChangeFn;

  @Input() snapshotFormControl: BudgetCompareMenuSnapshotFormControl;

  @Input() snapshotList: BudgetCompareMenuSnapshotList;

  @Input() snapshotValue: BudgetCompareMenuSnapshotValue;

  @Input() snapshotDisabled: BudgetCompareMenuSnapshotDisabled;

  @Input() snapshotShowEdit: BudgetCompareMenuSnapshotShowEdit;

  @Input() snapshotShowDelete: BudgetCompareMenuSnapshotShowDelete;

  @Input() snapshotLoading: BudgetCompareMenuSnapshotLoading;

  @Input() snapshotChangeFn: BudgetCompareMenuSnapshotChangeFn;

  @Input() snapshotRefreshFn: BudgetCompareMenuSnapshotRefreshFn;

  @Input() periodFormControl: BudgetCompareMenuPeriodFormControl;

  @Input() periodList: BudgetCompareMenuPeriodList;

  constructor(private organizationQuery: OrganizationQuery) {
    this.vendorFormControl = new FormControl('', {
      nonNullable: true,
    });
    this.vendorList = [];
    this.vendorLoading = false;
    this.vendorChangeFn = defaultVendorChangeFn;

    this.snapshotFormControl = new FormControl('', {
      nonNullable: true,
    });
    this.snapshotList = [];
    this.snapshotValue = '';
    this.snapshotDisabled = false;
    this.snapshotShowEdit = true;
    this.snapshotShowDelete = true;
    this.snapshotLoading = false;
    this.snapshotChangeFn = defaultSnapshotChangeFn;
    this.snapshotRefreshFn = defaultSnapshotRefreshFn;

    this.periodFormControl = new FormControl<string>(PeriodType.PERIOD_MONTH, {
      nonNullable: true,
    });
    this.periodList = defaultPeriodList;
  }
}
