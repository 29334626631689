import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';

export interface VisibleColumns {
  overall_budget: {
    primary: boolean;
    unit_cost: boolean;
    units: boolean;
    original: boolean;
    var_cost: boolean;
    var_perc: boolean;
  };
  remaining_budget: {
    perc: boolean;
    units: boolean;
    costs: boolean;
  };
  actuals_to_date: {
    perc: boolean;
    costs: boolean;
    units: boolean;
  };
  current_period: {
    months: boolean;
    quarters: boolean;
  };
  historicals: {
    months: boolean;
    quarters: boolean;
    years: boolean;
  };
  forecast: {
    months: boolean;
    quarters: boolean;
    years: boolean;
  };
}

@Component({
  selector: 'aux-column-chooser',
  templateUrl: './column-chooser.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnChooserComponent {
  columns!: VisibleColumns;

  defaultColumns: VisibleColumns = {
    overall_budget: {
      primary: true,
      unit_cost: false,
      units: true,
      original: true,
      var_cost: true,
      var_perc: true,
    },
    remaining_budget: {
      perc: true,
      units: true,
      costs: true,
    },
    actuals_to_date: {
      perc: true,
      costs: true,
      units: true,
    },
    current_period: {
      months: true,
      quarters: true,
    },
    historicals: {
      months: true,
      quarters: true,
      years: true,
    },
    forecast: {
      months: true,
      quarters: true,
      years: true,
    },
  };

  constructor(
    public ref: CustomOverlayRef<unknown, { columns: VisibleColumns }>,
    private cdr: ChangeDetectorRef
  ) {
    this.columns = this.ref.data?.columns || this.defaultColumns;
  }

  isGroupChecked(group: keyof VisibleColumns): boolean {
    return Object.values({ ...this.defaultColumns[group], ...this.columns?.[group] }).every(
      (el) => el
    );
  }

  isGroupIndeterminate(group: keyof VisibleColumns): boolean {
    return (
      Object.values({ ...this.defaultColumns[group], ...this.columns?.[group] }).some((el) => el) &&
      !this.isGroupChecked(group)
    );
  }

  onGroupChange(group: keyof VisibleColumns) {
    const columnGroup = { ...this.defaultColumns[group], ...this.columns?.[group] };
    const newValue = !this.isGroupChecked(group);
    const newGroup = Object.keys(columnGroup).reduce(
      (acc, key: string) => ({
        ...acc,
        [key]: newValue,
      }),
      {}
    );
    Object.assign(this.columns, { [group]: newGroup });
    this.cdr.detectChanges();
  }

  onConfirm() {
    this.ref.close(this.columns);
  }
}
