import { Injectable } from '@angular/core';
import { TrialInsightsChartModel } from '../../models/trial-insights-chart.model';
import {
  ChartConfiguration,
  ChartDataset,
  ElementOptionsByType,
  PluginOptionsByType,
  ScaleChartOptions,
  TooltipOptions,
} from 'chart.js';
import { DeepPartial } from 'chart.js/dist/types/utils';

export type TIFPEChartType = 'bar';
export type TIFPEChartLabel = [string, number][];

@Injectable()
export class TrialInsightsFinancePatientsEnrolledChartService
  implements TrialInsightsChartModel<TIFPEChartType, TIFPEChartLabel>
{
  createDatasets = (labels: TIFPEChartLabel) => {
    const datasets: ChartDataset<TIFPEChartType>[] = [];

    if (!labels?.length) {
      return datasets;
    }

    const colors = ['#138EE7'];
    const hoverColors = ['#138EE7'];

    labels.forEach(([label, percent], index) => {
      datasets.push({
        label: label,
        data: [percent],
        backgroundColor: [colors[index]],
        hoverBackgroundColor: [hoverColors[index]],
        barThickness: 85,
        xAxisID: 'x1',
      });
    });

    return datasets;
  };

  createChart = (
    datasets?: ChartDataset<TIFPEChartType>[],
    maxXAxes = 100
  ): ChartConfiguration<TIFPEChartType> => {
    return {
      type: 'bar',
      options: this.chartOptions(maxXAxes),
      data: {
        labels: this.labelOptions(),
        datasets: datasets ? datasets : [],
      },
    };
  };

  chartOptions = (maxXAxes?: number): ChartConfiguration<TIFPEChartType>['options'] => {
    return {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      elements: this.elementOptions(),
      scales: {
        ...this.xAxesOptions(maxXAxes).scales,
        ...this.yAxesOptions().scales,
      },
      plugins: this.pluginOptions(),
    };
  };

  labelOptions = () => {
    return ['Patients Enrolled'];
  };

  elementOptions = (): DeepPartial<ElementOptionsByType<TIFPEChartType>> => {
    return {
      bar: {
        borderColor: '#bacad0',
      },
    };
  };

  pluginOptions = (): DeepPartial<PluginOptionsByType<TIFPEChartType>> => {
    return {
      legend: {
        display: false,
      },
      tooltip: this.tooltipOptions(),
    };
  };

  tooltipOptions = (): DeepPartial<TooltipOptions<TIFPEChartType>> => {
    return {
      callbacks: {
        label: (tooltipItem) => {
          return `${tooltipItem.label}: ${tooltipItem.raw}%`;
        },
      },
    };
  };

  xAxesOptions = (maxXAxes?: number): DeepPartial<ScaleChartOptions<TIFPEChartType>> => {
    return {
      scales: {
        x1: {
          stacked: true,
          beginAtZero: true,
          max: maxXAxes,
          title: {
            display: false,
          },
          ticks: {
            display: false,
            stepSize: 10,
          },
          grid: {
            display: false,
            color: '#bacad0',
            lineWidth: 0.75,
            z: 1,
          },
        },
      },
    };
  };

  yAxesOptions = (): DeepPartial<ScaleChartOptions<TIFPEChartType>> => {
    return {
      scales: {
        y: {
          display: false,
          stacked: true,
        },
      },
    };
  };
}
