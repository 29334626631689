import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components/components.module';
import { PipeModule } from '@pipes/pipe.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChecklistRowInfoComponent } from './components/checklist-row-info/checklist-row-info.component';
import { ChecklistRowTitleComponent } from './components/checklist-row-title/checklist-row-title.component';
import { QuarterCloseChecklistService } from './services/quarter-close-checklist.service';
import { QuarterCloseChecklistToggleService } from './services/quarter-close-checklist-toggle.service';
import { QuarterCloseChecklistComponent } from './quarter-close-checklist.component';
import { ChecklistSectionGatherContractsComponent } from './components/checklist-section-gather-contracts/checklist-section-gather-contracts.component';
import { ChecklistSectionGatherEstimatesComponent } from './components/checklist-section-gather-estimates/checklist-section-gather-estimates.component';
import { QuarterCloseChecklistDirective } from './directives/quarter-close-checklist.directive';
import { QuarterCloseChecklistWorkflowService } from './services/quarter-close-checklist-workflow.service';
import { ChecklistSectionDiscountComponent } from './components/checklist-section-discount/checklist-section-discount.component';
import { ChecklistRowInfoGatherInvoicesComponent } from './components/checklist-row-info-gather-invoices/checklist-row-info-gather-invoices.component';
import { FirstNameShowComponent } from '@components/first-name-show/first-name-show.component';
import { DirectivesModule } from '@directives/directives.module';
import { ChecklistBannerComponent } from './components/checklist-banner/checklist-banner.component';
import { IconComponent } from '@components/icon/icon.component';
import { AssignMonthCloseComponent } from '../../assign-month-close/assign-month-close.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    PipeModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    FirstNameShowComponent,
    DirectivesModule,
    IconComponent,
    AssignMonthCloseComponent,
  ],
  providers: [
    QuarterCloseChecklistService,
    QuarterCloseChecklistToggleService,
    QuarterCloseChecklistWorkflowService,
    // Importing this provider at the app level,
    // so that period-close.component can use it as well
    // QuarterCloseChecklistPeriodCloseService
  ],
  declarations: [
    QuarterCloseChecklistComponent,
    QuarterCloseChecklistDirective,
    ChecklistRowTitleComponent,
    ChecklistRowInfoComponent,
    ChecklistBannerComponent,
    ChecklistRowInfoGatherInvoicesComponent,
    ChecklistSectionGatherContractsComponent,
    ChecklistSectionGatherEstimatesComponent,
    ChecklistSectionDiscountComponent,
  ],
  exports: [QuarterCloseChecklistComponent],
})
export class QuarterCloseChecklistModule {}
