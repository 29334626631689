<div class="trial-insights-component relative">
  <!-- Header -->
  <aux-trial-insights-header [title]="title" [header]="total" [color]="color">
    <!-- Icon -->
    <ng-container icon>
      <aux-icon name="FileMinus" [size]="35" [ngStyle]="{ color: color }" />
    </ng-container>
  </aux-trial-insights-header>

  <!-- Legend -->
  <aux-trial-insights-legend [options]="legendOptions" />

  <!-- Divider -->
  <div class="trial-insights-divider full"></div>

  <!-- Table -->
  <aux-trial-insights-table
    [options]="tableOptions"
    [isLoadingRemaining]="isLoadingRemaining"
    [selectedKey]="selectedKey"
    [sortOrder]="sortOrder"
    [changeSelectedKey]="queryService.changeSelectedKey"
    [toggleSortOrder]="queryService.toggleSortOrder"
  />
</div>
