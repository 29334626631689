import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { isArray } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';

export interface ICellWrapperParams extends ICellRendererParams {
  customLocator?: string;
  isExpandableColumn?: boolean;
  className?: string;
  modelUpdated$?: Observable<boolean>;
}

@Component({
  selector: 'aux-ag-cell-wrapper',
  template: `<p [attr.auto-qa]="autoTestAttribute" [ngClass]="[cellClass]">
    {{ this.value }}
  </p>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCellWrapperComponent implements ICellRendererAngularComp {
  params!: ICellWrapperParams;

  group!: boolean;

  cellClass!: string;

  value!: string | number;

  autoTestAttribute!: string;

  currentIndex?: number;

  modelUpdatedSubscription?: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  agInit(params: ICellWrapperParams): void {
    this.params = params;
    const cellValue = this.params.valueFormatted || this.params.value;
    this.group = !!this.params.node.group;
    this.value = cellValue;

    this.cellClass = this.params.className || '';

    this.cellClass +=
      this.params.isExpandableColumn && !this.group
        ? ` ag-row-group-leaf-indent ag-row-group-indent-${this.params.node.level} `
        : '';

    if (typeof this.params?.colDef?.cellClass !== 'function') {
      this.cellClass += this.getClassName(this.params?.colDef?.cellClass || '');
    }

    this.cellClass = this.cellClass.trim();

    if (params.customLocator || params.colDef?.field) {
      this.autoTestAttribute = this.getAutoTestAttribute(params);
    }

    if (params.modelUpdated$ && !this.modelUpdatedSubscription) {
      this.subscribeToModelUpdated(params.modelUpdated$);
    }
  }

  subscribeToModelUpdated(modelUpdated$: Observable<boolean>) {
    this.modelUpdatedSubscription = modelUpdated$.subscribe(() => {
      if (this.autoTestAttribute && this.currentIndex !== this.params.node.rowIndex) {
        this.autoTestAttribute = this.getAutoTestAttribute(this.params);
        this.cdr.markForCheck();
      }
    });
  }

  destroy(): void {
    if (this.modelUpdatedSubscription) {
      this.modelUpdatedSubscription.unsubscribe();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(params: ICellWrapperParams): boolean {
    return false;
  }

  getAutoTestAttribute(params: ICellWrapperParams): string {
    const isTotalCell = params.node.rowPinned === 'bottom';
    const field = params.colDef?.field || '';
    const fieldName = params.customLocator || field;

    this.currentIndex = params.node.rowIndex || 0;

    return isTotalCell ? `${fieldName}_total` : `${fieldName}_${this.currentIndex}`;
  }

  getClassName(cellClass: string[] | string): string {
    if (isArray(cellClass)) {
      return cellClass.reduce((accum, className) => {
        return `${accum} ${className}`;
      }, '');
    }

    return cellClass;
  }
}

export const getWrapperCellOptions = (modelUpdated$?: Observable<boolean>): ColDef => {
  return {
    cellRendererSelector: (params) => {
      const isGrouped = !!params.node.group;

      return {
        component: isGrouped ? 'agGroupCellRenderer' : AgCellWrapperComponent,
        params: { isExpandableColumn: true, className: 'text-left', modelUpdated$ },
      };
    },
    cellRenderer: AgCellWrapperComponent,
  };
};
