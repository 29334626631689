<input
  type="checkbox"
  class="w-4 h-4 text-indigo-600 border-gray-300 rounded cursor-pointer focus:ring-indigo-500"
  [id]="id"
  [name]="id"
  [disabled]="disabled"
  [ngClass]="{ 'cursor-not-allowed bg-gray-100': disabled }"
  [indeterminate]="indeterminate"
  [(ngModel)]="checked"
  (ngModelChange)="customChange.emit($event); onChangeCallback?.($event)"
/>
<label class="block ml-1.5 cursor-pointer" [for]="id"><ng-content></ng-content></label>
