<aux-workflow-panel
  *ngIf="(isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)"
  className="mb-7"
  [workflowName]="workflowName"
  [isAdminUser]="isAdminUser"
  [processing]="(iCloseMonthsProcessing$ | async)!"
  [hasUserPermissions]="userHasLockPatientDataPermission"
/>

<div class="flex justify-between items-center my-5">
  <div class="flex space-x-4">
    <aux-card
      topText="Patient Visits LTD"
      [middleText]="totalPatientVisitsLTD$ | async | money"
      className="min-w-[230px]"
    />

    <aux-card
      *ngIf="isPlannedVisitsEnabled$ | async"
      [middleText]="plannedThroughCurrentMonth$ | async | money"
      className=" min-w-[230px]"
      [bottomTemplate]="showPlannedVisits"
      [ngClass]="{ 'bg-aux-green-2': showPlannedVisitsControl.value }"
    >
      <div topText class="flex justify-between items-center">
        <div class="text-sm text-aux-gray-dark-100">
          {{ 'Planned through ' + $any(currentOpenMonth$ | async | date: 'MMMM') }}
        </div>
        <aux-icon name="AuxLogo" [size]="17.5" />
      </div>
    </aux-card>

    <ng-template #showPlannedVisits>
      <aux-checkbox
        class="mr-3"
        [formControl]="showPlannedVisitsControl"
        [ngClass]="{ 'selected-card': showPlannedVisitsControl.value }"
      >
        <div class="text-sm">Show planned visits</div>
      </aux-checkbox>
    </ng-template>
  </div>

  <aux-export-excel-button
    [gridAPI]="exportGridApi"
    [ignoreColsId]="['site_id']"
    [getDynamicExcelParamsCallback]="getDynamicExcelParams"
  />
</div>

<ng-container *ngIf="(isHandlingUpload$ | async) === false && (isLoadingData$ | async) === false">
  <ng-container *ngIf="(showGrid$ | async) === false">
    <div class="flex items-center justify-center flex-col text-aux-gray-dark mt-28 space-y-5">
      <aux-icon name="FileDescription" [size]="70" />
      <div class="text-xl font-bold">No Patient Data to Review</div>
    </div>
  </ng-container>

  <ng-container *ngIf="showGrid$ | async">
    <div *ngIf="showAnalyticsSection$ | async" class="grid grid-cols-3 gap-7.5 mb-8 mt-4">
      <div
        *ngFor="let card of cards$ | async; let i = index"
        class="border border-aux-gray-dark rounded p-5 h-40 flex flex-col justify-between tabular-nums"
        [attr.data-index]="i"
      >
        <div class="font-semibold text-aux-black h-14 w-72 pb-2">{{ card.header }}</div>
        <div
          class="flex justify-around items-center self-start"
          [ngClass]="{
            '-mt-3': i === 1
          }"
        >
          <div
            class="text-aux-gray-darkest justify-start font-bold text-xl w-44"
            [innerHTML]="card.data"
          ></div>
          <div class="flex justify-center flex-col">
            <div
              *ngFor="let prop of [card.firstProp, card.secondProp]; last as last"
              class="flex items-center"
              [ngClass]="{
                'mb-2': !last
              }"
            >
              <div *ngIf="prop.status && (prop.status === 'high' || prop.status === 'low')">
                <svg
                  class="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  [ngClass]="{
                    'text-aux-error': prop.status && prop.status === 'high',
                    'text-aux-green transform rotate-180': prop.status && prop.status === 'low'
                  }"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <span *ngIf="prop.status && prop.status === 'middle'" class="text-yellow-300">
                —
              </span>
              <div
                class="ml-1 font-medium text-aux-gray-darkest tracking-tighter text-sm whitespace-nowrap"
              >
                {{ prop.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <form
      [formGroup]="gridFiltersFormGroup"
      class="px-2 py-4 rounded-xl border border-[#E5E5E5] bg-[#FDFDFE]"
    >
      <div class="mb-4 flex justify-between items-center space-x-8">
        <div class="flex space-x-8">
          <div class="flex items-center">
            <div class="mr-2 text-sm">Site:</div>
            <aux-multi-select-dropdown
              class="w-48 2xl:w-72"
              placeholder="Please select site(s)"
              bindLabel="textToSearch"
              bindValue="value"
              [items]="$any(siteOptions$ | async)"
              [searchable]="true"
              [customOption]="true"
              formControlName="siteIds"
            />
          </div>

          <div class="flex items-center">
            <div class="text-sm mr-2">Protocol Version:</div>
            <ng-select
              class="w-48 2xl:w-72"
              placeholder="Select version"
              [searchable]="false"
              [clearable]="false"
              bindValue="value"
              [formControl]="protocolVersionControl"
              [items]="protocolVersionOptions$ | async"
            />
          </div>

          <div class="flex items-center">
            <div class="mr-2 text-sm">Group:</div>
            <ng-select
              class="w-48 2xl:w-72"
              placeholder="Select group"
              [searchable]="false"
              [clearable]="false"
              bindValue="value"
              formControlName="patientGroupId"
              [items]="patientGroupOptions$ | async"
            >
            </ng-select>
          </div>

          <div class="flex space-x-2 text-sm items-center">
            <div>Dates</div>
            <aux-toggle
              [value]="isDisplayCosts"
              (change)="display$.next($event ? 'costs' : 'dates')"
            />
            <div>Costs</div>
          </div>

          <div class="flex text-sm items-center">
            <aux-toggle-currency
              [value]="isContractedCurrency"
              (nameSelectedCurrency)="selectedVisibleCurrency$.next($event)"
            />
          </div>
        </div>
      </div>
    </form>

    <aux-pagination-grid
      [gridOptions]="$any(gridOptions$ | async)"
      [exportGridOptions]="(exportGridOptions$ | async)!"
      [dataSource]="datasource"
      [filterForm]="gridFiltersFormGroup"
      [serverSideFilters]="serverSideFilters"
      [filterValues$]="filterValues$"
      [sortModel$]="sortModel$"
      [idTable]="'patientTrackerTable'"
      (paginationChange)="onPaginationChange()"
      (gridReady)="onGridReady($event)"
    />
    <a class="aux-link text-xs mb-16 inline-block" [routerLink]="[protocolEntryLink]"
      >View Schedule of Assessments</a
    >
  </ng-container>
</ng-container>
