import { ChangeDetectionStrategy, Component, HostListener, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Utils } from '@services/utils';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationStore } from '@models/organization/organization.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ExcelCell,
  ExcelExportParams,
  FirstDataRenderedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ExcelButtonVariant } from '@components/export-excel-button/export-excel-button.component';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import {
  MonthCloseTableRowData,
  PeriodCloseComponent,
  QuarterDate,
} from '../../period-close.component';
import { TableConstants } from '@constants/table.constants';
import { StickyElementService } from '@services/sticky-element.service';
import { get, omit, uniq } from 'lodash-es';
import { Currency } from '@services/gql.service';
import { QuarterCloseChecklistPeriodCloseService } from '../quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import { AuxExcelStyles } from '@shared/utils';

@UntilDestroy()
@Component({
  selector: 'aux-reconciliation',
  templateUrl: './reconciliation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host ::ng-deep aux-vendor-dropdown .ng-dropdown-panel {
        left: -150px;
      }
    `,
  ],
})
export class ReconciliationComponent implements OnDestroy {
  exportButtonVariant = ExcelButtonVariant.FILLED;

  defaultColumns: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Vendor',
      field: 'vendor_name',
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Vendor',
      field: 'vendor_id',
      hide: true,
    },
    {
      headerName: 'Cost Category',
      field: 'cost_category',
      hide: true,
    },
  ];

  gridAPI!: GridApi<MonthCloseTableRowData>;

  gridAPI$: ReplaySubject<GridApi> = new ReplaySubject<GridApi>(1);

  gridOptions$ = new BehaviorSubject<GridOptions | null>(null);

  selectedVendor = new UntypedFormControl('');

  vendorCurrencies: Currency[] = [];

  currencyFormatter = (params: ValueFormatterParams) => {
    let currency = this.vendorCurrencies[0];
    if (this.selectedVendor.value) {
      currency =
        this.organizationQuery.getVendor(this.selectedVendor.value)[0]?.currency ||
        this.vendorCurrencies[0];
    } else if (params.data && params.data.contract_direct_cost_currency) {
      currency = params.data.contract_direct_cost_currency;
    } else if (params.node?.key) {
      currency =
        this.organizationQuery.getAll().filter((x) => x.name === params.node?.key)[0]?.currency ||
        this.vendorCurrencies[0];
    }
    return Utils.agCurrencyFormatterAccounting(params, currency);
  };

  getCellClass = () => (params: CellClassParams) => {
    if (params?.data?.contract_direct_cost_currency) {
      return [`budgetCost${params?.data?.contract_direct_cost_currency}`, 'ag-cell-align-right'];
    }
    return ['budgetCostNoSymbol', 'ag-cell-align-right'];
  };

  filteredGridData$ = combineLatest([
    this.periodCloseComponent.gridData$,
    this.organizationQuery.selectActiveId(),
  ]).pipe(
    map(([data, vendorId]) => {
      if (!vendorId) {
        return data;
      }

      return data.filter((row) => row.vendor_id === vendorId);
    })
  );

  excelOptions = {
    sheetName: 'Budget',
    fileName: 'auxilius-budget.xlsx',
    shouldRowBeSkipped(params) {
      return !params?.node?.data?.cost_category;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'vendor_name':
          return 280;
        case 'activity_name':
          return 490;
        default:
          return 105;
      }
    },
  } as ExcelExportParams;

  showAnalyticsSection$: Observable<boolean>;

  constructor(
    private organizationStore: OrganizationStore,
    public organizationQuery: OrganizationQuery,
    private launchDarklyService: LaunchDarklyService,
    public periodCloseComponent: PeriodCloseComponent,
    public periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private mainQuery: MainQuery,
    private stickyElementService: StickyElementService
  ) {
    this.showAnalyticsSection$ = this.launchDarklyService.select$(
      (flags) => flags.section_reconciliation_analytics
    );

    combineLatest([
      this.periodCloseComponent.budgetQuery.select(),
      this.periodCloseComponent.selectedQuarter.valueChanges.pipe(
        startWith(this.periodCloseComponent.selectedQuarter.value as string)
      ),
      this.periodCloseComponent.quartersObjUpdated$.pipe(startWith(null)),
    ])
      .pipe(
        map(([{ budget_info }]) => {
          this.vendorCurrencies = uniq(budget_info.map((x) => x.vendor_currency as Currency));
          const startingQuarter = this.periodCloseComponent.selectedQuarter.value || '';
          this.periodCloseService.selectedQuarterMonthChanged$.next(null);
          const months = this.periodCloseComponent.quartersObj[startingQuarter];

          const current_month = budget_info?.[0]?.current_month;

          if (!current_month || !months) {
            return [];
          }

          const getMonthHeader = (m: QuarterDate) => {
            return m.parsedDate.format('MMM');
          };

          const endingBalanceColumn = {
            headerName: 'Ending Balance',
            headerClass: 'ag-header-align-center balance',
            field: 'endingBalance',
            valueFormatter: this.currencyFormatter,
            children: [
              {
                headerName: ' ',
                field: 'eom_accruals.total',
                tooltipField: 'endingBalance',
                minWidth: 150,
                aggFunc: Utils.agSumFunc,
                valueFormatter: this.currencyFormatter,
                cellClass: this.getCellClass(),
              },
            ],
          };

          const columns = [
            {
              headerName: 'Work Performed Estimate',
              headerClass: 'ag-header-align-center in-month-accrual',
              minWidth: 180,
              children: months.map((month) => {
                return {
                  headerName: getMonthHeader(month),
                  field: `accrual_adjusted_obj.${month.date}`,
                  headerClass: 'ag-header-align-center',
                  minWidth: 100,
                  aggFunc: Utils.agSumFunc,
                  valueFormatter: this.currencyFormatter,
                  cellClass: this.getCellClass(),
                };
              }),
            },
            {
              headerName: 'Invoices Received',
              headerClass: 'ag-header-align-center invoiced',
              maxWidth: 240,
              children: months.map((month) => {
                return {
                  headerName: getMonthHeader(month),
                  field: `invoice_amounts.${month.date}`,
                  headerClass: 'ag-header-align-center',
                  minWidth: 100,
                  aggFunc: Utils.agSumFunc,
                  valueFormatter: this.currencyFormatter,
                  cellClass: this.getCellClass(),
                };
              }),
            },
            {
              headerName: 'Net Change in Accruals / (Prepaid)',
              headerClass: 'ag-header-align-center in-month-accrual-net',
              minWidth: 180,
              children: months.map((month) => {
                return {
                  headerName: getMonthHeader(month),
                  field: `net_accruals.${month.date}`,
                  headerClass: 'ag-header-align-center',
                  minWidth: 100,
                  aggFunc: Utils.agSumFunc,
                  valueFormatter: this.currencyFormatter,
                  cellClass: this.getCellClass(),
                };
              }),
            },
            endingBalanceColumn,
          ];
          this.gridOptions$.next({
            ...this.gridOptions(),
            columnDefs: [...this.defaultColumns, ...columns],
            excelStyles: [
              ...AuxExcelStyles,
              ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS),
            ],
          });
          return [];
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  gridOptions: () => GridOptions = () => ({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      sortable: false,
    },
    autoGroupColumnDef: {
      headerName: 'Vendor / Cost Category',
      minWidth: 200,
      field: 'activity_name',
      colId: 'activity_name',
      tooltipField: 'activity_name',
      cellRendererParams: {
        suppressCount: true,
      },
    },
    suppressAggFuncInHeader: true,
    suppressCellFocus: true,
    groupDefaultExpanded: 1,
    suppressColumnVirtualisation: true,
    columnDefs: [],
    excelStyles: [
      ...AuxExcelStyles,
      {
        id: 'header',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#000' },
        interior: { color: '#98aebd', pattern: 'Solid', patternColor: '#999999' },
      },
      {
        id: 'headerGroup',
        font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
        interior: { color: '#999999', pattern: 'Solid', patternColor: '#999999' },
      },
      {
        id: 'cell',
        font: { fontName: 'Arial', size: 11 },
      },
    ],
  });

  firstDataRendered({ api }: { api: GridApi }) {
    api.sizeColumnsToFit();
  }

  onVendorSelected(vendorId: string) {
    this.organizationStore.setActive(vendorId || null);
    setTimeout(() => {
      this.setBottomData(!vendorId && this.vendorCurrencies.length !== 1);
    }, 0);
  }

  getTotalRowData() {
    const rowsData: MonthCloseTableRowData[] = [];

    this.gridAPI?.forEachNodeAfterFilter((node) => {
      if (node.data) {
        rowsData.push(node.data);
      }
    });

    const setValueForMonth =
      (
        resultObj: Record<string, Record<string, number>>,
        rowValues: MonthCloseTableRowData,
        month: string
      ) =>
      (key: 'accrual_adjusted_obj' | 'invoice_amounts' | 'net_accruals'): void => {
        resultObj[key][month] = (resultObj[key][month] || 0) + (rowValues[key][month] || 0);
      };

    return rowsData.reduce(
      (accum, data) => {
        const month = Object.keys(omit(data.accrual_adjusted_obj, 'total'));

        month.forEach((m) => {
          const setValue = setValueForMonth(accum, data, m);

          setValue('accrual_adjusted_obj');
          setValue('invoice_amounts');
          setValue('net_accruals');
        });

        accum.eom_accruals.total += data.eom_accruals.total;

        return accum;
      },
      {
        accrual_adjusted_obj: {},
        invoice_amounts: {},
        net_accruals: {},
        eom_accruals: {
          total: 0,
        },
      } as {
        accrual_adjusted_obj: Record<string, number>;
        invoice_amounts: Record<string, number>;
        net_accruals: Record<string, number>;
        eom_accruals: Record<string, number>;
      }
    );
  }

  setBottomData(removeBottomRow: boolean) {
    if (removeBottomRow) {
      this.gridAPI?.setGridOption('pinnedBottomRowData', []);
    } else {
      this.gridAPI?.setGridOption('pinnedBottomRowData', [
        {
          activity_name: 'Total',
          ...this.getTotalRowData(),
        },
      ]);
    }
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    let totalData:
      | {
          accrual_adjusted_obj: Record<string, number>;
          invoice_amounts: Record<string, number>;
          net_accruals: Record<string, number>;
          eom_accruals: Record<string, number>;
        }
      | undefined = undefined;
    let currencyForFormatting = Currency.USD;
    if (this.vendorCurrencies.length === 1 || this.selectedVendor.value) {
      if (this.vendorCurrencies.length === 1) {
        currencyForFormatting = this.vendorCurrencies[0];
      } else {
        currencyForFormatting =
          this.organizationQuery.getVendor(this.selectedVendor.value)[0]?.currency || Currency.USD;
      }
      totalData = this.getTotalRowData();
    }

    const trialName = this.mainQuery.getSelectedTrial()?.short_name;

    const rows = (this.gridAPI.getAllDisplayedColumns() || [])
      .filter((col) => {
        const columnName = col.getColId();
        return !!columnName && col.getColId() !== 'ag-Grid-AutoColumn';
      })
      .map<ExcelCell>((col) => {
        const columnName = col.getColId() as string;

        const val = get(totalData, columnName);

        return {
          data: { value: `${val}`, type: 'Number' },
          styleId: `total_row_${currencyForFormatting}`,
        };
      });

    return {
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trialName}`, type: 'String' },
              mergeAcross: rows.length + 1,
              styleId: 'first_row',
            },
          ],
        },
      ],
      appendContent:
        this.vendorCurrencies.length === 1 || this.selectedVendor.value
          ? [
              {
                cells: [
                  {
                    data: { value: `Total`, type: 'String' },
                    styleId: `total_row_${currencyForFormatting}`,
                    mergeAcross: 1,
                  },
                  ...rows,
                ],
              },
            ]
          : [],
    };
  };

  customColFilter = (cel: ColDef | ColGroupDef) => !!cel.headerName;

  onDataRendered(e: FirstDataRenderedEvent) {
    this.gridAPI = e.api;
    this.gridAPI$.next(e.api);
    const allColumnIds: string[] = [];
    this.gridAPI.getColumns()?.forEach((column) => {
      allColumnIds.push(column.getColId());
    });
    this.gridAPI.autoSizeColumns(allColumnIds, false);

    this.setBottomData(this.vendorCurrencies.length !== 1);
    this.gridAPI.sizeColumnsToFit();
  }

  onGridReady({ api }: GridReadyEvent) {
    api.sizeColumnsToFit();
  }

  autoSize() {
    this.gridAPI.sizeColumnsToFit();
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }
}
