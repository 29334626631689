<div class="font-inter w-screen max-w-2xl">
  <div class="text-xl font-bold mb-4">
    Bulk Edit: <span class="font-bold">{{ selectedRows.length }} Selected Documents</span>
  </div>
  <div class="flex border-b pb-4">
    <div class="w-56">
      <span class="text-xs mb-1">Document Type:</span>
      <ng-select
        placeholder="Select"
        [multiple]="false"
        [appendTo]="'body'"
        [searchable]="false"
        [clearable]="true"
        [(ngModel)]="selectedType"
      >
        <ng-option *ngFor="let option of documentTypeOptions" [value]="option.value">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
    <div class="w-56 pl-4">
      <span class="text-xs mb-1">Vendor:</span>
      <ng-select
        placeholder="Select"
        [multiple]="false"
        [appendTo]="'body'"
        [searchable]="false"
        [clearable]="true"
        [(ngModel)]="selectedVendor"
      >
        <ng-option *ngFor="let option of documentLibrary.vendors" [value]="option.value">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
    <div class="w-56 pl-4">
      <span class="text-xs mb-1">Site:</span>
      <ng-select
        placeholder="Select"
        [multiple]="false"
        [appendTo]="'body'"
        [searchable]="false"
        [clearable]="true"
        [(ngModel)]="selectedSite"
      >
        <ng-option *ngFor="let option of documentLibrary.sites" [value]="option.value">
          <span [title]="option.label">{{ option.label }}</span>
        </ng-option>
      </ng-select>
    </div>
  </div>

  <div class="flex justify-between mt-4">
    <aux-button variant="custom" classList="text-sm h-full" label="Cancel" (click)="ref.close()" />
    <aux-button variant="primary" label="Apply" [spinnerSize]="6" [onClick]="onApply" />
  </div>
</div>
