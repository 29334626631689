import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TrialsStore, TrialsState } from './trials.store';

@Injectable({ providedIn: 'root' })
export class TrialsQuery extends QueryEntity<TrialsState> {
  constructor(protected store: TrialsStore) {
    super(store);
  }
}
