import { Utils } from '@services/utils';
import { Currency } from '@services/gql.service';
import { ColDef, ColGroupDef, ValueFormatterParams } from '@ag-grid-community/core';
import { TableConstants } from '@constants/table.constants';
import { AgHeaderCollapseColumnsActionComponent } from './ag-header-collapse-columns-action/ag-header-collapse-columns-action.component';
import { AuxExcelStyleKeys } from '@shared/utils';

export const InvestigatorSummaryColumns = (
  siteNumberColumnSettings: ColDef,
  columns: ColDef[]
): (ColDef | ColGroupDef)[] => [
  {
    headerName: 'Patients & Sites',
    headerClass: 'ag-header-align-center',
    children: [
      {
        headerName: 'Site #',
        field: 'site_no',
        cellClass: AuxExcelStyleKeys.TEXT_FORMAT,
        rowGroup: true,
        hide: true,
        filter: true,
      },
      siteNumberColumnSettings,
      {
        headerName: 'Site Name',
        field: 'site_name',
        tooltipField: 'site_name',
        cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT, AuxExcelStyleKeys.TEXT_FORMAT],
        minWidth: 125,
        resizable: true,
        suppressSizeToFit: true,
        aggFunc: 'first',
        filter: true,
        valueFormatter: (value: ValueFormatterParams) => value.value || Utils.zeroHyphen,
      },
      {
        headerName: 'Primary Investigator',
        field: 'investigator',
        tooltipField: 'investigator',
        cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT, AuxExcelStyleKeys.TEXT_FORMAT],
        minWidth: 125,
        resizable: true,
        suppressSizeToFit: true,
        aggFunc: 'first',
        filter: true,
        valueFormatter: (value: ValueFormatterParams) => value.value || Utils.zeroHyphen,
      },
      {
        headerName: 'Patient ID',
        field: 'patient',
        tooltipField: 'patient',
        cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT, AuxExcelStyleKeys.TEXT_FORMAT],
        minWidth: 125,
        resizable: true,
        suppressSizeToFit: true,
        filter: true,
        valueFormatter: (value: ValueFormatterParams) => value.value || Utils.zeroHyphen,
      },
    ],
  },
  TableConstants.SPACER_COLUMN,
  {
    headerName: 'Totals',
    headerClass: 'ag-header-align-center',
    children: [
      ...columns,
      {
        headerName: 'Patient Visit Total',
        field: 'patient_visit_total',
        headerComponent: AgHeaderCollapseColumnsActionComponent,
        tooltipField: 'patient_visit_total',
        cellClass: ['cell-right', 'cost', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
        minWidth: 170,
        aggFunc: 'sum',
        resizable: true,
        valueFormatter: (params: Pick<ValueFormatterParams, 'value'>) =>
          params.value ? Utils.agCurrencyFormatter(params, Currency.USD) : Utils.zeroHyphen,
      },
      {
        headerName: 'Invoiceables',
        field: 'invoiceables',
        tooltipField: 'invoiceables',
        cellClass: ['cell-right', 'cost', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
        minWidth: 125,
        aggFunc: 'sum',
        resizable: true,
        valueFormatter: (params: Pick<ValueFormatterParams, 'value'>) =>
          params.value ? Utils.agCurrencyFormatter(params, Currency.USD) : Utils.zeroHyphen,
      },
      {
        headerName: 'Total Cost to Date',
        field: 'total_cost_to_date',
        tooltipField: 'total_cost_to_date',
        cellClass: ['cell-right', 'cost', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
        minWidth: 125,
        aggFunc: 'sum',
        resizable: true,
        valueFormatter: (params: Pick<ValueFormatterParams, 'value'>) =>
          params.value ? Utils.agCurrencyFormatter(params, Currency.USD) : Utils.zeroHyphen,
      },
      {
        headerName: 'Forecast Cost through EOT',
        field: 'forecast_cost_through_eot',
        tooltipField: 'forecast_cost_through_eot',
        cellClass: ['cell-right', 'cost', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
        minWidth: 125,
        aggFunc: 'sum',
        resizable: true,
        valueFormatter: (params: Pick<ValueFormatterParams, 'value'>) =>
          params.value ? Utils.agCurrencyFormatter(params, Currency.USD) : Utils.zeroHyphen,
      },
      {
        headerName: 'Amount Remaining',
        field: 'amount_remaining',
        tooltipField: 'amount_remaining',
        cellClass: ['cell-right', 'cost', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
        minWidth: 125,
        aggFunc: 'sum',
        resizable: true,
        valueFormatter: (params: Pick<ValueFormatterParams, 'value'>) =>
          params.value ? Utils.agCurrencyFormatter(params, Currency.USD) : Utils.zeroHyphen,
      },
    ],
  },
];
