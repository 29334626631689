<h2 class="mb-4 font-semibold">1 Button</h2>

<button class="btn--primary" (click)="openOneButtonModal()">Launch Modal</button>

<h3 class="mt-4 font-semibold">HTML code</h3>
<div class="flex items-center mb-2">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(10)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;div</div>
    <div class="m-2">
      class="w-screen h-screen sm:max-w-2xl md:max-w-3xl lg:max-w-4xl sm:max-h-100 md:max-h-130
      lg:max-h-158"
    </div>
    <div class="m-2">&gt;&lt;/div&gt;</div>

    <div class="m-2">&lt;div class="pt-5 bg-white"&gt;</div>
    <div class="m-2">&lt;hr /&gt;</div>
    <div class="m-2">&lt;/div&gt;</div>

    <div class="m-2">&lt;div class="bg-white pt-5 flex justify-between"&gt;</div>
    <div class="m-2">
      &lt;button class="focus:outline-none" (click)="onCancel()"
      type="button"&gt;Cancel&lt;/button&gt;
    </div>
    <div class="m-2">
      &lt;button class="ml-auto mr-2 btn--primary" (click)="onSubmit()"&gt;Primary&lt;/button&gt;
    </div>
    <div class="m-2">&lt;/div&gt;</div>
  </div>
</div>

<h3 class="mt-4 font-semibold">Typescript code</h3>

<div class="flex items-center mb-12">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(7)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">this.overlayService.open&lt;</div>
    <div class="m-2">any,</div>
    <div class="m-2">
      &#123;header: string; useDesignSystemStyling: boolean; displayX: boolean; formValue?:
      null&#125;
    </div>
    <div class="m-2">&gt;&#40;&#123;</div>
    <div class="m-2">content: ModalDialogComponent,</div>
    <div class="m-2">
      data: &#123; header: '1 Button', useDesignSystemStyling: true, displayX: true &#125;,
    </div>
    <div class="m-2">&#125;&#41;;</div>
  </div>
</div>

<h2 class="mb-4 font-semibold">2 Button</h2>

<button class="btn--primary" (click)="openTwoButtonsModal()">Launch Modal</button>

<h3 class="mt-4 font-semibold">HTML code</h3>
<div class="flex items-center">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(11)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;div</div>
    <div class="m-2">
      class="w-screen h-screen sm:max-w-2xl md:max-w-3xl lg:max-w-4xl sm:max-h-100 md:max-h-130
      lg:max-h-158"
    </div>
    <div class="m-2">&gt;&lt;/div&gt;</div>

    <div class="m-2">&lt;div class="pt-5 bg-white"&gt;</div>
    <div class="m-2">&lt;hr /&gt;</div>
    <div class="m-2">&lt;/div&gt;</div>

    <div class="m-2">&lt;div class="bg-white pt-5 flex justify-between"&gt;</div>
    <div class="m-2">
      &lt;button class="focus:outline-none" (click)="onCancel()"
      type="button"&gt;Cancel&lt;/button&gt;
    </div>
    <div class="m-2">
      &lt;button class="ml-auto mr-2 btn--secondary"
      (click)="onSecondarySubmit()"&gt;Secondary&lt;/button&gt;
    </div>
    <div class="m-2">
      &lt;button class="btn--primary" (click)="onSubmit()"&gt;Primary&lt;/button&gt;
    </div>
    <div class="m-2">&lt;/div&gt;</div>
  </div>
</div>

<h3 class="mt-4 font-semibold">Typescript code</h3>

<div class="flex items-center mb-6">
  <div class="text-black bg-aux-gray-dark">
    <div *ngFor="let i of getLineNumbers(7)" class="m-2">{{ i }}</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">this.overlayService.open&lt;</div>
    <div class="m-2">any,</div>
    <div class="m-2">
      &#123;header: string; useDesignSystemStyling: boolean; displayX: boolean; formValue?:
      null&#125;
    </div>
    <div class="m-2">&gt;&#40;&#123;</div>
    <div class="m-2">content: ModalDialogComponent,</div>
    <div class="m-2">
      data: &#123; header: '2 Buttons', useDesignSystemStyling: true, displayX: false &#125;,
    </div>
    <div class="m-2">&#125;&#41;;</div>
  </div>
</div>
