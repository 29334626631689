import { Injectable } from '@angular/core';
import { EventStatus, GqlService } from '@services/gql.service';
import { firstValueFrom } from 'rxjs';
import { EventStore } from './event.store';
import { OverlayService } from '@services/overlay.service';
import { LocalStorageKey } from '@shared/constants';

@Injectable({ providedIn: 'root' })
export class EventService {
  private pollingInterval = 15000;

  constructor(
    private gqlService: GqlService,
    private eventStore: EventStore,
    private overlayService: OverlayService
  ) {}

  private clearFinishedEvent(id: string) {
    setTimeout(() => {
      this.eventStore.remove(id);
    }, 1000);
  }

  private getEventIdsFromLs(): string[] {
    return JSON.parse(localStorage.getItem(LocalStorageKey.EVENT_TRACKER) ?? '[]') as string[];
  }

  private addEventIdToLs(eventId: string) {
    const eventIds = this.getEventIdsFromLs();

    if (!eventIds.includes(eventId)) {
      const newIdList = JSON.stringify([eventId, ...eventIds]);
      localStorage.setItem(LocalStorageKey.EVENT_TRACKER, newIdList);
    }
  }

  private removeEventFromLs(eventId: string) {
    const eventIds = this.getEventIdsFromLs();

    const newIdList = JSON.stringify(eventIds.filter((id) => id !== eventId));
    localStorage.setItem(LocalStorageKey.EVENT_TRACKER, newIdList);
  }

  private async handleEvent(eventId: string, interval?: NodeJS.Timeout) {
    const { data, errors } = await firstValueFrom(this.gqlService.getEventTracker$(eventId));

    const eventStatus = data?.event_status;
    const isProcessing =
      eventStatus === EventStatus.EVENT_STATUS_IN_PROGRESS ||
      eventStatus === EventStatus.EVENT_STATUS_PENDING;
    const isComplete = eventStatus === EventStatus.EVENT_STATUS_COMPLETED;
    const isFailed = eventStatus === EventStatus.EVENT_STATUS_FAILED;
    const eventStoreKey = data ? `${data.trial_id}-${data.event_type}` : null;

    if (isProcessing && data && !this.eventStore.getValue().ids?.includes(eventStoreKey)) {
      this.eventStore.add({
        ...data,
        _id: eventStoreKey as string,
      });
    }

    if (isComplete && data) {
      this.eventStore.update(eventStoreKey, (entity) => {
        return {
          ...entity,
          _id: eventStoreKey as string,
          event_status: EventStatus.EVENT_STATUS_COMPLETED,
        };
      });
    }

    if (((isComplete || isFailed) && data) || !!errors.length) {
      this.eventStore.setLoading(false);
      this.clearFinishedEvent(eventStoreKey as string);

      if (interval) {
        clearInterval(interval);
      }

      if (isFailed && data?.error_message) {
        this.overlayService.error(data.error_message);
      }

      if (isComplete && data?.success_message) {
        this.overlayService.success(data.success_message);
      }

      this.removeEventFromLs(eventId);
    }
  }

  trackEvent(eventId: string) {
    this.eventStore.setLoading(true);
    this.handleEvent(eventId);
    this.addEventIdToLs(eventId);

    const interval = setInterval(() => {
      this.handleEvent(eventId, interval);
    }, this.pollingInterval);
  }

  trackEventsFromLs() {
    this.getEventIdsFromLs().forEach((eventId) => this.trackEvent(eventId));
  }
}
