import { Component, Input, ViewChild } from '@angular/core';
import { Option } from '@components/components.type';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ForecastMethodType } from '@services/gql.service';
import { ForecastTableGridMethodChange } from '../../models/forecast-table-grid.model';

@Component({
  selector: 'aux-forecast-table-grid-method-time',
  templateUrl: './forecast-table-grid-method-time.component.html',
  styleUrls: ['./forecast-table-grid-method-time.component.scss'],
})
export class ForecastTableGridMethodTimeComponent {
  @ViewChild('gridMethodTime') public input!: NgSelectComponent;

  @Input() categoryId = '';

  @Input() primarySettingsId = '';

  @Input() isChild = false;

  @Input() placeholder = '';

  @Input() tooltip = '';

  @Input() disabled = false;

  @Input() isInvalid = false;

  @Input() onMethodChange!: ForecastTableGridMethodChange;

  @Input() methodOptions: Option<ForecastMethodType>[] = [];

  @Input() selectedForecastMethod = '';

  onModelChange(event: string): void {
    this.onMethodChange(event, this.primarySettingsId, {
      name: !this.isChild ? 'category' : 'activity',
      prop: 'forecast_method',
      id: this.categoryId,
    });
  }
}
