<div class="flex max-w-full flex-wrap">
  <div
    *ngFor="let link of links; let last = last"
    (click)="onClick(link)"
    class="flex cursor-pointer underline-offset-4 text-aux-blue mr-2 flex-nowrap max-w-full"
    [auxTooltip]="link.name"
  >
    <div class="underline truncate">
      {{ link.name }}
    </div>
    <span *ngIf="!last">, </span>
  </div>
</div>
