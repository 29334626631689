import { TotalDriverSiteDistribution } from './forecast-site-curves.component';
import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  HeaderClassParams,
  RowClassParams,
  ValueFormatterFunc,
  ValueFormatterParams,
  ValueParserFunc,
} from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { TableConstants } from '@constants/table.constants';
import { decimalRoundingToString } from '@shared/utils';
import { CanvasComponentOptions } from '@components/canvas-chart/canvas-chart.component';
import { ChartDataset } from 'chart.js/dist/types';
import { BehaviorSubject } from 'rxjs';
import { AgMonthCellComponent } from '../components/ag-month-cell.component';

type Timeline = BehaviorSubject<{ startDate?: string; endDate?: string }>;
export class SiteCurvesConstants {
  static readonly GRID_OPTIONS_EXEL_STYLES = [
    {
      id: 'header',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
      interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
    },
    {
      id: 'text-aux-error',
      font: { color: '#D73C37' },
    },
    {
      id: 'text-aux-green',
      font: { color: '#437F7F' },
    },
    {
      id: 'cellPercent',
      font: { fontName: 'Arial', size: 11 },
      alignment: { horizontal: 'Right' },
      numberFormat: { format: '0.00;-0.00;—;—' },
    },
    {
      id: 'first_row',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
      interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
    },
    {
      id: 'total_row_header',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
    },
    {
      id: 'total_row',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'Number',
      numberFormat: { format: '#,##0.00' },
    },
    {
      id: 'total_row_extended_decimal',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'Number',
      numberFormat: { format: '#,##0.00######' },
    },
    {
      id: 'total_row_percent',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'String',
      alignment: {
        horizontal: 'Right',
      },
    },
  ];

  static readonly GRID_OPTIONS = {
    onCellClicked(event: CellClickedEvent) {
      const cellRange = event.api.getCellRanges();
      if ((cellRange?.length || 0) > 1) {
        event.api.stopEditing();
      }
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
    headerHeight: 40,
    groupIncludeFooter: true,
    pinnedBottomRowData: [],
    rowClassRules: {
      'has-error': (params: RowClassParams) => params.data.showError,
    },
    getRowStyle: (params: RowClassParams) => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold', 'justify-item': 'end' };
      }
      return {};
    },
  };

  static multiChartOptions(
    data: TotalDriverSiteDistribution[],
    labels: string[]
  ): CanvasComponentOptions {
    const colors = [
      {
        color: '#E3B506',
        border: true,
      },
      {
        color: '#236262',
        border: true,
      },
      {
        color: '#095b95',
        border: false,
      },
      {
        color: '#6B9DBF',
        border: false,
      },
      {
        color: '#BACAD0',
        border: false,
      },
    ].map(({ color, border }) => {
      return <ChartDataset<'line'>>{
        pointStyle: 'rectRounded',
        hoverBackgroundColor: color,
        hoverBorderColor: color,
        borderColor: color,
        backgroundColor: color,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        borderWidth: border ? 3 : 0,
        pointHoverBorderWidth: 4,
        pointRadius: 0,
        pointHoverRadius: 4,
        pointHitRadius: 4,
      };
    });
    return {
      data: {
        labels,
        datasets: [
          {
            ...colors[0],
            data: data.map((x) => x.net_sites_per_month || 0),
            label: 'Sites Per Month',
            type: 'line',
          },
          {
            ...colors[1],
            data: data.map((x) => x.total_sites_activated || 0),
            label: 'Total Sites Activated',
            type: 'line',
          },
          {
            ...colors[2],
            data: data.map((x) => x.sites_activated || 0),
            label: 'Sites Activated',
            // https://github.com/chartjs/Chart.js/issues/11356
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: 'bar',
          },
          {
            ...colors[3],
            data: data.map((x) => x.sites_closed || 0),
            label: 'Sites Closed',
            // https://github.com/chartjs/Chart.js/issues/11356
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: 'bar',
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
      },
      legend: { bottom: true, right: false, left: false, top: false },
      type: 'line',
    };
  }

  static getHeaderClasses =
    (editMode$: BehaviorSubject<boolean>) =>
    (params: HeaderClassParams): string | string[] => {
      const editCell = editMode$.getValue();

      if (!params.column?.getColId()) {
        return '';
      }

      if (
        params.column.getColId() === 'sites_activated' ||
        params.column.getColId() === 'sites_closed'
      ) {
        return editCell
          ? ['ag-header-edit-mode', 'ag-header-align-center']
          : ['ag-header-align-center'];
      }

      return ['ag-header-align-center'];
    };

  static getCellClasses =
    (editMode$: BehaviorSubject<boolean>) =>
    (params: CellClassParams): string | string[] => {
      const editCell = editMode$.getValue();
      if (params.colDef.field === 'distribution_month') {
        return editCell ? ['opacity-70', 'justify-start'] : 'justify-start';
      }
      if (params.colDef.field === 'sites_activated' || params.colDef.field === 'sites_closed') {
        return editCell ? ['editable-cell', 'justify-end'] : 'justify-end';
      }
      if (params.colDef.field === 'net_sites_per_month') {
        if (Utils.isNegativeAndNotCloseEnoughToZero(params.value)) {
          return 'justify-end';
        }
        return editCell ? ['opacity-70', 'justify-end'] : 'justify-end';
      }
      return 'justify-end';
    };

  static columnDefs(
    valueFormatter: ValueFormatterFunc,
    valueParser: ValueParserFunc,
    editCell: boolean,
    timeline$: Timeline
  ): ColDef[] {
    return [
      {
        headerName: 'Month',
        field: 'distribution_month',
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value === 'TOTAL') {
            return 'TOTAL';
          }
          return params.value
            ? Utils.dateFormatter(params.value, { day: undefined, year: '2-digit' })
            : '';
        },
        editable: false,
        cellRenderer: AgMonthCellComponent,
        cellRendererParams: {
          timeline$,
          noMonthInTimelineTooltipMessage:
            'This month is no longer included in the trial timeline. Please adjust your Site Curve or Trial Timeline to fix this issue.',
        },
      },
      {
        headerName: 'Sites Activated',
        field: 'sites_activated',
        aggFunc: 'sum',
        valueFormatter: valueFormatter,
        valueParser: valueParser,
      },
      {
        headerName: 'Sites Closed',
        field: 'sites_closed',
        aggFunc: 'sum',
        valueFormatter: valueFormatter,
        valueParser: valueParser,
      },
      {
        headerName: 'Net Sites Per Month',
        field: 'net_sites_per_month',
        aggFunc: 'sum',
        valueFormatter: valueFormatter,
        editable: false,
      },
      {
        headerName: 'Net Sites Per Month %',
        field: 'net_sites_per_month_percentage',
        aggFunc: 'sum',
        cellClass: () => {
          return editCell
            ? ['opacity-70', 'cellPercent', 'justify-end']
            : ['justify-end', 'cellPercent'];
        },
        editable: false,
        valueFormatter: (val: ValueFormatterParams) => {
          return val.value ? `${decimalRoundingToString(val.value, 2)}%` : Utils.zeroHyphen;
        },
      },
      {
        headerName: 'Total Sites Activated',
        field: 'total_sites_activated',
        editable: false,
        aggFunc: 'sum',
        valueFormatter: valueFormatter,
      },
      {
        headerName: 'Sites Activated %',
        field: 'sites_activated_percentage',
        aggFunc: 'sum',
        cellClass: () => {
          return editCell
            ? ['opacity-70', 'cellPercent', 'justify-end']
            : ['justify-end', 'cellPercent'];
        },
        editable: false,
        valueFormatter: (val: ValueFormatterParams) => {
          return val.value ? `${decimalRoundingToString(val.value, 2)}%` : Utils.zeroHyphen;
        },
      },
      {
        headerName: 'Sites Closed %',
        field: 'sites_closed_percentage',
        aggFunc: 'sum',
        cellClass: () => {
          return editCell
            ? ['opacity-70', 'cellPercent', 'justify-end']
            : ['justify-end', 'cellPercent'];
        },
        editable: false,
        valueFormatter: (val: ValueFormatterParams) => {
          return val.value ? `${decimalRoundingToString(val.value, 2)}%` : Utils.zeroHyphen;
        },
      },
    ];
  }
}
