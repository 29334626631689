import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgForOf } from '@angular/common';
import { ComponentsModule } from '@components/components.module';

@Component({
  selector: 'aux-entity-form-group-row',
  templateUrl: './entity-form-group-row.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgSelectModule, ReactiveFormsModule, AsyncPipe, NgForOf, ComponentsModule],
})
export class EntityFormGroupRowComponent {
  @Input() formGroup: FormGroup = new FormGroup({});

  @Input() disabled = true;
}
