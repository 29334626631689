import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  SpecificationSettingStore,
  SpecificationSettingState,
} from './specification-setting.store';

@Injectable({ providedIn: 'root' })
export class SpecificationSettingQuery extends QueryEntity<SpecificationSettingState> {
  constructor(protected store: SpecificationSettingStore) {
    super(store);
  }
}
