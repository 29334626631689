import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Currency } from '@services/gql.service';
import { RequireSome } from '@services/utils';
import { PurchaseOrderModel } from './purchase-orders.model';

export type PurchaseOrdersState = EntityState<
  RequireSome<Partial<PurchaseOrderModel>, 'id'> & {
    organization_name: string;
    organization_id: string;
    currency: Currency;
    received_amount: number;
    paid_amount: number;
    received_percentage: number;
    paid_percentage: number;
  },
  string
>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'purchase-orders' })
export class PurchaseOrdersStore extends EntityStore<PurchaseOrdersState> {
  constructor() {
    super({});
  }
}
