import { Flags } from '@services/launch-darkly.service';
import { BehaviorSubject } from 'rxjs';
import { ROUTING_PATH } from 'src/app/app-routing-path.const';
import { AuxIconName } from '@components/icon/icon';

export interface NavigationMenuItem {
  name: string;
  url: string;
  subRoutes: NavigationMenuItem[];
  className?: string;
  activeSvg?: AuxIconName;
  divider?: boolean;
  singleRoute?: boolean;
  svg?: AuxIconName;
  svgClasses?: string;
  flagKey?: keyof Flags;
  badge?: {
    amount: BehaviorSubject<number>;
    tooltip: string;
  };
}

export class NavigationMenuCost {
  static readonly ROUTER_CONFIG: NavigationMenuItem[] = [
    {
      name: 'Trial Dashboard',
      url: `/${ROUTING_PATH.DASHBOARD}`,
      flagKey: 'nav_home',
      subRoutes: [],
      singleRoute: true,
    },
    {
      name: 'Trial Insights',
      url: `/${ROUTING_PATH.TRIAL_INSIGHTS.INDEX}`,
      flagKey: 'nav_trial_insights',
      subRoutes: [
        {
          name: 'ClinOps',
          url: `/${ROUTING_PATH.TRIAL_INSIGHTS.INDEX}/${ROUTING_PATH.TRIAL_INSIGHTS.CLINICAL}`,
          flagKey: 'clin_ops_dashboard',
          subRoutes: [],
        },
        {
          name: 'Finance',
          url: `/${ROUTING_PATH.TRIAL_INSIGHTS.INDEX}/${ROUTING_PATH.TRIAL_INSIGHTS.FINANCE}`,
          flagKey: 'finance_dashboard',
          subRoutes: [],
        },
      ],
    },
    {
      name: 'Budget',
      url: `/${ROUTING_PATH.BUDGET.INDEX}`,
      flagKey: 'nav_budget',
      subRoutes: [
        {
          name: 'Budget',
          url: `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.INDEX}`,
          flagKey: 'nav_budget',
          subRoutes: [],
        },
        {
          name: 'Budget Library',
          url: `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.LIBRARY}`,
          flagKey: 'tab_budget_budget_library',
          subRoutes: [],
        },
        {
          name: 'Change Log',
          url: `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_LOG}`,
          flagKey: 'tab_budget_changelog',
          subRoutes: [],
        },
        {
          name: 'Change Orders',
          url: `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.CHANGE_ORDER}`,
          flagKey: 'tab_change_order_review',
          subRoutes: [],
          badge: {
            amount: new BehaviorSubject(0),
            tooltip: '# of Change Orders Pending Approval.',
          },
        },
        {
          name: 'Compare',
          url: `/${ROUTING_PATH.BUDGET.INDEX}/${ROUTING_PATH.BUDGET.COMPARE}`,
          flagKey: 'tab_compare',
          subRoutes: [],
        },
      ],
    },
    {
      name: 'Forecast',
      url: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}`,
      flagKey: 'nav_forecast',
      subRoutes: [
        {
          name: 'Forecast Methodology',
          url: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY}`,
          subRoutes: [],
        },
        {
          name: 'Timeline',
          url: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.TIMELINE.TRIAL_TIMELINE}`,
          subRoutes: [],
        },
        {
          name: 'Patient Drivers',
          url: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.CURVES}`,
          flagKey: 'tab_forecast_drivers',
          subRoutes: [],
        },
        {
          name: 'Site Drivers',
          url: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.CURVES}`,
          flagKey: 'tab_forecast_drivers',
          subRoutes: [],
        },
        {
          name: 'Investigator Forecast',
          url: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.INVESTIGATOR_FORECAST}`,
          flagKey: 'tab_forecast_investigator_forecast',
          subRoutes: [],
        },
        {
          name: 'Study Specifications',
          url: `/${ROUTING_PATH.FORECAST_ROUTING.INDEX}/${ROUTING_PATH.FORECAST_ROUTING.STUDY_SPECIFICATIONS}`,
          flagKey: 'tab_forecast_studyspecifications',
          subRoutes: [],
        },
      ],
    },
    {
      name: 'Period Close',
      url: `/${ROUTING_PATH.CLOSING.INDEX}`,
      flagKey: 'tab_forecast_in_month',
      subRoutes: [
        {
          name: 'Checklist',
          url: `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.CHECKLIST}`,
          subRoutes: [],
        },
        {
          name: 'In-Month Adjustments',
          url: `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.ADJUSTMENTS}`,
          subRoutes: [],
        },
        {
          name: 'Month & Quarter Close',
          url: `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.QUARTER_CLOSE}`,
          subRoutes: [],
        },
        {
          name: 'Reconciliation',
          url: `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.RECONCILIATION}`,
          flagKey: 'tab_budget_cash_management',
          subRoutes: [],
        },
        {
          name: 'Journal Entries',
          url: `/${ROUTING_PATH.CLOSING.INDEX}/${ROUTING_PATH.CLOSING.JOURNAL_ENTRIES}`,
          flagKey: 'tab_journal_entries',
          subRoutes: [],
        },
      ],
    },
    {
      name: 'Investigator',
      url: `${ROUTING_PATH.INVESTIGATOR.INDEX}`,
      flagKey: 'nav_investigator',
      subRoutes: [
        {
          name: 'Investigator Summary',
          url: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_SUMMARY}`,
          flagKey: 'tab_investigator_summary',
          subRoutes: [],
        },
        {
          name: 'Transactions',
          url: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_TRANSACTIONS}`,
          flagKey: 'tab_investigator_transactions',
          subRoutes: [],
        },
        {
          name: 'Patient Tracker',
          url: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_TRACKER}`,
          flagKey: 'nav_patient',
          subRoutes: [],
        },
        {
          name: 'Patient Budget',
          url: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET.INDEX}`,
          flagKey: 'tab_trial_settings_patient_protocol',
          subRoutes: [],
        },
        {
          name: 'Protocol Entry',
          url: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET_ENTRY}`,
          flagKey: 'tab_protocol_entry',
          subRoutes: [],
        },
        {
          name: 'Patient Groups',
          url: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_GROUPS}`,
          flagKey: 'tab_patient_groups',
          subRoutes: [],
        },
        {
          name: 'Sites',
          url: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.SITES}`,
          flagKey: 'tab_investigator_sites',
          subRoutes: [],
        },
        {
          name: 'Investigator Detail',
          url: `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_DETAIL}`,
          flagKey: 'tab_forecast_investigator_detail',
          subRoutes: [],
        },
      ],
    },
    {
      name: 'Vendor Payments',
      url: `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}`,
      flagKey: 'nav_invoices',
      subRoutes: [
        {
          name: 'Invoices',
          url: `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`,
          subRoutes: [],
        },
        {
          name: 'Purchase Orders',
          url: `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PURCHASE_ORDERS}`,
          subRoutes: [],
        },
        {
          name: 'Vendors',
          url: `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.VENDORS}`,
          subRoutes: [],
        },
        {
          name: 'Payment Milestones',
          url: `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_MILESTONES}`,
          subRoutes: [],
        },
        {
          name: 'Payment Schedule',
          url: `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_SCHEDULE}`,
          subRoutes: [],
          flagKey: 'tab_budget_payment_schedule',
        },
      ],
    },
    {
      name: 'Scenario Manager',
      url: `/${ROUTING_PATH.MANAGER}`,
      flagKey: 'tab_compare_scenario_manager',
      subRoutes: [],
      singleRoute: true,
    },
    {
      name: 'Risk Analytics',
      url: `/${ROUTING_PATH.RISK_ANALYTICS}`,
      flagKey: 'nav_risk',
      subRoutes: [],
      singleRoute: true,
    },
    { divider: true, name: '', url: '', subRoutes: [] },
    {
      name: 'Document Library',
      url: `/${ROUTING_PATH.DOCUMENTS}`,
      svg: 'Books',
      flagKey: 'nav_document_library',
      subRoutes: [],
      singleRoute: true,
    },
    {
      name: 'Audit History',
      url: `/${ROUTING_PATH.AUDIT_HISTORY}`,
      svg: 'FileSearch',
      flagKey: 'nav_audit_history',
      subRoutes: [],
      singleRoute: true,
    },
    {
      name: 'Settings',
      url: `/${ROUTING_PATH.SETTINGS.INDEX}`,
      svg: 'Settings',
      activeSvg: 'SettingsFilled',
      subRoutes: [],
      singleRoute: true,
    },
    {
      name: 'Design System',
      url: '/design-system',
      svg: 'Rocket',
      flagKey: 'nav_design_system',
      subRoutes: [],
      singleRoute: true,
    },
    {
      name: 'Ops Admin',
      url: '/ops-admin',
      svg: 'Settings',
      activeSvg: 'SettingsFilled',
      flagKey: 'nav_ops_admin',
      subRoutes: [],
      singleRoute: true,
    },
  ];
}
