import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InvoiceService } from '../state/invoice.service';
import { ConnectedPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'aux-invoice-filters',
  templateUrl: './invoice-filters.component.html',
  styleUrls: ['./invoice-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceFiltersComponent {
  @Input() onFilterChange!: () => void;

  @Input() form!: FormGroup;

  optionTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
      offsetX: -10,
      offsetY: 8,
    },
  ];

  constructor(public invoiceService: InvoiceService) {}

  resetAllFilters() {
    Object.values(this.form.controls).forEach((control) => {
      control.reset();
    });

    this.onFilterChange();
  }
}
