import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Option } from '@components/components.type';
import { FullSettings } from '../../../category/category.query';
import {
  ForecastTableGridPeriodChange,
  ForecastTableGridTimelinePhaseInterface,
} from '../../models/forecast-table-grid.model';
import { ForecastTableGridPeriodService } from '../../services/forecast-table-grid-period.service';

@Component({
  selector: 'aux-forecast-table-grid-period-time',
  templateUrl: './forecast-table-grid-period-time.component.html',
  styleUrls: ['./forecast-table-grid-period-time.component.scss'],
})
export class ForecastTableGridPeriodTimeComponent implements OnChanges {
  @ViewChild('gridPeriodTime') public input!: NgSelectComponent;

  @Input() categoryId = '';

  @Input() primarySettingsId = '';

  @Input() isChild = false;

  @Input() placeholder = '';

  @Input() tooltip = '';

  @Input() disabled = false;

  @Input() isInvalid = false;

  @Input() onPeriodChange!: ForecastTableGridPeriodChange;

  @Input() fullSettings?: FullSettings;

  @Input() timelinePhases: ForecastTableGridTimelinePhaseInterface[] = [];

  @Input() PeriodService!: ForecastTableGridPeriodService;

  phases: Option<string>[] = [];

  selectedPhase = '';

  label = '';

  ngOnChanges() {
    this.phases = this.PeriodService.parsePhases(this.timelinePhases, this.fullSettings);
    this.selectedPhase = this.PeriodService.parseSelectedPhase(
      this.timelinePhases,
      this.fullSettings
    );

    this.label = this.createLabel();
  }

  createLabel(): string {
    const selectedPhase = this.phases.find((phase) => {
      return phase.value === this.selectedPhase;
    });

    if (selectedPhase) {
      // Tooltip for truncated labels
      return selectedPhase.label.length > 25 ? selectedPhase.label : '';
    }

    return '';
  }

  openTimeModal(): void {
    if (this.fullSettings && !this.disabled) {
      this.onPeriodChange(this.fullSettings, {
        name: !this.isChild ? 'category' : 'activity',
        id: this.categoryId,
      });
    }
  }
}
