<form *ngVar="bannerValues$ | async as bannerValues" [formGroup]="form">
  <div *ngVar="editMode$ | async as editMode" class="flex justify-between mb-4">
    <div class="flex space-x-4 items-center">
      <div class="text-sm">Select Vendor:</div>
      <aux-vendor-dropdown
        [vendors]="vendorList"
        formControlName="vendorId"
        [loading]="(vendorListLoading$ | async)!"
      />
    </div>
    <div class="flex space-x-2">
      <aux-button
        *ngIf="!editMode && !!form.getRawValue().vendorId"
        label="Edit"
        icon="Pencil"
        [onClick]="editModeEnable()"
      />
      <aux-button
        *ngIf="editMode"
        label="Cancel"
        variant="secondary"
        icon="X"
        [onClick]="cancelEdit"
      />
      <aux-button
        *ngIf="editMode"
        label="Save"
        variant="success"
        icon="Check"
        [disabled]="submitButtonDisabled$ | async"
        [onClick]="saveSiteContractSetting"
      />
    </div>
  </div>
  <aux-investigator-forecast-saved-source-banner
    *ngIf="!!bannerValues.source"
    class="mb-7"
    [source]="bannerValues.source"
    [amount]="bannerValues.amount"
  />

  <p class="my-4 font-bold">Select Source</p>
  <div class="grid grid-cols-[1fr_2fr] relative">
    <aux-investigator-forecast-source-select [control]="form.controls.source" [sources]="sources" />

    <aux-investigator-forecast-overview
      *ngIf="!!form.getRawValue().source"
      [form]="form"
      [formValues]="$any(form.getRawValue())"
    />
  </div>
</form>
