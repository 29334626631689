<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<ng-container *ngIf="(loading$ | async) === false">
  <div class="w-full overflow-auto">
    <ag-grid-angular
      domLayout="autoHeight"
      class="ag-theme-aux compare-grid tabular-nums"
      [gridOptions]="$any(gridOptions$ | async)"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [columnDefs]="columnDefs"
      [rowData]="gridData$ | async"
      [groupAllowUnbalanced]="true"
      (firstDataRendered)="onDataRendered($event); autoSize()"
      (viewportChanged)="onWindowScroll()"
      (gridSizeChanged)="gridSizeChanged()"
      (filterChanged)="onFilterChanged()"
    />
  </div>
</ng-container>
