import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IntegrationsState, IntegrationsStore } from './integrations.store';

@Injectable({ providedIn: 'root' })
export class IntegrationsQuery extends Query<IntegrationsState> {
  constructor(protected store: IntegrationsStore) {
    super(store);
  }
}
