import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PurchaseOrdersStore, PurchaseOrdersState } from './purchase-orders.store';

@Injectable({ providedIn: 'root' })
export class PurchaseOrdersQuery extends QueryEntity<PurchaseOrdersState> {
  constructor(protected store: PurchaseOrdersStore) {
    super(store);
  }
}
