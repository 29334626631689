import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  getEntityType,
  MultiActiveState,
  StoreConfig,
} from '@datorama/akita';
import { listSiteContactsQuery, Site } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface SitesState
  extends EntityState<
      Omit<RequireSome<Partial<Site>, 'id'>, 'managed_by'> & {
        investigator?: listSiteContactsQuery | null;
        managed_by_id?: string | null;
      }
    >,
    MultiActiveState {}

export type SiteModel = getEntityType<SitesState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sites' })
export class SitesStore extends EntityStore<SitesState> {
  constructor() {
    super({});
  }
}
