import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { CdkTreeModule } from '@angular/cdk/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { AgActionsComponent } from '@components/ag-actions/ag-actions.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayService } from '@services/overlay.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AgHeaderDropdownComponent } from './ag-header-dropdown.component';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { OverlayComponent } from './overlay/overlay.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { AuxCardSelectComponent } from './aux-card-select/aux-card-select.component';
import { InputComponent } from './form-inputs/input/input.component';
import { DirectivesModule } from '../directives/directives.module';
import { SelectComponent } from './form-inputs/select/select.component';
import { ToggleComponent } from './form-inputs/toggle/toggle.component';
import { FileViewerComponent } from './file-manager/file-viewer.component';
import { RadioButtonModule } from './radio-button/radio-button.module';
import { FileManagerDialogComponent } from './file-manager-dialog/file-manager-dialog.component';
import { MenuComponent } from './menu/menu.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { AgDatePickerComponent } from './datepicker/ag-date-picker/ag-date-picker.component';
import { AgPulseMinusComponent } from './ag-actions/ag-pulse-minus.component';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { CompareComponent } from './compare/compare.component';
import { AgCheckboxComponent } from './ag-actions/ag-checkbox.component';
import { GuardWarningComponent } from './guard-warning/guard-warning.component';
import { ExportExcelButtonComponent } from './export-excel-button/export-excel-button.component';
import { AgHtmlHeaderComponent } from './ag-html-header/ag-html-header.component';
import { CanvasChartComponent } from './canvas-chart/canvas-chart.component';
import { SaveChangesComponent } from './save-changes/save-changes.component';
import { ToggleCurrencyComponent } from './toggle-currency/toggle-currency.component';
import { AgIntegrationsVendorComponent } from '../pages/settings/integrations/state/ag-integrations-vendor.component';
import { ProcessingLoaderComponent } from './processing-loader/processing-loader.component';
import { AgCellWrapperComponent } from './ag-cell-wrapper/ag-cell-wrapper.component';
import { RemoveDialogComponent } from './remove-dialog/remove-dialog.component';
import { AgIntegrationsConnectComponent } from '../pages/settings/integrations/state/ag-integrations-connect.component';
import { ExchangeLinkComponent } from './exchange-link/exchange-link.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { FileManagerUploadedFilesComponent } from './file-manager/file-manager-uploaded-files/file-manager-uploaded-files.component';
import { ButtonToggleGroupComponent } from '@components/button-toggle-group/button-toggle-group.component';
import { EditableListDropdownComponent } from '@components/editable-list-dropdown/editable-list-dropdown.component';
import { FieldNotificationComponent } from './field-notification/field-notification.component';
import { InputMaskComponent } from './form-inputs/input-mask/input-mask.component';
import { AgExpenseDefaultsDragComponent } from '../pages/settings/expense-defaults/state/ag-expense-defaults-drag/ag-expense-defaults-drag.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AgExpenseDefaultsHeaderComponent } from '../pages/settings/expense-defaults/state/ag-expense-defaults-header.component';
import { PercentIndicatorComponent } from './percent-indicator/percent-indicator.component';
import { AgCompareGroupHeaderComponent } from './compare/ag-compare-group-header/ag-compare-group-header.component';
import { SliderComponent } from './slider/slider.component';
import { AgLoadingCellComponent } from './ag-loading-cell';
import { NgChartsModule } from 'ng2-charts';
import { NgxSliderModule } from 'ngx-slider-v2';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { IconComponent } from '@components/icon/icon.component';

@NgModule({
  imports: [
    NgSelectModule,
    CommonModule,
    PortalModule,
    OverlayModule,
    CdkTreeModule,
    RouterModule,
    DirectivesModule,
    ReactiveFormsModule,
    FormsModule,
    RadioButtonModule,
    AgGridAngular,
    NgChartsModule,
    TooltipDirective,
    NgxSliderModule,
    DragDropModule,
    OverlayComponent,
    NgxMaskDirective,
    CheckboxComponent,
    InputComponent,
    IconComponent,
  ],
  declarations: [
    ButtonComponent,
    FileManagerComponent,
    AuxCardSelectComponent,
    SelectComponent,
    ToggleComponent,
    FileViewerComponent,
    FileManagerDialogComponent,
    MenuComponent,
    DatepickerComponent,
    AgDatePickerComponent,
    AgActionsComponent,
    AgPulseMinusComponent,
    TabGroupComponent,
    CompareComponent,
    AgCheckboxComponent,
    GuardWarningComponent,
    ExportExcelButtonComponent,
    AgHtmlHeaderComponent,
    AgHeaderDropdownComponent,
    CanvasChartComponent,
    SaveChangesComponent,
    ToggleCurrencyComponent,
    AgIntegrationsVendorComponent,
    AgIntegrationsConnectComponent,
    AgExpenseDefaultsDragComponent,
    AgExpenseDefaultsHeaderComponent,
    ProcessingLoaderComponent,
    AgCellWrapperComponent,
    RemoveDialogComponent,
    ExchangeLinkComponent,
    MultiSelectDropdownComponent,
    EditableListDropdownComponent,
    FileManagerUploadedFilesComponent,
    ButtonToggleGroupComponent,
    FieldNotificationComponent,
    InputMaskComponent,
    PercentIndicatorComponent,
    AgCompareGroupHeaderComponent,
    SliderComponent,
    AgLoadingCellComponent,
  ],
  exports: [
    NgChartsModule,
    ButtonComponent,
    CheckboxComponent,
    FileManagerComponent,
    SliderComponent,
    AuxCardSelectComponent,
    InputMaskComponent,
    InputComponent,
    SelectComponent,
    ToggleComponent,
    FileViewerComponent,
    RadioButtonModule,
    MenuComponent,
    DatepickerComponent,
    AgActionsComponent,
    TabGroupComponent,
    TooltipDirective,
    CompareComponent,
    AgCheckboxComponent,
    ExportExcelButtonComponent,
    AgHtmlHeaderComponent,
    AgHeaderDropdownComponent,
    CanvasChartComponent,
    SaveChangesComponent,
    ToggleCurrencyComponent,
    AgIntegrationsVendorComponent,
    AgExpenseDefaultsDragComponent,
    AgExpenseDefaultsHeaderComponent,
    ProcessingLoaderComponent,
    ExchangeLinkComponent,
    MultiSelectDropdownComponent,
    EditableListDropdownComponent,
    FileManagerUploadedFilesComponent,
    ButtonToggleGroupComponent,
    NgSelectModule,
    ReactiveFormsModule,
    PercentIndicatorComponent,
    AgCompareGroupHeaderComponent,
    AgLoadingCellComponent,
  ],
  providers: [OverlayService, provideEnvironmentNgxMask()],
})
export class ComponentsModule {}
