import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SpecificationInputType, SpecificationSettingType } from '@services/gql.service';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { OverlayService } from '@services/overlay.service';

import { SpecificationCategoryService } from './specification-category/specification-category.service';
import {
  FullSpecificationCategory,
  SpecificationCategoryQuery,
} from './specification-category/specification-category.query';
import { SpecificationService } from './specification/specification.service';
import { SpecificationQuery } from './specification/specification.query';
import { SpecificationModel } from './specification/specification.store';
import { SpecificationsUploadComponent } from './specifications-upload/specifications-upload.component';

@UntilDestroy()
@Component({
  selector: 'aux-specifications',
  templateUrl: './specifications.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecificationsComponent {
  specTypesEnum = SpecificationInputType;

  fg = this.fb.group({});

  showForm = new BehaviorSubject('');

  loading$ = new BehaviorSubject(false);

  specSetTypes = Object.keys(SpecificationSettingType).map((value) => {
    return {
      label: value.replace('SPECIFICATION_', ''),
      value,
    };
  });

  constructor(
    private specificationCategoryService: SpecificationCategoryService,
    public specificationCategoryQuery: SpecificationCategoryQuery,
    private specificationService: SpecificationService,
    private specificationQuery: SpecificationQuery,
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService
  ) {
    this.specificationCategoryService
      .getAllSpecifications$()
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  createSpecSetFB(
    { type, value, label, id } = {
      label: '',
      value: '',
      type: SpecificationSettingType.SPECIFICATION_GENERAL,
      id: '',
    }
  ) {
    return this.fb.group({
      label,
      value: [value, Validators.required],
      type,
      id,
    });
  }

  onShowForm(id: string, cat: FullSpecificationCategory) {
    // reset the form
    this.hideForm();

    if (id === cat.id) {
      const settings: UntypedFormArray = this.fb.array([this.createSpecSetFB()]);

      this.fg = this.fb.group({
        specName: ['', Validators.required],
        specType: SpecificationInputType.INPUT_MULTIVALUE,
        settings,
      });
    } else {
      const spec = cat.specifications.find((fullSpec) => fullSpec.id === id);
      if (spec?.input_type === SpecificationInputType.INPUT_MULTIVALUE) {
        const settings: UntypedFormArray = this.fb.array([]);

        for (const setting of spec.settings) {
          settings.push(
            this.createSpecSetFB({
              label: setting.setting_key ?? '',
              value: setting.setting_value ?? '',
              type: setting.specification_type,
              id: setting.id,
            })
          );
        }

        this.fg = this.fb.group({
          specName: [spec.name, Validators.required],
          specType: SpecificationInputType.INPUT_MULTIVALUE,
          settings,
        });
      }
    }

    this.showForm.next(id);
  }

  hideForm() {
    this.fg = this.fb.group({});
    this.showForm.next('');
  }

  async onSaveForm(cat: FullSpecificationCategory) {
    if (this.fg.valid && !this.loading$.getValue()) {
      this.loading$.next(true);
      // new spec case
      const { specName, specType, settings } = this.fg.value as {
        specName: string;
        specType: SpecificationInputType;
        settings: {
          label: string;
          value: string;
          type: SpecificationSettingType;
          id: string;
        }[];
      };

      if (this.showForm.getValue() === cat.id) {
        if (specType === SpecificationInputType.INPUT_MULTIVALUE) {
          const { success } = await this.specificationService.add(
            {
              input_type: SpecificationInputType.INPUT_MULTIVALUE,
              category_id: cat.id,
              name: specName,
            },
            settings.map((set) => {
              return {
                specification_type: set.type,
                setting_key: set.label,
                setting_value: set.value,
              };
            })
          );

          if (success) {
            this.overlayService.success('New specification successfully added!');
          }
        }

        this.hideForm();
      } else {
        this.loading$.next(true);
        await this.specificationService.update(
          {
            name: specName,
            category_id: cat.id,
            id: this.showForm.getValue(),
            input_type: specType,
          },
          settings.map((x) => {
            return {
              specification_type: x.type,
              setting_key: x.label,
              setting_value: x.value,
              id: x.id,
            };
          })
        );

        this.hideForm();
      }

      this.loading$.next(false);
    }
  }

  onAddNewLine() {
    const fa = this.fg.get('settings') as UntypedFormArray;
    fa.push(this.createSpecSetFB());
  }

  onRemoveLastLine() {
    const fa = this.fg.get('settings') as UntypedFormArray;
    fa.removeAt(fa.value.length - 1);
  }

  async onRemoveSpec(id: string, cat: FullSpecificationCategory) {
    const spec = this.specificationQuery.getEntity(id) as SpecificationModel;
    const resp = this.overlayService.openConfirmDialog({
      message: `Are you sure that you want to remove the Specification ${spec.name} ?`,
      header: 'Remove Specification',
    });
    const { data } = await firstValueFrom(resp.afterClosed$);
    if (data?.result) {
      const { success } = await this.specificationService.remove(id, cat.id);
      if (success) {
        this.overlayService.success('Specification successfully removed!');
      }
    }
  }

  onSpecUploadClick() {
    this.overlayService.open({ content: SpecificationsUploadComponent });
  }
}
