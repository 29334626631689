import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  template: `
    <div class="flex h-[45px] justify-start items-center">
      <div class="flex w-[120px] justify-center items-center">Primary</div>
      <div class="w-[10px] mx-[5px] font-normal text-base">></div>
      <div class="flex w-[120px] justify-center items-center">Secondary</div>
      <div class="w-[10px] mx-[5px] font-normal text-base">></div>
      <div class="flex w-[120px] justify-center items-center">Tertiary</div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgExpenseDefaultsHeaderComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  refresh(): boolean {
    return false;
  }

  agInit() {}
}
