import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  InvestigatorForecastForm,
  InvestigatorForecastTotals,
  InvestigatorForecastValues,
  SourceType,
} from '../../investigator-forecast.types';

@Component({
  selector: 'aux-investigator-forecast-overview',
  templateUrl: './investigator-forecast-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorForecastOverviewComponent {
  @Input() form!: FormGroup<InvestigatorForecastForm>;

  @Input() formValues!: InvestigatorForecastValues;

  sourceTypes = SourceType;

  getBottomTotalValuesBySelectedSource(): InvestigatorForecastTotals | null {
    const { source } = this.formValues;

    if (!source || source === SourceType.Contracted || source === SourceType.PatientVisits) {
      return null;
    }

    const formValues = this.formValues[source as SourceType];

    return {
      totalInvestigatorCosts: formValues.totalInvestigatorCosts,
      spendToDateAmount: formValues.spendToDateAmount,
      remainingAmount: formValues.remainingAmount,
    };
  }
}
