import * as dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { Utils } from '@services/utils';
import { MoneyPipe } from '@pipes/money.pipe';
import { listInvoicesForReconciliationQuery } from '@services/gql.service';
import { every, partition, sortBy } from 'lodash-es';
import { Workflow } from '../../quarter-close/close-quarter-check-list/store/workflow.store';
import {
  ChecklistInvoiceExpenseAmounts,
  ChecklistInvoiceMonthlyQuickView,
  ChecklistMonthlyDropdownDate,
  QuarterCloseChecklistPermissionList,
  QuarterCloseChecklistRow,
  QuarterCloseChecklistRowSections,
  QuarterCloseChecklistRowTitles,
  QuarterCloseChecklistSection,
  QuarterCloseChecklistVendorEstimateQuery,
  QuarterCloseChecklistVendorEstimateSummary,
} from '../models/quarter-close-checklist.model';
import { QuarterDate } from '../../../period-close.component';

@Injectable()
export class QuarterCloseChecklistWorkflowService {
  workflow(rowId: QuarterCloseChecklistRowTitles, workflowList: Workflow[]): Workflow {
    // Static values
    // eslint-disable-next-line
    return workflowList.find((checklistWorkflow) => {
      return rowId === checklistWorkflow.name;
    })!;
  }

  getLockAllWorkflows(section: QuarterCloseChecklistSection, workflowList: Workflow[]): Workflow[] {
    if (!section || !workflowList || !workflowList.length) {
      return [];
    }

    const sectionType = Object.values(QuarterCloseChecklistRowSections).find(
      (workflowSection) => workflowSection === section.toString()
    );

    const workflowsFiltered = workflowList.filter(
      (workflow) => workflow.section === sectionType && !workflow.properties.locked
    );

    return sortBy(workflowsFiltered, ['order']);
  }

  closeExpensesRowCompleted(workflowList: Workflow[]): boolean {
    const closeExpensesRow = workflowList.find((workflow) => {
      return workflow.name === QuarterCloseChecklistRowTitles.CloseExpenses;
    });

    return closeExpensesRow?.properties.locked || false;
  }

  gatherDocumentsSectionCompleted(workflowList: Workflow[]): boolean {
    const gatherDocumentsSection = workflowList.filter((workflow) => {
      const section = Object.values(QuarterCloseChecklistRowSections).find(
        (workflowSection) => workflowSection === 'GatherDocuments'
      );
      return workflow.section === section;
    });

    return gatherDocumentsSection.every((worklow) => worklow.properties.locked);
  }

  confirmForecastSectionCompleted(workflowList: Workflow[]): boolean {
    const confirmForecastSection = workflowList.filter((workflow) => {
      const section = Object.values(QuarterCloseChecklistRowSections).find(
        (workflowSection) => workflowSection === 'ConfirmForecast'
      );
      return workflow.section === section;
    });

    return confirmForecastSection.every((worklow) => worklow.properties.locked);
  }

  completedTotals(workflowList: Workflow[]): string {
    if (!workflowList.length) {
      return '0';
    }

    const completedWorkflows = workflowList.filter((workflow) => {
      return workflow.properties.locked;
    });

    return `${completedWorkflows.length}`;
  }

  sectionTotals(section: QuarterCloseChecklistSection, workflowList: Workflow[]): string {
    if (!workflowList.length) {
      return '0';
    }

    const sectionKey = Object.entries(QuarterCloseChecklistSection).find(([, value]) => {
      return value === section;
    })?.[1];

    const completedWorkflows = workflowList.filter((workflow) => {
      return sectionKey === (workflow.section as keyof typeof QuarterCloseChecklistSection);
    });

    return `${completedWorkflows.length}`;
  }

  sectionCompletedTotals(section: QuarterCloseChecklistSection, workflowList: Workflow[]): string {
    if (!workflowList.length) {
      return '0';
    }

    const sectionKey = Object.entries(QuarterCloseChecklistSection).find(([, value]) => {
      return value === section;
    })?.[1];

    const completedWorkflows = workflowList.filter((workflow) => {
      return (
        sectionKey === (workflow.section as keyof typeof QuarterCloseChecklistSection) &&
        workflow.properties.locked
      );
    });

    return `${completedWorkflows.length}`;
  }

  isAnyWorkflowUnlockedAndHasNoPermissions(
    section: QuarterCloseChecklistSection,
    workflowList: Workflow[],
    permissionList: QuarterCloseChecklistPermissionList
  ): boolean {
    if (!workflowList.length) {
      return true;
    }

    const sectionKey = Object.entries(QuarterCloseChecklistSection).find(([, value]) => {
      return value === section;
    })?.[1];

    const uncompletedWorkflows = workflowList.filter((workflow) => {
      return (
        sectionKey === (workflow.section as keyof typeof QuarterCloseChecklistSection) &&
        !workflow.properties.locked
      );
    });

    const noPermissions = uncompletedWorkflows.filter((workflow) => {
      return !permissionList[workflow.permissionField];
    });

    return noPermissions.length > 0;
  }

  disabledLockTooltip(
    rowId: QuarterCloseChecklistRow | QuarterCloseChecklistSection,
    disabledLock: boolean
  ): string {
    // If previous quarter, return "disabled previous quarter" tooltip

    // If CloseExpenses is disabled
    if (rowId === QuarterCloseChecklistRow.CloseExpenses && disabledLock) {
      return 'Prior Closing Checklist entries must all be confirmed before Vendor Expenses can be confirmed.';
    }

    // If ConfirmDiscounts is disabled
    if (rowId === QuarterCloseChecklistRow.CloseDiscounts && disabledLock) {
      return 'Prior Closing Checklist entries must all be confirmed before Discount can be confirmed.';
    }

    // If ConfirmClose section is disabled
    if (rowId === QuarterCloseChecklistSection.ConfirmClose && disabledLock) {
      return 'Prior Closing Checklist entries must all be confirmed before this section can be confirmed.';
    }

    return '';
  }

  filterVendorEstimates(
    vendorEstimateQuery: QuarterCloseChecklistVendorEstimateQuery
  ): QuarterCloseChecklistVendorEstimateSummary[] {
    if (
      !vendorEstimateQuery.success ||
      vendorEstimateQuery.errors.length ||
      !vendorEstimateQuery.data
    ) {
      return [];
    }

    return vendorEstimateQuery.data.map((vendorEstimateSummary) => {
      return {
        id: vendorEstimateSummary.organization_id,
        name: vendorEstimateSummary.organization_name,
        vendorEstimateExists: vendorEstimateSummary.vendor_estimate_exists,
        activitiesExceedingForecast: vendorEstimateSummary.activities_exceeding_forecast,
        activitiesExceedingAmountRemaining:
          vendorEstimateSummary.activities_exceeding_amount_remaining,
      };
    });
  }

  isPastQuarterMonth(quarterMonth: string, currentOpenMonth: string): boolean {
    const quarterMonthDate = dayjs(quarterMonth);
    const currentOpenMonthDate = dayjs(currentOpenMonth);

    return quarterMonthDate.isBefore(currentOpenMonthDate);
  }

  parseToStartOfMonth(selectedQuarterMonth: string): string {
    const date = dayjs(selectedQuarterMonth).startOf('month');

    return date.isValid() ? date.format('YYYY-MM-DD') : '';
  }

  parseQuarterMonthDropdownLabel(
    quarterMonth: string,
    currentOpenMonth: string
  ): [string, boolean] {
    const quarterMonthDate = dayjs(quarterMonth);
    const currentOpenMonthDate = dayjs(currentOpenMonth);

    const isSameMonth = quarterMonthDate.isSame(currentOpenMonthDate);
    const isAfterMonth = quarterMonthDate.isAfter(currentOpenMonthDate);

    const monthStatus = isSameMonth || isAfterMonth ? 'Open' : 'Closed';

    const quarterMonthFormatted = quarterMonthDate.format('MMMM YYYY');

    const label = isAfterMonth
      ? `${quarterMonthFormatted}`
      : `${quarterMonthFormatted} (${monthStatus})`;

    return [label, isAfterMonth];
  }

  // When calculating the Checklist invoice total,
  // we only include invoice totals if they contain
  // at least one non-zero categorized value.
  validateInvoiceExpenses(expenseAmounts: ChecklistInvoiceExpenseAmounts): [boolean, number] {
    // Splitting expense amounts
    const [categorized, total] = partition(expenseAmounts, (expense) => {
      return expense.amount_type !== 'AMOUNT_TOTAL';
    });

    // Invoice total is not valid if all
    // categorized expense amounts are 0.
    const allZeroExpenses = every(categorized, (expense) => {
      return expense.amount === 0;
    });

    const invoiceTotal = total[0].amount || 0;

    return [!allZeroExpenses, invoiceTotal];
  }

  calculateInvoiceMonthlyQuickView(
    selectedQuarterMonth: string,
    ungroupedInvoices: listInvoicesForReconciliationQuery[]
  ): ChecklistInvoiceMonthlyQuickView {
    // Default values
    const invoiceQuickView: ChecklistInvoiceMonthlyQuickView = {
      numberOfInvoices: Utils.zeroHyphen,
      amountInvoiced: Utils.zeroHyphen,
    };

    // Return default
    if (!selectedQuarterMonth || !ungroupedInvoices.length) {
      return invoiceQuickView;
    }

    let numberOfInvoices = 0;
    let amountInvoiced = 0;

    // Calculate invoice totals
    ungroupedInvoices.forEach((invoice) => {
      if (invoice.accrual_period !== selectedQuarterMonth) {
        return;
      }

      const validatedInvoice = this.validateInvoiceExpenses(invoice.expense_amounts);

      const validInvoice = validatedInvoice[0];
      const invoiceTotal = validatedInvoice[1];

      if (!validInvoice) {
        return;
      }

      // Increase number of invoices
      numberOfInvoices = numberOfInvoices + 1;

      // Increase amount invoiced
      amountInvoiced = amountInvoiced + invoiceTotal;
    });

    // Return default
    if (!numberOfInvoices) {
      return invoiceQuickView;
    }

    invoiceQuickView.numberOfInvoices = numberOfInvoices.toString();
    invoiceQuickView.amountInvoiced = new MoneyPipe().transform(amountInvoiced) as string;

    return invoiceQuickView;
  }

  configureQuarterMonths(
    quarterMonths: QuarterDate[],
    currentOpenMonth: string
  ): ChecklistMonthlyDropdownDate[] {
    return quarterMonths.map((monthObj) => {
      const value = this.parseToStartOfMonth(monthObj.iso);
      const parsedQuarterMonth = this.parseQuarterMonthDropdownLabel(value, currentOpenMonth);

      const label = parsedQuarterMonth[0];
      const disabled = parsedQuarterMonth[1];

      return {
        ...monthObj,
        label,
        value,
        disabled,
      };
    });
  }
}
