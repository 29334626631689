import { Injectable } from '@angular/core';
import { FullActivity, FullCategory } from '../../category/category.query';

@Injectable()
export class ForecastTableGridUomService {
  parseCategoryUom(category: FullCategory | FullActivity, isChild: boolean): string {
    if (!isChild) {
      return '-';
    }

    if ('uom' in category) {
      return `${category.uom || ''}`;
    }

    return '-';
  }
}
