import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';

@Component({
  selector: 'aux-guard-warning',
  templateUrl: './guard-warning.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuardWarningComponent {
  constructor(public ref: CustomOverlayRef<unknown, { flag: boolean }>) {}
}
