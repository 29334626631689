<!-- Title Row -->
<div class="checklist-row-title-container flex p-3 items-center text-aux-black">
  <!-- Icon -->
  <aux-icon name="Files" *ngIf="section === QuarterCloseChecklistSection.GatherDocuments" />
  <aux-icon name="ChartBar" *ngIf="section === QuarterCloseChecklistSection.ConfirmForecast" />
  <aux-icon name="FileCheck" *ngIf="section === QuarterCloseChecklistSection.ConfirmClose" />

  <!-- Title and Counts -->
  <span class="ml-1 flex-grow font-normal"> {{ title }} ({{ complete }}/{{ total }})</span>

  <!-- Complete button -->
  <div *ngIf="sectionCompleted" class="checklist-row-title-complete flex items-center mr-2">
    <span class="mr-1">Complete</span>
    <aux-icon name="Check" [size]="20" />
  </div>

  <!-- Lock All button -->
  <div
    *ngIf="!sectionCompleted && !isLoadingList && total && total !== '0'"
    class="checklist-row-title-lock-link-container"
    [auxTooltip]="tooltip"
  >
    <span
      *ngIf="!disabledLock"
      class="checklist-row-title-lock-link underline cursor-pointer"
      [attr.data-pendo-id]="
        this.section === 'GatherDocuments'
          ? 'checklist-gather-docments-lock-all'
          : this.section === 'ConfirmForecast'
            ? 'checklist-confirm-forecast-lock-all'
            : this.section === 'ConfirmClose'
              ? 'checklist-confirm-close-lock-all'
              : ''
      "
      (click)="lockAllWorkflows(section)"
    >
      Lock All
    </span>
    <span
      *ngIf="disabledLock"
      class="checklist-row-title-lock-link-disabled underline cursor-default"
      [attr.data-pendo-id]="
        this.section === 'GatherDocuments'
          ? 'checklist-gather-docments-lock-all'
          : this.section === 'ConfirmForecast'
            ? 'checklist-confirm-forecast-lock-all'
            : this.section === 'ConfirmClose'
              ? 'checklist-confirm-close-lock-all'
              : ''
      "
    >
      Lock All
    </span>
  </div>
</div>
