import { Injectable } from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import {
  CreateDriverSiteDistributionInput,
  GqlService,
  updateDriverSiteDistributionMutation,
} from '@services/gql.service';
import { switchMap, tap } from 'rxjs/operators';
import { SiteDistributionStore } from './site-distribution-store.service';
import { MainQuery } from '../../../../../../../layouts/main-layout/state/main.query';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteDistributionService {
  constructor(
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private siteDistributionStore: SiteDistributionStore,
    private overlayService: OverlayService
  ) {}

  getSiteCurveDistributions(id?: string) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.siteDistributionStore.setLoading(true);

        return this.gqlService.listDriverSiteDistributions$([], id);
      }),
      tap(({ success, data, errors }) => {
        if (success && data) {
          this.siteDistributionStore.set(data);
        } else {
          this.overlayService.error(errors);
        }
        this.siteDistributionStore.setLoading(false);
      })
    );
  }

  async createSiteDistribution(site: CreateDriverSiteDistributionInput) {
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.createDriverSiteDistribution$(site)
    );
    if (success && data) {
      this.siteDistributionStore.upsert(data.id, {
        ...data,
      });
    } else {
      this.overlayService.error(errors);
    }
    return { success, errors, data };
  }

  async updateSiteDistribution(x: updateDriverSiteDistributionMutation) {
    this.siteDistributionStore.setLoading(true);
    const { success, errors, data } = await firstValueFrom(
      this.gqlService.updateDriverSiteDistribution$({
        id: x.id,
        distribution_mode: x.distribution_mode,
        distribution_month: x.distribution_month,
        sites_activated: x.sites_activated,
        sites_closed: x.sites_closed,
        net_sites_per_month: x.net_sites_per_month,
        net_sites_per_month_percentage: x.net_sites_per_month_percentage,
        sites_activated_percentage: x.sites_activated_percentage,
        sites_closed_percentage: x.sites_closed_percentage,
      })
    );
    if (success && data) {
      this.siteDistributionStore.update(data.id, {
        id: data.id,
        distribution_mode: data.distribution_mode,
        distribution_month: data.distribution_month,
        sites_activated: data.sites_activated,
        sites_closed: data.sites_closed,
        net_sites_per_month: data.net_sites_per_month,
        net_sites_per_month_percentage: data.net_sites_per_month_percentage,
        sites_activated_percentage: data.sites_activated_percentage,
        sites_closed_percentage: data.sites_closed_percentage,
      });
      this.siteDistributionStore.setLoading(false);
      return true;
    }
    this.overlayService.error(errors);

    this.siteDistributionStore.setLoading(false);
    return false;
  }
}
