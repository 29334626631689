import { Injectable } from '@angular/core';

// This service will filter the vendors listed
// on In-Month Adjustments, when navigating to
// In-Month Adjustments from Period Close Checklist
// (using the Review Vendor Estimates dropdown)

@Injectable()
export class QuarterCloseChecklistVendorService {
  filterByVendorId = '';
}
