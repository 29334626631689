import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { DepartmentType, RoleType } from '@services/gql.service';
import { UserModel } from '@models/trial-users/trial-user.store';

import { Utils } from '@services/utils';
import { CustomOverlayRef } from '../../overlay/custom-overlay-ref';

export interface UserDialogSubmit {
  given_name: string;
  family_name: string;
  email: string;
  vendor: string;
  organization_id: string;
  title: string;
  permissions: string;
  role: RoleType;
  department: DepartmentType[];
}

@UntilDestroy()
@Component({
  selector: 'aux-user-dialog',
  templateUrl: './user-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDialogComponent {
  isAuxiliusSupport$ = new BehaviorSubject<boolean>(false);

  addUserForm = this.formBuilder.group({
    given_name: '',
    family_name: '',
    email: '',
    organization_id: '',
    title: '',
    permissions: '',
    role: '',
    approvers: '',
    department: null,
  });

  userOptions = [
    { label: 'Admin', value: 'ROLE_ADMIN' },
    { label: 'User', value: 'ROLE_USER' },
  ];

  departmentOptions = Object.entries(Utils.DEPARTMENT_OPTIONS).map(([key, label]) => ({
    label,
    key,
  }));

  mode: 'edit' | 'add' = 'edit';

  constructor(
    private formBuilder: UntypedFormBuilder,
    public ref: CustomOverlayRef<UserDialogSubmit, { mode: 'edit' | 'add'; formValue: UserModel }>,
    public vendorsQuery: OrganizationQuery
  ) {
    if (this.ref.data?.mode) {
      this.mode = this.ref.data.mode;
    }
    if (this.ref.data?.formValue) {
      this.addUserForm.patchValue(this.ref.data?.formValue);
    }

    this.getFc('email')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((email: string) => {
        const isAuxiliusSupportSelected = this.getFc('organization_id')?.value === 'Aux Support';
        const isAuxiliusEmail = email.includes('@auxili.us');

        this.isAuxiliusSupport$.next(isAuxiliusEmail);

        if (isAuxiliusSupportSelected && !isAuxiliusEmail) {
          this.getFc('organization_id')?.setValue(undefined);
        }
      });
  }

  getFc(str: string) {
    return this.addUserForm.get(str);
  }

  onCancel() {
    this.ref.close();
  }

  onSubmit() {
    this.getFc('title')?.updateValueAndValidity();

    if (this.addUserForm.valid) {
      this.ref.close(this.addUserForm.value);
    }
  }
}
