import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { Trial } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export type TrialsState = EntityState<
  Omit<RequireSome<Partial<Trial>, 'id'>, 'sponsor_organization'> & {
    sponsor_organization: {
      id: string;
      name: string;
    };
  }
>;

export type TrialModel = getEntityType<TrialsState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'trials' })
export class TrialsStore extends EntityStore<TrialsState> {
  constructor() {
    super({});
  }
}
