import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuxIconName } from '@components/icon/icon';

@Component({
  selector: 'aux-investigator-forecast-source-select-item',
  template: `
    <div class="grid grid-cols-[1fr_15px]">
      <div
        #container
        class="border border-aux-gray-dark"
        [ngClass]="{
          'bg-aux-gray-light': control.value === value
        }"
      >
        <div
          class="border-b border-aux-gray-dark bg-aux-gray-light p-3 flex justify-between items-center"
        >
          <div class="flex flex-col">
            <div class="flex items-center">
              <input
                type="radio"
                class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                [formControl]="control"
                [value]="value"
              />
              <span class="ml-3 font-bold cursor-pointer" (click)="selectSource()">{{
                label
              }}</span>
            </div>

            <span *ngIf="avgPatientCost" class="text-sm text-aux-gray-darkest italic ml-[24px]"
              >Avg. Patient Cost: {{ avgPatientCost | money }}</span
            >
          </div>
          <aux-icon [name]="icon" *ngIf="icon" />
        </div>
        <div class="grid grid-cols-2 gap-y-3 p-3">
          <span>Forecast Total:</span>
          <span class="text-right">{{ forecastTotal | money }}</span>
          <span>Spend to Date:</span>
          <span class="text-right">{{ spendToDate | money }}</span>
        </div>
        <div class="border-b h-1 border-aux-gray-dark mr-2 ml-2"></div>
        <div class="grid grid-cols-2 p-3 font-bold">
          <span>Remaining:</span>
          <span class="text-right">{{ remaining | money }}</span>
        </div>
      </div>

      <div *ngIf="control.value !== value" class="bg-white"></div>

      <div
        *ngIf="control.value === value"
        class="bg-aux-gray-light border-t border-b border-aux-gray-dark absolute w-[20px] right-[66.6%]"
        [ngStyle]="{ height: container.offsetHeight + 'px' }"
      ></div>
    </div>
  `,
})
export class InvestigatorForecastSourceSelectItemComponent {
  @Input() control!: FormControl;

  @Input() label!: string;

  @Input() value!: string;

  @Input() avgPatientCost?: number;

  @Input() icon?: AuxIconName;

  @Input() forecastTotal?: number;

  @Input() spendToDate?: number;

  @Input() remaining?: number;

  selectSource() {
    if (this.control.enabled) {
      this.control.setValue(this.value);
    }
  }
}
