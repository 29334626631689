import { Directive } from '@angular/core';
import { Query } from '@datorama/akita';
import { SortOrder } from '@services/gql.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TrialInsightsState } from '../models/trial-insights-store.model';
import { TrialInsightsStore } from '../store/trial-insights.store';
import { TrialInsightsLegendOptions } from '../models/trial-insights-legend.model';
import {
  TrialInsightsTableModel,
  TrialInsightsTableOptions,
} from '../models/trial-insights-table.model';
import { ChartConfiguration, ChartType } from 'chart.js';
import { TrialInsightsChartModel } from '../models/trial-insights-chart.model';

export type StateActual = Omit<TrialInsightsState, 'currentOpenMonth'>;
export type StateSlice = StateActual[keyof StateActual];

export type StoreActual = TrialInsightsStore;
export type StoreQuery = Query<TrialInsightsState>;

export type StoreDetail<T> = T | null;

export type StoreData<T> = {
  initialRequest: StoreDetail<T>;
  remainingRequest: StoreDetail<T>;
};

export interface SortOrderDefault {
  buttonKey: string;
  defaultOrder: SortOrder;
}

export interface BaseConfig {
  slice: keyof StateActual;
  sortDefaults: SortOrderDefault[];
}

export interface ConstructorConfig<CType extends ChartType> extends BaseConfig {
  trialInsightsQuery: StoreQuery;
  chartService: TrialInsightsChartModel<CType>;
  tableService: TrialInsightsTableModel;
}

@Directive()
export class GenericTrialInsightsQuery<CType extends ChartType = ChartType> {
  trialInsightsQuery: StoreQuery;

  store: Observable<StateSlice>;

  isLoading: Observable<StateSlice['isLoading'] | undefined>;

  isLoadingRemaining: Observable<StateSlice['isLoadingRemaining'] | undefined>;

  data: Observable<StateSlice['data'] | null | undefined>;

  totalAmount: BehaviorSubject<string>;

  expectedEnrolled: BehaviorSubject<string>;

  expectedEnrolledExceeded: BehaviorSubject<boolean>;

  selectedKey: BehaviorSubject<string>;

  sortOrder: BehaviorSubject<SortOrder>;

  sortDefaults: SortOrderDefault[];

  sortSeen: string[];

  chartService: TrialInsightsChartModel<CType>;

  chartOptions: BehaviorSubject<ChartConfiguration<CType>>;

  legendOptions: BehaviorSubject<TrialInsightsLegendOptions>;

  tableService: TrialInsightsTableModel;

  tableOptions: BehaviorSubject<TrialInsightsTableOptions>;

  chartFn: () => ChartConfiguration<CType>;

  legendFn: () => TrialInsightsLegendOptions;

  tableFn: () => TrialInsightsTableOptions;

  constructor(config: ConstructorConfig<CType>) {
    this.trialInsightsQuery = config.trialInsightsQuery;

    this.store = this.trialInsightsQuery.select(config.slice);
    this.isLoading = this.store.pipe(map((x) => x.isLoading));
    this.isLoadingRemaining = this.store.pipe(map((x) => x.isLoadingRemaining));
    this.data = this.store.pipe(
      map((x) => x.data),
      distinctUntilChanged()
    );

    this.totalAmount = new BehaviorSubject<string>('');
    this.expectedEnrolled = new BehaviorSubject<string>('');
    this.expectedEnrolledExceeded = new BehaviorSubject<boolean>(false);

    this.sortDefaults = config.sortDefaults;

    this.selectedKey = new BehaviorSubject<string>(this.parseSelectedKey());
    this.sortOrder = new BehaviorSubject<SortOrder>(
      this.parseSortOrder(this.selectedKey.getValue())
    );

    // Determines whether a table should use the table's most recent
    // sort order, or the button key's default sort order
    this.sortSeen = [this.selectedKey.getValue()];

    this.tableService = config.tableService;
    this.chartService = config.chartService;

    this.tableFn =
      (this.tableService.createTable && this.tableService.createTable) ||
      (() => ({}) as unknown as TrialInsightsTableOptions);
    this.chartFn =
      (this.chartService.createChart && this.chartService.createChart) ||
      (() => ({}) as unknown as ChartConfiguration<CType>);
    this.legendFn =
      (this.chartService.createLegend && this.chartService.createLegend) ||
      (() => ({}) as unknown as TrialInsightsLegendOptions);

    this.tableOptions = new BehaviorSubject<TrialInsightsTableOptions>(this.tableFn());
    this.chartOptions = new BehaviorSubject<ChartConfiguration<CType>>(this.chartFn());
    this.legendOptions = new BehaviorSubject<TrialInsightsLegendOptions>(this.legendFn());
  }

  resetState(): void {
    this.totalAmount.next('');
    this.expectedEnrolled.next('');
    this.expectedEnrolledExceeded.next(false);

    this.tableOptions.next(this.tableFn());
    this.chartOptions.next(this.chartFn());
    this.legendOptions.next(this.legendFn());
  }

  parseSelectedKey(): string {
    return this.sortDefaults[0].buttonKey;
  }

  parseSortOrder(selectedKey: string): SortOrder {
    const order = this.sortDefaults.find((sortDefault) => sortDefault.buttonKey === selectedKey);

    return order ? order.defaultOrder : SortOrder.DESC;
  }

  parseStoreData<T>(data: T[]): StoreData<T> {
    const parsedData: StoreData<T> = {
      initialRequest: null,
      remainingRequest: null,
    };

    if (!data) {
      return parsedData;
    }

    parsedData.initialRequest = data[0] ? data[0] : null;
    parsedData.remainingRequest = data[1] ? data[1] : null;

    return parsedData;
  }

  parseStoreDetail<T>(
    parsedData: StoreData<T>,
    selectedKey: string,
    sortOrder: SortOrder
  ): StoreDetail<T> | null {
    const sortDefault = this.sortDefaults.find((currentDefault) => {
      return selectedKey === currentDefault.buttonKey;
    });

    if (!sortDefault) {
      return null;
    }

    if (sortOrder === sortDefault.defaultOrder) {
      return parsedData.initialRequest;
    } else {
      return parsedData.remainingRequest;
    }
  }

  changeSelectedKey = (buttonKey: string) => {
    const seen = this.sortSeen.includes(buttonKey);

    if (!seen) {
      const defaultSortOrder = this.parseSortOrder(buttonKey);

      this.sortSeen.push(buttonKey);
      this.sortOrder.next(defaultSortOrder);
    }

    this.selectedKey.next(buttonKey);
  };

  toggleSortOrder = () => {
    const current = this.sortOrder.getValue();

    if (current === SortOrder.DESC) {
      this.sortOrder.next(SortOrder.ASC);
    } else {
      this.sortOrder.next(SortOrder.DESC);
    }
  };
}
