<div *ngVar="params.editable | async as editable">
  <div
    *ngIf="dataCheck$ | async"
    id="expense-drag-drop"
    cdkDropList
    cdkDropListOrientation="horizontal"
    class="flex justify-start items-center w-[430px] bg-transparent overflow-hidden"
    [cdkDropListEnterPredicate]="noReturnPredicate"
    (cdkDropListDropped)="onDragDropChange($event)"
  >
    <div *ngFor="let timePeriod of optionData | async; let last = last" class="flex items-center">
      <div
        class="flex items-center w-[120px] justify-center h-[25px] text-[12px] px-[5px]"
        cdkDrag
        cdkDragLockAxis="x"
        [cdkDragDisabled]="!editable"
        [ngClass]="get(timePeriod, editable).bg"
        [auxTooltip]="!editable ? params.disabledTooltip : ''"
      >
        <div [ngClass]="get(timePeriod, editable).txtClass">
          {{ get(timePeriod, editable).text }}
        </div>
      </div>

      <div *ngIf="!last" class="w-[20px] text-center">></div>
    </div>
  </div>
</div>
