<div class="trial-insights-component">
  <!-- Header -->
  <aux-trial-insights-header [title]="title" [header]="header" [color]="color">
    <!-- Icon -->
    <ng-container icon>
      <aux-icon name="Calendar" [size]="35" [ngStyle]="{ color: color }" />
    </ng-container>

    <!-- Link to Trial Timeline -->
    <ng-container additionalContent>
      <a class="aux-link" [routerLink]="routerLink"> View Full Timeline </a>
    </ng-container>
  </aux-trial-insights-header>

  <!-- Bar chart (Gantt/stacked bar) -->
  <div style="position: relative; height: 205px; width: 99%; margin-left: 5px">
    <!-- Chart -->
    <canvas
      *ngIf="(loading$ | async) === false"
      baseChart
      [type]="chartOptions.type"
      [data]="chartOptions.data"
      [options]="chartOptions.options"
    >
    </canvas>
  </div>

  <!-- Legend -->
  <aux-trial-insights-legend [options]="legendOptions" />
</div>
