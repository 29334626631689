import { EventQuery } from '@models/event/event.query';
import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SitesService } from '@models/sites/sites.service';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { SitesQuery } from '@models/sites/sites.query';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import {
  CellClickedEvent,
  ColDef,
  ExcelExportParams,
  ExcelStyle,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ITooltipParams,
} from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { OverlayService } from '@services/overlay.service';
import { EventService } from '@services/event.service';
import { SitesStore } from '@models/sites/sites.store';
import {
  EventType,
  Currency,
  WorkflowStep,
  PatientProtocolType,
  listPatientProtocolsQuery,
  PermissionType,
  PatientVisitSchedule,
} from '@services/gql.service';
import { TableConstants } from '@constants/table.constants';
import {
  AuxExcelFormats,
  AuxExcelStyles,
  AgSetColumnsVisible,
  ServerSideFilterInfo,
  ServerSideSortOrder,
  ServerSideColumnFilterType,
  decimalAdd,
} from '@shared/utils';
import { AuthQuery } from '@models/auth/auth.query';
import { TableService } from '@services/table.service';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { CurrencyToggle } from '@components/toggle-currency/toggle-currency.type';
import { PatientTrackerService } from './state/patient-tracker.service';
import { SiteDialogComponent } from '../sites/site-dialog/site-dialog.component';
import { WorkflowQuery } from '../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { ROUTING_PATH } from '../../../app-routing-path.const';
import { FormBuilder, FormControl } from '@angular/forms';
import { PatientGroupsService } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';
import { Option } from '@components/components.type';
import { getPatientCostColumns, getPatientVisitColumns, getPatientsColumn } from './utils';
import { PatientTrackerScheduleService } from './services';
import { PatientTrackerRow } from './types';
import { Router } from '@angular/router';
import { FormValuesQuery } from '@models/form-values/form-values.query';
import { AuthService } from '@models/auth/auth.service';
import { DatasourceService } from '@services/datasource.service';

interface FilterForm {
  has_patient_group: boolean;
  siteIds: string[];
  patientGroupId: string | null;
}

@UntilDestroy()
@Component({
  selector: 'aux-patient-tracker',
  templateUrl: './patient-tracker.component.html',
  styleUrls: ['patient-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientTrackerComponent {
  readonly datasource = this.datasourceService.patientVisitScheduleDatasource;

  gridOptions: ExcelExportParams = {
    fileName: 'auxilius-patient-tracker.xlsx',
    sheetName: 'Patient Tracker',
  };

  showForecastCostThroughEOT = new BehaviorSubject(false);

  siteOptions$ = this.patientTrackerService.siteOptions$;

  protocolVersionControl = new FormControl('');

  showPlannedVisitsControl = new FormControl(false);

  workflowName = WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_PATIENT_TRACKER;

  iCloseMonthsProcessing$ = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  currencyToggle = CurrencyToggle;

  protocolEntryLink = `/${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.PATIENT_BUDGET_ENTRY}`;

  investigatorTransactionRoute = `${ROUTING_PATH.INVESTIGATOR.INDEX}/${ROUTING_PATH.INVESTIGATOR.INVESTIGATOR_TRANSACTIONS}`;

  exportGridOptions$ = new BehaviorSubject<GridOptions>({
    excelStyles: [...AuxExcelStyles],
    onGridReady: (event: GridReadyEvent) => {
      this.exportGridApi = event.api;
    },
  });

  currentOpenMonth$ = this.mainQuery.select('currentOpenMonth');

  serverSideFilters: ServerSideFilterInfo<PatientVisitSchedule>[] = [
    {
      column: 'site_id',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'siteIds',
    },
    {
      column: 'patient_group_id',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'patientGroupId',
      transformFunction: (v: unknown) => {
        return v !== this.visitCostsOptionValue ? v : null;
      },
    },
    {
      column: 'has_patient_group',
      type: ServerSideColumnFilterType.IsEqualTo,
      inputPropertyName: 'has_patient_group',
      transformFunction: () => {
        return (
          !!this.gridFiltersFormGroup.value.patientGroupId &&
          this.gridFiltersFormGroup.value.patientGroupId !== null
        );
      },
    },
  ];

  filterValues$ = new BehaviorSubject<Record<string, unknown>>({});

  sortModel$ = new BehaviorSubject<ServerSideSortOrder<PatientVisitSchedule>[]>([]);

  gridFiltersFormGroup = this.formBuilder.group<FilterForm>(
    this.serverSideFilters.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.inputPropertyName]: [],
      }),
      {
        has_patient_group: true,
        siteIds: [] as string[],
        patientGroupId: null,
      }
    )
  );

  cards$ = new BehaviorSubject<
    {
      header: string;
      data: string;
      firstProp: { status?: string; label: string };
      secondProp: { status?: string; label: string };
    }[]
  >([
    {
      header: 'Average Cost, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'high',
        label: 'Prior 1 month',
      },
      secondProp: {
        status: 'high',
        label: 'Prior 3 months',
      },
    },
    {
      header: 'Forecasted Average Cost through EOT, Enrollees to Date',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'high',
        label: 'Prior 3 months',
      },
      secondProp: {
        status: 'high',
        label: 'vs. Current Budget',
      },
    },
    {
      header: 'Budgeted Average Cost through EOT',
      data: Utils.zeroHyphen,
      firstProp: {
        status: 'high',
        label: 'Prior 3 months',
      },
      secondProp: {
        status: 'high',
        label: 'Prior 6 months',
      },
    },
  ]);

  display$ = new BehaviorSubject<'dates' | 'costs'>('dates');

  isContractedCurrency = false;

  totalPatientVisitsLTD$ = new BehaviorSubject(0);

  plannedThroughCurrentMonth$ = new BehaviorSubject(0);

  selectedVisibleCurrency$ = new BehaviorSubject<CurrencyToggle>(CurrencyToggle.PRIMARY);

  showPlannedVisits$ = new BehaviorSubject(false);

  gridDataLoading$ = new BehaviorSubject(true);

  gridOptions$ = new BehaviorSubject<GridOptions>({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      minWidth: 120,
      cellRenderer: AgCellWrapperComponent,
    },
    paginationPageSize: 100,
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    columnTypes: Utils.columnTypes,
    onFilterChanged: (params) => {
      const rows: PatientTrackerRow[] = [];
      params.api?.forEachNodeAfterFilter((r) => r.data && rows.push(r.data));

      this.renderPinnedRow(this.selectedVisibleCurrency$.getValue());
    },
  });

  private visitCostsOptionValue = 'visitCosts';

  gridAPI!: GridApi;

  exportGridApi!: GridApi;

  analyticsCardsLoading = new BehaviorSubject(false);

  isDisplayCosts = false;

  showAnalyticsSection$: Observable<boolean>;

  isQuarterCloseEnabled$: Observable<boolean>;

  isClosingPanelEnabled$: Observable<boolean>;

  isPlannedVisitsEnabled$: Observable<boolean>;

  patientGroupOptions$ = new BehaviorSubject<Option[]>([]);

  protocolVersionOptions$ = new BehaviorSubject<Option[]>([]);

  isAdminUser = false;

  isHandlingUpload$ = new BehaviorSubject(false);

  isLoadingData$ = new BehaviorSubject(true);

  showGrid$ = new BehaviorSubject(false);

  showForecastCostThroughEOT$: Observable<boolean>;

  userHasLockPatientDataPermission = false;

  constructor(
    private patientProtocolService: PatientProtocolService,
    private patientProtocolQuery: PatientProtocolQuery,
    private patientTrackerService: PatientTrackerService,
    private patientGroupService: PatientGroupsService,
    private sitesService: SitesService,
    private sitesStore: SitesStore,
    private mainQuery: MainQuery,
    public sitesQuery: SitesQuery,
    private overlayService: OverlayService,
    private workflowQuery: WorkflowQuery,
    private authQuery: AuthQuery,
    launchDarklyService: LaunchDarklyService,
    private eventService: EventService,
    private patientTrackerScheduleService: PatientTrackerScheduleService,
    private formValuesQuery: FormValuesQuery,
    private router: Router,
    private authService: AuthService,
    private datasourceService: DatasourceService,
    private formBuilder: FormBuilder,
    private eventQuery: EventQuery
  ) {
    launchDarklyService
      .select$((flags) => flags.visit_costs)
      .pipe(untilDestroyed(this))
      .subscribe((isEnabled) => {
        const optionsWithoutVisitCostOption = this.patientGroupOptions$
          .getValue()
          .filter(({ value }) => value !== this.visitCostsOptionValue);

        if (isEnabled) {
          this.patientGroupOptions$.next([
            ...optionsWithoutVisitCostOption,
            {
              value: this.visitCostsOptionValue,
              label: 'Visit Costs',
            },
          ]);
        } else {
          this.patientGroupOptions$.next([...optionsWithoutVisitCostOption]);
        }
      });

    this.setUserPermissions();

    this.showForecastCostThroughEOT$ = launchDarklyService.select$(
      (flags) => flags.section_forecast_cost_through_eot
    );

    this.isClosingPanelEnabled$ = launchDarklyService.select$(
      (flags) => flags.closing_checklist_toolbar
    );

    this.isPlannedVisitsEnabled$ = launchDarklyService.select$(
      (flags) => flags.patient_tracker_planned_visits
    );

    this.showForecastCostThroughEOT$.subscribe((flag) => {
      this.showForecastCostThroughEOT.next(flag);
    });

    this.showPlannedVisitsControl.valueChanges.pipe(untilDestroyed(this)).subscribe((show) => {
      this.showPlannedVisits$.next(!!show);
      this.gridAPI?.refreshCells();
      this.renderPinnedRow(this.selectedVisibleCurrency$.getValue());
    });

    // Trigger valueFormatter for multiple currencies
    this.selectedVisibleCurrency$
      .pipe(untilDestroyed(this))
      .subscribe((selectedVisibleCurrency) => {
        this.gridAPI?.refreshCells();
        this.renderPinnedRow(selectedVisibleCurrency);
      });

    this.eventService
      .select$(EventType.SITE_PATIENT_TRACKER_TEMPLATE_UPLOADED)
      .pipe(
        untilDestroyed(this),
        tap(() => this.gridAPI?.purgeInfiniteCache())
      )
      .subscribe(() => {
        this.isHandlingUpload$.next(false);
      });

    this.fetchVisits();

    this.patientTrackerService.getSiteOptions().pipe(untilDestroyed(this)).subscribe();

    this.gridFiltersFormGroup
      .get('siteIds')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.formValuesQuery.updateValues(
          {
            site_ids: (value || []) as string[],
            selectedCurrencies: { isContractCurrency: true, isPrimaryCurrency: true },
          },
          'patientTracker'
        );
      });

    this.fetchFilterListOptions();
    this.fetchAnalyticCardData();

    this.showAnalyticsSection$ = launchDarklyService.select$(
      (flags) => flags.section_patient_tracker_analytics
    );

    this.isQuarterCloseEnabled$ = this.workflowQuery.isWorkflowAvailable$;

    this.showAnalyticsSection$
      .pipe(
        switchMap((flag) => {
          if (flag) {
            this.analyticsCardsLoading.next(true);
            return this.patientTrackerService.getAnalyticsCards();
          }
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((val) => {
        this.analyticsCardsLoading.next(false);
        this.cards$.next(val);
      });

    this.display$.pipe(untilDestroyed(this)).subscribe(() => {
      this.setVisibilityVisitColumns();
      this.renderPinnedRow(this.selectedVisibleCurrency$.getValue());
    });

    this.authQuery.adminUser$.pipe(untilDestroyed(this)).subscribe((event) => {
      this.isAdminUser = event;
    });

    this.protocolVersionControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.gridAPI) {
        this.gridAPI.purgeInfiniteCache();
      }
    });
  }

  private getGroupId(patientGroupId: string | null): string | null {
    return patientGroupId !== this.visitCostsOptionValue ? (patientGroupId as string) : null;
  }

  private fetchVisits() {
    combineLatest([
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.gridFiltersFormGroup.get('patientGroupId')!.valueChanges,
      this.protocolVersionControl.valueChanges,
    ])
      .pipe(
        untilDestroyed(this),
        switchMap(([patientGroupId]) => {
          const protocols = this.patientProtocolService.get(
            [
              PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT,
              PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
              PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
            ],
            this.getGroupId(patientGroupId),
            false,
            this.protocolVersionControl.value ?? '',
            true
          ) as Observable<GraphqlResponse<listPatientProtocolsQuery[]>>;

          return patientGroupId
            ? protocols.pipe(
                tap(() => {
                  const columns = Object.values(this.patientProtocolQuery.getValue().entities || {})
                    .map(({ id, name, order_by, patient_protocol_type }) => {
                      let visitSortIndex: number;
                      switch (patient_protocol_type) {
                        case PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL:
                          visitSortIndex = -3;
                          break;
                        case PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED:
                          visitSortIndex = -2;
                          break;
                        default:
                          visitSortIndex = order_by || -1;
                      }
                      return {
                        id,
                        name,
                        visitSortIndex,
                      };
                    })
                    .sort(({ visitSortIndex }, { visitSortIndex: visitSortIndex2 }) =>
                      Utils.alphaNumSort(visitSortIndex, visitSortIndex2)
                    );

                  this.gridAPI?.setGridOption('columnDefs', [
                    ...(this.gridAPI?.getColumnDefs() || []).filter(
                      (col) => col.headerName !== 'Visits'
                    ),
                    getPatientVisitColumns(
                      columns ?? [],
                      PatientTrackerComponent.getFormattedTooltip,
                      this.selectedVisibleCurrency$,
                      this.showPlannedVisits$,
                      this.mainQuery.getValue().currentOpenMonth
                    ),
                  ]);

                  this.setVisibilityVisitColumns();
                })
              )
            : of();
        })
      )
      .subscribe();
  }

  private fetchAnalyticCardData() {
    const patientGroupInput = {
      filter_model: [{ column: 'has_patient_group', op: 'EQ', values: [true] }],
    };

    this.datasource.refresh$.pipe(untilDestroyed(this), take(1)).subscribe(async () => {
      try {
        const analyticsData =
          await this.datasourceService.retrievePatientVisitScheduleAnalytics(patientGroupInput);

        patientGroupInput.filter_model[0].values = [false];

        const visitCostAnalyticData =
          await this.datasourceService.retrievePatientVisitScheduleAnalytics(patientGroupInput);

        const totalPatientVisitsLTD = decimalAdd(
          analyticsData.aggregation.patient_visit_ltd_amount ?? 0,
          visitCostAnalyticData.aggregation.patient_visit_ltd_amount ?? 0
        );

        const plannedThroughCurrentMonth = decimalAdd(
          analyticsData.aggregation.current_planned_amount ?? 0,
          visitCostAnalyticData.aggregation.current_planned_amount ?? 0
        );

        this.totalPatientVisitsLTD$.next(totalPatientVisitsLTD);
        this.plannedThroughCurrentMonth$.next(plannedThroughCurrentMonth);
      } catch (err) {
        console.error('Failed to refresh analytics', err);
      }
    });
  }

  private setVisibilityVisitColumns() {
    const columnDefs: ColDef[] | undefined = this.gridAPI
      ?.getColumnDefs()
      ?.map((columns) => ('children' in columns ? columns.children : []))
      .flat();

    const colIds: string[] = [];

    columnDefs?.forEach((x) => {
      if ('field' in x && x.field?.includes('::')) {
        colIds.push(x.field);
      }
    });

    const dateColIds = colIds.filter((x) => x.includes('::dates'));
    const costsColIds = colIds.filter((x) => x.includes('::costs'));

    const value = this.display$.getValue();
    const toggleVisibleColumns = this.updateColumnsVisibility(value, dateColIds, costsColIds);

    if (this.gridAPI) {
      toggleVisibleColumns(this.gridAPI);
    }

    if (this.exportGridApi) {
      toggleVisibleColumns(this.exportGridApi);
    }

    this.gridAPI?.sizeColumnsToFit();
    this.updateExcelExportGridOptions();
  }

  private updateColumnsVisibility =
    (value: 'dates' | 'costs', dateColIds: string[], costsColIds: string[]) =>
    (gridApi: GridApi) => {
      AgSetColumnsVisible({
        gridApi: gridApi,
        keys: dateColIds,
        visible: value === 'dates',
      });
      AgSetColumnsVisible({
        gridApi: gridApi,
        keys: costsColIds,
        visible: value === 'costs',
      });
    };

  openSiteDialog(event: CellClickedEvent) {
    const { site_id } = event.data;
    const site = this.sitesQuery.getEntity(site_id);
    if (site) {
      this.overlayService.open({ content: SiteDialogComponent, data: { site } });
    }
  }

  openInvestigatorTransAction(event: CellClickedEvent) {
    this.router.navigate([this.investigatorTransactionRoute], {
      queryParams: { external_patient_id: event.value },
    });
  }

  private getVisibleRowData() {
    const rows = this.gridAPI?.getRenderedNodes().map<PatientTrackerRow>((row) => row.data);

    return rows ?? [];
  }

  private clearPinnedBottomRow() {
    this.gridAPI?.setGridOption('pinnedBottomRowData', []);
  }

  private renderPinnedRow(selectedCurrency: CurrencyToggle) {
    if (selectedCurrency === this.currencyToggle.PRIMARY) {
      this.generatePinnedBottomData(this.getVisibleRowData());
    } else {
      this.clearPinnedBottomRow();
    }
  }

  shouldIncludeHiddenCellsFromCalculation(column: string, val: PatientTrackerRow) {
    const suffix = this.display$.getValue() === 'costs' ? '::costs' : '::dates';
    const isVisitCost = column.endsWith(suffix);
    const isCompleted = isVisitCost ? val[`${column.replace(suffix, '')}::completed`] : false;

    return !isVisitCost || isCompleted;
  }

  generatePinnedBottomData(rows: PatientTrackerRow[]) {
    const data = this.getTotalRowData(rows);

    this.gridAPI?.setGridOption('pinnedBottomRowData', [
      { ...data, external_patient_id: 'Total', currency: Currency.USD },
    ]);
  }

  private getTotalRowData(rows: PatientTrackerRow[]) {
    return rows.reduce(
      (acc, val) => {
        Object.entries(val).forEach(([column, value]) => {
          const shouldCalculate = this.shouldIncludeHiddenCellsFromCalculation(column, val);

          if (typeof value === 'number' && shouldCalculate) {
            acc[column] ||= 0;
            acc[column] += value || 0;
          }
        });

        return acc;
      },
      { actual_cost: 0, forecast_cost: 0, visit: 0, other: 0 } as Record<string, number>
    );
  }

  onPaginationChange() {
    this.renderPinnedRow(this.selectedVisibleCurrency$.getValue());
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI = api;

    this.datasource.initialize({
      untilDestroyedPipeOperator: untilDestroyed(this),
      filters: this.serverSideFilters,
      filterValues$: this.filterValues$,
      sortModel$: this.sortModel$,
      parseFunction: (items) => {
        setTimeout(() => {
          this.renderPinnedRow(this.selectedVisibleCurrency$.getValue());
        }, 0);

        return this.patientTrackerScheduleService.parsePatientVisitSchedules(
          this.protocolVersionControl.value ?? '',
          this.mainQuery.getValue().currentOpenMonth,
          items
        );
      },
    });

    this.gridAPI?.setGridOption('columnDefs', [
      getPatientsColumn(
        (event) => this.openSiteDialog(event),
        (event) => this.openInvestigatorTransAction(event)
      ),
      getPatientCostColumns(
        PatientTrackerComponent.getFormattedTooltip,
        this.selectedVisibleCurrency$,
        this.showForecastCostThroughEOT.value
      ),
    ]);

    // Default sorting
    this.gridAPI.applyColumnState({
      state: [{ colId: 'site_no', sort: 'asc', sortIndex: 1 }],
    });

    this.updateExcelExportGridOptions();
  }

  private fetchFilterListOptions() {
    combineLatest([
      this.patientProtocolService.getPatientProtocolVersions(),
      this.patientGroupService.getPatientGroupOptions$(),
      this.sitesService.get().pipe(map(({ data }) => data)),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([protocolVersions, patientGroups, sites]) => {
        if (sites?.[0]) {
          this.showGrid$.next(sites.length > 0);
          this.sitesStore.setActive([sites[0].id]);
          const cachedSites = this.formValuesQuery.getValuesByFormName('patientTracker').site_ids;
          this.gridFiltersFormGroup.patchValue({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            siteIds: (cachedSites ? cachedSites : [sites[0].id]) as string[],
          });
        } else {
          this.showGrid$.next(false);
          this.gridDataLoading$.next(false);
        }
        this.patientGroupOptions$.next([...patientGroups, ...this.patientGroupOptions$.getValue()]);

        this.protocolVersionOptions$.next(
          protocolVersions.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        );

        if (this.patientGroupOptions$.getValue().length) {
          this.gridFiltersFormGroup.patchValue({
            patientGroupId: this.patientGroupOptions$.getValue()[0].value,
          });
        }

        if (this.protocolVersionOptions$.getValue().length) {
          this.protocolVersionControl.setValue(this.protocolVersionOptions$.getValue()[0].value);
        }

        this.isLoadingData$.next(false);
      });
  }

  generateExcelStyle(columnDefs: ColDef[]): ExcelStyle[] {
    const styles = columnDefs.map((cd) => {
      let dataType = 'string';
      let format;
      if (cd.field?.endsWith('::dates')) {
        dataType = 'DateTime';
      } else if (cd.field?.endsWith('::costs')) {
        dataType = 'Number';
        format = AuxExcelFormats.Cost;
      }
      return { id: cd.field, dataType, numberFormat: { format } } as ExcelStyle;
    });
    return [...AuxExcelStyles, ...styles];
  }

  getDynamicExcelParams = async (): Promise<ExcelExportParams> => {
    this.updateExcelExportGridOptions();

    const data = await this.datasource.downloadAll();
    const name = this.mainQuery.getSelectedTrial()?.short_name;

    const version =
      this.protocolVersionOptions$
        .getValue()
        .find(({ value }) => value === this.protocolVersionControl.value)?.label ?? '';

    const patientGroup =
      this.patientGroupOptions$
        .getValue()
        .find(({ value }) => value === this.gridFiltersFormGroup.value.patientGroupId)?.label ?? '';

    this.exportGridApi.getColumns()?.forEach((col) => col.setSort(null));

    const gridData = this.patientTrackerScheduleService.parsePatientVisitSchedules(
      this.protocolVersionControl.value ?? '',
      this.mainQuery.getValue().currentOpenMonth,
      data.items
    );

    const totals = this.getTotalRowData(gridData);

    this.exportGridApi.setGridOption('rowData', gridData);

    const columns = totals
      ? (Object.entries(totals)
          .map(([key, value]) => (typeof value === 'number' ? key : null))
          .filter((key) => key) as string[])
      : [
          'totalVisitCostsToDate',
          'totalCostsFromOtherVersions',
          'totalInvoiceablesToDate',
          'totalLTDCosts',
          'totalForecastCostThroughEot',
          'forecastRemaining',
        ];

    this.gridAPI.getAllDisplayedColumns().forEach((column) => {
      if (
        totals &&
        column.getColId().endsWith('::costs') &&
        columns.indexOf(column.getColId()) === -1
      ) {
        totals[column.getColId()] = 0;
        columns.push(column.getColId());
      }
    });

    const appendContent: ExcelExportParams['appendContent'] = totals
      ? [
          {
            cells: [
              {
                data: { value: `Total`, type: 'String' },
                styleId: 'total_row_header',
              },
              ...TableService.getTotalRowForExcel(
                totals,
                this.gridAPI,
                ['external_patient_id', 'currency'],
                columns
              ),
            ],
          },
        ]
      : [
          {
            cells: [],
          },
        ];

    return {
      author: 'Auxilius',
      fontSize: 11,
      sheetName: 'Patient Tracker',
      fileName: 'auxilius-patient-tracker.xlsx',
      processCellCallback: (params) => {
        if (
          !this.shouldIncludeHiddenCellsFromCalculation(
            params.column.getColId() ?? '',
            params.node?.data || {}
          )
        ) {
          return Utils.zeroHyphen;
        }

        return TableService.processCellForExcel(
          this.selectedVisibleCurrency$,
          columns,
          '::costs'
        )(params);
      },
      prependContent: [
        {
          cells: [
            {
              data: {
                value: `Trial: ${name}`,
                type: 'String',
              },
              styleId: 'first_row',
              mergeAcross: 1,
            },
          ],
        },
        {
          cells: [
            {
              data: {
                value: `Version: ${version}`,
                type: 'String',
              },
              styleId: 'first_row',
              mergeAcross: 1,
            },
          ],
        },
        {
          cells: [
            {
              data: {
                value: `Patient Group: ${patientGroup}`,
                type: 'String',
              },
              styleId: 'first_row',
              mergeAcross: 1,
            },
          ],
        },
      ],
      appendContent,
      shouldRowBeSkipped(params) {
        return params.node?.data?.external_patient_id === 'Total';
      },
    } as ExcelExportParams;
  };

  private updateExcelExportGridOptions() {
    const gridColumns = this.gridAPI?.getColumnDefs() ?? [];

    this.exportGridApi?.setGridOption('columnDefs', gridColumns);
    this.exportGridOptions$.next({
      ...this.exportGridOptions$.getValue(),
      excelStyles: this.generateExcelStyle(gridColumns),
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static getFormattedTooltip = ({ valueFormatted }: ITooltipParams): string | undefined | null =>
    valueFormatted !== Utils.zeroHyphen ? valueFormatted : '';

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_CHECKLIST_PATIENT_DATA],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasLockPatientDataPermission]) => {
        this.userHasLockPatientDataPermission = userHasLockPatientDataPermission;
      });
  }
}
