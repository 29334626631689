import { Injectable } from '@angular/core';
import {
  TrialInsightsTableHeaderData,
  TrialInsightsTableRowData,
} from '../../models/trial-insights-table.model';

@Injectable()
export class TrialInsightsTableService {
  headers(headerData: TrialInsightsTableHeaderData[], selectedButton: string): [string, string] {
    const selectedHeader = headerData.find((header) => {
      return header.buttonKey === selectedButton;
    });

    if (!selectedHeader) {
      return ['', ''];
    }

    return [selectedHeader.leftValue, selectedHeader.rightValue];
  }

  rowData(
    rowData: TrialInsightsTableRowData[],
    selectedButton: string
  ): TrialInsightsTableRowData[] {
    const selectedRowData = rowData.filter((row) => {
      return row.buttonKey === selectedButton;
    });

    if (!selectedRowData.length) {
      return [{ buttonKey: selectedButton }] as TrialInsightsTableRowData[];
    }

    return selectedRowData;
  }
}
