export class MessagesConstants {
  static readonly PAGE_LOCKED_FOR_PERIOD_CLOSE = 'This Page is Locked for Period Close';

  static readonly CHANGES_UNABLE_SINCE_PERIOD_CLOSED =
    'Changes are unable to be made since the period is closed';

  static readonly CHANGES_UNABLE_SINCE_MONTH_CLOSED =
    'Adjustments can only be made for the current open month.';

  static readonly CANT_ADJUST_DISCOUNT = 'Discount cannot be manually adjusted';

  static readonly DO_NOT_HAVE_PERMISSIONS_TO_ACTION =
    'You do not have permission to perform this action. Please contact your system administrator if this has been restricted by mistake.';

  static readonly FORGOT_PASSWORD = {
    EMAIL_HAS_BEEN_SEND:
      'An email has been sent to supplied email address. Please fill in the fields by entering your new password along with the code sent.',
    PASSWORD_RESET_SUCCESSFUL: 'Password reset successful!',
  };

  static readonly SITE = {
    CURRENCY_WARNING: `We've noticed your site's currency has changed. Please update your site costs on both the Visit Costs and Invoiceables tabs.`,
  };

  static readonly SITE_CURVES = {
    SUCCESSFULLY_CREATED: 'Site Curve successfully created',
  };

  static readonly PATIENT_CURVES = {
    SUCCESSFULLY_CREATED: 'Patient Curve successfully created',
  };

  static readonly RESOLVE_TABLE_ERRORS = 'Please resolve the errors inside the table!';

  static readonly SUCCESSFULLY_SAVED = 'Successfully Saved';

  static readonly ERROR_OCCURRED = 'An error has occurred';

  static readonly SYSTEM_MAINTENANCE =
    'Auxilius is temporarily down. Our forecast indicates that we’ll be up and running again soon!';

  static readonly START_DATE_BEFORE_END = 'Start Date must be before End Date';

  static readonly INVOICE = {
    SUCCESSFULLY_UPDATED: 'Invoice successfully updated!',
    DOES_NOT_HAVE_FILE: `This invoice doesn't have file`,
    BILL_COM_RETRIEVAL: 'Beginning Bill.com data retrieval...',
    COUPA_RETRIEVAL: 'Beginning Coupa data retrieval...',
    DYNAMICS365_RETRIEVAL: 'Beginning Dynamics 365 data retrieval...',
    DYNAMICS365_FO_RETRIEVAL: 'Beginning Dynamics 365 F&O data retrieval...',
    NETSUITE_RETRIEVAL: 'Beginning Netsuite data retrieval...',
    ORACLE_FUSION_RETRIEVAL: 'Beginning Oracle Fusion data retrieval...',
    QUICKBOOKS_RETRIEVAL: 'Beginning Quickbooks Online data retrieval...',
    SAGE_INTACCT_RETRIEVAL: 'Beginning Sage Intacct data retrieval...',
    TOTAL_VALIDATION:
      'Sum of Services Total, Discount Total, Investigator Total and Pass-Through Total must be equal to Total Amount.',
  };

  static readonly ONLY_NUMERIC_VALUES_ARE_SUPPORTED = 'Only numeric values are supported.';

  static readonly SOME_CHANGES_ARE_NOT_SAVED = 'Something went wrong. Some changes are not saved.';

  static readonly FILE = {
    PROBLEM_DOWNLOADING_TEMPLATE: 'There was a problem downloading the template',
    NEED_UPLOAD_FILE: 'You need to upload a file!',
    MAX_ONE_FILE: 'Maximum one file allowed!',
    ERROR_UPLOADING_FILE: 'There was an error uploading the file. Please try again',
    MUST_BE_CSV: 'File type must be a .csv!',
  };

  static readonly LOGOUT = 'You have been logged out!';

  static readonly DUPLICATE_NAMES = 'Duplicate names are not allowed';

  static readonly EMPTY_NAME = 'Empty names are not allowed';

  static readonly MUST_SELECT_VENDOR = 'Must select a vendor!';

  static readonly REFRESH_PORTFOLIO_DASHBOARD = 'Refreshing Portfolio data...';
}
