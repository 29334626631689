import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@services/utils';

@Pipe({
  name: 'auxNumber',
})
export class AuxNumberPipe implements PipeTransform {
  transform(value: number): string {
    return Utils.decimalFormatter(value);
  }
}
