import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContractedInvestigatorAmountValues } from '../../../investigator-forecast.types';

@Component({
  selector: 'aux-investigator-forecast-overview-amount',
  template: `
    <ng-container>
      <div
        *ngVar="values || {} as contractedValues"
        class="border border-aux-gray-dark p-4 bg-white"
      >
        <div class="grid grid-cols-2 gap-y-3">
          <span>Contracted Investigator Amount:</span>
          <span class="text-right">{{ contractedValues.totalInvestigatorCosts | money }}</span>

          <div class="border-b h-1 border-aux-gray-dark col-span-2"></div>

          <span>Visit Costs to Date:</span>
          <span class="text-right">{{ contractedValues.visitCostsToDate | money }}</span>

          <span>Invoiceables to Date:</span>
          <span class="text-right">{{ contractedValues.invoiceablesToDate | money }}</span>

          <span>Invoiceables %:</span>
          <span class="text-right">{{ contractedValues.invoiceablesPerc | percent }}</span>

          <div class="border-b h-1 border-aux-gray-dark col-span-2"></div>

          <span class="font-bold">Remaining</span>
          <span class="text-right font-bold">{{ contractedValues.remainingAmount | money }}</span>
        </div>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorForecastOverviewAmountComponent {
  @Input() values!: ContractedInvestigatorAmountValues;
}
