import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { Utils } from '@services/utils';
import { QuarterCloseChecklistDirective } from '../../directives/quarter-close-checklist.directive';
import {
  ChecklistComponent,
  ChecklistComponentAddPoReportUploadFn,
  ChecklistComponentChangeLockFn,
  ChecklistComponentVendorEstimateUploadFn,
  QuarterCloseChecklistRow,
  QuarterCloseChecklistRowComponent,
  QuarterCloseChecklistRowTitles,
  QuarterCloseChecklistVendorEstimateSummary,
} from '../../models/quarter-close-checklist.model';
import { QuarterCloseChecklistToggleService } from '../../services/quarter-close-checklist-toggle.service';
import {
  WorkflowQuery,
  WorkflowService,
} from '../../../quarter-close/close-quarter-check-list/store';
import { Workflow } from '../../../quarter-close/close-quarter-check-list/store/workflow.store';
import { UntypedFormControl } from '@angular/forms';
import { listUsersQuery, PermissionType } from '@services/gql.service';
import { AuthQuery } from '@models/auth/auth.query';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InvoiceService } from 'src/app/pages/vendor-payments-page/tabs/invoices/state/invoice.service';
import { map } from 'rxjs/operators';
import { MessagesConstants } from '@constants/messages.constants';

@UntilDestroy()
@Component({
  selector: 'aux-checklist-row-info',
  templateUrl: './checklist-row-info.component.html',
  styleUrls: ['../../quarter-close-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistRowInfoComponent implements OnChanges {
  @Input() checklistComponent!: ChecklistComponent<QuarterCloseChecklistRowComponent>;

  @Input() rowId!: QuarterCloseChecklistRow;

  @Input() disabledRow = false;

  @Input() disabledLock = false;

  @Input() open = false;

  @Input() workflow!: Workflow;

  @Input() tooltip = '';

  @Input() changeLockStatus!: ChecklistComponentChangeLockFn;

  @Input() vendorEstimateUploadClick!: ChecklistComponentVendorEstimateUploadFn;

  @Input() addPoReportUploadClick!: ChecklistComponentAddPoReportUploadFn;

  @Input() vendorEstimateSummaries!: QuarterCloseChecklistVendorEstimateSummary[];

  @Input() title = '';

  @Input() detailRowContainerClassName = 'checklist-row-info-detail-container w-full mt-4 p-4';

  @Input() selectedMonth: string | null = '';

  @Input() hasUserPermissions = false;

  @Input() isPastQuarterMonth = false;

  @Input() checklistAdminPermission = false;

  @Input() permissionType: PermissionType | null = null;

  isAdmin = false;

  usersList$ = new BehaviorSubject<listUsersQuery[]>([]);

  externalUserWithPermissionsList$ = this.usersList$.pipe(
    map((listUsers) =>
      listUsers.filter(
        (user) =>
          !user.email.includes('@auxili.us') &&
          user.permissions.some(
            (permission) =>
              permission.permission_type === this.permissionType &&
              permission.permission_roles === 'E'
          )
      )
    )
  );

  lockedBy = '';

  lockedDate = '';

  locked = false;

  route = '';

  rowTitles = QuarterCloseChecklistRowTitles;

  zeroHyphen = Utils.zeroHyphen;

  assignToFormControl = new UntypedFormControl(null);

  user = '';

  @ViewChild(QuarterCloseChecklistDirective)
  checklistComponentHost!: QuarterCloseChecklistDirective;

  originalWorkflow?: Workflow;

  workflowSubscription!: Subscription;

  constructor(
    private toggleService: QuarterCloseChecklistToggleService,
    private workflowQuery: WorkflowQuery,
    private invoiceService: InvoiceService,
    private workflowService: WorkflowService,
    private authQuery: AuthQuery,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(): void {
    if (this.checklistComponent && this.open) {
      this.initializeChecklistComponent();
    }

    if (this.workflow) {
      this.initializeWorkflow();
    } else if (!this.workflow) {
      this.resetWorkflow();
    }
  }

  toggleRow(): void {
    if (this.workflow) {
      this.toggleService.toggleRow(this.rowId);
    }
  }

  initializeWorkflow() {
    this.originalWorkflow = this.workflowQuery.getEntity(this.workflow.id);
    this.isAdmin = this.authQuery.isAuxAdmin();
    this.lockedBy = this.workflow.updatedAuthorFullName;
    this.lockedDate = this.workflow.update_date;
    this.locked = this.workflow.properties.locked;
    this.route = this.workflow.properties.route ? `/${this.workflow.properties.route}` : '';
    this.user = '';
    if (this.workflowSubscription) {
      this.workflowSubscription.unsubscribe();
    }
    this.workflowSubscription = this.workflowService.listUsers$
      .pipe(untilDestroyed(this))
      .subscribe((users) => {
        this.usersList$.next([...users]);

        const selectedUser = users.find(
          (user) =>
            !user.email.includes('@auxili.us') &&
            user?.sub === this.workflow?.properties?.assigned_to &&
            user.permissions.some(
              (permission) =>
                permission.permission_type === this.permissionType &&
                permission.permission_roles === 'E'
            )
        );

        if (selectedUser) {
          this.user = `${selectedUser?.given_name} ${selectedUser?.family_name}`;
        }

        this.assignToFormControl.setValue(selectedUser ? selectedUser.sub : null);
        this.cdr.markForCheck();
      });
  }

  resetWorkflow() {
    this.lockedBy = '';
    this.lockedDate = '';
    this.locked = false;
    this.route = '';
  }

  linkClicked(e: string) {
    if (e === QuarterCloseChecklistRowTitles.GatherInvoices) {
      const currentSelectedMonth = dayjs(this.selectedMonth).format('YYYY-MM');
      this.invoiceService.setAccrualPeriodsAndVendorFilter([currentSelectedMonth]);
    }
  }

  initializeChecklistComponent(): void {
    setTimeout(() => {
      if (!this.checklistComponent || !this.open || !this.workflow) {
        return;
      }

      const { viewContainerRef } = this.checklistComponentHost;

      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent<QuarterCloseChecklistRowComponent>(
        this.checklistComponent.component
      );

      componentRef.instance.parent = this;

      if (this.checklistComponent.id === 'GatherEstimates') {
        componentRef.instance.data = {
          vendorEstimateUploadClick: this.vendorEstimateUploadClick,
          disabledLock: this.disabledLock,
          vendorEstimateSummaries: this.vendorEstimateSummaries,
        };
      }
      if (this.checklistComponent.id === 'GatherContracts') {
        componentRef.instance.data = {
          addPoReportUploadClick: this.addPoReportUploadClick,
          disabledLock: this.locked,
        };
      }

      this.checklistComponentHost.changeDetectorRef.detectChanges();
    }, 0);
  }

  getLockBtnTooltip(): string {
    if (!this.hasUserPermissions) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    return this.disabledLock ? this.tooltip : '';
  }

  getUnlockBtnTooltip(): string {
    return this.hasUserPermissions || this.isPastQuarterMonth
      ? ''
      : MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
  }

  async changeAssign(workflow: Workflow) {
    const selectedUser = this.usersList$
      .getValue()
      .find((user) => user.sub === this.assignToFormControl.value);
    const currentUserID = this.authQuery.getValue().sub;
    const updateLockedBy = this.usersList$.getValue().find((user) => user.sub === currentUserID);
    const currenDate = dayjs(new Date()).format('YYYY-MM-DD');
    await this.changeLockStatus(
      this.locked,
      {
        ...workflow,
        updated_by: currentUserID ? currentUserID : workflow.updated_by,
        updatedAuthorFullName: updateLockedBy?.given_name + ' ' + updateLockedBy?.family_name,
        properties: {
          ...workflow.properties,
          assigned_to: selectedUser?.sub ? selectedUser?.sub : '',
          assigned_by: workflow.updated_by,
          assigned_date: currenDate,
        },
      },
      true
    );
  }
}
