import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PaymentScheduleQuery } from '../../../../vendor-payments-page/tabs/payment-schedule/state/payment-schedule.query';
import { PaymentQuarterData } from '../../../../vendor-payments-page/tabs/payment-schedule/state/payment-schedule.store';

@Component({
  selector: 'aux-payments-history',
  templateUrl: './payments-history.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsHistoryComponent {
  @Input() title = '';

  @Input() historyKey!: keyof PaymentQuarterData;

  paymentData$ = this.paymentScheduleQuery.getPaymentScheduleCumulative();

  constructor(private paymentScheduleQuery: PaymentScheduleQuery) {}
}
