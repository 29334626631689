import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { Activity } from '@services/gql.service';
import { RequireSome } from '@services/utils';
import { InMonthBudgetTypes } from '../category/category.store';

export type ActivityState = EntityState<
  Omit<
    RequireSome<Partial<Activity>, 'id' | 'category_id'>,
    'budget_forecast_settings' | 'total_wp' | 'total_forecast' | 'total_accrual'
  > & {
    primary_settings_id: string;
    secondary_settings_id: string;
    forecast_override_id: string | null;
    unit_cost: number;
    discount_flag?: boolean;
    total_cost: number;
  } & InMonthBudgetTypes,
  string
>;

export type ActivityModel = getEntityType<ActivityState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'activity' })
export class ActivityStore extends EntityStore<ActivityState> {
  constructor() {
    super({});
  }
}
