import { NgFor } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'aux-table-skeleton',
  template: `<div class="w-full h-[550px]">
    <div class="grid grid-cols-5">
      <div
        *ngFor="let item of [1, 2, 3, 4, 5]"
        class="flex justify-center items-center bg-aux-gray-light h-[45px] border border-aux-gray-dark font-bold cursor-pointer animate-pulse"
      >
        <span class="h-[25px] w-[100px] bg-aux-gray-light-2 rounded"></span>
      </div>
    </div>
    <div class="animate-pulse">
      <div
        *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
        class="w-full h-[45px] bg-aux-gray-light-2 my-1"
      ></div>
    </div>
  </div>`,
  standalone: true,
  imports: [NgFor],
})
export class TableSkeletonComponent {}
