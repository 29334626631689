import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Utils } from '@services/utils';
import { TableConstants } from '@constants/table.constants';
import {
  AgBudgetAttributeComponentParams,
  AgBudgetEnhancedGroupHeaderComponent,
} from '../../../budget-page/tabs/budget-enhanced/ag-budget-enhanced-group-header.component';
import { GroupCellToggleRendererComponent } from './group-cell-toggle-renderer.component';

export const getJournalEntriesGridColumns = (
  organizationQuery: OrganizationQuery,
  currencyFormatter: (params: ValueFormatterParams) => string,
  attributeColumns: ColDef[],
  redirectFn: (params: ICellRendererParams | CellClickedEvent) => void
): ColDef[] => {
  return [
    {
      field: 'vendor_id',
      rowGroup: true,
      hide: true,
      cellRenderer: GroupCellToggleRendererComponent,
      cellRendererParams: {
        onLinkClickFn: redirectFn,
        query: organizationQuery,
      },
    },
    ...attributeColumns,
    {
      headerName: 'Debit',
      headerClass: 'ag-header-align-center',
      field: 'debit_amount',
      minWidth: 125,
      width: 125,
      aggFunc: Utils.agSumFunc,
      valueFormatter: currencyFormatter,
      cellClass: (value) => {
        const classes: string[] = ['ag-cell-align-right'];
        if (value.value) {
          classes.push('cursor-pointer !text-aux-blue underline');
        } else {
          classes.push('aux-link-zero-hyphen');
        }
        return classes;
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (event.value) {
          redirectFn(event);
        }
      },
    },
    {
      headerName: 'Credit',
      headerClass: 'ag-header-align-center',
      field: 'credit_amount',
      minWidth: 125,
      width: 125,
      aggFunc: Utils.agSumFunc,
      valueFormatter: currencyFormatter,
      cellClass: (value) => {
        const classes: string[] = ['ag-cell-align-right'];
        if (value.value) {
          classes.push('cursor-pointer !text-aux-blue underline');
        } else {
          classes.push('aux-link-zero-hyphen');
        }
        return classes;
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (event.value) {
          redirectFn(event);
        }
      },
    },
    {
      headerName: 'Currency',
      headerClass: 'ag-header-align-center',
      field: 'vendor_currency',
      minWidth: 90,
      width: 90,
    },
    {
      headerName: 'Line Memo',
      headerClass: 'ag-header-align-center',
      field: 'line_memo',
      minWidth: 125,
      width: 125,
      cellClass: ['!block', 'truncate', 'text-left'],
      tooltipField: 'line_memo',
    },
    {
      headerName: 'Subsidiary',
      headerClass: 'ag-header-align-center',
      field: 'sponsor_name',
      minWidth: 150,
      width: 150,
      cellClass: ['!block', 'truncate', 'text-left'],
      tooltipField: 'sponsor_name',
    },
    {
      headerName: 'Program',
      headerClass: 'ag-header-align-center',
      field: `custom_attr_${btoa('program')}`,
      minWidth: 150,
      width: 150,
      cellClass: ['!block', 'truncate', 'text-left'],
      tooltipField: `custom_attr_${btoa('program')}`,
    },
    {
      headerName: 'Project',
      headerClass: 'ag-header-align-center',
      field: `custom_attr_${btoa('project')}`,
      minWidth: 150,
      width: 150,
      cellClass: ['!block', 'truncate', 'text-left'],
      tooltipField: `custom_attr_${btoa('project')}`,
    },
    {
      headerName: 'Reversal Date',
      headerClass: 'ag-header-align-center',
      field: 'reversal_date',
      minWidth: 150,
      width: 150,
      cellClass: ['ag-cell-align-right'],
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return Utils.agDateFormatter(params, {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
          });
        }
        return '';
      },
    },
  ] as ColDef[];
};

export const JOURNAL_ENTRIES_GRID_OPTIONS = {
  defaultColDef: {
    ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
    sortable: false,
    resizable: true,
  },
  autoGroupColumnDef: {
    headerName: '',
    minWidth: 200,
    width: 200,
    field: 'entry_name',
    colId: 'entry_name',
    tooltipField: 'entry_name',
    cellRendererParams: {
      suppressCount: true,
    },
    pinned: 'left',
    headerComponent: AgBudgetEnhancedGroupHeaderComponent,
    headerComponentParams: {
      columnsToCollapse: [],
      expandLevel: () => -1,
      template: `Vendor`,
      alignHeaderOneRowHeight: true,
    } as unknown as AgBudgetAttributeComponentParams,
  },
  groupDefaultExpanded: 1,
  suppressAggFuncInHeader: true,
  getRowClass: Utils.parentEvenGroupOddRowClass,
};
