<ng-container [ngSwitch]="variant">
  <ng-template [ngSwitchCase]="excelButtonVariants.OUTLINE">
    <aux-button
      [variant]="'secondary'"
      icon="FileExport"
      [iconSize]="svgSize"
      [label]="title"
      [onClick]="exportExcel"
      [className]="className"
    />
  </ng-template>

  <ng-template [ngSwitchCase]="excelButtonVariants.FILLED">
    <aux-button
      icon="FileExport"
      [iconSize]="svgSize"
      [label]="title"
      [onClick]="exportExcel"
      [className]="className"
    />
  </ng-template>
</ng-container>
