import { listDriverPatientDistributionsQuery } from '@services/gql.service';
import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  HeaderClassParams,
  RowClassParams,
  ValueFormatterFunc,
  ValueFormatterParams,
  ValueParserFunc,
} from '@ag-grid-community/core';
import { Maybe, Utils } from '@services/utils';
import { decimalRoundingToString } from '@shared/utils';
import { TableConstants } from '@constants/table.constants';
import { ChartConfiguration } from 'chart.js';
import { ChartDataset } from 'chart.js/dist/types';
import { BehaviorSubject } from 'rxjs';
import { AgMonthCellComponent } from '../components/ag-month-cell.component';

type Timeline = BehaviorSubject<{ startDate?: string; endDate?: string }>;

export interface TopCard {
  title: string;
  value: string;
}

export interface ForecastActualComparisonType {
  title: string;
  value: string;
  percent: string;
}

export interface ComparisonCard {
  title: string;
  compareTo: ForecastActualComparisonType;
  compareFrom: ForecastActualComparisonType;
}

export interface ForecastAndActualType {
  patients_enrolled: Maybe<number>;
  patients_discontinued: Maybe<number>;
  patients_complete: Maybe<number>;
  total_patients_enrolled: Maybe<number>;
  net_patients_enrolled: Maybe<number>;
  cumulative_enrollment_percentage: Maybe<number>;
}

export const MIN_WIDTH = 90;

export const MAX_WIDTH = 170;

export const patientDriverMonthColumnDefs = (
  currentPatientGroupName$: BehaviorSubject<string>,
  timeline$: Timeline
): ColDef | ColGroupDef =>
  ({
    headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
    headerValueGetter: () => {
      return currentPatientGroupName$.getValue();
    },
    colId: 'month',
    children: [
      {
        headerName: 'Month',
        field: 'distribution_month',
        cellRenderer: AgMonthCellComponent,
        cellRendererParams: {
          timeline$,
          noMonthInTimelineTooltipMessage:
            'This month is no longer included in the trial timeline. Please adjust your Patient Curve or Trial Timeline to fix this issue.',
        },
        editable: false,
        minWidth: MIN_WIDTH * 2,
        width: MIN_WIDTH * 2,
        headerClass: 'ag-header-cell',
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value === 'TOTAL') {
            return 'TOTAL';
          }
          return params.value
            ? Utils.dateFormatter(params.value, { day: undefined, year: '2-digit' })
            : '';
        },
      },
    ],
  }) as ColGroupDef;

export const patientDriverEnrolledColumnDefs = (
  valueFormatter: ValueFormatterFunc,
  valueParser: ValueParserFunc,
  showEDCColumns: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Enrolled',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  colId: 'enrolled',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.patients_enrolled',
      minWidth: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      width: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
    {
      headerName: 'EDC',
      field: 'actual.patients_enrolled',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      editable: false,
      hide: !showEDCColumns,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
  ],
});

export const patientDriverCompleteColumnDefs = (
  valueFormatter: ValueFormatterFunc,
  valueParser: ValueParserFunc,
  showEDCColumns: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Complete',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  colId: 'patients_complete',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.patients_complete',
      minWidth: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      width: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
    {
      headerName: 'EDC',
      field: 'actual.patients_complete',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      editable: false,
      hide: !showEDCColumns,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
  ],
});

export const patientDriverDiscontinuedColumnDefs = (
  valueFormatter: ValueFormatterFunc,
  valueParser: ValueParserFunc,
  showEDCColumns: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Discontinued',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  colId: 'patients_discontinued',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.patients_discontinued',
      minWidth: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      width: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
    {
      headerName: 'EDC',
      field: 'actual.patients_discontinued',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      editable: false,
      hide: !showEDCColumns,
      valueFormatter: valueFormatter,
      valueParser: valueParser,
    },
  ],
});

export const patientDriverNetEnrolledColumnDefs = (
  valueFormatter: ValueFormatterFunc,
  showEDCColumns: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Net Enrolled',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  colId: 'net_patients_enrolled',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.net_patients_enrolled',
      minWidth: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      width: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      valueFormatter: valueFormatter,
      editable: false,
    },
    {
      headerName: 'EDC',
      field: 'actual.net_patients_enrolled',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      hide: !showEDCColumns,
      valueFormatter: valueFormatter,
      editable: false,
    },
  ],
});

export const patientDriverTotalEnrolledColumnDefs = (
  valueFormatter: ValueFormatterFunc,
  showEDCColumns: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Total Enrolled',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  colId: 'total_patients_enrolled',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.total_patients_enrolled',
      minWidth: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      width: showEDCColumns ? MIN_WIDTH : MIN_WIDTH * 2,
      valueFormatter: valueFormatter,
      editable: false,
    },
    {
      headerName: 'EDC',
      field: 'actual.total_patients_enrolled',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      hide: !showEDCColumns,
      valueFormatter: valueFormatter,
      editable: false,
    },
  ],
});

export const patientDriverAccrualPatientCurveColumnDefs = (
  editCell: boolean
): ColDef | ColGroupDef => ({
  headerName: 'Accrual Curve',
  headerClass: 'ag-header-align-center bg-aux-blue-dark aux-white border-aux-blue-dark',
  wrapText: true,
  colId: 'cumulative_enrollment_percentage',
  children: [
    {
      headerName: 'Forecast',
      field: 'forecast.cumulative_enrollment_percentage',
      minWidth: MIN_WIDTH,
      width: MIN_WIDTH,
      cellClass: () => {
        return editCell
          ? ['opacity-70', 'cellPercent', 'justify-end']
          : ['justify-end', 'cellPercent'];
      },
      editable: false,
      valueFormatter: (val: ValueFormatterParams) => {
        return val.value ? `${decimalRoundingToString(val.value, 2)}%` : Utils.zeroHyphen;
      },
    },
  ],
});

export class PatientCurvesConstants {
  static readonly GRID_OPTIONS_EXEL_STYLES = [
    {
      id: 'header',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
      interior: { patternColor: '#094673', color: '#094673', pattern: 'Solid' },
    },
    {
      id: 'text-aux-error',
      font: { color: '#D73C37' },
    },
    {
      id: 'text-aux-green',
      font: { color: '#437F7F' },
    },
    {
      id: 'cellPercent',
      font: { fontName: 'Arial', size: 11 },
      alignment: { horizontal: 'Right' },
      numberFormat: { format: '0.00;-0.00;—;—' },
    },
    {
      id: 'first_row',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#FFFFFF' },
      interior: { patternColor: '#999999', color: '#999999', pattern: 'Solid' },
    },
    {
      id: 'total_row_header',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
    },
    {
      id: 'total_row_extended_decimal',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'Number',
      numberFormat: { format: '#,##0.00#######' },
    },
    {
      id: 'total_row_percent',
      font: { fontName: 'Arial', size: 11, bold: true, color: '#000000' },
      interior: { patternColor: '#D9D9D9', color: '#D9D9D9', pattern: 'Solid' },
      dataType: 'String',
      alignment: {
        horizontal: 'Right',
      },
    },
  ];

  static readonly GRID_OPTIONS = {
    onCellClicked(event: CellClickedEvent) {
      const cellRange = event.api.getCellRanges();
      if ((cellRange?.length || 0) > 1) {
        event.api.stopEditing();
      }
    },
    enableRangeSelection: true,
    undoRedoCellEditingLimit: 20,
    undoRedoCellEditing: true,
    suppressMenuHide: true,
    enterNavigatesVertically: true,
    enterNavigatesVerticallyAfterEdit: true,
    headerHeight: 50,
    pinnedBottomRowData: [],
    groupIncludeFooter: true,
    rowClassRules: {
      'has-error': (params: RowClassParams) => params.data.showError,
    },
    getRowStyle: (params: RowClassParams) => {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold' };
      }
      return {};
    },
  };

  static multiChartOptions(
    data: listDriverPatientDistributionsQuery[],
    labels: string[]
  ): ChartConfiguration<'line'> {
    const colors = [
      {
        color: '#E3B506',
        border: true,
      },
      {
        color: '#236262',
        border: true,
      },
      {
        color: '#095b95',
        border: false,
      },
      {
        color: '#6B9DBF',
        border: false,
      },
      {
        color: '#9dbdd5',
        border: false,
      },
    ].map(({ color, border }) => {
      return <ChartDataset<'line'>>{
        hoverBorderColor: color,
        hoverBackgroundColor: color,
        pointStyle: 'rectRounded',
        borderColor: color,
        backgroundColor: color,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
        borderWidth: border ? 3 : 0,
        pointRadius: 0,
        pointHoverBorderWidth: 4,
        pointHoverRadius: 4,
        pointHitRadius: 4,
      };
    });

    return {
      data: {
        datasets: [
          {
            ...colors[0],
            data: data.map((x) => x.forecast.net_patients_enrolled || 0),
            label: 'Net Patients Enrolled',
            type: 'line',
          },
          {
            ...colors[1],
            data: data.map((x) => x.forecast.total_patients_enrolled || 0),
            label: 'Total Patients Enrolled',
            type: 'line',
          },
          {
            ...colors[2],
            data: data.map((x) => x.forecast.patients_enrolled || 0),
            cubicInterpolationMode: 'monotone',
            label: 'Enrolled',
            // https://github.com/chartjs/Chart.js/issues/11356
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: 'bar',
          },
          {
            ...colors[3],
            data: data.map((x) => x.forecast.patients_discontinued || 0),
            cubicInterpolationMode: 'monotone',
            label: 'Discontinued',
            // https://github.com/chartjs/Chart.js/issues/11356
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: 'bar',
          },
          {
            ...colors[4],
            data: data.map((x) => x.forecast.patients_complete || 0),
            cubicInterpolationMode: 'monotone',
            label: 'Completed',
            // https://github.com/chartjs/Chart.js/issues/11356
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            type: 'bar',
          },
        ],
        labels,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
      legend: { bottom: true, right: false, left: false, top: false },
      type: 'line',
    };
  }

  static getCellClasses = (editMode$: BehaviorSubject<boolean>) => (params: CellClassParams) => {
    const editCell = editMode$.getValue();
    if (params.data.distribution_month === 'TOTAL') {
      return params.colDef.field === 'distribution_month' ? '' : 'justify-end';
    }
    if (!params.colDef.field) {
      return '';
    }
    if (params.colDef.field.includes('distribution_month')) {
      return editCell
        ? ['opacity-70', 'justify-start', 'ag-cell-not-edit-cell']
        : ['justify-start'];
    }
    if (
      params.colDef.field.includes('forecast') &&
      (params.colDef.field.includes('patients_enrolled') ||
        params.colDef.field.includes('patients_discontinued') ||
        params.colDef.field.includes('patients_complete')) &&
      !params.colDef.field.includes('net') &&
      !params.colDef.field.includes('total')
    ) {
      return editCell
        ? ['editable-cell', 'justify-end', 'ag-cell-align-right']
        : ['justify-end', 'ag-cell-align-right'];
    }
    if (
      params.colDef.field.includes('actual') ||
      params.colDef.field.includes('net') ||
      params.colDef.field.includes('total')
    ) {
      return editCell
        ? ['justify-end', 'ag-cell-align-right', 'ag-cell-not-edit-cell']
        : ['justify-end', 'ag-cell-align-right'];
    }
    return '';
  };

  static getHeaderClasses =
    (editMode$: BehaviorSubject<boolean>) =>
    (params: HeaderClassParams): string | string[] => {
      const editCell = editMode$.getValue();
      if (!params.column?.getColId()) {
        return '';
      }
      const colId = params.column.getColId();
      switch (colId.includes('forecast')) {
        case colId.includes('enrolled'):
        case colId.includes('discontinued'):
        case colId.includes('complete'):
          return editCell &&
            !colId.includes('total') &&
            !colId.includes('net') &&
            !colId.includes('actual')
            ? ['ag-header-edit-mode']
            : [];
        default:
          return '';
      }
    };

  static allColDefs = (
    valueFormatter: ValueFormatterFunc,
    valueParser: ValueParserFunc,
    editCell: boolean,
    showEDCColumns: boolean,
    currentPatientGroupName$: BehaviorSubject<string>,
    timeline$: Timeline
  ): ColDef[] | ColGroupDef[] => [
    {
      headerName: '',
      field: 'id',
      valueFormatter: valueFormatter,
      hide: true,
    },
    patientDriverMonthColumnDefs(currentPatientGroupName$, timeline$),
    TableConstants.SPACER_COLUMN,
    patientDriverEnrolledColumnDefs(valueFormatter, valueParser, showEDCColumns),
    TableConstants.SPACER_COLUMN,
    patientDriverDiscontinuedColumnDefs(valueFormatter, valueParser, showEDCColumns),
    TableConstants.SPACER_COLUMN,
    patientDriverCompleteColumnDefs(valueFormatter, valueParser, showEDCColumns),
    TableConstants.SPACER_COLUMN,
    patientDriverNetEnrolledColumnDefs(valueFormatter, showEDCColumns),
    TableConstants.SPACER_COLUMN,
    patientDriverTotalEnrolledColumnDefs(valueFormatter, showEDCColumns),
    TableConstants.SPACER_COLUMN,
    patientDriverAccrualPatientCurveColumnDefs(editCell),
  ];
}
