import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'aux-investigator-forecast-link',
  template: ` <a class="aux-link text-sm" [routerLink]="[link]">{{ label }} </a>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorForecastLinkComponent {
  @Input() label!: string | number;

  @Input() link!: string;
}
