import { InvestigatorTransactionSourceType } from '@services/gql.service';

export const sourceFilterOptions = [
  {
    id: InvestigatorTransactionSourceType.INVESTIGATOR_TRANSACTION_SOURCE_TEMPLATE_UPLOAD,
    name: 'EDC',
  },
  {
    id: InvestigatorTransactionSourceType.INVESTIGATOR_TRANSACTION_SOURCE_SITE_INVOICEABLE_SCHEDULE,
    name: 'Scheduled',
  },
];
