import { Injectable } from '@angular/core';
import {
  listDriverPatientDistributionsQuery,
  listDriverSiteDistributionsQuery,
} from '@services/gql.service';
import * as minMax from 'dayjs/plugin/minMax';
import * as dayjs from 'dayjs';
import { first } from 'lodash';
import { DateUtils } from '@shared/utils';
import { ICellRendererParams, CellClassParams } from '@ag-grid-community/core';
import { BehaviorSubject } from 'rxjs';

dayjs.extend(minMax);

type Distribution = listDriverSiteDistributionsQuery | listDriverPatientDistributionsQuery;

type Timeline = {
  startDate: string;
  endDate: string;
};

@Injectable({ providedIn: 'root' })
export class DistributionTimelineService {
  constructor() {}

  getStartDate(timelineStartDate: string, distributions: Distribution[]) {
    const distributionStartMonth = first(distributions)?.distribution_month;

    if (!distributionStartMonth) {
      return timelineStartDate;
    }

    return dayjs(timelineStartDate).isAfter(distributionStartMonth)
      ? distributionStartMonth
      : timelineStartDate;
  }

  getEndDate(timelineEndDate: string, distributions: Distribution[]) {
    const distributionEndMonth = distributions[distributions.length - 1]?.distribution_month;

    if (!distributionEndMonth) {
      return timelineEndDate;
    }

    return dayjs(timelineEndDate).isBefore(distributionEndMonth)
      ? distributionEndMonth
      : timelineEndDate;
  }

  getDistributionTimeline(currentTimeline: Timeline, distributions: Distribution[]): Timeline {
    return {
      startDate: this.getStartDate(currentTimeline.startDate, distributions),
      endDate: this.getEndDate(currentTimeline.endDate, distributions),
    };
  }

  static isMonthNoLongerInTimeline(
    params: ICellRendererParams | CellClassParams,
    distributionDate: string,
    timeline$: BehaviorSubject<Partial<Timeline>>
  ) {
    if (params.data?.distribution_month === 'TOTAL') return false;

    return !DateUtils.isInRange(distributionDate, timeline$.getValue());
  }
}
