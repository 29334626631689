<div class="border border-aux-gray-dark rounded overflow-hidden">
  <div class="p-4 2xl:p-6">
    <ng-content></ng-content>
  </div>
  <div class="p-4 2xl:p-6 bg-aux-gray-light">
    <div class="flex justify-between items-center mb-4">
      <div class="font-bold text-aux-black">Alerts</div>
      <div class="flex items-center space-x-4">
        <button class="font-medium underline text-aux-blue text-sm">View All</button>
        <button class="font-medium underline text-aux-blue text-sm" (click)="onManage()">
          Manage
        </button>
      </div>
    </div>

    <div class="space-y-4">
      <div *ngFor="let alert of alerts" class="flex space-x-4">
        <div>
          <div
            class="w-4 h-4 rounded-full"
            [ngClass]="{
              'bg-aux-error': alert.color === 'red',
              'bg-aux-green': alert.color === 'green'
            }"
          ></div>
        </div>

        <div class="text-sm text-aux-black">
          {{ alert.message }}
        </div>
      </div>
    </div>
  </div>
</div>
