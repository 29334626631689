<div class="flex flex-row-reverse">
  <div class="flex justify-between items-center ml-4">
    <aux-menu
      #patient_driver_menu
      [showMenuForExternal]="(userHasUploadPatientDriverPermission$ | async) === true"
      [disableMenuForEveryone]="(isPatientsFinalized$ | async) === true"
    >
      <button
        type="button"
        role="menuitem"
        class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 flex px-4 py-2 text-sm w-full items-center focus:outline-none"
        (click)="onPatientDriverUploadClick(); patient_driver_menu.close()"
      >
        <aux-icon name="Upload" />
        <span class="ml-2">Upload</span>
      </button>
    </aux-menu>
  </div>
  <aux-editable-list-dropdown
    [dropdownFormControl]="curveControl"
    [items]="patientCurveListOptions"
    [label]="'Select a Curve:'"
    [classList]="'w-64'"
    [createBtnLabel]="'Create Blended Curve'"
    (edit)="editCurve($event)"
    (delete)="deleteCurve($event)"
    (create)="editCurve()"
  />
</div>

<ng-container *ngIf="(loading$ | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>
<ng-container *ngIf="(loading$ | async) === false">
  <div
    *ngIf="(showEnrollmentSettings$ | async) === true"
    class="border rounded border-aux-gray-dark bg-aux-gray-light py-4 px-8 w-10/12"
  >
    <div
      *ngIf="(patientCurveQuery.selectLoading() | async) === false; else loading"
      class="font-bold text-lg text-black mb-4"
    >
      Enrollment Settings
    </div>
    <div class="grid grid-cols-5 gap-10 whitespace-nowrap text-xs">
      <div>
        <div class="mb-3">Patients Enrolled (pspm)</div>
        <aux-input [type]="'number'" />
      </div>
      <div>
        <div class="mb-3">Total Patients Enrolled</div>
        <aux-input [type]="'number'" />
      </div>
      <div>
        <div class="mb-3">First Patient Enrolled</div>
        <aux-input [type]="'date'" />
      </div>
      <div>
        <div class="mb-3">Average Months Per Patient</div>
        <aux-input [type]="'number'" />
      </div>
      <div>
        <div class="mb-3">Total Enrolled + Dropped</div>
        <aux-input [type]="'number'" />
      </div>
    </div>

    <div class="flex space-x-4 my-5 text-xs">
      <div class="flex items-center space-x-2">
        <aux-radio-button name="radioGroup" tabIndex="1" [checked]="true" />
        <span>Apply monthly Δ to following month</span>
      </div>
      <div class="flex items-center space-x-2">
        <aux-radio-button name="radioGroup" tabIndex="2" />
        <span>Spread monthly Δ over enrollment period</span>
      </div>
      <div class="flex items-center space-x-2">
        <aux-radio-button name="radioGroup" tabIndex="3" />
        <span>Modify enrollment period (+/- 1 month)</span>
      </div>
    </div>

    <div class="flex space-x-4 items-center text-xs mb-5">
      <button type="button" class="btn btn--blue">Save Edits</button>
      <button class="aux-link focus:outline-none" type="button">Cancel</button>
    </div>
  </div>

  <div
    *ngVar="patientDriversEdcFlag | async as edcFlag"
    class="grid grid-cols-3 space-x-5 mt-5"
    [ngClass]="{ 'flex flex-col mb-5': (patientDriversEdcFlag | async) === true }"
  >
    <div
      class="col-span-2 h-full flex flex-col justify-between"
      [ngClass]="{
        'col-span-2': edcFlag === true,
        'col-span-3': edcFlag === false
      }"
    >
      <div *ngIf="topCards$ | async" class="flex-none mb-5">
        <aux-ag-patient-driver-upper-cards [topCards]="topCards$" />
      </div>
      <div class="font-bold text-xl text-aux-black mb-4 mt-[5px]">Estimated Enrollment Curve</div>
      <div
        class="flex flex-col"
        [ngClass]="{
          'grow items-center border rounded-lg': edcFlag === true
        }"
      >
        <div
          class="flex w-full justify-center items-center"
          [ngClass]="{
            'h-full': edcFlag === true
          }"
        >
          <div
            *ngIf="multiChart$ | async as multiChart"
            class="w-full flex-shrink-0"
            [ngClass]="{ 'px-2': edcFlag === true }"
          >
            <aux-canvas-chart [chartOptions]="$any(multiChart)" />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(comparisonCards$ | async) && edcFlag" class="col-span-1">
      <aux-ag-patient-driver-comparison-panel [comparisonCards$]="comparisonCards$" />
    </div>
  </div>

  <div class="mb-5 mt-[25px] flex items-center space-x-4 justify-between">
    <div class="text-lg font-bold text-black">Enrollment</div>
    <div *ngIf="(this.gridData$ | async)?.length !== 0" class="flex justify-end">
      <aux-button
        *ngIf="
          (editMode$ | async) === false &&
          (isBlended$ | async) === false &&
          userHasModifyPermissions
        "
        label="Edit"
        variant="secondary"
        [disabled]="(isPatientsFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
        [auxTooltip]="
          (isPatientsFinalized$ | async) ? messagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE : ''
        "
        icon="Pencil"
        [onClick]="this.onEditClick.bind(this)"
      />

      <button
        *ngIf="(editMode$ | async) === true && (isBlended$ | async) === false"
        type="button"
        class="ml-1 btn btn--secondary"
        (click)="cancelEditMode()"
      >
        <aux-icon name="X" />
        <span class="ml-0.5">Cancel</span>
      </button>

      <button
        *ngIf="(editMode$ | async) === true && (isEqual$ | async) === false"
        class="ml-1 btn btn--success"
        [auxTooltip]="
          (isEqual$ | async) === false
            ? 'This patient curve is unable to be saved until the following issue is resolved: Patients Enrolled must be equal to the sum of Patients Complete and Patients Discontinued.'
            : ''
        "
        [disabled]="(isEqual$ | async) === false"
      >
        <aux-icon
          name="Check"
          *ngIf="(btnLoading$ | async) === false && (isBlended$ | async) === false"
        />
        <span class="ml-2">Save</span>
      </button>
      <button
        *ngIf="(editMode$ | async) === true && (isEqual$ | async) === true"
        class="ml-1 btn btn--success"
        [disabled]="
          (isEqual$ | async) === false ||
          (editingGridCell$ | async) === true ||
          (isNetPatientNegative$ | async) === true
        "
        (click)="saveEditMode()"
      >
        <span *ngIf="(btnLoading$ | async) === true" class="spinner w-5 h-5"></span>
        <aux-icon
          name="Check"
          *ngIf="(btnLoading$ | async) === false && (isBlended$ | async) === false"
        />
        <span class="ml-2">Save</span>
      </button>
      <aux-button
        variant="secondary"
        label="Export"
        classList="ml-1"
        icon="FileExport"
        [onClick]="onExportForPatientDriver.bind(this)"
        [spinnerSize]="7"
      />
    </div>
  </div>

  <ag-grid-angular
    class="ag-theme-aux tabular-nums patient-table w-full"
    id="patient-table"
    domLayout="autoHeight"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [enableFillHandle]="(editMode$ | async) === true"
    (gridReady)="onGridReady($event)"
    (cellValueChanged)="cellValueChanged()"
    (cellEditingStopped)="cellEditingStopped()"
    (firstDataRendered)="onDataRendered($event)"
    (cellEditingStarted)="rowPinnedCheck($event)"
    (viewportChanged)="onWindowScroll()"
    [tabToNextCell]="tabToNextCell"
  />
</ng-container>

<ng-template #loading>
  <!-- TODO create a skeleton loading for invoice -->
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-template>
