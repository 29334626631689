import { Component, ChangeDetectionStrategy, forwardRef, ChangeDetectorRef } from '@angular/core';
import { BaseFormControlComponent } from '@components/form-inputs/base-form-control';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@services/utils';

@Component({
  selector: 'aux-datepicker',
  templateUrl: './datepicker.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent extends BaseFormControlComponent<string | null> {
  MONTH_NAMES = Utils.MONTH_NAMES;

  DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  datepickerValue = '';

  selectedDate = '';

  month!: number;

  year!: number;

  no_of_days: number[] = [];

  blankDays: number[] = [];

  showDatepicker = false;

  constructor(public cdr: ChangeDetectorRef) {
    super();
    this.initDate();
    this.getNoOfDays();
  }

  initDate(newDate?: string) {
    let today;
    if (newDate) {
      this.selectedDate = newDate;
    }
    if (this.selectedDate) {
      today = new Date(Date.parse(this.selectedDate));
    } else {
      today = new Date();
    }

    this.month = today.getMonth();
    this.year = today.getFullYear();

    this.datepickerValue = this.formatDateForDisplay(today);
    this.fc.setValue(this.datepickerValue);

    this.getNoOfDays();
  }

  formatDateForDisplay(date: Date) {
    const formattedDate = `0${date.getDate()}`.slice(-2);
    const formattedMonthInNumber = `0${date.getMonth() + 1}`.slice(-2);
    const formattedYear = date.getFullYear();

    return `${formattedYear}-${formattedMonthInNumber}-${formattedDate}`;
  }

  isSelectedDate(date: number) {
    const d = new Date(this.year, this.month, date);
    return this.datepickerValue === this.formatDateForDisplay(d);
  }

  isToday(date: number) {
    const today = new Date();
    const d = new Date(this.year, this.month, date);
    return today.toDateString() === d.toDateString();
  }

  getDateValue(date: number) {
    const selectedDate = new Date(this.year, this.month, date);
    this.datepickerValue = this.formatDateForDisplay(selectedDate);

    this.fc.setValue(this.datepickerValue);
    this.onChange(this.datepickerValue);

    this.showDatepicker = false;
  }

  getNoOfDays() {
    const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();
    // find where to start calendar day of week
    const dayOfWeek = new Date(this.year, this.month).getDay();
    const blankDaysArray = [];
    for (let i = 1; i <= dayOfWeek; i++) {
      blankDaysArray.push(i);
    }
    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    this.blankDays = blankDaysArray;
    this.no_of_days = daysArray;
  }

  prevMonth() {
    if (this.month === 0) {
      // eslint-disable-next-line no-plusplus
      this.year--;
      this.month = 12;
    }
    // eslint-disable-next-line no-plusplus
    this.month--;
    this.getNoOfDays();
  }

  nextMonth() {
    if (this.month === 11) {
      this.month = 0;
      // eslint-disable-next-line no-plusplus
      this.year++;
    } else {
      // eslint-disable-next-line no-plusplus
      this.month++;
    }
    this.getNoOfDays();
  }

  closePopup() {
    this.showDatepicker = false;
    this.cdr.detectChanges();
  }

  writeValue(str: string | unknown) {
    if (str === null) {
      this.fc.reset();
    }

    if (typeof str === 'string') {
      this.initDate(str);
    }
  }
}
