import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SiteGroupModel } from '../../models/site-group.model';

export type SiteGroupsState = EntityState<SiteGroupModel>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site-groups' })
export class SiteGroupsStore extends EntityStore<SiteGroupsState> {
  constructor() {
    super({});
  }
}
