<div class="bg-white w-[500px]">
  <div *ngIf="ref.data?.secondaryHeader" class="font-bold text-aux-black text-3xl mb-4">
    {{ ref.data?.secondaryHeader }}
  </div>

  <div
    *ngIf="ref.data?.message"
    class="text-xl text-aux-black mb-4"
    [ngClass]="{
      'mb-4': ref.data?.textarea,
      'mb-6': !ref.data?.textarea
    }"
    [innerHTML]="ref.data?.message"
  ></div>

  <div *ngIf="ref.data?.textarea as textareaConfig">
    <aux-input
      [label]="textareaConfig.label"
      [textArea]="true"
      [rows]="2"
      class="text-xs mb-4"
      [validators]="textareaConfig.required ? 'required' : ''"
      [formControl]="textarea"
    />
  </div>

  <div class="flex justify-between">
    <button type="button" class="btn--secondary text-xl p-3 pl-5 pr-5" (click)="close(false)">
      {{ ref.data?.cancelBtnText }}
    </button>

    <button
      type="button"
      class="btn--primary text-xl p-3 pl-5 pr-5"
      [attr.disabled]="ref.data?.okBtnDisabled"
      [attr.data-id]="ref.data?.okBtnDataId"
      (click)="close(true)"
    >
      {{ ref.data?.okBtnText }}
    </button>
  </div>
</div>
