import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'aux-card',
  templateUrl: 'card.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class CardComponent {
  @Input() topText?: string;

  @Input() middleText?: string;

  @Input() className?: string;

  @Input() bottomTemplate?: TemplateRef<unknown>;
}
