<div class="bubble-box bubble-extension">
  <div
    class="max-w-sm w-full bg-white shadow-md rounded-md pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
    @enterLeave
  >
    <div class="p-3 text-gray-400 bg-aux-green-dark">
      <div class="flex items-start text-white">
        <div class="ml-0.5 pt-0.5 w-max">
          <p class="text-sm font-bold">Success!</p>
        </div>
        <div class="ml-1 pt-0.5 w-max">
          <p class="text-sm font-medium">{{ ref.data?.message }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
