import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@models/auth/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export type RoleType = 'ROLE_ADMIN' | 'ROLE_USER';

export type PermissionType =
  | 'PERMISSION_FORECAST_LOGIC'
  | 'PERMISSION_ACCRUAL_MONTHLY'
  | 'PERMISSION_APPROVE_INVOICE'
  | 'PERMISSION_CIS_LOGADD'
  | 'PERMISSION_CIS_LOGACCEPT'
  | 'PERMISSION_APPROVE_CHANGE_ORDER'
  | 'PERMISSION_APPROVE_IN_MONTH'
  | 'PERMISSION_EDIT_BUDGET'
  | 'PERMISSION_DELETE_INVOICE'
  | 'PERMISSION_RISK_ALERTS'
  | 'PERMISSION_MODIFY_EXPENSE_DEFAULTS'
  | 'PERMISSION_MODIFY_FORECAST_METHODOLOGY'
  | 'PERMISSION_MODIFY_PATIENT_CURVE'
  | 'PERMISSION_MODIFY_SITE_CURVE'
  | 'PERMISSION_MODIFY_TRIAL_TIMELINE'
  | 'PERMISSION_MODIFY_OPEN_MONTH_ADJUSTMENTS'
  | 'PERMISSION_EDIT_INVOICE'
  | 'PERMISSION_TEMPLATE_UPLOADS'
  | 'PERMISSION_UPDATE_USERS'
  | 'PERMISSION_CHECKLIST_CONTRACTS'
  | 'PERMISSION_CHECKLIST_DISCOUNTS'
  | 'PERMISSION_CHECKLIST_FORECAST_METHODOLOGY'
  | 'PERMISSION_CHECKLIST_INVOICES'
  | 'PERMISSION_CHECKLIST_PATIENT_DATA'
  | 'PERMISSION_CHECKLIST_PATIENT_SITE_CURVES'
  | 'PERMISSION_CHECKLIST_TRIAL_TIMELINE'
  | 'PERMISSION_CHECKLIST_VENDOR_ESTIMATES'
  | 'PERMISSION_CHECKLIST_VENDOR_EXPENSES'
  | 'PERMISSION_UPDATE_USER_PERMISSIONS'
  | 'PERMISSION_CHECKLIST_ADMIN'
  | 'PERMISSION_UPDATE_SITES'
  | 'PERMISSION_UPDATE_SITE_COSTS'
  | 'PERMISSION_UPDATE_VENDORS'
  | 'PERMISSION_UPLOAD_VENDOR_ESTIMATE'
  | 'PERMISSION_UPLOAD_BUDGET'
  | 'PERMISSION_UPDATE_PROTOCOL_ENTRY'
  | 'PERMISSION_UPLOAD_SITE_DRIVER'
  | 'PERMISSION_UPLOAD_PATIENT_DRIVER'
  | 'PERMISSION_UPDATE_PURCHASE_ORDERS';

export interface AllowedRolesPermissions {
  sysAdminsOnly?: boolean;
  roles?: Array<RoleType>;
  permissions?: Array<PermissionType>;
}

@UntilDestroy()
@Directive({
  selector: '[auxAuthorize]',
})
export class AuthorizeDirective {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private ref: ChangeDetectorRef
  ) {}

  private adjustView(visible: boolean) {
    if (visible) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }

    this.ref.detectChanges();
  }

  @Input() set auxAuthorize(allowedRolesPermissions: AllowedRolesPermissions) {
    this.authService
      .isAuthorized$(allowedRolesPermissions)
      .pipe(untilDestroyed(this))
      .subscribe((authorized) => this.adjustView(authorized));
  }
}
