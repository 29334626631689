<ng-container *ngVar="form.get('averages') as formGroup">
  <div class="bg-white p-4 border border-aux-gray-dark text-sm">
    <div *ngIf="formGroup.value as averagesValues" class="grid grid-cols-2">
      <ng-container [ngTemplateOutlet]="siteDetails" />
    </div>
  </div>

  <div class="bg-white p-4 border border-aux-gray-dark text-sm mt-5" [formGroup]="formGroup">
    <div *ngIf="formGroup.getRawValue() as averagesValues" class="grid grid-cols-2 gap-y-3">
      <p>Contracted Sites:</p>
      <p class="text-right">{{ getSiteContract(averagesValues.contractedSites) }}</p>

      <p>Total Forecasted Patients:</p>
      <p class="text-right">{{ averagesValues.totalForecastedPatients }}</p>

      <p>Avg Patient Cost:</p>
      <p class="text-right">{{ averagesValues.averagePatientCost | money }}</p>

      <div class="border-b h-1 border-aux-gray-dark col-span-2"></div>

      <p>Forecasted Visit Costs:</p>
      <p class="text-right">{{ averagesValues.forecastedVisitCosts | money }}</p>

      <p>Invoiceables %</p>
      <p class="text-right">{{ averagesValues.invoiceables / 100 | percent }}</p>

      <p>Total Invoiceables</p>
      <p class="text-right">{{ averagesValues.forecastedInvoiceables | money }}</p>

      <div class="border-b h-1 border-aux-gray-dark col-span-2"></div>

      <p>Total Investigator Cost</p>
      <p class="text-right">{{ averagesValues.totalInvestigatorCosts | money }}</p>

      <p>Spend to Date:</p>
      <p class="text-right">{{ averagesValues.spendToDateAmount | money }}</p>

      <div class="border-b h-1 border-aux-gray-dark col-span-2"></div>

      <p class="font-bold">Remaining</p>
      <p class="font-bold text-right">{{ averagesValues.remainingAmount | money }}</p>
    </div>
  </div>
</ng-container>

<ng-template #siteDetails>
  <div
    *ngIf="form.get('averages')!.value as averagesValues"
    [formGroup]="$any(form.get('averages'))"
    [ngClass]="{ 'averages-form': (this.editMode$ | async) === true }"
  >
    <div class="grid grid-cols-2 gap-y-4 items-center">
      <p>Contracted Sites:</p>
      <div class="flex space-x-2">
        <div *ngFor="let average of averagesValues$ | async" class="flex items-center">
          <input
            type="radio"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            formControlName="contractedSites"
            [value]="average.last_updated"
          />
          <div class="flex flex-col ml-1 mr-4">
            <span>{{ average.site_count }}</span>
            <span class="italic">{{ average.last_updated }}</span>
          </div>
        </div>
      </div>

      <div class="self-start">
        <p>Total Forecasted Patients:</p>
        <p class="italic">
          From Patients Curve: {{ (formFieldLabels$ | async)!.totalForecastedPatients }}
        </p>
      </div>
      <aux-input
        formControlName="totalForecastedPatients"
        validators="required|greaterThenZero"
        [type]="'number'"
      />

      <div>
        <p>Average Patient Cost Per Site:</p>
        <p class="italic">
          Current: {{ (formFieldLabels$ | async)!.averagePatientCostPerSite | money }}
        </p>
      </div>
      <aux-input-mask formControlName="averagePatientCost" maskType="money" />

      <div class="border-b h-1 border-aux-gray-dark col-span-2"></div>

      <p>Forecasted Visit Costs:</p>
      <p>{{ averagesValues.forecastedVisitCosts | money }}</p>

      <p>Invoiceables %</p>
      <aux-input-mask formControlName="invoiceables" placeholder="10%" maskType="percentage" />

      <p>Forecasted Invoiceables</p>
      <p>{{ averagesValues.forecastedInvoiceables | money }}</p>

      <p class="ml-4">Patient Invoiceables</p>
      <aux-input-mask formControlName="otherInvoiceablesPerc" maskType="limitedPercentage" />

      <p class="ml-4">Site Invoiceables</p>
      <aux-input-mask formControlName="overheadInvoiceablesPerc" maskType="limitedPercentage" />

      <div class="border-b h-1 border-aux-gray-dark col-span-2"></div>

      <p class="font-bold">Total Investigator Cost</p>
      <p class="font-bold">{{ averagesValues.totalInvestigatorCosts | money }}</p>

      <p>Spend to Date:</p>
      <p>{{ averagesValues.spendToDateAmount | money }}</p>
    </div>
  </div>
</ng-template>
