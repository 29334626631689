import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ComponentsModule } from '@components/components.module';
import { EntityFormGroupRowComponent } from './entity-form-group-row/entity-form-group-row.component';
import { ManageBalanceSheetAccountsModalService } from './manage-balance-sheet-accounts-modal.service';
import {
  EntitySettings,
  EntitySettingsFormGroup,
  EntitySettingsFormGroupRow,
  EntitySettingsResponseRow,
} from '@models/organization/entity-settings/entity-settings.model';
import { OverlayService } from '@services/overlay.service';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { distinctUntilChanged, pairwise, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual } from 'lodash-es';
import { BudgetInfo, updateEntitySettingsMutation } from '@services/gql.service';
import { BudgetQuery } from '../../../../budget-page/tabs/budget-enhanced/state/budget.query';
import { LaunchDarklyService } from '@services/launch-darkly.service';

interface EntitySettingsRow {
  account: string;
  department: string;
  program: string;
  project: string;
}

@UntilDestroy()
@Component({
  selector: 'aux-manage-balance-sheet-accounts-modal',
  templateUrl: './manage-balance-sheet-accounts-modal.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgSelectModule,
    ReactiveFormsModule,
    AsyncPipe,
    NgForOf,
    ComponentsModule,
    EntityFormGroupRowComponent,
    FormsModule,
    NgIf,
  ],
  providers: [ManageBalanceSheetAccountsModalService],
})
export class ManageBalanceSheetAccountsModalComponent implements OnInit {
  readonly vendorControl = new FormControl('');

  readonly form: FormGroup<EntitySettingsFormGroup> = this.formBuilder.group({
    accruedLiabilities: this.buildFormGroup(),
    prepaidExpenses: this.buildFormGroup(),
    accountsPayable: this.buildFormGroup(),
  });

  vendors$ = this.budgetQuery.select('budget_info');

  vendors: BudgetInfo[] = [];

  isApplyToAllVendors = false;

  isApManualClearingVisible$ = this.launchDarklyService.select$(
    (flags) => flags.journal_entry_ap_manual_clearing
  );

  isApManualClearingVisible = true;

  private formHasChanges = false;

  constructor(
    private formBuilder: FormBuilder,
    private manageBalanceSheetAccountsModalService: ManageBalanceSheetAccountsModalService,
    private overlayService: OverlayService,
    private budgetQuery: BudgetQuery,
    private launchDarklyService: LaunchDarklyService,
    public ref: CustomOverlayRef
  ) {
    this.vendorControlSubscribe();
    this.allVendorsSubscribe();
    this.formValueChangesSubscribe();
    this.ref.canDeactivate = this.canDeactivate;
  }

  ngOnInit(): void {
    this.isApManualClearingVisible$.pipe(untilDestroyed(this)).subscribe((value) => {
      this.isApManualClearingVisible = value;
    });
  }

  async save(): Promise<void> {
    if (this.vendorControl.value) {
      if (this.isApplyToAllVendors) {
        if (await this.canApplyToAllVendors()) {
          const vendorIds = this.vendors.map((vendor) => vendor.vendor_id);
          this.saveForm(vendorIds as string[]);
          this.formHasChanges = false;
        }
      } else {
        this.saveForm([this.vendorControl.value]);
        this.formHasChanges = false;
      }
    } else {
      this.overlayService.error('Please select a vendor');
    }
  }

  private saveForm(vendorIds: string[]): void {
    if (!this.isApManualClearingVisible) {
      this.form.controls.accountsPayable.reset();
    }

    this.manageBalanceSheetAccountsModalService
      .save(vendorIds, this.form)
      .subscribe((result: GraphqlResponse<updateEntitySettingsMutation[]>) => {
        if (result.success) {
          this.overlayService.success('Changes successfully saved!');
          this.ref.close(true);
        } else {
          this.overlayService.error(result.errors);
        }
      });
  }

  private canDeactivate = async () => {
    if (this.formHasChanges) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await result.afterClosed$.toPromise();

      return !!event?.data;
    }

    return true;
  };

  private canApplyToAllVendors = async () => {
    const result = this.overlayService.openConfirmDialog({
      header: '',
      secondaryHeader: 'Apply to All Vendors',
      message:
        'Apply to All Vendors will override all Account, Department, Program, and Project values previously entered for Balance Sheet Accounts. <div class="mt-4">Are you sure you want to proceed?</div>',
      okBtnText: 'OK',
    });
    const event = await result.afterClosed$.toPromise();

    return !!event?.data?.result;
  };

  private buildFormGroup(): FormGroup<EntitySettingsFormGroupRow> {
    return this.formBuilder.group({
      account: '',
      department: '',
      program: '',
      project: '',
    });
  }

  private vendorControlSubscribe(): void {
    this.vendorControl.valueChanges
      .pipe(startWith(null), pairwise(), untilDestroyed(this))
      .subscribe(async ([prev, next]: [string | null, string | null]) => {
        if (await this.canDeactivate()) {
          if (next) {
            this.manageBalanceSheetAccountsModalService
              .getListEntitySettings(next)
              .subscribe((result) => {
                if (result.data && result.data[0].settings) {
                  const settings = JSON.parse(result.data[0].settings);
                  this.updateForm(settings);
                } else {
                  this.updateForm();
                }
              });
          }
        } else {
          this.vendorControl.setValue(prev, { emitEvent: false });
        }
      });
  }

  private combineSettingsRowFromResponse(
    settingsRow?: EntitySettingsResponseRow
  ): EntitySettingsRow {
    return {
      account: settingsRow?.ACCOUNT || '',
      department: settingsRow?.DEPARTMENT || '',
      program: settingsRow?.PROGRAM || '',
      project: settingsRow?.PROJECT || '',
    };
  }

  private allVendorsSubscribe(): void {
    this.vendors$.pipe(untilDestroyed(this)).subscribe((vendors) => {
      if (vendors.length) {
        this.vendors = vendors;
        this.vendorControl.setValue(vendors[0].vendor_id || '');
      } else {
        this.vendors = [];
        this.vendorControl.setValue('');
      }
    });
  }

  private formValueChangesSubscribe(): void {
    this.form.valueChanges
      .pipe(distinctUntilChanged(isEqual), untilDestroyed(this))
      .subscribe(() => (this.formHasChanges = true));
  }

  private updateForm(settings?: EntitySettings): void {
    if (settings) {
      this.form.patchValue({
        accruedLiabilities: this.combineSettingsRowFromResponse(settings.ACCRUED_LIABILITIES),
        prepaidExpenses: this.combineSettingsRowFromResponse(settings.PREPAID_EXPENSES),
        accountsPayable: this.combineSettingsRowFromResponse(settings.ACCOUNTS_PAYABLE),
      });
    } else {
      this.form.patchValue({
        accruedLiabilities: this.combineSettingsRowFromResponse(),
        prepaidExpenses: this.combineSettingsRowFromResponse(),
        accountsPayable: this.combineSettingsRowFromResponse(),
      });
    }
    this.formHasChanges = false;
  }
}
