<div class="my-4">
  <div class="my-4 flex flex-row-reverse">
    <aux-button
      variant="secondary"
      label="Export"
      icon="FileExport"
      (click)="getDynamicExcelParams()"
    />
  </div>

  <ag-grid-angular
    class="ag-theme-aux tabular-nums"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [suppressDragLeaveHidesColumns]="false"
    [domLayout]="'autoHeight'"
    (gridReady)="onGridReady($event)"
    (firstDataRendered)="stickyElementService.configure()"
    (viewportChanged)="onWindowScroll()"
  />
</div>
