import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';
import { map } from 'rxjs/operators';
import { AppService } from '@services/app.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { ROUTING_PATH } from '../../app-routing-path.const';

@Component({
  selector: 'aux-vendor-payments-page',
  template: `
    <div>
      <div *ngIf="showTabs$ | async" class="mb-8">
        <aux-tab-group [tabs]="tabs" />
      </div>

      <router-outlet />
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorPaymentsPageComponent {
  constructor(
    private appService: AppService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  tabs: TabGroupConfig[] = [
    { label: 'Invoices', route: ROUTING_PATH.VENDOR_PAYMENTS.INVOICES },
    { label: 'Purchase Orders', route: ROUTING_PATH.VENDOR_PAYMENTS.PURCHASE_ORDERS },
    { label: 'Vendors', route: ROUTING_PATH.VENDOR_PAYMENTS.VENDORS },
    {
      label: 'Payment Milestones',
      route: ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_MILESTONES,
    },
    {
      label: 'Payment Schedule',
      show: this.launchDarklyService.select$((flags) => flags.tab_budget_payment_schedule),
      route: ROUTING_PATH.VENDOR_PAYMENTS.PAYMENT_SCHEDULE,
    },
  ];

  showTabs$ = this.appService.paramMap$.pipe(map((p) => !p?.has('id')));
}
