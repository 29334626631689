import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OverrideSettingsStore, OverrideSettingsState } from './override-settings.store';

@Injectable({ providedIn: 'root' })
export class OverrideSettingsQuery extends QueryEntity<OverrideSettingsState> {
  constructor(protected store: OverrideSettingsStore) {
    super(store);
  }
}
