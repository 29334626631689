import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'aux-typography-type-scale',
  templateUrl: './typography-type-scale.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypographyTypeScaleComponent {
  @Input() title = '';

  @Input() classNames = '';

  @Input() description = '';
}
