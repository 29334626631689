import { Component, ViewChild } from '@angular/core';
import { ICellRenderer, ICellRendererParams } from '@ag-grid-community/core';
import {
  AgCellWrapperComponent,
  ICellWrapperParams,
} from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import {
  ForecastTableGridParentComponentContext,
  ForecastTableGridDataInterface,
} from '../../models/forecast-table-grid.model';
import { ForecastTableGridMethodPatientComponent } from '../forecast-table-grid-method-patient/forecast-table-grid-method-patient.component';
import { ForecastTableGridMethodSiteComponent } from '../forecast-table-grid-method-site/forecast-table-grid-method-site.component';
import { ForecastTableGridMethodTimeComponent } from '../forecast-table-grid-method-time/forecast-table-grid-method-time.component';
import { ForecastTableGridComponent } from '../../forecast-table-grid.component';

@Component({
  selector: 'aux-forecast-table-grid-method',
  templateUrl: './forecast-table-grid-method.component.html',
})
export class ForecastTableGridMethodComponent
  extends AgCellWrapperComponent
  implements ICellRenderer
{
  @ViewChild(ForecastTableGridMethodSiteComponent)
  siteComponent!: ForecastTableGridMethodSiteComponent;

  @ViewChild(ForecastTableGridMethodPatientComponent)
  patientComponent!: ForecastTableGridMethodPatientComponent;

  @ViewChild(ForecastTableGridMethodTimeComponent)
  timeComponent!: ForecastTableGridMethodTimeComponent;

  params!: ICellRendererParams;

  context!: ForecastTableGridParentComponentContext;

  componentParent!: ForecastTableGridComponent;

  data!: ForecastTableGridDataInterface;

  disableMethod = false;

  value!: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.context = this.params.context;
    this.componentParent = this.context.componentParent;

    if (!this.componentParent.userHasModifyPermissions) {
      this.disableMethod = true;
    }

    this.data = this.params.data;
    if (this.data) {
      this.data.driverSettingId = this.params.data.driverSettingId || undefined;
      this.data.method = this.params.data.method || undefined;
    }
    this.value = this.params.value;
    this.autoTestAttribute = this.getAutoTestAttribute(params);
  }

  refresh(): boolean {
    return false;
  }

  getAutoTestAttribute(params: ICellWrapperParams): string {
    const isTotalCell = params.node.rowPinned === 'bottom';

    const field = params.colDef?.field || '';
    const index = params.rowIndex;

    const fieldName = params.customLocator || field;

    return isTotalCell ? `${fieldName}_total` : `${fieldName}_${index}`;
  }
}
