import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  firstValueFrom,
  fromEvent,
  Subscription,
  timer,
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith, switchMap, throttleTime } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { AuthService } from '@models/auth/auth.service';
import { TimeoutDialogComponent } from '@components/dialogs/timeout-dialog/timeout-dialog.component';
import { isDev } from '@services/utils';
import { Router } from '@angular/router';
import { MessagesConstants } from '@constants/messages.constants';
import { Flags, LaunchDarklyService } from '@services/launch-darkly.service';
import { ROUTING_PATH } from '../app-routing-path.const';
import { LocalStorageKey } from '@shared/constants';
import { AppService } from '@services/app.service';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class IdleService {
  userActivity!: number;

  disableEvents = new BehaviorSubject(true);

  defaultMs = 0;

  devLogSubscription: Subscription | undefined;

  ref?: CustomOverlayRef<'logout' | undefined>;

  constructor(
    private overlayService: OverlayService,
    private authService: AuthService,
    private router: Router,
    private launchDarklyService: LaunchDarklyService,
    private appService: AppService
  ) {
    this.launchDarklyService
      .select$((flags) => flags.client_preference_session_timeout)
      .pipe(untilDestroyed(this))
      .subscribe((n) => {
        this.defaultMs = n * 1000;
      });

    this.appService.focusEvent$.pipe(untilDestroyed(this)).subscribe(async () => {
      await this.checkLastTime();
    });

    this.disableEvents
      .pipe(
        switchMap((bool) => {
          if (bool) {
            return EMPTY;
          }

          return combineLatest([
            fromEvent(window, 'mousemove').pipe(startWith(null)),
            fromEvent(document, 'keydown').pipe(startWith(null)),
          ]);
        }),
        throttleTime(200),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.refreshTimer();
      });

    if (isDev) {
      // this is only for the qa team to test the timeout functionality
      // to use this open the console in qa or dev and type
      // changeSessionExpirationSecondTo(10)
      // this will change the default 30-minute timeout to 10 seconds
      window.changeSessionExpirationSecondTo = (n: unknown) => {
        this.defaultMs = typeof n === 'number' ? n * 1000 : this.defaultMs;
        console.log('refreshed remaining seconds: ', this.defaultMs / 1000);
        this.refreshTimer();
      };

      window.toggleFeatureFlag = (featureFlagName: keyof Flags, customValue?: unknown) => {
        const flags = this.launchDarklyService.flags$.getValue();

        if (!Object.keys(flags).includes(featureFlagName)) {
          console.warn('Incorrect feature flag name');
          return;
        }
        let updatedFlag;
        if (customValue !== undefined) {
          updatedFlag = { [featureFlagName]: customValue };
        } else {
          updatedFlag = { [featureFlagName]: !flags[featureFlagName] };
        }

        this.launchDarklyService.flags$.next({
          ...flags,
          ...updatedFlag,
        });
      };
      window.logSecondsUntilSessionExpires = false;
    }
  }

  async checkLastTime() {
    const date = localStorage.getItem(LocalStorageKey.LAST_ACTIVE_DATE);

    const logout = async () => {
      clearTimeout(this.userActivity);
      if (this.ref) {
        this.ref.close();
      }
      await this.authService.signOut();
      await this.router.navigate([`/${ROUTING_PATH.LOGIN}`]);
    };

    if (date) {
      const diff = Date.now() - +date;
      // if the last active time is greater than one hour make sure user still logged in
      if (diff > 3_600_000) {
        const resp = await this.authService.getUserSession();
        if (resp == null) {
          await logout();
          return false;
        }
      }

      if (diff > this.defaultMs + 90_000) {
        await logout();
        return false;
      }
    }
    return true;
  }

  async refreshTimer() {
    const inTime = await this.checkLastTime();
    if (!inTime) {
      return;
    }
    localStorage.setItem(LocalStorageKey.LAST_ACTIVE_DATE, `${Date.now()}`);
    clearTimeout(this.userActivity);
    this.userActivity = window.setTimeout(() => {
      if (this.authService.getUserSession() === null) {
        this.authService.signOut();
        this.router.navigate([`/${ROUTING_PATH.LOGIN}`]);
        this.overlayService.error(MessagesConstants.LOGOUT, this.defaultMs);
      } else {
        this.showModal();
      }
    }, this.defaultMs);

    if (isDev) {
      this.devLogSubscription?.unsubscribe();

      if (window.logSecondsUntilSessionExpires) {
        this.devLogSubscription = timer(0, 1000).subscribe((secondsPassed) => {
          const v = (this.defaultMs - secondsPassed * 1000) / 1000;
          console.log(v, 'seconds until session expires');

          if (v <= 0) {
            this.devLogSubscription?.unsubscribe();
          }
        });
      }
    }
  }

  async showModal() {
    this.disableEvents.next(true);

    this.ref = this.openTimeoutDialog();

    const { data } = await firstValueFrom(this.ref.afterClosed$);
    if (data === 'logout') {
      await this.authService.signOut();
      this.router.navigate([`/${ROUTING_PATH.LOGIN}`]);
      this.overlayService.error(MessagesConstants.LOGOUT);
    } else {
      this.disableEvents.next(false);
    }
  }

  openTimeoutDialog() {
    return this.overlayService.open<'logout' | undefined>({
      content: TimeoutDialogComponent,
      data: {
        header: 'Your session is about to expire',
      },
      overlayConfig: {
        scrollStrategy: this.overlayService.overlay.scrollStrategies.block(),
      },
    });
  }
}
