import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GenericTrialInsightsComponent } from '../../classes/trial-insights-component.class';

// REPLACE THIS
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrialInsightsPaymentMilestonesTableService } from './payment-milestones-table.service';
import {
  PMChartType,
  TrialInsightsPaymentMilestoneChartService,
} from './payment-milestones-chart.service';
import { TrialInsightsPaymentMilestoneQueryService } from './payment-milestones-query.service';
import { TrialInsightsPaymentMilestoneStoreService } from './payment-milestones-store.service';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-finance-payment-milestones',
  templateUrl: './trial-insights-finance-payment-milestones.component.html',
  styleUrls: [
    '../../trial-insights.component.scss',
    './trial-insights-finance-payment-milestones.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsFinancePaymentMilestonesComponent extends GenericTrialInsightsComponent<PMChartType> {
  constructor(
    public chartService: TrialInsightsPaymentMilestoneChartService,
    public tableService: TrialInsightsPaymentMilestonesTableService,
    public storeService: TrialInsightsPaymentMilestoneStoreService,
    public queryService: TrialInsightsPaymentMilestoneQueryService,
    public cdr: ChangeDetectorRef
  ) {
    super({
      title: 'Upcoming Payment Milestones (Total)',
      color: '#236262',
      chartService,
      tableService,
      queryService,
      cdr,
    });
  }

  subscribeToData = () => {
    this.storeService.getPaymentMilestones$().pipe(untilDestroyed(this)).subscribe();
    this.queryService.processPaymentMilestones$().pipe(untilDestroyed(this)).subscribe();
  };
}
