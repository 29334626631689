import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OrganizationQuery } from '@models/organization/organization.query';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  BudgetCompareMenuFlatten$,
  BudgetCompareMenuVendorChangeFn,
  BudgetCompareMenuVendorFormControl,
  BudgetCompareMenuVendorLoading,
  defaultVendorChangeFn,
} from '../../budget-compare-menu.models';

@UntilDestroy()
@Component({
  selector: 'aux-budget-compare-menu-vendor',
  templateUrl: './budget-compare-menu-vendor.component.html',
  styleUrls: ['../../budget-compare-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetCompareMenuVendorComponent {
  @Input() vendorFormControl: BudgetCompareMenuVendorFormControl;

  @Input() vendorList: BudgetCompareMenuFlatten$<typeof this.organizationQuery.allVendors$>;

  @Input() vendorLoading: BudgetCompareMenuVendorLoading;

  @Input() vendorChangeFn: BudgetCompareMenuVendorChangeFn;

  constructor(private organizationQuery: OrganizationQuery) {
    this.vendorFormControl = new FormControl<string>('', { nonNullable: true });
    this.vendorList = [];
    this.vendorLoading = false;
    this.vendorChangeFn = defaultVendorChangeFn;
  }
}
