import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TrialInsightsLegendOptions } from '../../models/trial-insights-legend.model';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-legend',
  templateUrl: './trial-insights-legend.component.html',
  styleUrls: ['../../trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsLegendComponent {
  @Input() options: TrialInsightsLegendOptions = {
    compact: false,
    centered: false,
    divider: false,
    color: '',
    data: [],
  };
}
