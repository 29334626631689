import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  templateUrl: './dashboard.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
  alerts = [
    {
      header: 'CIS Log Verification',
      date: '20 min ago',
      html: `<div>2 of 2 users verified <a class="aux-link" href="#">CIS Log Item #5</a> for Vendor #2 in trial AJ-156-7A</div>`,
    },
    {
      header: 'Risk Alert',
      date: '1 day ago',
      html: `<div><a class="aux-link" href="#">Risk Analytics</a> changed in site health for two sites, Columbia Presbyterian and Colorado University</div>`,
    },
    {
      header: 'Accrual Drivers Change',
      date: '2 days ago',
      html: `<div><a class="aux-link" href="#">Drivers</a> were updated on the patients tab for trial AJ-156-7A</div>`,
    },
    {
      header: 'New Change Order Added',
      date: '5 days ago',
      html: `<div><a class="aux-link" href="#">WO Amendment #3</a> was added for vendor #1 in trial AJ-156-7A</div>`,
    },
  ];

  timelineData = [
    {
      header: 'Trial 1, Vendor 1',
      value: 20,
      progressColor: 'blue',
      leftLabel: 'WP: 12%',
      rightLabel: 'Total Budget: $2.6mm',
    },
    {
      header: 'Trial 1, Vendor 2',
      value: 50,
      progressColor: 'blue',
      leftLabel: 'WP: 52%',
      rightLabel: 'Trial Budget: $450,000',
    },
    {
      header: 'Trial 1, Vendor 3',
      value: 5,
      progressColor: 'blue',
      leftLabel: 'WP: 0%',
      rightLabel: 'Trial Budget: $230,000',
    },
  ];

  percentage = 80;
}
