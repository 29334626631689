<ng-container *ngIf="(integrationsQuery.selectLoading() | async) === true">
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-container>

<div *ngIf="(integrationsQuery.selectLoading() | async) === false">
  <div class="text-aux-black text text-xl h-9" style="margin-bottom: 15px">Active Integrations</div>
  <ag-grid-angular
    class="ag-theme-alpine integration-table tabular-nums"
    style="width: 1100px"
    [rowData]="gridData$ | async"
    [gridOptions]="gridOptions"
    [domLayout]="'autoHeight'"
  />
  <div
    class="flex justify-between items-end h-9"
    style="margin-bottom: 15px; margin-top: 50px; width: 1100px"
  >
    <span class="text-aux-black text-xl">Available Integrations</span>
    <span class="text-aux-black"
      >Contact
      <a class="aux-link cursor-pointer" href="mailto: integrations@auxili.us"
        >integrations&#64;auxili.us</a
      >
      to add integrations to Auxilius</span
    >
  </div>
  <div class="flex flex-wrap justify-between" style="width: 1100px">
    <div
      *ngFor="let int of integrationVendors"
      class="grid grid-rows-3 border-2 border-aux-gray-dark mb-2 p-1 items-center"
      style="width: 208px; height: 175px"
    >
      <div class="row-span-2 flex items-center justify-center">
        <img
          style="max-height: 150px; max-width: 150px"
          class="flex justify-center"
          [src]="int.logo"
          [alt]="int.name"
        />
      </div>
      <div class="row-span-1">
        <div class="flex text-aux-black text-xl justify-center">{{ int.name }}</div>
        <div class="flex text-aux-gray-darkest text-base justify-center">{{ int.type }}</div>
      </div>
    </div>
  </div>
</div>
