<div class="flex flex-row justify-between items-center">
  <div class="text-sm mr-1">Period</div>
  <ng-select
    class="w-32"
    bindValue="value"
    [formControl]="periodFormControl"
    [clearable]="false"
    [multiple]="false"
    [searchable]="false"
    [items]="periodList"
  />
</div>
