import { Injectable } from '@angular/core';
import { GqlService } from '@services/gql.service';
import { GenericOperator } from '@services/operator.class';
import { Observable, Subscriber } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import {
  TrialInsightsInvestigatorCostResponseType,
  TrialInsightsResponseGeneric,
  TrialInsightsState,
} from '../../models/trial-insights-store.model';
import { TrialInsightsStore } from '../../store/trial-insights.store';

type state = Partial<TrialInsightsState['investigatorCost']>;
type loadingState = 'initial' | 'completed';
type responseType = TrialInsightsInvestigatorCostResponseType;
type response = TrialInsightsResponseGeneric<responseType[]>;

@Injectable()
export class TrialInsightsClinicalInvestigatorCostStoreService {
  constructor(
    private store: TrialInsightsStore,
    private mainQuery: MainQuery,
    private gqlService: GqlService
  ) {}

  getTotalInvestigatorCosts$(): Observable<unknown> {
    return this.mainQuery.select('trialKey').pipe(
      this.updateLoadingState('initial'),
      switchMap(() => this.gqlService.getTotalInvestigatorCosts$().pipe(take(1))),
      this.processResponse(),
      this.updateLoadingState('completed')
    );
  }

  loadingState(type: loadingState): state {
    const loadingState: state = {};

    if (type === 'initial') {
      loadingState.isLoading = true;
      loadingState.data = null;
    } else {
      loadingState.isLoading = false;
    }

    return loadingState;
  }

  updateLoadingState(type: loadingState) {
    const updates = this.loadingState(type);

    const processFn = (_: unknown, subscriber: Subscriber<unknown>) => {
      this.store.update((state) => {
        return {
          ...state,
          investigatorCost: {
            ...state.investigatorCost,
            ...updates,
          },
        };
      });

      subscriber.next(updates);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }

  processResponse() {
    const processFn = (response: response, subscriber: Subscriber<unknown>) => {
      const { data } = response;

      this.store.update((state) => {
        return {
          ...state,
          investigatorCost: {
            ...state.investigatorCost,
            data,
          },
        };
      });

      subscriber.next(data);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }
}
