import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { BehaviorSubject } from 'rxjs';
import { BudgetCurrencyQuery } from 'src/app/pages/budget-page/tabs/budget-enhanced/state/budget-currency.query';
import { BudgetCurrencyType } from '../../../../budget-page/tabs/budget-enhanced/budget-type';
import { AgSetColumnsVisible } from '@shared/utils';

@Component({
  templateUrl: './ag-header-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgHeaderActionsComponent {
  constructor(private budgetCurrencyQuery: BudgetCurrencyQuery) {}

  params!: ICellRendererParams;

  change$ = new BehaviorSubject(true);

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  onChangeClick() {
    if (this.budgetCurrencyQuery.getValue().currency === BudgetCurrencyType.USD) {
      AgSetColumnsVisible({
        gridApi: this.params.api,
        keys: [
          'expense_amounts.investigator_total.value',
          'expense_amounts.pass_thru_total.value',
          'expense_amounts.services_total.value',
          'expense_amounts.discount_total.value',
          'expense_amounts.investigator_total_trial_currency.value',
          'expense_amounts.pass_thru_total_trial_currency.value',
          'expense_amounts.services_total_trial_currency.value',
          'expense_amounts.discount_total_trial_currency.value',
        ],
        visible: !this.change$.getValue(),
      });
    } else {
      AgSetColumnsVisible({
        gridApi: this.params.api,
        keys: [
          'expense_amounts.investigator_total.value',
          'expense_amounts.pass_thru_total.value',
          'expense_amounts.services_total.value',
          'expense_amounts.discount_total.value',
        ],
        visible: !this.change$.getValue(),
      });
    }
    this.change$.next(!this.change$.getValue());
  }
}
