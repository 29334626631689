<ng-template #scenarioTemp>
  <button class="text-sm pl-3 btn btn--blue" (click)="createScenario()">
    <aux-icon name="Plus" />
    Create New Scenario
  </button>
</ng-template>

<div class="mb-4 pb-2 pt-2 border-b text-sm">Scenarios</div>

<div class="grid grid-cols-4 gap-10 whitespace-nowrap text-xs">
  <div *ngFor="let scenario of scenarios" class="rounded border p-4">
    <div class="text-aux-blue text-sm leading-5 mb-2 font-bold">{{ scenario.header }}</div>
    <div class="flex mt-1 mb-1">{{ scenario.row1 }}</div>
    <div class="flex mt-1 mb-1">{{ scenario.row2 }}</div>
    <div class="flex mt-1 mb-1">{{ scenario.row3 }}</div>

    <div class="flex justify-between mt-4">
      <button class="font-medium underline text-aux-blue">View/Edit</button>

      <div class="flex items-center font-medium">
        <aux-checkbox />
        Compare
      </div>
    </div>
  </div>
</div>

<div class="mb-4 mt-16 pb-2 pt-2 border-b text-sm">Vendor Budgets</div>

<div class="flex items-center space-x-2 mb-4 text-xs">
  <span>Vendor</span>
  <ng-select class="w-32 inline-block" />
</div>

<div class="grid grid-cols-4 gap-5 whitespace-nowrap text-xs">
  <div *ngFor="let budget of budgets" class="rounded border p-4">
    <div class="text-aux-blue text-sm leading-5 mb-2 font-bold">{{ budget.header }}</div>
    <div class="flex mt-1 mb-1">{{ budget.row1 }}</div>
    <div class="flex mt-1 mb-1">{{ budget.row2 }}</div>
    <div class="flex mt-1 mb-1">{{ budget.row3 }}</div>

    <div class="flex justify-between mt-4">
      <button class="font-medium underline text-aux-blue">View/Edit</button>

      <div class="flex items-center font-medium">
        <aux-checkbox />
        Compare
      </div>
    </div>
  </div>
</div>
