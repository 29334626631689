import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  stage: 'soner',
  restApiKey: 'IJh18Bt07p1x7PpWOTYpU7QLSc2PbPzx7tnoKkXa',
  restApiEndpoint: 'https://g6onyhirw5.execute-api.us-east-1.amazonaws.com/soner',
  cognito: {
    identityPoolId: 'us-east-1:4dc64c7e-f798-4117-9868-1ed956fd44c3',
    userPoolId: 'us-east-1_i21iwH7d7',
    userPoolClientId: '4jg4oaqlo3qvl8b2n0mlh4ieof',
    loginWith: {
      oauth: {
        domain: 'auxilius-soner-user-pool.auth.us-east-1.amazoncognito.com',
        scopes: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: ['http://localhost:4200'],
        redirectSignOut: ['http://localhost:4200'],
        responseType: 'code',
      },
    },
  },
  appSyncApiId: 'uc2j6e72ejealkzrp6d2gdada4',
  API: {
    endpoint: 'https://w5hkhnzwkzgztjsyodb5d5taiq.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    defaultAuthMode: 'userPool',
  },
  S3: {
    bucket: 'soner-auxilius-documents',
    region: 'us-east-1',
  },
  launchDarkly: {
    clientId: '62f41b9d67be3b1153482f1a',
    clientName: 'dev',
  },
  analytics: {
    Pendo: {
      accountId: 'soner-local',
    },
  },
};
