import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { Column, ColumnGroup, IHeaderGroupParams } from '@ag-grid-community/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import * as dayjs from 'dayjs';
import { AgHeaderExpandComponent } from 'src/app/pages/budget-page/tabs/budget-enhanced/ag-header-expand.component';

interface AgAdjustmentPrevMonthHeaderColumnParams extends IHeaderGroupParams {
  columnGroup: ColumnGroup;
  selected_month: FormControl;
  localStorageKey: string;
  collapsedByDefault: boolean;
}

@Component({
  template: `
    <div class="flex items-center">
      <div class="flex flex-col justify-center items-center">
        <span>Previous Month</span>
        <span>({{ prevMonth$ | async }})</span>
      </div>
      <aux-icon
        [size]="20"
        [name]="visible ? 'ChevronLeft' : 'ChevronRight'"
        class="ml-1 cursor-pointer flex justify-center items-center"
        (click)="toggleExpand()"
      />
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgAdjustmentPrevMonthHeaderComponent
  extends AgHeaderExpandComponent
  implements IHeaderGroupAngularComp
{
  params!: AgAdjustmentPrevMonthHeaderColumnParams;

  visible = true;

  prevMonth$!: Observable<string>;

  agInit(params: AgAdjustmentPrevMonthHeaderColumnParams): void {
    this.params = params;

    this.visible = !params.collapsedByDefault;

    this.prevMonth$ = this.params.selected_month.valueChanges.pipe(
      startWith(null),
      map(() => {
        return dayjs(this.params.selected_month.value).subtract(1, 'month').format('MMMM YYYY');
      })
    );

    this.initializeExpandCols();
  }

  filterCols(column: Column) {
    return ['prev_month_amount'].indexOf(column.getColId()) === -1;
  }

  refresh(): boolean {
    return false;
  }
}
