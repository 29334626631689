<div *ngIf="title" class="text-xl font-semibold mb-4">
  {{ title }}
</div>

<div class="p-4 rounded-lg bg-aux-gray-light flex">
  <aux-input
    #notesTextArea
    class="flex-grow"
    inputClassName="resize-none border-0 bg-transparent !outline-none !shadow-none w-full !max-w-full !text-lg text-aux-gray-darkest !font-normal"
    [textArea]="true"
    [rows]="1"
    [formControl]="notesControl"
    placeholder="Add a note..."
    [textareaAutoresize]="true"
    [disabled]="!editMode"
  />

  <div class="w-[150px] min-w-[150px] flex justify-end items-start pt-0.5">
    <aux-button
      *ngIf="!editMode; else actions"
      variant="custom"
      icon="Pencil"
      svgAttributes="!mr-0 text-aux-blue"
      classList="py-0.5 mt-[5px]"
      [ngClass]="{
        'opacity-50': !hasEditPermission
      }"
      type="button"
      [auxTooltip]="!hasEditPermission ? disabledTooltipText : ''"
      [disabled]="!hasEditPermission"
      (click)="edit()"
    />

    <ng-template #actions>
      <aux-button class="mr-2" variant="primary" label="Save" (click)="onSave()" />

      <aux-button variant="secondary" classList="text-sm" label="Cancel" (click)="cancel()" />
    </ng-template>
  </div>
</div>
