import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { DepartmentType, PermissionType } from '@services/gql.service';
import { Maybe } from '@services/utils';

export interface AuthState {
  sub: string;
  given_name: string;
  family_name: string;
  email: string;
  is_admin: boolean;
  is_sys_admin: boolean;
  trial_id: string;
  permissions: PermissionType[];
  roles: string[];
  organization_id: Maybe<string>;
  department: Maybe<(DepartmentType | null)[]>;
  title: Maybe<string>;
}

export function createInitialState(): AuthState {
  return {
    sub: '',
    given_name: '',
    family_name: '',
    email: '',
    is_admin: false,
    is_sys_admin: false,
    trial_id: '',
    permissions: [],
    roles: [],
    organization_id: null,
    department: [],
    title: '',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
