import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EventStore, EventStoreState } from './event.store';

import { MainQuery } from './../../layouts/main-layout/state/main.query';
import { Observable, map } from 'rxjs';
import { EventStatus, EventType } from '@services/gql.service';

@Injectable({ providedIn: 'root' })
export class EventQuery extends QueryEntity<EventStoreState> {
  constructor(
    protected store: EventStore,
    private mainQuery: MainQuery
  ) {
    super(store);
  }

  isEventProcessing(eventType: EventType): boolean {
    const trial = this.mainQuery.getValue().trialKey;
    const events = this.store.getValue().entities || {};
    const event = events[`${trial}-${eventType}`];

    return !!event;
  }

  selectProcessingEvent(eventType: EventType): Observable<boolean | null> {
    const trial = this.mainQuery.getValue().trialKey;

    return this.selectEntity(`${trial}-${eventType}`).pipe(
      map((event) => {
        if (!event) {
          return null;
        }

        const { event_status } = event;

        return (
          event_status === EventStatus.EVENT_STATUS_IN_PROGRESS ||
          event_status === EventStatus.EVENT_STATUS_PENDING
        );
      })
    );
  }
}
