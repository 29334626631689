import { NgModule } from '@angular/core';
import {
  CardComponent,
  PaginationGridComponent,
  TableSkeletonComponent,
  VendorDropdownComponent,
} from './components';
import { PaginationPanelComponent } from './components/pagination-panel/pagination-panel.component';

const sharedComponents = [
  CardComponent,
  PaginationGridComponent,
  PaginationPanelComponent,
  TableSkeletonComponent,
  VendorDropdownComponent,
] as const;

@NgModule({
  imports: [...sharedComponents],
  exports: [...sharedComponents],
})
export class SharedModule {}
