import { Injectable } from '@angular/core';
import { EntityState, ActiveState, EntityStore, StoreConfig, getEntityType } from '@datorama/akita';
import { ChangeLog, ChangeLogItem } from '@services/gql.service';
import { RequireSome } from '@services/utils';

export interface ChangeLogState
  extends EntityState<
      Omit<RequireSome<Partial<ChangeLog>, 'id'>, 'trial_id'> & {
        change_log_items: ChangeLogItemModel[];
        clis_total: string;
        services_total: string;
        investigator_fees_total: string;
        pass_thru_total: string;
        services_percent: number;
        investigator_fee_percent: number;
        pass_thru_percent: number;
      },
      string
    >,
    ActiveState {}

export type ChangeLogModel = getEntityType<ChangeLogState>;

export type ChangeLogItemModel = Omit<
  RequireSome<Partial<ChangeLogItem>, 'id'>,
  'expense_amounts' | 'organization'
> & {
  expense_amounts: { amount_type: string; amount?: number | null | undefined }[];
  total_amount: number;
  investigator_amount: number;
  passthrough_amount: number;
  service_amount: number;
  organization: {
    id: string;
    name: string;
  };
  file_link?: string;
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'change-log' })
export class ChangeLogStore extends EntityStore<ChangeLogState> {
  constructor() {
    super({});
  }
}
