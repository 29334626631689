import {
  ColDef,
  ColGroupDef,
  Column,
  EditableCallbackParams,
  ICellRendererParams,
  IProvidedColumn,
  ISetFilterParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { Utils } from '@services/utils';
import {
  ColumnsWithEditClassesFunction,
  SelectedVendorColumnEditClassesFunction,
  ColumnsWithEditClassesAndExpandCollapseFunction,
  TableService,
} from '@services/table.service';
import { AgAdjustmentEvidenceBasedHeaderComponent } from './ag-adjustment-evidence-based-header/ag-adjustment-evidence-based-header.component';
import { EvidenceBasedHeaderColumnFunction } from './ag-adjustment-evidence-based-header/ag-adjustment-evidence-based-header.model';
import { TableConstants } from '@constants/table.constants';
import { AgExpandableGroupHeaderComponent } from './ag-expandable-group-header.component';
import { AgAdjustmentVendorEstimateHeaderComponent } from './ag-adjustment-vendor-estimate-header.component';
import { cellSize } from '../../../budget-page/tabs/budget-enhanced/column-defs';
import { BehaviorSubject } from 'rxjs';
import { decimalAdd, decimalDivide, decimalMultiply } from '@shared/utils';

export const spacerColumn = (colId?: string): ColDef | ColGroupDef => ({
  headerClass: 'ag-invisible',
  cellClass: 'ag-invisible',
  colId: colId,
  children: [
    { headerClass: 'ag-invisible', cellClass: 'ag-invisible', colId, width: 5, maxWidth: 5 },
  ],
});

export const blankActivitiesHeaderClass = 'blank-activities-header-class';

export const uomHide$ = new BehaviorSubject(true);

export const getActivitiesColumnDefs: ColumnsWithEditClassesAndExpandCollapseFunction = (
  getClassNames,
  currency,
  showUnitTotals$
) => [
  {
    headerName: '',
    headerClass: ['ag-header-align-center', blankActivitiesHeaderClass],
    children: [
      {
        headerName: 'Activity ID',
        field: 'activity_no',
        headerClass: ['ag-header-align-center'],
        hide: true,
        width: 70,
        minWidth: 50,
      },
      {
        headerName: 'Vendor',
        field: 'vendor_name',
        hide: true,
      },
      {
        headerName: 'Cost Category',
        field: 'cost_category_name',
        rowGroup: true,
        hide: true,
      },
      {
        headerName: 'Category',
        field: 'group0',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'actuals_performed_to_date',
        hide: true,
        aggFunc: 'sum',
      },
      {
        field: 'previous_months_accruals',
        hide: true,
        aggFunc: 'sum',
      },
    ],
  },
  {
    headerName: 'Overall Budget',
    headerGroupComponent: AgExpandableGroupHeaderComponent,
    headerGroupComponentParams: {
      localStorageKey: 'closing_page_overall_budget',
      collapsedByDefault: true,
      expandableCols: ['uom', 'units', 'tma_unit_cost', 'total_amount'],
      filterCols: (column: Column) => {
        return (
          (!column.getColDef().hide &&
            ['actuals_to_date', 'total_remaining'].indexOf(column.getColId()) === -1) ||
          (column.getColId().includes('uom') && !uomHide$.getValue())
        );
      },
    },
    headerClass: 'ag-header-align-center',
    children: [
      {
        field: 'group1',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'group2',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'group3',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'group4',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'activity_type',
        hide: true,
        filter: true,
      },
      {
        headerName: 'Label',
        field: 'display_label',
        headerClass: ['ag-header-align-center'],
        width: 70,
        minWidth: 50,
      },
      {
        headerName: 'Unit of Measure',
        headerClass: 'ag-header-align-center',
        field: 'uom',
        colId: 'uom',
        hide: uomHide$.getValue(),
        width: cellSize.large,
        minWidth: cellSize.large,
        suppressMenu: false,
        filter: true,
        filterParams: {
          showTooltips: true,
        } as ISetFilterParams,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'uom',
        cellClass: ['text-left', 'max-w', 'whitespace-nowrap', 'overflow-hidden', 'text-ellipsis'],
        valueFormatter: (params) => params.value || '',
      },
      {
        headerName: 'Units',
        field: 'units',
        headerClass: ['ag-header-align-center'],
        cellClass: getClassNames(['budget-units', 'ag-cell-align-right']),
        width: 85,
        minWidth: 70,
        valueFormatter: ({ value }: { value: number }) => Utils.decimalFormatter(value),
        aggFunc: showUnitTotals$.getValue() ? 'sum' : '',
      },
      {
        headerName: 'Unit Cost',
        field: 'tma_unit_cost',
        headerClass: ['ag-header-align-center'],
        cellClass: getClassNames([`budgetCost${currency}`, 'ag-cell-align-right']),
        width: 145,
        minWidth: 100,
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
      },
      {
        headerName: 'Current (LRE)',
        field: 'total_amount',
        width: 145,
        minWidth: 100,
        headerClass: ['ag-header-align-center'],
        cellClass: [`budgetCost${currency}`, 'ag-cell-align-right'],
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
        aggFunc: 'sum',
      },
      {
        headerName: 'Actuals to Date ($)',
        field: 'actuals_to_date',
        width: 145,
        minWidth: 100,
        headerClass: ['ag-header-align-center'],
        cellClass: [`budgetCost${currency}`, 'ag-cell-align-right'],
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
        aggFunc: 'sum',
      },
      {
        headerName: 'Remaining ($)',
        field: 'total_remaining',
        width: 145,
        minWidth: 100,
        headerClass: ['ag-header-align-center'],
        cellClass: [`budgetCost${currency}`, 'ag-cell-align-right'],
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
        aggFunc: 'sum',
      },
    ],
  },
];

export const getPreviousMonthColumnDefs: ColumnsWithEditClassesFunction = (
  getClassNames,
  currency,
  showUnitTotals$
) => [
  {
    headerName: 'Actuals (Units)',
    field: 'prev_month_unit',
    headerClass: ['ag-header-align-center'],
    cellClass: getClassNames(['budget-units', 'ag-cell-align-right']),
    width: 85,
    minWidth: 70,
    valueFormatter: ({ value }) => Utils.decimalFormatter(value),
    aggFunc: showUnitTotals$.getValue() ? 'sum' : '',
  },
  {
    headerName: 'Actuals ($)',
    field: 'prev_month_amount',
    headerClass: ['ag-header-align-center'],
    cellClass: getClassNames([`budgetCost${currency}`, 'ag-cell-align-right']),
    width: 160,
    minWidth: 100,
    valueFormatter: (params: ValueFormatterParams) => {
      return Utils.agCurrencyFormatterAccounting(params, currency);
    },
    aggFunc: 'sum',
  },
];

export const getCurrentForecastColumnDefs: ColumnsWithEditClassesFunction = (
  getClassNames,
  currency,
  showUnitTotals$
) => [
  {
    headerName: 'Current Forecast',
    headerClass: ['ag-header-align-center'],
    headerGroupComponent: AgExpandableGroupHeaderComponent,
    headerGroupComponentParams: {
      collapsedByDefault: true,
      filterCols(column: IProvidedColumn, index: number, columns: IProvidedColumn[]) {
        return index !== columns.length - 1;
      },
      localStorageKey: 'closing_page_current_forecast',
      expandableCols: ['current_forecast_percentage', 'current_forecast_unit'],
    },
    children: [
      {
        headerName: '% Complete',
        field: 'current_forecast_percentage',
        headerClass: ['ag-header-align-center'],
        cellClass: getClassNames(['percent', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT]),
        minWidth: 100,
        width: 100,
        valueGetter: (params) => {
          // For Category/Group and Discount rows
          if (!params.data || params.data.cost_category_name === 'Discount') {
            const total_amount = !params.data
              ? params.node?.aggData.total_amount
              : params.data.total_amount;

            const current_forecast_amount = !params.data
              ? params.node?.aggData.current_forecast_amount
              : params.data.current_forecast_amount;

            return (current_forecast_amount / total_amount) * 100;
          }

          return params.data.current_forecast_percentage;
        },
        valueFormatter: ({ value }) =>
          Utils.percentageFormatter(decimalDivide(value, 100), { maximumFractionDigits: 4 }),
      },
      {
        headerName: 'Units',
        field: 'current_forecast_unit',
        headerClass: ['ag-header-align-center'],
        cellClass: getClassNames(['budget-units', 'ag-cell-align-right']),
        width: 85,
        minWidth: 70,
        valueFormatter: ({ value }) => Utils.decimalFormatter(value),
        aggFunc: showUnitTotals$.getValue() ? 'sum' : '',
      },
      {
        headerName: 'Expense ($)',
        field: 'current_forecast_amount',
        headerClass: ['ag-header-align-center'],
        cellClass: getClassNames([`budgetCost${currency}`, 'ag-cell-align-right']),
        width: 150,
        minWidth: 100,
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
        aggFunc: 'sum',
      },
    ],
  },
];
export const getVendorEstimateColumnDefs: SelectedVendorColumnEditClassesFunction = (
  getClassNames,
  getSelectedVendorCurrency,
  editMode$,
  showUnitTotals$,
  hideVendorEstimatePercentLTD$
) => {
  const getEditableCellClasses = TableService.getEditableCellClasses(editMode$) || [];

  const getEditableHeaderClasses = TableService.getEditableHeaderClasses(editMode$) || [];

  return [
    {
      headerName: '% Complete',
      field: 'vendor_estimate_percentage',
      headerClass: ['ag-header-align-center'],
      headerGroupComponent: AgAdjustmentVendorEstimateHeaderComponent,
      cellClass: getClassNames(['percent', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT]),
      minWidth: 100,
      width: 100,
      valueGetter: (params) => {
        // For Category/Group and Discount rows
        if (!params.data || params.data.cost_category_name === 'Discount') {
          const total_amount = !params.data
            ? params.node?.aggData.total_amount
            : params.data.total_amount;

          const vendor_estimate_amount = !params.data
            ? params.node?.aggData.vendor_estimate_amount
            : params.data.vendor_estimate_amount;

          return (vendor_estimate_amount / total_amount) * 100;
        }

        return params.data.vendor_estimate_percentage;
      },
      valueFormatter: ({ value }) =>
        Utils.percentageFormatter(decimalDivide(value, 100), { maximumFractionDigits: 4 }),
    },
    {
      headerName: 'Units',
      field: 'vendor_estimate_unit',
      headerClass: ['ag-header-align-center'],
      cellClass: getClassNames(['budget-units', 'ag-cell-align-right']),
      width: 85,
      minWidth: 70,
      valueFormatter: ({ value }) => Utils.decimalFormatter(value),
      aggFunc: showUnitTotals$.getValue() ? 'sum' : '',
    },
    {
      headerName: 'Expense ($)',
      field: 'vendor_estimate_amount',
      headerClass: getEditableHeaderClasses(['ag-header-align-center']),
      cellClass: getEditableCellClasses(['budget-units', 'ag-cell-align-right']),
      editable: (params: EditableCallbackParams) => TableService.isEditableCell(editMode$)(params),
      cellRenderer: (params: ICellRendererParams) => {
        params.eGridCell.setAttribute('data-pendo-id', 'adjustments-vendor-estimate-expense');
        return params.valueFormatted;
      },
      width: 260,
      minWidth: 220,
      valueFormatter: (value) => {
        const formattedString = Utils.agCurrencyFormatterAccounting(
          value,
          getSelectedVendorCurrency()
        );
        return formattedString;
      },
      aggFunc: 'sum',
    },
    {
      headerName: '% Complete LTD',
      field: 'vendor_estimate_percent_ltd',
      headerClass: getEditableHeaderClasses(['ag-header-align-center']),
      cellClass: getEditableCellClasses(['percent', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT]),
      editable: (params: EditableCallbackParams) => TableService.isEditableCell(editMode$)(params),
      minWidth: 130,
      hide: hideVendorEstimatePercentLTD$.getValue(),
      width: 130,
      cellRenderer: (params: ICellRendererParams) => {
        params.eGridCell.setAttribute('data-pendo-id', 'adjustments-vendor_estimate_percent_ltd');
        return params.valueFormatted;
      },
      valueGetter: (params) => {
        // For Category/Group and Discount rows
        if (!params.data || params.data.cost_category_name === 'Discount') {
          const total_amount = !params.data
            ? params.node?.aggData.total_amount
            : params.data.total_amount;

          const actualsToDate = !params.data
            ? params.node?.aggData?.actuals_performed_to_date
            : params.data?.actuals_performed_to_date;

          const vendor_estimate_amount = !params.data
            ? params.node?.aggData.vendor_estimate_amount
            : params.data.vendor_estimate_amount;

          const previous_months_accruals = !params.data
            ? params.node?.aggData?.previous_months_accruals
            : params.data.previous_months_accruals;

          if (!total_amount) {
            return 0;
          }

          return decimalMultiply(
            decimalDivide(
              decimalAdd(
                decimalAdd(actualsToDate || 0, vendor_estimate_amount),
                previous_months_accruals
              ),
              total_amount
            ),
            100,
            6
          );
        }

        return params.data.vendor_estimate_percent_ltd;
      },
      valueFormatter: ({ value }) =>
        Utils.percentageFormatter(decimalDivide(value, 100), { maximumFractionDigits: 4 }),
    },
  ] as ColDef[];
};

export const getEvidenceBasedColumnDefs: EvidenceBasedHeaderColumnFunction = (
  getClassNames,
  editMode$,
  getSelectedMonthAndVendor,
  currency,
  showUnitTotals$
) => [
  spacerColumn('evidence_spacer'),
  {
    headerName: 'Evidence Based',
    headerClass: ['ag-header-align-center'],
    headerGroupComponent: AgAdjustmentEvidenceBasedHeaderComponent,
    headerGroupComponentParams: {
      editMode$,
      getSelectedMonthAndVendor,
      collapsedByDefault: true,
      localStorageKey: 'closing_page_evidence_based',
      expandableCols: ['evidence_based_percentage', 'evidence_based_unit'],
    },
    children: [
      {
        headerName: '% Complete',
        colId: 'evidence_based_percentage',
        field: 'evidence_based_percentage',
        headerClass: ['ag-header-align-center'],
        cellClass: getClassNames(['percent', TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT]),
        minWidth: 100,
        width: 100,
        hide: true,
        valueGetter: (params) => {
          // For Category/Group and Discount rows
          if (!params.data || params.data.cost_category_name === 'Discount') {
            const total_amount = !params.data
              ? params.node?.aggData.total_amount
              : params.data.total_amount;

            const evidence_based_amount = !params.data
              ? params.node?.aggData.evidence_based_amount
              : params.data.evidence_based_amount;

            return (evidence_based_amount / total_amount) * 100;
          }

          return params.data.evidence_based_percentage;
        },
        valueFormatter: ({ value }) => Utils.percentageFormatter(decimalDivide(value, 100)),
      },
      {
        headerName: 'Units',
        colId: 'evidence_based_unit',
        field: 'evidence_based_unit',
        headerClass: ['ag-header-align-center'],
        hide: true,
        cellClass: getClassNames(['budget-units', 'ag-cell-align-right']),
        width: 85,
        minWidth: 70,
        valueFormatter: ({ value }) => Utils.decimalFormatter(value),
        aggFunc: showUnitTotals$.getValue() ? 'sum' : '',
      },
      {
        headerName: 'Expense ($)',
        colId: 'evidence_based_amount',
        field: 'evidence_based_amount',
        headerClass: ['ag-header-align-center'],
        cellClass: getClassNames([`budgetCost${currency}`, 'ag-cell-align-right']),
        width: 195,
        minWidth: 145,
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(params, currency);
        },
        aggFunc: 'sum',
      },
    ],
  },
];
