import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';
import {
  DocumentType,
  GqlService,
  ProcessType,
  StoredDocument,
  StoredDocumentProcessStatus,
} from '@services/gql.service';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '@components/components.module';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';
import { GridApi, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import { BehaviorSubject } from 'rxjs';
import { TableConstants } from '@constants/table.constants';
import { ApiService, AwsFile } from '@services/api.service';
import { OverlayService } from '@services/overlay.service';
import { EdcConvertedFilesGridRow } from './edc-converted-files.model';
import { getEdcConvertedFilesGridOptions } from './edc-converted-files.constants';
import { TableSkeletonComponent } from '@shared/components';
import { OpsAdminUtils } from '../../utils/ops-admin.utils';
import * as dayjs from 'dayjs';

@UntilDestroy()
@Component({
  selector: 'aux-edc-converted-files',
  templateUrl: './edc-converted-files.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    FormsModule,
    ComponentsModule,
    NgIf,
    NgClass,
    AgGridModule,
    AsyncPipe,
    TableSkeletonComponent,
  ],
})
export class EdcConvertedFilesComponent {
  private gridAPI!: GridApi;

  searchValue = '';

  loadingGridData = true;

  downloadFile = async (downloadLink: string, fileName: string): Promise<void> => {
    const ref = this.overlayService.loading();

    await this.apiService.downloadFile({
      key: downloadLink,
      fileName: fileName,
    } as AwsFile);

    ref.close();
  };

  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      sortable: false,
      resizable: true,
    },
    columnDefs: getEdcConvertedFilesGridOptions(this.downloadFile),
  } as GridOptions;

  gridData$ = new BehaviorSubject<EdcConvertedFilesGridRow[]>([]);

  constructor(
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private apiService: ApiService,
    private overlayService: OverlayService
  ) {
    this.onTrialChange();
  }

  onGridReady({ api }: GridReadyEvent): void {
    this.gridAPI = api;
  }

  autosize(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  refreshGridData(): void {
    this.loadingGridData = true;
    const trialId = this.mainQuery.getValue().trialKey;

    this.gqlService
      .fetchStoredDocuments$({
        page: {
          offset: 0,
        },
        trial_id: trialId,
        document_type_id: DocumentType.DOCUMENT_EDC_DATA,
        process_type: ProcessType.PROCESS_TRANSFORM_ATHENA,
      })
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result.success && result.data) {
          this.gridData$.next(this.mapEdcConvertedFilesGridData(result.data.items));
        } else {
          this.gridData$.next([]);
        }
        this.loadingGridData = false;
      });
  }

  private onTrialChange(): void {
    this.mainQuery
      .select('trialKey')
      .pipe(
        switchMap(async () => this.refreshGridData()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private mapEdcConvertedFilesGridData(
    convertedFiles: StoredDocument[]
  ): EdcConvertedFilesGridRow[] {
    return convertedFiles.map((file: StoredDocument) => {
      const sourceDocuments = JSON.parse(file.source_documents);
      const originalFiles =
        sourceDocuments.length > 0
          ? sourceDocuments.map((doc: { bucket_key: string }) => ({
              name: doc.bucket_key.substr(doc.bucket_key.lastIndexOf('/') + 1),
              link: OpsAdminUtils.prepareDownloadLink(doc.bucket_key),
            }))
          : [];
      let edcConvertedFileDownloadLink = '';
      let edcConvertedFileName = '';
      const settings = JSON.parse(file.settings || '');
      const month_close = settings.MONTH_CLOSE
        ? dayjs(settings.MONTH_CLOSE).format('MMMM YYYY')
        : '';

      if (file.process_status === StoredDocumentProcessStatus.STATUS_PROCESSED) {
        const processed_docs = JSON.parse(file.processed_documents || '[]') as {
          default?: string;
          bucket_key: string;
        }[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const default_docs: any[] = processed_docs.filter((doc: any) => doc.default);
        edcConvertedFileDownloadLink =
          default_docs.length > 0
            ? default_docs[0].bucket_key
            : JSON.parse(file.processed_documents || '')[0].bucket_key;
        edcConvertedFileName = edcConvertedFileDownloadLink.substr(
          edcConvertedFileDownloadLink.lastIndexOf('/') + 1
        );
      }

      return {
        converted_file: edcConvertedFileName,
        converted_file_download_link: OpsAdminUtils.prepareDownloadLink(
          edcConvertedFileDownloadLink
        ),
        month_close: month_close,
        status: file.process_status,
        original_files: originalFiles,
        date_converted: this.dateConverted(file),
      };
    });
  }

  private dateConverted(file: StoredDocument): string {
    if (file.process_status === StoredDocumentProcessStatus.STATUS_PROCESSED) {
      return file.process_date || '';
    }

    return '';
  }
}
