import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PatientGroupsModel } from './patient-groups.model';

export type PatientGroupsState = EntityState<PatientGroupsModel, string>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patient-groups' })
export class PatientGroupsStore extends EntityStore<PatientGroupsState> {
  constructor() {
    super({});
  }
}
