import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class QuarterCloseAdjustmentsService {
  formControlSelectedMonthSource = new BehaviorSubject<string>('');

  formControlSelectedVendorSource = new BehaviorSubject<string>('');

  formControlSelectedCategorySource = new BehaviorSubject<string>('');

  selectedMonthValue$ = this.formControlSelectedMonthSource.asObservable();

  selectedVendorValue$ = this.formControlSelectedVendorSource.asObservable();

  selectedCategoryValue$ = this.formControlSelectedCategorySource.asObservable();

  updateFormControlValues(selectedMonth?: string, selectedVendor?: string) {
    if (selectedMonth) {
      this.formControlSelectedMonthSource.next(selectedMonth);
    }
    if (selectedVendor) {
      this.formControlSelectedVendorSource.next(selectedVendor);
    }
  }

  updateSelectCategory(selectedCategory: string): void {
    this.formControlSelectedCategorySource.next(selectedCategory);
  }
}
