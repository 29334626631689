import { Component } from '@angular/core';
import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';
import { TooltipComponent } from '@components/tooltip/tooltip.component';

@Component({
  standalone: true,
  template: ` <aux-tooltip [text]="params.value || ''" /> `,
  imports: [TooltipComponent],
  styles: [
    `
      :host {
        position: absolute;
        pointer-events: none;
        max-width: 400px;
      }
      :host ::ng-deep div {
        word-break: break-word;
      }
    `,
  ],
})
export class AgTooltipComponent implements ITooltipAngularComp {
  params!: ITooltipParams;

  agInit(params: ITooltipParams): void {
    this.params = params;
  }
}
