import { EventQuery } from './../../../../../models/event/event.query';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import {
  Currency,
  EventType,
  InvoiceStatus,
  Note,
  PermissionType,
  WorkflowStep,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, pairwise, startWith } from 'rxjs/operators';
import {
  FormControl,
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { AuthService } from '@models/auth/auth.service';
import { AuthQuery } from '@models/auth/auth.query';
import { Utils } from '@services/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { PurchaseOrdersQuery } from '../../purchase-orders/state/purchase-orders.query';
import { InvoiceModel } from '../state/invoice.model';
import { InvoiceService } from '../state/invoice.service';
import { WorkflowQuery } from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';
import { decimalAdd, decimalEquality } from '@shared/utils';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { MessagesConstants } from '@constants/messages.constants';
import { ItemsViaPdfTabComponent } from '../items-via-pdf-tab/items-via-pdf-tab.component';

@UntilDestroy()
@Component({
  selector: 'aux-invoice',
  templateUrl: 'invoice.component.html',
  styleUrls: ['invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceComponent implements OnInit {
  @ViewChild('invoiceFormRef') invoiceFormRef!: FormGroupDirective;

  @ViewChild('itemsViaPdfTab') itemsViaPdfTab!: ItemsViaPdfTabComponent;

  readonly messagesConstants = MessagesConstants;

  invoiceForm = this.formBuilder.group({
    accrual_period: '',
    vendor_id: '',
    invoice_no: '',
    invoice_date: '',
    invoice_total: '',
    investigator_total: '',
    services_total: '',
    discount_total: '',
    pass_thru_total: '',
    invoice_total_trial_currency: '',
    pass_thru_total_trial_currency: '',
    services_total_trial_currency: '',
    discount_total_trial_currency: '',
    investigator_total_trial_currency: '',
  });

  isInvoiceFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
  );

  iCloseMonthsProcessing$ = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  userHasEditInvoicePermission = false;

  invoiceLockTooltip$ = this.workflowQuery.invoiceLockTooltip$;

  filteredPONumbers$ = this.purchaseOrdersQuery.selectAll().pipe(
    map((value) => {
      return value.filter((po) => {
        return po.organization_id === this.invoice.organization.id;
      });
    })
  );

  filteredOrganizations$ = this.vendorsQuery.allVendors$;

  autoAdjustedAccrual = false;

  userAdjustedAccrual = false;

  @Input() set invoiceData(inv: InvoiceModel) {
    this.invoice = inv;
    this.invoiceForm.patchValue({
      accrual_period: this.invoice.accrual_period
        ? dayjs(this.invoice.accrual_period).format('YYYY-MM')
        : null,
      vendor_id: this.invoice.organization.id,
      invoice_no: this.invoice.invoice_no,
      invoice_date: this.invoice.invoice_date,
      invoice_total: Utils.agCurrencyFormatter(
        this.invoice.expense_amounts.invoice_total,
        this.invoice.expense_amounts.invoice_total.contract_curr ||
          this.invoice.organization.currency
      ).toString(),
      investigator_total: Utils.agCurrencyFormatter(
        this.invoice.expense_amounts.investigator_total,
        this.invoice.expense_amounts.investigator_total.contract_curr ||
          this.invoice.organization.currency
      ).toString(),
      services_total: Utils.agCurrencyFormatter(
        this.invoice.expense_amounts.services_total,
        this.invoice.expense_amounts.services_total.contract_curr ||
          this.invoice.organization.currency
      ).toString(),
      discount_total: Utils.agCurrencyFormatter(
        this.invoice.expense_amounts.discount_total,
        this.invoice.expense_amounts.discount_total.contract_curr ||
          this.invoice.organization.currency
      ).toString(),
      pass_thru_total: Utils.agCurrencyFormatter(
        this.invoice.expense_amounts.pass_thru_total,
        this.invoice.expense_amounts.pass_thru_total.contract_curr ||
          this.invoice.organization.currency
      ).toString(),
      invoice_total_trial_currency: Utils.currencyFormatter(
        this.invoice.expense_amounts.invoice_total_trial_currency.value
      ).toString(),
      pass_thru_total_trial_currency: Utils.currencyFormatter(
        this.invoice.expense_amounts.pass_thru_total_trial_currency.value
      ).toString(),
      services_total_trial_currency: Utils.currencyFormatter(
        this.invoice.expense_amounts.services_total_trial_currency.value
      ).toString(),
      discount_total_trial_currency: Utils.currencyFormatter(
        this.invoice.expense_amounts.discount_total_trial_currency.value
      ).toString(),
      investigator_total_trial_currency: Utils.currencyFormatter(
        this.invoice.expense_amounts.investigator_total_trial_currency.value
      ).toString(),
    });
    this.cloneInvoice();
  }

  invoice: InvoiceModel = {} as InvoiceModel;

  @Input() trialMonthClose = '';

  @Input() trialEndDate = '';

  @Input() isDeclined = false;

  @Input() disabled = false;

  accrualDisabled = false;

  clonedInvoice: InvoiceModel = {} as InvoiceModel;

  selectedPOReference = new UntypedFormControl(null);

  accrualMinDate = dayjs().format('YYYY-MM');

  accrualMaxDate = dayjs().format('YYYY-MM');

  activeTabIndex = 0;

  isOcrItemsEnabled = false;

  isOcrItemsEnabled$ = this.launchDarklyService.select$((flags) => {
    this.isOcrItemsEnabled = flags.tab_invoice_line_items_ocr;
    if (!flags.tab_invoice_line_items_ocr) {
      this.activeTabIndex = 1;
    }
    return flags.tab_invoice_line_items_ocr;
  });

  isIntegrationItemsEnabled = false;

  isIntegrationItemsEnabled$ = this.launchDarklyService.select$((flags) => {
    this.isIntegrationItemsEnabled = flags.tab_invoice_line_items_integration;
    if (!flags.tab_invoice_line_items_integration) {
      this.activeTabIndex = 0;
    }
    return flags.tab_invoice_line_items_integration;
  });

  tabs: { label: string; show: Observable<boolean> }[] = [
    {
      label: 'Line Items (via PDF Parser)',
      show: this.isOcrItemsEnabled$,
    },
    {
      label: 'Line Items (via Integration)',
      show: this.isIntegrationItemsEnabled$,
    },
  ];

  notesControl = new FormControl();

  private isFirstInit = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public vendorsQuery: OrganizationQuery,
    public purchaseOrdersQuery: PurchaseOrdersQuery,
    public authQuery: AuthQuery,
    private invoiceService: InvoiceService,
    private overlayService: OverlayService,
    private authService: AuthService,
    private workflowQuery: WorkflowQuery,
    private mainQuery: MainQuery,
    private launchDarklyService: LaunchDarklyService,
    private eventQuery: EventQuery
  ) {}

  ngOnInit(): void {
    combineLatest([this.isInvoiceFinalized$, this.iCloseMonthsProcessing$])
      .pipe(untilDestroyed(this))
      .subscribe(([isInvoiceFinalized, iCloseMonthProcessing]) => {
        if (this.disabled || isInvoiceFinalized || iCloseMonthProcessing) {
          Object.keys(this.invoiceForm.controls).forEach((fieldName) => {
            this.invoiceForm.get(fieldName)?.disable();
          });
          this.selectedPOReference.disable();
        }
      });

    this.authService
      .isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
      })
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.userHasEditInvoicePermission = x;
      });

    this.invoiceForm.valueChanges
      .pipe(
        startWith(this.invoiceForm.value),
        pairwise(),
        filter(([prev, next]) => this.invoice.invoice_date == null && !isEqual(prev, next))
      )
      .subscribe(([prev, next]) => {
        // invoice date was changed and user has not manually adjusted accrual
        if (
          next.invoice_date &&
          prev.invoice_date !== next.invoice_date &&
          !this.userAdjustedAccrual
        ) {
          // timeout ensure that patchValue happens after current call
          setTimeout(
            () =>
              this.invoiceForm.patchValue(
                {
                  accrual_period: this.getAccrualPeriodFromDate(next.invoice_date),
                },
                { emitEvent: false, onlySelf: true }
              ),
            0
          );
          this.autoAdjustedAccrual = true;
          // accrual was changed
        } else if (next.accrual_period !== prev.accrual_period) {
          if (this.autoAdjustedAccrual) {
            this.autoAdjustedAccrual = false;
          } else {
            this.userAdjustedAccrual = true;
          }
        }
      });
  }

  onTabChange(tabIndex: number): void {
    this.activeTabIndex = tabIndex;
  }

  private getAccrualPeriodFromDate(invoiceDate: string): string {
    const isInvoiceDateValid =
      invoiceDate && dayjs(invoiceDate).isAfter(this.trialMonthClose, 'month');

    return dayjs(isInvoiceDateValid ? invoiceDate : this.accrualMinDate).format('YYYY-MM');
  }

  private cloneInvoice() {
    this.clonedInvoice = JSON.parse(JSON.stringify(this.invoice));
    this.accrualMinDate = dayjs(this.trialMonthClose).format('YYYY-MM');
    this.accrualMaxDate = dayjs(this.trialEndDate).format('YYYY-MM');
    this.accrualDisabled = this.isAccrualDisabled();
    this.clonedInvoice.accrual_period = dayjs(this.clonedInvoice.accrual_period).format('YYYY-MM');

    this.invoiceForm.patchValue({
      isReviewComplete: this.clonedInvoice.invoice_status === InvoiceStatus.STATUS_APPROVED,
    });
    this.invoiceForm.markAsPristine();

    if (this.clonedInvoice.notes && this.isFirstInit) {
      this.notesControl.setValue(this.clonedInvoice.notes?.[0]?.message || '');
    }

    this.isFirstInit = false;
  }

  async onEditSave() {
    this.clonedInvoice.expense_amounts = {
      invoice_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.invoice_total.toString()),
        type: this.clonedInvoice.expense_amounts.invoice_total.type,
        contract_curr: this.clonedInvoice.expense_amounts.invoice_total.contract_curr,
        is_vendor_currency_amount: true,
      },
      pass_thru_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.pass_thru_total.toString()),
        type: this.clonedInvoice.expense_amounts.pass_thru_total.type,
        contract_curr: this.clonedInvoice.expense_amounts.pass_thru_total.contract_curr,
        is_vendor_currency_amount: true,
      },
      services_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.services_total.toString()),
        type: this.clonedInvoice.expense_amounts.services_total.type,
        contract_curr: this.clonedInvoice.expense_amounts.services_total.contract_curr,
        is_vendor_currency_amount: true,
      },
      discount_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.discount_total.toString()),
        type: this.clonedInvoice.expense_amounts.discount_total.type,
        contract_curr: this.clonedInvoice.expense_amounts.discount_total.contract_curr,
        is_vendor_currency_amount: true,
      },
      investigator_total: {
        value: Utils.reverseAccountingFormat(this.invoiceForm.value.investigator_total.toString()),
        type: this.clonedInvoice.expense_amounts.investigator_total.type,
        contract_curr: this.clonedInvoice.expense_amounts.investigator_total.contract_curr,
        is_vendor_currency_amount: true,
      },
      invoice_total_trial_currency: {
        value: Utils.reverseAccountingFormat(
          this.invoiceForm.value.invoice_total_trial_currency.toString()
        ),
        type: this.clonedInvoice.expense_amounts.invoice_total_trial_currency.type,
        is_vendor_currency_amount: false,
      },
      pass_thru_total_trial_currency: {
        value: Utils.reverseAccountingFormat(
          this.invoiceForm.value.pass_thru_total_trial_currency.toString()
        ),
        type: this.clonedInvoice.expense_amounts.pass_thru_total_trial_currency.type,
        is_vendor_currency_amount: false,
      },
      services_total_trial_currency: {
        value: Utils.reverseAccountingFormat(
          this.invoiceForm.value.services_total_trial_currency.toString()
        ),
        type: this.clonedInvoice.expense_amounts.services_total_trial_currency.type,
        is_vendor_currency_amount: false,
      },
      discount_total_trial_currency: {
        value: Utils.reverseAccountingFormat(
          this.invoiceForm.value.discount_total_trial_currency.toString()
        ),
        type: this.clonedInvoice.expense_amounts.discount_total_trial_currency.type,
        is_vendor_currency_amount: false,
      },
      investigator_total_trial_currency: {
        value: Utils.reverseAccountingFormat(
          this.invoiceForm.value.investigator_total_trial_currency.toString()
        ),
        type: this.clonedInvoice.expense_amounts.investigator_total_trial_currency.type,
        is_vendor_currency_amount: false,
      },
    };

    const invoiceTotal = this.clonedInvoice.expense_amounts.invoice_total;
    let otherTotals = 0;
    Object.entries(this.clonedInvoice.expense_amounts)
      .filter(([, expense]) => expense.type !== invoiceTotal.type)
      .forEach(([key, expense]) => {
        if (Utils.isNumber(expense.value) && !key.includes('_trial_currency')) {
          otherTotals = decimalAdd(otherTotals, Number(expense.value));
        }
      });

    if (!decimalEquality(otherTotals, Number(invoiceTotal.value), 2)) {
      this.overlayService.error([
        'sum of',
        '- Investigator',
        '- Services',
        '- Discount',
        '- Pass-thru',
        'must be equal to Invoice Total',
      ]);
      return false;
    }

    const accrualPeriod = this.invoiceForm.value.accrual_period;

    await this.invoiceService.update({
      ...this.clonedInvoice,
      accrual_period: accrualPeriod ? `${accrualPeriod}-01` : null,
      expense_amounts: this.clonedInvoice.expense_amounts,
      invoice_date: this.invoiceForm.value.invoice_date,
      invoice_no: this.invoiceForm.value.invoice_no,
      due_date: this.clonedInvoice.due_date,
      organization: {
        id: this.invoiceForm.value.vendor_id,
        name: this.vendorsQuery.getEntity(this.invoiceForm.value.vendor_id)?.name as string,
        currency: this.vendorsQuery.getEntity(this.invoiceForm.value.vendor_id)
          ?.currency as Currency,
      },
    });
    return true;
  }

  onVendorChange(): void {
    if (this.clonedInvoice.po_reference) {
      this.selectedPOReference.setErrors({ message: 'error' });
    }
    this.filteredPONumbers$ = this.purchaseOrdersQuery.selectAll().pipe(
      map((value) => {
        return value.filter((po) => {
          return po.organization_id === this.invoiceForm.value.vendor_id;
        });
      })
    );
  }

  async saveNotes(): Promise<void> {
    if (this.clonedInvoice.notes && this.clonedInvoice.notes.length > 0) {
      const result = await this.invoiceService.updateInvoiceNote(
        this.clonedInvoice.notes[0].id,
        this.notesControl.value
      );
      if (result) {
        this.clonedInvoice.notes = [result as Note];
      }
    } else {
      const result = await this.invoiceService.createInvoiceNote(
        this.clonedInvoice.id,
        this.notesControl.value
      );
      if (result) {
        this.clonedInvoice.notes = [result as Note];
      }
    }
    this.notesControl.markAsPristine();
  }

  cancelNotes(): void {
    if (this.clonedInvoice.notes && this.clonedInvoice.notes.length > 0) {
      this.notesControl.setValue(this.clonedInvoice.notes[0].message);
    } else {
      this.notesControl.setValue(null);
    }
    this.notesControl.markAsPristine();
  }

  private isAccrualDisabled(): boolean {
    if (this.trialMonthClose.length && this.invoiceForm.value.accrual_period) {
      return !dayjs(this.invoiceForm.value.accrual_period).isSameOrAfter(
        this.trialMonthClose,
        'month'
      );
    }
    return false;
  }
}
