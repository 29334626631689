import { Injectable } from '@angular/core';
import { Option } from '@components/components.type';
import { CurveType, listTimelineMilestonesQuery } from '@services/gql.service';
import { sortBy, flatten } from 'lodash-es';
import { FullActivity, FullCategory, FullSettings } from '../../category/category.query';
import { TimelineState } from '../../../timeline-group/timeline/state/timeline.store';
import {
  ForecastTableGridTimelineCategoriesInterface,
  ForecastTableGridTimelinePhaseInterface,
  ForecastTableGridTimelineMilestoneInterface,
  ForecastTableGridTimelineItemsResultsInterface,
} from '../models/forecast-table-grid.model';
import { TimelineQuery } from '../../../timeline-group/timeline/state/timeline.query';

@Injectable()
export class ForecastTableGridPeriodService {
  PeriodPatientOptions: Option<string>[] = [
    {
      value: 'Patient Curves',
      label: 'Patient Curves',
    },
  ];

  PeriodSiteOptions: Option<CurveType>[] = [
    {
      value: CurveType.NET,
      label: 'Net Sites',
    },
    {
      value: CurveType.ACTIVATION,
      label: 'Sites Activated',
    },
    {
      value: CurveType.CLOSE_OUT,
      label: 'Sites Closed',
    },
  ];

  constructor(private timelineQuery: TimelineQuery) {}

  isCustomPeriodFilled(primarySettings: FullSettings): boolean {
    return (
      !primarySettings.milestone_category &&
      !!(primarySettings.start_milestone || primarySettings.period_start_date) &&
      !!(primarySettings.end_milestone || primarySettings.period_end_date)
    );
  }

  parseCategoryPeriod(category: FullCategory | FullActivity): string {
    return category.primary_settings.milestone_category?.name || '';
  }

  parsePhases(
    timelinePhases: ForecastTableGridTimelinePhaseInterface[],
    fullSettings?: FullSettings
  ): Option<string>[] {
    if (!fullSettings || (fullSettings && fullSettings.milestone_category)) {
      return timelinePhases.map((phase) => {
        return {
          label: phase.name !== 'Custom' ? `Preset: ${phase.name}` : `${phase.name}`,
          value: phase.cat_id,
        };
      });
    }

    if (!fullSettings.milestone_category) {
      const startDate = fullSettings.start_milestone?.name || fullSettings.period_start_date;
      const endDate = fullSettings.end_milestone?.name || fullSettings.period_end_date;

      return timelinePhases.map((phase) => {
        return {
          label:
            phase.name !== 'Custom'
              ? `Preset: ${phase.name}`
              : `${phase.name}: ${startDate || ''} - ${endDate || ''}`,
          value: phase.cat_id,
        };
      });
    }

    return [];
  }

  parseSelectedPhase(
    timelinePhases: ForecastTableGridTimelinePhaseInterface[],
    fullSettings?: FullSettings
  ): string {
    if (!fullSettings) {
      return '';
    }

    if (fullSettings.milestone_category) {
      return (
        timelinePhases.find((phase) => {
          return phase.cat_id === fullSettings.milestone_category?.id;
        })?.cat_id || ''
      );
    }

    if (!fullSettings.milestone_category) {
      return (
        timelinePhases.find((phase) => {
          return phase.name === 'Custom';
        })?.cat_id || ''
      );
    }

    return '';
  }

  parseTimelineItems(
    timelineItems: ForecastTableGridTimelineItemsResultsInterface | never[]
  ): [ForecastTableGridTimelinePhaseInterface[], ForecastTableGridTimelineMilestoneInterface[]] {
    if (!timelineItems) {
      return [[], []];
    }

    const timelinePhases = this.getTimelinePhases();
    const timelineMilestones = this.getTimelineMilestones(timelinePhases);

    return [timelinePhases, timelineMilestones];
  }

  getTimelinePhases(): ForecastTableGridTimelinePhaseInterface[] {
    const milestoneResults = this.timelineQuery.getValue();
    const categories = this.getTimelineCategories(milestoneResults);

    const phases: ForecastTableGridTimelinePhaseInterface[] = Object.entries(categories)
      .filter(([, timelineCategory]) => timelineCategory.milestones.length >= 1)
      .map(([cat_id, { milestones, name }]) => {
        return {
          cat_id,
          firstMilestone: milestones[0],
          lastMilestone: milestones[milestones.length - 1],
          milestones,
          name,
        } as ForecastTableGridTimelinePhaseInterface;
      })
      .filter((phase) => phase.milestones.every((milestone) => !!milestone));

    phases.push({
      name: 'Custom',
      cat_id: '',
      firstMilestone: null,
      lastMilestone: null,
      milestones: [],
    });

    return phases;
  }

  getTimelineCategories(milestones: TimelineState): ForecastTableGridTimelineCategoriesInterface {
    const { items } = milestones;

    return items.reduce(
      (
        obj: ForecastTableGridTimelineCategoriesInterface,
        timelineMilestone: listTimelineMilestonesQuery
      ) => {
        const milestoneObj = obj;

        // Initialize object
        milestoneObj[timelineMilestone.milestone.milestone_category_id] ||= {
          name: '',
          milestones: [],
        };
        // Initialize name
        milestoneObj[timelineMilestone.milestone.milestone_category_id].name =
          timelineMilestone.milestone.milestone_category.name;
        // Initialze dates
        milestoneObj[timelineMilestone.milestone.milestone_category_id].milestones.push({
          ...timelineMilestone.milestone,
          start_date:
            timelineMilestone.actual_start_date ||
            timelineMilestone.revised_start_date ||
            timelineMilestone.contract_start_date,
          end_date:
            timelineMilestone.actual_end_date ||
            timelineMilestone.revised_end_date ||
            timelineMilestone.contract_end_date,
        });

        return milestoneObj;
      },
      {}
    );
  }

  getTimelineMilestones(
    phases: ForecastTableGridTimelinePhaseInterface[]
  ): ForecastTableGridTimelineMilestoneInterface[] {
    const filteredPhases = phases.filter((phase) => {
      return phase.name !== 'Custom' && phase.cat_id !== '';
    });

    return sortBy(flatten([...filteredPhases.map((phase) => phase.milestones)]), [
      'start_date',
      'end_date',
      'name',
    ]);
  }
}
