import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '@services/gql.service';
import { Utils } from '@services/utils';

@Pipe({
  name: 'money',
})
export class MoneyPipe implements PipeTransform {
  transform(value?: number | null | undefined, currency?: Currency): string {
    return Utils.currencyFormatter(value || 0, {}, currency);
  }
}
