import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams, GridApi } from '@ag-grid-community/core';
import { UntypedFormControl } from '@angular/forms';
import { FormControlConstants } from '@constants/form-control.constants';

interface IAgDatePickerParams extends ICellEditorParams {
  type?: 'date' | 'month';
  min?: string;
  max?: string;
}

@Component({
  selector: 'aux-ag-date-picker',
  templateUrl: './ag-date-picker.component.html',
  styles: [
    `
      :host,
      :host ::ng-deep input,
      :host ::ng-deep label,
      .aux-ag-date-picker {
        height: 100%;
      }

      :host ::ng-deep input {
        font-size: 14px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgDatePickerComponent implements ICellEditorAngularComp {
  readonly formControlConstants = FormControlConstants;

  params!: IAgDatePickerParams;

  fc = new UntypedFormControl();

  api!: GridApi;

  max = '';

  min = '';

  type: 'date' | 'month' = 'date';

  stopEditing!: (suppressNavigateAfterEdit?: boolean | undefined) => void;

  agInit(params: IAgDatePickerParams): void {
    this.params = params;

    if (params.api) {
      this.api = params.api;
    }

    if (params.type) {
      this.type = params.type;
    }

    this.min = params.min || '';
    this.max = params.max || '';
    this.stopEditing = params.stopEditing;
    this.fc.setValue(params.value);
  }

  getValue() {
    return this.fc.value;
  }
}
