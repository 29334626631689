<aux-workflow-panel
  *ngIf="(isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)"
  className="mb-4"
  [workflowName]="workflowName"
  [isAdminUser]="(isAdminUser$ | async) === true"
  [processing]="(iCloseMonthsProcessing$ | async)!"
  [hasUserPermissions]="userHasLockInvoicesPermission"
/>

<ng-container
  *ngIf="
    (invoiceQuery.selectLoading() | async) === false && (filesLoading$ | async) === false;
    else loading
  "
>
  <ng-container>
    <ng-container>
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <h1 class="text-2xl font-bold">Invoice Library</h1>

          <div class="ml-7">
            <aux-checkbox
              class="mt-3 mb-3 text-sm"
              (customChange)="checkRequireCostBreakdown($event)"
            >
              Require Cost Breakdown
            </aux-checkbox>
          </div>
          <div class="ml-7">
            <aux-checkbox
              class="mt-3 mb-3 text-sm"
              (customChange)="checkRequireAccrualPeriod($event)"
            >
              Require Accrual Period
            </aux-checkbox>
          </div>
        </div>

        <div class="grid grid-flow-col gap-x-1.5">
          <div *ngIf="(invoiceService.loadingFetchBillCom$ | async) === true" class="flex h-full">
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchBillCom$ | async) === false &&
              (billComIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
              (click)="invoiceService.triggerFetchBillCom()"
            >
              <aux-icon name="CloudDownload" class="flex-shrink-0 mr-2" />
              Sync Invoices
            </button>
          </div>

          <div *ngIf="(invoiceService.loadingFetchCoupa$ | async) === true" class="flex h-full">
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchCoupa$ | async) === false &&
              (coupaIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
              (click)="invoiceService.triggerFetchCoupa()"
            >
              <aux-icon name="CloudDownload" class="mr-2 flex-shrink-0" />
              Sync Invoices
            </button>
          </div>

          <div
            *ngIf="(invoiceService.loadingFetchDynamics365$ | async) === true"
            class="flex h-full"
          >
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchDynamics365$ | async) === false &&
              (dynamics365IntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
              (click)="invoiceService.triggerFetchDynamics365()"
            >
              <aux-icon name="CloudDownload" class="mr-2 flex-shrink-0" />
              Sync Invoices
            </button>
          </div>

          <div
            *ngIf="(invoiceService.loadingFetchDynamics365Fo$ | async) === true"
            class="flex h-full"
          >
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchDynamics365Fo$ | async) === false &&
              (dynamics365FoIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
              (click)="invoiceService.triggerFetchDynamics365Fo()"
            >
              <aux-icon name="CloudDownload" class="mr-2 flex-shrink-0" />
              Sync Invoices
            </button>
          </div>

          <div *ngIf="(invoiceService.loadingFetchNetsuite$ | async) === true" class="flex h-full">
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchNetsuite$ | async) === false &&
              (netsuiteIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
              (click)="invoiceService.triggerFetchNetsuite()"
            >
              <aux-icon name="CloudDownload" class="mr-2 flex-shrink-0" />
              Sync Invoices
            </button>
          </div>

          <div
            *ngIf="(invoiceService.loadingFetchOracleFusion$ | async) === true"
            class="flex h-full"
          >
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchOracleFusion$ | async) === false &&
              (oracleFusionIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
              (click)="invoiceService.triggerFetchOracleFusion()"
            >
              <aux-icon name="CloudDownload" class="mr-2 flex-shrink-0" />

              Sync Invoices
            </button>
          </div>

          <div
            *ngIf="(invoiceService.loadingFetchQuickbooksOnline$ | async) === true"
            class="flex h-full"
          >
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchQuickbooksOnline$ | async) === false &&
              (quickbooksOnlineIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
              (click)="invoiceService.triggerFetchQuickbooksOnline()"
            >
              <aux-icon name="CloudDownload" class="mr-2 flex-shrink-0" />
              Sync Invoices
            </button>
          </div>

          <div
            *ngIf="(invoiceService.loadingFetchSageIntacct$ | async) === true"
            class="flex h-full"
          >
            <div class="spinner w-9 h-9"></div>
          </div>

          <div
            *ngIf="
              (invoiceService.loadingFetchSageIntacct$ | async) === false &&
              (sageIntacctIntegrationEnabled$ | async) === true
            "
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
          >
            <button
              class="btn--secondary pl-3"
              [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
              (click)="invoiceService.triggerFetchSageIntacct()"
            >
              <aux-icon name="CloudDownload" class="mr-2 flex-shrink-0" />
              Sync Invoices
            </button>
          </div>

          <aux-button
            variant="secondary"
            label="Export"
            icon="FileExport"
            [onClick]="this.onExportInvoices.bind(this)"
            [loading]="isBtnLoading('export') | async"
            [spinnerSize]="7"
          />

          <aux-button
            classList="pl-3"
            variant="primary"
            label="Add New"
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
            [onClick]="this.onNewInvoice.bind(this)"
            [disabled]="
              (isInvoiceFinalized$ | async) ||
              (iCloseMonthsProcessing$ | async) ||
              (isEditModeEnabled$ | async)
            "
            icon="CirclePlus"
          />
        </div>
      </div>

      <div class="flex items-end justify-between mt-2">
        <div class="flex items-end flex-wrap">
          <aux-button
            *ngIf="isEditModeEnabled$ | async"
            variant="secondary"
            label="Bulk Edit"
            classList="mr-4"
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
            [disabled]="
              (isSavingChanges$ | async) ||
              (isInvoiceFinalized$ | async) ||
              (iCloseMonthsProcessing$ | async) ||
              !selectedRows.length
            "
            icon="Pencil"
            [spinnerSize]="7"
            [onClick]="onBulkApplyButtonClick"
          />
          <aux-button
            *ngIf="isEditModeEnabled$ | async"
            variant="negative"
            label="Delete"
            classList="mr-4"
            icon="Trash"
            [auxTooltip]="(invoiceLockTooltip$ | async)!"
            [disabled]="
              (isSavingChanges$ | async) ||
              (isInvoiceFinalized$ | async) ||
              (iCloseMonthsProcessing$ | async) ||
              !selectedRows.length ||
              !userHasDeleteInvoicePermission
            "
            [onClick]="removeInvoices"
            [spinnerSize]="7"
          />
          <aux-invoice-filters
            [form]="invoiceService.filtersForm"
            [onFilterChange]="invoiceService.onFilterChange"
          />
          <div
            *ngIf="numberOfVendorCurrencies > 1 && (vendorCurrencyEnabled$ | async)"
            class="pl-6 mb-2"
          >
            <aux-toggle-budget-currency
              class="pl-6 mb-2"
              [value]="isVendorCurrency"
              (nameSelectedCurrency)="selectedBudgetCurrencyType$.next($event)"
            />
          </div>
        </div>
        <div *ngIf="isEditModeEnabled$ | async" class="grid grid-flow-col gap-x-1.5">
          <aux-button
            variant="secondary"
            label="Cancel"
            icon="X"
            [onClick]="onCancel"
            [disabled]="isSavingChanges$ | async"
          />
          <aux-button
            variant="success"
            label="Save"
            icon="Check"
            [disabled]="(hasChanges$ | async) === false"
            [onClick]="onSave"
            [spinnerSize]="7"
          />
        </div>
        <aux-button
          *ngIf="
            (isEditModeEnabled$ | async) === false &&
            (authQuery.isAuxAdmin() || userHasEditInvoicePermission)
          "
          variant="secondary"
          label="Edit"
          icon="Pencil"
          [auxTooltip]="(invoiceLockTooltip$ | async)!"
          [disabled]="(isInvoiceFinalized$ | async) || (iCloseMonthsProcessing$ | async)"
          [onClick]="enableEditMode"
        />
      </div>

      <div class="mt-4">
        <ag-grid-angular
          class="ag-theme-aux tabular-nums"
          domLayout="autoHeight"
          [rowData]="gridData$ | async"
          [gridOptions]="$any(gridOptions$ | async)"
          [quickFilterText]="
            (invoiceService.filtersForm.controls.search.valueChanges | async) || ''
          "
          [isExternalFilterPresent]="invoiceService.isExternalFilterPresent"
          [doesExternalFilterPass]="invoiceService.doesExternalFilterPass"
          [overlayNoRowsTemplate]="overlayNoRowsTemplate"
          [enableFillHandle]="(isEditModeEnabled$ | async) === true"
          [paginationPageSize]="paginationPageSize"
          (firstDataRendered)="firstDataRendered($event)"
          (filterChanged)="invoiceService.generatePinnedRow()"
          (rowSelected)="rowSelected($event)"
          (paginationChanged)="invoiceService.generatePinnedRow()"
          (rowDataUpdated)="invoiceService.generatePinnedRow()"
          (cellValueChanged)="cellValueChanged($event)"
          (gridSizeChanged)="gridSizeChanged()"
          (viewportChanged)="onWindowScroll()"
        />
        <aux-pagination-panel
          *ngIf="(gridData$ | async)?.length"
          [gridApi]="gridAPI"
          [paginationPageSize]="paginationPageSize"
          (paginationChange)="fetchDocumentsForPage()"
        />
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <!-- TODO create a skeleton loading for invoice -->
  <div class="border-8 h-32 m-auto mt-40 spinner w-32"></div>
</ng-template>
