import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SiteDistributionStore, SiteDistributionState } from './site-distribution-store.service';

@Injectable({ providedIn: 'root' })
export class SiteDistributionQuery extends QueryEntity<SiteDistributionState> {
  constructor(protected store: SiteDistributionStore) {
    super(store);
  }
}
