import { ChangeDetectorRef, Directive, ViewContainerRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line
  selector: '[checklistComponentHost]',
})
export class QuarterCloseChecklistDirective {
  constructor(
    public viewContainerRef: ViewContainerRef,
    public changeDetectorRef: ChangeDetectorRef
  ) {}
}
