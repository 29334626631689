import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@services/utils';

@Pipe({
  name: 'accounting',
})
export class AccountingPipe implements PipeTransform {
  transform(value?: number | null | undefined): unknown {
    return Utils.numberToAccounting(value || 0);
  }
}
