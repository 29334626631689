<div
  class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
  @enterLeave
>
  <div
    class="p-4 text-white"
    [ngClass]="{
      'bg-green-400': ref.data?.mode === 'success',
      'bg-aux-error': ref.data?.mode === 'error'
    }"
  >
    <div class="flex items-start">
      <div class="shrink-0">
        <aux-icon name="CircleCheckFilled" *ngIf="ref.data?.mode === 'success'" [size]="20" />
        <aux-icon name="AlertCircleFilled" *ngIf="ref.data?.mode === 'error'" [size]="20" />
      </div>
      <div class="ml-3 flex-1 pt-0.5 w-max">
        <p *ngFor="let message of messages" class="text-sm font-medium">
          {{ message }}
        </p>
        <!--<p class="mt-1 text-sm text-gray-500">Anyone with a link can now view this file.</p>-->
      </div>
      <div class="shrink-0 flex">
        <button
          class="rounded-md inline-flex hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          (click)="ref.close()"
        >
          <span class="sr-only">Close</span>
          <aux-icon name="X" />
        </button>
      </div>
    </div>
  </div>
</div>
