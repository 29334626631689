import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColDef, ColGroupDef, ExcelExportParams, GridApi } from '@ag-grid-community/core';

type ColFilter = (cel: ColDef | ColGroupDef) => boolean;

export enum ExcelButtonVariant {
  OUTLINE = 'outline',
  FILLED = 'filled',
}

@Component({
  selector: 'aux-export-excel-button',
  templateUrl: './export-excel-button.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportExcelButtonComponent {
  @Input() gridAPI?: GridApi;

  @Input() excelOptions?: ExcelExportParams = {};

  @Input() ignoreColsId: string[] = [];

  @Input() variant?: ExcelButtonVariant = ExcelButtonVariant.OUTLINE;

  @Input() className = '';

  @Input() svgAttributes: { class: string } = { class: '' };

  @Input() svgSize = 24;

  @Input() getDynamicExcelParamsCallback?: () => ExcelExportParams | Promise<ExcelExportParams>;

  excelButtonVariants = ExcelButtonVariant;

  title = 'Export';

  private defaultExcelOptions: ExcelExportParams = {
    author: 'Auxilius',
    fontSize: 11,
    columnWidth: 105,
  };

  @Input() customColFilter?: ColFilter;

  @Input() ignoreColsCallback?: (colId?: string) => boolean;

  @Input() onExportSuccess?: () => void;

  private handleIgnoreColsCallback(colId: string, defaultValue = true): boolean {
    return this.ignoreColsCallback ? this.ignoreColsCallback(colId) : defaultValue;
  }

  private isColGroup(cell: ColDef | ColGroupDef): cell is ColGroupDef {
    return !!(cell as ColGroupDef)?.children;
  }

  private isColDef(col: ColDef | ColGroupDef): col is ColDef {
    return !!(col as ColDef).colId;
  }

  private filterIgnoreCols(col: ColDef): boolean {
    const colId = col.colId || '';

    return !colId.startsWith('spacerColumn') && this.ignoreColsId.indexOf(colId) === -1;
  }

  private getChildrenGroupIds(cols: (ColDef | ColGroupDef)[]): string[] {
    return cols
      .filter(this.isColDef)
      .filter((col) => !col.hide)
      .filter((col) => {
        return this.handleIgnoreColsCallback(col.colId || '');
      })
      .filter((col) => this.filterIgnoreCols(col))
      .map((col) => col.colId) as string[];
  }

  private defaultColFilter: ColFilter = (cel) => {
    return !!cel.headerName && this.isColDef(cel) ? !cel?.hide : true;
  };

  getDashboardIDs(): string[] {
    if (!this.gridAPI) {
      return [];
    }

    return (this.gridAPI?.getColumnDefs() || [])
      .filter((cel: ColDef | ColGroupDef) =>
        this.customColFilter ? this.customColFilter(cel) : this.defaultColFilter(cel)
      )
      .reduce<string[]>((accum, cell: ColDef | ColGroupDef) => {
        if (this.isColGroup(cell)) {
          const res = this.getChildrenGroupIds(cell.children);

          // eslint-disable-next-line no-param-reassign
          accum = accum.concat(res || []);
        }

        if (this.isColDef(cell) && this.filterIgnoreCols(cell)) {
          const isValidColId = this.handleIgnoreColsCallback(cell.colId || '');

          if (isValidColId) {
            accum.push(cell.colId || '');
          }
        }

        return accum;
      }, []);
  }

  exportExcel = async () => {
    const additionalExcelOption = this.getDynamicExcelParamsCallback
      ? await this.getDynamicExcelParamsCallback()
      : {};

    this.gridAPI?.exportDataAsExcel({
      ...this.defaultExcelOptions,
      columnKeys: this.getDashboardIDs(),
      ...this.excelOptions,
      ...additionalExcelOption,
    });

    if (this.onExportSuccess) {
      this.onExportSuccess();
    }
  };
}
