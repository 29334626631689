import { Store, StoreConfig } from '@datorama/akita';
import { TrialInsightsState } from '../models/trial-insights-store.model';

const initState = () => {
  return {
    isLoading: true,
    isLoadingRemaining: true,
    data: null,
  };
};

const initBVACompareMenuState = () => {
  return {
    ...initState(),
    vendorId: null,
    snapshotName: null,
  };
};

const initPaymentMilestone = () => {
  return {
    ...initState(),
    totalMilestones: 0,
    chartData: [],
  };
};

const initRemainingSpend = () => {
  return {
    ...initState(),
    totalRemainingSpend: 0,
  };
};

export function createInitialState(): TrialInsightsState {
  return {
    patientCost: initState(),
    investigatorCost: initState(),
    screenFail: initState(),
    siteCost: initState(),
    bvaChart: initBVACompareMenuState(),
    patientEnrolled: initState(),
    paymentMilestone: initPaymentMilestone(),
    remainingSpend: initRemainingSpend(),
    currentOpenMonth: null,
  };
}

@StoreConfig({ name: 'trialInsights', resettable: true })
export class TrialInsightsStore extends Store<TrialInsightsState> {
  constructor() {
    super(createInitialState());
  }
}
