import { ChangeLogItemStatus, TrialImplementationStatus } from '@services/gql.service';

export class ChangeLogItemStatusConstants {
  static readonly config = [
    {
      value: ChangeLogItemStatus.STATUS_PENDING_REVIEW,
      circleClass: 'bg-aux-gray-darkest',
      textClass: 'text-aux-gray-darkest',
      text: 'Pending Review',
    },
    {
      value: ChangeLogItemStatus.STATUS_PENDING_APPROVAL,
      circleClass: 'bg-aux-blue-light-200',
      textClass: 'text-aux-blue-light-200',
      text: 'Pending Approval',
    },
    {
      value: ChangeLogItemStatus.STATUS_APPROVED,
      circleClass: 'bg-aux-green-dark',
      textClass: 'text-aux-green-dark',
      text: 'Approved',
    },
    {
      value: ChangeLogItemStatus.STATUS_DECLINED,
      circleClass: 'bg-aux-red-dark',
      textClass: 'text-aux-red-dark',
      text: 'Declined',
    },
    {
      value: ChangeLogItemStatus.STATUS_ON_HOLD,
      circleClass: 'bg-aux-warn',
      textClass: 'text-aux-warn',
      text: 'On Hold',
    },
  ];
}

export const TrialStatusMap: Record<
  TrialImplementationStatus,
  { color: string; text: string; tooltipValue: string }
> = {
  [TrialImplementationStatus.IMPLEMENTATION_STATUS_ARCHIVED]: {
    color: '#6E0005',
    text: 'Archived',
    tooltipValue: 'Auxilius Status: Archived',
  },
  [TrialImplementationStatus.IMPLEMENTATION_STATUS_BLANK]: {
    color: '#bacad0',
    text: 'N/A',
    tooltipValue: 'Auxilius Status: N/A',
  },
  [TrialImplementationStatus.IMPLEMENTATION_STATUS_IMPLEMENTATION]: {
    color: '#5259FF',
    text: 'Implementation',
    tooltipValue: 'Auxilius Status: Implementation',
  },
  [TrialImplementationStatus.IMPLEMENTATION_STATUS_LIVE]: {
    color: '#24A148',
    text: 'Live',
    tooltipValue: 'Auxilius Status: Live',
  },
  [TrialImplementationStatus.IMPLEMENTATION_STATUS_USER_ACCEPTANCE_TESTING]: {
    color: '#009AE0',
    text: 'UAT',
    tooltipValue: 'Auxilius Status: User Acceptance Testing (UAT)',
  },
};
