import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export enum DividerOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

@Component({
  selector: 'aux-divider',
  templateUrl: './divider.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerComponent implements OnInit {
  @Input() orientation: DividerOrientation = DividerOrientation.HORIZONTAL;

  isHorizontalView!: boolean;

  ngOnInit(): void {
    this.isHorizontalView = this.orientation === DividerOrientation.HORIZONTAL;
  }
}
