import { BudgetCurrencyType } from '../budget-type';

export interface BudgetCurrencyState {
  currency: BudgetCurrencyType;
}

export function createInitialState(): BudgetCurrencyState {
  return {
    currency: BudgetCurrencyType.VENDOR,
  };
}
