import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'aux-investigator-forecast-saved-source-banner',
  templateUrl: './investigator-forecast-saved-source-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigatorForecastSavedSourceBannerComponent {
  @Input() source?: string;

  @Input() amount?: number;
}
