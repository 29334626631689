<div class="text-xl font-bold mb-4">{{ mode === 'add' ? 'Add' : 'Edit' }} User</div>

<form [formGroup]="addUserForm" (ngSubmit)="onSubmit()">
  <div class="grid grid-cols-3 gap-5">
    <aux-input label="First Name" formControlName="given_name" validators="required" />
    <aux-input label="Last Name" formControlName="family_name" validators="required" />
    <fieldset [disabled]="mode === 'edit'">
      <aux-input
        label="Email Address"
        formControlName="email"
        validators="required|email"
        [type]="'email'"
      />
    </fieldset>

    <div>
      <div class="mb-1 text-xs">Company</div>
      <ng-select
        id="vendor"
        class="select select__big"
        formControlName="organization_id"
        bindValue="id"
        bindLabel="name"
        label="Company"
        required
        auxFormError
        [clearable]="false"
        [searchable]="false"
        [appendTo]="'body'"
      >
        <ng-option *ngFor="let vendor of vendorsQuery.selectAll() | async" [value]="vendor">
          <span [title]="vendor.name">{{ vendor.name }}</span>
        </ng-option>
        <ng-option
          *ngIf="isAuxiliusSupport$ | async"
          [value]="{ id: 'Aux Support', name: 'Auxilius Support' }"
        >
          <span [title]="'Auxilius Support'">Auxilius Support</span>
        </ng-option>
      </ng-select>
    </div>

    <div>
      <div class="mb-1 text-xs">Department</div>
      <ng-select
        class="select select__big"
        formControlName="department"
        bindLabel="label"
        bindValue="key"
        label="Department"
        auxFormError
        [items]="departmentOptions"
        [clearable]="false"
        [searchable]="false"
        [appendTo]="'body'"
        [multiple]="true"
        [required]="getFc('organization_id')?.value !== 'Aux Support'"
      />
    </div>

    <aux-input
      label="Title"
      formControlName="title"
      [validators]="getFc('organization_id')?.value !== 'Aux Support' ? 'required' : ''"
    />

    <aux-input
      *ngIf="getFc('company')?.value === 'Other'"
      label="Name of other company"
      placeholder="Company"
      formControlName="companyName"
    />

    <div>
      <div class="mb-1 text-xs">User Type</div>
      <ng-select
        class="select select__big"
        formControlName="role"
        bindLabel="label"
        label="User Type"
        bindValue="value"
        auxFormError
        required
        [items]="userOptions"
        [clearable]="false"
        [searchable]="false"
        [appendTo]="'body'"
      />
    </div>
  </div>

  <div class="flex space-x-4 mt-4">
    <button class="text-sm font-normal btn btn--blue w-32" type="submit">
      {{ mode === 'add' ? 'Save' : 'Save Edits' }}
    </button>
    <button
      class="text-sm font-normal aux-link focus:outline-none"
      type="button"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</form>
