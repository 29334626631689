<div class="ag-theme-aux tabular-nums">
  <div class="ag-paging-panel ag-unselectable">
    <div class="flex items-center">
      <span>Rows per page:</span>
      <ng-select
        class="w-20 ml-4"
        dropdownPosition="top"
        [clearable]="false"
        [searchable]="false"
        [items]="[5, 10, 20, 50, 100]"
        [(ngModel)]="pageSize"
        (change)="paginationSizeChange($event)"
      />
    </div>
    <span class="ag-paging-row-summary-panel flex gap-1">
      <span class="ag-paging-row-summary-panel-number">{{ getFirstRowOnPageNumber() }}</span>
      <span>to</span>
      <span class="ag-paging-row-summary-panel-number">
        {{ getLastRowOnPageNumber() }}
      </span>
      <span>of</span>
      <span class="ag-paging-row-summary-panel-number">{{ getRowCount() }}</span>
    </span>

    <span *ngIf="{ lastPage: isLastPage() } as obj" class="ag-paging-page-summary-panel">
      <div class="ag-paging-button" [ngClass]="{ 'ag-disabled': isFirstPage() }">
        <span class="ag-icon ag-icon-first" (click)="paginationGoToFirstPage()"></span>
      </div>
      <div class="ag-paging-button" [ngClass]="{ 'ag-disabled': isFirstPage() }">
        <span class="ag-icon ag-icon-previous" (click)="paginationGoToPreviousPage()"></span>
      </div>
      <span class="ag-paging-description flex gap-1">
        <span>Page</span>
        <span class="ag-paging-number">{{ getCurrentPage() }}</span>
        <span>of</span>
        <span class="ag-paging-number">{{ getTotalPages() }}</span>
      </span>
      <div class="ag-paging-button" [ngClass]="{ 'ag-disabled': obj.lastPage }">
        <span class="ag-icon ag-icon-next" (click)="!obj.lastPage && paginateToNextPage()"></span>
      </div>
      <div class="ag-paging-button" [ngClass]="{ 'ag-disabled': obj.lastPage }">
        <span class="ag-icon ag-icon-last" (click)="!obj.lastPage && paginateToLastPage()"></span>
      </div>
    </span>
  </div>
</div>
