import { Injectable } from '@angular/core';

interface Settings {
  vendorId: string;
  lastSaved: boolean;
}

type InvestigatorForecastSettings = Record<string, Record<string, Settings>>;

@Injectable({ providedIn: 'root' })
export class InvestigatorForecastLsService {
  private localStorageKey = 'investigatorForecast';

  saveSettings(trialId: string, vendorId: string) {
    const list = this.getListSelections();

    const trialSettings = list[trialId] || {};

    // reset lastSaved flag for prev settings
    let updatedList = Object.entries(trialSettings).reduce<InvestigatorForecastSettings>(
      (accum, [vendorKey, settings]) => {
        return {
          ...accum,
          [trialId]: {
            ...accum[trialId],
            [vendorKey]: {
              ...settings,
              lastSaved: false,
            },
          },
        };
      },
      {}
    );

    const settings = {
      vendorId,
      lastSaved: true,
    };

    if (updatedList[trialId]) {
      updatedList[trialId][vendorId] = settings;
    } else {
      updatedList = {
        ...list,
        [trialId]: {
          [vendorId]: settings,
        },
      };
    }

    localStorage.setItem(this.localStorageKey, JSON.stringify(updatedList));
  }

  getLastSavedVendorByTrial(trialId: string) {
    const settings = this.getLastSavedSettings(trialId);

    return settings?.vendorId || '';
  }

  private getLastSavedSettings(trialId: string): Settings | undefined {
    const list = this.getListSelections();

    return Object.values(list[trialId] || {}).find(({ lastSaved }) => !!lastSaved);
  }

  private getListSelections(): InvestigatorForecastSettings {
    return JSON.parse(localStorage.getItem(this.localStorageKey) || '{}');
  }
}
