import { Flags } from '@services/launch-darkly.service';
import { Route, Routes } from '@angular/router';

type Flag = keyof Flags;

export const buildRoutesByFeatureFlags = (
  featureFlags: Flags | null,
  flagRoutes: Routes
): Routes => {
  return flagRoutes.reduce((routes: Routes, featureFlagRoute: Route) => {
    let isFeatureFlagRouteEnabled: null | boolean = true;

    if (featureFlagRoute.data?.feature) {
      isFeatureFlagRouteEnabled =
        featureFlags && !!featureFlags[featureFlagRoute.data?.feature as Flag];
    }

    if (isFeatureFlagRouteEnabled) {
      return [...routes, featureFlagRoute];
    }

    return routes;
  }, []);
};

export enum FeatureFlag {
  ACCOUNT_SETTINGS = 'tab_profile_settings',
  BUDGET_ENHANCED = 'tab_budget_budget_enhanced',
  BUDGET_LIBRARY = 'tab_budget_budget_library',
  BUDGET_CHANGE_LOG = 'tab_budget_changelog',
  BUDGET_CHANGE_ORDER = 'tab_change_order_review',
  COMPARE = 'tab_compare',
  CLOSING_RECONCILIATION = 'tab_budget_cash_management',
  JOURNAL_ENTRIES = 'tab_journal_entries',
  FORECAST_DRIVERS = 'tab_forecast_drivers',
  FORECAST_STUDY_SPECS = 'tab_forecast_studyspecifications',
  PATIENT_TRACKER = 'nav_patient',
  PROTOCOL_ENTRY = 'tab_protocol_entry',
  TRIAL_INSIGHTS = 'nav_trial_insights',
  INVESTIGATOR_SUMMARY = 'tab_investigator_summary',
  INVESTIGATOR_TRANSACTIONS = 'tab_investigator_transactions',
  PATIENT_BUDGET = 'tab_trial_settings_patient_protocol',
  SITES_INVESTIGATOR_DETAIL = 'tab_forecast_investigator_detail',
  SITES_INVESTIGATOR_FORECAST = 'tab_forecast_investigator_forecast',
  INVESTIGATOR_SITES = 'tab_investigator_sites',
  SETTINGS_EXCHANGE_RATES = 'tab_exchange_rates',
  SETTINGS_INTEGRATIONS = 'tab_integrations',
  SETTING_EXPENSE_DEFAULTS = 'expense_defaults',
  VENDOR_PAYMENTS_PAYMENT_SCHEDULE = 'tab_budget_payment_schedule',
  USER_PERMISSIONS = 'tab_user_permissions',
}
