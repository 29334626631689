<ng-container>
  <div *ngIf="defaultChartOptions$ | async as chartOptions" class="w-full">
    <div
      *ngIf="chartOptions"
      class="w-full"
      [ngClass]="{ 'flex items-center': chartOptions.legend.right || chartOptions.legend.left }"
    >
      <div *ngIf="!chartOptions.legend.top; else legendTopBottom"></div>
      <div *ngIf="!chartOptions.legend.left; else legendLeftRight"></div>
      <div style="height: 250px" class="w-full">
        <canvas
          baseChart
          class="chart"
          [data]="chartOptions.data"
          [options]="chartOptions.options"
          [legend]="false"
          [type]="chartOptions.type"
        >
        </canvas>
      </div>
      <div *ngIf="!chartOptions.legend.bottom; else legendTopBottom" class="w-full"></div>
      <div *ngIf="!chartOptions.legend.right; else legendLeftRight"></div>
    </div>
  </div>
</ng-container>

<ng-template #legendTopBottom
  ><div class="flex flex-wrap gap-y-[10px] items-center justify-center space-x-8 mt-4">
    <div
      *ngFor="let color of colors"
      class="flex items-center text-sm"
      (click)="legendAction(color)"
    >
      <div
        *ngIf="color.type === 'bar'"
        class="w-14 h-4 mr-4 border-2"
        [ngStyle]="{
          'background-color': color.color,
          'border-color': color.color
        }"
      ></div>
      <div
        *ngIf="color.type === 'line'"
        class="w-14 h-1 mr-4 rounded-full"
        [ngStyle]="{
          'background-color': color.color,
          'border-color': color.color
        }"
      ></div>
      <div class="text-xs" [ngClass]="{ 'line-through text-aux-gray-dark': color.hide }">
        {{ color.label }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #legendLeftRight
  ><div class="grid justify-start mt-4 ml-1 font-bold">
    <div
      *ngFor="let color of colors"
      class="flex items-center text-sm mb-1"
      (click)="legendAction(color)"
    >
      <div
        *ngIf="color.type === 'bar'"
        class="w-14 h-4 mr-4 border-2"
        [ngStyle]="{
          'background-color': color.color,
          'border-color': color.color
        }"
      ></div>
      <div
        *ngIf="color.type === 'line'"
        class="w-14 h-1 mr-4 rounded-full"
        [ngStyle]="{
          'background-color': color.color,
          'border-color': color.color
        }"
      ></div>
      <div class="text-xs" [ngClass]="{ 'line-through text-aux-gray': color.hide }">
        {{ color.label }}
      </div>
    </div>
  </div>
</ng-template>
