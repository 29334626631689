<div
  class="max-w-3xl w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
  @enterLeave
>
  <div class="p-4 text-white bg-aux-error">
    <div class="flex items-start">
      <div>
        <div class="shrink-0 flex justify-between">
          <button
            id="copyButton"
            class="rounded-md inline-flex hover:outline-none"
            [ngClass]="{
              'focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500': isClicked
            }"
          >
            <aux-icon
              name="Copy"
              class="cursor-pointer"
              [size]="20"
              auxTooltip="Copy content"
              (click)="copyContent()"
            />
          </button>
          <button
            class="rounded-md inline-flex hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            (click)="ref.close()"
          >
            <span class="sr-only">Close</span>
            <aux-icon name="X" [size]="20" />
          </button>
        </div>
        <div class="ml-1 mr-1 flex-1 pt-0.5 max-h-[500px] overflow-y-auto scrollbar">
          <p *ngFor="let message of messages" class="text-sm font-medium">
            {{ message }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
