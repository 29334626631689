<div class="max-w-[372px]">
  <div class="text-sm">
    <p class="mb-5">
      <span class="font-bold underline">Note:</span> Expense account and department names are
      derived from the budget. The program and project for expense accounts are set in the Trial
      Settings. Balance sheet account names and department/program/project can be managed by
      clicking Manage Accounts button on the Journal Entries page.
    </p>

    <p class="font-bold">Rules & Logic:</p>

    <ul class="pl-5 list-decimal">
      <li>
        <span class="font-bold">Accruals:</span> If an Account/Vendor is in an accrual position
        (Accrual LTD), a <span class="underline">debit</span> will be recorded in the corresponding
        Expense account, and a <span class="underline">credit</span> will be recorded in the
        customer's Accrued Liabilities account. This increases the recorded expense to account for
        any unbilled services utilized in period.
      </li>
      <li>
        <span class="font-bold">Prepaids:</span> If an Account/Vendor is in a prepaid position
        (Prepaid LTD), a <span class="underline">credit</span> will be recorded in the corresponding
        Expense account, and a <span class="underline">debit</span> will be recorded in the
        customer's Prepaid Expense account. This credit to the corresponding Expense account will
        net down the cumulative expense recorded to correctly present the expense in period.
        <ng-container *ngIf="isApManualClearingVisible$ | async">
          However, if any invoices received are unpaid as of period end, the Prepaid Expense debit
          amount will be reduced by the unpaid invoice amount, and instead a
          <span class="underline">debit</span> will be recorded in the AP Manual (Clearing) account.
        </ng-container>
      </li>
    </ul>
  </div>

  <hr class="my-4" />

  <div class="flex">
    <button class="ml-auto mr-2 btn--primary" (click)="close()">OK</button>
  </div>
</div>
