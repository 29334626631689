import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

interface AgSelectEditorOptionRendererParams extends ICellRendererParams {
  getDataPendoId: (value: unknown) => string;
}

@Component({
  selector: 'aux-ag-select-editor-option-renderer',
  templateUrl: 'ag-select-editor-option-renderer.component.html',
  styleUrls: ['ag-select-editor-option-renderer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgSelectEditorOptionRendererComponent implements ICellRendererAngularComp {
  params!: AgSelectEditorOptionRendererParams;

  agInit(params: AgSelectEditorOptionRendererParams) {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
