import { Component, ChangeDetectionStrategy } from '@angular/core';
import { of } from 'rxjs';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';
import { ROUTING_PATH } from '../../app-routing-path.const';

@Component({
  selector: 'aux-forecast-accrual-page',
  templateUrl: './forecast-accruals-page.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastAccrualsPageComponent {
  tabs: TabGroupConfig[] = [
    {
      label: 'Forecast Methodology',
      show: of(true),
      route: ROUTING_PATH.FORECAST_ROUTING.FORECAST_METHODOLOGY,
    },
    {
      label: 'Timeline',
      route: ROUTING_PATH.FORECAST_ROUTING.TIMELINE.INDEX,
    },
    {
      label: 'Patient Drivers',
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_drivers),
      route: ROUTING_PATH.FORECAST_ROUTING.PATIENT_DRIVER.INDEX,
    },
    {
      label: 'Site Drivers',
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_drivers),
      route: ROUTING_PATH.FORECAST_ROUTING.SITE_DRIVER.INDEX,
    },
    {
      label: 'Investigator Forecast',
      route: ROUTING_PATH.FORECAST_ROUTING.INVESTIGATOR_FORECAST,
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_investigator_forecast),
    },
    {
      label: 'Study Specifications',
      show: this.launchDarklyService.select$((flags) => flags.tab_forecast_studyspecifications),
      route: ROUTING_PATH.FORECAST_ROUTING.STUDY_SPECIFICATIONS,
    },
  ];

  constructor(private launchDarklyService: LaunchDarklyService) {}
}
