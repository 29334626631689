export const gridData = [
  {
    prop: 'value$',
    description: 'picked button value',
    type: `BehaviorSubject<string>`,
    default: `new BehaviorSubject<string>('')`,
    example: '[value$]="activeTabIndex$"',
  },
  {
    prop: 'items',
    description: 'toggle button list',
    type: 'ButtonToggleItem[]',
    default: '[]',
    example: '[items]="tabs"',
  },
];
