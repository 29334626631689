export enum Converted_File_Document_Types {
  DOCUMENT_SITE_AGREEMENTS = 'Site Contracts',
  DOCUMENT_INVOICE = 'Invoices',
  DOCUMENT_VENDOR_BUDGET = 'Vendor Budgets',
}

export interface ConvertedFilesGridRow {
  document_type: string;
  site: string | undefined;
  converted_file: string;
  converted_file_download_link: string;
  status: string;
  original_file: string;
  original_file_download_link: string;
  date_converted: string;
}
