<div *ngIf="paymentData$ | async as paymentData" class="text-xs mt-4">
  <div class="flex ml-12">
    <div class="flex-initial font-bold text-xxs w-32 text-right pr-1.5 pl-1.5">{{ title }}</div>
    <div
      *ngFor="let data of paymentData"
      class="overflow-hidden text-center tabular-nums flex-1 pr-1.5 pl-1.5"
    >
      {{ data[historyKey] | money }}
    </div>
  </div>
</div>
