import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SiteGroupsStore, SiteGroupsState } from './site-groups.store';

@Injectable({ providedIn: 'root' })
export class SiteGroupsQuery extends QueryEntity<SiteGroupsState> {
  constructor(protected store: SiteGroupsStore) {
    super(store);
  }
}
