<!-- Method, only when the Driver is Time -->
<div class="forecast-table-grid-select-container">
  <ng-select
    class="forecast-table-grid-select"
    [searchable]="false"
    [clearable]="true"
    [appendTo]="'body'"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [auxTooltip]="tooltip"
    [ngClass]="{ 'is-invalid': isInvalid }"
    [ngModel]="selectedForecastMethod"
    (ngModelChange)="onModelChange($event)"
  >
    <ng-option *ngFor="let option of methodOptions" [value]="option.value">
      <span [title]="option.label">{{ option.label }}</span>
    </ng-option>
  </ng-select>
</div>
