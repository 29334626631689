import { Injectable } from '@angular/core';
import { combineQueries, Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { PaymentSchedulesQuery } from '@models/payment-schedules/payment-schedules.query';
import { PatientProtocolType } from '@services/gql.service';
import { map } from 'rxjs/operators';
import { CommonConstants } from '@constants/common.constants';

import { PatientProtocolStore, PatientProtocolState } from './patient-protocol.store';

@QueryConfig({
  sortBy: 'order_by',
  sortByOrder: Order.ASC,
})
@Injectable({ providedIn: 'root' })
export class PatientProtocolQuery extends QueryEntity<PatientProtocolState> {
  private commonConstants = CommonConstants;

  constructor(
    protected store: PatientProtocolStore,
    private paymentSchedulesQuery: PaymentSchedulesQuery
  ) {
    super(store);
  }

  getPatientProtocolsBasedOnType(
    types: PatientProtocolType[],
    site_id: string,
    patient_group_id?: string
  ) {
    return combineQueries([
      this.selectAll({
        filterBy: (entity) => types.includes(entity.patient_protocol_type),
      }),
      this.paymentSchedulesQuery.selectAll({
        filterBy: (paymentSchedule) => paymentSchedule.site_id === site_id,
      }),
    ]).pipe(
      map(([patientProtocols, paymentSchedules]) => {
        const filterPaymentSchedules = (patient_protocol_id: string) =>
          paymentSchedules.filter(
            (paymentSchedule) => paymentSchedule.patient_protocol_id === patient_protocol_id
          );

        return (
          patient_group_id
            ? patientProtocols.filter((protocol) => {
                return patient_group_id === this.commonConstants.PATIENT_VISITS_GROUP_ID
                  ? !protocol.patient_group_id
                  : protocol.patient_group_id === patient_group_id;
              })
            : patientProtocols
        ).map((patientProtocol) => {
          const paymentSchedule = filterPaymentSchedules(patientProtocol.id)?.[0];

          return {
            ...patientProtocol,
            amount: paymentSchedule?.amount,
            note: paymentSchedule?.note,
            paymentScheduleId: paymentSchedule?.id,
          };
        });
      })
    );
  }
}
