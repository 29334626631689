import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { GenericTrialInsightsComponent } from '../../classes/trial-insights-component.class';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  TIRSChartLabel,
  TIRSChartType,
  TrialInsightsRemainingSpendChartService,
} from './remaining-spend-over-time-chart.service';
import { TrialInsightsRemainingSpendQueryService } from './remaining-spend-over-time-query.service';
import { TrialInsightsRemainingSpendStoreService } from './remaining-spend-over-time-store.service';
import { TrialInsightsRemainingSpendTableService } from './remaining-spend-over-time-table.service';

@UntilDestroy()
@Component({
  selector: 'aux-trial-insights-finance-remaining-spend-over-time',
  templateUrl: './trial-insights-finance-remaining-spend-over-time.component.html',
  styleUrls: [
    '../../trial-insights.component.scss',
    './trial-insights-finance-remaining-spend-over-time.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsFinanceRemainingSpendOverTimeComponent extends GenericTrialInsightsComponent<
  TIRSChartType,
  TIRSChartLabel
> {
  constructor(
    public chartService: TrialInsightsRemainingSpendChartService,
    public tableService: TrialInsightsRemainingSpendTableService,
    public storeService: TrialInsightsRemainingSpendStoreService,
    public queryService: TrialInsightsRemainingSpendQueryService,
    public cdr: ChangeDetectorRef
  ) {
    super({
      title: 'Remaining Spend',
      color: '#094673',
      chartService,
      tableService,
      queryService,
      cdr,
    });
  }

  subscribeToData = () => {
    this.storeService.getRemainingSpend$().pipe(untilDestroyed(this)).subscribe();
    this.queryService.processRemainingSpend$().pipe(untilDestroyed(this)).subscribe();
  };
}
