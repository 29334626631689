<div class="flex items-center mb-8">
  <!-- Header -->
  <div class="flex flex-col flex-grow">
    <span class="text-2xl font-bold">Welcome, {{ userName }}</span>
    <span class="text-base">{{ userOrganization }}</span>
  </div>

  <!-- Nav -->
  <div class="trial-insights-nav-container">
    <a
      *ngIf="showClinOpsButton$ | async"
      class="trial-insights-nav"
      [routerLink]="clinicalRouterLink"
      [routerLinkActive]="['active']"
    >
      ClinOps
    </a>

    <a
      *ngIf="showFinanceButton$ | async"
      class="trial-insights-nav"
      [routerLink]="financeRouterLink"
      [routerLinkActive]="['active']"
    >
      Finance
    </a>
  </div>
</div>
