import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import {
  DepartmentType,
  GqlService,
  RoleType,
  UserPermissionInput,
  UserTrialInput,
} from '@services/gql.service';

import { TrialUserStore } from './trial-user.store';
import { MainQuery } from '../../layouts/main-layout/state/main.query';
import { firstValueFrom } from 'rxjs';

const mapPermissionArrToObj = (
  arr: {
    permission_id: string;
    permission_roles: string;
  }[]
) => {
  const obj: { [k: string]: 'E' | 'U' } = {};
  arr.forEach((x) => {
    obj[x.permission_id] = x.permission_roles as 'E' | 'U';
  });
  return obj;
};

@Injectable({ providedIn: 'root' })
export class TrialUserService {
  constructor(
    private trialUserStore: TrialUserStore,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private gqlService: GqlService
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.trialUserStore.setLoading(true);
        this.trialUserStore.remove(() => true);

        return this.gqlService.listUsers$(false).pipe(
          tap((x) => {
            if (x.data) {
              const newData = x.data.map((y) => {
                const arr = y.permissions;
                const permissions = mapPermissionArrToObj(arr);
                return {
                  ...y,
                  permissions,
                };
              });
              this.trialUserStore.set(newData);
            }
            this.trialUserStore.setLoading(false);
          })
        );
      })
    );
  }

  async add({
    email,
    family_name,
    given_name,
    department,
    organization_id,
    title,
    trial_id,
    role,
  }: {
    given_name: string;
    family_name: string;
    email: string;
    title: string;
    organization_id: string;
    trial_id: string;
    department: DepartmentType[];
    role: RoleType;
  }) {
    this.trialUserStore.setLoading(true);

    const { data, errors, success } = await firstValueFrom(
      this.gqlService.createUser$({
        given_name,
        family_name,
        email,
        title,
      })
    );
    if (success && data) {
      const {
        data: data2,
        errors: trialErrors,
        success: trialSuccess,
      } = await this.addToTrial({
        trial_id,
        user_id: data.sub,
        department,
        organization_id,
        given_name,
        family_name,
        title,
        role,
        user_email: email,
      });
      if (trialSuccess && data2) {
        this.trialUserStore.add({
          __typename: 'User',
          email,
          family_name,
          given_name,
          organization: data2.organization,
          department: data2.department,
          sub: data.sub,
          title,
          permissions: mapPermissionArrToObj(
            data2.permissions as {
              permission_id: string;
              permission_roles: 'E' | 'U';
            }[]
          ),
          last_login_date: data2.last_login_date,
          role: data2.role,
        });
        this.trialUserStore.setLoading(false);

        return true;
      }
      // eslint-disable-next-line no-alert
      alert(trialErrors);
    } else {
      // eslint-disable-next-line no-alert
      alert(errors);
    }

    this.trialUserStore.setLoading(false);
    return false;
  }

  async reinvite(email: string) {
    this.trialUserStore.setLoading(true);

    const { data, errors, success } = await firstValueFrom(
      this.gqlService.resendUserInvite$({ email })
    );
    if (success && data) {
      this.overlayService.success('User successfully reinvited');
    } else {
      this.overlayService.error(errors);
    }

    this.trialUserStore.setLoading(false);
    return false;
  }

  async addToTrial({
    organization_id,
    given_name,
    family_name,
    title,
    department,
    role,
    trial_id,
    user_id,
    user_email,
  }: UserTrialInput) {
    return firstValueFrom(
      this.gqlService.addUserToTrial$({
        trial_id,
        department,
        user_id,
        organization_id,
        given_name,
        family_name,
        title,
        role,
        user_email,
      })
    );
  }

  async update({
    email,
    sub,
    title,
    given_name,
    department,
    family_name,
    role,
    organization_id,
  }: Omit<UserTrialInput, 'user_id' | 'trial_id'> & { sub: string; email: string }) {
    const { success, errors, data } = await this.addToTrial({
      organization_id,
      given_name,
      department,
      family_name,
      title,
      role,
      trial_id: this.mainQuery.getValue().trialKey,
      user_id: sub as string,
    });

    if (success && data) {
      this.trialUserStore.update(sub, {
        role,
        sub,
        family_name,
        given_name,
        department: data.department,
        organization: data.organization,
        title,
        email,
      });
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors, data };
  }

  async updatePermissions(
    values: {
      sub: string;
      permission: string;
      value: boolean;
    }[]
  ) {
    this.trialUserStore.setLoading(true);
    const trial_id = this.mainQuery.getValue().trialKey;
    const input = values.map(({ sub, value, permission }) => {
      return <UserPermissionInput>{
        trial_id,
        user_id: sub,
        permission_id: permission,
        permission_roles: value ? 'E' : 'U',
      };
    });

    const { success, data, errors } = await firstValueFrom(
      this.gqlService.batchUpdateUserPermissions$(input)
    );

    if (success && data) {
      values.forEach((v) => {
        this.trialUserStore.update(v.sub, ({ permissions }) => {
          return {
            permissions: { ...permissions, [v.permission]: v.value ? 'E' : 'U' },
          };
        });
      });
    } else {
      console.error(errors);
    }

    this.trialUserStore.setLoading(false);
  }

  async remove(user_id: string) {
    const { success, errors } = await firstValueFrom(this.gqlService.removeUserFromTrial$(user_id));

    if (success) {
      this.trialUserStore.remove(user_id);
      this.overlayService.success('User successfully removed');
    } else {
      this.overlayService.error(['User could not be removed', ...errors]);
    }

    return { success, errors };
  }
}
