<!-- Method, only when the Driver is Site -->
<div class="forecast-table-grid-select-container">
  <ng-select
    #dropdown
    class="forecast-table-grid-select"
    [searchable]="false"
    [clearable]="false"
    [appendTo]="'ag-grid-angular'"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [auxTooltip]="tooltip"
    [ngClass]="{ 'is-invalid': isInvalid }"
    [(ngModel)]="driverSettingId"
    (open)="onDropdownOpen()"
    (close)="onDropdownClose()"
    (ngModelChange)="onModelChange($event)"
  >
    <ng-option
      *ngFor="let item of filteredSiteCurves; index as index; let first = first"
      [value]="item.value"
    >
      {{ item.label }}
    </ng-option>
    <ng-template ng-footer-tmp class="flex flex-col">
      <div class="items-wrapper grow">
        <ng-option
          *ngFor="let item of filteredSiteCurves; let first = first; let last = last"
          [value]="item.value"
        >
          <div
            *ngIf="item.showLine && !first"
            class="border-b h-1 border-aux-gray-darkest my-1 mx-1"
          ></div>
          <div
            class="relative flex justify-between cursor-pointer select-none py-3 pl-3 -mx-2 option-font"
            [class.selected-item]="driverSettingId === item.value"
            [class.first-item]="first"
            [class.last-item]="last"
            [class.hover-item]="driverSettingId !== item.value"
            (click)="onModelChange(item.value); dropdown.close()"
          >
            <div class="flex-1">
              {{ item.label }}
            </div>
            <aux-icon
              *ngIf="dropdownOpen && driverSettingId === item.value"
              class="checkmark mr-2"
              name="Check"
              [size]="20"
            />
          </div>
        </ng-option>
      </div>
    </ng-template>
  </ng-select>
</div>
