import { Pipe, PipeTransform } from '@angular/core';
import { first, last } from 'lodash-es';
import { Utils } from '@services/utils';

@Pipe({
  name: 'hideLastName',
  standalone: true,
})
export class HideLastNamePipe implements PipeTransform {
  getInitialLetter(str: string) {
    return `${str[0].toUpperCase()}.`;
  }

  transform(value?: string): string {
    if (!value) {
      return '';
    }
    if (value === 'Auxilius Expert' || value === Utils.zeroHyphen) {
      return value;
    }
    const arr = value.split(' ').filter((str) => str);
    return `${first(arr)} ${this.getInitialLetter(last(arr) || '')}`;
  }
}
