import { Component, computed, signal } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';
import { ComponentsModule } from '@components/components.module';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { BeActivitiesModalRowData } from './be-activities-modal.component';
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  GetRowIdFunc,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from '@ag-grid-community/core';
import { OverlayService } from '@services/overlay.service';
import { TableConstants } from '@constants/table.constants';
import {
  AgInlineHeaderComponent,
  AgInlineHeaderComponentParams,
} from './ag-inline-header.component';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { firstValueFrom } from 'rxjs';
import { AgTooltipComponent } from './ag-tooltip.component';
import { FormsModule } from '@angular/forms';
import { WarningModalComponent, WarningModalParams } from './warning-modal.component';
import { AgGridAngular } from '@ag-grid-community/angular';

export interface BeAttributesModalParams {
  rows: BeActivitiesModalRowData[];
  vendor: {
    id: string;
    label: string;
  };
  attributes: {
    attribute_name: string;
    attribute_value: string;
  }[];
}

const ERROR_TABLE_TOOLTIP = 'Please resolve errors to continue';

@Component({
  standalone: true,
  template: `
    <div class="hidden absolute sm:block top-0 right-0 pr-4 pt-4">
      <button
        id="closeButton"
        type="button"
        class="bg-white flex rounded-md text-gray-400 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        (click)="close('close', false)"
      >
        <span class="sr-only">Close</span>
        <aux-icon name="X" />
      </button>
    </div>

    <div class="text-2xl font-bold absolute pt-4 top-0">Edit Attributes</div>
    <div class="w-[1200px] be-attributes-container my-[32px] relative">
      <div class="flex items-center justify-between mb-[32px]">
        <div>
          <ng-select
            [(ngModel)]="selectedVendor"
            bindLabel="label"
            bindValue="id"
            [disabled]="true"
            [items]="vendors"
          />
        </div>
        <div>
          <aux-button
            variant="secondary"
            (clickEmit)="addNewColumn()"
            icon="Plus"
            label="Add New Attribute"
            classList="!border-none h-[35px]"
            [disabled]="addNewColumnDisabled"
          />
        </div>
      </div>
      <ag-grid-angular
        style="height: 500px"
        class="ag-theme-aux tabular-nums be-attributes-table"
        [rowData]="filteredRowData"
        (gridReady)="onGridReady($event)"
        [gridOptions]="gridOptions"
        [getRowId]="getRowId"
        (cellValueChanged)="onCellChanged($event)"
      />
    </div>

    <div class="flex items-center justify-between">
      <div>
        <aux-button
          label="Cancel"
          variant="hyperlink"
          classList="no-underline text-aux-gray-darkest"
          (clickEmit)="close('close', false)"
        />
      </div>
      <div>
        <aux-button
          class="mr-[16px]"
          label="Go Back"
          variant="secondary"
          (clickEmit)="close('edit', false)"
        />
        <aux-button
          label="Save & Update"
          variant="primary"
          [disabled]="!atLeastOneRowHasChanged() || doesHeaderHasError()"
          [auxTooltip]="
            !atLeastOneRowHasChanged()
              ? emptyRowMsg
              : doesHeaderHasError()
                ? headerErrorTooltip
                : ''
          "
          (clickEmit)="close('save')"
        />
      </div>
    </div>
  `,
  imports: [IconComponent, ComponentsModule, FormsModule, AgGridAngular],
  styles: [
    `
      ::ng-deep .be-attributes-container .ng-select {
        @apply w-[254px];
      }
      ::ng-deep .be-attributes-container .ng-select.ng-select-disabled > .ng-select-container {
        @apply bg-aux-gray-light-2 text-aux-gray-dark-1;
      }
      ::ng-deep .be-attributes-container .ng-select .ng-arrow {
        @apply border-t-aux-gray-darkest;
      }
      ::ng-deep .be-attributes-container .ag-cell:first-child {
        @apply !border-l-[1px];
      }
      ::ng-deep .be-attributes-container .ag-cell:first-child {
        @apply !border-r-[1px];
      }
      ::ng-deep .be-attributes-container .ag-cell.ag-cell-inline-editing {
        @apply p-0;
      }
      ::ng-deep .be-attributes-container .error-header {
        @apply !border-aux-error !border-[1.5px];
      }
      ::ng-deep .be-attributes-container .ag-row-last .ag-cell {
        @apply !border-b-[1px];
      }
      ::ng-deep .be-attributes-container .ag-header {
        height: 64px !important;
        min-height: 64px !important;
      }
      ::ng-deep .be-attributes-container .ag-header-row,
      ::ng-deep .be-attributes-container .ag-header-cell {
        overflow: unset;
      }
      ::ng-deep .be-attributes-container .ag-header-cell-text {
        white-space: nowrap !important;
      }
      ::ng-deep .be-attributes-container .ag-header-group-cell-no-group {
        @apply hidden;
      }
    `,
  ],
})
export class BeAttributesModalComponent {
  rowData: BeActivitiesModalRowData[];
  filteredRowData: BeActivitiesModalRowData[];

  gridAPI!: GridApi<BeActivitiesModalRowData>;
  data: BeAttributesModalParams;
  columns: ColGroupDef[];
  gridOptions: GridOptions;

  emptyRowMsg = 'Fill out at least 1 attribute to Save';

  newColumnCounter = 0;

  addNewColumnDisabled = false;

  headerNames: Record<string, string> = {};

  originalHeaders = '{}';

  headerErrorTooltip = ERROR_TABLE_TOOLTIP;

  headerErrorState = signal<Record<string, boolean>>({});

  doesHeaderHasError = computed(() => {
    return Object.values(this.headerErrorState()).some((z) => z);
  });

  state = signal<Record<string, Record<string, string>>>({});

  atLeastOneRowHasChanged = computed(() => {
    const bool = !!(this.deletedColumns().length || this.renamedColumns().length);

    return bool || !!Object.keys(this.state()).length;
  });

  addedColumns: string[] = [];
  deletedColumns = signal<string[]>([]);
  renamedColumns = signal<string[]>([]);

  originalColumns: string[] = [];

  disableCanDeactivate = false;

  selectedVendor: string;
  vendors: { id: string; label: string }[];

  constructor(
    public ref: CustomOverlayRef<
      {
        type: 'close' | 'save' | 'edit';
        rows: BeActivitiesModalRowData[];
        deletedColumns: string[];
        renamedColumns: { oldValue: string; newValue: string }[];
      },
      BeAttributesModalParams
    >,
    private overlayService: OverlayService
  ) {
    if (!this.ref.data) {
      throw new Error("You didn't pass data to BeActivitiesModal");
    }

    this.ref.canDeactivate = this.canDeactivate;

    this.data = this.ref.data;
    this.rowData = this.data.rows;
    this.filteredRowData = this.rowData
      .filter((row) => !row.deleted)
      .slice(0)
      .map((x) => ({ ...x, attributes: { ...x.attributes } }));

    this.vendors = [this.data.vendor];
    this.selectedVendor = this.data.vendor.id;

    this.columns = [
      {
        headerName: 'Attributes',
        headerClass: 'ag-header-align-center',
        children: [
          {
            headerName: 'Activity',
            headerClass: 'ag-header-align-center text-sm',
            field: 'activity_name',
            tooltipField: 'activity_name',
            editable: false,
            cellClass: '!block truncate text-left',
          },
        ],
      },
    ];

    // todo get all attribute options here
    // const options: Record<string, string[]> = {};
    this.data.attributes.forEach((attr) => {
      const field = `attributes.custom_attr_${btoa(attr.attribute_name)}`;
      this.columns[0].children.push(
        this.getAttributeColumn({
          field,
          headerName: attr.attribute_name,
          autoFocus: false,
        })
      );
      this.headerNames[field] = attr.attribute_name;
      this.originalColumns.push(field);
    });

    this.originalHeaders = JSON.stringify(this.headerNames);

    this.isAddNewColumnDisabled();

    this.gridOptions = <GridOptions>{
      ...TableConstants.DEFAULT_GRID_OPTIONS.EDIT_GRID_OPTIONS,
      tooltipShowDelay: 0,
      headerHeight: 32,
      stopEditingWhenCellsLoseFocus: true,
      defaultColDef: {
        editable: true,
        suppressMenu: true,
        suppressMovable: true,
        tooltipComponent: AgTooltipComponent,
      },
      rowClassRules: {},
      enableFillHandle: true,
      enableRangeSelection: true,
      columnDefs: this.columns,
    };
  }

  canDeactivate = async () => {
    return this.disableCanDeactivate ? true : await this.openUnsavedChanges();
  };

  async openUnsavedChanges() {
    if (
      this.atLeastOneRowHasChanged() ||
      this.addedColumns.length ||
      this.renamedColumns.length ||
      this.deletedColumns.length
    ) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event?.data;
    }
    return true;
  }

  close(type: 'close' | 'edit' | 'save', force = true) {
    this.disableCanDeactivate = force;

    const headers: Record<string, string> = JSON.parse(this.originalHeaders);

    const deletedColumns: string[] = [];
    const renamedColumns: { oldValue: string; newValue: string }[] = [];

    Object.entries(headers).forEach(([key, value]) => {
      if (key in this.headerNames) {
        if (value !== this.headerNames[key]) {
          renamedColumns.push({ oldValue: value, newValue: this.headerNames[key] });
        }
      } else {
        deletedColumns.push(value);
      }
    });

    this.ref.close({
      deletedColumns,
      renamedColumns,
      rows: type === 'save' ? this.getRowData() : this.rowData,
      type,
    });
  }

  getRowData() {
    const state = this.state();
    return this.rowData.map((row) => {
      let attributes = row.attributes;
      const obj: Record<string, string> = {};
      Object.entries(this.headerNames).forEach(([key, value]) => {
        obj[value] =
          state[row.id]?.[key] != null
            ? state[row.id][key]
            : attributes[key.replace('attributes.', '')] || '';
      });
      attributes = obj;

      return {
        ...row,
        attributes,
        changed: row.changed || !!state[row.id],
      };
    });
  }

  isAddNewColumnDisabled() {
    this.addNewColumnDisabled = this.columns[0].children.length > 6;
  }

  getRowId: GetRowIdFunc<BeActivitiesModalRowData> = (d) => {
    return d.data.id;
  };

  getAttributeColumn = ({
    field,
    headerName = '',
    autoFocus,
  }: {
    field: string;
    headerName?: string;
    autoFocus: boolean;
  }) => {
    return <ColDef>{
      headerName,
      headerClass: () => {
        const name = this.headerNames[field];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [field]: _, ...rest } = this.headerNames;

        const bool = !name || Object.values(rest).includes(name);
        this.headerErrorState.update((state) => {
          return {
            ...state,
            [field]: bool,
          };
        });

        return ['ag-header-align-center text-sm !p-0', bool ? 'error-header' : ''];
      },
      field,
      headerComponent: AgInlineHeaderComponent,
      suppressHeaderKeyboardEvent: () => {
        return true;
      },
      tooltipField: field,
      headerComponentParams: <AgInlineHeaderComponentParams>{
        autoFocus,
        getHeaderNames: () => this.headerNames,
        onBlur: (name) => {
          if (this.originalColumns.includes(field) && this.headerNames[field] != name) {
            this.renamedColumns.update((c) => {
              return [...c.filter((f) => f != field), field];
            });
          }
          this.headerNames[field] = name;
          this.gridAPI.setGridOption('columnDefs', [...this.columns]);
        },
        onRemoveColumn: async () => {
          if (!autoFocus) {
            const ref = this.overlayService.open<'cancel' | 'confirm', WarningModalParams>({
              baseComponent: WarningModalComponent,
              data: {
                header: 'Delete Attribute?',
                message:
                  'Deleting this attribute column will delete the attribute column for the entire vendor budget. Are you sure you want to continue?',
                maxWidth: 455,
                cancelLabel: 'Go Back',
                confirmLabel: 'Delete',
              },
            });

            const { data: refData } = await firstValueFrom(ref.afterClosed$);

            if (refData !== 'confirm') {
              return;
            }
          }

          const [def] = this.columns;
          def.children = def.children.filter((c) => (c as ColDef).field !== field);
          this.columns = [def];
          this.gridAPI?.setGridOption('columnDefs', [...this.columns]);
          this.gridAPI.sizeColumnsToFit();
          this.isAddNewColumnDisabled();
          this.addedColumns = this.addedColumns.filter((f) => f !== field);
          if (this.originalColumns.includes(field)) {
            this.renamedColumns.update((c) => {
              return c.filter((f) => f != field);
            });
            this.deletedColumns.update((c) => {
              return [...c, field];
            });
          }
          delete this.headerNames[field];
          this.headerErrorState.update((state) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [field]: _, ...rest } = state;
            return {
              ...rest,
            };
          });

          this.gridAPI.forEachNode((n) => {
            const data = n.data;
            if (data) {
              this.state.update((state) => {
                const row = state[data.id] || {};
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [field]: _, ...rest } = row;
                if (Object.keys(rest).length) {
                  return {
                    ...state,
                    [data.id]: {
                      ...rest,
                    },
                  };
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const { [data.id]: __, ...restRows } = state;
                  return restRows;
                }
              });
            }
          });
        },
      },
      cellClass: '!block truncate text-left',
    };
  };

  addNewColumn() {
    const headerName = `NAME${++this.newColumnCounter}`;
    const field = `attributes.custom_attr_${headerName}`;
    this.headerNames[field] = '';
    this.columns[0].children.push(
      this.getAttributeColumn({
        field,
        autoFocus: true,
      })
    );
    this.gridAPI.setGridOption('columnDefs', this.columns);
    this.gridAPI.sizeColumnsToFit();
    this.addedColumns.push(field);
    this.isAddNewColumnDisabled();

    this.gridAPI.forEachNode((n) => {
      if (n.data) {
        const property = field.replace('attributes.', '');
        n.updateData({
          ...n.data,
          attributes: { ...n.data.attributes, [property]: '' },
        });
        this.updateState(n.data.id, field, '');
      }
    });
  }

  onGridReady(event: GridReadyEvent<BeActivitiesModalRowData>) {
    this.gridAPI = event.api;
    this.gridAPI.sizeColumnsToFit();
  }

  onCellChanged(event: CellValueChangedEvent<BeActivitiesModalRowData>) {
    const rowData = event.data;
    const colID = event.column.getColId();
    this.updateState(rowData.id, colID, event.newValue || '');
  }

  updateState(id: string, colID: string, value: '') {
    this.state.update((state) => {
      const row = state[id] || {};
      // if we want to remove the changes when the user deletes their changes
      // if (event.newValue === '') {
      //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
      //   const { [colID]: _, ...rest } = row;
      //   if (Object.keys(rest).length) {
      //     return {
      //       ...state,
      //       [rowData.id]: {
      //         ...rest,
      //       },
      //     };
      //   } else {
      //     const { [rowData.id]: __, ...restRows } = state;
      //     return restRows;
      //   }
      // }
      return {
        ...state,
        [id]: {
          ...row,
          [colID]: value,
        },
      };
    });
  }
}
