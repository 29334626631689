import { Injectable } from '@angular/core';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { switchMap, map, tap } from 'rxjs/operators';
import {
  CreatePurchaseOrderInput,
  GqlService,
  InvoiceStatus,
  UpdatePurchaseOrderInput,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { NoNullFields } from '@services/utils';
import { firstValueFrom } from 'rxjs';

import { PurchaseOrdersStore } from './purchase-orders.store';

@Injectable({ providedIn: 'root' })
export class PurchaseOrdersService {
  constructor(
    private purchaseOrdersStore: PurchaseOrdersStore,
    private mainQuery: MainQuery,
    private gqlService: GqlService,
    private overlayService: OverlayService
  ) {}

  get() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.purchaseOrdersStore.setLoading(true);
        return this.gqlService.listPO$().pipe(
          tap(({ success, errors, data }) => {
            if (success && data) {
              this.purchaseOrdersStore.set(
                data.map((po) => {
                  const obj = {
                    paid_amount: 0,
                    received_amount: 0,
                    organization_name: po.organization.name,
                    organization_id: po.organization.id,
                    currency: po.organization.currency,
                  };
                  po.invoice_summaries.forEach((invoice_summary) => {
                    switch (invoice_summary.invoice_status) {
                      case InvoiceStatus.STATUS_IN_QUEUE:
                      case InvoiceStatus.STATUS_PENDING_REVIEW:
                      case InvoiceStatus.STATUS_PENDING_APPROVAL:
                        obj.received_amount += invoice_summary.total_amount || 0;
                        break;
                      case InvoiceStatus.STATUS_APPROVED:
                        obj.paid_amount += invoice_summary.total_amount || 0;
                        break;
                      default:
                        break;
                    }
                  });

                  return {
                    ...po,
                    ...obj,
                    received_percentage:
                      po.po_amount === 0
                        ? 0
                        : Math.round((obj.received_amount / po.po_amount) * 100),
                    paid_percentage: Math.round((obj.paid_amount / po.po_amount) * 100),
                  };
                })
              );
            } else {
              this.overlayService.error(errors);
            }
          })
        );
      })
    );
  }

  listPONumbers() {
    return this.gqlService.listPONumbers$().pipe(
      map((x) => {
        return { ...x, data: x.data ? x.data : null };
      })
    );
  }

  async add(input: CreatePurchaseOrderInput) {
    const { success, errors, data } = await firstValueFrom(this.gqlService.createPO$(input));
    if (success && data) {
      this.purchaseOrdersStore.add({
        ...data,
        received_amount: 0,
        paid_amount: 0,
        paid_percentage: 0,
        received_percentage: 0,
        organization_name: data.organization.name,
        organization_id: data.organization.id,
        currency: data.organization.currency,
      });
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors, data };
  }

  async update(input: NoNullFields<Required<UpdatePurchaseOrderInput>>) {
    const { success, errors, data } = await firstValueFrom(this.gqlService.updatePO$(input));

    if (success && data) {
      this.purchaseOrdersStore.update(input.id, {
        po_amount: input.po_amount,
        po_number: input.po_number,
        organization_id: input.organization_id,
        organization_name: data.organization.name,
        currency: data.organization.currency,
      });
    } else {
      this.overlayService.error(errors);
    }

    return { success, errors, data };
  }

  remove(id: string) {
    this.purchaseOrdersStore.remove(id);
  }

  async deletePurchaseOrder(id: string) {
    this.purchaseOrdersStore.setLoading(true);

    const { success, data, errors } = await firstValueFrom(this.gqlService.removePO$(id));

    if (success && data) {
      this.purchaseOrdersStore.remove(id);
      this.overlayService.success('Successfully removed!');
    } else {
      this.overlayService.error(errors);
    }
    this.purchaseOrdersStore.setLoading(false);
  }
}
