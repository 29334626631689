import { GridOptions } from '@ag-grid-community/core';

export const gridOptions: GridOptions = {
  headerHeight: 45,
  defaultColDef: {
    wrapText: true,
    autoHeight: true,
    cellClass: 'text-left',
    rowDrag: false,
  },
  columnDefs: [
    {
      headerName: 'Property',
      headerClass: 'ag-header-align-center font-bold',
      field: 'prop',
      suppressMenu: true,
    },
    {
      headerName: 'Description',
      headerClass: 'ag-header-align-center font-bold',
      field: 'description',
      suppressMenu: true,
      width: 550,
    },
    {
      headerName: 'Type',
      headerClass: 'ag-header-align-center font-bold',
      field: 'type',
      suppressMenu: true,
    },
    {
      headerName: 'Default value',
      headerClass: 'ag-header-align-center font-bold',
      field: 'default',
      suppressMenu: true,
    },
    {
      headerName: 'Example',
      headerClass: 'ag-header-align-center font-bold',
      field: 'example',
      suppressMenu: true,
      width: 300,
    },
  ],
};
