import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TrialInsightsService } from './services/trial-insights.service';
import { TrialInsightsStore } from './store/trial-insights.store';
import { TimelineService } from '../forecast-accruals-page/tabs/timeline-group/timeline/state/timeline.service';
import { Observable } from 'rxjs';
import { LaunchDarklyService } from '@services/launch-darkly.service';

@UntilDestroy()
@Component({
  templateUrl: './trial-insights.component.html',
  styleUrls: ['./trial-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsComponent implements OnInit, OnDestroy {
  showFinanceButton$: Observable<boolean>;

  showClinOpsButton$: Observable<boolean>;

  constructor(
    private trialInsights: TrialInsightsService,
    private store: TrialInsightsStore,
    private timelineService: TimelineService,
    private launchDarklyService: LaunchDarklyService
  ) {
    this.showFinanceButton$ = this.launchDarklyService.select$((flags) => flags.finance_dashboard);
    this.showClinOpsButton$ = this.launchDarklyService.select$((flags) => flags.clin_ops_dashboard);
  }

  ngOnInit(): void {
    this.trialInsights.changeBackground(true);

    this.timelineService.getTimelineItems().pipe(untilDestroyed(this)).subscribe();
  }

  ngOnDestroy(): void {
    this.trialInsights.changeBackground(false);
    this.store.reset();
  }
}
