import { Injectable } from '@angular/core';
import { listActualPatientVisitSettingsQuery } from '@services/gql.service';
import { PatientVisitsValues } from '../investigator-forecast.types';
import {
  decimalAdd,
  decimalDifference,
  decimalDivide,
  decimalMultiply,
  decimalRoundingToNumber,
} from '@shared/utils';
import { Utils } from '@services/utils';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvestigatorPatientVisits {
  private precision = 2;

  editMode$ = new BehaviorSubject<boolean>(false);

  private toPercent(value: number): number {
    return decimalRoundingToNumber(value, this.precision);
  }

  getForecastedRemainingInvoiceables(
    invoiceablesPerc: number,
    forecastedRemainingVisitCosts: number
  ) {
    return decimalMultiply(invoiceablesPerc, forecastedRemainingVisitCosts, 4);
  }

  getForecastedRemainingInvestigatorCosts(
    forecastedRemainingVisitCosts: number,
    forecastedRemainingInvoiceables: number
  ) {
    return decimalAdd(forecastedRemainingVisitCosts, forecastedRemainingInvoiceables);
  }

  getTotalDiscontinued(discontinuedDifference: number, patientsRemainingToDiscontinue: number) {
    return decimalMultiply(
      discontinuedDifference,
      Utils.roundToNumber(patientsRemainingToDiscontinue, 2),
      4
    );
  }

  getPatientRemainingToDiscontinueAmount(
    totalDiscontinuedPerc: number,
    currentDiscontinuedPerc: number,
    currentEnrolledAmount: number
  ) {
    const remainingToDiscontinue = decimalMultiply(
      decimalDifference(totalDiscontinuedPerc, currentDiscontinuedPerc),
      currentEnrolledAmount,
      4
    );

    return remainingToDiscontinue >= 0 ? remainingToDiscontinue : 0;
  }

  getPatientRemainingToDiscontinueAmountLeft(
    currentEnrolledAmount: number,
    discontinuedPercent: number
  ) {
    return decimalMultiply(currentEnrolledAmount, discontinuedPercent, this.precision);
  }

  getDiscontinuedDifference(
    averageCompletedPatientCost: number,
    remainingPatientsToDiscontinueCost: number
  ) {
    return decimalDifference(
      averageCompletedPatientCost,
      remainingPatientsToDiscontinueCost,
      this.precision
    );
  }

  getRemainingPatientsToDiscontinueCost(
    remainingPatientsToDiscontinuePerc: number,
    averageCompletedPatientCost: number
  ) {
    return decimalMultiply(
      remainingPatientsToDiscontinuePerc,
      averageCompletedPatientCost,
      this.precision
    );
  }

  getTotalForecastedRemainingInvestigatorCosts(
    forecastedRemainingInvestigatorCosts: number,
    totalDiscontinued: number
  ) {
    return decimalDifference(
      forecastedRemainingInvestigatorCosts,
      totalDiscontinued,
      this.precision
    );
  }

  getTotalForecastedInvestigatorAmountThroughEndOfTrial(
    totalForecastedRemainingInvestigatorCosts: number,
    totalSpendToDate: number
  ) {
    return decimalAdd(totalForecastedRemainingInvestigatorCosts, totalSpendToDate, this.precision);
  }

  getInitialSummaryValues(
    patientDataList: listActualPatientVisitSettingsQuery[],
    spendToDate: number
  ): PatientVisitsValues {
    const patientVisitData = patientDataList[0]; // last updated values

    const invoiceablesPerc = decimalDivide(patientVisitData.invoiceables_percent || 0, 100);

    const forecastedRemainingInvoiceables = this.getForecastedRemainingInvoiceables(
      invoiceablesPerc,
      patientVisitData.forecasted_remaining_visit_costs
    );

    const forecastedRemainingInvestigatorCosts = this.getForecastedRemainingInvestigatorCosts(
      patientVisitData.forecasted_remaining_visit_costs,
      forecastedRemainingInvoiceables
    );

    const invoiceablesDistribution = decimalDifference(100, patientVisitData.overhead_percent || 0);

    // discontinued
    const averageCompletedPatientCost = decimalRoundingToNumber(
      patientVisitData.average_completed_patient_cost,
      this.precision
    );

    const remainingPatientsToDiscontinuePerc = this.toPercent(
      Math.round(patientVisitData.remaining_patients_to_discontinue_percent || 0)
    );

    const remainingPatientsToDiscontinueCost = this.getRemainingPatientsToDiscontinueCost(
      decimalDivide(remainingPatientsToDiscontinuePerc, 100),
      averageCompletedPatientCost
    );

    const totalDiscontinuedPerc = decimalDivide(patientVisitData.discontinued_percent || 0, 100);

    const currentDiscontinuedPerc = decimalDivide(
      patientVisitData.current_discontinued_percent || 0,
      100
    );

    const currentEnrolledAmount = patientVisitData.current_enrolled_amount || 0;

    const patientRemainingToDiscontinueAmount = this.getPatientRemainingToDiscontinueAmount(
      totalDiscontinuedPerc,
      currentDiscontinuedPerc,
      currentEnrolledAmount
    );

    const patientRemainingToDiscontinueAmountLeft = this.getPatientRemainingToDiscontinueAmountLeft(
      currentEnrolledAmount,
      totalDiscontinuedPerc
    );

    const differentPatientCost = this.getDiscontinuedDifference(
      averageCompletedPatientCost,
      remainingPatientsToDiscontinueCost
    );

    const totalDiscontinued = this.getTotalDiscontinued(
      differentPatientCost,
      patientRemainingToDiscontinueAmount
    );

    // Spend to date and remaining section

    const totalForecastedRemainingInvestigatorCosts =
      this.getTotalForecastedRemainingInvestigatorCosts(
        forecastedRemainingInvestigatorCosts,
        totalDiscontinued
      );

    const totalForecastedInvestigatorAmountThroughEndOfTrial =
      this.getTotalForecastedInvestigatorAmountThroughEndOfTrial(
        totalForecastedRemainingInvestigatorCosts,
        spendToDate
      );

    return {
      // Remaining Costs section
      forecastedRemainingVisitCosts: patientVisitData.forecasted_remaining_visit_costs,
      invoiceablesPerc: this.toPercent(patientVisitData.invoiceables_percent || 0),
      forecastedRemainingInvoiceables,
      invoiceablesDistribution,
      forecastedRemainingInvestigatorCosts,
      currentInvoiceablesPercent: this.toPercent(
        decimalDivide(patientVisitData.current_invoiceables_percent, 100)
      ),

      // Discontinued section
      totalDiscontinuedPerc: this.toPercent(patientVisitData.discontinued_percent || 0),
      currentDiscontinuedAmount: patientVisitData.current_discontinued_amount,
      currentEnrolledAmount,
      currentDiscontinuedPerc,

      patientRemainingToDiscontinueAmount,
      patientRemainingToDiscontinueAmountLeft,

      averageCompletedPatientCost,
      remainingPatientsToDiscontinuePerc,
      remainingPatientsToDiscontinueCost,
      differentPatientCost,
      totalDiscontinued,

      // Spend to date and remaining section
      visitCostsToDate: patientVisitData.visit_costs_to_date,
      invoiceablesToDate: patientVisitData.invoicables_to_date,
      totalSpendToDate: spendToDate,
      totalForecastedInvestigatorAmountThroughEndOfTrial,
      totalForecastedRemainingInvestigatorCosts,

      // right section
      totalInvestigatorCosts: totalForecastedInvestigatorAmountThroughEndOfTrial,
      spendToDateAmount: spendToDate,
      remainingAmount: decimalDifference(
        totalForecastedInvestigatorAmountThroughEndOfTrial,
        spendToDate
      ),
      avgPatientCost: averageCompletedPatientCost,
    };
  }
}
