import { Directive, HostListener, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[auxTextareaAutoresize]',
})
export class TextareaAutoresizeDirective implements OnInit {
  @Input() auxTextareaAutoresize = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener(':input')
  onInput() {
    this.resize();
  }

  ngOnInit() {
    if (this.elementRef.nativeElement.scrollHeight) {
      setTimeout(() => this.resize());
    }
  }

  resize() {
    if (this.auxTextareaAutoresize) {
      this.elementRef.nativeElement.style.height = '0';
      this.elementRef.nativeElement.style.height =
        this.elementRef.nativeElement.scrollHeight + 'px';
    }
  }
}
