import { Injectable } from '@angular/core';
import {
  TrialInsightsLegendDataOptions,
  TrialInsightsLegendOptions,
} from '../../models/trial-insights-legend.model';
import { TrialInsightsChartModel } from '../../models/trial-insights-chart.model';

@Injectable()
export class TrialInsightsClinicalScreenFailChartService implements TrialInsightsChartModel {
  createLegend = (data: TrialInsightsLegendDataOptions[] = []): TrialInsightsLegendOptions => {
    return {
      compact: false,
      centered: false,
      divider: false,
      color: '',
      data,
    };
  };
}
