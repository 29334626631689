import { ValueSetterParams } from '@ag-grid-community/core';
import { isString, set } from 'lodash-es';
import { removeSymbolsFromString } from '@shared/utils/formatter.utils';

export function agFormatToNumberValueSetter(
  params: ValueSetterParams,
  symbolsToRemove: string[],
  field: string
): boolean {
  const newValue = isString(params.newValue)
    ? removeSymbolsFromString(params.newValue, symbolsToRemove)
    : params.newValue;

  set(params.data, field, Number(newValue));

  return true;
}
