import { Injectable } from '@angular/core';
import {
  Currency,
  listBudgetAttributesQuery,
  listJournalEntriesQuery,
} from '@services/gql.service';
import {
  JOURNAL_ENTRIES_GRID_OPTIONS,
  getJournalEntriesGridColumns,
} from './journal-entries-table.constants';
import { Utils } from '@services/utils';
import {
  CellClickedEvent,
  ColDef,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { BudgetEnhancedAttributesComponent } from '../../../budget-page/tabs/budget-enhanced/budget-enhanced-attributes/budget-enhanced-attributes.component';
import { JournalEntriesGridRow, JournalEntryAttribute } from './journal-entries.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Injectable()
export class JournalEntriesService {
  constructor(private organizationQuery: OrganizationQuery) {}

  getJournalEntriesGridData(entries: listJournalEntriesQuery[]): JournalEntriesGridRow[] {
    const journalEntries = entries?.map((entry: listJournalEntriesQuery) => {
      return {
        ...entry,
        attributes: JSON.parse(JSON.parse(entry?.attributes || '')),
      };
    });

    return journalEntries.map((entry) => {
      return {
        vendor_id: entry.vendor_id,
        entry_name: entry.entry_name,
        debit_amount: this.isCurrencyUsd(entry)
          ? entry.debit_amount
          : entry.debit_contracted_amount,
        credit_amount: this.isCurrencyUsd(entry)
          ? entry.credit_amount
          : entry.credit_contracted_amount,
        vendor_currency: entry?.vendor_currency?.replace('CURRENCY_', '') || Currency.USD,
        line_memo: entry?.line_memo,
        sponsor_name: entry.sponsor_name,
        reversal_date: entry.reversal_date,
        ...this.convertAttributesIntoRowFields(entry.attributes),
      };
    });
  }

  getUpdatedGridOptions(
    attributes: listBudgetAttributesQuery[],

    hideRows: BehaviorSubject<boolean>,

    redirectFn: (params: ICellRendererParams | CellClickedEvent) => void
  ): GridOptions {
    const attributeColumns: ColDef[] = [];

    attributes.forEach((attribute, index) => {
      attributeColumns.push({
        headerName: attribute.attribute_name || '',
        headerClass: 'ag-header-align-center',
        field: this.getAttributeFieldName(attribute.attribute_name || ''),
        minWidth: 150,
        width: 150,
        cellRenderer: BudgetEnhancedAttributesComponent,
        cellRendererParams: {
          custom: {
            name: attribute.attribute_name?.toLowerCase() || '',
            maxWidth: 'max-w-[130px]',
          },
          col_index: index,
        },
      });
    });

    return {
      ...JOURNAL_ENTRIES_GRID_OPTIONS,
      isExternalFilterPresent: function () {
        return true;
      },
      doesExternalFilterPass: (node: IRowNode) => {
        return !(hideRows.getValue() && !node.data.debit_amount && !node.data.credit_amount);
      },
      columnDefs: getJournalEntriesGridColumns(
        this.organizationQuery,
        this.currencyFormatter,
        attributeColumns,
        redirectFn
      ),
    } as GridOptions;
  }

  private getAttributeFieldName(attributeName: string): string {
    return `custom_attr_${btoa(attributeName.toLowerCase())}`;
  }

  private currencyFormatter = (params: ValueFormatterParams): string => {
    let currency;
    if (params.data === undefined && params.node) {
      currency = this.organizationQuery.getEntity(params.node.key)?.currency || Currency.USD;
    }

    return Utils.currencyFormatter(
      Number(params.value),
      {},
      params.data?.vendor_currency || currency
    );
  };

  private convertAttributesIntoRowFields(attributes: JournalEntryAttribute[]) {
    const list: { [key: string]: string } = {};

    attributes.forEach((attribute) => {
      list[`${this.getAttributeFieldName(attribute.attribute_name || '')}`] =
        attribute.attribute_value;
    });

    return list;
  }

  private isCurrencyUsd(entry: listJournalEntriesQuery): boolean {
    if (!entry?.vendor_currency) {
      return true;
    }

    return entry.vendor_currency.replace('CURRENCY_', '') === Currency.USD;
  }
}
