import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { BudgetState, createInitialState } from './budget.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'budget' })
export class BudgetStore extends Store<BudgetState> {
  constructor() {
    super(createInitialState());
  }
}
