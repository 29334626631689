import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { Currency, DataSource } from '@services/gql.service';
import { BehaviorSubject } from 'rxjs';
import { AuthQuery } from '@models/auth/auth.query';
import { Utils } from '@services/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InvoiceLineItem, InvoiceModel } from '../state/invoice.model';
import { MainQuery } from '../../../../../layouts/main-layout/state/main.query';
import { decimalAdd, AuxExcelStyles } from '@shared/utils';
import {
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { TableConstants } from '@constants/table.constants';
import { TrialsQuery } from '@models/trials/trials.query';
import { StickyElementService } from '@services/sticky-element.service';

@UntilDestroy()
@Component({
  selector: 'aux-invoice-items',
  templateUrl: 'invoice-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceItemsComponent implements OnChanges, OnDestroy {
  @Input() invoice: InvoiceModel | undefined;

  @Input() items: InvoiceLineItem[] = [];

  getCellClass = () => () => {
    if (this.invoice?.organization?.currency) {
      return [`budgetCost${this.invoice?.organization?.currency}`, 'ag-cell-align-right'];
    }
    return ['budgetCostNoSymbol', 'ag-cell-align-right'];
  };

  gridOptions = {
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    enableRangeSelection: true,
    suppressCellFocus: false,
    columnDefs: [
      {
        headerName: 'Description',
        field: 'description',
        tooltipField: 'description',
        valueGetter: (value: ValueGetterParams<InvoiceLineItem>) => {
          if (this.invoice?.data_source_id === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE) {
            return value?.data?.Description || '';
          }
          return value?.data?.description || '';
        },
        tooltipValueGetter: (params) => {
          return params.valueFormatted !== Utils.zeroHyphen ? params.valueFormatted : '';
        },
        minWidth: 300,
        flex: 2,
        sortable: false,
        resizable: true,
        cellClass: '!block text-left max-w whitespace-nowrap overflow-hidden text-ellipsis',
      },
      {
        headerName: 'Total Amount',
        headerClass: 'ag-header-align-center justify-center font-bold',
        field: 'amount',
        valueGetter: (value) => {
          if (this.invoice?.data_source_id === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE) {
            return value?.data?.Amount || 0;
          }
          if (this.invoice?.data_source_id === DataSource.DATA_SOURCE_DYNAMICS365) {
            return value?.data?.netAmountIncludingTax || 0;
          }
          return value?.data?.amount || 0;
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return Utils.agCurrencyFormatterAccounting(
            params,
            this.invoice?.organization?.currency || Currency.USD
          );
        },
        valueParser: (params) => Number(params.newValue),
        cellClass: this.getCellClass(),
        minWidth: 300,
        flex: 1,
        resizable: true,
        sortable: false,
      },
    ],
    excelStyles: [...AuxExcelStyles, ...Utils.generateExcelCurrencyStyles(Utils.CURRENCY_OPTIONS)],
  } as GridOptions;

  excelOptions = {
    author: 'Auxilius',
    fontSize: 11,
    sheetName: 'Invoice Line Items',
    skipPinnedBottom: true,
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'description':
          return 400;
        default:
          return 200;
      }
    },
  } as ExcelExportParams;

  gridAPI!: GridApi;

  gridData$ = new BehaviorSubject<InvoiceLineItem[]>([]);

  constructor(
    public vendorsQuery: OrganizationQuery,
    public authQuery: AuthQuery,
    public stickyElementService: StickyElementService,
    private mainQuery: MainQuery,
    private trialsQuery: TrialsQuery
  ) {}

  ngOnChanges(): void {
    this.gridData$.next(this.items || []);
  }

  ngOnDestroy(): void {
    this.stickyElementService.reset();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  onGridReady(e: GridReadyEvent): void {
    this.gridAPI = e.api;
    this.gridData$.pipe(untilDestroyed(this)).subscribe(() => {
      const pinnedBottomData = {
        ...this.calculatePinnedBottomData(
          this.invoice?.data_source_id || DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE
        ),
      };
      this.gridAPI.setGridOption('pinnedBottomRowData', [pinnedBottomData]);
    });
  }

  private calculatePinnedBottomData(dataSource: DataSource) {
    let amountKey: keyof InvoiceLineItem = 'amount';
    let descriptionKey = 'description';
    if (dataSource === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE) {
      amountKey = 'Amount';
      descriptionKey = 'Description';
    } else if (dataSource === DataSource.DATA_SOURCE_DYNAMICS365) {
      amountKey = 'netAmountIncludingTax';
    }

    const totalAmount = this.gridData$.getValue().reduce((acc, currentRecord) => {
      const amt = currentRecord[amountKey];
      if (Utils.isNumber(amt)) {
        acc = decimalAdd(acc, amt);
      }
      return acc;
    }, 0);

    return { [amountKey]: totalAmount, [descriptionKey]: 'Total' };
  }

  getDynamicExcelParams(): void {
    const trial = this.trialsQuery.getEntity(this.mainQuery.getValue().trialKey);
    if (!trial) {
      return;
    }
    const totals = this.gridAPI.getPinnedBottomRow(0)?.data;

    const amount =
      this.invoice?.data_source_id === DataSource.DATA_SOURCE_QUICKBOOKS_ONLINE
        ? totals.Amount
        : this.invoice?.data_source_id === DataSource.DATA_SOURCE_DYNAMICS365
          ? totals.netAmountIncludingTax
          : totals.amount;
    const exportOptions = {
      ...this.excelOptions,
      fileName: `auxilius-invoice-line-items-${trial.short_name}-${this.invoice?.invoice_no}.xlsx`,
      columnKeys: ['description', 'amount'],
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${trial.short_name}`, type: 'String' },
              mergeAcross: 1,
              styleId: 'first_row',
            },
          ],
        },
      ],
      appendContent: [
        {
          cells: [
            {
              data: { value: `Total`, type: 'String' },
              styleId: 'total_row_header',
            },
            {
              data: { value: `${amount}`, type: 'Number' },
              styleId: `total_row_${this.invoice?.organization?.currency || Currency.USD}`,
            },
          ],
        },
      ],
    } as ExcelExportParams;
    this.gridAPI?.exportDataAsExcel({
      ...exportOptions,
    });
  }
}
