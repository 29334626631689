import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { UntypedFormControl, Validators } from '@angular/forms';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { Observable } from 'rxjs';
import { WorkflowQuery } from '../close-quarter-check-list/store';

@Component({
  templateUrl: './quarter-close-dialog.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterCloseDialogComponent {
  textarea = new UntypedFormControl('', Validators.required);

  sendToNetsuite = new UntypedFormControl(false, Validators.required);

  showNetsuiteMessage$: Observable<boolean>;

  steps = [
    'Trial Timeline is accurate',
    'Site and Patient Curves are up to date',
    'Patient Tracker is up to date',
    'Email Vendor Estimate for Trailing Month WP',
  ];

  isQuarterCloseEnabled$ = this.workflowQuery.isQuarterCloseEnabled$;

  constructor(
    public ref: CustomOverlayRef<
      { note: string; sendToNetsuite: boolean } | undefined,
      { formatted_current_month: string }
    >,
    private ld: LaunchDarklyService,
    private workflowQuery: WorkflowQuery
  ) {
    this.showNetsuiteMessage$ = ld.select$((flags) => flags.netsuite_integration_month_close);
  }

  close(bool: boolean) {
    const note = this.textarea.value;
    const sendToNetsuite = this.sendToNetsuite.value;
    if (bool && !note) {
      this.textarea.markAsTouched();
      this.textarea.markAsDirty();
      this.textarea.updateValueAndValidity();
      return;
    }

    this.ref.close({
      note,
      sendToNetsuite,
    });
  }
}
