import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { InvoiceModel } from '../state/invoice.model';
import { UploadDocumentsDialogComponent } from '../upload-documents-dialog/upload-documents-dialog.component';
import { OverlayService } from '@services/overlay.service';

@Component({
  selector: 'aux-line-items-no-rows',
  templateUrl: './line-items-no-rows.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineItemsNoRowsComponent {
  @Input() invoice: InvoiceModel | undefined;

  @Output() closeDialog = new EventEmitter();

  constructor(private overlayService: OverlayService) {}

  upload() {
    const result = this.overlayService.open<unknown[]>({
      content: UploadDocumentsDialogComponent,
      data: { invoice: this.invoice },
    });
    result.afterClosed$.subscribe(async () => {
      this.closeDialog.emit();
    });
  }
}
