import { Injectable } from '@angular/core';
import { TrialInsightsLegendOptions } from '../../models/trial-insights-legend.model';
import { ChartDataset } from 'chart.js/dist/types';
import {
  ChartConfiguration,
  ElementOptionsByType,
  PluginOptionsByType,
  ScaleChartOptions,
  TooltipOptions,
} from 'chart.js';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { TrialInsightsChartModel } from '../../models/trial-insights-chart.model';

export type TICPCChartType = 'bar';
export type TICPCChartLabel = number;

@Injectable()
export class TrialInsightsClinicalPatientCostChartService
  implements TrialInsightsChartModel<TICPCChartType, TICPCChartLabel>
{
  createLegend = (patientsEnrolled?: TICPCChartLabel): TrialInsightsLegendOptions => {
    return {
      compact: false,
      centered: false,
      divider: false,
      color: '',
      data: [
        {
          displayIcon: true,
          displayDivider: false,
          iconColor: '#095b95',
          valueColor: '#095b95',
          title: 'Patients Enrolled',
          value: patientsEnrolled ? patientsEnrolled + '' : '',
        },
      ],
    };
  };

  createDatasets = (patientsEnrolled?: number): ChartDataset<TICPCChartType>[] => {
    return [
      {
        data: patientsEnrolled ? [patientsEnrolled] : [],
        backgroundColor: ['#095b95'],
        hoverBackgroundColor: ['#256ea2'],
        barThickness: 55,
        xAxisID: 'x1',
      },
    ];
  };

  createChart = (
    datasets?: ChartDataset<TICPCChartType>[],
    expectedEnrolled?: number
  ): ChartConfiguration<TICPCChartType> => {
    return {
      type: 'bar',
      options: this.chartOptions(expectedEnrolled),
      data: {
        labels: this.labelOptions(),
        datasets: datasets ? datasets : [],
      },
    };
  };

  chartOptions = (expectedEnrolled?: number): ChartConfiguration<TICPCChartType>['options'] => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: this.elementOptions(),
      scales: {
        ...this.xAxesOptions(expectedEnrolled).scales,
        ...this.yAxesOptions().scales,
      },
      plugins: this.pluginOptions(),
      indexAxis: 'y',
    };
  };

  labelOptions = () => {
    return ['Patients Enrolled'];
  };

  tooltipOptions = (): DeepPartial<TooltipOptions<TICPCChartType>> => {
    return {};
  };

  pluginOptions = (): DeepPartial<PluginOptionsByType<TICPCChartType>> => {
    return {
      legend: {
        display: false,
      },
      tooltip: this.tooltipOptions(),
    };
  };

  elementOptions = (): DeepPartial<ElementOptionsByType<TICPCChartType>> => {
    return {
      bar: {
        borderColor: '#bacad0',
      },
    };
  };

  xAxesOptions = (expectedEnrolled?: number): DeepPartial<ScaleChartOptions<TICPCChartType>> => {
    return {
      scales: {
        x1: {
          beginAtZero: true,
          max: expectedEnrolled || 100,
          title: {
            display: false,
          },
          grid: {
            display: true,
            color: '#bacad0',
            lineWidth: 0.75,
            z: 1,
          },
          ticks: {
            display: true,
            callback: (value, index, values) => {
              if (values.length <= 4) {
                return value;
              }

              const lastIndex = values.length - 1;
              const nextLastIndex = lastIndex - 1;

              if (index !== lastIndex && index !== nextLastIndex) {
                return value;
              }

              const lastValue = Number(values[lastIndex]);
              const nextLastValue = Number(values[nextLastIndex]);

              if (index === nextLastIndex) {
                if (lastValue - nextLastValue <= 25) {
                  return null;
                }
              }

              return value;
            },
          },
        },
      },
    };
  };

  yAxesOptions = (): DeepPartial<ScaleChartOptions<TICPCChartType>> => {
    return {
      scales: {
        y: {
          display: false,
        },
      },
    };
  };
}
