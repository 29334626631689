import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  CellClassParams,
  CellClickedEvent,
  ColDef,
  ColSpanParams,
  ExcelExportParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from '@ag-grid-community/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Utils } from '@services/utils';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { isEqual, merge } from 'lodash-es';
import { TableService } from '@services/table.service';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { InvestigatorSummary } from '@services/gql.service';
import { SiteModel } from '@models/sites/sites.store';
import { OverlayService } from '@services/overlay.service';
import { SitesQuery } from '@models/sites/sites.query';
import { SitesService } from '@models/sites/sites.service';
import { FormBuilder } from '@angular/forms';
import * as dayjs from 'dayjs';
import { TableConstants } from '@constants/table.constants';
import { InvestigatorSummaryRow, InvestigatorSummaryService } from './investigator-summary.service';
import { InvestigatorSummaryColumns } from './investigator-summary.columns';
import { MainQuery } from '../../../layouts/main-layout/state/main.query';
import { SiteDialogConstants } from '../sites/site-dialog/site-dialog.constants';
import { AuxExcelStyles } from '@shared/utils';
import { AgExpandHeaderComponent, AgExpandHeaderParams } from '@shared/components';
import { StickyElementService } from '@services/sticky-element.service';

@UntilDestroy()
@Component({
  selector: 'aux-investigator-summary',
  templateUrl: './investigator-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InvestigatorSummaryService],
})
export class InvestigatorSummaryComponent implements OnInit, OnDestroy {
  readonly ignoreColsForTotal = ['site_id', 'site_no', 'patient', 'investigator'];

  sortChanged$ = new Subject();

  readonly siteNumberColumnSettings: ColDef = {
    headerName: 'Site #',
    field: 'site_no',
    tooltipField: 'site_no',
    minWidth: 125,
    cellClass: (x: CellClassParams) => {
      if (x?.data) {
        if (x?.data?.isTotal) {
          return 'text-left';
        }
        return [
          'grid-cell underline aux-link cursor-pointer justify-end text-left',
          TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT,
        ];
      }

      return ['text-left', TableConstants.STYLE_CLASSES.CELL_ALIGN_LEFT];
    },
    showRowGroup: true,
    cellRenderer: TableConstants.AG_SYSTEM.AG_GROUP_CELL_RENDERER,
    headerComponent: AgExpandHeaderComponent,
    headerComponentParams: {
      sortChanged$: this.sortChanged$,
    } as AgExpandHeaderParams,
    cellRendererParams: {
      suppressCount: true,
    },
    sort: 'asc',
    resizable: true,
    suppressSizeToFit: true,
    filter: true,
    colSpan: (params: ColSpanParams): number => {
      if (params.data?.isTotal) {
        return 2;
      }
      return 1;
    },
    onCellClicked: (event: CellClickedEvent) => this.openSiteDialog(event),
  };

  gridRefresh$ = new BehaviorSubject(true);

  quickFilterText = '';

  gridAPI!: GridApi<InvestigatorSummaryRow>;

  gridData$ = new BehaviorSubject<InvestigatorSummaryRow[]>([]);

  gridOptions$: BehaviorSubject<GridOptions> = new BehaviorSubject({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      cellRenderer: AgCellWrapperComponent,
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    groupDisplayType: TableConstants.AG_SYSTEM.CUSTOM,
    suppressAggFuncInHeader: true,
    suppressMenuHide: true,
    getRowClass: Utils.oddEvenRowClass,
    excelStyles: AuxExcelStyles,
  } as GridOptions);

  excelOptions = {
    sheetName: 'Investigator Summary',
    shouldRowBeSkipped(params) {
      return !params.node?.data?.site_no;
    },
    processCellCallback: (params) => {
      if (params.column.getColId() === 'investigator') {
        return params.value || Utils.zeroHyphen;
      }

      return params.value;
    },
    columnWidth(params) {
      switch (params.column?.getId()) {
        case 'patient':
        case 'site_no':
          return 105;
        case 'patient_visit_total':
        case 'invoiceables':
        case 'total_cost_to_date':
        case 'amount_remaining':
          return 200;
        case 'forecast_cost_through_eot':
          return 230;
        default:
          return 150;
      }
    },
  } as ExcelExportParams;

  investigatorSummaryFiltersForm = this.formBuilder.group({
    site_ids: null,
    patient_ids: null,
  });

  siteOptions$ = this.investigatorSummaryService.siteOptions$;

  patientOptions$ = this.investigatorSummaryService.patientOptions$;

  constructor(
    private formBuilder: FormBuilder,
    private investigatorSummaryService: InvestigatorSummaryService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private sitesService: SitesService,
    private sitesQuery: SitesQuery,
    private stickyElementService: StickyElementService
  ) {}

  ngOnInit(): void {
    this.mainQuery
      .select('trialKey')
      .pipe(
        untilDestroyed(this),
        tap(() => this.investigatorSummaryFiltersForm.reset()),
        switchMap(() => this.investigatorSummaryService.getDefaultFilters()),
        switchMap(() => this.sitesService.get())
      )
      .subscribe(() => {
        this.initTable();
      });

    this.filtersValueChanges();
  }

  ngOnDestroy() {
    this.stickyElementService.reset();
  }

  onGridReady({ api }: GridReadyEvent): void {
    this.gridAPI = api;
  }

  private initTable(): void {
    this.toggleLoaders(true);
    this.investigatorSummaryService
      .fetchAll()
      .subscribe((response: GraphqlResponse<Array<InvestigatorSummary>>) => {
        const dynamicColumns: ColDef[] =
          this.investigatorSummaryService.getGridDynamicColumns(response);

        this.gridAPI?.setGridOption('columnDefs', [
          ...InvestigatorSummaryColumns(this.siteNumberColumnSettings, dynamicColumns),
        ]);
        this.gridData$.next(this.investigatorSummaryService.getGridData(response, dynamicColumns));
        this.setTotalRowData(this.gridData$.getValue());

        this.gridAPI?.applyColumnState({
          state: [{ colId: 'site_no', sort: 'asc' }],
        });

        this.gridAPI?.sizeColumnsToFit();
        this.toggleLoaders(false);
      });
  }

  private onEdit(site: SiteModel): void {
    const ref = this.overlayService.open({
      data: { site },
      ...SiteDialogConstants.OVERLAY_SETTINGS,
    });

    ref.afterClosed$.pipe(untilDestroyed(this)).subscribe(() => {
      this.investigatorSummaryService
        .getDefaultFilters()
        .pipe(
          switchMap(() => this.sitesService.get()),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.initTable();
        });
    });
  }

  private openSiteDialog(event: CellClickedEvent): void {
    const siteById = this.sitesQuery.getEntity(event.data?.site_id);

    if (siteById) {
      this.onEdit(siteById);
    }
  }

  private toggleLoaders(isLoading: boolean): void {
    this.gridRefresh$.next(isLoading);
  }

  private filtersValueChanges(): void {
    this.investigatorSummaryFiltersForm.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        distinctUntilChanged(isEqual),
        tap((filters) => this.filterGrid(filters))
      )
      .subscribe();
  }

  private filterGrid(filters: { site_ids: string[]; patient_ids: string[] }): void {
    const siteNumbers: string[] = [];
    const siteNoFilterInstance = this.gridAPI.getFilterInstance('site_no');
    const patientIdsFilterInstance = this.gridAPI.getFilterInstance('patient');

    filters.site_ids?.forEach((siteId: string) => {
      const siteNo = this.investigatorSummaryService.siteMap.get(siteId)?.site_no;
      if (siteNo) {
        siteNumbers.push(siteNo);
      }
    });

    siteNoFilterInstance?.setModel({
      filterType: 'text',
      type: 'Equals',
      values: siteNumbers?.length ? siteNumbers : null,
    });

    patientIdsFilterInstance?.setModel({
      filterType: 'text',
      type: 'Equals',
      values: filters.patient_ids?.length ? filters.patient_ids : null,
    });

    this.gridAPI.onFilterChanged();
    this.setTotalRowData();
  }

  getDynamicExcelParams = (): ExcelExportParams => {
    const name = this.mainQuery.getSelectedTrial()?.short_name;
    const totals = this.gridAPI.getPinnedBottomRow(0)?.data;
    const columns = Object.entries(totals)
      .map(([key, value]) => (typeof value === 'number' ? key : null))
      .filter((key) => key) as string[];

    const columnKeys = this.gridAPI
      .getAllDisplayedColumns()
      .map((x) => {
        return x.getId().includes('site_no') ? 'site_no' : x.getId();
      })
      .filter((x) => x !== 'spacerColumn');

    const appendContent: ExcelExportParams['appendContent'] = totals
      ? [
          {
            cells: [
              {
                data: { value: `Total`, type: 'String' },
                styleId: 'total_row_header',
              },
              ...TableService.getTotalRowForExcel(
                totals,
                this.gridAPI,
                ['site_no_1', 'patient', 'site_id', 'site_no'],
                columns
              ),
            ],
          },
        ]
      : [{ cells: [] }];
    return {
      fileName: `auxilius-patient-summary_${dayjs().format('YYYY.MM.DD-HHmmss')}.xlsx`,
      columnKeys,
      ...this.excelOptions,
      appendContent,
      prependContent: [
        {
          cells: [
            {
              data: { value: `Trial: ${name}`, type: 'String' },
              mergeAcross: 1,
              styleId: 'first_row',
            },
          ],
        },
      ],
      shouldRowBeSkipped(params) {
        return params.node?.data?.site_no === 'Total' || !params.node?.data;
      },
    };
  };

  setTotalRowData(initialRows?: InvestigatorSummaryRow[]): void {
    const rowData: InvestigatorSummaryRow[] = [];

    this.gridAPI?.forEachNodeAfterFilter((node) => {
      if (node.data) {
        rowData.push(node.data);
      }
    });

    this.gridAPI?.setGridOption('pinnedBottomRowData', [
      merge(
        {
          site_no: 'Total',
          isTotal: true,
        },
        TableService.generateTotalRow(
          (initialRows || rowData) as Record<string, number>[],
          this.ignoreColsForTotal
        )
      ),
    ]);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.gridAPI?.sizeColumnsToFit();
    this.stickyElementService.configure();
  }
}
