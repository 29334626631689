import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { SiteCurveStore, SiteCurveState } from './site-curve.store';

@QueryConfig({
  sortBy: 'rank_order',
  sortByOrder: Order.ASC,
})
@Injectable({ providedIn: 'root' })
export class SiteCurveQuery extends QueryEntity<SiteCurveState> {
  constructor(protected store: SiteCurveStore) {
    super(store);
  }
}
