import { Component } from '@angular/core';
import { IconComponent } from '@components/icon/icon.component';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';
import { ActivitySubType, ActivityType } from '@services/gql.service';
import { InputComponent } from '@components/form-inputs/input/input.component';
import { FormsModule } from '@angular/forms';
import { BeActivitiesModalRowData, BeActivityTypes } from './be-activities-modal.component';

export type BeInlineCategoryDropdownOption = {
  id: string;
  name: string;
  type: BeActivityTypes;
  indent: number;
  disabled: boolean;
  disabledTooltip?: string;
};
export type BeInlineCategoryDropdownParams = ICellRendererParams<BeActivitiesModalRowData> & {
  categories: BeInlineCategoryDropdownOption[];
  onCategoryChange: (
    params: BeInlineCategoryDropdownParams,
    id: string,
    type: BeActivityTypes
  ) => void;

  usedCategories: () => Record<BeActivityTypes, boolean>;
};

@Component({
  standalone: true,
  template: `
    <div
      class="flex items-center justify-between h-full pl-[10px] pr-[4px]"
      (click)="onDropdown()"
      [ngClass]="{ 'cursor-pointer': !disabled }"
      [auxTooltip]="disabled ? msg : ''"
      [auxTooltipPositions]="topLevelTooltipPositions"
    >
      <div class="truncate" *ngIf="!selectedCategory">Select and Manage Categories</div>
      <div
        class="truncate"
        *ngIf="selectedCategory"
        #cat
        [auxTooltip]="cat.offsetWidth < cat.scrollWidth && !disabled ? selectedCategory : ''"
        [auxTooltipPositions]="categoryTooltipPositions"
      >
        {{ selectedCategory }}
      </div>
      <aux-icon class="flex-shrink-0" name="CaretDownFilled" [size]="16" />
    </div>
    <div #trigger="cdkOverlayOrigin" cdkOverlayOrigin></div>

    <ng-template
      cdkConnectedOverlay
      class="border-solid border-aux-gray-dark-100"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayPositions]="positions"
      [cdkConnectedOverlayOpen]="dropdownOpened"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'random-class'"
      (detach)="dropdownOpened = false"
      (backdropClick)="dropdownOpened = false"
    >
      <div
        class="py-3 bg-white rounded-b-[4px] px-[8px] ml-[-1px] border"
        style="box-shadow: 0 5px 3px 0 #696F8E59;"
        [ngStyle]="{
          width: trigger.elementRef.nativeElement.clientWidth + 1.5 + 'px'
        }"
      >
        <div class="h-[32px] w-full">
          <aux-input
            [(ngModel)]="filterText"
            (ngModelChange)="onFilter()"
            icon="Search"
            iconPosition="left"
            inputClassName="text-aux-gray-dark-100 focus-within:text-aux-gray-dark-100 rounded-[4px] placeholder:text-aux-gray-dark-100 !placeholder:not-italic"
            iconClassName="text-aux-gray-dark-100 focus-within:text-aux-gray-dark-100"
            placeholder="Search"
          />
        </div>
        <div class="h-[200px] mt-[10px] overflow-auto snap-y w-full">
          <div
            *ngFor="let option of filteredCategories; trackBy: trackByFn"
            (click)="onDropdownSelected(option)"
            class="items-center py-[8px] leading-[17px] hover:bg-aux-blue-light-50 truncate font-medium snap-end rounded-[4px]"
            [auxTooltip]="
              option.disabledTooltip
                ? option.disabledTooltip
                : opt.offsetWidth < opt.scrollWidth
                  ? option.name
                  : ''
            "
            [auxTooltipPositions]="tooltipPositions"
            #opt
            [ngStyle]="{
              paddingLeft: option.indent * 12 + 'px'
            }"
            [ngClass]="{
              'cursor-pointer': !option.disabled,
              'cursor-not-allowed': option.disabled
            }"
          >
            {{ option.name }}
          </div>
        </div>
      </div>
    </ng-template>
  `,
  imports: [
    IconComponent,
    NgIf,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    NgForOf,
    TooltipDirective,
    NgStyle,
    InputComponent,
    FormsModule,
    NgClass,
  ],
  styles: [
    `
      :host {
        @apply w-full h-full;
      }
    `,
  ],
})
export class BeInlineCategoryDropdownComponent implements ICellRendererAngularComp {
  categories: BeInlineCategoryDropdownOption[] = [];
  filteredCategories: BeInlineCategoryDropdownOption[] = [];
  filterText = '';

  dropdownOpened = false;
  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: -34,
    },
  ];

  tooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
    },
  ];

  categoryTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
      offsetX: -11,
    },
  ];

  topLevelTooltipPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
    },
  ];

  params!: BeInlineCategoryDropdownParams;

  selectedCategory = '';

  disabled = false;

  msg = 'Auxilius Product restricts Category and Activity Name for Investigator and Discount.';

  disableChangingCategoriesTooltip = 'Cannot change cost category for existing activities';

  isGenerated = true;

  activityType: BeActivityTypes | '' = '';

  agInit(params: BeInlineCategoryDropdownParams) {
    this.params = params;
    this.categories = params.categories;
    if (this.params.node.data?.category) {
      this.selectedCategory =
        this.categories.find(
          (c) =>
            c.type === this.params.node.data?.activity_type &&
            c.id === this.params.node.data?.category
        )?.name || '';

      this.activityType = this.params.node.data?.activity_type;
      this.isGenerated = this.params.node.data?.isGenerated;

      switch (this.params.node.data?.activity_type) {
        case ActivityType.ACTIVITY_DISCOUNT:
          this.disabled = true;
          break;
        case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_INVOICEABLES:
        case ActivitySubType.ACTIVITY_INVESTIGATOR_SITE_INVOICEABLES:
        case ActivitySubType.ACTIVITY_INVESTIGATOR_PATIENT_VISITS:
          this.disabled = true;
          this.selectedCategory = `Investigator > ${this.selectedCategory}`;
          break;
        default:
          this.disabled = false;
          break;
      }
    }

    this.onFilter();
  }

  refresh() {
    return false;
  }

  onDropdown() {
    if (!this.disabled) {
      this.onFilter();
      this.dropdownOpened = true;
    }
  }

  onDropdownSelected(option: BeInlineCategoryDropdownOption) {
    if (option.disabled) {
      return;
    }

    const { id, type } = option;

    this.dropdownOpened = false;
    // clean state
    this.params.node.updateData(<BeActivitiesModalRowData>{
      ...this.params.node.data,
      category: '',
      activity_type: '',
    });

    // refill
    this.params.node.updateData(<BeActivitiesModalRowData>{
      ...this.params.node.data,
      category: id,
      activity_type: type,
    });
    this.params.onCategoryChange(this.params, id, type);
  }

  onFilter() {
    const usedCategories = this.params.usedCategories();
    const categories = this.categories.map((c) => {
      const bool = this.isGenerated ? false : c.type !== this.activityType;
      return {
        ...c,
        disabled: c.type === ActivityType.ACTIVITY_INVESTIGATOR || usedCategories[c.type] || bool,
        disabledTooltip: bool ? this.disableChangingCategoriesTooltip : '',
      };
    });

    if (this.filterText) {
      this.filteredCategories = categories.filter((c) => {
        return c.name.toLowerCase().includes(this.filterText.toLowerCase().trim());
      });
    } else {
      this.filteredCategories = categories;
    }
  }

  trackByFn(_: number, c: BeInlineCategoryDropdownOption) {
    return c.id;
  }
}
