import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'aux-typography-rule',
  templateUrl: './typography-rule.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypographyRuleComponent {
  @Input() title = '';

  @Input() description = '';

  @Input() classNames = '';
}
