import { IHeaderGroupAngularComp } from '@ag-grid-community/angular';
import { Column, ColumnGroup, IHeaderGroupParams } from '@ag-grid-community/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

import { BehaviorSubject, combineLatest } from 'rxjs';
import { AgHeaderExpandComponent } from 'src/app/pages/budget-page/tabs/budget-enhanced/ag-header-expand.component';

import { PeriodCloseComponent } from '../../period-close.component';
import { AuxIconName } from '@components/icon/icon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessagesConstants } from '@constants/messages.constants';
import { WorkflowQuery } from '../quarter-close/close-quarter-check-list/store';
import { WorkflowStep } from '@services/gql.service';

interface AgAdjustmentVendorEstimateHeaderColumnParams extends IHeaderGroupParams {
  columnGroup: ColumnGroup;
  removeVendorEstimateLoading$: BehaviorSubject<boolean>;
  doesSelectedMonthHasVendorEstimate$: BehaviorSubject<boolean>;
  isEditMode$: BehaviorSubject<boolean>;
  userHasAdjustPermission$: BehaviorSubject<boolean>;
  doesSelectedMonthHasVendorEstimateSupportingDoc$: BehaviorSubject<boolean>;
  isSelectedMonthOpenOrFuture$: BehaviorSubject<boolean>;
  isSelectedMonthOpen$: BehaviorSubject<boolean>;
  collapsedByDefault: boolean;
  onDeleteVendorEstimate: VoidFunction;
  onDownloadVendorEstimate: VoidFunction;
  filterCols: (column: Column) => boolean;
  onEditAdjustments: VoidFunction;
  onUploadVendorEstimate: VoidFunction;
  localStorageKey: string;
}
@UntilDestroy()
@Component({
  template: `
    <div class="flex items-center justify-center" *ngIf="{ menuItems: menuItems$ | async } as obj">
      <div>Vendor Estimate</div>
      <ng-container
        *ngVar="{
          editAdjustmentDisabled: editAdjustmentDisabled$ | async,
          isEditMode: params.isEditMode$ | async,
          isWorkflowLocked: isWorkflowLocked$ | async
        } as obj"
      >
        <span
          *ngIf="(params.isSelectedMonthOpen$ | async) === true"
          class="flex rounded-full p-[4px] ml-[10px] bg-white cursor-pointer"
          [ngClass]="{
            'opacity-80 cursor-not-allowed':
              obj.editAdjustmentDisabled || obj.isEditMode || obj.isWorkflowLocked
          }"
        >
          <aux-icon
            name="Pencil"
            [size]="20"
            class="text-aux-blue"
            [auxTooltip]="getPencilTooltip(obj.editAdjustmentDisabled, obj.isWorkflowLocked)"
            (click)="
              !obj.editAdjustmentDisabled &&
                !obj.isEditMode &&
                !obj.isWorkflowLocked &&
                params.onEditAdjustments()
            "
          />
        </span>
      </ng-container>
      <aux-menu
        #vendorEstimateMenu
        class="bg-white ml-[10px] flex rounded-full outline-none"
        className="p-[4px] cursor-pointer"
        [iconClassName]="
          (vendorEstimateMenu.isOpen$ | async) ? 'text-aux-gray-light' : 'text-aux-blue'
        "
        [ngClass]="{ '!bg-aux-gray-dark': vendorEstimateMenu.isOpen$ | async }"
        [showMenuForExternal]="true"
        *ngIf="!!obj.menuItems?.length"
      >
        <button
          type="button"
          role="menuitem"
          *ngFor="let item of obj.menuItems"
          class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 p-[8px] text-sm w-full flex items-center focus:outline-none max-w-[370px]"
          (click)="item.onClick(); vendorEstimateMenu.close()"
        >
          <div class="flex space-x-4">
            <aux-icon
              [name]="item.icon"
              class="text-aux-blue"
              [ngClass]="item.textColor"
              style="display: flex"
            />
            <div class="text-left">
              <p class="text-base text-aux-blue" [ngClass]="item.textColor">{{ item.title }}</p>
            </div>
          </div>
        </button>
      </aux-menu>

      <aux-icon
        (click)="toggleExpand()"
        [name]="visible ? 'ChevronLeft' : 'ChevronRight'"
        [size]="20"
        class="ml-1 cursor-pointer"
      />
      <ng-container *ngIf="params.removeVendorEstimateLoading$ | async">
        <div class="border-8 h-8 m-auto spinner w-8"></div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      ::ng-deep div,
      ::ng-deep button {
        outline: 2px solid transparent;
        outline-offset: 2px;
      }

      ::ng-deep button:focus {
        box-shadow: none !important;
      }

      ::ng-deep .options {
        border-radius: 0;
        border: 1px solid #bacad0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgAdjustmentVendorEstimateHeaderComponent
  extends AgHeaderExpandComponent
  implements IHeaderGroupAngularComp
{
  params!: AgAdjustmentVendorEstimateHeaderColumnParams;

  isWorkflowLocked$ = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_ADJUSTMENTS
  );

  visible = true;

  editAdjustmentDisabled$ = new BehaviorSubject(false);

  private readonly menuItems: {
    icon: AuxIconName;
    title: string;
    textColor?: string;
    onClick: VoidFunction;
  }[] = [
    {
      title: 'Send Vendor Estimate Support',
      icon: 'Send',
      onClick: () => this.params.onUploadVendorEstimate(),
    },
    {
      title: 'Download Vendor Estimate',
      icon: 'Download',
      onClick: () => this.params.onDownloadVendorEstimate(),
    },
    {
      title: 'Delete Vendor Estimate',
      icon: 'Trash',
      textColor: 'text-aux-red-dark',
      onClick: () => this.params.onDeleteVendorEstimate(),
    },
  ];

  menuItems$ = new BehaviorSubject(this.menuItems);

  constructor(
    public periodCloseComponent: PeriodCloseComponent,
    private workflowQuery: WorkflowQuery
  ) {
    super();
  }

  agInit(params: AgAdjustmentVendorEstimateHeaderColumnParams): void {
    this.params = params;
    this.visible = !params.collapsedByDefault;
    this.filterCols = this.params.filterCols;
    this.initializeExpandCols();
    this.filterMenuItems();

    this.params.userHasAdjustPermission$.pipe(untilDestroyed(this)).subscribe((hasPermission) => {
      this.editAdjustmentDisabled$.next(!hasPermission);
    });
  }

  filterMenuItems() {
    combineLatest([
      this.params.removeVendorEstimateLoading$,
      this.params.doesSelectedMonthHasVendorEstimate$,
      this.params.doesSelectedMonthHasVendorEstimateSupportingDoc$,
      this.params.isSelectedMonthOpenOrFuture$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
          removeVendorEstimateLoading,
          doesSelectedMonthHasVendorEstimate,
          doesSelectedMonthHasVendorEstimateSupportingDoc,
          isSelectedMonthOpenOrFuture,
        ]) => {
          const filteredMenuItems = [];

          if (isSelectedMonthOpenOrFuture) {
            filteredMenuItems.push(this.menuItems[0]);
          }

          if (doesSelectedMonthHasVendorEstimateSupportingDoc) {
            filteredMenuItems.push(this.menuItems[1]);
          }

          if (
            !removeVendorEstimateLoading &&
            doesSelectedMonthHasVendorEstimate &&
            isSelectedMonthOpenOrFuture
          ) {
            filteredMenuItems.push(this.menuItems[2]);
          }

          this.menuItems$.next(filteredMenuItems);
        }
      );
  }

  refresh(): boolean {
    return false;
  }

  getPencilTooltip(noPermission: boolean, isLocked: boolean) {
    if (noPermission) {
      return MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;
    }

    if (isLocked) {
      return MessagesConstants.PAGE_LOCKED_FOR_PERIOD_CLOSE;
    }

    return '';
  }
}
