<div *ngIf="!isLoadingList" class="flex items-center">
  <div class="mr-4">
    <div class="text-sm"># Invoices</div>
    <div>{{ numberOfInvoices }}</div>
  </div>
  <div class="mr-4">
    <div class="text-sm">Invoiced in Period</div>
    <div>{{ amountInvoiced }}</div>
  </div>
</div>
