import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnChanges,
} from '@angular/core';
import { AuxIconName, AUX_ICONS } from '@components/icon/icon';
import { TypedNgChanges } from '@shared/utils';

@Component({
  standalone: true,
  selector: 'aux-icon',
  template: `<ng-content />`,
  imports: [],
  styles: [
    `
      :host {
        display: inline-block;
        fill: none;
        stroke: currentColor;
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  @Input({ required: true }) name!: AuxIconName;

  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  @Input()
  size = 24;

  private elem = inject(ElementRef);
  private changeDetector = inject(ChangeDetectorRef);

  ngOnChanges(changes: TypedNgChanges<IconComponent>) {
    const name = changes.name.currentValue;

    this.elem.nativeElement.innerHTML = AUX_ICONS[`Icon${name}`];
    this.changeDetector.markForCheck();
  }
}
