import { MonthStats } from '../../period-close.component';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'aux-period-close-header-month',
  template: `<div
    class="p-3 flex items-center flex-col border border-inactive-gray bg-aux-blue-dark rounded w-44 text-aux-black"
    [ngClass]="{
      'bg-aux-gray-dark': monthStat.status !== 'Open',
      'border-transparent': monthStat.status === 'Open'
    }"
  >
    <p
      class="font-bold"
      [ngClass]="{
        'text-white': monthStat.status === 'Open'
      }"
    >
      {{ monthStat.date | date: 'MMMM' }}
    </p>
    <p
      *ngVar="monthStat.eom_accruals | money as eom_accruals"
      class="text-x text-ellipsis overflow-hidden max-w-full"
      [ngClass]="{
        'text-white': monthStat.status === 'Open'
      }"
      [auxTooltip]="eom_accruals"
    >
      {{ eom_accruals }}
    </p>
    <p
      class="font-bold"
      [ngClass]="{
        'text-white': monthStat.status === 'Open'
      }"
    >
      {{ monthStat.status }}
    </p>
  </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodCloseHeaderMonthComponent {
  @Input() monthStat!: MonthStats;
}
