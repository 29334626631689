import { Component } from '@angular/core';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';

@Component({
  standalone: true,
  template: `{{ params.displayName }} <span class="text-aux-error ml-1">*</span>`,
  imports: [],
  styles: [],
})
export class AgRequiredHeaderComponent implements IHeaderAngularComp {
  params!: IHeaderParams;
  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
