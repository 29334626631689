export interface InvoiceItemColumnInfo {
  headerName: string;
  field: string;
  type: InvoiceItemColumnType;
}

export enum InvoiceItemColumnType {
  INVOICE_ID = 'INVOICE_ID',
  LINE_ITEM_ID = 'LINE_ITEM_ID',
  ITEM = 'ITEM',
  OTHER = 'OTHER',
  QUANTITY = 'QUANTITY',
  PRICE = 'PRICE',
  UNIT_PRICE = 'UNIT_PRICE',
  PRODUCT_CODE = 'PRODUCT_CODE',
}
