<span *ngIf="label" class="block mb-1 text-xs">{{ label }}</span>
<div class="flex multi-select">
  <ng-select
    class="w-full tabular-nums"
    [ngClass]="{
      'no-border-right': prefix
    }"
    [multiple]="true"
    [clearable]="true"
    [searchable]="searchable"
    [placeholder]="placeholder"
    [formControl]="fc"
    [items]="items"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [loading]="loading"
    (ngModelChange)="onChange(fc.value)"
  >
    <ng-template let-items="items" let-clear="clear" ng-multi-label-tmp class="flex-nowrap">
      <div
        *ngIf="items.length <= maximumItemsToDisplay"
        class="ng-value space-x-2 flex bg-transparent"
        style="background-color: transparent"
      >
        <div *ngFor="let item of items" class="flex" style="background-color: #ebf5ff">
          <span class="ng-value-icon left" aria-hidden="true" (click)="clear(item)"> × </span>

          <span class="ng-value-label overflow-hidden text-ellipsis">
            {{ getOptionLabel(item) }}
          </span>
        </div>
      </div>

      <div *ngIf="items.length > maximumItemsToDisplay" class="ng-value">
        <span class="ng-value-label">{{ items.length }} Selected</span>
      </div>
    </ng-template>

    <ng-template *ngIf="customOption" let-item="item" ng-option-tmp>
      <p class="pr-4 text-sm font-bold leading-5 overflow-hidden text-ellipsis">
        {{ item.title }}
      </p>

      <p class="pr-4 text-sm italic leading-5 overflow-hidden text-ellipsis">
        {{ item.subTitle }}
      </p>
    </ng-template>
  </ng-select>
  <div
    *ngIf="prefix"
    class="flex justify-center items-center border rounded border-aux-gray-dark rounded-bl-none rounded-tl-none"
    style="height: 35px; width: 25px; min-width: 25px"
    [ngClass]="{ 'cursor-pointer': !!prefixClick }"
    (click)="prefixClick && prefixClick()"
  >
    {{ prefix }}
  </div>
</div>
