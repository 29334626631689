import { MainService } from '../../layouts/main-layout/state/main.service';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  firstValueFrom,
  from,
  merge,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { LoggedInUser } from '@models/auth/LoggedInUser';
import * as dayjs from 'dayjs';
import { groupBy, last, round, uniq } from 'lodash-es';
import {
  Approval,
  BudgetData,
  CategoryType,
  Currency,
  EventType,
  GqlService,
  InvoiceStatus,
  listSitePatientTrackerMonthlyAmountsQuery,
} from '@services/gql.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OverlayService } from '@services/overlay.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { OrganizationService } from '@models/organization/organization.service';
import { EventService } from '@services/event.service';
import { AuthService } from '@models/auth/auth.service';
import { Router } from '@angular/router';
import { AuthQuery } from '@models/auth/auth.query';
import { ObjectFromList, Utils } from '@services/utils';
import { FormControl } from '@angular/forms';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TabGroupConfig } from '@components/tab-group/tab-group.component';

import { BudgetService } from '../budget-page/tabs/budget-enhanced/state/budget.service';
import { BudgetQuery } from '../budget-page/tabs/budget-enhanced/state/budget.query';
import { ApprovalQuery } from '../budget-page/tabs/budget-enhanced/state/approval.query';
import { MainQuery } from '../../layouts/main-layout/state/main.query';
import { MainStore } from '../../layouts/main-layout/state/main.store';
import { InvoiceService } from '../vendor-payments-page/tabs/invoices/state/invoice.service';
import {
  WorkflowQuery,
  WorkflowService,
} from './tabs/quarter-close/close-quarter-check-list/store';
import { ROUTING_PATH } from '../../app-routing-path.const';
import {
  BudgetState,
  ExtendedBudgetData,
} from '../budget-page/tabs/budget-enhanced/state/budget.model';
import { ApprovalState } from '../budget-page/tabs/budget-enhanced/state/approval.model';
import { InvoiceAmounts } from '../vendor-payments-page/tabs/invoices/state/invoice.model';
import { QuarterCloseChecklistPeriodCloseService } from './tabs/quarter-close-checklist/services/quarter-close-checklist-period-close.service';
import { EventQuery } from '@models/event/event.query';

dayjs.extend(quarterOfYear);

export type MonthCloseTableRowData = {
  account_value: string;
  po_value: string;
  dept_value: string;
  adjustment_obj: Record<string, number>;
  accrual_adjusted_obj: Record<string, number>;
  invoice_amounts: Record<string, number>;
  net_accruals: Record<string, number>;
  initial_wp_obj: Record<string, number>;
  eom_accruals: Record<string, number>;
  eom_accrual: Record<string, number>;
  eom_prepaid: Record<string, number>;
  eom_unpaid: Record<string, number>;
  eom_accrual_debit: Record<string, number>;
  eom_accrual_credit: Record<string, number>;
  eom_prepaid_debit: Record<string, number>;
  eom_prepaid_credit: Record<string, number>;
  monthly_wp_obj: Record<string, number>;
  monthly_invoice_ltd: Record<string, number>;
  wp_ltd_start: number;
  invoice_ltd_start: number;
  starting_accruals: number;
  starting_accrual: number;
  starting_prepaid: number;
  invoice_ltd_end: number;
  invoice_unpaid_ltd_end: number;
  wp_ltd_end: number;
  invoice_ltd: number;
  ending_accruals: number;
  [permission: `${string}::${string}`]: boolean;
  [permission: `${string}::${string}::disabled`]: boolean;
  [attributes: `custom_attr_${string}`]: string;
} & ExtendedBudgetData;

type MonthCloseBottomData = Pick<
  MonthCloseTableRowData,
  | 'adjustment_obj'
  | 'accrual_adjusted_obj'
  | 'forecast_obj'
  | 'invoice_amounts'
  | 'net_accruals'
  | 'initial_wp_obj'
  | 'eom_accruals'
  | 'eom_accrual'
  | 'eom_prepaid'
  | 'eom_unpaid'
  | 'eom_accrual_debit'
  | 'eom_accrual_credit'
  | 'eom_prepaid_debit'
  | 'eom_prepaid_credit'
  | 'monthly_wp_obj'
  | 'monthly_invoice_ltd'
  | 'account_value'
  | 'po_value'
  | 'dept_value'
  | 'attributes'
>;

export interface InvestigatorEstimate {
  patient: number;
  other: number;
  overhead: number;
  total_all: number;
  show_adjustment?: boolean;
}

export interface MonthStats {
  date: string;
  eom_accruals: number;
  status: 'Closed' | 'Future' | 'Open';
}

export interface QuarterDate {
  parsedDate: dayjs.Dayjs;
  date: string;
  iso: string;
}

export interface QuarterStartData {
  wp_ltd: number;
  invoice_ltd: number;
  starting_accrual: number;
  starting_prepaid: number;
}

export interface QuarterStartDataForBottomRow {
  wp_ltd_start: number;
  invoice_ltd_start: number;
  starting_accrual: number;
  starting_prepaid: number;
}

export interface QuarterEndData {
  wp_ltd: number;
  invoice_ltd: number;
  ending_accruals: number;
  invoice_unpaid_ltd: number;
}

@UntilDestroy()
@Component({
  selector: 'aux-period-close',
  templateUrl: './period-close.component.html',
  styles: [
    `
      .month-name {
        font-size: 3.5rem;
        line-height: 3.5rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodCloseComponent implements OnInit, OnDestroy {
  upperShorNameMonths = Utils.SHORT_MONTH_NAMES.map((month) => month.toUpperCase());

  months: QuarterDate[] = [];

  monthsStat$ = new BehaviorSubject<MonthStats[]>([]);

  closeMonthProcessingMessage =
    'The period close process is currently running. It can take up to 5 minutes. You can navigate away from this page. ' +
    'You will receive an email notification when complete, and an Audit Package will be available in the Document Library.';

  columnsForMonth = [
    'accrual_adjusted_obj',
    'invoice_amounts',
    'net_accruals',
    'eom_accruals',
    'eom_accrual_debit',
    'eom_accrual_credit',
    'eom_prepaid_debit',
    'eom_prepaid_credit',
    'monthly_wp_obj',
    'monthly_invoice_ltd',
    'eom_accrual',
    'eom_prepaid',
    'eom_unpaid',
  ] as const;

  iCloseMonthsProcessing$ = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  invoicesLoading$ = new BehaviorSubject(false);

  invoices$ = new BehaviorSubject<
    Record<
      string,
      {
        id: string;
        accrual_period: string;
        organization_id: string;
        invoice_status: InvoiceStatus;
        expense_amounts: InvoiceAmounts;
        payment_date: string;
      }[]
    >
  >({});

  isQuarterCloseEnabled$ = this.workflowQuery.isQuarterCloseEnabled$;

  closedMonthColumns = [
    'forecast_obj',
    'adjustment_obj',
    'accrual_adjusted_obj',
    'invoice_amounts',
    'net_accruals',
    'eom_accruals',
    'eom_accrual_debit',
    'eom_accrual_credit',
    'eom_prepaid_debit',
    'eom_prepaid_credit',
    'eom_accrual',
    'eom_prepaid',
    'eom_unpaid',
    'initial_wp_obj',
    'monthly_wp_obj',
    'monthly_invoice_ltd',
  ] as const;

  bottomRowData$ = new BehaviorSubject<MonthCloseBottomData>(this.emptyBottomData());

  bottomRowDataForMergedDiscount$ = new BehaviorSubject<MonthCloseBottomData>(
    this.emptyBottomData()
  );

  quarter_start_data: QuarterStartData = {
    wp_ltd: 0,
    invoice_ltd: 0,
    starting_accrual: 0,
    starting_prepaid: 0,
  };

  quarter_end_data: QuarterEndData = {
    wp_ltd: 0,
    invoice_ltd: 0,
    ending_accruals: 0,
    invoice_unpaid_ltd: 0,
  };

  analytics$ = new BehaviorSubject<{
    initial_wp: number;
    total_adjustments: number;
    invoices_received: number;
    starting_accrual_balance: number;
    ending_accruals: number;
  }>({
    initial_wp: 0,
    total_adjustments: 0,
    invoices_received: 0,
    starting_accrual_balance: 0,
    ending_accruals: 0,
  });

  isAdminUser = false;

  currencies: Set<Currency> = new Set();

  loadingSPT$ = new BehaviorSubject(false);

  loading$ = combineLatest([
    this.budgetQuery.selectLoading(),
    this.loadingSPT$,
    this.invoicesLoading$,
  ]).pipe(map((boolArr) => boolArr.some((bool) => bool)));

  selectedCategoryType$ = new BehaviorSubject<CategoryType>(CategoryType.CATEGORY_SERVICE);

  refresh$ = new Subject();

  refreshNgOnInit$ = new Subject();

  listSitePatientTrackerMonthlyAmountsData$ = new BehaviorSubject<
    listSitePatientTrackerMonthlyAmountsQuery[]
  >([]);

  groupedInvestigatorEstimate$ = this.listSitePatientTrackerMonthlyAmountsData$.pipe(
    map((sitePatientTrackerMonthlyAmounts) => {
      const vendor_date_spts: Record<string, Record<string, InvestigatorEstimate>> = {};

      for (const sptMonthlyAmount of sitePatientTrackerMonthlyAmounts) {
        vendor_date_spts[sptMonthlyAmount.vendor_id] ||= {};
        vendor_date_spts[sptMonthlyAmount.vendor_id][
          sptMonthlyAmount.completion_month.toUpperCase()
        ] = {
          patient: round(sptMonthlyAmount.patient_amount || 0, 2),
          other: round(sptMonthlyAmount.other_amount || 0, 2),
          overhead: round(sptMonthlyAmount.overhead_amount || 0, 2),
          total_all: round(sptMonthlyAmount.total_amount || 0, 2),
        } as InvestigatorEstimate;
      }

      return vendor_date_spts;
    }),
    shareReplay(1)
  );

  selectedMonth$ = new BehaviorSubject<{
    month: string;
    category: string;
    vendor: string;
  } | null>(null);

  quarters: string[] = [];

  quartersObj: Record<string, QuarterDate[]> = {};

  quartersObjUpdated$ = new Subject();

  selectedQuarter = new FormControl('');

  currentQuarter = '';

  currentMonth = '';

  getWorkflowDate?: string;

  getWorkflowSub?: Subscription;

  tabs: TabGroupConfig[] = [
    { label: 'Checklist', route: ROUTING_PATH.CLOSING.CHECKLIST },
    { label: 'In-Month Adjustments', route: ROUTING_PATH.CLOSING.ADJUSTMENTS },
    { label: 'Month & Quarter Close', route: ROUTING_PATH.CLOSING.QUARTER_CLOSE },
    { label: 'Notes & History', route: ROUTING_PATH.CLOSING.NOTES, show: of(false) },
    {
      label: 'Reconciliation',
      route: ROUTING_PATH.CLOSING.RECONCILIATION,
      show: of(this.launchDarklyService.flags$.getValue().tab_budget_cash_management),
    },
    {
      label: 'Journal Entries',
      route: ROUTING_PATH.CLOSING.JOURNAL_ENTRIES,
      show: this.launchDarklyService.select$((flags) => flags.tab_journal_entries),
    },
  ];

  // when we change the trial we need to reset selectedQuarter
  // so this variable is holding the latest value for selected trial.
  lastSelectedQuarterValue: string | null = null;

  invoice_statuses: Array<InvoiceStatus> = [
    InvoiceStatus.STATUS_APPROVED,
    InvoiceStatus.STATUS_IN_QUEUE,
    InvoiceStatus.STATUS_PENDING_REVIEW,
    InvoiceStatus.STATUS_PENDING_APPROVAL,
  ];

  runningOnInit = false;

  monthClosed$ = new BehaviorSubject(false);

  gridInit = combineLatest([
    this.loading$,
    this.groupedInvestigatorEstimate$,
    this.budgetQuery.select(),
    this.approvalQuery.select(),
    this.invoices$,
  ]).pipe(
    untilDestroyed(this),
    switchMap(
      ([loading, groupedInvestigatorEstimate, budget_state, approval_state, groupedInvoices]) => {
        if (loading) {
          return EMPTY;
        }

        return from(this.authService.getLoggedInUser()).pipe(
          map((user) => {
            return {
              groupedInvestigatorEstimate,
              user,
              budget_state,
              approval_state,
              groupedInvoices,
            };
          })
        );
      }
    )
  );

  gridDataMergedDiscount$ = this.gridInit.pipe(
    map((params) => this.mapFn(params, true)),
    shareReplay(1)
  );

  gridData$ = this.gridInit.pipe(
    map((params) => this.mapFn(params)),
    shareReplay(1)
  );

  onSelectionChanged({ node: selectedRow, column }: ICellRendererParams) {
    const period = column?.getColId().split('.')[1];
    if (!period) {
      return;
    }
    const parsedDate = dayjs(`03/${period.replace('-', '/')}`);
    // this.isSelectedMonthInPast = parsedDate.date(1) < dayjs(this.currentMonth).date(1);
    if (selectedRow.group) {
      this.organizationStore.setActive(selectedRow.key);
      // When vendor's pencil selected, this'll be unsetable.
      this.selectedCategoryType$.next(CategoryType.CATEGORY_UNDETERMINED);
    } else {
      const data: BudgetData = selectedRow.data;
      this.organizationStore.setActive(data.vendor_id || '');
      this.selectedCategoryType$.next(
        `CATEGORY_${data.cost_category
          ?.toUpperCase()
          .replace('-', '')
          .replace('SERVICES', 'SERVICE')}` as CategoryType
      );
    }

    this.selectedMonth$.next({
      month: parsedDate.date(1).format('YYYY-MM-DD'),
      category: this.selectedCategoryType$.getValue(),
      vendor: this.organizationQuery.getActiveId() || '',
    });

    this.router.navigate([ROUTING_PATH.CLOSING.INDEX, ROUTING_PATH.CLOSING.ADJUSTMENTS]);
  }

  constructor(
    public organizationQuery: OrganizationQuery,
    private overlayService: OverlayService,
    private organizationStore: OrganizationStore,
    private organizationService: OrganizationService,
    private launchDarklyService: LaunchDarklyService,
    private budgetService: BudgetService,
    public budgetQuery: BudgetQuery,
    private eventService: EventService,
    private approvalQuery: ApprovalQuery,
    private mainService: MainService,
    private authService: AuthService,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private router: Router,
    public authQuery: AuthQuery,
    private mainStore: MainStore,
    private invoiceService: InvoiceService,
    private workflowQuery: WorkflowQuery,
    private periodCloseService: QuarterCloseChecklistPeriodCloseService,
    private workflowService: WorkflowService,
    private eventQuery: EventQuery
  ) {
    this.mainStore.update({ fullPage: true });

    this.eventQuery
      .selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH)
      .pipe(untilDestroyed(this))
      .subscribe((isProcessing) => {
        if (!this.runningOnInit && isProcessing === false) {
          this.refreshGridData();
        }
      });

    merge(
      this.eventService.select$(EventType.UPDATE_MONTH_ACCRUALS),
      this.eventService.select$(EventType.UPDATE_QUARTER_ACCRUALS)
    ).subscribe(() => {
      if (!this.runningOnInit && !this.eventQuery.isEventProcessing(EventType.CLOSE_TRIAL_MONTH)) {
        this.refreshGridData();
      }
    });

    this.mainQuery
      .select('trialKey')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.bottomRowData$.next(this.emptyBottomData());
        this.currencies.clear();
        this.lastSelectedQuarterValue = null;
      });
  }

  emptyBottomData() {
    return {
      adjustment_obj: {},
      accrual_adjusted_obj: {},
      forecast_obj: {},
      invoice_amounts: {},
      net_accruals: {},
      initial_wp_obj: {},
      eom_accruals: {},
      eom_accrual: {},
      eom_prepaid: {},
      eom_unpaid: {},
      eom_accrual_debit: {},
      eom_accrual_credit: {},
      eom_prepaid_debit: {},
      eom_prepaid_credit: {},
      monthly_wp_obj: {},
      monthly_invoice_ltd: {},
      account_value: '',
      po_value: '',
      dept_value: '',
      attributes: [],
    };
  }

  mapFn(
    {
      user,
      budget_state,
      approval_state,
      groupedInvoices,
      groupedInvestigatorEstimate,
    }: {
      user: LoggedInUser | null;
      budget_state: BudgetState;
      approval_state: ApprovalState;
      groupedInvoices: Record<
        string,
        {
          id: string;
          accrual_period: string;
          organization_id: string;
          invoice_status: InvoiceStatus;
          expense_amounts: InvoiceAmounts;
          payment_date: string;
        }[]
      >;
      groupedInvestigatorEstimate: Record<string, Record<string, InvestigatorEstimate>>;
    },
    mergeDiscountWithServices = false
  ) {
    const { budget_data, budget_info, header_data } = budget_state;

    if (!budget_info?.[0]) {
      return [];
    }

    const { current_month } = budget_info[0];

    let quarters: string[] = [];
    const quartersObj: Record<
      string,
      {
        parsedDate: dayjs.Dayjs;
        date: string;
        iso: string;
      }[]
    > = {};
    let currentQuarter = '';

    header_data
      .filter((x) => x.group_name === 'Timeline')[0]
      .date_headers.forEach((date) => {
        const parsedDate = this.parseBudgetHeaderDate(date);
        const q = parsedDate.quarter();
        const year = parsedDate.year();
        const str = `Q${q}-${year}`;
        quartersObj[str] ||= [];
        const iso = parsedDate.format('YYYY-MM-DD');

        if (current_month?.slice(0, 7) === iso.slice(0, 7)) {
          currentQuarter = str;
        }
        quartersObj[str].push({
          parsedDate,
          date,
          iso,
        });
        quarters.push(str);
      });
    quarters = uniq(quarters);

    const auxilius_start_date = this.mainQuery.getAuxiliusStartDate();

    const current_quarter_index = quarters.findIndex((q) => q === currentQuarter);
    this.quarters = quarters.slice(0, current_quarter_index + 1).reverse();

    if (auxilius_start_date) {
      this.quarters = this.quarters.filter((q) => {
        const monthIndex = quartersObj[q].length - 1;

        return (
          quartersObj[q][monthIndex].parsedDate.isSameOrAfter(auxilius_start_date, 'month') ||
          quartersObj[q][monthIndex].parsedDate.isSameOrAfter(current_month, 'month')
        );
      });
    }

    this.quartersObj = quartersObj;
    this.currentQuarter = currentQuarter;
    this.currentMonth = current_month || '';

    if (!this.lastSelectedQuarterValue) {
      this.selectedQuarter.setValue(currentQuarter, { emitEvent: false });
      this.updateIsCurrentQuarterSelected(currentQuarter);
      this.onSelectedQuarterChange(currentQuarter);
    }

    this.quartersObjUpdated$.next(null);

    this.quarter_start_data = {
      wp_ltd: 0,
      invoice_ltd: 0,
      starting_accrual: 0,
      starting_prepaid: 0,
    };

    this.quarter_end_data = {
      wp_ltd: 0,
      invoice_ltd: 0,
      ending_accruals: 0,
      invoice_unpaid_ltd: 0,
    };

    const newBudgetData = !mergeDiscountWithServices
      ? budget_data
      : this.mergeServicesWithDiscount(budget_data);

    const data: MonthCloseTableRowData[] = newBudgetData.map((row) => {
      this.currencies.add((row.contract_direct_cost_currency as Currency) || Currency.USD);
      const approvals: Approval[] = [];
      const usedApprovals: Approval[] = [];
      const arr: {
        name: string;
        permission: string;
        checked: boolean;
        disabled: boolean;
      }[] = [];
      const rules = this.launchDarklyService.flags$.getValue().approval_rule_in_month.rule;

      if (approval_state.approvals) {
        approval_state.approvals.forEach((approval) => {
          if (row.vendor_id === approval.vendor_id && row.cost_category === approval.category) {
            approvals.push(approval);
          }
        });
      }

      for (let i = 0; i < rules.length; i++) {
        let checked = false;
        let disabled = false;
        const { name, permission_type } = rules[i];

        for (let index = 0; index < approvals.length; index++) {
          if (approvals[index].permission === permission_type) {
            usedApprovals.push(...approvals.splice(index));
            checked = true;
            disabled = true;
            break;
          }
        }

        if (!checked && user && !user.IsSysAdmin()) {
          const isAuthorized = this.authService.isAuthorizedSync(user, {
            permissions: [permission_type],
          });

          if (isAuthorized) {
            disabled = usedApprovals.some(({ aux_user_id, permission }) => {
              return aux_user_id === user.getSub() && permission === permission_type;
            });
          } else {
            disabled = true;
          }
        }

        arr.push({
          name,
          permission: permission_type,
          checked,
          disabled: checked || disabled,
        });
      }

      const obj = arr.reduce(
        (acc, { name, permission, disabled, checked }) => {
          acc[`${name}::${permission}`] = checked;
          acc[`${name}::${permission}::disabled`] = disabled;

          return acc;
        },
        {} as Record<string, boolean>
      );

      // const date = budget_info[0].current_month?.slice(0, 7);
      const invoices = groupedInvoices[row.vendor_id || ''] || [];

      let invoice_ltd_start = 0;

      let invoice_ltd_end = 0;

      let invoice_unpaid_ltd_end = 0;

      const months = this.quartersObj[this.selectedQuarter.value || ''];

      const quarter_start = months[0].iso;

      const eom_unpaid: Record<string, number> = {};

      const invoice_amounts = invoices.reduce(
        (i_acc, val) => {
          const period = dayjs(val.accrual_period).format('MMM-YYYY').toUpperCase();
          const is_old = dayjs(quarter_start).date(1).diff(val.accrual_period) > 0;

          let total = 0;
          if (row.cost_category === 'Services') {
            const discountValue = mergeDiscountWithServices
              ? val.expense_amounts.discount_total.value
              : 0;
            total = val.expense_amounts.services_total.value + discountValue;
            i_acc[period] = (i_acc[period] || 0) + total;
            if (is_old) {
              invoice_ltd_start += total;
            }
          }
          if (row.cost_category === 'Discount' && !mergeDiscountWithServices) {
            total = val.expense_amounts.discount_total.value;
            i_acc[period] = (i_acc[period] || 0) + total;
            if (is_old) {
              invoice_ltd_start += total;
            }
          }
          if (row.cost_category === 'Investigator') {
            total = val.expense_amounts.investigator_total.value;
            i_acc[period] = (i_acc[period] || 0) + total;
            if (is_old) {
              invoice_ltd_start += total;
            }
          }
          if (row.cost_category === 'Pass-through') {
            total = val.expense_amounts.pass_thru_total.value;
            i_acc[period] = (i_acc[period] || 0) + total;
            if (is_old) {
              invoice_ltd_start += total;
            }
          }

          if (val.invoice_status !== InvoiceStatus.STATUS_DECLINED) {
            months.forEach((mon) => {
              const mPeriod = dayjs(mon.iso).format('MMM-YYYY').toUpperCase();
              const isSameBefore = dayjs(mon.iso)
                .date(1)
                .isSameOrAfter(dayjs(val.accrual_period).date(1));
              if (isSameBefore) {
                if (val.payment_date) {
                  const isPaySameBefore = dayjs(mon.iso)
                    .date(1)
                    .isSameOrAfter(dayjs(val.payment_date).date(1));
                  if (isPaySameBefore) {
                    return;
                  }
                }
                eom_unpaid[mPeriod] = (eom_unpaid[mPeriod] || 0) + total;
              }
            });
          }

          return i_acc;
        },
        {} as Record<string, number>
      );

      let forecast_obj = row.forecast_obj as Record<string, number>;
      const adjustment_obj = row.adjustment_obj as Record<string, number>;
      const { accrual_obj, accrual_adjusted_obj } = row;
      const initial_wp_obj: Record<string, number> = {};
      const monthly_wp_obj: Record<string, number> = {};
      const monthly_invoice_ltd: Record<string, number> = {};

      Object.keys(adjustment_obj).forEach((month) => {
        if (!accrual_obj[month]) {
          accrual_obj[month] = 0;
        }
        if (!accrual_adjusted_obj[month]) {
          accrual_adjusted_obj[month] = 0;
        }
      });

      if (row.cost_category === 'Investigator') {
        const investigator_forecast_obj: Record<string, number> = {};
        if (row.vendor_id && groupedInvestigatorEstimate[row.vendor_id]) {
          for (const estimateMonth of Object.keys(groupedInvestigatorEstimate[row.vendor_id])) {
            const estimate = groupedInvestigatorEstimate[row.vendor_id][estimateMonth];
            investigator_forecast_obj[estimateMonth] = estimate.total_all;
          }
        }

        forecast_obj = { ...forecast_obj, ...investigator_forecast_obj };
      }
      let wp_ltd_end = row.wp_ltd;
      const starting_accruals = row.wp_ltd - invoice_ltd_start;
      const starting_accrual = starting_accruals > 0 ? starting_accruals : 0;
      const starting_prepaid = starting_accruals > 0 ? 0 : Math.abs(starting_accruals);

      this.quarter_start_data.wp_ltd += row.wp_ltd;
      this.quarter_start_data.invoice_ltd += invoice_ltd_start;
      this.quarter_start_data.starting_accrual += starting_accrual;
      this.quarter_start_data.starting_prepaid += starting_prepaid;

      months.forEach((month) => {
        initial_wp_obj[month.date] =
          accrual_obj && Object.prototype.hasOwnProperty.call(accrual_obj, month.date)
            ? accrual_obj[month.date]
            : forecast_obj[month.date];

        if (dayjs(month.iso).date(1).isAfter(dayjs(current_month).date(1))) {
          accrual_adjusted_obj[month.date] = initial_wp_obj[month.date];
          adjustment_obj[month.date] = 0;
        }

        wp_ltd_end += accrual_adjusted_obj[month.date] ? accrual_adjusted_obj[month.date] : 0;
      });

      const eom_accruals: Record<string, number> = {};
      const eom_accrual: Record<string, number> = {};
      const eom_prepaid: Record<string, number> = {};
      const eom_accrual_debit: Record<string, number> = {};
      const eom_accrual_credit: Record<string, number> = {};
      const eom_prepaid_debit: Record<string, number> = {};
      const eom_prepaid_credit: Record<string, number> = {};
      const net_accruals = Object.keys(row.accrual_adjusted_obj).reduce(
        (acc, val) => {
          return {
            ...acc,
            [val]: row.accrual_adjusted_obj[val] - (invoice_amounts[val] || 0),
          };
        },
        {} as Record<string, number>
      );

      months.forEach((month, index) => {
        invoice_ltd_end += invoice_amounts[month.date] || 0;

        eom_accruals[month.date] =
          starting_accruals +
          months.slice(0, index + 1).reduce((acc, m) => {
            return acc + net_accruals[m.date] || 0;
          }, 0);

        eom_accrual[month.date] = eom_accruals[month.date] > 0 ? eom_accruals[month.date] : 0;
        eom_prepaid[month.date] =
          eom_accruals[month.date] > 0 ? 0 : Math.abs(eom_accruals[month.date]);
      });

      this.months = months;

      invoice_ltd_end += invoice_ltd_start;
      invoice_unpaid_ltd_end = eom_unpaid[last(months)?.date || ''] || 0;

      const ending_accruals = wp_ltd_end - invoice_ltd_end;

      this.quarter_end_data.invoice_unpaid_ltd += invoice_unpaid_ltd_end;
      this.quarter_end_data.ending_accruals += ending_accruals;
      this.quarter_end_data.wp_ltd += wp_ltd_end;
      this.quarter_end_data.invoice_ltd += invoice_ltd_end;

      const account_value =
        row?.attributes?.find((x) => x.attribute === 'account_no')?.attribute_value || '';

      const po_value = row?.attributes?.find((x) => x.attribute === 'po_no')?.attribute_value || '';

      const dept_value =
        row?.attributes?.find((x) => x.attribute === 'department')?.attribute_value || '';

      const extraAttributes =
        row.attributes?.reduce(
          (acc, a) => {
            if (a.attribute_name && a.attribute_value) {
              acc[`custom_attr_${btoa(a.attribute_name)}`] = a.attribute_value;
            }

            return acc;
          },
          {} as Record<string, string>
        ) || {};

      months.forEach((month, index) => {
        const wp = accrual_adjusted_obj[month.date] || 0;
        const ir = invoice_amounts[month.date] || 0;
        if (index === 0) {
          //first month of the quarter
          monthly_wp_obj[month.date] = (row.wp_ltd || 0) + wp;
          monthly_invoice_ltd[month.date] = (invoice_ltd_start || 0) + ir;
        } else {
          //second month of the quarter
          monthly_wp_obj[month.date] = monthly_wp_obj[months[index - 1].date] + wp;
          monthly_invoice_ltd[month.date] = monthly_invoice_ltd[months[index - 1].date] + ir;
        }
      });

      const monthStart: Record<string, number> = {};
      const monthEnd: Record<string, number> = {};
      months.forEach((month, index) => {
        if (index === 0) {
          monthStart[month.date] = starting_accruals || 0;
        } else {
          monthStart[month.date] = monthEnd[months[index - 1].date];
        }
        monthEnd[month.date] = monthStart[month.date] + (net_accruals[month.date] || 0);
      });

      const getPrepaidAndAccrualAccountBalance = (netAccrual: number) => {
        return {
          prepaid: netAccrual < 0 ? -netAccrual : 0,
          accrual: netAccrual > 0 ? netAccrual : 0,
        };
      };
      months.forEach((month) => {
        const netAccrualAtMonthStart = monthStart[month.date];
        const netAccrualAtMonthClose = monthEnd[month.date];
        const startingAccountBalances = getPrepaidAndAccrualAccountBalance(netAccrualAtMonthStart);
        const endingAccountBalances = getPrepaidAndAccrualAccountBalance(netAccrualAtMonthClose);
        const deltaPrepaid = endingAccountBalances.prepaid - startingAccountBalances.prepaid;
        const deltaAccrual = endingAccountBalances.accrual - startingAccountBalances.accrual;
        eom_accrual_credit[month.date] = deltaAccrual > 0 ? deltaAccrual : 0;
        eom_accrual_debit[month.date] = deltaAccrual < 0 ? -deltaAccrual : 0;
        eom_prepaid_credit[month.date] = deltaPrepaid < 0 ? -deltaPrepaid : 0;
        eom_prepaid_debit[month.date] = deltaPrepaid > 0 ? deltaPrepaid : 0;
      });

      return <MonthCloseTableRowData>{
        ...row,
        ...obj,
        wp_ltd_start: row.wp_ltd,
        invoice_ltd_start,
        starting_accruals,
        starting_accrual,
        starting_prepaid,
        forecast_obj,
        adjustment_obj,
        invoice_amounts,
        net_accruals,
        eom_accruals,
        eom_accrual,
        eom_prepaid,
        eom_unpaid,
        eom_accrual_debit,
        eom_accrual_credit,
        eom_prepaid_debit,
        eom_prepaid_credit,
        initial_wp_obj,
        invoice_ltd_end,
        invoice_unpaid_ltd_end,
        wp_ltd_end,
        ending_accruals,
        monthly_wp_obj,
        monthly_invoice_ltd,
        account_value,
        dept_value,
        po_value,
        ...extraAttributes,
      };
    });

    return this.getBudgetDataWithSummary(data);
  }

  mergeServicesWithDiscount(budget_data: ExtendedBudgetData[]) {
    const discountRows: Record<string, ExtendedBudgetData> = {};
    return budget_data
      .filter((row) => {
        if (row.cost_category === 'Discount') {
          discountRows[row.vendor_id || ''] = row;
          return false;
        }
        return true;
      })
      .map((row) => {
        if (row.cost_category === 'Services' && row.vendor_id && discountRows[row.vendor_id]) {
          const discountRow = discountRows[row.vendor_id];
          const keys = [
            'direct_cost',
            'contract_direct_cost',
            'accrual',
            'forecast',
            'total_monthly_accrual',
            'adjustment',
            'wp_ltd',
          ] as const;
          const obj_keys = [
            'forecast_obj',
            'accrual_obj',
            'accrual_adjusted_obj',
            'adjustment_obj',
            'accrual_override',
          ] as const;
          return {
            ...row,
            ...keys.reduce(
              (acc, key) => {
                acc[key] = (row[key] || 0) + (discountRow[key] || 0);
                return acc;
              },
              {} as ObjectFromList<typeof keys, number>
            ),
            ...obj_keys.reduce(
              (acc, key) => {
                acc[key] = {};
                Object.entries(row[key]).forEach(([month, v]) => {
                  acc[key][month] = v + discountRow[key][month];
                });
                return acc;
              },
              {} as Record<string, Record<string, number>>
            ),
          };
        }
        return row;
      });
  }

  private parseBudgetHeaderDate(date: string): dayjs.Dayjs {
    const [month, year] = date.split('-');

    const monthNumber = this.upperShorNameMonths.indexOf(month) + 1;
    const strMonth = `${monthNumber}`.padStart(2, '0');

    return dayjs(`03/${strMonth}/${year}`, 'DD/MM/YYYY');
  }

  getBudgetDataWithSummary(data: MonthCloseTableRowData[]) {
    const months = this.quartersObj[this.selectedQuarter.value || ''];

    return data.map((row) => {
      return this.columnsForMonth.reduce((accum, rowKey) => {
        return <MonthCloseTableRowData>{
          ...accum,
          [rowKey]: {
            ...row[rowKey],
            total: ['eom_accruals', 'eom_accrual', 'eom_prepaid', 'eom_unpaid'].includes(rowKey)
              ? accum[rowKey][months[months.length - 1].date]
              : months.reduce((sum, { date }) => {
                  sum += accum[rowKey][date] || 0;
                  return sum;
                }, 0),
          },
        };
      }, row);
    });
  }

  refreshGridData() {
    this.runningOnInit = true;
    setTimeout(() => {
      this.monthClosed$.next(false);
      this.refreshNgOnInit$.next(null);
      this.runningOnInit = false;
    }, 5000);
  }

  ngOnInit(): void {
    this.refreshNgOnInit$
      .pipe(
        startWith(null),
        untilDestroyed(this),
        switchMap(() => {
          this.organizationStore.setActive(null);
          return merge(
            this.authQuery.adminUser$.pipe(
              tap((event) => {
                this.isAdminUser = event;
                this.periodCloseService.isAdminUser.next(event);
              })
            ),
            this.mainQuery.select('trialKey').pipe(
              switchMap(() => {
                this.loadingSPT$.next(true);
                return this.gqlService.listSitePatientTrackerMonthlyAmounts$();
              }),
              tap(({ data, success, errors }) => {
                if (success && data) {
                  this.listSitePatientTrackerMonthlyAmountsData$.next(data);
                } else {
                  this.listSitePatientTrackerMonthlyAmountsData$.next([]);
                  this.overlayService.error(errors);
                }
                this.loadingSPT$.next(false);
              })
            ),
            this.organizationService.listIdOrganizations$().pipe(
              switchMap(() => {
                this.invoicesLoading$.next(true);

                return combineLatest([
                  this.gqlService
                    .listInvoicesForReconciliation$({
                      invoice_statuses: this.invoice_statuses,
                      deposit: false,
                    })
                    .pipe(
                      map(({ success, data, errors }) => {
                        // Store the ungrouped invoices to support
                        // the Review Invoices checklist row.
                        // The checklist row requires knowing the number
                        // of invoices, and their current statuses.
                        this.periodCloseService.ungroupedInvoices = data || [];

                        this.invoices$.next(
                          groupBy(
                            (data || [])
                              .filter((invoice) => invoice.accrual_period)
                              .map(
                                ({
                                  id,
                                  accrual_period,
                                  organization,
                                  invoice_status,
                                  expense_amounts,
                                  payment_date,
                                }) => {
                                  return {
                                    id,
                                    accrual_period: accrual_period || '',
                                    organization_id: organization.id,
                                    invoice_status: invoice_status || '',
                                    expense_amounts:
                                      this.invoiceService.mapExpenseAmountToObject(expense_amounts),
                                    payment_date: payment_date || '',
                                  };
                                }
                              ),
                            'organization_id'
                          ) as Record<
                            string,
                            {
                              id: string;
                              accrual_period: string;
                              organization_id: string;
                              invoice_status: InvoiceStatus;
                              expense_amounts: InvoiceAmounts;
                              payment_date: string;
                            }[]
                          >
                        );
                        if (!(success && data)) {
                          this.overlayService.error(errors);
                        }

                        this.invoicesLoading$.next(false);
                      })
                    ),
                ]);
              })
            ),
            this.refresh$.pipe(
              startWith(null),
              switchMap(() => {
                return combineLatest([
                  this.selectedQuarter.valueChanges.pipe(
                    distinctUntilChanged(),
                    startWith(null),
                    switchMap((selectedQuarter) => {
                      if (selectedQuarter) {
                        this.updateIsCurrentQuarterSelected(selectedQuarter);
                      }

                      this.lastSelectedQuarterValue = selectedQuarter;
                      this.onSelectedQuarterChange(selectedQuarter as string);

                      return this.budgetService.getInMonthBudgetData(
                        selectedQuarter ? this.quartersObj[selectedQuarter][0].iso : null
                      );
                    })
                  ),
                  this.budgetService.getMonthCloseApprovals(),
                ]);
              })
            ),
            this.gridData$.pipe(
              tap((rows) => {
                const { obj, analytics } = this.generatePinnedBottomData(
                  rows,
                  this.currencies.values().next().value
                );
                this.setMonthStats(obj.eom_accruals);
                this.analytics$.next(analytics);

                if (this.currencies.size === 1) {
                  this.bottomRowData$.next(obj);
                } else {
                  this.bottomRowData$.next(this.emptyBottomData());
                }
              })
            ),
            this.gridDataMergedDiscount$.pipe(
              tap((rows) => {
                const { obj } = this.generatePinnedBottomData(
                  rows,
                  this.currencies.values().next().value
                );

                if (this.currencies.size === 1) {
                  this.bottomRowDataForMergedDiscount$.next(obj);
                } else {
                  this.bottomRowDataForMergedDiscount$.next(this.emptyBottomData());
                }
              })
            )
          );
        })
      )
      .subscribe();

    this.iCloseMonthsProcessing$
      .pipe(untilDestroyed(this), skip(1), distinctUntilChanged())
      .subscribe((isProcessing) => {
        if (!isProcessing) {
          firstValueFrom(this.mainService.getOpenMonth$());
        }
      });
  }

  updateIsCurrentQuarterSelected(selectedQuarter: string) {
    const isCurrentQuarterSelected = selectedQuarter === this.currentQuarter;

    this.periodCloseService.selectedQuarterMonth = isCurrentQuarterSelected
      ? this.currentMonth
      : last(this.quartersObj[selectedQuarter])?.iso || '';

    this.periodCloseService.persistedQuarterMonth = '';

    this.periodCloseService.currentOpenMonth = this.currentMonth;

    this.periodCloseService.quarterMonths = this.quartersObj[this.selectedQuarter.value || ''];

    this.periodCloseService.isCurrentQuarterSelected.next(isCurrentQuarterSelected);
  }

  onSelectedQuarterChange(selectedQuarter: string): void {
    if (selectedQuarter) {
      const workflowDate = dayjs(this.periodCloseService.getSelectedQuarterMonth(this.router.url))
        .format('MMM-YYYY')
        .toUpperCase();

      if (this.getWorkflowDate === workflowDate) {
        return;
      }

      this.getWorkflowSub?.unsubscribe();
      this.getWorkflowDate = workflowDate;

      if (!this.router.url.includes(ROUTING_PATH.CLOSING.CHECKLIST)) {
        this.getWorkflowSub = this.workflowService
          .getWorkflowList(this.isAdminUser, workflowDate)
          .subscribe();
      }
    }
  }

  ngOnDestroy() {
    this.mainStore.update({ fullPage: false });
    this.getWorkflowSub?.unsubscribe();
    this.periodCloseService.resetAllState();
  }

  generatePinnedBottomData(rows: MonthCloseTableRowData[], currency: Currency) {
    const data = rows.reduce(
      (acc, row) => {
        this.closedMonthColumns.forEach((field) => {
          for (const period of Object.keys(row[field])) {
            if (field === 'eom_accrual') {
              acc.eom_accruals_trial_currency[period] =
                (acc.eom_accruals_trial_currency[period] || 0) +
                (row.eom_accrual[period] || 0) * (row.exchange_rate[period] || 1);
              acc[field][period] = (acc[field][period] || 0) + (row[field][period] || 0);
            } else if (field === 'eom_prepaid') {
              acc.eom_prepaid_trial_currency[period] =
                (acc.eom_prepaid_trial_currency[period] || 0) +
                (row.eom_prepaid[period] || 0) * (row.exchange_rate[period] || 1);
              acc[field][period] = (acc[field][period] || 0) + (row[field][period] || 0);
            } else if (field === 'eom_unpaid') {
              acc.eom_unpaid_trial_currency[period] =
                (acc.eom_unpaid_trial_currency[period] || 0) +
                (row.eom_unpaid[period] || 0) * (row.exchange_rate[period] || 1);
              acc[field][period] = (acc[field][period] || 0) + (row[field][period] || 0);
            } else {
              acc[field][period] = (acc[field][period] || 0) + (row[field][period] || 0);
            }
          }
        });
        return acc;
      },
      {
        forecast_obj: {},
        adjustment_obj: {},
        accrual_adjusted_obj: {},
        invoice_amounts: {},
        net_accruals: {},
        eom_accruals: {},
        eom_accruals_trial_currency: {},
        eom_accrual: {},
        eom_prepaid: {},
        eom_unpaid: {},
        eom_prepaid_trial_currency: {},
        eom_unpaid_trial_currency: {},
        eom_accrual_debit: {},
        eom_accrual_credit: {},
        eom_prepaid_debit: {},
        eom_prepaid_credit: {},
        initial_wp_obj: {},
        monthly_wp_obj: {},
        monthly_invoice_ltd: {},
      } as {
        forecast_obj: Record<string, number>;
        adjustment_obj: Record<string, number>;
        accrual_adjusted_obj: Record<string, number>;
        invoice_amounts: Record<string, number>;
        net_accruals: Record<string, number>;
        eom_accruals: Record<string, number>;
        eom_accruals_trial_currency: Record<string, number>;
        eom_accrual: Record<string, number>;
        eom_prepaid: Record<string, number>;
        eom_unpaid: Record<string, number>;
        eom_prepaid_trial_currency: Record<string, number>;
        eom_unpaid_trial_currency: Record<string, number>;
        eom_accrual_debit: Record<string, number>;
        eom_accrual_credit: Record<string, number>;
        eom_prepaid_debit: Record<string, number>;
        eom_prepaid_credit: Record<string, number>;
        initial_wp_obj: Record<string, number>;
        monthly_wp_obj: Record<string, number>;
        monthly_invoice_ltd: Record<string, number>;
        [k: string]: Record<string, number>;
      }
    );

    const { ending_accruals, starting_accrual_balance } = rows.reduce(
      (acc, row) => {
        acc.ending_accruals += row.ending_accruals;
        acc.starting_accrual_balance += row.starting_accruals;
        return acc;
      },
      {
        ending_accruals: 0,
        starting_accrual_balance: 0,
      }
    );

    const analytics = {
      initial_wp: 0,
      total_adjustments: 0,
      invoices_received: 0,
      ending_accruals,
      starting_accrual_balance,
    };

    (this.quartersObj[this.selectedQuarter.value || ''] || []).forEach((month) => {
      analytics.initial_wp += data.initial_wp_obj[month.date] || 0;
      analytics.total_adjustments += data.adjustment_obj[month.date] || 0;
    });

    analytics.invoices_received = this.quarter_end_data.invoice_ltd;

    const obj = {
      ...data,
      wp_ltd_start: this.quarter_start_data.wp_ltd,
      invoice_ltd_start: this.quarter_start_data.invoice_ltd,
      starting_accrual: this.quarter_start_data.starting_accrual,
      starting_prepaid: this.quarter_start_data.starting_prepaid,
      ending_accruals: this.quarter_end_data.ending_accruals,
      wp_ltd_end: this.quarter_end_data.wp_ltd,
      invoice_ltd_end: this.quarter_end_data.invoice_ltd,
      invoice_unpaid_ltd_end: this.quarter_end_data.invoice_unpaid_ltd,
      cost_category: 'Total',
      currency,
      account_value: '',
      po_value: '',
      dept_value: '',
    };

    const eom_net_accrual_trial_currency: Record<string, number> = {};
    Object.keys(obj.eom_accruals_trial_currency).forEach((month) => {
      eom_net_accrual_trial_currency[month] =
        obj.eom_accruals_trial_currency[month] - obj.eom_prepaid_trial_currency[month];
    });

    this.setMonthStats(eom_net_accrual_trial_currency);

    return {
      obj,
      analytics,
    };
  }

  private setMonthStats(eom_accruals: Record<string, number>) {
    const formattedCurrentMonth = dayjs(this.currentMonth).format('MMM-YYYY').toUpperCase();

    this.monthsStat$.next(
      this.months.map(({ date }) => {
        let status = 'Open';

        const month = dayjs(date);

        if (month.isBefore(formattedCurrentMonth)) {
          status = 'Closed';
        }

        if (month.isAfter(formattedCurrentMonth)) {
          status = 'Future';
        }

        return {
          date: dayjs(`01-${date}`).toISOString(),
          eom_accruals: eom_accruals[date] || 0,
          status: status as MonthStats['status'],
        };
      })
    );
  }
}
