<div class="flex">
  <div>
    <h3 class="mb-7 text-lg">Type Scale</h3>
    <aux-typography-type-scale
      *ngFor="let scale of typeScaleList"
      [title]="scale.title"
      [description]="scale.description"
      [classNames]="scale.classNames"
    />
  </div>
  <aux-divider class="flex-initial" [orientation]="DividerOrientation.VERTICAL" />
  <div class="w-2/6">
    <h3 class="mb-7 text-lg">Rules of Engagement</h3>
    <div *ngFor="let rule of typographyRules">
      <aux-typography-rule
        *ngIf="rule.title !== 'Links'"
        [title]="rule.title"
        [description]="rule.description"
        [classNames]="rule?.classNames || ''"
      />
      <div *ngIf="rule.title === 'Links'">
        <p class="text-lg font-bold mb-7">Links</p>
        <p>
          Links are
          <span class="underline text-aux-blue cursor-pointer">&#64;aux-blue and underlined</span>
        </p>
        <aux-divider />
      </div>
    </div>
  </div>
</div>
