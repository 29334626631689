<div class="grid bg-white w-full rounded-lg">
  <span class="text-aux-gray-dark-100 pl-4 pt-3 pb-[10px]"> {{ card?.title }} </span>

  <div class="grid grid-cols-2 space-x-1 pl-4 pb-3 pr-[11px]">
    <div class="flex flex-col col-span-1">
      <span class="text-aux-blue-dark pb-2">
        {{ card?.compareFrom?.title }}
      </span>
      <span class="text-aux-gray-darkest pb-1 font-medium text-[20px]">
        {{ card?.compareFrom?.value }}
      </span>
      <span class="text-aux-green-dark text-sm">
        {{ card?.compareFrom?.percent }}
      </span>
    </div>
    <div class="flex flex-col col-span-1">
      <span class="text-aux-blue-dark pb-2">
        {{ card?.compareTo?.title }}
      </span>
      <span class="text-aux-gray-darkest pb-1 font-medium text-[20px]">
        {{ card?.compareTo?.value }}
      </span>
      <span class="text-aux-green-dark text-sm">
        {{ card?.compareTo?.percent }}
      </span>
    </div>
  </div>
</div>
