import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import {
  GqlService,
  listClosingChecklistUpdatesQuery,
  listUserNamesWithEmailQuery,
  listUsersQuery,
  listWorkflowDetailsQuery,
  WorkflowDetailType,
} from '@services/gql.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from '../../../../../../layouts/main-layout/state/main.query';
import { Workflow, WorkflowModel, WorkflowProperty, WorkflowStore } from './workflow.store';
import {
  ChecklistComponentChangeLockStatusFn,
  QuarterCloseChecklistRowPermissionFields,
  QuarterCloseChecklistRowSections,
  QuarterCloseChecklistRowTitles,
  ShowWarningModalResponse,
} from '../../../quarter-close-checklist/models/quarter-close-checklist.model';
import { TrialUserService } from '@models/trial-users/trial-user.service';

@Injectable({ providedIn: 'root' })
export class WorkflowService {
  listUsers$ = new BehaviorSubject<listUsersQuery[]>([]);

  trialMonthClose$ = new BehaviorSubject<string>('');

  constructor(
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private workflowStore: WorkflowStore,
    private overlayService: OverlayService,
    private trialUserService: TrialUserService
  ) {}

  getFullNameAuthor(userId: string, users: listUserNamesWithEmailQuery[], isAdminUser: boolean) {
    const user = users.find(({ sub }) => sub === userId);

    if (!user) {
      return '';
    }

    return user.email.includes('@auxili.us') && !isAdminUser
      ? 'Auxilius Expert'
      : `${user.given_name} ${user.family_name}`;
  }

  private transformEntities({
    workflowList,
    userList,
    isAdminUser,
    workflowUpdateList,
  }: {
    workflowList: listWorkflowDetailsQuery[];
    userList: listUserNamesWithEmailQuery[];
    isAdminUser: boolean;
    workflowUpdateList: listClosingChecklistUpdatesQuery[];
  }): Workflow[] {
    return workflowList.map((workflow) => {
      const workflowProperties = JSON.parse(workflow.properties)?.properties as WorkflowProperty;
      const workflowName: QuarterCloseChecklistRowTitles =
        [...Object.values(QuarterCloseChecklistRowTitles)].find((title) => {
          return title === workflow.name;
        }) || QuarterCloseChecklistRowTitles.GatherContracts;

      const section =
        Object.entries(QuarterCloseChecklistRowSections).find(([key]) => {
          return key === workflowName;
        })?.[1] ||
        QuarterCloseChecklistRowSections[
          'Pending Site Contracts, Change Orders, and Purchase Orders'
        ];

      const permissionField =
        Object.entries(QuarterCloseChecklistRowPermissionFields).find(([key]) => {
          return key === workflowName;
        })?.[1] ||
        QuarterCloseChecklistRowPermissionFields[
          'Pending Site Contracts, Change Orders, and Purchase Orders'
        ];

      let { update_date, updated_by } =
        workflowUpdateList.find((w) => w.step === workflow.step_type) || {};
      if (workflowProperties.last_update_info) {
        update_date = workflowProperties.last_update_info.update_date;
        updated_by = workflowProperties.last_update_info.updated_by;
      }
      return {
        ...workflow,
        properties: workflowProperties,
        updatedAuthorFullName: this.getFullNameAuthor(workflow.updated_by, userList, isAdminUser),
        last_updated_by_full_name: this.getFullNameAuthor(updated_by || '', userList, isAdminUser),
        last_update_date: update_date || '',
        last_updated_by: updated_by || '',
        workflowName,
        section,
        permissionField,
      };
    });
  }

  async getTrialInformation() {
    return this.mainQuery.getValue().currentOpenMonth;
  }

  getWorkflowList(isAdminUser: boolean, workflowDate?: string) {
    return this.mainQuery.select('currentOpenMonth').pipe(
      switchMap((currentOpenMonth) => {
        const trialKey = this.mainQuery.getValue().trialKey;

        this.workflowStore.setLoading(true);

        let workflowCurrentTrialDate = '';

        if (currentOpenMonth) {
          this.trialMonthClose$.next(currentOpenMonth);

          workflowCurrentTrialDate = dayjs(currentOpenMonth).format('MMM-YYYY').toUpperCase();
        }

        return combineLatest([
          this.gqlService.listWorkflowDetails$({
            entity_id: trialKey,
            type: WorkflowDetailType.WF_MONTH_CLOSE_LOCK,
            properties: JSON.stringify({
              month: workflowDate || workflowCurrentTrialDate,
            }),
          }),
          this.gqlService.listClosingChecklistUpdates$(),
          this.trialUserService.get(),
          this.mainQuery.select('userList'),
        ]).pipe(
          tap(
            ([
              { data, errors: listWorkflowErrors },
              { data: workflowUpdateList, errors: listWorkflowUpdateErrors },
              { data: listActiveUsersWithPermissions, errors: listUsersQueryErrors },
              allUsersList,
            ]) => {
              if (
                data &&
                listActiveUsersWithPermissions &&
                currentOpenMonth &&
                workflowUpdateList
              ) {
                this.workflowStore.set(
                  this.transformEntities({
                    workflowList: data,
                    userList: allUsersList as listUserNamesWithEmailQuery[],
                    isAdminUser,
                    workflowUpdateList,
                  })
                );

                this.listUsers$.next(listActiveUsersWithPermissions || []);
              }

              const errors = listWorkflowErrors || listWorkflowUpdateErrors || listUsersQueryErrors;
              if (errors) {
                this.overlayService.error(errors);
              }

              this.workflowStore.setLoading(false);
            }
          )
        );
      })
    );
  }

  getWorkflowListFromStore(
    isAdminUser: boolean
  ): Observable<GraphqlResponse<listWorkflowDetailsQuery[] | Workflow[]>> {
    const workflowData = Object.values(this.workflowStore.getValue().entities || {});
    const isStoreEmpty = !!workflowData.length;

    if (!isStoreEmpty) {
      return this.getWorkflowList(isAdminUser).pipe(
        map(([workflowList]) => {
          return workflowList;
        })
      );
    }

    return of({ success: true, errors: [], data: workflowData });
  }

  async updateWorkflowLockStatus(
    locked: boolean,
    workflow: WorkflowModel,
    isAdminUser: boolean,
    isAssignProcess: boolean
  ) {
    const soft_update = isAssignProcess;
    const { success, data } = await firstValueFrom(
      this.gqlService.updateWorkflowDetail$({
        id: workflow.id,
        properties: JSON.stringify({ properties: { ...workflow.properties, locked } }),
        soft_update,
      })
    );
    if (success && data) {
      this.workflowStore.update(workflow.id, {
        properties: { ...workflow.properties, locked },
        updatedAuthorFullName: this.getFullNameAuthor(
          data.updated_by,
          this.listUsers$.getValue(),
          isAdminUser
        ),
        update_date: data.update_date,
      });
    }
  }

  showWarningModal(workflowName: string, message: string): ShowWarningModalResponse {
    let name = workflowName;

    if (name.includes('Confirm ')) {
      const finalNameIndex = 1;
      name = name.split('Confirm ')[finalNameIndex];
    }

    if (name.includes('Review ')) {
      const finalNameIndex = 1;
      name = name.split('Review ')[finalNameIndex];
    }

    const modal = this.overlayService.openConfirmDialog({
      header: `Unlock ${name}?`,
      message,
      okBtnText: 'Continue',
    });

    return firstValueFrom(modal.afterClosed$);
  }

  changeLockStatus: ChecklistComponentChangeLockStatusFn = async (
    shouldLock: boolean,
    workflow: WorkflowModel,
    isAdminUser: boolean,
    isAssign: boolean
  ) => {
    // Allow any rows to lock themselves
    if (!workflow.properties.locked) {
      await this.updateWorkflowLockStatus(shouldLock, workflow, isAdminUser, isAssign);
      return;
    }

    // Allow CloseDiscounts row to be unlocked at any time
    if (workflow.name === QuarterCloseChecklistRowTitles.CloseDiscounts) {
      await this.updateWorkflowLockStatus(shouldLock, workflow, isAdminUser, isAssign);
      return;
    }

    this.getWorkflowListFromStore(isAdminUser).subscribe(async (workflowData) => {
      if (!workflowData.success || workflowData.errors.length || !workflowData.data) {
        return;
      }

      const workflowList = workflowData.data as WorkflowModel[];

      // Get CloseExpenses and CloseDiscounts workflows
      const closeExpensesWorkflow = workflowList.find(
        (checklistWorkflow) =>
          checklistWorkflow.name === QuarterCloseChecklistRowTitles.CloseExpenses
      );
      const closeDiscountsWorkflow = workflowList.find(
        (checklistWorkflow) =>
          checklistWorkflow.name === QuarterCloseChecklistRowTitles.CloseDiscounts
      );

      if (closeExpensesWorkflow && closeDiscountsWorkflow) {
        // If CloseExpenses and CloseDiscounts are unlocked, allow any unlock
        if (!closeExpensesWorkflow.properties.locked && !closeDiscountsWorkflow.properties.locked) {
          await this.updateWorkflowLockStatus(shouldLock, workflow, isAdminUser, isAssign);
          return;
        }

        // Allow CloseExpenses to unlock if CloseDiscounts is unlocked.
        if (
          workflow.name === QuarterCloseChecklistRowTitles.CloseExpenses &&
          !closeDiscountsWorkflow.properties.locked
        ) {
          await this.updateWorkflowLockStatus(shouldLock, workflow, isAdminUser, isAssign);
          return;
        }

        // Unlocking CloseExpenses will unlock CloseExpenses and CloseDiscounts
        if (
          workflow.name === QuarterCloseChecklistRowTitles.CloseExpenses &&
          closeDiscountsWorkflow.properties.locked
        ) {
          // Warn user
          const message = `Unlocking this item will automatically unlock Discounts. All checklist entries must be locked in order to close the period.`;
          const warning = await this.showWarningModal(workflow.name, message);

          if (warning.data?.result) {
            // Unlock CloseDiscounts and CloseExpenses
            await this.updateWorkflowLockStatus(
              shouldLock,
              closeDiscountsWorkflow,
              isAdminUser,
              isAssign
            );
            await this.updateWorkflowLockStatus(shouldLock, workflow, isAdminUser, isAssign);

            return;
          }

          return;
        }

        // All other unlocks while CloseExpenses is locked, will unlock both
        if (closeExpensesWorkflow.properties.locked && !closeDiscountsWorkflow.properties.locked) {
          // Warn user
          const message = `Unlocking this item will automatically unlock Vendor Accruals. All checklist entries must be locked in order to close the period.`;
          const warning = await this.showWarningModal(workflow.name, message);

          if (warning.data?.result) {
            // Unlock CloseExpenses and workflow
            await this.updateWorkflowLockStatus(
              shouldLock,
              closeExpensesWorkflow,
              isAdminUser,
              isAssign
            );
            await this.updateWorkflowLockStatus(shouldLock, workflow, isAdminUser, isAssign);

            return;
          }

          return;
        }

        // All other unlocks while CloseExpenses and CloseDiscounts, will unlock all three
        if (closeExpensesWorkflow.properties.locked && closeDiscountsWorkflow.properties.locked) {
          // Warn user
          const message = `Unlocking this item will automatically unlock Vendor Expenses and Discounts. All checklist entries must be locked in order to close the period.`;
          const warning = await this.showWarningModal(workflow.name, message);

          if (warning.data?.result) {
            // Unlock CloseDiscounts, CloseExpenses, and workflow
            await this.updateWorkflowLockStatus(
              shouldLock,
              closeDiscountsWorkflow,
              isAdminUser,
              isAssign
            );
            await this.updateWorkflowLockStatus(
              shouldLock,
              closeExpensesWorkflow,
              isAdminUser,
              isAssign
            );
            await this.updateWorkflowLockStatus(shouldLock, workflow, isAdminUser, isAssign);
          }
        }
      }
    });
  };
}
