import { Injectable } from '@angular/core';
import { getSiteCostSummaryQuery, SortOrder } from '@services/gql.service';
import { GenericOperator } from '@services/operator.class';
import { Utils } from '@services/utils';
import * as dayjs from 'dayjs';
import { BehaviorSubject, combineLatest, Observable, Subscriber } from 'rxjs';
import { TrialInsightsQuery } from '../../store/trial-insights.query';
import { TrialInsightsClinicalSiteCostChartService } from './site-cost-chart.service';
import { TrialInsightsClinicalSiteCostTableService } from './site-cost-table.service';
import { TrialInsightsState } from '../../models/trial-insights-store.model';
import {
  TrialInsightsSiteCostKey,
  TrialInsightsTableOptions,
} from '../../models/trial-insights-table.model';
import {
  GenericTrialInsightsQuery,
  SortOrderDefault,
} from '../../classes/trial-insights-query.class';

type state = Partial<TrialInsightsState['siteCost']>;
type rowData = TrialInsightsTableOptions['rowData']['data'];
type results = [state['data'], TrialInsightsSiteCostKey, SortOrder];

const sortDefaults: SortOrderDefault[] = [
  {
    buttonKey: TrialInsightsSiteCostKey.SPEND,
    defaultOrder: SortOrder.DESC,
  },
  {
    buttonKey: TrialInsightsSiteCostKey.LAG,
    defaultOrder: SortOrder.ASC,
  },
];

@Injectable()
export class TrialInsightsClinicalSiteCostQueryService extends GenericTrialInsightsQuery {
  constructor(
    public trialInsightsQuery: TrialInsightsQuery,
    public tableService: TrialInsightsClinicalSiteCostTableService,
    public chartService: TrialInsightsClinicalSiteCostChartService
  ) {
    super({
      trialInsightsQuery,
      slice: 'siteCost',
      sortDefaults,
      chartService,
      tableService,
    });
  }

  processSiteCostSummary$(): Observable<results> {
    return combineLatest([
      this.data as Observable<getSiteCostSummaryQuery[]>,
      this.selectedKey as BehaviorSubject<TrialInsightsSiteCostKey>,
      this.sortOrder,
    ]).pipe(this.processResponseData());
  }

  processResponseData() {
    const processFn = (sourceValue: results, subscriber: Subscriber<results>) => {
      const [data, selectedKey, sortOrder] = sourceValue;

      if (!data || !data.length) {
        this.resetState();
        return;
      }

      const parsedData = this.parseStoreData(data);
      const costData = this.parseStoreDetail(parsedData, selectedKey, sortOrder);

      if (!costData) {
        return;
      }

      // Costs
      const costs =
        selectedKey === TrialInsightsSiteCostKey.SPEND ? costData.site_costs : costData.sites_lag;

      // Sites Activated
      const sitesActivated = costData.sites_activated;
      const siteString = 'Enrolled Site';
      const totalSitesString = sitesActivated === 1 ? siteString : `${siteString}s`;
      this.totalAmount.next(`${sitesActivated} ${totalSitesString}`);

      // Legend Options
      const forecastActivated = costData.expected_sites_activated;
      const forecastClosed = costData.expected_sites_closed;

      const legendOptions = this.chartService.createLegend(forecastActivated, forecastClosed);
      this.legendOptions.next(legendOptions);

      const rowData: rowData = costs.map((row) => {
        let rightHeader = '';
        let rightSubheader = '';

        if ('total_cost' in row) {
          rightHeader = Utils.currencyFormatter(row.total_cost);
          rightSubheader = `${row.patients_enrolled} Patients`;
        } else {
          const date = dayjs(row.latest_transaction);

          if (date.isValid()) {
            rightHeader = date.format('MMM DD, YYYY');
          }
        }

        return {
          buttonKey: selectedKey,
          leftHeader: row.site_name || '',
          leftSubheader:
            row.primary_investigator?.given_name && row.primary_investigator?.family_name
              ? `${row.primary_investigator?.given_name} ${row.primary_investigator?.family_name}`
              : '',
          rightHeader,
          rightSubheader,
        };
      });

      // Table Options
      const tableOptions = this.tableService.createTable(selectedKey, rowData);
      this.tableOptions.next(tableOptions);

      subscriber.next(sourceValue);
    };

    const operatorConfig = new GenericOperator(processFn);
    return operatorConfig.operator();
  }
}
