import { Component } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { TooltipDirective } from '@components/tooltip/tooltip.directive';

export type ICheckboxCellRendererParams = ICellRendererParams & {
  tooltipField?: string;
  checkboxTooltip: () => string;
};

// todo move this component to shared folder
@Component({
  template: `
    <div class="flex items-center">
      <div class="ag-selection-checkbox flex" *ngIf="!pinned">
        <!--AG-CHECKBOX-->
        <div
          class="ag-labeled ag-label-align-right ag-checkbox ag-input-field"
          [ngClass]="{ 'ag-disabled': !selectable }"
          [auxTooltip]="checkboxTooltip"
        >
          <div
            class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
            [ngClass]="{
              'ag-disabled': !selectable,
              'ag-checked': isSelected,
              'ag-indeterminate': isSelected === undefined
            }"
            (click)="selectRow()"
          >
            <input
              class="ag-input-field-input ag-checkbox-input"
              type="checkbox"
              [disabled]="!selectable"
              [value]="isSelected"
              [indeterminate]="isSelected === undefined"
            />
          </div>
        </div>
      </div>

      <span
        class="ag-cell-value"
        role="presentation"
        [style.paddingLeft.px]="paddingLeft"
        [auxTooltip]="tooltip"
      >
        <span class="ag-cell-wrapper">
          <span
            class="ag-group-expanded"
            *ngIf="params.node.expanded && isGroup"
            (click)="onClick()"
          >
            <span class="ag-icon ag-icon-tree-open" role="presentation"></span>
          </span>
          <span
            class="ag-group-contracted"
            *ngIf="!params.node.expanded && isGroup"
            (click)="onClick()"
          >
            <span class="ag-icon ag-icon-tree-closed" role="presentation"></span>
          </span>
          <span class="ag-group-value" [ngClass]="{ 'ml-[11px]': !isGroup }">
            {{ params.value }}
          </span>
        </span>
      </span>
    </div>
  `,
  standalone: true,
  imports: [NgIf, TooltipDirective, NgClass],
})
export class AgBeCheckboxGroupRendererComponent implements ICellRendererAngularComp {
  params!: ICheckboxCellRendererParams;
  paddingLeft!: number;
  isGroup!: boolean;
  pinned = false;
  tooltip = '';
  checkboxTooltip = '';
  isSelected: boolean | undefined = false;
  selectable = false;

  agInit(params: ICheckboxCellRendererParams): void {
    this.params = params;
    this.paddingLeft = params.node.level * 10;
    this.isGroup = !!params.node.group;
    this.pinned = params.node.isRowPinned();
    this.tooltip = params.data && params.tooltipField ? params.data[params.tooltipField] : '';
    this.isSelected = params.node.isSelected();
    this.selectable = params.node.selectable;
    this.checkboxTooltip = params.checkboxTooltip();

    this.params.node.addEventListener('selectableChanged', this.onSelectableChanged);
    this.params.node.addEventListener('rowSelected', this.onRowSelected);
  }

  onSelectableChanged = () => {
    this.selectable = this.params.node.selectable;
  };

  onRowSelected = () => {
    this.isSelected = this.params.node.isSelected();
  };

  refresh() {
    return false;
  }

  destroy() {
    this.params.node.removeEventListener('rowSelected', this.onRowSelected);
    this.params.node.removeEventListener('selectableChanged', this.onSelectableChanged);
  }

  onClick() {
    this.params.node.setExpanded(!this.params.node.expanded);
  }

  selectRow() {
    if (this.params.node.selectable) {
      this.params.node.setSelected(!this.params.node.isSelected());
      this.isSelected = this.params.node.isSelected();
    }
  }
}
