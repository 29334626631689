import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'aux-how-it-works-modal',
  templateUrl: './how-it-works-modal.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgIf],
})
export class HowItWorksModalComponent {
  isApManualClearingVisible$ = this.launchDarklyService.select$(
    (flags) => flags.journal_entry_ap_manual_clearing
  );

  constructor(
    public ref: CustomOverlayRef,
    private launchDarklyService: LaunchDarklyService
  ) {}

  close(): void {
    this.ref.close();
  }
}
