import { Injectable } from '@angular/core';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { switchMap, tap } from 'rxjs/operators';
import {
  CreateTimelineMilestoneInput,
  createTimelineMilestoneMutation,
  EntityType,
  EventType,
  GqlService,
  listTimelineMilestonesQuery,
  UpdateTimelineMilestoneInput,
  updateTimelineMilestoneMutation,
} from '@services/gql.service';
import { firstValueFrom, Observable, of } from 'rxjs';
import { OverlayService } from '@services/overlay.service';
import {
  MilestoneModel,
  MilestoneStore,
} from '@models/milestone-category/milestone/milestone.store';
import { MilestoneCategoryStore } from '@models/milestone-category/milestone-category.store';
import { arrayAdd, arrayRemove, arrayUpdate } from '@datorama/akita';
import { Utils } from '@services/utils';
import { MilestoneCategoryQuery } from '@models/milestone-category/milestone-category.query';
import { TimelineStore } from './timeline.store';
import { TimelineGridData } from '../timeline.component';
import { omit, uniqBy } from 'lodash-es';
import { batchPromises } from '@shared/utils';
import { EMPTY_UUID } from '@shared/constants';

export type UpdateDependencyInput = {
  id: string;
  field_name: string;
  contract_end_date: string;
  contract_start_date: string;
  track_from_milestone_id: string | null;
};

export type UpdateTimelineMilestone = Omit<
  UpdateTimelineMilestoneInput,
  'name' | 'milestone_category_id' | 'description'
> & {
  name: string;
  milestone_category_id: string;
  description: string | null;
  milestone_id: string;
};

type GetMilestones = (
  timeline_id: string
) => Observable<{ success: boolean; data: listTimelineMilestonesQuery[] | null; errors: string[] }>;

@Injectable({ providedIn: 'root' })
export class TimelineService {
  constructor(
    private timelineStore: TimelineStore,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private overlayService: OverlayService,
    private milestoneStore: MilestoneStore,
    private milestoneCategoryStore: MilestoneCategoryStore,
    private milestoneCategoryQuery: MilestoneCategoryQuery
  ) {}

  getTimelineItems(getMilestones: GetMilestones = this.gqlService.listTimelineMilestones$) {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.timelineStore.setLoading(true);
        return this.gqlService.getTimeline$().pipe(
          switchMap(({ data, success, errors }) => {
            if (success && data) {
              return getMilestones(data.id).pipe(
                tap((x) => {
                  if (x.data) {
                    this.timelineStore.update({
                      items: x.data,
                      selectedTimeline: data.id,
                      timelineExists: true,
                    });
                  }
                  this.timelineStore.setLoading(false);
                })
              );
            }

            this.overlayService.error(errors);
            this.timelineStore.setLoading(false);
            return of(<GraphqlResponse<listTimelineMilestonesQuery[]>>{
              success: false,
              data: null,
              errors: [],
            });
          })
        );
      })
    );
  }

  checkIfTimelineMilestoneExist() {
    return this.mainQuery.select('trialKey').pipe(
      switchMap(() => {
        this.timelineStore.setLoading(true);

        return this.gqlService.getTimeline$();
      }),
      switchMap(({ data: timeline, success, errors }) => {
        if (timeline && success) {
          return this.gqlService.listTimelineMilestoneIds$(timeline.id).pipe(
            tap(({ data: milestones }) => {
              this.timelineStore.update({
                timelineExists: !!milestones?.length,
              });
            })
          );
        }

        this.overlayService.error(errors);
        this.timelineStore.setLoading(false);

        return of();
      })
    );
  }

  async createTimelineMilestone(input: Omit<CreateTimelineMilestoneInput, 'timeline_id'>) {
    const { items } = this.timelineStore.getValue();
    const timelineMilestoneId = Utils.uuid();
    const milestoneId = Utils.uuid();
    this.milestoneStore.add({
      id: milestoneId,
      milestone_category_id: input.milestone_category_id,
      name: input.name,
      description: input.description,
      __typename: 'Milestone',
    } as MilestoneModel);
    const data: createTimelineMilestoneMutation = {
      __typename: 'TimelineMilestone',
      actual_end_date: input.actual_end_date,
      actual_start_date: input.actual_end_date,
      contract_end_date: input.contract_end_date,
      contract_start_date: input.contract_start_date,
      id: timelineMilestoneId,
      revised_end_date: input.revised_end_date,
      revised_start_date: input.revised_start_date,
      track_from_milestone: input.track_from_milestone_id
        ? {
            __typename: 'Milestone',
            id: input.track_from_milestone_id,
          }
        : null,
      milestone: {
        __typename: 'Milestone',
        id: milestoneId,
        name: input.name,
        milestone_category_id: input.milestone_category_id,
        milestone_category: {
          __typename: 'MilestoneCategory',
          name: this.milestoneCategoryQuery.getEntity(input.milestone_category_id)?.name || '',
        },
        organizations_with_forecasts: [],
      },
    };
    this.timelineStore.update({ items: [...items, data] });

    return { success: true, createdMilestoneId: data.id, milestoneId };
  }

  async updateTimelineForDependency({
    id,
    contract_end_date,
    contract_start_date,
    track_from_milestone_id,
  }: UpdateDependencyInput) {
    const { items } = this.timelineStore.getValue();
    const timelineMilestone = items.find((tm) => tm.id === id);
    if (timelineMilestone) {
      const data: updateTimelineMilestoneMutation = {
        __typename: 'TimelineMilestone',
        contract_end_date,
        contract_start_date,
        id,
        track_from_milestone: track_from_milestone_id
          ? {
              __typename: 'Milestone',
              id: track_from_milestone_id,
            }
          : null,
        milestone: timelineMilestone.milestone,
      };

      this.timelineStore.update((x) => {
        return {
          items: arrayUpdate(x.items, id, data),
        };
      });
    }

    return {
      success: true,
    };
  }

  async updateTimelineMilestone(input: UpdateTimelineMilestone) {
    this.timelineStore.setLoading(true);
    const {
      milestone_category_id,
      milestone_id,
      name,
      description,
      id,
      track_from_milestone_id,
      contract_start_date,
      contract_end_date,
    } = input;

    this.milestoneStore.update(milestone_id, (state) => {
      // if the milestone_category_id changed
      if (state.milestone_category_id !== milestone_category_id) {
        // remove the milestone_id from old category
        this.milestoneCategoryStore.update(state.milestone_category_id, ({ milestone_ids }) => {
          return { milestone_ids: arrayRemove(milestone_ids, milestone_id) };
        });

        // add milestone_id to new category
        this.milestoneCategoryStore.update(milestone_category_id, ({ milestone_ids }) => {
          return { milestone_ids: arrayAdd(milestone_ids, milestone_id) };
        });
      }
      return {
        name,
        milestone_category_id,
        description,
      };
    });

    const { items } = this.timelineStore.getValue();
    const timelineMilestone = items.find((tm) => tm.id === id);
    if (timelineMilestone) {
      const data: updateTimelineMilestoneMutation & { contract_month_difference: string | null } = {
        __typename: 'TimelineMilestone',
        contract_end_date: contract_end_date || '',
        contract_start_date: contract_start_date || '',
        contract_month_difference: '',
        id,
        track_from_milestone: track_from_milestone_id
          ? {
              __typename: 'Milestone',
              id: track_from_milestone_id,
            }
          : null,
        milestone: {
          __typename: 'Milestone',
          id: milestone_id,
          name,
          milestone_category_id,
          milestone_category: {
            __typename: 'MilestoneCategory',
            name: this.milestoneCategoryQuery.getEntity(input.milestone_category_id)?.name || '',
          },
          organizations_with_forecasts: timelineMilestone.milestone.organizations_with_forecasts,
        },
      };

      this.timelineStore.update((x) => {
        return {
          items: arrayUpdate(x.items, id, data),
        };
      });
    }

    this.timelineStore.setLoading(false);

    return {
      success: true,
    };
  }

  async triggerTimelineUpdatedEvent() {
    return firstValueFrom(
      this.gqlService.processEvent$({
        type: EventType.TIMELINE_UPDATED,
        entity_type: EntityType.TRIAL,
        entity_id: this.mainQuery.getValue().trialKey,
      })
    );
  }

  async deleteTimelineMilestone(id: string) {
    this.timelineStore.setLoading(true);

    this.timelineStore.update((x) => {
      const milestone_id = x.items.find((ti) => {
        return ti.id === id;
      })?.milestone?.id;

      const ids: string[] = [];
      x.items.forEach((ti) => {
        if (ti.track_from_milestone?.id === milestone_id) {
          ids.push(ti.id);
        }
      });
      return {
        items: arrayUpdate(arrayRemove(x.items, [id]), ids, {
          track_from_milestone: null,
        }),
      };
    });
    this.timelineStore.setLoading(false);
  }

  // Use this function to make sequential requests for the
  // update, updateDeps, and delete inputs in saveChanges().
  async requestChanges<T>(inputs: Promise<GraphqlResponse<T>>[], errors: string[]): Promise<void> {
    const results = await batchPromises(inputs, (p) => p);

    results.forEach((r) => {
      if (!(r instanceof Error) && r.errors && r.errors.length > 0) {
        errors.push(...r.errors);
      }
    });
  }

  convertToCreateInput(item: TimelineGridData): Omit<CreateTimelineMilestoneInput, 'timeline_id'> {
    const omitFields = [
      'milestone_name',
      'milestone_category_name',
      'organizations_with_forecasts',
      'track_from_milestone_name',
      'contract_month_difference',
    ];

    return {
      name: item.milestone_name,
      contract_start_date: item.contract_start_date || '',
      contract_end_date: item.contract_end_date || '',
      milestone_category_id: item.milestone_category_id || '',
      ...omit(item, omitFields),
    };
  }

  convertToUpdateInput(item: TimelineGridData): UpdateTimelineMilestoneInput {
    return {
      id: item.id,
      name: item.milestone_name,
      description: item.description,
      contract_start_date: item.contract_start_date,
      contract_end_date: item.contract_end_date,
      milestone_category_id: item.milestone_category_id,
      track_from_milestone_id: item.track_from_milestone_id || EMPTY_UUID,
    };
  }

  convertAnyToUpdateInput(
    item: UpdateTimelineMilestone | UpdateDependencyInput
  ): UpdateTimelineMilestoneInput {
    return item;
  }

  async saveChanges(
    createInputs: Omit<CreateTimelineMilestoneInput, 'timeline_id'>[],
    updateInputs: UpdateTimelineMilestone[],
    updateDependencyInputs: UpdateDependencyInput[],
    deleteInputs: string[],
    gridData: TimelineGridData[]
  ) {
    const { selectedTimeline } = this.timelineStore.getValue();

    const createProms: Promise<GraphqlResponse<createTimelineMilestoneMutation>>[] = [];
    const updateProms: Promise<GraphqlResponse<unknown>>[] = [];
    const deleteProms: Promise<GraphqlResponse<unknown>>[] = [];
    const errors: string[] = [];

    // Prevent milestones from being created
    // if they're eventually deleted
    const createInputsFiltered = createInputs.filter((input) => {
      return input.id ? !deleteInputs.includes(input.id) : false;
    });

    // Use the latest grid data to create the milestone
    const createInputsMapped = createInputsFiltered.map((input) => {
      const data = gridData.find((row) => row.id === input.id);
      return data ? this.convertToCreateInput(data) : input;
    });

    /**
    Create
    */

    const updateAfterCreate: Record<string, string> = {};

    createInputsMapped.forEach((createInput) => {
      if (createInput.id && createInput.track_from_milestone_id) {
        updateAfterCreate[createInput.id] = createInput.track_from_milestone_id;
      }

      createProms.push(
        firstValueFrom(
          this.gqlService.createTimelineMilestone$({
            ...createInput,
            timeline_id: selectedTimeline,
            track_from_milestone_id: null,
          })
        )
      );
    });

    const createResults = await batchPromises(createProms, (p) => p);

    /**
    Update and Update Dependencies
    */

    const updateInputsJoined = [...updateInputs, ...updateDependencyInputs];

    createResults.forEach((res) => {
      if (res instanceof Error || !res.data) {
        return;
      }
      // Do track_from_milestone_id updates for new milestones after creating all new milestones
      if (updateAfterCreate[res.data.id]) {
        updateInputsJoined.push({
          description: undefined,
          field_name: '',
          milestone_category_id: '',
          milestone_id: '',
          name: '',
          ...res.data,
          track_from_milestone_id: updateAfterCreate[res.data.id],
        });
      }
    });

    const updateInputsFiltered = updateInputsJoined.filter((input) => {
      return input.id ? !deleteInputs.includes(input.id) : false;
    });

    const updateInputsDeduped = uniqBy(updateInputsFiltered, 'id');

    const updateInputsMapped = updateInputsDeduped.map((input) => {
      const data = gridData.find((row) => row.id === input.id);
      return data ? this.convertToUpdateInput(data) : this.convertAnyToUpdateInput(input);
    });

    updateInputsMapped.forEach((updateInput) => {
      updateProms.push(firstValueFrom(this.gqlService.updateTimelineMilestone$(updateInput)));
    });

    await this.requestChanges(updateProms, errors);

    /**
    Delete
    */

    deleteInputs.forEach((deleteInput) => {
      deleteProms.push(firstValueFrom(this.gqlService.removeTimelineItem$(deleteInput)));
    });

    await this.requestChanges(deleteProms, errors);

    // The errors array is passed by reference to requestChanges(),
    // so the array will already contain all the update, updateDeps, and
    // delete errors here
    if (errors.length > 0) {
      return { success: false, errors };
    }

    return this.triggerTimelineUpdatedEvent();
  }
}
