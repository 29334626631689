<form class="font-inter" [formGroup]="fg" (ngSubmit)="onSave()">
  <div class="w-screen max-w-md"></div>
  <div class="text-xl font-bold mb-4">Confirm Manual Override</div>

  <div class="text-xs mb-2">Enter New Forecast:</div>
  <aux-input class="mb-8 w-16" formControlName="units" />

  <div class="text-xs mb-2">Spread difference (10 units) over:</div>
  <div class="flex space-x-4 mb-8">
    <div class="flex items-center">
      <input
        name="spread_type"
        type="radio"
        value="Next Month"
        class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
        formControlName="spread_type"
        [id]="'spread-next'"
      />
      <label class="ml-2" [for]="'spread-next'">
        <span class="block text-sm font-medium text-gray-700">Next Month</span>
      </label>
    </div>
    <div class="flex items-center">
      <input
        name="spread_type"
        type="radio"
        value="Total Period"
        class="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
        formControlName="spread_type"
        [id]="'spread-total'"
      />
      <label class="ml-2" [for]="'spread-total'">
        <span class="block text-sm font-medium text-gray-700">Total Period</span>
      </label>
    </div>
  </div>

  <div class="flex space-x-4 mt-8">
    <button class="w-48 text-sm btn btn--blue" type="submit">
      <span *ngIf="loading$ | async" class="spinner w-6 h-6 mr-3"></span>
      <span>Save</span>
    </button>

    <button
      class="text-sm font-normal aux-link focus:outline-none"
      type="button"
      (click)="ref.close()"
    >
      Cancel
    </button>
  </div>
</form>
