import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { akitaDevtools } from '@datorama/akita';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { fetchAuthSession } from '@aws-amplify/auth';

akitaDevtools();
const cognitoOAuthOverride =
  environment.cognito.loginWith.oauth && location.hostname === 'localhost'
    ? {
        loginWith: {
          oauth: {
            ...environment.cognito.loginWith.oauth,
            redirectSignIn: [location.origin],
            redirectSignOut: [location.origin],
          },
        },
      }
    : {};

Amplify.configure(
  {
    Auth: {
      Cognito: {
        ...environment.cognito,
        ...cognitoOAuthOverride,
      },
    },
    API: {
      GraphQL: {
        ...environment.API,
      },
    },
    Storage: {
      S3: {
        ...environment.S3,
      },
    },
  },
  {
    API: {
      GraphQL: {
        headers: async () => ({
          Authorization: (await fetchAuthSession()).tokens?.idToken?.toString() as string,
        }),
      },
    },
  }
);

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-055299}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Auxilius}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Auxilius}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Auxilius}_need_to_be_licensed___{Auxilius}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{19_April_2025}____[v3]_[01]_MTc0NTAxNzIwMDAwMA==acf40870545fbf49575f9d01f3d42cc7'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
