import { Injectable } from '@angular/core';
import { Option } from '@components/components.type';
import { DriverPatientGroup, DriverSiteGroup, ForecastMethodType } from '@services/gql.service';
import { memo } from 'helpful-decorators';
import { FullActivity, FullCategory } from '../../category/category.query';
import { SiteCurveQuery } from '../../drivers/forecast-sites/site-curve/site-curve.query';
import { PatientCurveQuery } from '../../patient-curve/patient-curve.query';
import { PatientCurveState } from '../../patient-curve/patient-curve.store';
import {
  ForecastTableGridSiteCurveInterface,
  ForecastTableGridSiteCurveResultsInterface,
} from '../models/forecast-table-grid.model';
import { EditableListDropdownItem } from '@components/editable-list-dropdown/editable-list-dropdown-item.model';

@Injectable()
export class ForecastTableGridMethodService {
  // Method options when Driver is Time
  MethodOptions: Option<ForecastMethodType>[] = [
    {
      value: ForecastMethodType.FORECAST_STRAIGHTLINE,
      label: 'Straight Line',
    },
    {
      value: ForecastMethodType.FORECAST_FRONTLOADED,
      label: 'Frontloaded',
    },
    {
      value: ForecastMethodType.FORECAST_BACKLOADED,
      label: 'Backloaded',
    },
  ];

  constructor(
    private siteCurveQuery: SiteCurveQuery,
    private patientCurveQuery: PatientCurveQuery
  ) {}

  @memo()
  getTimeMethod(forecast_method: string | null): string {
    const val = this.MethodOptions.filter((method) => method.value === forecast_method);
    return val[0]?.label || '';
  }

  parseCategoryMethod(category: FullCategory | FullActivity): string {
    return category.primary_settings.forecast_method || '';
  }

  parseSiteCurves(
    siteCurves: ForecastTableGridSiteCurveResultsInterface
  ): ForecastTableGridSiteCurveInterface[] {
    if (!siteCurves.success || siteCurves.errors.length || !siteCurves.data) {
      return [];
    }
    let flagCurveType = '';
    return siteCurves.data
      .sort((a, b) => (a.curve_type < b.curve_type ? -1 : 1))
      .map((siteCurve) => {
        if (!siteCurve.is_blended && flagCurveType !== siteCurve.curve_type) {
          flagCurveType = siteCurve.curve_type;
          return {
            label: siteCurve.name,
            value: siteCurve.driver_setting_id,
            curveType: siteCurve.curve_type,
            showLine: true,
          };
        }

        return {
          label: siteCurve.name,
          value: siteCurve.driver_setting_id,
          curveType: siteCurve.curve_type,
          showLine: false,
        };
      });
  }

  parsePatientCurves(patientCurves: PatientCurveState[]): EditableListDropdownItem[] {
    if (!patientCurves || !patientCurves.length) {
      return [];
    }

    let flag = false;
    return patientCurves.map((patientCurve) => {
      if (!patientCurve.is_blended && !flag) {
        flag = true;
        return {
          name: patientCurve.name,
          value: patientCurve.driver_setting_id,
          isEditable: false,
          showLine: true,
          isDeletable: false,
        };
      }

      return {
        name: patientCurve.name,
        value: patientCurve.driver_setting_id,
        isEditable: false,
        showLine: false,
        isDeletable: false,
      };
    });
  }

  getPatientGroupName(id: string | null | undefined): DriverPatientGroup | null {
    if (typeof id !== 'string') {
      return null;
    }

    return this.patientCurveQuery.getEntity(id) || null;
  }

  getSiteGroupName(id: string | null | undefined): DriverSiteGroup | null {
    if (typeof id !== 'string') {
      return null;
    }

    return this.siteCurveQuery.getEntity(id) || null;
  }
}
