<div class="flex items-center space-x-2">
  <span class="text-base text-aux-gray-darkest">Total Amount</span>
  <button class="flex items-center no-underline aux-link" (click)="onChangeClick()">
    <aux-icon
      class="text-aux-gray-darkest"
      [name]="(change$ | async) ? 'ChevronLeft' : 'ChevronRight'"
      [size]="20"
    />
  </button>
</div>
