import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface AnalyticData {
  title: string;
  differencePerc: number;
  differenceAmount?: number;
  differenceUnit?: number | string;
  topTitle?: string;
}

@Component({
  selector: 'aux-trial-insights-finance-analytic-card',
  template: `
    <div
      class="w-full h-[100px] grid grid-cols-2 border border-aux-gray-dark items-center bg-aux-gray-light"
    >
      <div class="text-aux-blue-dark px-3">{{ data.title }}</div>
      <div class="bg-white px-3 h-full flex flex-col justify-center">
        <p
          *ngIf="data.topTitle && (data.differenceAmount || data.differenceUnit)"
          class="text-right overflow-hidden text-ellipsis whitespace-nowrap"
          [auxTooltip]="data.topTitle"
        >
          {{ data.topTitle }}
        </p>
        <p *ngIf="data.differenceAmount" class="text-right">{{ data.differenceAmount | money }}</p>
        <p *ngIf="data.differenceUnit" class="text-right">{{ data.differenceUnit | round: 2 }}</p>
        <aux-percent-indicator [value]="data.differencePerc" />
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrialInsightsFinanceAnalyticCardComponent {
  @Input() data!: AnalyticData;
}
