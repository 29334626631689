<p class="mb-8">
  The <span class="text-aux-blue-light">aux-button</span> component is used to show buttons all
  around the project. It encapsulates all the logic for loading, disabling, showing icons and
  provides flexible API.
</p>

<p class="text-lg font-bold mb-3">API</p>

<ag-grid-angular
  class="ag-theme-alpine mb-8 w-full"
  style="width: 100%"
  [rowData]="documentationData"
  [gridOptions]="documentationOptions"
  [domLayout]="'autoHeight'"
  (gridReady)="autoSize($event)"
  (firstDataRendered)="autoSize($event)"
/>

<p class="text-lg font-bold">Variants</p>

<div *ngFor="let button of buttons">
  <h3 class="mt-10">
    {{ button.variantName }}
  </h3>
  <p *ngIf="button.description" class="mt-2">{{ button.description }}</p>

  <div class="flex items-center gap-6 mt-4">
    <aux-button
      [variant]="button.variant"
      [label]="button.variantName"
      [icon]="button.icon"
      [classList]="button.classList"
    />
    <aux-button
      [variant]="button.variant"
      [label]="button.variantName"
      [classList]="button.classList"
    />
  </div>

  <div class="flex items-center mt-6">
    <div class="text-black bg-aux-gray-dark">
      <div class="m-2">1</div>
      <div class="m-2">2</div>
    </div>

    <div class="pr-2 text-white bg-aux-gray-darkest">
      <div class="m-2">
        &lt;aux-button variant="{{ button.variant }}" label="{{ button.variantName }}" icon="{{
          button.icon
        }}"{{ getClassListString(button.classList) }}&gt;&lt;/aux-button&gt;
      </div>
      <div class="m-2">
        &lt;aux-button variant="{{ button.variant }}" label="{{ button.variantName }}"{{
          getClassListString(button.classList)
        }}&gt;&lt;/aux-button&gt;
      </div>
    </div>
  </div>
</div>

<p class="text-lg font-bold mt-8 mb-3">States</p>

<div class="flex items-center gap-6 mt-4">
  <aux-button variant="primary" label="Default" icon="Eye" [iconSize]="20" />
  <aux-button variant="primary" label="Disabled" icon="Eye" [iconSize]="20" [disabled]="true" />
  <aux-button variant="primary" label="Loading" icon="Eye" [iconSize]="20" [loading]="true" />
  <aux-button
    variant="primary"
    label="Loading and disabled"
    icon="Eye"
    [iconSize]="20"
    [loading]="true"
    [disabled]="true"
  />
</div>

<div class="flex items-center mt-6">
  <div class="text-black bg-aux-gray-dark">
    <div class="m-2">1</div>
    <div class="m-2">2</div>
    <div class="m-2">3</div>
    <div class="m-2">4</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">
      &lt;aux-button variant="primary" label="Default" icon="eye" [svgAttributes]="{{ '{' }} class:
      'w-5 h-5' &#125;&gt;&lt;/aux-button&gt;
    </div>
    <div class="m-2">
      &lt;aux-button variant="primary" label="Disabled" icon="eye" [svgAttributes]="{{ '{' }} class:
      'w-5 h-5' &#125; [disabled]="true"&gt;&lt;/aux-button&gt;
    </div>
    <div class="m-2">
      &lt;aux-button variant="primary" label="Loading" icon="eye" [svgAttributes]="{{ '{' }} class:
      'w-5 h-5' &#125; [loading]="true"&gt;&lt;/aux-button&gt;
    </div>
    <div class="m-2">
      &lt;aux-button variant="primary" label="Loading and disabled" [svgAttributes]="{{ '{' }}
      class: 'w-5 h-5' &#125; icon="eye" [loading]="true" [disabled]="true"&gt;&lt;/aux-button&gt;
    </div>
  </div>
</div>

<p class="mt-8">
  If the button doesn't have any icon, its label is replaced by spinner on loading in order to keep
  its width.
</p>

<div class="flex items-center gap-6 mt-4">
  <aux-button variant="success" label="Save" />
  <aux-button variant="success" label="Save" [loading]="true" />
</div>

<div class="flex items-center mt-6">
  <div class="text-black bg-aux-gray-dark">
    <div class="m-2">1</div>
    <div class="m-2">2</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">&lt;aux-button variant="success" label="Save"&gt;&lt;/aux-button&gt;</div>
    <div class="m-2">
      &lt;aux-button variant="success" label="Save" [loading]="true"&gt;&lt;/aux-button&gt;
    </div>
  </div>
</div>

<p class="text-lg font-bold mt-8 mb-3">Button Group</p>

<div class="flex items-center gap-6 mt-4">
  <aux-button-group label="Edit" iconName="Pencil" [options]="buttonGroupOptions" />
</div>

<div class="flex items-center mt-6">
  <div class="text-black bg-aux-gray-dark">
    <div class="m-2">1</div>
  </div>

  <div class="pr-2 text-white bg-aux-gray-darkest">
    <div class="m-2">
      &lt;aux-button-group label="Edit" iconName="Pencil" [options]="buttonGroupOptions" /&gt;
    </div>
  </div>
</div>
