<ag-grid-angular
  class="ag-theme-aux document-library-table tabular-nums"
  domLayout="autoHeight"
  [gridOptions]="$any(gridOptions$ | async)"
  [context]="getGridContext()"
  [rowSelection]="'multiple'"
  [suppressRowClickSelection]="true"
  [quickFilterText]="formGroup.controls.search.valueChanges | async"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  [loadingCellRenderer]="loadingCellRenderer"
  (gridReady)="onGridReady($event)"
  (rowSelected)="rowSelectedHandler($event)"
  (viewportChanged)="onWindowScroll()"
  (gridSizeChanged)="gridSizeChanged()"
/>
<aux-pagination-panel [gridApi]="gridAPI" (paginationChange)="paginationChange()" />
