import { Injectable } from '@angular/core';
import { PaymentSchedulesState } from '@models/payment-schedules/payment-schedules.store';
import { getEntityType } from '@datorama/akita';
import { PatientProtocolState } from '@models/patient-protocol/patient-protocol.store';
import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import { PaymentSchedulesQuery } from '@models/payment-schedules/payment-schedules.query';
import { listSitesQuery } from '@services/gql.service';
import { map } from 'rxjs/operators';
import { convertFilterToMap, mapSiteToSiteOption } from '@shared/utils';
import { Utils } from '@services/utils';
import { first } from 'lodash-es';
import { SitesQuery } from '@models/sites/sites.query';
import { SiteModel, SitesState } from '@models/sites/sites.store';

export const PATIENT_PROTOCOL_PATIENT_VISIT = 'PATIENT_PROTOCOL_PATIENT_VISIT';

export const AMOUNT_PATTERN = {
  PRIMARY: '::amount',
  CONTRACT: '::amountContract',
};

export const CURRENCY_PATTERN = {
  PRIMARY: '::currency',
  CONTRACT: '::contractCurrency',
};

export type PatientProtocolComparator = (
  patientType: getEntityType<PatientProtocolState>
) => boolean;

@Injectable({
  providedIn: 'root',
})
export class PatientBudgetTableDataService {
  siteOptions$ = this.sitesQuery
    .select()
    .pipe(map((entities) => this.processSiteOptions(entities)));

  private siteMap = new Map<string, SiteModel>();

  constructor(
    private patientProtocolQuery: PatientProtocolQuery,
    private paymentSchedulesQuery: PaymentSchedulesQuery,
    private sitesQuery: SitesQuery
  ) {}

  getCostsGridData(
    comparator: (patientType: getEntityType<PatientProtocolState>) => boolean,
    patientGroupId?: string
  ) {
    const gridData: Record<string, number>[] = [];
    const groupedPaymentSchedules = this.paymentSchedulesQuery.getGroupedPaymentSchedules();

    const filteredPatientProtocols =
      patientGroupId && patientGroupId !== 'visits_costs'
        ? this.patientProtocolQuery
            .getAll()
            .filter(comparator)
            .filter((patientGroup) => patientGroup.patient_group_id === patientGroupId)
        : this.patientProtocolQuery
            .getAll()
            .filter(comparator)
            .filter((patientGroup) => !patientGroup.patient_group_id);

    filteredPatientProtocols.forEach((patientProtocol) => {
      let data = {};
      const groupedPaymentSchedule = groupedPaymentSchedules[
        patientProtocol.id
      ] as getEntityType<PaymentSchedulesState>[];
      if (groupedPaymentSchedule) {
        groupedPaymentSchedule.forEach((paymentSchedule) => {
          data = {
            ...data,
            [paymentSchedule.site_id]: paymentSchedule.amount,
            [`${paymentSchedule.site_id}${AMOUNT_PATTERN.PRIMARY}`]: paymentSchedule.amount,
            [`${paymentSchedule.site_id}${AMOUNT_PATTERN.CONTRACT}`]:
              paymentSchedule.amount_contract,
            [`${paymentSchedule.site_id}${CURRENCY_PATTERN.CONTRACT}`]:
              paymentSchedule.sps_contract_expense_currency,
            [`${paymentSchedule.site_id}${CURRENCY_PATTERN.PRIMARY}`]:
              paymentSchedule.sps_expense_currency,
          };
        });
      }

      data = {
        ...data,
        target_date_days_out: patientProtocol.target_date_days_out,
        target_tolerance_days_out: patientProtocol.target_tolerance_days_out,
        patient_protocol_id: patientProtocol.id,
        patient_protocol_name: patientProtocol.name,
      };

      gridData.push(data);
    });
    return gridData;
  }

  processSiteOptions(sites: SitesState) {
    const entities = sites.entities;

    if (entities) {
      const siteArray = Object.values(entities);
      this.siteMap = convertFilterToMap<SiteModel>(siteArray || []);

      const sorted = siteArray.sort(({ site_no }, { site_no: site_no2 }) =>
        Utils.localeAlphaNumSort(site_no || '', site_no2 || '')
      ) as listSitesQuery[];
      return sorted.map((site: listSitesQuery) => {
        return mapSiteToSiteOption(site, this.getPrincipalInvestigatorName(site.id));
      });
    }

    return [];
  }

  private getPrincipalInvestigatorName(siteId: string): string {
    const principalInvestigator = first(this.siteMap.get(siteId)?.contacts);

    return principalInvestigator
      ? `${principalInvestigator.given_name} ${principalInvestigator.family_name}`
      : '';
  }
}
