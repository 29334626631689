<div class="w-screen max-w-2xl max-h-158 overflow-auto">
  <div class="mb-8 text-xl font-bold">Add Trial Site</div>

  <form class="grid grid-cols-2 gap-5" [formGroup]="fg" (ngSubmit)="onAddTrialSite()">
    <div class="flex flex-col justify-between space-y-2">
      <aux-input
        formControlName="name"
        label="Site Name"
        validators="required"
        [showRequiredAsterisk]="true"
      />

      <aux-input
        formControlName="site_no"
        label="Site No"
        validators="required"
        [showRequiredAsterisk]="true"
      />

      <aux-input formControlName="address_line_1" label="Address Line 1" />
      <aux-input formControlName="address_line_2" label="Address Line 2" />
      <aux-input formControlName="address_line_3" label="Address Line 3" />
    </div>
    <div class="flex flex-col">
      <div class="mb-1 text-xs">Contracts & Payment Terms</div>
      <aux-file-manager #fileManager class="h-24" [eager]="false" />
    </div>
    <div class="space-y-2">
      <div class="grid grid-cols-2 gap-5">
        <aux-input formControlName="zip" label="Zip" />
        <aux-input formControlName="city" label="City" />
      </div>
      <div class="grid grid-cols-2 gap-5">
        <aux-input formControlName="state" label="State" />
        <div>
          <div class="text-xs"><span class="text-aux-error font-bold">*</span> Country</div>
          <ng-select
            class="select select__big"
            formControlName="country"
            appendTo="body"
            label="Country"
            auxFormError
            placeholder="Country"
            [clearable]="false"
          >
            <ng-option *ngFor="let country of countries" [value]="country.value">
              {{ country.label }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5">
        <aux-input formControlName="target_patients" label="Target Patients" />
        <div class="aux-select">
          <label for="vendor"><span class="text-aux-error font-bold">*</span> Managed By</label>
          <ng-select
            class="select select__big"
            id="vendor"
            formControlName="managed_by_id"
            bindValue="id"
            bindLabel="name"
            label="Managed By"
            appendTo="body"
            auxFormError
            placeholder="—"
            [clearable]="false"
            [searchable]="false"
          >
            <ng-option
              *ngFor="let organization of organizationQuery.selectAll() | async"
              [value]="organization"
            >
              <span [title]="organization.name">{{ organization.name }}</span>
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5">
        <aux-input
          formControlName="site_activation"
          label="Site Activation"
          placeholder="YYYY-MM-DD"
          [type]="'date'"
        />
        <div>
          <div class="text-xs">
            <span class="text-aux-error font-bold">*</span>
            Currency
          </div>
          <ng-select
            class="select select__big"
            formControlName="currency"
            bindLabel="label"
            bindValue="key"
            label="Currency"
            auxFormError
            required
            [items]="currencyOptions"
            [clearable]="false"
            [searchable]="true"
            [appendTo]="'body'"
            [multiple]="false"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-5">
        <aux-input
          formControlName="closeout_date"
          label="Closeout Date"
          placeholder="YYYY-MM-DD"
          [type]="'date'"
        />
      </div>
      <div>
        <div class="font-semibold my-2">Primary Investigator</div>
        <div class="flex space-x-4">
          <aux-input formControlName="given_name" class="flex-1" [label]="'First Name'" />
          <aux-input formControlName="family_name" class="flex-1" [label]="'Last Name'" />
        </div>
      </div>
    </div>
    <div class="max-h-60 overflow-auto">
      <aux-file-viewer
        [fileManager]="fileManager"
        [onlyShowUploaded]="false"
        [disableFirstFileMargin]="true"
      />
    </div>
    <div class="ml-2 mt-6 mb-2">
      <button class="w-48 text-sm btn btn--blue" type="submit">Add Trial Site</button>
    </div>
  </form>
</div>
