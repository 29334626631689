import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Utils } from '@services/utils';
import { ChangeOrderDetailComponent } from './change-order-detail.component';
import { ChangeOrderVerification } from '../change-order/state/change-order.store';
import { ChangeOrderService } from '../change-order/state/change-order.service';

@Component({
  selector: 'aux-change-order-verification-card',
  template: `
    <div
      class="p-4 pb-5 border min-h-full text-xs"
      *ngIf="{
        editMode: (editMode$ | async) === true,
        changeOrder: parent.changeOrder$ | async
      } as state"
    >
      <div class="flex items-center justify-between mb-4">
        <div class="text-sm font-bold">Verification Stats</div>
        <div class="text-xs flex items-center">
          <button
            class="font-normal aux-link focus:outline-none"
            (click)="onCancel()"
            type="button"
            *ngIf="state.editMode"
          >
            Cancel
          </button>
          <div
            class="ml-4 flex space-x-2 items-center cursor-pointer text-aux-green-dark"
            (click)="onSave()"
            *ngIf="state.editMode"
          >
            <div>Save</div>
            <span class="spinner w-5 h-5" *ngIf="(loading$ | async) === true"></span>
            <aux-icon name="Check" [size]="20" *ngIf="(loading$ | async) === false" />
          </div>
          <div
            class="flex space-x-2 items-center cursor-pointer text-aux-blue-dark"
            (click)="onEdit()"
            *ngIf="showEditButton && !state.editMode"
          >
            <div>Edit</div>
            <aux-icon name="Pencil" [size]="20" />
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 items-center gap-5">
        <ng-container *ngFor="let verification of verifications; last as last">
          <ng-container *ngIf="!state.editMode">
            <div class="col-span-8">{{ verification.name }}</div>
            <ng-container *ngIf="verification.status as icon">
              <aux-icon
                name="AlertTriangle"
                *ngIf="icon !== 'check'"
                [ngClass]="{
                  'text-aux-warn': icon === 'exc-yellow',
                  'text-aux-red-dark': icon === 'exc-red',
                  'text-aux-gray-dark': icon === 'exc-gray'
                }"
              />
              <aux-icon name="Check" class="text-aux-green-dark" *ngIf="icon === 'check'" />
            </ng-container>
            <div class="justify-self-end col-span-3">{{ verification.value }}</div>
            <hr class="col-span-full" *ngIf="!last" />
          </ng-container>
          <ng-container *ngIf="state.editMode">
            <div class="col-span-5">
              <aux-input [(ngModel)]="verification.name" placeholder="Enter Text" />
            </div>
            <ng-select
              [(ngModel)]="verification.status"
              class="col-span-3 select select__big mt-0"
              [clearable]="false"
              [searchable]="false"
              [multiple]="false"
              [appendTo]="'body'"
            >
              <ng-option [value]="'exc-yellow'">
                <aux-icon name="AlertTriangle" class="text-aux-warn" />
              </ng-option>
              <ng-option [value]="'exc-red'">
                <aux-icon name="AlertTriangle" class="text-aux-red-dark" />
              </ng-option>
              <ng-option [value]="'exc-gray'">
                <aux-icon name="AlertTriangle" class="text-aux-gray-dark" />
              </ng-option>
              <ng-option [value]="'check'">
                <aux-icon name="Check" class="text-aux-green-dark" />
              </ng-option>
            </ng-select>
            <div class="col-span-4">
              <aux-input [(ngModel)]="verification.value" placeholder="Enter Text" />
            </div>
            <hr class="col-span-full" *ngIf="!last" />
          </ng-container>
        </ng-container>
        <ng-container *ngIf="state.editMode">
          <div class="col-span-full flex items-center text-sm">
            <button
              (click)="onRemoveLastLine()"
              class="aux-link focus:outline-none text-aux-red-dark"
              type="button"
              *ngIf="verifications.length !== 0"
            >
              Remove line
            </button>
            <div class="grow"></div>
            <button
              (click)="onAddLine()"
              class="aux-link focus:outline-none text-aux-blue-dark"
              type="button"
            >
              Add new line
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderVerificationCardComponent {
  @Input() showEditButton = false;

  loading$ = new BehaviorSubject(false);

  editMode$ = new BehaviorSubject(false);

  verifications: ChangeOrderVerification[] = [];

  constructor(
    public parent: ChangeOrderDetailComponent,
    private changeOrderService: ChangeOrderService
  ) {
    this.refreshVerifications();
  }

  async onSave() {
    if (this.loading$.getValue()) {
      return;
    }
    const co = this.parent.changeOrder$.getValue();
    if (!co) {
      return;
    }
    this.loading$.next(true);

    await this.changeOrderService.verificationUpdate({
      verifications: this.verifications,
      workflow_id: this.parent.changeOrder$.getValue()?.workflow_id || '',
      co_id: co.id,
    });

    this.loading$.next(false);
    this.editMode$.next(false);
  }

  onEdit() {
    this.refreshVerifications();
    this.editMode$.next(true);
  }

  onCancel() {
    this.editMode$.next(false);
  }

  onAddLine() {
    this.verifications.push({ name: '', status: 'check', value: '' });
  }

  onRemoveLastLine() {
    this.verifications.pop();
  }

  refreshVerifications() {
    this.verifications = Utils.clone(this.parent.changeOrder$.getValue()?.verifications || []);
  }
}
