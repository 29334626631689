import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OrganizationQuery } from '@models/organization/organization.query';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { ApiService } from '@services/api.service';
import { InvoiceStore, InvoiceState } from './invoice.store';

@Injectable({ providedIn: 'root' })
export class InvoiceQuery extends QueryEntity<InvoiceState> {
  constructor(
    protected store: InvoiceStore,
    private organizationQuery: OrganizationQuery,
    private mainQuery: MainQuery,
    private apiService: ApiService
  ) {
    super(store);
  }

  async downloadINV(
    id: string
  ): Promise<{ success: boolean; data: string | null; errors: string[] }> {
    const emptyReturn = {
      success: false,
      data: null,
      errors: ['Unexpected Error!'],
    };

    const inv = this.getEntity(id);
    if (!inv) {
      return emptyReturn;
    }

    const org = this.organizationQuery.getEntity(inv.organization.id);
    if (!org) {
      return emptyReturn;
    }
    const currentTrial = this.mainQuery.getSelectedTrial();

    if (!currentTrial) {
      return emptyReturn;
    }

    const { success, data, errors } = await this.apiService.getS3ZipFile(
      this.getFilePath(inv.organization.id, inv.id)
    );

    if (success && data) {
      // [insert trial name]_[insert vendor name]_Change Order [insert Change Order #]_[insert date created YYYY.MM.DD]
      const fileName = `${currentTrial.short_name}_${org.name}_${inv.invoice_no}_${(
        inv.invoice_date || ''
      ).slice(0, 10)}`;
      await this.apiService.downloadZipOrFile(data, fileName);
    }

    return { success, data, errors };
  }

  getFilePath(org_id: string, id: string) {
    const trial_id = this.mainQuery.getValue().trialKey;
    return `trials/${trial_id}/vendors/${org_id}/invoices/${id}/`;
  }
}
