import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FileManagerComponent } from '@components/file-manager/file-manager.component';
import { FileMetadata } from '@services/api.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CustomOverlayRef } from '@components/overlay/custom-overlay-ref';
import { OverlayService } from '@services/overlay.service';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { EntityType, EventType, GqlService } from '@services/gql.service';

@Component({
  selector: 'aux-specifications-upload',
  template: `
    <aux-file-manager
      #manager
      class="h-32"
      [fetchFilesOnInit]="false"
      [pathFn]="pathFn"
      [eager]="false"
      [metadata]="metadata"
      [showSuccessOnUpload]="true"
      [accept]="'.csv'"
    />
    <div class="max-h-60 overflow-auto mt-4">
      <aux-file-viewer
        [fileManager]="manager"
        [disableFirstFileMargin]="true"
        [onlyShowUploaded]="false"
      />
    </div>

    <div *ngIf="errorMessage" class=" mt-4 p-5 font-medium bg-aux-error text-white rounded-md">
      {{ errorMessage }}
    </div>

    <div class="mt-4 flex space-x-4">
      <button class="w-48 text-sm btn btn--blue" (click)="onUpload()">
        <span *ngIf="loading$ | async" class="spinner w-6 h-6 mr-3"></span>
        <span>Upload Specification</span>
      </button>

      <button
        class="text-sm font-normal aux-link focus:outline-none"
        type="button"
        (click)="ref.close()"
      >
        Cancel
      </button>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecificationsUploadComponent {
  @ViewChild(FileManagerComponent) fileManager: FileManagerComponent | undefined;

  metadata: FileMetadata = {};

  loading$ = new BehaviorSubject(false);

  errorMessage = '';

  constructor(
    public ref: CustomOverlayRef<unknown, unknown>,
    private overlayService: OverlayService,
    private mainQuery: MainQuery,
    private gqlService: GqlService
  ) {}

  pathFn: () => string = () => '';

  getFilePath() {
    const trialId = this.mainQuery.getValue().trialKey;
    return `trials/${trialId}/specification/`;
  }

  async onUpload() {
    this.errorMessage = '';

    if (this.fileManager && !this.loading$.getValue()) {
      const files = this.fileManager.fileQuery.getAll();

      if (!files.length) {
        this.errorMessage = 'You need to upload a file!';
        return;
      }

      if (files.length > 1) {
        this.errorMessage = 'Maximum one file allowed!';
        return;
      }

      const match = files[0].bucket_key.match(/\.([^.]+)$/);
      if (!(match?.[1] === 'csv' || match?.[1] === 'png')) {
        this.errorMessage = 'File type must be .csv or .png!';
        return;
      }

      this.loading$.next(true);

      const file = files[0];
      const key = `${this.getFilePath()}${file.bucket_key}`;

      this.fileManager.fileStore.update(file.id, {
        ...file,
        bucket_key: key,
      });

      const fileSuccess = await this.fileManager.fileService.uploadFiles({ admin: '1' });

      if (fileSuccess) {
        const mode = match?.[1] === 'csv' ? 'csv' : 'png';

        const { success, errors } = await firstValueFrom(
          this.gqlService.processEvent$({
            type:
              mode === 'csv'
                ? EventType.SPECIFICATIONS_TEMPLATE_UPLOADED
                : EventType.SPECIFICATIONS_IMAGE_UPLOADED,
            entity_type: EntityType.TRIAL,
            entity_id: this.mainQuery.getValue().trialKey,
            bucket_key: `public/${key}`,
          })
        );
        if (success) {
          this.overlayService.success(`Specification successfully uploaded!`);
        } else {
          this.fileManager.apiService.removeFile(`${this.getFilePath()}${file.bucket_key}`);
          this.overlayService.error(errors);
        }

        this.ref.close(true);
      }
    }
    this.loading$.next(false);
  }
}
