import { Injectable } from '@angular/core';
import {
  Currency,
  fetchInvestigatorTransactionsQuery,
  FetchPatientTransactionsInput,
  GqlService,
  listPatientGroupsQuery,
  listPatientProtocolListQuery,
  listPatientProtocolSubTypesQuery,
  listPatientProtocolVersionsQuery,
  listPatientsQuery,
  listSitesQuery,
  PatientGroupType,
  PatientTransactionSortableFields,
} from '@services/gql.service';
import { map, tap } from 'rxjs/operators';
import { OverlayService } from '@services/overlay.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Maybe, Utils } from '@services/utils';
import * as dayjs from 'dayjs';
import { ValueFormatterParams, SortModelItem } from '@ag-grid-community/core';
import { Option } from '@components/components.type';
import { first, uniqBy } from 'lodash-es';
import { SitesService } from '@models/sites/sites.service';
import { SiteOption } from '@models/site-option.model';
import { convertFilterToMap, mapSiteToSiteOption } from '@shared/utils';

export type InvestigatorTransactions = fetchInvestigatorTransactionsQuery['items'];

export type InvestigatorTransactionsFilterForm = Omit<
  FetchPatientTransactionsInput,
  'page' | 'sort_by'
>;

export interface InvestigatorTransactionsDataRow {
  completionDate: string;
  source: string;
  source_created_date: string | null | undefined;
  site: string;
  siteName: string;
  principalInvestigator: string;
  patient?: string | null;
  category: string;
  patientGroup?: string | null;
  protocolVersion: string | null;
  siteAddress: string | null;
  description?: string | null;
  contractCurrency: Currency;
  currency: Currency;
  totalContract: number;
  exchangeRate: string;
  totalUsd: number;
  country: string;
}

interface Pagination {
  offset: number;
  limit: number;
}
@Injectable({
  providedIn: 'root',
})
export class InvestigatorTransactionsService {
  totalCost$ = new BehaviorSubject(0);

  totalItems$ = new BehaviorSubject(0);

  lastSourceRefreshDate$ = new BehaviorSubject('');

  initialFilters: InvestigatorTransactionsFilterForm = {
    end_activity_date: null,
    start_activity_date: null,
    patient_group_ids: null,
    patient_protocol_version_ids: null,
    patient_ids: null,
    patient_protocol_names: null,
    site_ids: null,
    countries: null,
    patient_protocol_sub_types: null,
    sources: null,
  };

  currentFilters$ = new BehaviorSubject<InvestigatorTransactionsFilterForm>(this.initialFilters);

  private siteMap = new Map<string, listSitesQuery>();

  siteOptions$ = new BehaviorSubject<SiteOption[]>([]);

  patientOptions$ = new BehaviorSubject<listPatientsQuery[]>([]);

  allPatientOptions$ = new BehaviorSubject<listPatientsQuery[]>([]);

  descriptionOptions$ = new BehaviorSubject<listPatientProtocolListQuery[]>([]);

  patientGroupOptions$ = new BehaviorSubject<listPatientGroupsQuery[]>([]);

  protocolVersionOptions$ = new BehaviorSubject<listPatientProtocolVersionsQuery[]>([]);

  countryOptions$ = new BehaviorSubject<Option[]>([]);

  categoryOptions$ = new BehaviorSubject<listPatientProtocolSubTypesQuery[]>([]);

  private patientGroupMap = new Map<string, listPatientGroupsQuery>();

  private sortingMap = new Map<
    keyof InvestigatorTransactionsDataRow,
    PatientTransactionSortableFields
  >([
    ['completionDate', PatientTransactionSortableFields.ACTIVITY_DATE],
    ['source', PatientTransactionSortableFields.SOURCE],
    ['site', PatientTransactionSortableFields.SITE_NO],
    ['siteName', PatientTransactionSortableFields.SITE_NAME],
    ['principalInvestigator', PatientTransactionSortableFields.PRINCIPAL_INVESTIGATOR],
    ['description', PatientTransactionSortableFields.PATIENT_PROTOCOL_NAME],
    ['category', PatientTransactionSortableFields.PATIENT_PROTOCOL_SUB_TYPE],
    ['patientGroup', PatientTransactionSortableFields.PATIENT_GROUP_NAME],
    ['protocolVersion', PatientTransactionSortableFields.PATIENT_PROTOCOL_VERSION],
    ['patient', PatientTransactionSortableFields.PATIENT_NO],
    ['contractCurrency', PatientTransactionSortableFields.CONTRACT_CURRENCY],
    ['totalContract', PatientTransactionSortableFields.CONTRACT_TOTAL],
    ['totalUsd', PatientTransactionSortableFields.TRIAL_CURRENCY_TOTAL],
    ['country', PatientTransactionSortableFields.COUNTRY],
    ['exchangeRate', PatientTransactionSortableFields.EXCHANGE_RATE],
    ['source_created_date', PatientTransactionSortableFields.SOURCE_CREATED_DATE],
  ]);

  inMonthAdjustmentsFilter = new BehaviorSubject<boolean>(false);

  inMonthAdjustmentsFilterDateStart = '';

  inMonthAdjustmentsFilterDateEnd = '';

  inMonthAdjustmentsFilterVendorId = '';

  inMonthAdjustmentsFilterSiteIds: string[] = [];

  constructor(
    private gqlService: GqlService,
    private overlayService: OverlayService,
    private sitesService: SitesService
  ) {}

  getTransactionFilters() {
    return combineLatest([
      this.sitesService.get(),
      this.gqlService.listPatients$(),
      this.gqlService.listPatientGroups$([PatientGroupType.PATIENT_GROUP_STANDARD]),
      this.gqlService.listPatientProtocolVersions$(),
      this.gqlService.listPatientProtocolList$([], true),
      this.gqlService.listPatientProtocolSubTypes$([]),
    ]).pipe(
      tap(
        ([
          siteList,
          patientList,
          patientGroupList,
          protocolVersionList,
          descriptionList,
          patientProtocolSubTypelist,
        ]) => {
          this.siteMap = convertFilterToMap<listSitesQuery>(siteList?.data || []);
          this.patientGroupMap = convertFilterToMap<listPatientGroupsQuery>(
            patientGroupList?.data || []
          );

          this.setOptions(
            siteList,
            patientList,
            patientGroupList,
            protocolVersionList,
            descriptionList,
            patientProtocolSubTypelist
          );

          this.setInMonthAdjustmentsFilters();
        }
      )
    );
  }

  private getPatientTransactionsGridData(
    list: InvestigatorTransactions
  ): InvestigatorTransactionsDataRow[] {
    return list.map<InvestigatorTransactionsDataRow>((item) => ({
      completionDate: item.activity_date,
      source: item.source.name || '',
      site: this.siteMap.get(item.site_payment_schedule.site_id)?.site_no || '',
      siteName: this.siteMap.get(item.site_payment_schedule.site_id)?.name || '',
      principalInvestigator: this.getPrincipalInvestigatorName(item.site_payment_schedule.site_id),
      patient: item.external_patient_id,
      category: item.site_payment_schedule.patient_protocol.patient_protocol_sub_type?.name || '',
      patientGroup: this.patientGroupMap.get(item.patient_group_id || '')?.name || '',
      protocolVersion:
        item.site_payment_schedule.patient_protocol.patient_protocol_version?.name || '',
      siteAddress: this.getSiteAddress(this.siteMap.get(item.site_payment_schedule.site_id)),
      description: item.site_payment_schedule.patient_protocol.name,
      contractCurrency: item.site_payment_schedule.expense_amount.contract_curr as Currency,
      currency: item.site_payment_schedule.expense_amount.amount_curr as Currency,
      totalContract: item.site_payment_schedule.expense_amount.contract_amount || 0,
      exchangeRate: Utils.exchangeRateFormatter({
        value: item.exchange_rate?.rate || 0,
      } as ValueFormatterParams),
      totalUsd: item.site_payment_schedule.expense_amount.amount || 0,
      country: Utils.getCountryName(item.site_payment_schedule.country || ''),
      source_created_date: item.source_created_date,
    }));
  }

  private getSiteAddress(site?: listSitesQuery): string {
    const citySubStr = this.getSafeAddressName(site?.city);

    const address = `${this.getSafeAddressName(site?.address_line_1)} ${this.getSafeAddressName(
      site?.address_line_2
    )} ${this.getSafeAddressName(site?.address_line_3)}`.trim();

    return site
      ? `${address} ${citySubStr} ${site.state || ''} ${site.zip || ''} ${Utils.getCountryName(
          site.country || ''
        )}`.trim()
      : Utils.zeroHyphen;
  }

  private getSafeAddressName(address: Maybe<string>, suffix = ','): string {
    return address ? `${address}${suffix}` : '';
  }

  private siteIdsByVendor(vendorId: string): string[] {
    const sitesByVendor = [...this.siteMap].filter(([, site]) => {
      return site.managed_by.id === vendorId;
    });

    const siteIds = sitesByVendor.map(([siteId]) => siteId);

    return siteIds;
  }

  getInMonthAdjustmentsFilters(): [string, string, string[]] {
    return [
      this.inMonthAdjustmentsFilterDateStart,
      this.inMonthAdjustmentsFilterDateEnd,
      this.inMonthAdjustmentsFilterSiteIds,
    ];
  }

  resetInMonthAdjustmentsFilters(): void {
    this.inMonthAdjustmentsFilterDateStart = '';
    this.inMonthAdjustmentsFilterDateEnd = '';
    this.inMonthAdjustmentsFilterVendorId = '';
    this.inMonthAdjustmentsFilterSiteIds = [];
  }

  addInMonthAdjustmentsFilters(monthStart: string, monthEnd: string, vendorId: string): void {
    // Only update filters if all values exist
    if (!monthStart || !monthEnd || !vendorId) {
      return;
    }

    this.inMonthAdjustmentsFilterDateStart = monthStart;
    this.inMonthAdjustmentsFilterDateEnd = monthEnd;
    this.inMonthAdjustmentsFilterVendorId = vendorId;
  }

  setInMonthAdjustmentsFilters(): void {
    if (
      !this.inMonthAdjustmentsFilterDateStart ||
      !this.inMonthAdjustmentsFilterDateEnd ||
      !this.inMonthAdjustmentsFilterVendorId
    ) {
      return;
    }

    const siteIds = this.siteIdsByVendor(this.inMonthAdjustmentsFilterVendorId);

    // Only update filters if sites from vendor exist
    if (!siteIds.length) {
      return;
    }

    this.inMonthAdjustmentsFilterSiteIds = siteIds;
  }

  getTransactionList(
    filter: InvestigatorTransactionsFilterForm,
    sortBy: SortModelItem[],
    pagination: Pagination
  ) {
    const validFilters = this.nullifyInvalidFilters(filter);
    this.currentFilters$.next(validFilters);
    return this.gqlService
      .fetchInvestigatorTransactions$({
        ...validFilters,
        page: pagination,
        sort_by: this.getSorting(sortBy),
      })
      .pipe(
        map(({ data, success, errors }: GraphqlResponse<fetchInvestigatorTransactionsQuery>) => {
          if (success && data) {
            const transactionList = this.getPatientTransactionsGridData(data.items);

            // BE return correct these data only when offset 0
            // to avoid unnecessary DB connections and load
            if (pagination.offset === 0) {
              this.lastSourceRefreshDate$.next(data.latest_source_date || dayjs().toString());
              this.totalCost$.next(data.meta_data?.total_cost || 0);
              this.totalItems$.next(data.meta_data?.total_count || 0);
            }

            return {
              data: transactionList,
              next_offset: data.next_offset,
            };
          }

          if (errors.length) {
            this.overlayService.error(errors);
          }

          return {
            data: [],
            next_offset: 0,
          };
        })
      );
  }

  dynamicPatientIdFilter(site_ids: string[], patient_ids: string[] | null): string[] {
    const filteredValues = this.allPatientOptions$
      .getValue()
      .filter((option) => site_ids.includes(option.site_id) || !site_ids.length);
    const arrWithoutDuplicatePatients: listPatientsQuery[] = [];
    filteredValues.forEach((value) => {
      if (
        !arrWithoutDuplicatePatients.includes(value) &&
        !arrWithoutDuplicatePatients.some((eachValue) => eachValue.id === value.id)
      ) {
        arrWithoutDuplicatePatients.push(value);
      }
    });
    this.patientOptions$.next(arrWithoutDuplicatePatients);
    return patient_ids
      ? this.patientOptions$
          .getValue()
          .map((option) => {
            return option.id;
          })
          .filter((option) => patient_ids.includes(option))
      : [];
  }

  private setOptions(
    siteList: GraphqlResponse<listSitesQuery[]>,
    patientList: GraphqlResponse<listPatientsQuery[]>,
    patientGroupList: GraphqlResponse<listPatientGroupsQuery[]>,
    protocolVersionList: GraphqlResponse<listPatientProtocolVersionsQuery[]>,
    descriptionList: GraphqlResponse<listPatientProtocolListQuery[]>,
    patientProtocolSubTypeList: GraphqlResponse<listPatientProtocolSubTypesQuery[]>
  ) {
    this.siteOptions$.next(
      (siteList.data || [])
        .sort(({ site_no }, { site_no: site_no2 }) => Utils.localeAlphaNumSort(site_no, site_no2))
        .map((site: listSitesQuery) =>
          mapSiteToSiteOption(site, this.getPrincipalInvestigatorName(site.id))
        )
    );
    this.patientOptions$.next(
      (patientList.data || []).sort(
        ({ external_patient_id }, { external_patient_id: external_patient_id2 }) =>
          Utils.alphaNumSort(external_patient_id, external_patient_id2)
      )
    );
    this.descriptionOptions$.next(
      (uniqBy(descriptionList.data, 'name') || []).sort(({ name }, { name: name2 }) =>
        Utils.alphaNumSort(name, name2)
      )
    );
    this.patientGroupOptions$.next(
      (patientGroupList.data || []).sort(({ name }, { name: name2 }) =>
        Utils.alphaNumSort(name, name2)
      )
    );
    this.protocolVersionOptions$.next(
      (protocolVersionList.data || []).sort(({ name }, { name: name2 }) =>
        Utils.alphaNumSort(name, name2)
      )
    );
    this.categoryOptions$.next(
      (patientProtocolSubTypeList.data || []).sort(({ name }, { name: name2 }) =>
        Utils.alphaNumSort(name, name2)
      )
    );
    this.countryOptions$.next(this.getCountryOptions(siteList?.data || []));
    this.allPatientOptions$.next(this.patientOptions$.getValue());
  }

  private getSorting(sorting: SortModelItem[]): FetchPatientTransactionsInput['sort_by'] {
    return sorting.map((items) => {
      return {
        field: this.sortingMap.get(items.colId as keyof InvestigatorTransactionsDataRow),
        order: items.sort.toUpperCase(),
      };
    }) as FetchPatientTransactionsInput['sort_by'];
  }

  private getCountryOptions(list: listSitesQuery[]): Option[] {
    return uniqBy(list, 'country')
      .map(({ country }) => ({
        value: country || '',
        label: Utils.getCountryName(country || ''),
      }))
      .filter(({ value }) => !!value)
      .sort(({ label }, { label: label2 }) => Utils.alphaNumSort(label, label2));
  }

  private nullifyInvalidFilters(
    filers: InvestigatorTransactionsFilterForm = {}
  ): InvestigatorTransactionsFilterForm {
    return Object.entries(filers).reduce((accum, [key, value]) => {
      return {
        ...accum,
        [key]: ['end_activity_date', 'start_activity_date'].includes(key) ? value || null : value,
      };
    }, {});
  }

  private getPrincipalInvestigatorName(siteId: string): string {
    const principalInvestigator = first(this.siteMap.get(siteId)?.contacts);

    return principalInvestigator
      ? `${principalInvestigator.given_name} ${principalInvestigator.family_name}`
      : '';
  }
}
