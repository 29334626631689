import { LaunchDarklyService } from '@services/launch-darkly.service';
import { Component, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { IconComponent } from '@components/icon/icon.component';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'aux-stage-indicator',
  template: `<div
    class="bg-white rounded p-1 text-aux-blue-dark text-sm flex space-x-1 items-center"
  >
    <aux-icon name="Box" *ngIf="isSandboxEnv$ | async; else devIcon" />
    <ng-template #devIcon>
      <aux-icon name="SettingsBolt" />
    </ng-template>

    <p>{{ envName$ | async }}</p>
  </div>`,
  standalone: true,
  imports: [AsyncPipe, IconComponent, NgIf],
})
export class StageIndicatorComponent implements OnInit {
  envName$ = this.launchDarklyService.envName$;

  isSandboxEnv$ = new BehaviorSubject(false);

  constructor(private launchDarklyService: LaunchDarklyService) {}

  ngOnInit(): void {
    this.envName$.pipe(untilDestroyed(this)).subscribe((envName) => {
      this.isSandboxEnv$.next(!!envName.match('-sandbox'));
    });
  }
}
