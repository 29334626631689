<!-- Period, only when the Driver is Time -->
<div
  class="forecast-table-grid-select-container time"
  [auxTooltip]="disabled ? tooltip : label"
  (click)="openTimeModal()"
>
  <div
    id="forecast-table-grid-period-time-datepicker"
    class="flex absolute items-center justify-end"
    [ngStyle]="{ cursor: !disabled ? 'pointer' : 'default' }"
  >
    <aux-icon name="Calendar" />
  </div>
  <ng-select
    class="forecast-table-grid-select"
    [searchable]="false"
    [clearable]="false"
    [appendTo]="'body'"
    [isOpen]="false"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [ngClass]="{ 'is-invalid': isInvalid }"
    [ngModel]="selectedPhase"
  >
    <ng-option *ngFor="let option of phases" [value]="option.value">
      <span [title]="option.label">{{ option.label }}</span>
    </ng-option>
  </ng-select>
</div>
