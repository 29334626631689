import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { BudgetActivityAttributes } from '@services/gql.service';
import { isArray } from 'lodash-es';

type BudgetEnhancedAttributesComponentParams = ICellRendererParams & {
  custom: { name: string; maxWidth?: string };
  col_index: number;
};

@Component({
  template: `
    <div
      *ngIf="status"
      class="flex items-center text-[12px] h-[25px] rounded-[2.5px] justify-center px-[10px]"
      [auxTooltip]="status"
      [ngClass]="[bgClass, widthClass]"
    >
      <div
        class="max-w whitespace-nowrap overflow-hidden text-ellipsis font-bold"
        [ngClass]="textClass"
      >
        {{ status }}
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100px;
        height: 33px;
        align-items: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetEnhancedAttributesComponent implements ICellRendererAngularComp {
  params!: BudgetEnhancedAttributesComponentParams;

  status!: string;

  bgClass!: string;

  textClass!: string;

  widthClass!: string;

  colors = [
    {
      bg: 'bg-aux-gray-dark-100',
      text: 'text-white',
    },
    {
      bg: 'border-[0.5px] border-aux-green-dark bg-white',
      text: 'text-aux-green-dark',
    },
    {
      bg: 'border-[0.5px] border-aux-gray-darkest bg-aux-gray-dark',
      text: 'text-aux-gray-darkest',
    },
    {
      bg: 'bg-aux-gray-darkest',
      text: 'text-white',
    },
    {
      bg: 'bg-aux-green-dark',
      text: 'text-white',
    },
    {
      bg: 'border-[0.5px] border-aux-blue bg-white',
      text: 'text-aux-blue',
    },
  ];

  agInit(params: BudgetEnhancedAttributesComponentParams): void {
    this.params = params;

    const bg = this.colors[params.col_index % this.colors.length].bg;
    const text = this.colors[params.col_index % this.colors.length].text;

    this.setStatus(
      bg,
      text,
      params.data?.[`custom_attr_${btoa(params.custom?.name)}`] ||
        this.getCategoryStatus(params) ||
        '',
      params.custom?.maxWidth || 'max-w-[98px]'
    );
  }

  setStatus(bgClass: string, textClass: string, status: string, widthClass: string) {
    this.bgClass = bgClass;
    this.status = status;
    this.textClass = textClass;
    this.widthClass = widthClass;
  }

  // if all activities of the category have the same attribute, it will return that attribute value
  getCategoryStatus(params: BudgetEnhancedAttributesComponentParams) {
    if (params.node.allChildrenCount) {
      const allChildValues = params.node.allLeafChildren.map((child) => {
        if (!isArray(child.data.attributes)) {
          child.data.attributes = [];
        }
        return child.data.attributes.filter((a: BudgetActivityAttributes) => {
          return a.attribute_name === params.custom.name;
        })[0]?.attribute_value;
      });
      if (allChildValues.every((x) => x == allChildValues[0])) {
        return allChildValues[0];
      }
    }
  }

  refresh(): boolean {
    return false;
  }
}
