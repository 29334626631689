import { EventQuery } from './../../../../../models/event/event.query';
import { ChangeDetectionStrategy, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthQuery } from '@models/auth/auth.query';
import { OrganizationQuery } from '@models/organization/organization.query';
import { OrganizationService } from '@models/organization/organization.service';
import { OrganizationStore } from '@models/organization/organization.store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ApprovalType,
  EntityType,
  EventType,
  GqlService,
  InvoiceStatus,
  listUserNamesWithEmailQuery,
  PermissionType,
  User,
  WorkflowStep,
} from '@services/gql.service';
import { OverlayService } from '@services/overlay.service';
import { BehaviorSubject, combineLatest, firstValueFrom, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { Utils } from '@services/utils';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { EventService } from '@services/event.service';
import { UserTasksService } from '@models/user-tasks';
import { AuthService } from '@models/auth/auth.service';
import { InvoiceModel } from '../state/invoice.model';
import { InvoiceQuery } from '../state/invoice.query';
import { InvoiceService } from '../state/invoice.service';
import { InvoiceComponent } from '../invoice/invoice.component';
import { ROUTING_PATH } from '../../../../../app-routing-path.const';
import {
  WorkflowQuery,
  WorkflowService,
} from '../../../../closing-page/tabs/quarter-close/close-quarter-check-list/store';
import { last } from 'lodash-es';
import { MessagesConstants } from '@constants/messages.constants';

interface ApprovalTitle {
  approved_by: string;
  approved_time: string;
}

@UntilDestroy()
@Component({
  selector: 'aux-invoices-detail',
  templateUrl: './invoices-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesDetailComponent implements OnInit {
  @ViewChildren('invoiceProc') invoiceFormRef!: QueryList<InvoiceComponent>;

  spinner = {
    inQueue: false,
    delete: false,
    edit: false,
    save: false,
    cancel: false,
    download: false,
    downloadTemplate: false,
    approve: false,
    forceApprove: false,
    decline: false,
    adminReview: false,
  };

  invoicesLink = `/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}/${ROUTING_PATH.VENDOR_PAYMENTS.INVOICES}`;

  loading$ = new BehaviorSubject(false);

  invoices$ = new BehaviorSubject<InvoiceModel | null>(null);

  isInvoiceFinalized$ = this.workflowQuery.getLockStatusByWorkflowStepType(
    WorkflowStep.WF_STEP_MONTH_CLOSE_LOCK_INVOICES
  );

  iCloseMonthsProcessing$ = this.eventQuery.selectProcessingEvent(EventType.CLOSE_TRIAL_MONTH);

  invoiceLockTooltip$ = this.workflowQuery.invoiceLockTooltip$;

  selectedVendor = new UntypedFormControl('');

  isRemove = false;

  invoiceId = '';

  dontHavePermission = MessagesConstants.DO_NOT_HAVE_PERMISSIONS_TO_ACTION;

  organization$ = this.invoices$.pipe(
    switchMap((inv) => {
      return this.organizationQuery.selectEntity(inv?.organization?.id);
    })
  );

  status$ = this.invoices$.pipe(
    map((invoice) => {
      let status = '';
      if (invoice) {
        switch (invoice.invoice_status) {
          case InvoiceStatus.STATUS_PENDING_APPROVAL:
            status = 'Pending Approval';
            break;
          case InvoiceStatus.STATUS_IN_QUEUE:
            status = 'In Queue';
            break;
          case InvoiceStatus.STATUS_PENDING_REVIEW:
            status = 'Pending Review';
            break;
          case InvoiceStatus.STATUS_APPROVED:
            status = 'Approved';
            break;
          case InvoiceStatus.STATUS_DECLINED:
            status = 'Declined';
            break;
          default:
            break;
        }
      }
      return status;
    })
  );

  isDecline$ = this.statusCheck(InvoiceStatus.STATUS_DECLINED);

  isPendingApproval$ = this.statusCheck(InvoiceStatus.STATUS_PENDING_APPROVAL);

  isInQueue$ = this.statusCheck(InvoiceStatus.STATUS_IN_QUEUE);

  isPendingReview$ = this.statusCheck(InvoiceStatus.STATUS_PENDING_REVIEW);

  isApproved$ = this.statusCheck(InvoiceStatus.STATUS_APPROVED);

  approvalBy$ = new BehaviorSubject<ApprovalTitle | null>(null);

  users = new Map<string, Pick<User, 'given_name' | 'family_name' | 'email'>>();

  btnLoading$ = new BehaviorSubject(this.spinner);

  trialMonthClose$ = new BehaviorSubject('');

  trialEndDate$ = new BehaviorSubject('');

  isEditEnabled$ = this.authService.isAuthorized$({
    permissions: [PermissionType.PERMISSION_EDIT_INVOICE],
  });

  isDeleteEnabled$ = this.authService.isAuthorized$({
    permissions: [PermissionType.PERMISSION_DELETE_INVOICE],
  });

  isApproveEnabled$ = this.authService.isAuthorized$({
    permissions: [PermissionType.PERMISSION_APPROVE_INVOICE],
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private invoiceQuery: InvoiceQuery,
    private invoiceService: InvoiceService,
    public authQuery: AuthQuery,
    private organizationQuery: OrganizationQuery,
    private vendorsService: OrganizationService,
    private organizationStore: OrganizationStore,
    private overlayService: OverlayService,
    private gqlService: GqlService,
    private mainQuery: MainQuery,
    private eventService: EventService,
    private authService: AuthService,
    private userTaskService: UserTasksService,
    private workflowQuery: WorkflowQuery,
    private workflowService: WorkflowService,
    private eventQuery: EventQuery
  ) {
    this.route.paramMap
      .pipe(
        tap(() => {
          this.loading$.next(true);
        }),
        map((params) => params.get('id')),
        switchMap((id) => {
          // trying to catch an issue where the query param ?trial= is included in the param 'id' value on very rare occassions
          let verifiedId = id;
          if (verifiedId?.includes('?')) {
            verifiedId = verifiedId.substring(0, verifiedId.indexOf('?'));
          } else if (verifiedId?.includes('%')) {
            verifiedId = verifiedId.substring(0, verifiedId.indexOf('%'));
          }
          return combineLatest([
            this.invoiceService.getOne(verifiedId || ''),
            this.gqlService.getTrialInformation$(),
          ]).pipe(
            switchMap(([{ success, data }, { data: trial }]) => {
              if (success && data) {
                const trialMonthClose = trial?.length ? trial[0].trial_month_close : '';
                const trialEndDate = trial?.length ? trial[0].trial_end_date : '';
                return this.invoiceQuery.selectEntity(id || '').pipe(
                  map((invoice) => {
                    return {
                      invoice,
                      trialMonthClose,
                      trialEndDate,
                    };
                  })
                );
              }
              return of(null);
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.invoices$.next(data?.invoice || null);
        this.trialEndDate$.next(data?.trialEndDate ?? '');
        this.trialMonthClose$.next(data?.trialMonthClose ?? '');
        this.loading$.next(false);
      });

    this.workflowService
      .getWorkflowListFromStore(this.authQuery.isAuxAdmin())
      .pipe(untilDestroyed(this))
      .subscribe();

    combineLatest([this.mainQuery.select('userList'), this.route.paramMap])
      .pipe(
        tap(([_users]) => {
          _users.forEach((user: listUserNamesWithEmailQuery) => {
            this.users.set(user.sub, user);
          });
        }),
        switchMap(() => {
          return this.invoices$;
        }),
        untilDestroyed(this)
      )
      .subscribe((invoice) => {
        if (invoice) {
          this.invoiceId = invoice.id;
          const approval = last(invoice.approvals);
          if (approval) {
            this.approvalBy$.next(<ApprovalTitle>{
              approved_by: this.userFormatter(approval.aux_user_id || ''),
              approved_time: approval.approval_time || '',
            });
          }
        }
      });
  }

  ngOnInit() {
    combineLatest([this.vendorsService.get(), this.route.paramMap])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const vendors = this.organizationQuery.getAllVendors();
        if (vendors.length === 1) {
          this.organizationStore.setActive(vendors[0].id);
          this.selectedVendor.setValue(vendors[0].id);
        } else {
          // reset any older selected vendors.
          this.organizationStore.setActive(null);
          this.selectedVendor.setValue('');
        }
      });

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigateByUrl(`/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}`);
      });
  }

  async canDeactivate(): Promise<boolean> {
    const invoiceFormRef = this.invoiceFormRef.first;
    if (invoiceFormRef) {
      const oldInvoice = this.invoiceQuery
        .getAll()
        .find((invoice) => invoice.id === invoiceFormRef.clonedInvoice.id);

      if (oldInvoice) {
        if (
          oldInvoice.id !== invoiceFormRef.clonedInvoice.id ||
          oldInvoice.invoice_no !== invoiceFormRef.clonedInvoice.invoice_no
        ) {
          return true;
        }

        if (
          (!invoiceFormRef.invoiceForm.pristine ||
            !invoiceFormRef.notesControl.pristine ||
            this.itemsViaPdfTabHasChanges()) &&
          !this.isRemove
        ) {
          const result = this.overlayService.open({ content: GuardWarningComponent });
          const event = await firstValueFrom(result.afterClosed$);
          return !!event.data;
        }
        return true;
      }
      return false;
    }
    return true;
  }

  async onSave() {
    this.btnLoading$.next({ ...this.spinner, save: true });

    this.invoiceFormRef.first.invoiceFormRef.onSubmit({} as Event);

    if (this.invoiceFormRef.first.invoiceForm.valid) {
      await this.invoiceFormRef.first.onEditSave();
    }

    this.btnLoading$.next({ ...this.spinner, save: false });
  }

  isPoInvalid() {
    return this.invoiceFormRef?.first?.selectedPOReference?.status === 'INVALID';
  }

  statusCheck(status: InvoiceStatus) {
    return this.invoices$.pipe(map((x) => x?.invoice_status === status));
  }

  async onDelete() {
    const inv = this.invoices$.getValue();

    if (!inv) {
      return;
    }

    if (this.btnLoading$.getValue().delete) {
      return;
    }
    this.btnLoading$.next({ ...this.spinner, delete: true });
    const resp = this.overlayService.openConfirmDialog({
      header: 'Remove Invoice?',
      message: `Are you sure you want to remove Invoice ${inv.invoice_no}?`,
      okBtnText: 'Remove',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await firstValueFrom(resp.afterClosed$);
    if (event.data?.result) {
      const id = this.invoiceQuery.getEntity(inv.id);
      if (!id) {
        return;
      }

      const { success } = await this.invoiceService.remove(id, event.data?.textarea);

      if (success) {
        this.loading$.next(true);
        this.isRemove = true;
        await this.userTaskUpdate();
        this.loading$.next(false);
        this.router.navigateByUrl(`/${ROUTING_PATH.VENDOR_PAYMENTS.INDEX}`);
      }
    }
    this.btnLoading$.next({ ...this.spinner, delete: false });
  }

  async onDownloadInv() {
    const inv = this.invoices$.getValue();
    if (!inv) {
      return;
    }
    if (this.btnLoading$.getValue().download) {
      return;
    }
    this.btnLoading$.next({ ...this.spinner, download: true });
    const { success, data, errors } = await this.invoiceQuery.downloadINV(inv.id);

    if (success && data) {
      this.overlayService.success();
    } else {
      this.overlayService.error(errors);
    }
    this.btnLoading$.next({ ...this.spinner, download: false });
  }

  async onApprove(header = '') {
    const inv = this.invoices$.getValue();
    if (!inv) {
      return;
    }
    if (this.btnLoading$.getValue().forceApprove) {
      return;
    }
    this.btnLoading$.next({ ...this.spinner, forceApprove: true });

    const resp = this.overlayService.openConfirmDialog({
      header: `${header} Approve Invoice?`,
      message: `Are you sure you want to approve Invoice ${inv.invoice_no}?`,
      okBtnText: `${header} Approve`,
    });
    const event = await firstValueFrom(resp.afterClosed$);

    if (!event.data?.result) {
      this.btnLoading$.next({ ...this.spinner, forceApprove: false });
      return;
    }

    const { success: approveSuccess, errors: approveErrors } = await firstValueFrom(
      this.gqlService.approveRule$({
        approved: true,
        comments: '',
        permission: 'PERMISSION_APPROVE_INVOICE',
        approval_type: ApprovalType.APPROVAL_INVOICE,
        entity_id: inv.id,
        entity_type: EntityType.INVOICE,
        activity_details: '{}',
      })
    );
    if (!approveSuccess) {
      this.overlayService.error(approveErrors);
      this.btnLoading$.next({ ...this.spinner, forceApprove: false });
      return;
    }
    await this.invoiceService.update({
      ...inv,
      invoice_status: InvoiceStatus.STATUS_APPROVED,
    });
    this.btnLoading$.next(this.spinner);
    await this.userTaskUpdate();
  }

  async onDecline() {
    const inv = this.invoices$.getValue();

    if (!inv?.id) {
      return;
    }

    if (this.btnLoading$.getValue().decline) {
      return;
    }
    this.btnLoading$.next({ ...this.spinner, decline: true });

    const resp = this.overlayService.openConfirmDialog({
      header: 'Decline Invoice?',
      message: `Are you sure you want to decline Invoice ${inv.invoice_no}?`,
      okBtnText: 'Decline',
      textarea: {
        label: 'Reason',
        required: true,
      },
    });
    const event = await firstValueFrom(resp.afterClosed$);

    if (!event.data?.result) {
      this.btnLoading$.next({ ...this.spinner, decline: false });
      return;
    }

    const { success: approveSuccess, errors: approveErrors } = await firstValueFrom(
      this.gqlService.approveRule$({
        approved: false,
        comments: '',
        permission: 'PERMISSION_APPROVE_INVOICE',
        approval_type: ApprovalType.APPROVAL_INVOICE,
        entity_id: inv.id,
        entity_type: EntityType.INVOICE,
        activity_details: '{}',
      })
    );
    if (!approveSuccess) {
      this.overlayService.error(approveErrors);
      this.btnLoading$.next({ ...this.spinner, decline: false });
      return;
    }

    await this.invoiceService.update({
      ...inv,
      decline_reason: event.data.textarea,
      invoice_status: InvoiceStatus.STATUS_DECLINED,
    });
    await this.userTaskUpdate();
    this.btnLoading$.next(this.spinner);
  }

  async userTaskUpdate() {
    await this.userTaskService.triggerUserTaskList$();
  }

  userFormatter(sub: string | undefined) {
    const user = this.users.get(sub || '');
    if (user) {
      const isUserAuxAdmin = user.email.includes('@auxili.us');
      if (this.authQuery.isAuxAdmin() || !isUserAuxAdmin) {
        return `${user.given_name} ${user.family_name}`;
      }
      return 'Auxilius Expert';
    }
    return Utils.zeroHyphen;
  }

  private itemsViaPdfTabHasChanges(): boolean {
    return (
      this.invoiceFormRef.first.itemsViaPdfTab &&
      this.invoiceFormRef.first.itemsViaPdfTab.hasChanges()
    );
  }
}
