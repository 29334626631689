<div class="grid bg-white" style="width: 500px">
  <span class="font-bold text-aux-black text-3xl mb-4"> Unsaved Changes</span>
  <span class="text-xl text-aux-black mb-4">
    You have unsaved changes that will be lost if you decide to continue</span
  >
  <span class="text-xl text-aux-black mb-6"> Are you sure that you want to leave this page?</span>
  <div class="flex justify-between">
    <button class="btn--secondary text-xl p-3 pl-5 pr-5" (click)="this.ref.close(false)">
      Stay on this Page
    </button>
    <button class="btn--primary text-xl p-3 pl-5 pr-5" (click)="this.ref.close(true)">
      Leave this Page
    </button>
  </div>
</div>
