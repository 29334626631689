import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { InvoiceModel } from './invoice.model';

export type InvoiceState = EntityState<InvoiceModel, string>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'invoice' })
export class InvoiceStore extends EntityStore<InvoiceState> {
  constructor() {
    super({});
  }
}
