import { Injectable } from '@angular/core';
import { EntityState, EntityStore, getEntityType, StoreConfig } from '@datorama/akita';
import { DriverPatientGroup } from '@services/gql.service';

export type PatientCurveState = EntityState<
  DriverPatientGroup & {
    showLine: boolean;
    patient_group_ids: string[];
    patient_group_id: string;
  }
>;

export type PatientCurveModel = getEntityType<PatientCurveState>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patient-curve', idKey: 'driver_setting_id' })
export class PatientCurveStore extends EntityStore<PatientCurveState> {
  constructor() {
    super({});
  }
}
