<div>
  <aux-workflow-panel
    *ngIf="(isQuarterCloseEnabled$ | async) && (isClosingPanelEnabled$ | async)"
    className="mb-6"
    [workflowName]="workflowName"
    [isAdminUser]="isAdminUser"
    [processing]="(iCloseMonthsProcessing$ | async)!"
    [hasUserPermissions]="userHasLockPatientSiteCurvePermission"
  />

  <div class="relative">
    <aux-tab-group class="absolute" [tabs]="tabs" [variant]="'button'" />
  </div>

  <router-outlet />
</div>
