<div class="flex justify-between items-center mb-8">
  <h1 class="text-2xl text-aux-gray-darkest font-bold h-11 flex items-center">Dashboard</h1>
  <!--<div class="flex space-x-2"></div>-->
</div>

<div class="rounded border p-4 mb-5">
  <div class="font-bold text-xl text-black mb-4">Notifications and Alerts</div>

  <div class="flex space-x-4 mb-4 text-xs">
    <aux-checkbox [id]="'all'" [checked]="true">All</aux-checkbox>
    <aux-checkbox [id]="'risk_flags'">Risk Flags</aux-checkbox>
    <aux-checkbox [id]="'change_management'">Change Management</aux-checkbox>
    <aux-checkbox [id]="'tasks'">Tasks</aux-checkbox>
    <aux-checkbox [id]="'financial'">Financial</aux-checkbox>
  </div>

  <ng-container *ngFor="let alert of alerts">
    <div class="grid grid-cols-12 rounded border p-6 my-2 items-center">
      <div class="col-span-3">
        <div class="text-xs">{{ alert.date }}</div>
        <div class="font-bold text-sm text-aux-black">{{ alert.header }}</div>
      </div>
      <div class="flex justify-between items-center col-span-9">
        <div class="text-sm text-aux-black" [innerHTML]="alert.html"></div>
        <aux-icon name="X" />
      </div>
    </div>
  </ng-container>

  <div class="text-aux-blue flex items-center justify-center text-sm">
    Expand All
    <aux-icon name="ChevronDown" class="text-aux-blue" />
  </div>
</div>

<div class="grid grid-cols-3 gap-5">
  <div class="border border-aux-gray-dark rounded overflow-hidden p-4">
    <div class="font-bold text-xl text-aux-black mb-4">Work Performed, by Trial/Vendor</div>
    <div class="space-y-4">
      <ng-container *ngFor="let timeline of timelineData">
        <ng-container
          [ngTemplateOutlet]="progress"
          [ngTemplateOutletContext]="{
            config: timeline
          }"
        />
      </ng-container>
    </div>
  </div>
  <div class="border border-aux-gray-dark rounded overflow-hidden p-4">
    <div class="font-bold text-xl text-aux-black mb-4">Change Log Summary</div>

    <div class="text-xs flex">
      <div
        *ngIf="percentage > 0"
        class="h-5 rounded-l flex items-center pr-8 justify-end"
        [ngClass]="{
          'bg-aux-blue': percentage <= 100,
          'bg-aux-error': percentage > 100
        }"
        [ngStyle]="{ width: (percentage > 100 ? 100 : percentage) + '%' }"
      >
        <span *ngIf="percentage > 20" class="text-white">(%{{ percentage }}) </span>
      </div>
      <div
        class="h-5 bg-aux-blue-light rounded-r flex items-center pl-4"
        [ngStyle]="{ width: 100 - (percentage > 100 ? 100 : percentage) + '%' }"
      >
        <span class="text-white">$636,540</span>
      </div>
    </div>

    <div class="flex text-xs space-x-4 mt-2 mb-4">
      <div class="flex items-center space-x-2">
        <div class="w-4 h-4 rounded-full bg-aux-blue"></div>
        <div>% Unapproved</div>
      </div>
      <div class="flex items-center space-x-2">
        <div class="w-4 h-4 rounded-full bg-aux-blue-light"></div>
        <div>Total Open Change Logs</div>
      </div>
    </div>

    <table class="w-full">
      <thead>
        <tr>
          <th></th>
          <th class="text-center text-aux-black text-xs font-normal">Total CL, $</th>
          <th class="text-center text-aux-black text-xs font-normal">Unapproved, % ($)</th>
        </tr>
      </thead>
      <tbody>
        <tr class="border-b border-aux-gray-light">
          <td class="font-medium text-aux-black text-sm py-2">Vendor 1</td>
          <td class="text-center text-sm"><strong>$58,000</strong></td>
          <td class="text-center text-sm"><strong></strong></td>
        </tr>
        <tr class="border-b border-aux-gray-light">
          <td class="font-medium text-aux-black text-sm py-2">Vendor 2</td>
          <td class="text-center text-sm"><strong>$456,000</strong></td>
          <td class="text-center text-sm"><strong></strong></td>
        </tr>
        <tr class="border-b border-aux-gray-light">
          <td class="font-medium text-aux-black text-sm py-2">Vendor 3</td>
          <td class="text-center text-sm"><strong>$122,540</strong></td>
          <td class="text-center text-sm"><strong>$118,560</strong></td>
        </tr>
      </tbody>
    </table>

    <a href="#" class="mt-2 text-sm block font-medium underline text-aux-blue">See all</a>
  </div>
  <div class="border border-aux-gray-dark rounded overflow-hidden p-4">
    <div class="font-bold text-xl text-aux-black mb-4">Risk Flags</div>

    <div class="flex text-xs space-x-4 mt-8 mb-16 font-bold">
      <div class="flex items-center space-x-2">
        <div class="w-4 h-4 rounded-full bg-aux-green"></div>
        <div>8 healthy monitors</div>
      </div>
      <div class="flex items-center space-x-2">
        <div class="w-4 h-4 rounded-full bg-aux-error"></div>
        <div>5 flagged monitors</div>
      </div>
    </div>

    <div class="font-bold text-sm">Recently changed monitors</div>

    <div class="flex flex-col text-xs space-y-4 mt-2 mb-4">
      <div class="flex items-center space-x-2">
        <div class="w-4 h-4 rounded-full bg-aux-green"></div>
        <div>Cash position remains above $1mm</div>
      </div>
      <div class="flex items-center space-x-2">
        <div class="w-4 h-4 rounded-full bg-aux-error"></div>
        <div>Average cost per patient to date exceeds budget by 20%</div>
      </div>
      <div class="flex items-center space-x-2">
        <div class="w-4 h-4 rounded-full bg-aux-error"></div>
        <div>15% of sites with target site efficiency ratio < 3</div>
      </div>
    </div>
  </div>
</div>

<ng-template #progress let-config="config">
  <div>
    <div class="font-medium text-aux-black text-sm">
      {{ config.header }}
    </div>

    <ng-container
      [ngTemplateOutlet]="progressTemplate"
      [ngTemplateOutletContext]="{
        config: config
      }"
    />

    <!-- <progressbar-->
    <!--   [value]="config.value"-->
    <!--   [max]="100"-->
    <!--   [ngClass]="{-->
    <!--     green: config.progressColor === 'green',-->
    <!--     blue: config.progressColor === 'blue'-->
    <!--   }"-->
    <!--   class="h-1.5 my-2 bg-aux-gray-dark"-->
    <!-- ></progressbar>-->

    <div class="flex justify-between items-center">
      <div class="text-aux-black text-xs">{{ config.leftLabel }}</div>
      <div class="text-aux-black text-xs">{{ config.rightLabel }}</div>
    </div>
  </div>
</ng-template>

<ng-template #progressTemplate let-config="config">
  <div class="relative">
    <div class="h-1.5 my-2 bg-aux-gray-dark rounded-full"></div>
    <div
      class="absolute h-1.5 rounded-full inset-0"
      [ngClass]="{
        'bg-aux-green': config.progressColor === 'green',
        'bg-aux-blue': config.progressColor === 'blue'
      }"
      [style.width]="config.value + '%'"
    ></div>
  </div>
</ng-template>
