import { MainQuery } from 'src/app/layouts/main-layout/state/main.query';
import { FormGroup } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  of,
  ReplaySubject,
} from 'rxjs';
import {
  CellValueChangedEvent,
  DomLayoutType,
  FillOperationParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ProcessCellForExportParams,
  RowDragEndEvent,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { isEqual, partition } from 'lodash-es';
import { PatientProtocolService } from '@models/patient-protocol/patient-protocol.service';
import { PatientProtocolQuery } from '@models/patient-protocol/patient-protocol.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventService } from '@services/event.service';
import {
  CreatePatientProtocolInput,
  EntityType,
  EventType,
  GqlService,
  PatientGroupType,
  PatientProtocolFrequency,
  PatientProtocolSubType,
  PatientProtocolType,
  PermissionType,
} from '@services/gql.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { Maybe, RequireSome, Utils } from '@services/utils';
import { OverlayService } from '@services/overlay.service';
import { PatientProtocolStore } from '@models/patient-protocol/patient-protocol.store';
import { GuardWarningComponent } from '@components/guard-warning/guard-warning.component';
import { AgCellWrapperComponent } from '@components/ag-cell-wrapper/ag-cell-wrapper.component';
import { LaunchDarklyService } from '@services/launch-darkly.service';
import { StickyElementService } from '@services/sticky-element.service';
import { MessagesConstants } from '@constants/messages.constants';
import { TableConstants } from '@constants/table.constants';
import { PatientGroupsService } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.service';
import { PatientGroupsQuery } from '../../forecast-accruals-page/tabs/forecast/drivers/patients/patient-groups/state/patient-groups.query';
import { ButtonToggleItem } from '@components/button-toggle-group/button-toggle-item.model';
import { ProtocolForm } from './protocol-section/protocol-section.component';
import { ProtocolVersionModalComponent } from './protocol-version-modal.component';
import { Option } from '@components/components.type';
import { EditableListDropdownItem } from '@components/editable-list-dropdown/editable-list-dropdown-item.model';
import { AgSetColumnsVisible } from '@shared/utils';
import { AuthService } from '@models/auth/auth.service';

interface PatientProtocolGridData {
  target_tolerance_days_out?: number | undefined;
  target_date_days_out?: number | null | undefined;
  patient_protocol_name?: string | undefined;
  patient_group_id?: string | undefined;
  patient_protocol_id: string;
  patient_protocol_type?: PatientProtocolType | undefined;
  patient_protocol_frequency?: PatientProtocolFrequency | null | undefined;
  patient_protocol_sub_type?: PatientProtocolSubType | null | undefined;
  patient_protocol_sub_type_id?: string | null | undefined;
  order_by: number;
}

enum PatientProtocolForVisits {
  PATIENT_PROTOCOL_PATIENT_VISIT = 'PATIENT_PROTOCOL_PATIENT_VISIT',
}

enum PatientProtocolForOthers {
  PATIENT_PROTOCOL_DISCONTINUED = 'PATIENT_PROTOCOL_DISCONTINUED',
  PATIENT_PROTOCOL_OTHER = 'PATIENT_PROTOCOL_OTHER',
  PATIENT_PROTOCOL_OVERHEAD = 'PATIENT_PROTOCOL_OVERHEAD',
  PATIENT_PROTOCOL_SCREEN_FAIL = 'PATIENT_PROTOCOL_SCREEN_FAIL',
}

@UntilDestroy()
@Component({
  selector: 'aux-patient-protocol-edit',
  templateUrl: './patient-protocol-edit.component.html',
  styleUrls: ['./patient-protocol-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientProtocolEditComponent implements OnDestroy {
  patientOptions: ButtonToggleItem[] = [];

  protocolVersionOptions: (Option & { effective_date?: Maybe<string> })[] = [];

  protocolForm!: FormGroup;

  isProtocolVersionLoading$ = new BehaviorSubject(true);

  isProtocolSubTypesLoading$ = new BehaviorSubject(true);

  protocolSubTypes: PatientProtocolSubType[] = [];

  userHasUpdateProtocolEntryPermission = false;

  gridOptions$: BehaviorSubject<GridOptions> = new BehaviorSubject({
    defaultColDef: {
      ...TableConstants.DEFAULT_GRID_OPTIONS.DEFAULT_COL_DEF,
      cellRenderer: AgCellWrapperComponent,
      editable: () => {
        return this.editModeGrid$.getValue();
      },
    },
    ...TableConstants.DEFAULT_GRID_OPTIONS.GRID_OPTIONS,
    suppressCellFocus: false,
    enterNavigatesVertically: true,
    enterNavigatesVerticallyAfterEdit: true,
    undoRedoCellEditingLimit: 20,
    undoRedoCellEditing: true,
    enableRangeSelection: true,
    suppressMenuHide: true,
    rowDragManaged: true,
    getRowId: ({ data }) => data?.patient_protocol_id || data?.randomID,
    processCellForClipboard: (params: ProcessCellForExportParams) => {
      return this.subTypeCopyForClipboard(params);
    },
    processCellFromClipboard: (params: ProcessCellForExportParams) => {
      return this.subTypeCopyFromClipboard(params);
    },
    fillOperation: (params: FillOperationParams) => {
      if (params.column.getColId() === 'patient_protocol_sub_type') {
        return params.values[0];
      }
      return false;
    },
    columnDefs: [
      {
        headerName: 'patient_protocol_id',
        field: 'patient_protocol_id',
        hide: true,
      },
      {
        ...TableConstants.DEFAULT_GRID_OPTIONS.ACTIONS_COL_DEF,
        cellRendererParams: {
          deleteClickFN: ({ rowNode }: { rowNode: RowNode }) => {
            if (rowNode.data.patient_protocol_id) {
              this.removedRows.push(rowNode.data.patient_protocol_id);
            } else if (this.addedRowCounter === 1) {
              this.reorderedRows.clear();
              this.removedRows = [];
              this.newRowAdded = false;
            } else {
              this.removedRows.pop();
            }
            this.addedRowCounter -= 1;
            this.gridAPI.applyTransaction({ remove: [rowNode.data] });
            this.checkChanges();
          },
        },
        rowDrag: true,
        hide: true,
      },
      {
        headerName: 'RANDOM ID',
        field: 'randomID',
        hide: true,
      },
      {
        headerName: 'Name',
        field: 'patient_protocol_name',
        resizable: true,
        cellClass: 'text-left',
        cellClassRules: {
          'has-cell-error': (params) => {
            return params.data.missingNameError || params.data.duplicateNameError;
          },
        },
        tooltipValueGetter: Utils.getDuplicateNameCellTooltip,
        valueSetter: (params: ValueSetterParams) => {
          params.data.patient_protocol_name = params.newValue.toString();

          return true;
        },
      },
      {
        headerName: 'Visit Day',
        field: 'target_date_days_out',
        minWidth: 150,
        valueFormatter: Utils.preserveZeroDashFormatter,
        cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
        valueParser: (params) => {
          return params.newValue === '' ? null : params.newValue;
        },
      },
      {
        headerName: 'Visit Window [+/-]',
        field: 'target_tolerance_days_out',
        minWidth: 150,
        valueFormatter: Utils.dashFormatter,
        cellClass: [TableConstants.STYLE_CLASSES.CELL_ALIGN_RIGHT],
        cellDataType: 'text',
      },
      {
        headerName: 'patient_group_id',
        field: 'patient_group_id',
        hide: true,
      },
      {
        field: 'patient_protocol_type',
        hide: true,
        cellEditor: 'agRichSelectCellEditor',
        minWidth: 300,
        cellClass: 'text-left',
        cellClassRules: {
          'has-cell-error': (params) => params.data.missingTypeError,
        },
        cellEditorParams: () => {
          return {
            values: Object.keys(
              this.patientGroupId$.getValue() || this.isVisit$.getValue()
                ? PatientProtocolForVisits
                : PatientProtocolForOthers
            ),
          };
        },
      },
      {
        headerName: 'Type',
        field: 'patient_protocol_sub_type',
        minWidth: 300,
        cellClass: 'text-left',
        cellClassRules: {
          'has-cell-error': (params) => params.data.missingTypeError,
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: () => {
          return {
            values: this.subTypeCellEditorParams(),
          };
        },
        valueGetter: (params: ValueGetterParams) => {
          return this.subTypeGetter(params);
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return this.subTypeFormatter(params);
        },
        valueSetter: (params: ValueSetterParams) => {
          return this.subTypeSetter(params);
        },
      },
      {
        headerName: 'Frequency',
        field: 'patient_protocol_frequency',
        cellEditor: 'agRichSelectCellEditor',
        minWidth: 300,
        cellClass: 'text-left',
        cellEditorParams: () => {
          return {
            values: [null, ...Object.keys(PatientProtocolFrequency)],
          };
        },
        valueFormatter: (node) => {
          switch (node.value) {
            case PatientProtocolFrequency.PATIENT_PROTOCOL_FREQUENCY_MONTHLY:
              return 'Monthly';
            case PatientProtocolFrequency.PATIENT_PROTOCOL_FREQUENCY_ANNUALLY:
              return 'Annually';
            case PatientProtocolFrequency.PATIENT_PROTOCOL_FREQUENCY_ACTIVATION:
              return 'Activation';
            case PatientProtocolFrequency.PATIENT_PROTOCOL_FREQUENCY_CLOSEOUT:
              return 'Closeout';
            case PatientProtocolFrequency.PATIENT_PROTOCOL_FREQUENCY_QUARTERLY:
              return 'Quarterly';
            default:
              return null;
          }
        },
      },
    ],
  } as GridOptions);

  loading$ = this.patientProtocolQuery.selectLoading();

  gridAPI$ = new ReplaySubject<GridApi>(1);

  gridAPI!: GridApi;

  gridData$: Observable<PatientProtocolGridData[]> = this.patientProtocolQuery.selectAll().pipe(
    map((value) => {
      return value.map(
        ({
          id,
          name,
          patient_protocol_type,
          patient_protocol_sub_type,
          patient_protocol_frequency,
          target_tolerance_days_out,
          target_date_days_out,
          order_by,
        }) => ({
          patient_protocol_id: id,
          patient_protocol_name: name,
          patient_protocol_type,
          patient_protocol_sub_type,
          patient_protocol_frequency,
          target_tolerance_days_out,
          target_date_days_out,
          order_by,
        })
      );
    })
  );

  editedRows = new Set<string>();

  removedRows: string[] = [];

  reorderQueue: { id: string; new_index: number }[] = [];

  reorderedRows = new Set<string>();

  addedRowCounter = 0;

  newRowAdded = false;

  initialValues: PatientProtocolGridData[] = [];

  hasChanges = false;

  editModeGrid$ = new BehaviorSubject(false);

  patientGroupId$ = new BehaviorSubject<string | null>(null);

  isVisit$ = new BehaviorSubject(false);

  defaultPatientGroup = [
    {
      value: 'visitCosts',
      label: 'Visit Costs',
      show: this.launchDarklyService.select$((flags) => flags.visit_costs),
    },
    {
      value: 'invoiceables',
      label: 'Invoiceables',
    },
  ];

  domLayout: DomLayoutType = 'autoHeight';

  constructor(
    private patientProtocolService: PatientProtocolService,
    private patientProtocolQuery: PatientProtocolQuery,
    private patientProtocolStore: PatientProtocolStore,
    private patientGroupService: PatientGroupsService,
    private patientGroupQuery: PatientGroupsQuery,
    private overlayService: OverlayService,
    private eventService: EventService,
    private gqlService: GqlService,
    private launchDarklyService: LaunchDarklyService,
    private mainQuery: MainQuery,
    private stickyElementService: StickyElementService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.setUserPermissions();
    this.launchDarklyService
      .select$((flags) => flags.visit_costs)
      .pipe(untilDestroyed(this))
      .subscribe((showVisit) => this.isVisit$.next(showVisit));

    this.mainQuery
      .select('trialKey')
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          this.isProtocolVersionLoading$.next(true);

          return this.patientProtocolService.getPatientProtocolVersions();
        })
      )
      .subscribe((versions) => {
        this.isProtocolVersionLoading$.next(false);
        this.protocolVersionOptions = versions.map(({ id, name, effective_date }) => ({
          label: name,
          value: id,
          effective_date,
        }));
      });

    this.mainQuery
      .select('trialKey')
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          this.isProtocolSubTypesLoading$.next(true);

          return this.patientProtocolService.getPatientProtocolSubTypes();
        })
      )
      .subscribe((subTypes) => {
        this.isProtocolSubTypesLoading$.next(false);
        this.protocolSubTypes = subTypes;
      });

    this.patientGroupService
      .get([PatientGroupType.PATIENT_GROUP_STANDARD])
      .pipe(
        untilDestroyed(this),
        switchMap(({ data: patientGroupList }) => {
          if (!patientGroupList) {
            return of([]);
          }

          const patientGroups = patientGroupList.map((patientGroup) => {
            return { value: patientGroup.id, label: patientGroup.name };
          });

          this.patientOptions = [];

          return patientGroups.length > 0
            ? this.patientProtocolService.get(
                [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT],
                patientGroups[0].value
              )
            : this.patientProtocolService.get([PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT]);
        }),
        tap(() => {
          const patientGroups = this.patientGroupQuery.getAll().map((patientGroup) => {
            return { value: patientGroup.id, label: patientGroup.name };
          });

          if (patientGroups.length > 0) {
            this.patientGroupId$.next(patientGroups[0].value);
            this.patientOptions.unshift(...patientGroups, ...this.defaultPatientGroup);
          } else {
            this.patientOptions = this.patientOptions.concat(this.defaultPatientGroup);
          }

          this.setDefaultProtocolForm();
        })
      )
      .subscribe();

    combineLatest([this.isProtocolVersionLoading$, this.patientGroupQuery.selectAll()])
      .pipe(untilDestroyed(this))
      .subscribe(([isProtocolVersionLoading, patientGroupList]) => {
        if (!isProtocolVersionLoading && patientGroupList.length) {
          this.setDefaultProtocolForm();
        }
      });

    this.gridData$.pipe(untilDestroyed(this)).subscribe((gridData) => {
      this.initialValues = gridData;
    });

    combineLatest([
      this.eventService.select$(EventType.TRIAL_CHANGED),
      this.mainQuery.select('trialKey'),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.initialValues = [];
        this.cancelEditMode();
      });
  }

  ngOnDestroy() {
    this.stickyElementService.reset();
  }

  checkChanges() {
    const currentValues: PatientProtocolGridData[] = [];

    this.gridAPI?.forEachNode(({ data }) => {
      currentValues.push({
        ...data,
        target_date_days_out: data.target_date_days_out === '' ? null : data.target_date_days_out,
        target_tolerance_days_out:
          data.target_tolerance_days_out === '' ? 0 : data.target_tolerance_days_out,
      });
    });

    this.hasChanges = !isEqual(this.initialValues, currentValues);
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanges) {
      const result = this.overlayService.open({ content: GuardWarningComponent });
      const event = await firstValueFrom(result.afterClosed$);
      return !!event.data;
    }
    return true;
  }

  onGridReady({ api }: GridReadyEvent) {
    this.gridAPI$.next(api);
    this.gridAPI = api;
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: ['target_date_days_out', 'target_tolerance_days_out'],
      visible: !!this.patientGroupId$.getValue() || this.isVisit$.getValue(),
    });
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: ['patient_protocol_frequency'],
      visible: this.protocolForm?.controls?.patientGroup?.value === 'invoiceables',
    });
    api.sizeColumnsToFit();
  }

  onAddPatientProtocol() {
    const patientGroupId = this.patientGroupId$.getValue();
    let ss;
    if (patientGroupId) {
      ss = this.gridAPI.applyTransaction({
        add: [
          {
            randomID: Utils.uuid(),
            patient_protocol_type:
              this.patientGroupId$.getValue() || this.isVisit$.getValue()
                ? PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT
                : null,
            patient_group_id: patientGroupId || null,
          },
        ],
      });
    } else {
      ss = this.gridAPI.applyTransaction({
        add: [{ randomID: Utils.uuid() }],
      });
    }

    if (ss?.add.length && ss.add[0].rowIndex) {
      this.gridAPI.startEditingCell({
        rowIndex: ss.add[0].rowIndex,
        colKey: 'patient_protocol_name',
      });
    }
    this.addedRowCounter += 1;
    this.newRowAdded = true;
    this.sizeColumnsToFit();
    this.checkChanges();
  }

  onRowDragEnd(event: RowDragEndEvent) {
    if (event.node.data?.patient_protocol_id && event.node.rowIndex !== null) {
      this.reorderQueue.push({
        id: event.node.data.patient_protocol_id,
        new_index: event.node.rowIndex + 1,
      });
      this.reorderedRows.add(event.node.data.patient_protocol_id);
      this.checkChanges();
    }
  }

  cellValueChanged(event: CellValueChangedEvent) {
    if (event.data.patient_protocol_id) {
      this.editedRows.add(event.data.patient_protocol_id);
      this.checkChanges();
    }

    this.clearErrors();
  }

  getDuplicateNames() {
    const names: string[] = [];
    const duplicates: string[] = [];

    this.gridAPI.forEachNode((node) => {
      const name = node.data.patient_protocol_name;

      if (names.includes(name)) {
        duplicates.push(name);
      } else {
        names.push(name);
      }
    });

    return duplicates;
  }

  clearErrors() {
    const duplicateNames = this.getDuplicateNames();

    this.gridAPI.forEachNode((node) => {
      const { patient_protocol_name, patient_protocol_sub_type } =
        node.data as PatientProtocolGridData;

      if (patient_protocol_sub_type) {
        node.data.missingTypeError = false;
      }

      if (patient_protocol_name) {
        node.data.missingNameError = false;

        if (!duplicateNames.includes(patient_protocol_name)) {
          node.data.duplicateNameError = false;
        }
      }
    });

    this.gridAPI.redrawRows();
  }

  async showErrors() {
    const duplicateNames = this.getDuplicateNames();

    this.clearErrors();
    const patient_protocol_version_id = this.protocolForm.controls.protocolVersion.value || null;
    let patient_group_id = this.protocolForm.controls.patientGroup.value;
    let patient_protocol_type = PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT;
    if (patient_group_id === 'invoiceables') {
      // Using PATIENT_PROTOCOL_OTHER as a stand-in for "not-PATIENT_VISIT" since fn_patient_protocol_is_unique only cares whether the type is PATIENT_VISIT or not
      patient_protocol_type = PatientProtocolType.PATIENT_PROTOCOL_OTHER;
      patient_group_id = null;
    } else if (patient_group_id === 'visitCosts') {
      patient_group_id = null;
    }
    let isThereAnyInvalidRow = false;

    const promises: Promise<boolean>[] = [];

    this.gridAPI.forEachNode((node) => {
      const { patient_protocol_id, patient_protocol_name, patient_protocol_sub_type } =
        node.data as PatientProtocolGridData;

      if (!patient_protocol_sub_type) {
        node.data.missingTypeError = true;
        isThereAnyInvalidRow = true;
      }

      const promise: Promise<boolean> = this.patientProtocolService
        .checkPatientProtocolIsUnique(
          patient_protocol_id,
          patient_protocol_version_id,
          patient_protocol_name || '',
          patient_protocol_type,
          patient_group_id || null
        )
        .then((isUnique) => {
          if (!isUnique) {
            node.data.duplicateNameError = true;
            isThereAnyInvalidRow = true;
          }

          if (!patient_protocol_name) {
            node.data.missingNameError = true;
            isThereAnyInvalidRow = true;
          } else if (duplicateNames.includes(patient_protocol_name)) {
            node.data.duplicateNameError = true;
            isThereAnyInvalidRow = true;
          }
          return isUnique;
        });

      promises.push(promise);
    });

    await Promise.all(promises);

    this.gridAPI.clearRangeSelection();
    this.gridAPI.redrawRows();
    return isThereAnyInvalidRow;
  }

  onSaveAll = async () => {
    const isInvalid = await this.showErrors();

    if (isInvalid) {
      this.overlayService.error(MessagesConstants.RESOLVE_TABLE_ERRORS);
    }

    if (!isInvalid) {
      this.patientProtocolStore.setLoading(true);

      const upsertData: (CreatePatientProtocolInput & {
        patient_protocol_id: string | null;
      })[] = [];

      const reorderData: {
        patient_protocol_id: string;
        order_by: number;
      }[] = [];

      if (
        this.reorderQueue.length < this.gridAPI.getDisplayedRowCount() &&
        !this.removedRows.length
      ) {
        this.reorderQueue.forEach(({ new_index, id }) => {
          const pp = this.patientProtocolQuery.getEntity(id);
          if (pp) {
            const {
              patient_protocol_frequency,
              patient_protocol_sub_type,
              name,
              patient_group_id,
              target_date_days_out,
              target_tolerance_days_out,
            } = pp;

            // Extract subtype id
            const patient_protocol_sub_type_id =
              this.subTypeIdFromGridData(patient_protocol_sub_type);

            upsertData.push({
              patient_protocol_id: id,
              patient_protocol_sub_type_id,
              patient_protocol_frequency,
              patient_group_id,
              patient_protocol_version_id: this.protocolForm.controls.protocolVersion.value || null,
              name,
              target_date_days_out: Number.isNaN(parseInt(target_date_days_out + ''))
                ? null
                : parseInt(target_date_days_out + ''), // this is somehow already a string but typescript thinks it's not
              target_tolerance_days_out,
              order_by: new_index,
            });
          }
        });
        this.gridAPI.forEachNode((node) => {
          const rowIndex = (node.rowIndex || 0) + 1;

          const {
            patient_protocol_id,
            patient_protocol_frequency,
            patient_protocol_sub_type,
            patient_protocol_name,
            patient_group_id,
            target_date_days_out,
            target_tolerance_days_out,
          } = node.data as RequireSome<
            PatientProtocolGridData,
            | 'patient_protocol_id'
            | 'target_date_days_out'
            | 'patient_protocol_type'
            | 'patient_protocol_frequency'
            | 'patient_protocol_name'
            | 'patient_group_id'
          >;

          // Extract subtype id
          const patient_protocol_sub_type_id =
            this.subTypeIdFromGridData(patient_protocol_sub_type);

          if (!patient_protocol_id || this.editedRows.has(patient_protocol_id)) {
            upsertData.push({
              patient_protocol_id,
              patient_protocol_sub_type_id,
              patient_protocol_frequency,
              name: patient_protocol_name,
              target_date_days_out: Number.isNaN(parseInt(target_date_days_out + ''))
                ? null
                : parseInt(target_date_days_out + ''), // this is somehow already a string but typescript thinks it's not
              patient_protocol_version_id: this.protocolForm.controls.protocolVersion.value || null,
              patient_group_id,
              target_tolerance_days_out,
              order_by: rowIndex,
            });
          }

          if (patient_protocol_id) {
            reorderData.push({ patient_protocol_id, order_by: rowIndex });
          }
        });
      } else {
        this.gridAPI.forEachNode((node) => {
          const rowIndex = (node.rowIndex || 0) + 1;

          const {
            patient_protocol_id,
            patient_protocol_frequency,
            patient_protocol_sub_type,
            patient_protocol_name,
            patient_group_id,
            target_date_days_out,
            target_tolerance_days_out,
          } = node.data as RequireSome<
            PatientProtocolGridData,
            | 'patient_protocol_id'
            | 'patient_protocol_type'
            | 'patient_protocol_frequency'
            | 'patient_protocol_name'
            | 'patient_group_id'
            | 'target_date_days_out'
          >;

          // Extract subtype id
          const patient_protocol_sub_type_id =
            this.subTypeIdFromGridData(patient_protocol_sub_type);

          if (
            this.reorderQueue.length ||
            !patient_protocol_id ||
            this.editedRows.has(patient_protocol_id) ||
            this.removedRows.length
          ) {
            upsertData.push({
              patient_protocol_id,
              patient_protocol_sub_type_id,
              patient_protocol_frequency,
              patient_group_id,
              patient_protocol_version_id: this.protocolForm.controls.protocolVersion.value || null,
              name: patient_protocol_name,
              target_date_days_out: Number.isNaN(parseInt(target_date_days_out + ''))
                ? null
                : parseInt(target_date_days_out + ''), // this is somehow already a string but typescript thinks it's not
              target_tolerance_days_out,
              order_by: rowIndex,
            });
          }

          if (patient_protocol_id) {
            reorderData.push({ patient_protocol_id, order_by: rowIndex });
          }
        });
      }

      let hasError = true;

      if (upsertData.length) {
        for (const { patient_protocol_id, order_by } of reorderData) {
          this.patientProtocolStore.update(patient_protocol_id, { order_by });
        }
        hasError = await this.patientProtocolService.upsert(upsertData);
      }

      if (this.removedRows.length) {
        hasError = await this.patientProtocolService.remove(this.removedRows);
      }

      await firstValueFrom(
        this.gqlService.processEvent$({
          type: EventType.UPDATE_INVESTIGATOR_SCHEDULES,
          entity_type: EntityType.SITE,
          entity_id: '',
        })
      );

      if (!hasError) {
        this.overlayService.success();
      }

      this.cancelEditMode();
      this.patientProtocolStore.setLoading(false);
    }
  };

  sizeColumnsToFit(): void {
    this.gridAPI.sizeColumnsToFit();
  }

  editGrid = (): void => {
    this.domLayout = 'normal';
    this.editModeGrid$.next(true);
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: [TableConstants.FIELDS.ACTIONS],
      visible: true,
    });
    this.sizeColumnsToFit();
    this.gridAPI.redrawRows();
    this.gridAPI.startEditingCell({
      rowIndex: 0,
      colKey: 'patient_protocol_name',
    });
  };

  cancelEditMode = (): void => {
    this.domLayout = 'autoHeight';
    this.gridAPI.stopEditing();
    this.editModeGrid$.next(false);
    AgSetColumnsVisible({
      gridApi: this.gridAPI,
      keys: [TableConstants.FIELDS.ACTIONS],
      visible: false,
    });
    this.gridData$ = this.patientProtocolQuery.selectAll().pipe(
      map((value) => {
        return value.map(
          ({
            id,
            name,
            patient_protocol_type,
            patient_protocol_frequency,
            patient_protocol_sub_type,
            target_tolerance_days_out,
            target_date_days_out,
            order_by,
          }) => ({
            patient_protocol_id: id,
            patient_protocol_name: name,
            patient_protocol_type,
            patient_protocol_frequency,
            patient_protocol_sub_type,
            target_tolerance_days_out,
            target_date_days_out,
            order_by,
          })
        );
      })
    );
    this.resetChangeIndicators();
    this.gridAPI.redrawRows();
    setTimeout(() => this.sizeColumnsToFit(), 0);
  };

  private resetChangeIndicators(): void {
    this.reorderedRows.clear();
    this.editedRows.clear();
    this.removedRows = [];
    this.reorderQueue = [];
    this.newRowAdded = false;
    this.addedRowCounter = 0;
    this.hasChanges = false;
  }

  onProtocolFormReady(form: FormGroup) {
    this.protocolForm = form;

    this.setDefaultProtocolForm();
  }

  private setDefaultProtocolForm() {
    setTimeout(() => {
      this.protocolForm?.setValue({
        patientGroup: this.patientOptions[0]?.value || null,
        protocolVersion: this.protocolVersionOptions[0]?.value || null,
      });
    }, 0);
  }

  private setUserPermissions(): void {
    combineLatest([
      this.authService.isAuthorized$({
        sysAdminsOnly: false,
        permissions: [PermissionType.PERMISSION_UPDATE_PROTOCOL_ENTRY],
      }),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([userHasUpdateProtocolEntryPermission]) => {
        this.userHasUpdateProtocolEntryPermission = userHasUpdateProtocolEntryPermission;
      });
  }

  async onChangePatientGroup({ patientGroup, protocolVersion }: ProtocolForm) {
    const canDeactivate = await this.canDeactivate();

    if (!canDeactivate || !patientGroup || !protocolVersion) {
      return;
    }

    const gData = this.patientGroupQuery.getEntity(patientGroup);

    const protocol_version = protocolVersion || '';

    if (gData) {
      this.patientGroupId$.next(gData.id);

      this.patientProtocolService
        .get(
          [PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT],
          gData.id,
          false,
          protocol_version
        )
        .pipe(untilDestroyed(this))
        .subscribe();
    } else {
      this.patientGroupId$.next(null);

      if (patientGroup === 'visitCosts') {
        this.isVisit$.next(true);

        this.patientProtocolService
          .get([PatientProtocolType.PATIENT_PROTOCOL_PATIENT_VISIT], '', false, protocol_version)
          .pipe(untilDestroyed(this))
          .subscribe();
      } else if (patientGroup === 'invoiceables') {
        this.isVisit$.next(false);

        this.patientProtocolService
          .get(
            [
              PatientProtocolType.PATIENT_PROTOCOL_DISCONTINUED,
              PatientProtocolType.PATIENT_PROTOCOL_OTHER,
              PatientProtocolType.PATIENT_PROTOCOL_OVERHEAD,
              PatientProtocolType.PATIENT_PROTOCOL_SCREEN_FAIL,
            ],
            '',
            false,
            protocol_version
          )
          .pipe(untilDestroyed(this))
          .subscribe();
      }
    }

    if (this.editModeGrid$.getValue()) {
      this.cancelEditMode();
    }
  }

  createProtocol = async (isAmendment?: boolean) => {
    const response = await firstValueFrom(
      this.overlayService.open<{ version: Option }>({
        content: ProtocolVersionModalComponent,
        data: {
          modalView: isAmendment ? 'amendment' : 'create',
          name: isAmendment ? 'Create Protocol Amendment' : 'Create Protocol',
          protocolVersionOptions: this.protocolVersionOptions,
        },
      }).afterClosed$
    );

    if (response.data?.version) {
      this.protocolVersionOptions = [response.data.version, ...this.protocolVersionOptions];

      this.protocolForm.controls.protocolVersion.setValue(response.data.version.value);

      const successMessage = isAmendment ? 'Amendment Created' : 'Protocol Created';

      this.overlayService.showFieldTooltip('protocolVersion', successMessage);
    }
  };

  // Selects the node.data field to return
  subTypeGetter(params: ValueGetterParams): PatientProtocolSubType {
    return params.data?.patient_protocol_sub_type as PatientProtocolSubType;
  }

  // Formats the UI rendered string (only)
  subTypeFormatter(params: ValueFormatterParams): string {
    if (!params.value) {
      return '';
    }

    const subType = params.value as PatientProtocolSubType;
    return subType.name;
  }

  // Setting new value after editing
  subTypeSetter(params: ValueSetterParams): boolean {
    params.data.patient_protocol_sub_type = params.newValue as PatientProtocolSubType;

    return true;
  }

  // Process copy value for clipboard (from grid)
  subTypeCopyForClipboard(params: ProcessCellForExportParams): string | number {
    // Return default
    if (typeof params.value !== 'object') {
      return params.value;
    }

    // Return default if object isn't PatientProtocolSubType
    if (!('patient_protocol_type' in params.value)) {
      return params.value;
    }

    // Prefix to find copied PatientProtocolSubType
    // objects on paste
    const prefix = 'SUBTYPE_COPY_';
    const subType = JSON.stringify(params.value);
    return prefix + subType;
  }

  // Process paste value from clipboard (to grid)
  subTypeCopyFromClipboard(params: ProcessCellForExportParams) {
    const prefix = 'SUBTYPE_COPY_';

    if (!params.value.includes(prefix)) {
      return params.value;
    }

    const subTypeString = params.value.split(prefix).pop();
    return JSON.parse(subTypeString) as PatientProtocolSubType;
  }

  subTypeIdFromGridData(data: PatientProtocolSubType | null | undefined): string {
    return data?.id || '';
  }

  subTypeCellEditorParams(): PatientProtocolSubType[] {
    // Partition between Visits and Other types
    const [visits, others] = partition(this.protocolSubTypes, (subType) => {
      return subType.patient_protocol_type.includes('VISIT');
    });

    // If a group id is selected, or if it's a visit,
    // use visits, otherwise use other types.
    const groupId = !!this.patientGroupId$.getValue();
    const isVisit = this.isVisit$.getValue();

    return groupId || isVisit ? visits : others;
  }

  async editProtocolVersion(version: EditableListDropdownItem) {
    const effectiveDateTemplate = 'Effective Date: ';

    const { data } = await firstValueFrom(
      this.overlayService.open<{
        success: boolean;
        updatedVersionName: string;
        effective_date: string;
      }>({
        content: ProtocolVersionModalComponent,
        data: {
          modalView: 'edit',
          name: 'Edit Protocol Version',
          protocolVersionOptions: this.protocolVersionOptions,
          protocolVersion: {
            name: version.name,
            id: version.value,
            effective_date: version.bottomText?.replace(effectiveDateTemplate, ''),
          },
        },
      }).afterClosed$
    );

    if (data?.success && data?.updatedVersionName) {
      this.protocolVersionOptions = this.protocolVersionOptions.map((v) =>
        v.value === version.value
          ? {
              ...v,
              label: data?.updatedVersionName,
              effective_date: data.effective_date,
            }
          : v
      );

      this.cdr.markForCheck();
    }
  }

  async deleteProtocolVersion(version: EditableListDropdownItem) {
    const { data } = await firstValueFrom(
      this.overlayService.openConfirmDialog({
        header: 'Remove Protocol Version',
        message: `Are you sure you want to delete Protocol ${version.name}?`,
        okBtnText: 'Confirm',
      }).afterClosed$
    );

    if (data?.result) {
      const success = await this.patientProtocolService.removePatientProtocolVersion(
        version.value,
        version.name
      );

      const isSelectedVersionDeleted = this.protocolForm.value.protocolVersion === version.value;

      if (success) {
        this.protocolVersionOptions = this.protocolVersionOptions.filter(
          ({ value }) => value !== version.value
        );

        if (isSelectedVersionDeleted) {
          this.protocolForm.controls.protocolVersion.setValue(
            this.protocolVersionOptions[0]?.value || null
          );
        }
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.stickyElementService.configure();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.stickyElementService.configure();
  }

  gridSizeChanged() {
    this.stickyElementService.configure();
  }
}
