import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { OrganizationQuery } from '@models/organization/organization.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Currency } from '@services/gql.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ForecastQuery } from '../state/forecast.query';
import { ForecastService } from '../state/forecast.service';

@UntilDestroy()
@Component({
  selector: 'aux-forecast-analytics-section',
  templateUrl: './forecast-analytics-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastAnalyticsSectionComponent implements OnInit {
  analyticsSectionLoading$ = new BehaviorSubject(false);

  orgCurrency = Currency.USD;

  constructor(
    public forecastQuery: ForecastQuery,
    private forecastService: ForecastService,
    private organizationQuery: OrganizationQuery
  ) {
    this.orgCurrency = this.organizationQuery.getActive()?.currency || Currency.USD;
  }

  ngOnInit(): void {
    this.initAnalyticsSection();
  }

  private initAnalyticsSection(): void {
    this.analyticsSectionLoading$.next(true);
    combineLatest([
      this.forecastService.getActivitiesByDriver(),
      this.forecastService.getUnforecastedCosts(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(() => this.analyticsSectionLoading$.next(false));
  }
}
